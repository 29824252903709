import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import CustomTabs from "app/v2/components/CustomTabs";
import ContractOnboarding from "./ContractOnboarding";
import ContractDetail from "./ContractDetail";
import ConsultantContractHeader from "./ConsultantContractHeader";
import commonImages from "../../../../images/common";
import { Tooltip } from "@mui/material";
import TimesheetConsultant from "./TimeSheetConsultant";
import OnboardingDetailsConsultant from "./OnboardingDetailsConsultant";

const useStyles = makeStyles((theme) => ({
  contractModalRoot: {
    maxWidth: "1080px",
    width: "100%",
  },
}));
const ConsultantContract = () => {
  const classes = useStyles();
  const tabsData = [

    {
      label: "Onboarding Details",
      component: <OnboardingDetailsConsultant />,
    },
   
    {
      label: "Contract Details",
      component: <ContractDetail />,
    },
    {
      label: "Timesheet ",
      component: <TimesheetConsultant />,
    },
    {
      label: "History",
      component: <ContractOnboarding />,
    },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return (
    // <Tooltip
    //   title="Feature will be available in the next release"
    //   placement="top"
    //   arrow
    // >
      <div>
        <div
          className={classes.contractModalRoot}
          // style={{ opacity: 0.4, pointerEvents: "none" }}
        >
          <ConsultantContractHeader />
          <CustomTabs
            tabsData={tabsData}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
          />
        </div>
      </div>
    // </Tooltip>
  );
};

ConsultantContract.propTypes = {};
export default ConsultantContract;
