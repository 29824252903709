export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkIsTodaysDate = (d) => {
  const inputDate = new Date(d);
  const todaysDate = new Date();
  if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};

// eslint-disable-next-line no-unused-expressions
export const getInnerHtml = (__html) => { __html };

export const scrollToTop = () => window.scrollTo({
  top: 0,
  left: 0,
  behavior: "smooth"
});