import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import Overview from "./profileedit/Overview";
import Skills from "./profileedit/Skills";
import WorkHistory from "./profileedit/WorkHistory";
import EducationAndCertification from "./profileedit/EducationAndCertification";
import LanguageProficiency from "./profileedit/LanguageProficiency";
import Awards from "./profileedit/Awards";
import Resumes from "./profileedit/Resumes";

import { useDispatch } from "react-redux";

const ProfileEdit = ({
  data,
  skillIDToNameMapper,
  skillLevelIDToRatingMapper,
  experiences,
  contractTypes,
  industries,
  setProfileData,
}) => {
  const dispatch = useDispatch();

  const onOverviewEntryChange = (id, value) => {
    // console.log({ id, value });
    setProfileData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      let overviewData = updatedProfileData?.overview;
      overviewData[id] = value;
      updatedProfileData.overview = overviewData;
      return updatedProfileData;
    });

    // setProfileData((prevProfileData) => {
    //   const updatedConsultantDetails = {
    //     ...prevProfileData.consultantDetails,
    //     overview: {
    //       ...prevProfileData.consultantDetails.overview,
    //       [id]: value,
    //     },
    //   };
    //   return {
    //     ...prevProfileData,
    //     consultantDetails: updatedConsultantDetails,
    //   };
    // });
  };

  const onOtherInfoChange = (id, value) => {
    setProfileData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      updatedProfileData[id] = value;

      // const updatedConsultantDetails = { ...prevProfileData.consultantDetails };
      // updatedConsultantDetails[id] = value;

      // return {
      //   ...prevProfileData,
      //   consultantDetails: updatedConsultantDetails,
      // };

      return updatedProfileData;
    });
  };

  const onSocialLinksEntryChange = (id, value) => {
    setProfileData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      let socialLinksData = { ...updatedProfileData?.socialLinks };
      socialLinksData[id] = value;
      updatedProfileData.socialLinks = socialLinksData;
      return updatedProfileData;
    });
  };

  const onSkillsPickerChange = (skillsPickerID, value) => {
    onOtherInfoChange("skills", value);
  };

  return (
    <>
      <Overview
        overviewData={data?.overview}
        // socialLinks={data?.socialLinks?.[0]}
        socialLinks={data?.socialLinks}
        // activityDetails={data?.activity?.[0]}
        activityDetails={data?.activity}
        experiences={experiences}
        contractTypes={contractTypes}
        onOverviewEntryChange={onOverviewEntryChange}
        onSocialLinksEntryChange={onSocialLinksEntryChange}
      />
      <Skills data={data?.skills} setSkillsData={onSkillsPickerChange} />

      <WorkHistory
        data={data?.workHistory}
        industries={industries}
        contractTypes={contractTypes}
        onOtherInfoChange={onOtherInfoChange}
        skillIDToNameMapper={skillIDToNameMapper}
      />
      <EducationAndCertification
        educationData={data?.education}
        certificationData={data?.certification}
        onOtherInfoChange={onOtherInfoChange}
      />
      <LanguageProficiency
        data={data?.languages}
        onOtherInfoChange={onOtherInfoChange}
      />
      <Awards data={data?.awards} onOtherInfoChange={onOtherInfoChange} />
      <Resumes
        resumeDoc={data?.resumeDoc}
        videoResume={data?.videoResume}
        onOtherInfoChange={onOtherInfoChange}
      />
    </>
  );
};

ProfileEdit.propTypes = {};

export default ProfileEdit;
