import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { messages } from "app/v2/Pages/Utils/constants";
import Title from "../../common/Title";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ConnectionsCard from "../common/Cards/ConnectionsCard";
import {
  getConnectionsData,
  putConnectionsData,
  postConnectionsData,
} from "app/v2/services/consultant/connections.service";
import { getAccountType } from "../../Utils/utilFunctions";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  connectionFeedContainerLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  tabs: {
    marginTop: "38px",
    marginLeft: "24px",
    display: "flex",
    columnGap: "60px",
  },
  tab: {
    fontWeight: 600,
    fontSize: "18px",
    paddingBottom: "24px",
    color: "#ABABAB",
    cursor: "pointer",
  },
  selectedTab: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  connectionFeedBodyContainer: {
    paddingTop: "171px", // headerDiv total height is 171 px(including margin,padding)
    minWidth: "1000px",
  },
  leftView: {
    padding: "48px 24px",
    width: "305px",
    position: "absolute",
    borderRight: "1px solid #EDEDED",
    overflow: "auto",
    height: "615px",
  },
  rightView: {
    margin: "10px 24px 24px 329px", // left margin - 305px +24px(here 305 is fixed leftView div's width )
    minHeight: "600px" /*added recently to avoid footer with main*/,
  },
  searchTextDiv: {
    marginBottom: "26px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  levelCheckboxDiv: {
    margin: "12px 24px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "76px",
    rowGap: "12px",
  },
  loadMoreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyConnectionData = [
  {
    _id: "1",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "2",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "3",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "4",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "5",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "6",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "7",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
];

const Connections = (props) => {
  const classes = useStyles();

  const sortData = [
    {
      label: "Newest",
      value: "Newest",
    },
    {
      label: "Oldest",
      value: "Oldest",
    },
    // {
    //   label: "Alphabetical (A-Z)",
    //   value: "Alphabetical (A-Z)",
    // },

    // {
    //   label: "Alphabetical (Z-A)",
    //   value: "Alphabetical (Z-A)",
    // },
  ];

  const tabs = [
    "All Connections",
    "Consultant  Connections",
    "Agency Connections",
  ];
  const [sortSelectionValue, setSortSelectionValue] = useState(
    sortData[0]?.value
  );

  const [connectionsData, setConnectionsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [searchText, setSearchText] = useState("");
  const [currentSortSelectionValue, setCurrentSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [statusOfConnection, setStatusOfConnection] = useState([]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [updateConnectionsApi, setUpdateConnectionsApi] = useState(false);

  const onSortChange = (id, currentValue) => {
    setSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState, elementID) => {
    if (elementID === "worktype-checkbox-group") {
      setStatusOfConnection((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
    }
    // if (elementID === "contractType-checkbox-group") {
    //   setContractTypeData((prevItems) => {
    //     const updatedItems = prevItems?.map((item) => {
    //       if (item.value === checkboxValue) {
    //         return { ...item, checked: checkedState };
    //       }
    //       return item;
    //     });
    //     return updatedItems;
    //   });
    // }
  };

  const filterBySortValue = (jobDataReceived) => {
    switch (sortSelectionValue) {
      case "Newest":
        // Sort the jobData based on the "published" date in descending order
        jobDataReceived?.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0);
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
        break;
      // case "Relevance":
      //   break;
      // case "Alphabetical (A-Z)":
      //   jobDataReceived.sort((jobEntry1, jobEntry2) => {
      //     const companyName1 = jobEntry1?.consultantDetails.name || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
      //     const companyName2 = jobEntry2?.consultantDetails.name || "ZZZZZ";

      //     return companyName1.localeCompare(companyName2);
      //   });
      //   break;
      // case "Alphabetical (Z-A)":
      //   jobDataReceived.sort((jobEntry1, jobEntry2) => {
      //     const title1 = jobEntry1?.consultantDetails.name || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
      //     const title2 = jobEntry2?.consultantDetails.name || "ZZZZZ";

      //     return title2.localeCompare(title1);
      //   });
      //   break;
      case "Oldest":
        // Sort the jobDataReceived based on the "published" date in descending order
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined published dates as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date1 - date2;
        });
        break;
      default:
        // If the sortSelectionValue is not recognized, default to sorting by "Date Accepted"
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined acceptedDate as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
    }

    return jobDataReceived;
  };

  useEffect(() => {
    filterBySortValue(filteredJobData);
  }, [filteredJobData]);

  const filterByConnectionStatus = (jobDataReceived) => {
    const selectedConnectionStatus = statusOfConnection
      .filter((onboardvalue) => onboardvalue.checked === true)
      .map((onboardvalue) => onboardvalue.value);

    if (isValidArray(selectedConnectionStatus)) {
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        selectedConnectionStatus.includes(jobEntry?.status)
      );
    }

    return jobDataReceived;
  };

  const getOnboardingStatusfilter = (jobData) => {
    let filters = [];
    let onBoardingstatusAndCount = new Map();

    jobData?.forEach((jobEntry) => {
      const workType = jobEntry?.status;
      if (workType && workType.length > 0 && workType !== "rejected") {
        const workTypeCountInMap = onBoardingstatusAndCount.get(workType);
        onBoardingstatusAndCount.set(
          workType,
          workTypeCountInMap ? workTypeCountInMap + 1 : 1
        );
      }
    });

    onBoardingstatusAndCount.forEach((workTypeEntryCount, workTypeEntry) => {
      filters.push({
        label: `${workTypeEntry} (${workTypeEntryCount})`,
        value: `${workTypeEntry}`,
        checked: false,
      });
    });

    return filters;
  };

  const onTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const updateFilteredJobData = () => {
    if (isValidArray(jobData)) {
      let filteredJobData = JSON.parse(JSON.stringify(jobData));
      if (searchText?.length > 0) {
        const searchTerm = searchText.toLowerCase();
        filteredJobData = filteredJobData.filter(
          (jobEntry) =>
            jobEntry?.receiver.name?.toLowerCase()?.startsWith(searchTerm) ||
            jobEntry?.sender.name?.toLowerCase()?.startsWith(searchTerm)
        );
      }
      filteredJobData = filterBySortValue(filteredJobData);
      filteredJobData = filterByConnectionStatus(filteredJobData);
      // filteredJobData = filterByContractType(filteredJobData);
      // filteredJobData = filterByCountry(filteredJobData);
      // filteredJobData = filterBySalary(filteredJobData);
      setFilteredJobData(filteredJobData);
    }
  };

  useEffect(() => {
    setSearchText("");
    const storedUserData = localStorage.getItem("user");
    const user_id = JSON.parse(storedUserData);
    const userId = user_id._id;
    const accountType = getAccountType();

    //  Get api
    // const request = {
    //   user_id: "",
    //   from_id: user_id,
    //   fromUserType: accountType,
    //   toUserType: "Client",
    //   to_id: "111xxx...",
    //   status: "accepted",
    // };

    getConnectionsData(userId)
      .then((res) => {
        if (isValidArray(res.data)) {
          setDataLoading(false);
          let receivedJobData = JSON.parse(JSON.stringify(res.data));

          if (selectedTab === "Consultant  Connections") {
            setFilteredJobData([]);

            const tabData = receivedJobData?.filter(
              (tab) =>
                (tab?.receiver?.userType === "Consultants" ||
                  tab?.receiver?.userType === "Client") &&
                (tab?.sender?.userType === "Agency" ||
                  tab?.sender?.userType === "Client")
            );
            setFilteredJobData(tabData);
            setJobData(tabData);
            setConnectionsData(tabData);
            // setConnectionsData(dummyConnectionData);
            const connectstatus = getOnboardingStatusfilter(tabData);
            setStatusOfConnection(connectstatus);
          } else if (selectedTab === "Agency Connections") {
            setFilteredJobData([]);

            const tabData = receivedJobData?.filter(
              (tab) =>
                (tab?.receiver?.userType === "Agency" ||
                  tab?.receiver?.userType === "Client") &&
                (tab?.sender?.userType === "Agency" ||
                  tab?.sender?.userType === "Client")
            );
            setFilteredJobData(tabData);
            setJobData(tabData);
            setConnectionsData(tabData);
            // setConnectionsData(dummyConnectionData);
            const connectstatus = getOnboardingStatusfilter(tabData);
            setStatusOfConnection(connectstatus);
          } else {
            setFilteredJobData(receivedJobData);
            setJobData(receivedJobData);
            setConnectionsData(res.data);
            // setConnectionsData(dummyConnectionData);
            const connectstatus = getOnboardingStatusfilter(receivedJobData);
            setStatusOfConnection(connectstatus);
          }

          // setFilteredJobData(receivedJobData);
          // setJobData(receivedJobData);
          // setConnectionsData(res.data);
          // // setConnectionsData(dummyConnectionData);
          // const connectstatus = getOnboardingStatusfilter(receivedJobData);
          // setStatusOfConnection(connectstatus);
        } else {
          setConnectionsData([]);
        }
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });

    setDataLoading(false);
    // setConnectionsData(dummyConnectionData);
  }, [updateConnectionsApi, selectedTab]);

  useEffect(() => {
    updateFilteredJobData();
  }, [
    searchText,
    sortSelectionValue,
    statusOfConnection,

    // contractTypeData,
    // salaryDetails,
    // jobCountries,
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTab, statusOfConnection]);

  return (
    <div className={dataLoading && classes.connectionFeedContainerLoading}>
      <>
        {dataLoading ? (
          <CircularProgress />
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <div>
                <div className={classes.headerDiv}>
                  <Title title="Connections" />
                  <div className={classes.tabs}>
                    {tabs?.map((tabName) => (
                      <div
                        className={clsx([
                          classes.tab,
                          tabName === selectedTab && classes.selectedTab,
                        ])}
                        onClick={() => {
                          onTabClick(tabName);
                        }}
                      >
                        {tabName}
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classes.connectionFeedBodyContainer}>
                  <Grid container>
                    <Grid item xs={12} sm={3} className={classes.leftView}>
                      <div className={classes.searchTextDiv}>
                        <CustomTextfield
                          id="searchText"
                          value={searchText}
                          placeholder="Search by name"
                          onChange={(id, value) => {
                            setSearchText(value);
                          }}
                          // endAdornment={<SearchRoundedIcon />}
                          noErrorMessage={true}
                        />
                      </div>
                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Sort{" "}
                        </Typography>
                        <div className={classes.sortRadioGroupDiv}>
                          <CustomRadioGroup
                            id="sort-radio-group"
                            name="sort-radio-group"
                            defaultValue={sortSelectionValue}
                            data={sortData}
                            onChange={onSortChange}
                          />
                        </div>
                      </div>

                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Filter
                        </Typography>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Status
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <div className={classes.levelCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="worktype-checkbox-group"
                                  data={statusOfConnection}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        {/* <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Skills
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div> */}
                        {/* <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Location
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div> */}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={9} className={classes.rightView}>
                      {filteredJobData?.length > 0 ? (
                        filteredJobData.map((connectionData, index) => (
                          <ConnectionsCard
                            key={index}
                            data={connectionData}
                            setUpdateConnectionsApi={setUpdateConnectionsApi}
                          />
                        ))
                      ) : (
                        <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
                      )}

                      {/* <div className={classes.loadMoreDiv}>
                        <MoreHorizIcon
                          className={classes.loadMoreIcon}
                          fontSize="large"
                        />
                        <Typography className={classes.loadMoreText}>
                          Load more
                        </Typography>
                      </div> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

Connections.propTypes = {};

export default Connections;
