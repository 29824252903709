import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import { Link, Typography } from "@mui/material";

import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";

import { messages } from "app/v2/Pages/Utils/constants";
import { EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  sendInviteButton: {
    width: "100%",
    padding: "16px 28px",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    columnGap: "10px",
    fontSize: "16px",
    fontWeight: 600,
  },
  line: {
    marginBottom: 0,
  },
  resendInviteText: {
    fontSize: "14px",
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
    marginTop: "12px",
    cursor: "pointer",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  button: {
    padding: "16px 28px",
    width: "197px",
    boxSizing: "content-box",
    fontSize: "16px",
    fontWeight: 600,
  },
  noButton: {
    border: "1px solid #EDEDED",
  },
}));

const ExistingConsultantSendInviteDialog = ({
  dialogOpen,
  setDialogOpen,
  closeParentDialog,
}) => {
  const classes = useStyles();
  const [sendInviteActionLoading, setSendInviteActionLoading] = useState(true);
  const [sendInviteError, setSendInviteError] = useState(false);
  const [sendInviteCompleted, setSendInviteCompleted] = useState(false);

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {sendInviteActionLoading && "Action in progress"}
        {sendInviteError && "Error"}
        {sendInviteCompleted && "Invitation Sent"}
      </Typography>
    );
  };

  const DialogBody = () => {
    if (sendInviteActionLoading) return <DialogLoadingBody />;
    else if (sendInviteError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else if (sendInviteCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: "Your invitation has been sent successfully.",
          }}
        />
      );
    else return <></>;
  };

  useEffect(() => {
    setTimeout(() => {
      /** Confirm case */
      setSendInviteActionLoading(false);
      setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
      // setSendInviteCompleted(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (!dialogOpen) {
      // When this current dialog closes we should close its parent dialog as well.
      closeParentDialog();
    }
  }, [dialogOpen]);

  return (
    <CustomDialog
      open={dialogOpen}
      setOpen={setDialogOpen}
      noCloseAction={sendInviteActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const SendInviteDialog = ({
  sendInviteDialogOpen,
  setSendInviteDialogOpen,
}) => {
  const inviteResponseTypesMessages = {
    NEW_CONSULTANT_INVITED: {
      message:
        "The consultant has been invited successfully. Upon acceptance, they will be added to your agency in our system.",
    },
    CONSULTANT_EXIST: {
      message: "Consultant User Already Exists in the System!",
      desc: "Consultant email matches with a user and may be part of another agency  within our system, would you still like to invite him/her to join your agency?",
    },
  };
  const classes = useStyles();
  const [sendInviteActionLoading, setSendInviteActionLoading] = useState(true);
  const [sendInviteError, setSendInviteError] = useState(false);
  const [sendInviteApiSuccess, setSendInviteApiSuccess] = useState(false);
  const [sendInviteSuccessResponseType, setSendInviteSuccessResponseType] =
    useState();
  const [eCSIDialogOpen, setECSIDialogOpen] = useState(false); // eCSI/ECSI -existing consultant send invite.
  const [firstRender, setFirstRender] = useState(true);

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {sendInviteActionLoading && "Action in progress"}
        {sendInviteError && "Error"}
        {sendInviteApiSuccess && (
          <>
            {sendInviteSuccessResponseType === "CONSULTANT_EXIST"
              ? "Invitation Response"
              : "Invitation Sent"}
          </>
        )}
      </Typography>
    );
  };

  const DialogBody = () => {
    if (sendInviteActionLoading) return <DialogLoadingBody />;
    else if (sendInviteError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else if (sendInviteApiSuccess)
      return (
        <>
          {sendInviteSuccessResponseType === "NEW_CONSULTANT_INVITED" && (
            <DialogSuccessBody
              content={{
                title:
                  inviteResponseTypesMessages?.[sendInviteSuccessResponseType]
                    ?.message,
              }}
            />
          )}

          {sendInviteSuccessResponseType === "CONSULTANT_EXIST" && (
            <div className="w-[778px] p-[48px] flex flex-col gap-y-[24px]">
              <div className="py-[48px] flex flex-col gap-y-[24px] text-center">
                <div className="text-[24px] font-semibold">
                  {
                    inviteResponseTypesMessages?.[sendInviteSuccessResponseType]
                      ?.message
                  }
                </div>
                <div className="text-[16px] text-[#717171]">
                  {
                    inviteResponseTypesMessages?.[sendInviteSuccessResponseType]
                      ?.desc
                  }
                </div>
              </div>
              <div className="flex justify-center gap-x-[24px]">
                <CustomButton
                  customClasses={clsx([classes.button, classes.noButton])}
                  onClick={() => {
                    setSendInviteDialogOpen(false);
                  }}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="button1"
                  customClasses={classes.button}
                  onClick={() => {
                    setECSIDialogOpen(true);
                  }}
                >
                  Proceed to Invite
                </CustomButton>
              </div>
              {eCSIDialogOpen && (
                <ExistingConsultantSendInviteDialog
                  dialogOpen={eCSIDialogOpen}
                  setDialogOpen={setECSIDialogOpen}
                  closeParentDialog={() => {
                    setSendInviteDialogOpen(false);
                  }}
                />
              )}
            </div>
          )}
        </>
      );
    else return <></>;
  };

  useEffect(() => {
    setFirstRender(false);
    setTimeout(() => {
      /** Confirm case */
      const responseType = "NEW_CONSULTANT_INVITED";
      setSendInviteActionLoading(false);
      setSendInviteApiSuccess(true);
      setSendInviteSuccessResponseType(responseType);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
      // setSendInviteApiSuccess(false);
    }, 3000);
  }, []);

  useEffect(() => {
    // Another case for closing send invite dialog -> When existing consultant send invite is closed, its previous send invite dialog also needs to be closed . Added extra firstRender condition so it does not enter this logic on initial render when sendInviteDialogOpen is false initially.
    const existingConsultantSuccessResponse =
      sendInviteSuccessResponseType === "CONSULTANT_EXIST";
    if (!firstRender && existingConsultantSuccessResponse && !eCSIDialogOpen)
      setSendInviteDialogOpen(false);
  }, [eCSIDialogOpen]);

  return (
    <CustomDialog
      open={sendInviteDialogOpen}
      setOpen={setSendInviteDialogOpen}
      noCloseAction={sendInviteActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const AddConsultant = ({ closeDialog }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [consultantData, setConsultantData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [activateSendInvite, setActivateSendInvite] = useState(false);
  const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const onConsultantDataChange = (id, value) => {
    setConsultantData((consultantData) => {
      let updatedConsultantData = { ...consultantData };
      updatedConsultantData[id] = value;
      return updatedConsultantData;
    });
  };

  const validateEntries = () => {
    const isValidFirstName = consultantData?.first_name?.length > 0;
    const isValidLastName = consultantData?.last_name?.length > 0;
    const isValidEmail = EMAIL_PATTERN.test(consultantData?.email);
    setActivateSendInvite(isValidFirstName && isValidLastName && isValidEmail);
  };

  const onSendInviteClick = () => {
    setSendInviteDialogOpen(true);
  };

  useEffect(() => {
    validateEntries();
  }, [consultantData]);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    if (!firstRender && !sendInviteDialogOpen) closeDialog(); // closing add consultant dialog once send invite dialog is closed. Added extra firstRender condition so it does not enter this logic on initial render when sendInviteDialogOpen is false initially.
  }, [sendInviteDialogOpen]);

  return (
    <>
      <div className="p-[48px] flex flex-col gap-y-[33px]">
        <div className="text-[24px] font-semibold">Add Consultant</div>
        <div className="px-[16px] flex flex-col gap-y-[24px]">
          <div className="flex gap-x-[24px]">
            <div className="flex-1">
              <CustomTextfield
                id="first_name"
                label="First Name"
                placeholder="Kyla"
                value={consultantData?.first_name}
                onChange={onConsultantDataChange}
              />
            </div>
            <div className="flex-1">
              <CustomTextfield
                id="last_name"
                label="Last Name"
                placeholder="Andrews"
                value={consultantData?.last_name}
                onChange={onConsultantDataChange}
              />
            </div>
          </div>
          <div className="text-[24px] font-semibold">Invitation Email</div>
          <CustomTextfield
            id="email"
            type="email"
            label="email"
            placeholder="Add Email"
            value={consultantData?.email}
            onChange={onConsultantDataChange}
          />
        </div>
        <CustomTooltip
          title={!activateSendInvite && "Please enter all the  details."}
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.sendInviteButton}
              disabled={!activateSendInvite}
              onClick={() => {
                onSendInviteClick();
              }}
            >
              Send Invite <img src="/iconfilledsend.svg" />
            </CustomButton>
          </div>
        </CustomTooltip>
      </div>
      {sendInviteDialogOpen && (
        <SendInviteDialog
          sendInviteDialogOpen={sendInviteDialogOpen}
          setSendInviteDialogOpen={setSendInviteDialogOpen}
        />
      )}
    </>
  );
};

AddConsultant.propTypes = {};

export default AddConsultant;
