import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    maxWidth: "510px",
    width: "100%",
    boxShadow: "none",
    marginBottom: "12px !important",
    border: 0,
    "&::before": {
      backgroundColor: theme.palette.common.white,
    },
  },
  accordionSummary: {
    height: "44px",
    padding: "12px 0",
    border: 0,
    borderBottom: "1px solid #E0E0E0",
  },
  title: {
    fontWeight: 500,
  },
  accordionDetails: {
    margin: "auto",
    padding: "16px 12px",
    overflowX: "auto",
  },
}));

const CustomAccordion = (props) => {
  const classes = useStyles();
  const { title, children, expandedState } = props;
  const [expanded, setExpanded] = useState(expandedState);

  const handleAccordionChange = () => {
    setExpanded((prevState) => !prevState);
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordionChange}
      className={classes.accordionRoot}
    >
      <AccordionSummary
        expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <Typography variant="body2" className={classes.title}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

CustomAccordion.propTypes = {};

export default CustomAccordion;
