import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CardCollection from "../../common/CardCollection";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomButton from "app/v2/components/CustomButton";
import TabbedCardView from "../common/TabbedCardView";

const useStyles = makeStyles((theme) => ({}));

/**
 * @description - This component is the "view all" page for consultants/agencies from the dashboard feed.
 */
const FeedViewAll = ({
  title = "Search Consultants by Job Post",
  viewType = "consultant",
}) => {
  const classes = useStyles();
  const isConsultantView = viewType === "consultant";
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabsData = [
    {
      label: "Grid view",
      component: <CardCollection cardsData={[]} />,
    },
    {
      label: "List view",
      component: <CardCollection cardsData={[]} />,
    },
  ];
  const joborTeamPostsExists = true; // add condition to show the specific job posts drop down.
  const dummyJobPostsData = [
    "Job 27: Frontend Developer for Contractual Project",
    "Job 28: Backend Developer for Contractual Project",
    "Job 29: Backend Developer for Full time Project",
  ]; // will get this at global level context or redux store.
  const dummyTeamPostsData = [
    "Team 4: Costco App Development Project",
    "Team 5: Costco App Development Project",
    "Team 6: Costco App Development Project",
  ]; // will get this at global level context or redux store.
  const autocompeleteData = isConsultantView
    ? dummyJobPostsData
    : dummyTeamPostsData;
  return (
    <div>
      <Typography>{title}</Typography>
      {joborTeamPostsExists && (
        <div>
          <CustomAutocomplete
            id={"job-posts-autocomplete"}
            data={autocompeleteData}
            defaultSelection={autocompeleteData[0]}
            // placeholder={"Enter Role"}
            // onChange={onRoleChange}
            // customClasses={classes.autoCompleteRoot}
            // disabled={disableRoleAutocomplete}
          />
          <CustomButton>Filter & Sort</CustomButton>
        </div>
      )}
      <Typography>2,231 consultants found</Typography>
      <TabbedCardView
        tabsData={tabsData}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
        viewAllOnClick={() => {}}
      />
    </div>
  );
};

FeedViewAll.propTypes = {};

export default FeedViewAll;
