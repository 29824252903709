import React from "react";
import PropTypes from "prop-types";
import { Typography, Card, CardContent } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  hpRoot: {
    margin: "0 252px 197px 252px",
    [theme.breakpoints.down("1700")]: {
      marginLeft: "200px",
      marginRight: "200px",
    },
    [theme.breakpoints.down("1500")]: {
      marginLeft: "100px",
      marginRight: "100px",
    },
    [theme.breakpoints.down("800")]: {
      marginLeft: "47px",
      marginRight: "47px",
    },
  },
  title: {
    fontSize: "60px",
    fontWeight: 500,
    maxWidth: "1008px",
    width: "100%",
    margin: "auto",
    marginBottom: "121px",
    textAlign: "center",
    [theme.breakpoints.down("1200")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "32px",
      marginBottom: "81px",
    },
  },
  swiperContainer: {
    [theme.breakpoints.down("800")]: {
      marginLeft: "47px",
      marginRight: "0",
    },
  },
  stepCardDiv: {
    maxWidth: "332px",
    minHeight: "466px", // 466-160(top and bottom padding)
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: "80px 40px 0 40px", // no bottom padding as minHeight already handles it
    [theme.breakpoints.down("800")]: {
      margin: "auto",
      maxWidth: "305px",
      minHeight: "427px",
      padding: "70px 36px",
    },
  },
  stepNumber: {
    fontSize: "80px",
    fontWeight: 700,
    color: theme.palette.secondary.light,
    marginBottom: "32px",
    [theme.breakpoints.down("1700")]: {
      fontSize: "70px",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "60px",
    },
  },
  stepHeading: {
    fontWeight: 700,
    color: "inherit",
    marginBottom: "32px",
    [theme.breakpoints.down("1700")]: {
      fontSize: "28px",
    },
  },
  stepDescription: {
    fontWeight: 500,
    [theme.breakpoints.down("800")]: {
      fontSize: "20px",
    },
  },
}));

const HiringProcess = (props) => {
  const classes = useStyles();
  const mdDown = useMediaQuery("(max-width:1200px)");
  const smDown = useMediaQuery("(max-width:800px)");
  const data = {
    title: "How to hire top remote developers through FindPro?",
    steps: [
      {
        heading: "Tell us the skills you need",
        description: "We’ll schedule a call and understand your requirements.",
      },
      {
        heading: "We find the best talent for you",
        description: "Get a list of pre-vetted candidates within days.",
      },
      {
        heading: "Schedule interviews",
        description: "Meet and select the developers you like.",
      },
      {
        heading: "Begin your trial",
        description: "Start building with a no-risk 2-week trial period.",
      },
    ],
  };

  const StepCard = ({ stepData, stepNumber }) => {
    return (
      <div className={classes.stepCardDiv}>
        <Typography className={classes.stepNumber}>{stepNumber + 1}</Typography>
        <Typography variant="h5" className={classes.stepHeading}>
          {stepData.heading}
        </Typography>
        <Typography variant="body1" className={classes.stepDescription}>
          {stepData.description}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.hpRoot}>
      <Typography className={classes.title}>{data.title}</Typography>
      <Swiper
        slidesPerView={smDown ? 1.1 : mdDown ? 2.3 : data.steps.length}
        spaceBetween={mdDown ? 10 : 22}
        grabCursor={true}
        // loop={true}
        className={clsx([classes.swiperContainer, "mySwiper"])}
      >
        {data.steps.map((stepData, index) => (
          <SwiperSlide>
            <StepCard stepData={stepData} stepNumber={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

HiringProcess.propTypes = {};

export default HiringProcess;
