import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../images/hire_developer_team/common";
import teamImages from "../../../images/hire_developer_team/team";

const useStyles = makeStyles((theme) => ({
  teamInfoDiv: {
    maxWidth: "1555px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    columnGap: "121px",
    [theme.breakpoints.down("1600")]: {
      maxWidth: "1350px",
    },
    [theme.breakpoints.down("1400")]: {
      maxWidth: "950px",
    },
    [theme.breakpoints.down("1000")]: {
      maxWidth: "750px",
      columnGap: "60px",
    },
    [theme.breakpoints.down("1000")]: {
      flexDirection: "column",
      columnGap: 0,
    },
  },
  teamImage: {
    [theme.breakpoints.down("800")]: {
      marginBottom: "48px",
    },
  },
  titleAndDetailsDiv: {
    maxWidth: "665px",
    [theme.breakpoints.down("800")]: {
      margin: "0 47px 159px 47px",
    },
  },
  title: {
    marginBottom: "36px",
    [theme.breakpoints.down("1400")]: {
      fontSize: "40px",
    },
    [theme.breakpoints.down("1000")]: {
      fontSize: "35px",
    },
  },
  detailsDiv: {},
  detailsEntryListItem: {
    listStyle: "none",
    display: "flex",
    columnGap: "45px",
    marginBottom: "36px",
    alignItems: "flex-start",
    [theme.breakpoints.down("1400")]: {
      columnGap: "20px",
    },
  },
  detailsIcon: {
    objectFit: "none",
    marginTop: "4px",
  },
  detailsTitleDescDiv: {
    maxWidth: "509px",
  },
  detailsTitle: {
    fontWeight: 500,
    color: theme.palette.secondary.light,
    marginBottom: "5px",
    [theme.breakpoints.down("1400")]: {
      fontSize: "18px",
    },
  },
  detailsDesc: {
    fontWeight: 300,
  },
}));
const TeamInfo = (props) => {
  const classes = useStyles();
  const data = {
    title: "A different kind of development team",
    image: teamImages.team_info,
    details: [
      {
        title: "The people",
        desc: "Lorem ipsum dolor sit amet consectetur. Eget velit diam tellus odio venenatis. Magna bibendum nulla semper facilisi. Pulvinar.",
        icon: commonImages.dollar_icon,
      },
      {
        title: "The path",
        desc: "Lorem ipsum dolor sit amet consectetur. Eget velit diam tellus odio venenatis. Magna bibendum nulla semper facilisi. Pulvinar.",
        icon: commonImages.code_icon1,
      },
    ],
  };
  return (
    <div className={classes.teamInfoDiv}>
      <div className={classes.teamImage}>
        <img src={data.image} />
      </div>
      <div className={classes.titleAndDetailsDiv}>
        <Typography className={classes.title} variant="h2">
          {data.title}
        </Typography>
        <div className={classes.detailsDiv}>
          <ul>
            {data.details.map((entry, index) => (
              <li className={classes.detailsEntryListItem}>
                <img
                  src={entry.icon}
                  alt={`detail-${index}`}
                  className={classes.detailsIcon}
                />

                <div className={classes.detailsTitleDescDiv}>
                  <Typography className={classes.detailsTitle} variant="body1">
                    {entry.title}
                  </Typography>
                  <Typography className={classes.detailsDesc} variant="body2">
                    {entry.desc}
                  </Typography>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

TeamInfo.propTypes = {};

export default TeamInfo;
