import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAppliedCandidates,
  getAppliedVendorCandidates,
  getInvitedCandidates,
  getAllTestsByJobId,
} from "./manage-jobs.service";

// First, create the thunk
const getAppliedCandidatesThunk = createAsyncThunk(
  "jobs/appliedCandidates/get",
  async (job_id, thunkAPI) => {
    return getAppliedCandidates(job_id);
  }
);

const getInvitedCandidatesThunk = createAsyncThunk(
  "jobs/invitedCandidates/get",
  async (job_id, thunkAPI) => {
    return getInvitedCandidates(job_id);
  }
);

const getAppliedVendorCandidatesThunk = createAsyncThunk(
  "jobs/appliedVendorCandidates/get",
  async (job_id, thunkAPI) => {
    return getAppliedVendorCandidates(job_id);
  }
);

const getAllInvitedTestCandidatesByJobIdThunk = createAsyncThunk(
  "jobs/invitedTestCandidates/get",
  async (job_id, thunkAPI) => {
    return getAllTestsByJobId(job_id);
  }
);

export {
  getAppliedCandidatesThunk,
  getInvitedCandidatesThunk,
  getAppliedVendorCandidatesThunk,
  getAllInvitedTestCandidatesByJobIdThunk,
};
