import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, FormHelperText } from "@mui/material";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";

import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTimeRangePicker from "app/v2/Pages/common/CustomTimeRangePicker";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomButton from "app/v2/components/CustomButton";

import CloseIcon from "@mui/icons-material/Close";

import { UserContext } from "app/v2";

import {
  isUndefined,
  isDateStringValidFormat,
  isTimeStringValidFormat,
  isValidString,
} from "app/v2/Pages/Utils/utilFunctions";

import { TWELVE_HOUR_TIME_FORMAT } from "app/v2/Pages/Utils/constants";

import { updateActiveConsultantOnboardingActivity } from "app/v2/services/client/activeConsultant.service";
import { useSnackbar } from "notistack";
import CheckBoxTable from "./CheckBoxTable";
import Title from "../../common/Title";
import InvoiceSettings from "./InvoiceSettings";
import { activeConsultants } from "app/v2/services/client/consultants.service";

dayjs.extend(utc);
dayjs.extend(timezone); // Extend dayjs with timezone plugin

const useStyles = makeStyles((theme) => ({
  dateOrTimePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  dateOrTimePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
}));

const Activity = ({ props }) => {
  const classes = useStyles();
  const statuses = ["Documents under Assessment", "Completed"];
  const workModeData = ["On site", "Hybrid", "Remote"];

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  // const onboardingDetails = drawerDetails?.contractActivity?.onboardingDetails;
  const [onboardingDetails, setOnBoardingDetails] = useState({});
  console.log("detaisl of onbaodinr", onboardingDetails);
  const activeWorkId = drawerDetails?.contractActivity?._id;
  // const timesheetSettings=drawerDetails?.contractActivity?.settings?.timesheetSettings;
  const [timesheetSettings, setTimesheetSettings] = useState({});
  // const invoiceSettingsDetails = drawerDetails?.contractActivity?.settings;
  const [invoiceSettingsDetails,setInvoiceSettingsDetails]= useState({})
  const [onboardingStatus, setOnboardingStatus] = useState("");
  console.log("detaisl 2", onboardingStatus);
  const [startDate, setStartDate] = useState("");
  const [workingTime, setWorkTime] = useState({
    start: "",
    end: "",
  });
  const [workMode, setWorkMode] = useState("");
  const [activateUpdateButton, setActivateUpdateButton] = useState(true);

  const onStatusChange = (id, value) => {
    setOnboardingStatus(value);
  };

  const isDateInvalid = () => {
    /**
     * Condition -> (!isUndefined(startDate) && startDate === "")
     * 1. While initially setting the start date it will be undefined,
     * and we dont have to show error message immediately,only when there's a change in date and it is invalid
     * (invalid check is handled-check date picker's onChange logic,
     * selecting older date isa disabled in the picker itself),then we have to show the message.
     * 2. But for edit case we specifically set it to null if its not a valid truthy value(since we dont show error if value is undefined in point 1).
     */

    return !isUndefined(startDate) && startDate === "";
  };

  const handleDateChange = (date) => {
    let formattedDateInput = "";
    try {
      formattedDateInput = dayjs(date).format("YYYY-MM-DD"); // sets date in ISO format specifically with EOD timestamp eg: "2023-12-12T23:59:59.999Z"

      if (formattedDateInput === "Invalid Date") formattedDateInput = "";
    } catch (error) {
      formattedDateInput = "";
    }

    setStartDate(formattedDateInput);
  };

  const validateEntries = () => {
    const validStatus = isValidString(onboardingStatus);
    if (!validStatus) {
      setActivateUpdateButton(false);
      return;
    }
    const validWorkMode = isValidString(workMode);
    if (!validWorkMode) {
      setActivateUpdateButton(false);
      return;
    }
    const isValidStartDateString = isDateStringValidFormat(
      startDate,
      "YYYY-MM-DD"
    ); // Validating as per format used in handleDateChange, This format even works if date received is in ISO format.
    if (!isValidStartDateString) {
      setActivateUpdateButton(false);
      return;
    }

    const isStartTimeStringValid = isTimeStringValidFormat(
      workingTime?.start,
      TWELVE_HOUR_TIME_FORMAT
    );
    if (!isStartTimeStringValid) {
      setActivateUpdateButton(false);
      return;
    }
    const isEndTimeStringValid = isTimeStringValidFormat(
      workingTime?.end,
      TWELVE_HOUR_TIME_FORMAT
    );
    if (!isEndTimeStringValid) {
      setActivateUpdateButton(false);
      return;
    }
  };

  const onUpdate = () => {
    const onboardingStatusObj = {
      consultantId: drawerDetails?.contractActivity?.consultantDetails?._id,
      jobId: drawerDetails?.contractActivity?.jobDetails?._id,
      onboardingDetails: {
        onboardingStatus,
        workingTime,
        startDate,
        workMode,
      },
    };

    updateActiveConsultantOnboardingActivity(onboardingStatusObj)
      .then((response) => {
        const variantType = response?.success ? "success" : "error";
        const message = response?.message
          ? response?.message
          : "Unknown error occurred";
        enqueueSnackbar(message, {
          variant: variantType,
          autoHideDuration: 2000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000); // temp
        setActivateUpdateButton(false)
      })
      .catch((error) => {
        enqueueSnackbar("Problem in updating the Onboarding Activity.", {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  useEffect(() => {
    const fetchValues = async () => {
      try {
        const res = await activeConsultants();

        if (res?.success) {
          
          let receivedJobData = JSON.parse(JSON.stringify(res?.data));

          let filterActiveConsultantData = receivedJobData
            ?.filter(
              (data) =>
                data?.consultantDetails?._id ===
                drawerDetails?.contractActivity?.consultantDetails?._id
            )
            .filter(
              (filterdata) =>
                filterdata?.appNo === drawerDetails?.contractActivity?.appNo
            );

          setTimesheetSettings(
            filterActiveConsultantData?.[0]?.settings?.timesheetSettings
          );
          setInvoiceSettingsDetails(filterActiveConsultantData?.[0]?.settings?.invoiceSettings)

          const onboardingDetailsData =
            filterActiveConsultantData?.[0]?.onboardingDetails;

          setOnBoardingDetails(onboardingDetailsData);
          setOnboardingStatus(onboardingDetailsData?.onboardingStatus || "");
          setStartDate(onboardingDetailsData?.startDate || "");
          setWorkTime({
            start: onboardingDetailsData?.workingTime?.start || "",
            end: onboardingDetailsData?.workingTime?.end || "",
          });
          setWorkMode(onboardingDetailsData?.workMode || "");
          setActivateUpdateButton(false)

          console.log("full values 123", filterActiveConsultantData);
        }
      } catch (error) {
        // Handle errors if necessary
        console.error("Error fetching values:", error);
      }
    };

    fetchValues();
  }, [drawerDetails]); // Ensure to include 'drawerDetails' as a dependency

  // Rest of your code...

  useEffect(() => {
    validateEntries();
  }, [onboardingStatus, startDate, workingTime, workMode]);

  useEffect(() => {
    const isValueChanged =
      onboardingStatus !== onboardingDetails?.onboardingStatus ||
      startDate !== onboardingDetails?.startDate ||
      workingTime.start !== onboardingDetails?.workingTime?.start ||
      workingTime.end !== onboardingDetails?.workingTime?.end ||
      workMode !== onboardingDetails?.workMode;
  
    setActivateUpdateButton(isValueChanged);
  }, [onboardingDetails, onboardingStatus, startDate, workingTime, workMode]);

  return (
    <div className="flex flex-col gap-y-[16px] p-[32px]">
      <div className="flex flex-row gap-x-10">
      <CustomSelect
        id="onboarding status"
        data={statuses}
        label="Onboarding status"
        placeholder={"Select"}
        onChange={onStatusChange}
        defaultSelection={onboardingStatus}
      />
      <CustomSelect
        id="workType"
        data={workModeData}
        label="work mode"
        placeholder={"Select"}
        onChange={(id, value) => {
          setWorkMode(value);
        }}
        defaultSelection={workMode}
      />
</div>
      <div className="flex flex-col">
        <CustomLabel label="start date" />
        <FormControl error={isDateInvalid()}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={
                isDateStringValidFormat(startDate, "YYYY-MM-DD")
                  ? dayjs(startDate)
                  : null
              }
              onChange={(date) => {
                handleDateChange(date);
              }}
              className={clsx([
                classes.dateOrTimePicker,
                isDateInvalid() && classes.dateOrTimePickerError,
              ])}
            />
          </LocalizationProvider>
          {isDateInvalid() && (
            <FormHelperText className="ml-0">
              Please enter a valid date.
            </FormHelperText>
          )}
        </FormControl>
      </div>

      <CustomTimeRangePicker
        label="work time"
        ampm={true}
        start={workingTime?.start}
        end={workingTime?.end}
        noStartEndComparison={true}
        onChange={(formattedStartTime, formattedEndTime) => {
          setWorkTime({
            start: formattedStartTime,
            end: formattedEndTime,
          });
        }}
      />

      

      {/* <CustomTooltip
        title={!activateUpdateButton ? "Please fill the above entries." : null}
      > */}
        <div className="flex justify-center">
          <CustomButton
            type="button1"
            customClasses="text-[16px] font-semibold py-3 px-7 flex gap-x-[8px]"
            onClick={onUpdate}
            disabled={!activateUpdateButton}
          >
            Update
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconfilledsend.svg"
            />
          </CustomButton>
        </div>
      {/* </CustomTooltip> */}
      <hr className="border-t my-4" />

      <div className="">
        <h2 className="text-xl font-bold mb-2">Timesheet Settings</h2>

        <CheckBoxTable
          timesheetSettings={timesheetSettings}
          activeWorkId={activeWorkId}
        />
      </div>
      <hr className="border-t my-4" />
      <div>
        <h2 className="text-xl font-bold mb-8">Invoice Settings</h2>

        <InvoiceSettings
          invoiceSettingsDetails={invoiceSettingsDetails}
          activeWorkId={activeWorkId}
        />
      </div>
    </div>
  );
};

Activity.propTypes = {};

export default Activity;
