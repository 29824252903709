import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getAccountType } from "../login/login.service";
import classnames from "classnames";
import "./subscribe.scss";

const SubscribeComponent = (props) => {
  const [planULDetails, setPlanULDetails] = useState({});
  const [isAutoRenew, setIsAutoRenew] = useState(true);
  const [isTileActive, setIsTileActive] = useState(true);

  useEffect(() => {
    if (props.plans?.length > 0) {
      const unlimitedPlans = props.plans.filter(
        (plan) => plan.name === "Unlimited"
      );
      let uPlans = {};
      unlimitedPlans.forEach((pl) => {
        uPlans[pl.frequency] = pl;
      });
      setPlanULDetails(uPlans);
    }
  }, [props.plans]);

  return (
    <div className="subs-container new-layout">
      <Grid
        container
        sx={{ width: "100%", justifyContent: "center", p: 5 }}
        className="body-spacing"
      >
        {/* {plans.map((x, i) => ( TODO: PLAN MAPPING FROM ADMIN WILL BE TAKEN CARE ONCE OTHER PLANS ARE CONFIRMED*/}
        <>
          {props.features["Unlimited"] && (
            <Grid key={0} item xs sx={{ maxWidth: "300px", mr: 3 }}>
              <Card>
                {getAccountType() !== "employers" ? (
                  <div className="plan-container">
                    <div style={{ textAlign: "center", margin: "2rem 0" }}>
                      <span className={`plan-title ${"Unlimited"}-title`}>
                        {"Unlimited"}
                      </span>
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        margin: "1.5rem 0",
                        fontWeight: 700,
                        fontSize: "18px",
                      }}
                    >
                      {"x.description"}
                    </div>
                    <p className="subs-amount">${"x.amount"}</p>
                    <p className="subs-duration">per month</p>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Link
                        to={{
                          pathname: "/subscribe-payment",
                          state: { amount: "x.amount", planId: "x._id" },
                        }}
                      >
                        <Button sx={{ width: "13vw", marginTop: "2rem" }}>
                          Buy Now
                        </Button>
                      </Link>
                    </CardActions>
                  </div>
                ) : (
                  <div className="auto-renewal">
                    <div className="renewal-tiles">
                      <div
                        className={classnames("pay-tile", {
                          isActive: isTileActive,
                        })}
                        onMouseOver={() => setIsTileActive(true)}
                      >
                        <p className="subs-amount">
                          ${planULDetails["monthly"]?.amount}
                        </p>
                        <p className="subs-duration">For 30 days</p>
                        <CardActions
                          sx={{
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/subscribe-payment",
                              state: {
                                amount: planULDetails["monthly"]?.amount,
                                planId: planULDetails["monthly"]?.paypalPlanId,
                                _id: planULDetails["monthly"]?._id,
                                autoRenew: isAutoRenew,
                              },
                            }}
                          >
                            <Button
                              sx={{ width: "13vw", marginTop: "2rem" }}
                              onClick={() =>
                                localStorage.setItem(
                                  "reload-page",
                                  // @ts-ignore
                                  isAutoRenew
                                )
                              }
                            >
                              Buy Now
                            </Button>
                          </Link>
                        </CardActions>
                      </div>
                      <div
                        className={classnames("pay-tile", {
                          isActive: !isTileActive,
                          "d-none": !planULDetails["quarterly"],
                        })}
                        onMouseOver={() => setIsTileActive(false)}
                      >
                        <p className="subs-amount">
                          ${planULDetails["quarterly"]?.amount}
                        </p>
                        <p className="subs-duration">For 90 days</p>
                        <CardActions
                          sx={{
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Link
                            to={{
                              pathname: "/subscribe-payment",
                              state: {
                                amount: planULDetails["quarterly"]?.amount,
                                planId:
                                  planULDetails["quarterly"]?.paypalPlanId,
                                _id: planULDetails["quarterly"]?._id,
                                autoRenew: isAutoRenew,
                              },
                            }}
                          >
                            <Button
                              sx={{ width: "13vw", marginTop: "2rem" }}
                              onClick={() =>
                                localStorage.setItem("reload-page", isAutoRenew)
                              }
                            >
                              Buy Now
                            </Button>
                          </Link>
                        </CardActions>
                      </div>
                    </div>
                    <FormGroup sx={{ marginTop: "2rem" }}>
                      <FormControlLabel
                        className="filter-label"
                        sx={{ margin: 0 }}
                        control={
                          <Checkbox
                            sx={{ padding: "5px", borderRadius: 0 }}
                            size="medium"
                            onChange={() => setIsAutoRenew(!isAutoRenew)}
                            checked={isAutoRenew}
                            checkedIcon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <rect
                                  x="1"
                                  y="1"
                                  width="14"
                                  height="14"
                                  rx="4"
                                  fill="#1C81FF"
                                  stroke="#1C81FF"
                                  strokeWidth="2"
                                />
                                <path
                                  d="M5.15144 7.73734C4.76092 7.34682 4.12775 7.34682 3.73723 7.73734C3.3467 8.12787 3.3467 8.76103 3.73723 9.15156L5.15144 7.73734ZM6.66656 10.6667L5.95945 11.3738C6.14699 11.5613 6.40134 11.6667 6.66656 11.6667C6.93177 11.6667 7.18613 11.5613 7.37366 11.3738L6.66656 10.6667ZM11.8181 6.92934C12.2086 6.53881 12.2086 5.90565 11.8181 5.51512C11.4276 5.1246 10.7944 5.1246 10.4039 5.51512L11.8181 6.92934ZM3.73723 9.15156L5.95945 11.3738L7.37366 9.95957L5.15144 7.73734L3.73723 9.15156ZM7.37366 11.3738L11.8181 6.92934L10.4039 5.51512L5.95945 9.95957L7.37366 11.3738Z"
                                  fill="#4FC7B3"
                                />
                                <path
                                  d="M5.15144 7.73734C4.76092 7.34682 4.12775 7.34682 3.73723 7.73734C3.3467 8.12787 3.3467 8.76103 3.73723 9.15156L5.15144 7.73734ZM6.66656 10.6667L5.95945 11.3738C6.14699 11.5613 6.40134 11.6667 6.66656 11.6667C6.93177 11.6667 7.18613 11.5613 7.37366 11.3738L6.66656 10.6667ZM11.8181 6.92934C12.2086 6.53881 12.2086 5.90565 11.8181 5.51512C11.4276 5.1246 10.7944 5.1246 10.4039 5.51512L11.8181 6.92934ZM3.73723 9.15156L5.95945 11.3738L7.37366 9.95957L5.15144 7.73734L3.73723 9.15156ZM7.37366 11.3738L11.8181 6.92934L10.4039 5.51512L5.95945 9.95957L7.37366 11.3738Z"
                                  fill="white"
                                  fill-opacity="0.92"
                                />
                              </svg>
                            }
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <rect
                                  x="0.75"
                                  y="0.75"
                                  width="14.5"
                                  height="14.5"
                                  rx="4.25"
                                  stroke="#C1C1C1"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            }
                            value={isAutoRenew}
                            className="filters__checkbox"
                          />
                        }
                        label={`Auto Renew (your plan will be auto renewed post ${
                          isTileActive ? 30 : 90
                        } days)`}
                      />
                    </FormGroup>
                  </div>
                )}
                <div className="features">
                  {getAccountType() === "employers" && <h3>{"Unlimited"}</h3>}
                  {props.features["Unlimited"].map((feature) => {
                    return (
                      <div>
                        <p className="features-title">
                          <BsFillCheckCircleFill /> {feature.title}
                        </p>
                        <p className="features-desc">{feature.description}</p>
                        <hr />
                      </div>
                    );
                  })}
                </div>
              </Card>
            </Grid>
          )}
        </>
        {/* ))} */}
      </Grid>
    </div>
  );
};

export default SubscribeComponent;
