import hire_consultant from "./hire_consultant.png";
import hire_team from "./hire_team.png";
import work_exp from "./work_exp.png";
import resumes from "./resumes.png";

const images = {
  hire_consultant,
  hire_team,
  work_exp,
  resumes,
};
export default images;
