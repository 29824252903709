import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import {
  getEmployerInfo,
  updateEmployerInfo,
  updateEmployerItAssessmentTokens,
} from "./profile-settings.service";

// First, create the thunk
export const getEmployerInfoThunk = createAsyncThunk("employer_info", async (request, thunkAPI) => {
  return getEmployerInfo();
});

export const updateProfilePictureThunk = createAsyncThunk("profile-settings/profile-picture/put", async(request, thunkAPI) => {
  return updateEmployerInfo(request).then((res) => {
    thunkAPI.dispatch(profileComplete(res.isProfileComplete));
    // updateProfileComplete(res.isProfileComplete);
    return res;
  });
});

export const updateEmployerInfoThunk = createAsyncThunk("profile-settings/put", async(request, thunkAPI) => {
  return updateEmployerInfo(request).then((res) => {
    thunkAPI.dispatch(profileComplete(res.isProfileComplete));
    // updateProfileComplete(res.isProfileComplete);
    return res;
  });
});

export const updateEmployerItAssessmentThunk = createAsyncThunk(
  "it-assessment/put",
  async (request, thunkAPI) => {
    return updateEmployerItAssessmentTokens(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);
