import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomLabel from "./filters/CustomLabel";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    color: `${theme.palette.common.black} !important`,
    padding: "0 8px 0 0",
  },
  checkboxDisabled: {
    color: `${theme.palette.secondary.main} !important`,
    padding: "0 8px 0 0",
  },
  checkboxLabelRoot: {
    margin: "0 0 14px 0",
  },
  checkboxLabel: {
    fontSize: "14px",
  },
}));

const CustomCheckboxGroup = ({ id, data, onChange, disabled }) => {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.value, event.target.checked, id);
  };
  return (
    <FormGroup>
      {data?.map((checkbox, index) => (
        <FormControlLabel
          key={`checkbox-${index}`}
          control={
            <Checkbox
              checked={checkbox?.checked}
              disabled={disabled || checkbox?.disabled}
              value={checkbox?.value}
              onChange={handleChange}
              classes={{
                root:
                  disabled || checkbox?.disabled
                    ? classes.checkboxDisabled
                    : classes.checkbox,
              }}
            />
          }
          label={checkbox?.label}
          classes={{
            root: classes.checkboxLabelRoot,
            label: classes.checkboxLabel,
          }}
        />
      ))}
    </FormGroup>
  );
};

CustomCheckboxGroup.propTypes = {};

export default CustomCheckboxGroup;
