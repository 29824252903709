// @ts-nocheck
import React, { useEffect } from "react";
import { Route } from "react-router";
import AdminRoute from "./routes/adminRoute";
import CandidateRoute from "./routes/candidateRoute";
import EmployerRoute from "./routes/employerRoute";
import VendorRoute from "./routes/vendorRoute";
import LandingPage from "./landingPage/LandingPage";
import "./app.scss";
// import AskAnExpert from "./common/components/askAnExpert/AskAnExpert";
import PrivacyPolicies from "./landingPage/PrivacyPolicies";
import Terms from "./landingPage/Terms";
import Calendy from "./common/components/calendy/calendy";
import HowWeWork from "./landingPage/howwework";
import CalendyTeam from "./common/components/calendy/calendy_team";
import V2Pages from "./v2";
import { BrowserRouter } from "react-router-dom";


const App = () => {

  localStorage.setItem("isShareFlow", true);
  localStorage.setItem("isVendorBulkUpload", true);


  return (
<>
      {/* <BrowserRouter basename="/v1">
      <Route exact path={"/"} component={LandingPage} />
      <Route path={"/v2"} component={V2Pages} />
      <Route exact path={"/how_we_work"} component={HowWeWork} />
      <Route exact path={"/privacy_policy"} component={PrivacyPolicies} />
      <Route exact path={"/terms_conditions"} component={Terms} />
      <Route
        exact
        path={"/all-jobs"}
        component={() => <LandingPage isAllJobs={true} />}
      />
      <Route path={"/candidates"} component={CandidateRoute} />
      <Route path={"/employers"} component={EmployerRoute} />
      <Route path={"/vendors"} component={VendorRoute} />
      <Route path={"/admin"} component={AdminRoute} />
      <Route path={"/ask-an-expert"} component={Calendy} />
      <Route path={"/hire-a-team"} component={CalendyTeam} />
    </BrowserRouter> */}
      <BrowserRouter basename="/">
        <Route path={"/"} component={V2Pages} />
      </BrowserRouter>
</>
  );
};

export default App;
