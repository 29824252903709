import React from "react";
import PropTypes from "prop-types";
import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import { Typography } from "@mui/material";

const Job = ({ type }) => {
  const statusTypeInfo = {
    active: {
      text: "Active",
      textColor: "#14C042",
      dotColor: "#14C042",
      divColor: "#EBFFF0",
    },
    inactive: {
      text: "Inactive",
      textColor: "#4b5563",
      dotColor: "#4b5563",
      divColor: "#F9FAFB",
    },
    closed: {
      text: "Closed",
      textColor: "#1E5DFF",
      dotColor: "#1E5DFF",
      divColor: "#F4F8FF",
    },
    draft: {
      text: "Draft",
      textColor: "#717171",
      divColor: "#F4F8FF",
      borderColor: "#717171",
    },
  };
  const statusInfo = statusTypeInfo[type];

  return (
    <>
      {statusInfo ? (
        <div
          style={{ backgroundColor: statusInfo?.divColor }} // divColor addition in className below is not working.
          className={`flex gap-x-[5px] items-center px-[8px] bg-[${
            statusInfo?.divColor
          }] ${
            statusInfo?.borderColor &&
            `border-[1px] border-solid border-[${statusInfo?.borderColor}]`
          }`}
          // className={`flex gap-x-[5px] items-center px-[8px] ${
          //   statusInfo?.borderColor &&
          //   `border-[1px] border-solid border-[${statusInfo?.borderColor}]`
          // }`}
        >
          {statusInfo?.dotColor && <IconDot fillColor={statusInfo?.dotColor} />}
          <Typography
            style={{
              color: statusInfo?.textColor,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {statusInfo?.text}
          </Typography>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Job;
