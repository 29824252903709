import React from "react";
export const IconLightUser = ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 

            fill={fillColor}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
            width="26" height="26" viewBox="0 0 26 26">
        <path d="M13.2032 12.8065C15.73 12.8065 17.7783 10.7582 17.7783 8.2314C17.7783 5.70461 15.73 3.65625 13.2032 3.65625C10.6764 3.65625 8.62805 5.70461 8.62805 8.2314C8.62805 10.7582 10.6764 12.8065 13.2032 12.8065Z" stroke="#0B0E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.2031 14.4707C10.7882 14.5369 8.42177 15.1634 6.29049 16.3008C5.74205 16.5791 5.28147 17.0039 4.95989 17.5281C4.6383 18.0523 4.4683 18.6554 4.46875 19.2704V19.8777C4.46875 20.2086 4.60021 20.526 4.83421 20.76C5.06822 20.994 5.38559 21.1255 5.71652 21.1255H20.6897C21.0207 21.1255 21.338 20.994 21.572 20.76C21.806 20.526 21.9375 20.2086 21.9375 19.8777V19.2704C21.938 18.6554 21.7679 18.0523 21.4464 17.5281C21.1248 17.0039 20.6642 16.5791 20.1158 16.3008C17.9845 15.1634 15.618 14.5369 13.2031 14.4707V14.4707Z" stroke="#0B0E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>

  );
};
