const QualificationsObj = {
  "status":"active|inactive|closed",
  "status_original":"inactive",
  "jobName":"Development Team Lead|Frontend Javascript Developer",
  "role": "Hiring Manager",
  "roleName": "Mike Fitz",
  "jobNo": "FP23117",
  "openPositions": 1
};
export { QualificationsObj };
