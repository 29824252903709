import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getHiringBoardOfJob = async (jobID) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/hiring-board${jobID ? `/${jobID}` : ``}`
  );
};

export const moveConsultant = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/consultant-accept`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request, // {consultantId,jobId,stage}
  });
};

export const moveConsultant2 = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/activeConsultants`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request, // {consultantId,jobId,stage}
  });
};



export const getHackerRankTests = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/getAllTest`);
};

export const inviteConsultantForTest = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/inviteConsultant`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request, // {consultantId,jobId,testId,dueDate}
  });
};

export const updateConsultantTestInvite = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/updateinvitedConsultant`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request, // {consultantId,jobId,testId,dueDate}
  });
};

export const cancelConsultantTestInvite = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/cancelledTestInvitation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request, // {consultantId,jobId,testId,dueDate}
  });
};

export const setHiringBoardStageData = async ({ jobID, request }) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/set-hiring-board${jobID ? `/${jobID}` : ``}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: request, // array of stage objects.
    }
  );
};

export const scheduleMeeting = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/schedule-meeting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
export const reScheduleMeeting = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/reScheduleMeeting`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const downloadPDFReport = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/downloadPDFReport`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getJobProposalDetails = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/jobProposal`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const makeBid = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/bidAmount`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const activeConsultantsByAcceptingTheBid = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/activeConsultants`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
