import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import CustomButton from "../../components/CustomButton";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";
import { useHistory } from "react-router-dom";
import "../../components/customswiperstyles.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "97rem",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    padding: "9.6rem 0 15.6rem 11.3rem",
    [theme.breakpoints.down("700")]: {
      padding: "97px 40px",
    },
  },
  title: {
    color: "inherit",
    fontWeight: 600,
    marginBottom: "5rem",
    [theme.breakpoints.down("600")]: {
      fontSize: "1.5rem",
      marginBottom: "3rem",
    },
  },
  swiper: {
    paddingBottom: "124px",
  },
  cardContainer: {
    maxWidth: "386px", // 486 -100 since padding is already set
    height: "785px", // 881 - 96 since padding is already set
    padding: "48px 50px",
    borderRadius: "7px",
    [theme.breakpoints.down("1810")]: {
      height: "685px",
      padding: "40px 45px",
    },
    [theme.breakpoints.down("1600")]: {
      height: "600px",
      padding: "35px 40px",
    },
    [theme.breakpoints.down("700")]: {
      maxWidth: "259px",
      height: "470px",
      padding: "30px 35px",
    },
    [theme.breakpoints.down("500")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("350")]: {
      height: "370px",
    },
  },
  cardTitle: {
    height: "180px",
    fontSize: "50px", // reducing size to fit in last card content.
    fontWeight: 700,
    color: "inherit",
    marginBottom: "85px",
    [theme.breakpoints.down("1810")]: {
      height: "150px",
      fontSize: "45px",
      marginBottom: "80px",
    },
    [theme.breakpoints.down("1600")]: {
      height: "100px",
      fontSize: "40px",
    },
    [theme.breakpoints.down("700")]: {
      fontSize: "22px",
      marginBottom: "5px",
    },
    [theme.breakpoints.down("350")]: {
      fontSize: "16px",
      height: "70px",
    },
  },
  cardImage: {
    height: "250px",
    marginBottom: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("1810")]: {
      height: "200px",
    },
    [theme.breakpoints.down("700")]: {
      height: "170px",
    },
    [theme.breakpoints.down("480")]: {
      height: "150px",
    },
    [theme.breakpoints.down("350")]: {
      height: "100px",
      marginBottom: "5px",
    },
  },
  cardContentData: {
    height: "128px",
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "36px",
    [theme.breakpoints.down("1810")]: {
      height: "98px",
      fontSize: "16px",
      marginBottom: "25px",
    },
    [theme.breakpoints.down("700")]: {
      fontSize: "14px",
      marginBottom: 0,
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("350")]: {
      paddingBottom: "20px",
    },
  },
  cardActionDiv: {
    height: "20%",
    justifyContent: "center",
  },
  cardActionButton: {
    border: "2px solid black",
    width: "100%",
    maxWidth: "368px",
    fontSize: "1rem",
    fontWeight: 500,
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    [theme.breakpoints.down("700")]: {
      fontSize: "9px",
      paddingTop: "0.8rem",
      paddingBottom: "0.8rem",
    },
    [theme.breakpoints.down("350")]: {
      fontSize: "6px",
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  whiteCard: {
    backgroundColor: "white",
    color: "black",
  },
}));

const FeatureCard = ({ cardData, cardNumber }) => {
  const classes = useStyles();
  const isFirstCard = cardNumber === 0;
  return (
    <div
      className={clsx([
        classes.cardContainer,
        isFirstCard && classes.whiteCard,
      ])}
    >
      <Typography className={classes.cardTitle}>{cardData.title}</Typography>
      <div>
        <img
          src={cardData.body.image}
          alt={`${cardData.title}-card`}
          className={classes.cardImage}
        />
      </div>
      <Typography variant="body1" className={classes.cardContentData}>
        {cardData.body.data}
      </Typography>
      {cardData.actionComponent}
    </div>
  );
};

const Benefits = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const addFeaturesImages = images.addFeatures;
  const cardsData = [
    {
      title: "Streamline Recruitment",
      body: {
        image: addFeaturesImages[0],
        data: "Our platform streamlines the recruitment process, saving time and resources for recruiters and organizations.",
      },
      actionComponent: (
        <CustomButton
          type="button2"
          customClasses={clsx([classes.cardActionButton])}
          onClick={() => {
            history.push("/hire-developer-details");
          }}
        >
          On-Demand Real Time <TrendingFlatIcon />
        </CustomButton>
      ),
    },
    {
      title: "Access to a Global Talent Pool",
      body: {
        image: addFeaturesImages[1],
        data: "Connect with top IT talent from around the world and access a pool of qualified candidates.",
      },
    },
    {
      title: "Improved Hiring Transparency",
      body: {
        image: addFeaturesImages[2],
        data: "Stay Worry-Free of Our Help to You. If You Need Something, Never Hesitate to Schedule a Meeting.",
      },
    },
    {
      title: "Robust Vetting Process",
      body: {
        image: addFeaturesImages[3],
        data: "Trust that the candidates you connect with have been thoroughly vetted and meet your IT skill requirements.",
      },
    },
  ];
  const smDown = useMediaQuery("(max-width:1000px)");
  const mdDown = useMediaQuery("(max-width:1400px)");
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        BENEFITS
      </Typography>
      <Swiper
        slidesPerView={smDown ? 1.5 : mdDown ? 2.3 : 3.3}
        spaceBetween={smDown ? 30 : 50}
        grabCursor={true}
        scrollbar={{
          hide: false,
          // dragSize: 10,
        }}
        modules={[Scrollbar]}
        className={clsx([
          classes.swiper,
          "mySwiper",
          "custom-swiper-scrollbar",
        ])}
      >
        {cardsData.map((cardData, index) => (
          <SwiperSlide>
            <FeatureCard cardData={cardData} cardNumber={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Benefits.propTypes = {};

export default Benefits;
