import React, { useEffect, useState } from "react";
import tw from 'twin.macro';

import { List, Text } from "../../common";
import Tooltip from "../../common/ToolTip";

import {QualificationsObj} from "./Qualifications.dummy.js";

import { 
  ArrowDownSVG
} from "../../SVGs/Index";

import { Slider } from "@mui/material";
import { makeStyles } from '@mui/styles';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CustomButton from "app/v2/components/CustomButton";
const Button = CustomButton;


const useStyles = makeStyles(() => ({
    slider: {
      '& .MuiSlider-thumb': {
        color: 'black',
      },
      '& .MuiSlider-track': {
        color: '#333',
        height: 4,
        borderRadius: 1,
      },
      '& .MuiSlider-rail': {
        color: '#333',
      },
      '& .MuiSlider-active': {
        color: '333',
      },
    },
    accordion: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '& .MuiAccordionSummary-root': {
          padding: 0,
        },
        '& .MuiAccordionSummary-content': {
          margin: 0,
        },
        '& .MuiAccordionDetails-root': {
          padding: 0,
        },
      },
  }));

const Qualifications = (props) => {

    const classes = useStyles();

    const sliders = [
        { label: 'Ruby', value: 5, min: 1, max: 10 },
        { label: 'C#', value: 3, min: 1, max: 10 },
        { label: 'Python', value: 7, min: 1, max: 10 },
        { label: 'MySQL', value: 4, min: 1, max: 10 },
        { label: 'JavaScript', value: 8, min: 1, max: 10 },
        { label: 'Java', value: 2, min: 1, max: 10 },
        { label: 'HTML', value: 6, min: 1, max: 10 },
      ];
    const [sliderValues, setSliderValues] = useState(sliders.map((slider) => slider.value));

    
    function handleSliderChange(index, newValue) {
        setSliderValues((prevValues) => {
            const updatedValues = [...prevValues];
            updatedValues[index] = newValue;
            return updatedValues;
          });
    }

    return (
    <>

    <div className="flex flex-col sm:gap-10 md:gap-10 gap-10 items-center justify-start mx-auto p-10 md:px-10 sm:px-5 w-full">
        <div className="flex flex-col gap-6 items-start justify-center max-w-[890px] mt-[30px] mx-auto sm:px-5 w-full">
          <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">Qualifications</Text>


          <div className=" w-full ">
            <Accordion defaultExpanded className={classes.accordion}>
                <AccordionSummary
                className="border border-gray-200 border-solid flex flex-row gap-2.5 items-center justify-between p-4 w-full"
                expandIcon={ <ArrowDownSVG /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <div className="flex flex-row gap-3 items-center justify-start">
                        <Text className="font-semibold text-base text-black-900 w-auto">Backend Development</Text>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">8</Text>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <List
                        className="sm:flex-col flex-row gap-5 grid md:grid-cols-1 grid-cols-2 justify-start px-2.5 py-4 w-full"
                        orientation="horizontal"
                        >
                        
                        {sliders.map((slider, index) => (
                            <div className="flex flex-1 flex-col items-start justify-start w-full ">
                                <div key={slider.label} className="flex sm:flex-col flex-row sm:gap-5 items-center justify-start w-full">
                                    <div className="flex flex-col h-10 md:h-auto items-start justify-start px-2 py-3 w-[137px]">
                                        <Text className="font-semibold text-black-900 text-sm w-auto"> {slider.label} </Text>
                                    </div>
                                    <Slider
                                        size="small"
                                        defaultValue={slider.value}
                                        // value={slider.value}
                                        value={sliderValues[index]}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        // marks
                                        min={slider.min}
                                        max={slider.max}
                                        className={classes.slider}
                                        onChange={(event, newValue) => handleSliderChange(index, newValue)}
                                    />
                                </div>
                            </div>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                className="border border-gray-200 border-solid flex flex-row gap-2.5 items-center justify-between p-4 w-full"
                expandIcon={ <ArrowDownSVG  /> }
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <div className="flex flex-row gap-3 items-center justify-start">
                        <Text className="font-semibold text-base text-black-900 w-auto">Team/People Management</Text>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">0</Text>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <List
                        className="sm:flex-col flex-row gap-5 grid md:grid-cols-1 grid-cols-2 justify-start px-2.5 py-4 w-full"
                        orientation="horizontal"
                        >
                        
                        {sliders.map((slider, index) => (
                            <div className="flex flex-1 flex-col items-start justify-start w-full ">
                                <div key={slider.label} className="flex sm:flex-col flex-row sm:gap-5 items-center justify-start w-full">
                                    <div className="flex flex-col h-10 md:h-auto items-start justify-start px-2 py-3 w-[137px]">
                                        <Text className="font-semibold text-black-900 text-sm w-auto"> {slider.label} </Text>
                                    </div>
                                    <Slider
                                        size="small"
                                        defaultValue={slider.value}
                                        // value={slider.value}
                                        value={sliderValues[index]}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        // marks
                                        min={slider.min}
                                        max={slider.max}
                                        className={classes.slider}
                                        onChange={(event, newValue) => handleSliderChange(index, newValue)}
                                    />
                                </div>
                            </div>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                className="border border-gray-200 border-solid flex flex-row gap-2.5 items-center justify-between p-4 w-full"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <div className="flex flex-row gap-3 items-center justify-start">
                        <Text className="font-semibold text-base text-black-900 w-auto">Business Development</Text>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">0</Text>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <List
                        className="sm:flex-col flex-row gap-5 grid md:grid-cols-1 grid-cols-2 justify-start px-2.5 py-4 w-full"
                        orientation="horizontal"
                        >
                        
                        {sliders.map((slider, index) => (
                            <div className="flex flex-1 flex-col items-start justify-start w-full ">
                                <div key={slider.label} className="flex sm:flex-col flex-row sm:gap-5 items-center justify-start w-full">
                                    <div className="flex flex-col h-10 md:h-auto items-start justify-start px-2 py-3 w-[137px]">
                                        <Text className="font-semibold text-black-900 text-sm w-auto"> {slider.label} </Text>
                                    </div>
                                    <Slider
                                        size="small"
                                        defaultValue={slider.value}
                                        // value={slider.value}
                                        value={sliderValues[index]}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        // marks
                                        min={slider.min}
                                        max={slider.max}
                                        className={classes.slider}
                                        onChange={(event, newValue) => handleSliderChange(index, newValue)}
                                    />
                                </div>
                            </div>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
                <AccordionSummary
                className="border border-gray-200 border-solid flex flex-row gap-2.5 items-center justify-between p-4 w-full"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <div className="flex flex-row gap-3 items-center justify-start ">
                        <Text className="font-semibold text-base text-black-900 w-auto">Frontend Development</Text>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">0</Text>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <List
                        className="sm:flex-col flex-row gap-5 grid md:grid-cols-1 grid-cols-2 justify-start px-2.5 py-4 w-full"
                        orientation="horizontal"
                        >
                        
                        {sliders.map((slider, index) => (
                            <div className="flex flex-1 flex-col items-start justify-start w-full ">
                                <div key={slider.label} className="flex sm:flex-col flex-row sm:gap-5 items-center justify-start w-full">
                                    <div className="flex flex-col h-10 md:h-auto items-start justify-start px-2 py-3 w-[137px]">
                                        <Text className="font-semibold text-black-900 text-sm w-auto"> {slider.label} </Text>
                                    </div>
                                    <Slider
                                        size="small"
                                        defaultValue={slider.value}
                                        // value={slider.value}
                                        value={sliderValues[index]}
                                        aria-label="Small"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        // marks
                                        min={slider.min}
                                        max={slider.max}
                                        className={classes.slider}
                                        onChange={(event, newValue) => handleSliderChange(index, newValue)}
                                    />
                                </div>
                            </div>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>

        </div>
        <div className="flex flex-col gap-6 items-start justify-center max-w-[890px] mb-[158px] mx-auto sm:px-5 w-full">
          <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">Qualifications</Text>
          <div className="flex flex-col items-start justify-start w-full">
            <div className="bg-gray-100 flex flex-col items-start justify-start w-full">
              <div className="border-b border-gray-200 border-solid flex flex-row gap-2.5 items-center justify-between p-4 w-full">
                <Text className="font-semibold text-base text-black-900 w-auto">Expert Level</Text>
                <ArrowDownSVG />
              </div>
              <div className="flex flex-col items-start justify-start px-2.5 py-4 w-full">
                <div className="flex flex-col gap-3 items-start justify-center p-4 w-full">
                  <Text className="font-semibold text-gray-600 text-xs uppercase w-auto">skills</Text>
                  <div className="flex flex-row gap-3 items-start justify-start w-auto">
                    <Text className="bg-gray-200 font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                      Ruby
                    </Text>
                    <Text className="bg-gray-200 cursor-pointer font-bold min-w-[37px] px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs">
                      C#
                    </Text>
                    <Text className="bg-gray-200 font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                      Python
                    </Text>
                    <Text className="bg-gray-200 font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                      MySQL
                    </Text>
                    <Text className="bg-gray-200 font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                      Javascript
                    </Text>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-2.5 items-center justify-between p-4 w-full">
              <Text className="font-semibold text-base text-black-900 w-auto">Intermediate Level Skills</Text>
              <ArrowDownSVG />
            </div>
            <div className="flex flex-row gap-2.5 items-center justify-between p-4 w-full">
              <Text className="font-semibold text-base text-black-900 w-auto">Junior Level Skills</Text>
              <ArrowDownSVG />
            </div>
            <div className="flex flex-row gap-2.5 items-center justify-between p-4 w-full">
              <Text className="font-semibold text-base text-black-900 w-auto">Soft Skills</Text>
              <ArrowDownSVG />
            </div>
          </div>
        </div>
      </div>

    </>
    );
};

Qualifications.propTypes = {};
Qualifications.defaultProps = QualificationsObj;

export default Qualifications;