import React from "react";
export const CheckSVG = ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
        width="15" height="16" viewBox="0 0 15 16">
        <path d="M7.5 13.1546C8.17691 13.1546 8.84719 13.0212 9.47257 12.7622C10.098 12.5032 10.6662 12.1235 11.1448 11.6448C11.6235 11.1662 12.0032 10.598 12.2622 9.97257C12.5212 9.34719 12.6546 8.67691 12.6546 8C12.6546 7.32309 12.5212 6.65281 12.2622 6.02743C12.0032 5.40205 11.6235 4.83381 11.1448 4.35516C10.6662 3.87652 10.098 3.49683 9.47257 3.23779C8.84719 2.97875 8.17691 2.84542 7.5 2.84542C6.13292 2.84542 4.82184 3.38849 3.85516 4.35516C2.88849 5.32184 2.34542 6.63292 2.34542 8C2.34542 9.36708 2.88849 10.6782 3.85516 11.6448C4.82183 12.6115 6.13292 13.1546 7.5 13.1546ZM7.5 13.9883C5.91181 13.9883 4.38867 13.3574 3.26565 12.2344C2.14263 11.1113 1.51172 9.58819 1.51172 8C1.51172 6.41181 2.14263 4.88867 3.26565 3.76565C4.38867 2.64263 5.91181 2.01172 7.5 2.01172C9.08819 2.01172 10.6113 2.64263 11.7344 3.76565C12.8574 4.88867 13.4883 6.41181 13.4883 8C13.4883 9.58819 12.8574 11.1113 11.7344 12.2344C10.6113 13.3574 9.08819 13.9883 7.5 13.9883Z" stroke="#363636" strokeWidth="0.0234375"/>
        <path d="M10.2345 6.23449C10.3049 6.16407 10.4004 6.12451 10.5 6.12451C10.5996 6.12451 10.6951 6.16407 10.7655 6.23449C10.8359 6.3049 10.8755 6.4004 10.8755 6.49999C10.8755 6.59957 10.8359 6.69507 10.7655 6.76549L7.39049 10.1405C7.35565 10.1754 7.31427 10.2031 7.26871 10.222C7.22315 10.2409 7.17431 10.2507 7.12499 10.2507C7.07566 10.2507 7.02682 10.2409 6.98126 10.222C6.9357 10.2031 6.89432 10.1754 6.85949 10.1405L4.98449 8.26549C4.94962 8.23062 4.92196 8.18923 4.90309 8.14367C4.88422 8.09812 4.87451 8.04929 4.87451 7.99999C4.87451 7.95068 4.88422 7.90185 4.90309 7.8563C4.92196 7.81074 4.94962 7.76935 4.98449 7.73449C5.01935 7.69962 5.06074 7.67196 5.1063 7.65309C5.15185 7.63422 5.20068 7.62451 5.24999 7.62451C5.29929 7.62451 5.34812 7.63422 5.39367 7.65309C5.43923 7.67196 5.48062 7.69962 5.51549 7.73449L7.12499 9.34474L10.2345 6.23449Z" />
      </svg>
          
    </>

  );
};
