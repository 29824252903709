import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import About from "./About";
import ConnectorAndCompanies from "../hire_developer_team/common/ConnectorAndCompanies";

const useStyles = makeStyles((theme) => ({}));
const HireTeamDetails = (props) => {
  const classes = useStyles();
  return (
    <>
      <About />
      <ConnectorAndCompanies />
    </>
  );
};

HireTeamDetails.propTypes = {};

export default HireTeamDetails;
