import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import {
  Button,
  Typography,
  OutlinedInput,
  FormControl,
  Tooltip,
} from "@mui/material";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "240px",
    padding: "16px",
    boxSizing: "content-box",
    boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
  },
  shareText: {
    color: "#717171",
    fontSize: "12px",
    fontWeight: 700,
  },
  linkRoot: { border: "1px solid #EDEDED", borderRadius: "2px" },
  linkFieldset: {
    border: "0",
  },
  linkTextField: {
    // width: "216px",
    boxSizing: "content-box",
    padding: "6px 12px",
    fontSize: "12px",
    textOverflow: "ellipsis",
  },
  copyButton: {
    width: "30px",
    height: "13px",
    padding: "5px",
    boxSizing: "content-box",
    backgroundColor: "#EDEDED !important",
    color: theme.palette.common.black,
    fontSize: "10px",
    fontWeight: 600,
  },
  socialIconsContainer: {
    display: "flex",
    columnGap: "8px",
    justifyContent: "space-between",
  },
  socialButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "57px",
    height: "14px",
    padding: "8px !important",
    flex: "1 0 0",
    border: "1px solid #EDEDED !important",
  },
}));
const SocialShare = ({ shareLink }) => {
  console.log({ shareLink, origin: window.location.origin });
  const [showTooltip, setShowTooltip] = useState(false);

  const onCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.shareText}>Share Profile</Typography>
      {shareLink ? (
        <>
          <div className={classes.shareLinkDiv}>
            <FormControl variant="outlined">
              <OutlinedInput
                type="text"
                startAdornment={<img src="/link.svg" />}
                endAdornment={
                  <Tooltip
                    title="Copied!"
                    placement="top"
                    arrow
                    open={showTooltip}
                  >
                    <Button
                      className={classes.copyButton}
                      onClick={onCopyClick}
                    >
                      COPY
                    </Button>
                  </Tooltip>
                }
                value={shareLink}
                disabled={true}
                classes={{
                  notchedOutline: classes.linkFieldset,
                  root: classes.linkRoot,
                  input: classes.linkTextField,
                }}
              />
            </FormControl>
          </div>
          <div className={classes.socialIconsContainer}>
            <FacebookShareButton
              url={shareLink}
              className={classes.socialButton}
            >
              <img src="/facebook.svg" />
            </FacebookShareButton>
            <LinkedinShareButton
              url={shareLink}
              className={classes.socialButton}
            >
              <img src="/linkedin.svg" />
            </LinkedinShareButton>
          </div>
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

SocialShare.propTypes = {};

export default SocialShare;
