import React from "react";
import PropTypes from "prop-types";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({}));

const PandaDocDetails = ({ data }) => {
  const classes = useStyles();
  return (
    <div className="self-stretch flex flex-row items-center justify-start gap-[36px] text-left text-base text-black font-body-text-regular-12 py-6">
      <div className="flex-1 flex flex-row items-center justify-start gap-[24px]">
        <img
          className="relative w-12 h-12 overflow-hidden shrink-0"
          alt=""
          src="/iconlightpdf.svg"
        />
        <div className="flex-1 flex flex-col items-start justify-start">
          <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
            <div className="self-stretch relative [text-decoration:underline] leading-[130%] font-semibold inline-block overflow-hidden text-ellipsis whitespace-nowrap h-[21px] shrink-0">
              <p className="m-0">{data?.docName}</p>
              <p className="m-0">4241123</p>
            </div>
            <div className="self-stretch relative text-xs leading-[120%] uppercase font-semibold text-neutral-900">
              {data?.docType}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-[12px] text-xs text-neutral-500">
            <div className="relative leading-[180%]">
              Created {data?.createdDate}
            </div>
            <div className="relative leading-[180%]">
              Document No. {data?.docNo}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start gap-[16px]">
        <div className="flex flex-row p-2.5 items-center justify-center border-[1px] border-solid border-neutral-50">
          <button>
            {" "}
            <img
              className="relative w-[23.49px] h-5 overflow-hidden shrink-0"
              alt=""
              src="/logosdropbox.svg"
            />
          </button>
        </div>
        <div className="flex flex-row p-2.5 items-center justify-center border-[1px] border-solid border-neutral-50">
          <button>
            <img
              className="relative w-5 h-[17.89px] overflow-hidden shrink-0"
              alt=""
              src="/logosgoogledrive.svg"
            />
          </button>
        </div>
        <div className="flex flex-row p-2.5 items-center justify-center border-[1px] border-solid border-neutral-50">
          <button>
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/logosmicrosoftonedrive.svg"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

PandaDocDetails.propTypes = {};

export default PandaDocDetails;
