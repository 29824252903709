import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../../../images/common";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import JobStatus from "app/v2/Pages/common/Status/Job";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    width: "1340px",
    height: "396px",
    border: "1px solid #EDEDED",
    padding: "36px",
    marginBottom: "2px ",
    borderColor: "#ABABAB",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    width: "100%",
    overflowX: "auto",
    paddingBottom: "10px",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  hideActions: {
    display: "none",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const FeatureJobListCard = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();
  const [cardData, setCardData] = useState();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setCardData(data);
  }, [data]);

  const [isBookMarkClicked, setIsBookMarkClicked] = useState(false);

  const handleBookMarkClick = () => {
    setIsBookMarkClicked(!isBookMarkClicked);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx([classes.listContainer])}
    >
      <div
        style={{
          height: "89px",
          width: "1299px",
          display: "flex",
          margin: "0px 24px 0px 0px",
        }}
      >
        <img
          className={classes.orgImage}
          alt={`org_${cardData?._id}_image`}
          src={cardData?.image ? cardData.image : "/org_placeholder.svg"}
        />
        <div style={{ width: "988px", height: "79px" }}>
          <div
            style={{
              width: "auto",
              height: "29px",
              margin: "0px 0px 0px 19px ",
              fontSize: "24px",
              // fontFamily:"Plus Jakarta Sans",
              lineHeight: "28.80px",
              fontWeight: 600,
            }}
          >
            FrontEnd JavaScript Developer Needed
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "19px",
              fontSize: "14px",
              color: "#717171",
              lineHeight: "19.60px",
            }}
          >
            <div style={{ marginRight: "12px" }}>Posted Yesterday</div>
            <div style={{ marginRight: "12px" }}>
              Last updated today at 13:23
            </div>
            <div>Job no: 23147</div>
          </div>
          <div
            style={{
              display: "flex",
              marginLeft: "19px",
              fontSize: "14px",
              color: "#717171",
              lineHeight: "19.60px",
            }}
          >
            <div style={{ marginRight: "12px" }}>Hiring Manager</div>
            <div style={{ marginRight: "12px" }}>Mike Fitz</div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              margin: "0px 19px 0px 19px",
              width: "96px",
              height: "22px",
              border: "1px solid #CCCCCC",
              textAlign: "center",
              fontFamily: "Plus Jakarta Sans",
              fontWeight: 600,
              lineHeight: "19.2px",
              fontSize: "12px",
              color: "#363636",
            }}
          >
            Invited
          </div>
          <div
            style={{
              width: "69px",
              height: "22px",
              border: "1px solid #CCCCCC",
              textAlign: "center",
              backgroundColor: "#EBFFF0",
            }}
          >
            <JobStatus type="active" />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "24px",
          fontSize: "14px",
          color: "#717171",
          lineHeight: "19.60px",
          fontWeight: 600,
        }}
      >
        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>OPENPOSITIONS</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            15
          </div>
        </div>

        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>EXPERIENCE LEVEL</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            JUNIOR
          </div>
        </div>
        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>CONTRACT TYPE</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            PART TIME
          </div>
        </div>
        <div
          style={{
            width: "247px",
            height: "39px",
            color: "#717171",
            fontSize: "12px",
            fontWeight: 600,
          }}
        >
          <div>WORK TYPE</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            Hybrid
          </div>
        </div>
        <div style={{ width: "247px", height: "39px" }}>
          <div>salaryRange</div>
          <div style={{ fontSize: "18px", fontWeight: 700, color: "#0B0E1E" }}>
            $30k-$50k/yr
          </div>
        </div>
      </div>
      <div style={{ margin: "36px 36px 36px 0px", display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginRight: "79px",
              width: "127px",
              lineHeight: "14.40px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            MATCH RATE
          </div>
          <div
            style={{
              marginRight: "79px",
              width: "127px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            90%
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginRight: "79px",
              width: "223px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            LOCATION
          </div>
          <div
            style={{
              marginRight: "79px",
              width: "223px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
              wordWrap: "break-word",
            }}
          >
            SINGAPORE CITY,SINGAPORE
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              marginRight: "79px",
              width: "159px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            TIMEZONE
          </div>
          <div
            style={{
              marginRight: "79px",
              width: "159px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            SGST(GMT+8,0)
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              width: "245px",
              color: "#717171",
              fontSize: "12px",
              fontWeight: 600,
            }}
          >
            TECHNOLOGY SKILLS
          </div>
          <div
            style={{
              width: "245px",
              color: "#0B0E1E",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            REACTJS NODEJS ANGULAR
          </div>
        </div>

        <div></div>
      </div>

      <div>
        {isHovered && (
          <div style={{ display: "flex" }}>
            <button
              // onClick={() => {
              //   history.push(`/client/consultant/${cardData?._id}`);
              //   window.scrollTo(0, 0);
              // }}
              onClick={() => {
                history.push("/agency/job-detail");
              }}
              className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 self-stretch flex flex-row items-center justify-center gap-[10px] mr-4"
            >
              <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-white text-center">
                View Job Details
              </div>
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconlightup-right.svg"
              />
            </button>

            <button
              className={`cursor-pointer ${
                isBookMarkClicked
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={handleBookMarkClick}
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src={
                  isBookMarkClicked
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

FeatureJobListCard.propTypes = {};

export default FeatureJobListCard;
