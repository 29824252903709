import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  FormControl,
  FormHelperText,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Input,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import DatePicker from "react-datepicker";
import ContentLoader from "react-content-loader";
import clsx from "clsx";

import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

import Title from "../../common/Title";

import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomTooltip from "app/v2/components/CustomTooltip";

import DialogLoadingBody from "../../common/DialogLoadingBody";
import DialogSuccessBody from "../../common/DialogSuccessBody";
import DialogErrorBody from "../../common/DialogErrorBody";

import {
  isValidArray,
  isValidObject,
  isValidString,
} from "../../Utils/utilFunctions";
import { messages, validDocFileMimeTypes } from "../../Utils/constants";

import {
  addDocuments,
  deleteDocument,
  getActiveConsultants,
  updateDocuments,
  getDocumentTypes,
  createInvoice,
} from "app/v2/services/admin/invoices.service";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import { useSnackbar } from "notistack";
import { s3Upload } from "app/v2/services/miscService";
import useFetch from "app/common/utils/useFetch";
import FilePreview from "../../common/FilePreview";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomCheckbox from "app/v2/components/CustomCheckbox";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    fontSize: "1rem", // Adjust the font size as needed
    fontWeight: "bold",
    padding: "0.5rem", // Adjust the padding as needed
  },
  searchBoxOuterDiv: {
    maxWidth: "477px",
    width: "100%",
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
  },
  searchBox: {
    width: "100%",
  },
  currentlyPursuingCBLabel: {
    fontSize: "14px",
    fontWeight: "600",
    // color: "#717171",
  },
  tags: {
    width: "100%",
    // height: "90%",
  },
  userImage: {
    width: "36px",
    height: "36px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  consultantCell: {
    display: "flex !important",
    columnGap: "8px",
    alignItems: "center",
  },
  viewDetailsButton: {
    fontWeight: 600,
    fontSize: "14px",
  },
  paginationContainer: {
    float: "right",
    marginBottom: "16px",
    paddingRight: "38px",
  },
  actionMenu: {
    "& .MuiPaper-root": {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  actionMenuItem: {
    fontWeight: 600,
    fontSize: "14px",
  },
  dialogBody: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px 84px",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  dialogActionButton: {
    fontWeight: 600,
    padding: "16px 28px",
  },
  viewButton: {
    fontWeight: 600,
    textWrap: "nowrap",
  },
  dateOrTimePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  dateOrTimePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },

  tableHead: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: "#F7F7F7",
  },
  uploadButton: {
    backgroundColor: "#07121f !important",
    width: "fit-content",
    color: "white",
    padding: theme.spacing(1), // Adjust padding as needed
    fontSize: "16px", // Adjust font size as needed
  },
  submitUploadButton: {
    backgroundColor: "#07121f !important",

    fontWeight: 600,
    margin: "auto",
  },
  fileUploadInput: {
    display: "none",
  },
  uploadingButton: {
    marginLeft: theme.spacing(2),
  },
}));

const ActiveConsultants = (props) => {
  const classes = useStyles();
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getStartDateOfWeek = () => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const diff =
      currentDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1);
    const startOfWeek = new Date(currentDate.setDate(diff));

    return startOfWeek;
  };

  const getEndDateOfWeek = () => {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const diff =
      currentDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1);
    const startOfWeek = new Date(currentDate.setDate(diff));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return endOfWeek;
  };

  const consultantsPerScroll = 8;
  const singleColumns = ["Consultant", "Job", "Org"];
  const groupedTimeSheetColumns = ["Pay-as-you-go", "Milestone", "Fixed rate"];

  const [consultantsData, setConsultantsData] = useState({});
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);

  const [searchTextTags, setSearchTextTags] = useState([]);

  const [totalPagesCount, setTotalPagesCount] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  // #region action states
  const [actionMenuAnchor, setActionMenuAnchor] = useState({});
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionEntryData, setActionEntryData] = useState();
  const [startDate, setStartDate] = useState(getStartDateOfWeek());
  const [endDate, setEndDate] = useState(getEndDateOfWeek());
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  // const dummyData = [
  //   {
  //     _id: "1",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 1",
  //       profilePhoto: "",
  //       ID: "12345",
  //     },
  //     invoiceNo: "INV-2001",
  //     jobName: "ReactJS Developer-1",
  //     jobID: "1234",
  //     orgName: "FindPRO INC",
  //     amount: "500",
  //     startDate: "",
  //     endDate: "",
  //     status: "Paid",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  //   {
  //     _id: "2",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 2",
  //       profilePhoto: "",
  //     },
  //     invoiceNo: "INV-2002",
  //     jobName: "ReactJS Developer-2",
  //     jobID: "1235",
  //     orgName: "FindPRO INC",
  //     amount: "600",
  //     startDate: "",
  //     endDate: "",
  //     status: "Pending",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  //   {
  //     _id: "3",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 3",
  //       profilePhoto: "",
  //     },
  //     invoiceNo: "INV-2003",
  //     jobName: "ReactJS Developer-3",
  //     jobID: "1236",
  //     orgName: "FindPRO INC",
  //     amount: "",
  //     startDate: "",
  //     endDate: "",
  //     status: "Paid",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  //   {
  //     _id: "4",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 4",
  //       profilePhoto: "",
  //     },
  //     invoiceNo: "INV-2004",
  //     jobName: "ReactJS Developer-4",
  //     jobID: "1237",
  //     orgName: "FindPRO INC",
  //     amount: "",
  //     startDate: "",
  //     endDate: "",
  //     status: "Paid",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  //   {
  //     _id: "5",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 5",
  //       profilePhoto: "",
  //     },
  //     invoiceNo: "INV-2005",
  //     jobName: "ReactJS Developer-5",
  //     jobID: "1238",
  //     orgName: "FindPRO INC",
  //     amount: "",
  //     startDate: "",
  //     endDate: "",
  //     status: "Paid",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  //   {
  //     _id: "6",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 6",
  //       profilePhoto: "",
  //     },
  //     invoiceNo: "INV-2006",
  //     jobID: "1239",
  //     orgName: "FindPRO INC",
  //     amount: "",
  //     startDate: "",
  //     endDate: "",
  //     status: "Paid",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  //   {
  //     _id: "7",
  //     consultantDetails: {
  //       firstName: "Julia",
  //       lastName: "Miller 7",
  //       profilePhoto: "",
  //     },
  //     invoiceNo: "INV-2007",
  //     jobID: "1240",
  //     orgName: "FindPRO INC",
  //     amount: "",
  //     startDate: "",
  //     endDate: "",
  //     status: "Paid",
  //     timesheetTasksCount: {
  //       payAsYouGo: 5,
  //       milestone: 6,
  //       fixedRate: 7,
  //     },
  //   },
  // ];

  // const timeSheetDummyData = [
  //   {
  //     _id: "65d5c2a576d1f5f643faebe7",
  //     date: "2024-02-21T00:00:00.000Z",
  //     timesheetType: "Pay-as-you-go",
  //     hours: 12.05,
  //     dueDate: null,
  //     startTime: "7:59 PM",
  //     endTime: "8:02 AM",
  //     description: "task 567",
  //     amount: 0,
  //     status: "declined",
  //     statusReason: "",
  //     approvedOrDeclinedDateTime: "2024-02-22T06:30:58.858Z",
  //     createdAt: "2024-02-21T09:30:13.174Z",
  //     updatedAt: "2024-02-22T06:30:58.859Z",
  //   },
  //   {
  //     _id: "65d457a568fa9e8dc3583e1a",
  //     date: "2024-02-20T00:00:00.000Z",
  //     timesheetType: "Pay-as-you-go",
  //     hours: 3.07,
  //     dueDate: null,
  //     startTime: "6:11 PM",
  //     endTime: "9:15 PM",
  //     description: "task 234",
  //     amount: 0,
  //     status: "approved",
  //     statusReason: "",
  //     approvedOrDeclinedDateTime: "2024-02-22T06:42:56.477Z",
  //     createdAt: "2024-02-20T07:41:25.909Z",
  //     updatedAt: "2024-02-22T06:42:56.477Z",
  //   },
  // ];

  const getFullName = (consultantDetails) => {
    const firstName = consultantDetails?.firstName;
    const lastName = consultantDetails?.lastName;
    let fullName = "";
    if (firstName) fullName += firstName + " ";
    if (lastName) fullName += lastName;
    return fullName;
  };
  const getDateOutput = (dateString) => {
    if (isValidString(dateString)) {
      let localDateString = new Date(dateString).toLocaleDateString();
      localDateString =
        localDateString === "Invalid Date" ? "" : localDateString;
      return localDateString;
    }
    return "-";
  };

  const ViewTimeSheetDialog = ({ actionEntryData }) => {
    const columns = {
      payAsYouGo: [
        "Description",
        "Date",
        "Start Time",
        "End time",
        "Hours",
        "Status",
      ],
      milestone: ["Description", "Date", "Due date", "Amount", "Status"],
      fixedRate: ["Description", "Date", "Amount", "Status"],
    };
    const timesheetType = actionEntryData?.timesheetType;
    let timesheetTypeData = null;

    switch (timesheetType) {
      case "payAsYouGo":
        timesheetTypeData = actionEntryData?.timesheet["pay-as-you-go"];
        break;
      case "milestone":
        timesheetTypeData = actionEntryData?.timesheet.milestone;
        break;
      case "fixedRate":
        timesheetTypeData = actionEntryData?.timesheet.fixedrate;
        break;
      default:
        break;
    }

    return (
      <>
        {isValidArray(timesheetTypeData) ? (
          <>
            <TableContainer
              sx={{
                width: "auto",
                maxHeight: 440,
                margin: "0 24px 24px 24px",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns[timesheetType].map((column, index) => (
                      <TableCell
                        key={`single-column-${index}`}
                        sx={{
                          backgroundColor: "#F7F7F7",
                          fontWeight: 600,
                        }}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {timesheetTypeData && timesheetTypeData.length > 0 ? (
                    timesheetTypeData.map((timeSheetEntry) => (
                      <React.Fragment key={timeSheetEntry?._id}>
                        {isValidObject(timeSheetEntry) ? (
                          <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                            <TableCell>
                              {timeSheetEntry?.description ?? ""}
                            </TableCell>
                            {timesheetType === "payAsYouGo" && (
                              <>
                                <TableCell>
                                  {getDateOutput(timeSheetEntry?.date)}
                                </TableCell>
                                <TableCell>
                                  {timeSheetEntry?.startTime}
                                </TableCell>
                                <TableCell>{timeSheetEntry?.endTime}</TableCell>
                                <TableCell>{timeSheetEntry?.hours}</TableCell>
                                <TableCell>{timeSheetEntry?.status}</TableCell>
                              </>
                            )}

                            {timesheetType === "milestone" && (
                              <>
                                <TableCell>
                                  {getDateOutput(timeSheetEntry?.date)}
                                </TableCell>
                                <TableCell>
                                  {getDateOutput(timeSheetEntry?.dueDate)}
                                </TableCell>
                                <TableCell>{timeSheetEntry?.amount}</TableCell>
                                <TableCell>{timeSheetEntry?.status}</TableCell>
                              </>
                            )}
                            {timesheetType === "fixedRate" && (
                              <>
                                <TableCell>
                                  {getDateOutput(timeSheetEntry?.date)}
                                </TableCell>

                                <TableCell>{timeSheetEntry?.amount}</TableCell>
                                <TableCell>{timeSheetEntry?.status}</TableCell>
                              </>
                            )}
                          </TableRow>
                        ) : (
                          <React.Fragment />
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>No data available</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Pagination
                        color="primary"
                        count={totalPagesCount}
                        page={currentPage}
                        onChange={handlePageChange}
                        classes={{ ul: classes.paginationContainer }}
                      /> */}
          </>
        ) : (
          <Alert severity="info">{messages.NO_DATA_FOUND}</Alert>
        )}
      </>
    );
  };

  const CreateInvoiceDialog = ({
    actionEntryData,
    setActionLoading,
    setActionError,
    setActionCompleted,
    setDialogOpen,
    setStatusMessage,
  }) => {
    const activeconsultantId = actionEntryData?._id;
    const clientId = actionEntryData?.orgId;
    const consultantId = actionEntryData?.consultantId;
    const jobId = actionEntryData?.jobId;

    const onYesClick = () => {
      setActionLoading(true);

      // const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
      //   variant: "info",
      //   autoHideDuration: 3000,
      //   action: (key) => (
      //     <CloseIcon
      //       onClick={() => closeSnackbar(key)}
      //       style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
      //     />
      //   ),
      // });
      const requestBody = {
        jobId: jobId,
        userId: consultantId,
        userType: "consultant",
        startDate: startDate,
        endDate: endDate,
        currency: "$",
        paymentMethod: "PayPal",
        notes: "",
        billingAddress: `${actionEntryData?.settings?.invoiceSettings?.billingAddress.address}, ${actionEntryData?.settings?.invoiceSettings?.billingAddress.city}`,
        //"invoiceNumber": "INV00002", // Calculat it in backend
        billingFrequency:
          actionEntryData?.settings?.invoiceSettings?.clientInvoiceContact
            ?.invoiceFrequency,
        termsAndConditions: "Lorem ipsum",
        // discounts: {
        //   rate: 1,
        //   amount: 10,
        // },
        latePaymentFees: 0,
        projectDescription: "Lorem ipsum",
        invoiceContactInformation: {
          name: actionEntryData?.settings?.invoiceSettings?.clientInvoiceContact
            ?.fullName,
          email:
            actionEntryData?.settings?.invoiceSettings?.clientInvoiceContact
              ?.email,
          phone:
            actionEntryData?.settings?.invoiceSettings?.clientInvoiceContact
              ?.phone,
          address: "Lorem Ipsum",
        },
      };
      createInvoice(requestBody)
        .then((res) => {
          setTimeout(() => {
            if (res.success) {
              setActionLoading(false);
              setActionCompleted(true);
            }
          }, 2000);
        })
        .catch((err) => {
          setTimeout(() => {
            setActionLoading(false);
            setActionError(true);
            if (err?.message) {
              setStatusMessage(err?.message);
            } else {
              setStatusMessage(
                "There is something wrong please try again after some time"
              );
            }
          }, 2000);
        });
    };
    const onNoClick = () => {
      setDialogOpen(false);
    };
    const classes = useStyles();
    return (
      <>
        <div>
          Do you want to create an invoice for all approved timesheets of{" "}
          <strong>{actionEntryData?.consultants?.name}</strong> ?
        </div>
        <div>
          <span>For the chosen date range from </span>
          <strong>{startDate.toDateString()}</strong> -{" "}
          <strong>{endDate.toDateString()}</strong> ?
        </div>

        <div className="flex gap-y-[8px] justify-end mt-[16px]">
          <CustomButton
            customClasses={classes.dialogActionButton}
            onClick={onNoClick}
          >
            No
          </CustomButton>

          <CustomButton
            type="button1"
            customClasses={classes.dialogActionButton}
            onClick={() => {
              onYesClick(actionEntryData?._id);
            }}
          >
            Yes
          </CustomButton>
        </div>
      </>
    );
  };

  // const useStyles = makeStyles((theme) => ({
  //   fileUploadInput: {
  //     display: 'none',
  //   },
  //   uploadButton: {
  //     marginLeft: theme.spacing(2),
  //   },
  // }));

  const UploadDocDialog = ({
    actionEntryData,
    setActionLoading,
    setDialogOpen,
  }) => {
    const classes = useStyles();

    const uploadRef = useRef(null);
    const [searchTextTags, setSearchTextTags] = useState([]);
    const [get] = useFetch();
    const [contractDialogOpen, setContractDialogOpen] = useState(false);
    const [actionType, setActionType] = useState("");
    const [updateContractDetails, setUpdateContractDetails] = useState({});

    const contractColumns = [
      "FileName",
      "DocumentType",
      "File",
      "Visibility",
      "Tags",
      "Action",
    ];
    const dummyContractData = [
      {
        id: "123456789",
        fileName: "Contract-1",
        file: "texctDocument.pdf",
        tags: ["React", "Node"],
        visibility: ["client", "consultant"],
      },
      {
        id: "000000000",

        fileName: "Contract-2",
        file: "texctDocument2.pdf",
        tags: ["React", "Node"],
        visibility: ["client", "consultant,agency"],
      },
      {
        id: "78678678",

        fileName: "Contract-3",
        file: "texctDocument3.pdf",
        tags: ["React", "Node", "tag3", "tag4", "tag5"],
        visibility: ["client", "consultant,agency"],
      },
    ];

    // const documentTypeData=["Contract" ,"Aggrement","Employee","HandBook","Id"]

    const AddUpdateContract = ({ selectedContractId, actionType }) => {
      const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
      const [documentType, setDocumentType] = useState("");

      const [filename, setFilename] = useState(
        updateContractDetails?.fileName ?? ""
      );
      const [tags, setTags] = useState(updateContractDetails?.tags ?? []);
      const [visibilityOptions, setVisibilityOptions] = useState(
        updateContractDetails?.visibility ?? []
      );
      const [selectedFile, setSelectedFile] = useState(
        updateContractDetails?.file ?? ""
      );
      const [isSubmitDisabled, setSubmitDisabled] = useState(true);
      const handleCheckboxChange = (value) => {
        const updatedCheckboxes = [...selectedCheckboxes];
        const index = updatedCheckboxes.indexOf(value);

        if (index === -1) {
          updatedCheckboxes.push(value);
        } else {
          updatedCheckboxes.splice(index, 1);
        }

        setSelectedCheckboxes(updatedCheckboxes);
      };

      const handleVisibilityCheckboxChange = (option) => {
        setVisibilityOptions((prevOptions) => {
          if (prevOptions.includes(option)) {
            return prevOptions.filter((item) => item !== option);
          } else {
            return [...prevOptions, option];
          }
        });
      };

      const onFileUploadToS3 = async (file) => {
        let s3UploadedFileDetails;
        let errorInUpload = false;
        const formData = new FormData();
        formData.append("file", file);
        try {
          s3UploadedFileDetails = await get(s3Upload(formData));
        } catch (err) {
          errorInUpload = true;
        }

        return {
          uploadedUrl: s3UploadedFileDetails?.[0]?.location,
          errorInUpload,
        };
      };

      const handleDocumentType = (id, value) => {
        setDocumentType(value);
      };
      const handleFileUpload = async (event) => {
        let file = event.target.files[0];

        if (file) {
          // const localFileUrl = URL.createObjectURL(file);
          const higherFileSize = file.size > 1048576 * 5; // 5 mb
          const isValidDoc = validDocFileMimeTypes.includes(file.type);
          if (higherFileSize || !isValidDoc) {
            // enqueueSnackbar(
            // higherFileSize
            //   ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
            //   : messages.RESUME_DOC_UPLOAD_EXTENSION_WARNING,
            //   {
            //     variant: "error",
            //   }
            // );

            const key = enqueueSnackbar(
              higherFileSize
                ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
                : messages.RESUME_DOC_UPLOAD_EXTENSION_WARNING,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
            return;
          } else {
            let result = await onFileUploadToS3(file);
            setSelectedFile(result?.uploadedUrl);
          }
        }
      };

      const handleCancelUpload = () => {
        setSelectedFile("");
        if (uploadRef.current) {
          uploadRef.current.value = null;
        }
      };

      const handleFilenameChange = (id, value) => {
        setFilename(value);
      };

      const handleTagsChange = (id, value) => {
        setTags(value);
      };

      useEffect(() => {
        const isFormValid =
          // selectedCheckboxes.length > 0 &&
          filename.trim() !== "" &&
          tags.length > 0 &&
          visibilityOptions.length > 0 &&
          selectedFile !== "" &&
          (actionType === "add" ? documentType !== "" : true);

        setSubmitDisabled(!isFormValid);
      }, [
        documentType,
        selectedCheckboxes,
        filename,
        tags,
        visibilityOptions,
        selectedFile,
      ]);

      useEffect(() => {
        setSubmitDisabled(true);
      }, []);

      const handleSubmission = () => {
        // const formData = new FormData();

        // // Add selected checkboxes to formData
        // selectedCheckboxes.forEach((checkbox) => {
        //   formData.append("selectedCheckboxes", checkbox);
        // });

        // // Add other data to formData
        // formData.append("filename", filename);
        // formData.append("tags", tags);

        // // Add visibility options to formData
        // visibilityOptions.forEach((option) => {
        //   formData.append("visibilityOptions[]", option);
        // });

        // // Add the selected file to formData
        // if (selectedFile) {
        //   formData.append("file", selectedFile);
        // }

        const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
          variant: "info",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });

        const activeconsultantId = actionEntryData?._id;
        const clientId = actionEntryData?.orgId;
        const consultantId = actionEntryData?.consultantId;

        const selectedIds = [];

        if (selectedCheckboxes.includes("client")) {
          selectedIds.push(clientId);
        }

        if (selectedCheckboxes.includes("consultant")) {
          selectedIds.push(consultantId);
        }

        if (selectedCheckboxes.includes("clientConsultant")) {
          selectedIds.push(clientId, consultantId);
        }
        if (selectedCheckboxes.includes("consultantAgency")) {
          selectedIds.push(clientId, consultantId);
        }

        const requestBody = {
          documentType: documentType,
          file: selectedFile || "",
          fileName: filename,
          tags: tags,
          activeWorkId: activeconsultantId,
          // userIds: selectedIds,
          userIds: [clientId, consultantId],

          visibility: visibilityOptions,
        };
        addDocuments(requestBody)
          .then((res) => {
            if (res?.success === true) {
              const key = enqueueSnackbar("Your action was successfull", {
                variant: "success",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
              setSubmitDisabled(true);
              setDialogOpen(false);
              setCurrentPage(1);
              fetchConsultants(1);
            }
          })
          .catch(() => {
            const key = enqueueSnackbar(
              `Your action was not successful. Please try again later.`,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          });
      };

      const handleUpdateDocument = (documentId) => {
        // const formData = new FormData();

        // // Add selected checkboxes to formData
        // selectedCheckboxes.forEach((checkbox) => {
        //   formData.append("selectedCheckboxes", checkbox);
        // });

        // // Add other data to formData
        // formData.append("filename", filename);
        // formData.append("tags", tags);

        // // Add visibility options to formData
        // visibilityOptions.forEach((option) => {
        //   formData.append("visibilityOptions[]", option);
        // });

        // // Add the selected file to formData
        // if (selectedFile) {
        //   formData.append("file", selectedFile);
        // }

        const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
          variant: "info",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });

        const activeconsultantId = actionEntryData?._id;
        const clientId = actionEntryData?.orgId;
        const consultantId = actionEntryData?.consultantId;

        const selectedIds = [];

        if (selectedCheckboxes.includes("client")) {
          selectedIds.push(clientId);
        }

        if (selectedCheckboxes.includes("consultant")) {
          selectedIds.push(consultantId);
        }

        if (selectedCheckboxes.includes("clientConsultant")) {
          selectedIds.push(clientId, consultantId);
        }
        if (selectedCheckboxes.includes("consultantAgency")) {
          selectedIds.push(clientId, consultantId);
        }

        const requestBody = {
          file: selectedFile || "",
          fileName: filename,
          tags: tags,
          // activeWorkId: activeconsultantId,
          // userIds: selectedIds,
          userIds: [clientId, consultantId],

          visibility: visibilityOptions,
          documentType: documentType,
        };
        updateDocuments(requestBody, documentId)
          .then((res) => {
            if (res?.success === true) {
              const key = enqueueSnackbar("Your action was successfull", {
                variant: "success",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
              setSubmitDisabled(true);
              setDialogOpen(false);
              setContractDialogOpen(false);
              setCurrentPage(1);
              fetchConsultants(1);
            }
          })
          .catch(() => {
            setContractDialogOpen(false);
            const key = enqueueSnackbar(
              `Your action was not successful. Please try again later.`,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          });
      };

      const handleDeleteDocument = (documentId) => {
        const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
          variant: "info",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });

        const activeconsultantId = actionEntryData?._id;
        const clientId = actionEntryData?.orgId;
        const consultantId = actionEntryData?.consultantId;

        // const requestBody = {
        //   file: selectedFile || "",
        //   fileName: filename,
        //   tags: tags,
        //   activeWorkId: activeconsultantId,
        //   // userIds: selectedIds,
        //   userIds: [clientId, consultantId],

        //   visibility: visibilityOptions,
        // };
        deleteDocument(documentId)
          .then((res) => {
            if (res?.success === true) {
              const key = enqueueSnackbar("Your action was successfull", {
                variant: "success",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
              setDialogOpen(false);
              setCurrentPage(1);
              fetchConsultants(1);
            }
          })
          .catch(() => {
            setDialogOpen(false);

            const key = enqueueSnackbar(
              `Your action was not successful. Please try again later.`,
              {
                variant: "error",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              }
            );
          });
      };

      return (
        <div className="flex flex-col gap-y-[16px] flex-wrap ">
          {actionType === "delete" && (
            <>
              Are you sure you want to delete
              {getFullName(actionEntryData?.consultantDetails)} contract
              <div className="flex gap-y-[8px] justify-end mt-[16px]">
                <CustomButton
                  customClasses={classes.dialogActionButton}
                  onClick={() => {
                    setContractDialogOpen(false);
                    setActionType("");
                  }}
                >
                  No
                </CustomButton>

                <CustomButton
                  type="button1"
                  customClasses={classes.dialogActionButton}
                  onClick={() => {
                    handleDeleteDocument(updateContractDetails?._id);
                    setActionType("");
                  }}
                >
                  Yes
                </CustomButton>
              </div>
            </>
          )}{" "}
          {actionType === "update" && (
            <>
              <CustomSelect
                id="documentType"
                name="documentType"
                data={documentTypeData}
                label="Document Type"
                // value={documentType}
                onChange={handleDocumentType}
                // defaultSelection={}
              />
              <div className=" flex  justify-center">
                <input
                  type="file"
                  accept=".pdf, .txt"
                  className={classes.fileUploadInput}
                  onChange={handleFileUpload}
                  ref={uploadRef}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploadButton}
                  onClick={() => uploadRef.current.click()}
                >
                  <div>Upload File</div>
                  <FileUploadOutlinedIcon fontSize="small" />
                </Button>
              </div>
              <div className="flex justify-center">
                {selectedFile && (
                  <div className="flex  flex-col items-center gap-x-2">
                    <CloseIcon
                      style={{ cursor: "pointer", marginLeft: "auto" }}
                      onClick={handleCancelUpload}
                    />
                    <div>
                      {" "}
                      <FilePreview fileUrlOrContent={selectedFile} />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex space-x-2">
                <div className="w-full ">
                  <CustomTextfield
                    id="fileName"
                    label="Filename"
                    value={filename}
                    onChange={handleFilenameChange}
                  />
                </div>
                <div className="w-full h-full">
                  <label className="text-xs font-montserrat font-semibold  uppercase">
                    Tags
                  </label>
                  <CustomAutocomplete
                    id="tags"
                    data={tags}
                    placeholder="Tags"
                    defaultSelection={tags}
                    allowMultipleSelection={true}
                    allowCustomUserInput={true}
                    onChange={(e, val) => {
                      setTags(val);
                    }}
                    customClasses={classes.tags}
                    // showSearchIcon={true}
                  />
                </div>
              </div>

              {/* Visibility Checkboxes */}
              <label className="text-xl font-bold">Visibility:</label>

              <div className=" ">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibilityOptions.includes("client")}
                      onChange={() => handleVisibilityCheckboxChange("client")}
                    />
                  }
                  label="Client"
                  classes={{ label: classes.checkboxLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibilityOptions.includes("consultant")}
                      onChange={() =>
                        handleVisibilityCheckboxChange("consultant")
                      }
                    />
                  }
                  label="Consultant"
                  classes={{ label: classes.checkboxLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibilityOptions.includes("agency")}
                      onChange={() => handleVisibilityCheckboxChange("agency")}
                    />
                  }
                  label="Agency"
                  classes={{ label: classes.checkboxLabel }}
                />
              </div>

              {/* Update Button */}
              <div className="flex justify-center">
                <CustomButton
                  type="button1"
                  customClasses={`text-[16px] font-semibold py-2 px-2 flex gap-x-[8px] ${
                    isSubmitDisabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }`}
                  onClick={() =>
                    handleUpdateDocument(updateContractDetails?._id)
                  }
                  disabled={isSubmitDisabled}
                >
                  Update{" "}
                </CustomButton>
              </div>
            </>
          )}
          {actionType === "add" && (
            <>
              <CustomSelect
                id="documentType"
                name="documentType"
                data={documentTypeData}
                label="Document Type"
                // value={documentType}
                onChange={handleDocumentType}
                // defaultSelection={}
              />
              <div className=" flex  justify-center">
                <input
                  type="file"
                  accept=".pdf, .txt"
                  className={classes.fileUploadInput}
                  onChange={handleFileUpload}
                  ref={uploadRef}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploadButton}
                  onClick={() => uploadRef.current.click()}
                >
                  <div>Upload File</div>
                  <FileUploadOutlinedIcon fontSize="small" />
                </Button>
              </div>
              <div className="flex justify-center">
                {selectedFile && (
                  <div className="flex  flex-col items-center gap-x-2">
                    <CloseIcon
                      style={{ cursor: "pointer", marginLeft: "auto" }}
                      onClick={handleCancelUpload}
                    />
                    <div>
                      {" "}
                      <FilePreview fileUrlOrContent={selectedFile} />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex space-x-2">
                <div className="w-full ">
                  <CustomTextfield
                    id="fileName"
                    label="Filename"
                    value={filename}
                    onChange={handleFilenameChange}
                  />
                </div>
                <div className="w-full h-full">
                  <label className="text-xs font-montserrat font-semibold  uppercase">
                    Tags
                  </label>
                  <CustomAutocomplete
                    id="tags"
                    data={tags}
                    placeholder="Tags"
                    defaultSelection={tags}
                    allowMultipleSelection={true}
                    allowCustomUserInput={true}
                    onChange={(e, val) => {
                      setTags(val);
                    }}
                    customClasses={classes.tags}
                    // showSearchIcon={true}
                  />
                </div>
              </div>

              {/* Visibility Checkboxes */}
              <label className="text-xl font-bold">Visibility:</label>

              <div className=" ">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibilityOptions.includes("client")}
                      onChange={() => handleVisibilityCheckboxChange("client")}
                    />
                  }
                  label="Client"
                  classes={{ label: classes.checkboxLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibilityOptions.includes("consultant")}
                      onChange={() =>
                        handleVisibilityCheckboxChange("consultant")
                      }
                    />
                  }
                  label="Consultant"
                  classes={{ label: classes.checkboxLabel }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={visibilityOptions.includes("agency")}
                      onChange={() => handleVisibilityCheckboxChange("agency")}
                    />
                  }
                  label="Agency"
                  classes={{ label: classes.checkboxLabel }}
                />
              </div>

              {/* Submit Button */}
              <div className="flex justify-center">
                <CustomButton
                  type="button1"
                  customClasses={`text-[16px] font-semibold py-2 px-2 flex gap-x-[8px] ${
                    isSubmitDisabled
                      ? "cursor-not-allowed opacity-50"
                      : "cursor-pointer"
                  }`}
                  onClick={handleSubmission}
                  disabled={isSubmitDisabled}
                >
                  Add
                </CustomButton>
              </div>
            </>
          )}
        </div>
      );
    };

    return (
      <div className="flex flex-col gap-y-[16px] flex-wrap ">
        <div className="flex justify-between ">
          <h2 className="text-xl font-bold ">List of Documents</h2>
          <CustomButton
            onClick={() => {
              setContractDialogOpen(true);
              setActionType("add");
              setUpdateContractDetails({});
            }}
            type="button1"
            customClasses=" py-2 mb-2 mx-auto w-full !important "
            // disabled={isSubmitDisabled}
          >
            Add new document
          </CustomButton>
        </div>
        <TableContainer
          sx={{
            width: "auto",
            maxHeight: 440,
            margin: "0 24px 24px 24px",
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {contractColumns.map((column, index) => (
                  <TableCell
                    key={`single-column-${index}`}
                    sx={{
                      backgroundColor: "#F7F7F7",
                      fontWeight: 600,
                    }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {actionEntryData &&
            actionEntryData?.contractsDocuments?.length > 0 ? (
              actionEntryData?.contractsDocuments?.map((contractValues) => (
                <React.Fragment key={contractValues?._id}>
                  {isValidObject(contractValues) ? (
                    <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                      <TableCell>{contractValues?.fileName ?? "-"}</TableCell>
                      <TableCell>
                        {contractValues?.documentType ?? "-"}
                      </TableCell>

                      <TableCell>
                        {`${contractValues?.fileName.substring(
                          0,
                          10
                        )}.${contractValues?.file
                          .split("/")
                          .pop()
                          .split(".")
                          .pop()}`}
                      </TableCell>

                      <TableCell>
                        {contractValues?.visibility &&
                          contractValues.visibility.join(", ")}
                      </TableCell>
                      <TableCell>
                        {contractValues?.tags &&
                        contractValues.tags.length > 3 ? (
                          <Tooltip title={contractValues.tags.join(", ")}>
                            <span>
                              {contractValues.tags.slice(0, 3).join(", ")}
                              {contractValues.tags.length > 3 ? "..." : ""}
                            </span>
                          </Tooltip>
                        ) : (
                          contractValues?.tags && contractValues.tags.join(", ")
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-row space-x-2">
                          <CustomButton
                            onClick={() => {
                              setContractDialogOpen(true);
                              setActionType("update");
                              setUpdateContractDetails(contractValues);
                            }}
                            type="button1"
                            customClasses=" py-2 mb-2 mx-auto w-full !important "
                            // disabled={isSubmitDisabled}
                          >
                            Update
                          </CustomButton>
                          <CustomButton
                            onClick={() => {
                              setActionType("delete");
                              setContractDialogOpen(true);
                              setUpdateContractDetails(contractValues);
                            }}
                            type="button1"
                            customClasses=" py-2 mb-2 mx-auto w-full !important "
                            // disabled={isSubmitDisabled}
                          >
                            Delete
                          </CustomButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <React.Fragment />
                  )}
                </React.Fragment>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>No data available</TableCell>
              </TableRow>
            )}
          </Table>
        </TableContainer>
        {/* <label className="text-xl font-bold">Document Type:</label> */}
        {/* Checkboxes */}
        {/* <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckboxes.includes("client")}
                onChange={() => handleCheckboxChange("client")}
              />
            }
            label="Client"
            classes={{ label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckboxes.includes("consultant")}
                onChange={() => handleCheckboxChange("consultant")}
              />
            }
            label="Consultant"
            classes={{ label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckboxes.includes("agency")}
                onChange={() => handleCheckboxChange("agency")}
              />
            }
            label="Agency"
            classes={{ label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckboxes.includes("clientConsultant")}
                onChange={() => handleCheckboxChange("clientConsultant")}
              />
            }
            label="Client and Consultant"
            classes={{ label: classes.checkboxLabel }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedCheckboxes.includes("consultantAgency")}
                onChange={() => handleCheckboxChange("consultantAgency")}
              />
            }
            label="Consultant and Agency"
            classes={{ label: classes.checkboxLabel }}
          />
        </div> */}
        <CustomDialog
          open={contractDialogOpen}
          setOpen={setContractDialogOpen}
          // noCloseAction={contractActionLoading}
          title={
            <div className="text-[16px] font-semibold text-center">
              {actionType === "add" && "Add Document"}
              {actionType === "update" && "Update Document"}
              {actionType === "delete" && "Delete Document"}
            </div>
          }
          content={
            <div className={classes.dialogBody}>
              <AddUpdateContract
                selectedContractId={""}
                actionType={actionType}
              />
            </div>
          }
        />{" "}
      </div>
    );
  };

  const ActionDialog = ({
    dialogOpen,
    setDialogOpen,
    actionType,
    actionEntryData,
  }) => {
    const classes = useStyles();

    const dialogTitle = {
      viewTimesheet:
        actionEntryData?.timesheetType === "payAsYouGo"
          ? "Pay-as-you-go timesheet"
          : actionEntryData?.timesheetType === "milestone"
          ? "Milestone timesheet"
          : "Fixed rate timesheet ",
      createInvoice: "Create Invoice",
      uploadDocument: "Upload Documents",
      payAsYouGo: "Pay as you go timesheet",
    };

    const successActionMessages = {
      createInvoice: "You have created the invoice successfully.",
      uploadDocument: "You have uploaded the document successfully.",
    };

    const isViewTimesheetAction = actionType === "viewTimesheet";
    const isCreateInvoiceAction = actionType === "createInvoice";
    const isUploadAction = actionType === "uploadDocument";

    const [actionLoading, setActionLoading] = useState(false);
    const [actionError, setActionError] = useState(false);
    const [actionCompleted, setActionCompleted] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");

    const Title = () => {
      return (
        <div className="text-[16px] font-semibold text-center">
          {actionLoading || actionError || actionCompleted ? (
            <>
              {actionLoading && "Action in progress"}
              {actionError && "Error"}
              {actionCompleted && `${dialogTitle[actionType]}`}
            </>
          ) : (
            <>{dialogTitle[actionType]}</>
          )}
        </div>
      );
    };

    const DialogBody = () => {
      if (actionLoading) return <DialogLoadingBody />;
      else if (actionCompleted)
        return (
          <DialogSuccessBody
            content={{
              title: successActionMessages[actionType],
            }}
          />
        );
      else if (actionError)
        return <DialogErrorBody content={{ title: statusMessage }} />;
      else
        return (
          <div className={classes.dialogBody}>
            {isViewTimesheetAction ? (
              <ViewTimeSheetDialog actionEntryData={actionEntryData} />
            ) : (
              <></>
            )}
            {isCreateInvoiceAction ? (
              <CreateInvoiceDialog
                actionEntryData={actionEntryData}
                setActionLoading={setActionLoading}
                setActionError={setActionError}
                setActionCompleted={setActionCompleted}
                setDialogOpen={setDialogOpen}
                setStatusMessage={setStatusMessage}
              />
            ) : (
              <></>
            )}
            {isUploadAction ? (
              <UploadDocDialog
                actionEntryData={actionEntryData}
                setActionLoading={setActionLoading}
                setDialogOpen={setDialogOpen}
              />
            ) : (
              <></>
            )}
          </div>
        );
    };

    return (
      <CustomDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        noCloseAction={actionLoading}
        title={<Title />}
        content={<DialogBody />}
      />
    );
  };

  const fetchConsultants = async (currentPage) => {
    console.log({ currentPage });
    setDataLoading(true);

    const data = {
      limit: consultantsPerScroll,
      offset: currentPage - 1,
      filter: searchTextTags,
      startDate: startDate,
      endDate: endDate,
      hideNullEntries: checkBoxValue,
    };
    getActiveConsultants(data)
      .then((response) => {
        let invoicesReceived = response?.data;
        if (isValidArray(invoicesReceived)) {
          const totalClientCount = response?.count;
          const pageCount =
            totalClientCount > 0
              ? Math.ceil(totalClientCount / consultantsPerScroll)
              : 0;
          setConsultantsData(invoicesReceived);
          setTotalPagesCount(pageCount);
        }
        setDataLoading(false);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  };

  const handleColumnMenuOpen = (event, data) => {
    const actionEntryData = JSON.parse(JSON.stringify(data));
    setActionMenuAnchor({ [actionEntryData._id]: event.currentTarget });
    setActionEntryData(actionEntryData);
  };

  const handleColumnMenuClose = () => {
    setActionMenuAnchor({});
  };

  const onActionClick = (actionType, data) => {
    setActionType(actionType);
    if (data) setActionEntryData(data);
    setActionDialogOpen(true);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchConsultants(value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleSubmitOfDateRange = () => {
    setCurrentPage(1);
    fetchConsultants(1);
  };
  const handleCheckBox = () => {
    setCheckBoxValue((prev) => !prev);
  };

  useEffect(() => {
    fetchConsultants(currentPage);
  }, []);

  useEffect(() => {
    getDocumentTypes().then((res) => {
      const values = Object.values(res?.data);
      setDocumentTypeData(values);
    });
  }, []);

  return (
    <div>
      <Title title="Active Consultants" />
      <>
        {dataError ? (
          <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
        ) : (
          <>
            <div className="flex justify-between items-center m-[24px]">
              <div>
                <div className="flex gap-x-[16px] items-center">
                  <div className="flex space-x-6 mb-4 mt-4 z-10">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <div className="flex space-x-2">
                        <DatePicker
                          value={startDate}
                          onChange={handleStartDateChange}
                          placeholderText={`Select start date`}
                          selected={startDate}
                        />
                        <DatePicker
                          // label="End Date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          placeholderText={`Select end date`}
                          selected={endDate}
                          minDate={startDate}
                        />
                      </div>
                    </LocalizationProvider>

                    <CustomCheckbox
                      label="DZT"
                      id="hideNullEntries"
                      checked={checkBoxValue}
                      // size="small"
                      customClasses={{ label: classes.currentlyPursuingCBLabel }}

                      onChange={handleCheckBox}
                    />
                    <CustomButton
                      onClick={handleSubmitOfDateRange}
                      type="button1"
                      customClasses=" py-2 w-[130px] h-full "
                      // disabled={isSubmitDisabled}
                    >
                      Submit
                    </CustomButton>
                  </div>
                </div>
              </div>
              <div className={classes.searchBoxOuterDiv}>
                <CustomAutocomplete
                  id="searchTags"
                  data={searchTextTags}
                  placeholder="Search"
                  defaultSelection={searchTextTags}
                  allowMultipleSelection={true}
                  allowCustomUserInput={true}
                  onChange={(e, val) => {
                    setSearchTextTags(val);
                  }}
                  customClasses={classes.searchBox}
                  // showSearchIcon={true}
                />
                <SearchIcon
                  onClick={() => {
                    setCurrentPage(1);
                    fetchConsultants(1);
                  }}
                  className="cursor-pointer ml-[8px]"
                />
                <CustomTooltip title="Note: For the search field, press the 'Enter' key to create the tag after entering it.">
                  <div>
                    <InfoIcon />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            {dataLoading ? (
              <ContentLoader
                speed={2}
                width={1440}
                height={800}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="20" y="0" rx="3" ry="3" width="1440" height="700" />
              </ContentLoader>
            ) : (
              <>
                {isValidArray(consultantsData) ? (
                  <>
                    <TableContainer
                      sx={{
                        width: "auto",
                        maxHeight: 440,
                        margin: "0 24px 24px 24px",
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{
                                backgroundColor: "#F7F7F7",
                                fontWeight: 600,
                                borderBottom: "none",
                              }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{
                                backgroundColor: "#F7F7F7",
                                fontWeight: 600,
                                borderBottom: "none",
                                borderLeft: "1px solid #EDEDED",
                              }}
                            >
                              View TimeSheets
                            </TableCell>
                            <TableCell
                              align="center"
                              colSpan={3}
                              sx={{
                                backgroundColor: "#F7F7F7",
                                fontWeight: 600,
                                borderBottom: "none",
                                borderLeft: "1px solid #EDEDED",
                              }}
                            >
                              Number of Approved TimeSheets
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "#F7F7F7",
                                fontWeight: 600,
                                borderBottom: "none",
                                borderLeft: "1px solid #EDEDED",
                              }}
                            ></TableCell>
                          </TableRow>
                          <TableRow>
                            {singleColumns.map((column, index) => (
                              <TableCell
                                key={`single-column-${index}`}
                                sx={{
                                  backgroundColor: "#F7F7F7",
                                  fontWeight: 600,
                                }}
                              >
                                {column}
                              </TableCell>
                            ))}
                            {groupedTimeSheetColumns.map((column, index) => (
                              <TableCell
                                key={`all-timesheet-column-${index}`}
                                sx={{
                                  backgroundColor: "#F7F7F7",
                                  fontWeight: 600,
                                  borderLeft:
                                    index === 0 ? "1px solid #EDEDED" : "",
                                }}
                              >
                                {column}
                              </TableCell>
                            ))}
                            {groupedTimeSheetColumns.map((column, index) => (
                              <TableCell
                                key={`approved-timesheet-column-${index}`}
                                sx={{
                                  backgroundColor: "#F7F7F7",
                                  fontWeight: 600,
                                  borderLeft:
                                    index === 0 ? "1px solid #EDEDED" : "",
                                  borderRight:
                                    index === groupedTimeSheetColumns.length - 1
                                      ? "1px solid #EDEDED"
                                      : "",
                                }}
                              >
                                {column}
                              </TableCell>
                            ))}
                            <TableCell
                              key={`timesheet-action-column`}
                              sx={{
                                backgroundColor: "#F7F7F7",
                                fontWeight: 600,
                              }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {consultantsData.map((consultantData, index) => (
                            <>
                              {isValidObject(consultantData) ? (
                                <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                                  <TableCell>
                                    <div className={classes.consultantCell}>
                                      <img
                                        src={
                                          consultantData.consultants?.image ||
                                          "/user_placeholder.svg"
                                        }
                                        alt="user-profile"
                                        className={classes.userImage}
                                      />
                                      <div className="text-[14px] font-semibold">
                                        {consultantData?.consultants?.name ??
                                          "-"}
                                      </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="text-[12px]">
                                    <div className="font-semibold">
                                      {consultantData?.jobs?.jobTitle ?? "-"}
                                    </div>
                                    <div>
                                      Job ID-{" "}
                                      {consultantData?.jobs?.jobNo ?? "-"}
                                    </div>
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    {consultantData?.organizations?.company ??
                                      "-"}
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    <CustomButton
                                      type="button1"
                                      customClasses={classes.viewButton}
                                      onClick={() => {
                                        onActionClick("viewTimesheet", {
                                          ...consultantData,
                                          timesheetType: "payAsYouGo",
                                        });
                                      }}
                                    >
                                      View
                                    </CustomButton>
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    <CustomButton
                                      type="button1"
                                      customClasses={classes.viewButton}
                                      onClick={() => {
                                        onActionClick("viewTimesheet", {
                                          ...consultantData,
                                          timesheetType: "milestone",
                                        });
                                      }}
                                    >
                                      View
                                    </CustomButton>
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    <CustomButton
                                      type="button1"
                                      customClasses={classes.viewButton}
                                      onClick={() => {
                                        onActionClick("viewTimesheet", {
                                          ...consultantData,
                                          timesheetType: "fixedRate",
                                        });
                                      }}
                                    >
                                      View
                                    </CustomButton>
                                  </TableCell>
                                  <TableCell>
                                    {consultantData?.timesheet?.[
                                      "pay-as-you-go"
                                    ]?.reduce((acc, entry) => {
                                      return entry?.status === "approved"
                                        ? acc + 1
                                        : acc;
                                    }, 0) || 0}
                                  </TableCell>
                                  <TableCell>
                                    {consultantData?.timesheet?.milestone?.reduce(
                                      (acc, entry) => {
                                        return entry?.status === "approved"
                                          ? acc + 1
                                          : acc;
                                      },
                                      0
                                    ) || 0}
                                  </TableCell>

                                  <TableCell>
                                    {consultantData?.timesheet?.fixedRate?.reduce(
                                      (acc, entry) => {
                                        return entry?.status === "approved"
                                          ? acc + 1
                                          : acc;
                                      },
                                      0
                                    ) || 0}
                                  </TableCell>
                                  <TableCell className="text-[14px]">
                                    <IconButton
                                      id={`consultant-${index}-action-button`}
                                      onClick={(event) => {
                                        handleColumnMenuOpen(
                                          event,
                                          consultantData
                                        );
                                      }}
                                      className="p-0"
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                    <Menu
                                      id={`consultant-${index}-menu`}
                                      MenuListProps={{
                                        "aria-labelledby": `consultant-${index}-menu`,
                                      }}
                                      anchorEl={
                                        actionMenuAnchor[consultantData._id]
                                      }
                                      open={Boolean(
                                        actionMenuAnchor[consultantData._id]
                                      )}
                                      onClose={() => {
                                        handleColumnMenuClose();
                                      }}
                                      TransitionComponent={Fade}
                                      className={classes.actionMenu}
                                    >
                                      <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={() => {
                                          onActionClick("createInvoice");
                                          setActionMenuAnchor({});
                                        }}
                                      >
                                        Create Invoice
                                      </MenuItem>

                                      <MenuItem
                                        className={classes.actionMenuItem}
                                        onClick={() => {
                                          onActionClick("uploadDocument");
                                          setActionMenuAnchor({});
                                        }}
                                      >
                                        Documents
                                      </MenuItem>
                                    </Menu>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      color="primary"
                      count={totalPagesCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      classes={{ ul: classes.paginationContainer }}
                    />
                  </>
                ) : (
                  <Alert severity="info">{messages.NO_DATA_FOUND}</Alert>
                )}
                {actionDialogOpen && (
                  <ActionDialog
                    dialogOpen={actionDialogOpen}
                    setDialogOpen={setActionDialogOpen}
                    actionType={actionType}
                    actionEntryData={actionEntryData}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    </div>
  );
};

ActiveConsultants.propTypes = {};

export default ActiveConsultants;
