import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Title from "../../common/Title";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import AgencyGridCard from "app/v2/Pages/Employer/common/Cards/Grid/Agency";
import AgencyListCard from "app/v2/Pages/Employer/common/Cards/List/Agency";
import CustomButton from "app/v2/components/CustomButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useLocation } from "react-router-dom";
import SearchLoaderModal from "../../common/SearchLoaderModal";

const useStyles = makeStyles((theme) => ({
  agencyFeedContainer: {
    minHeight: "calc(100vh - 382px)", // total viewport height minus navbar and footer's heights, minHeight:100% did not work,hence had to set it as pre parent div defined in EmployerRoute.
  },
  title: {
    fontWeight: 600,
    fontSize: "36px",
    marginBottom: "16px",
  },
  body: {
    margin: "0 52px",
  },
  actions: {
    display: "flex",
    columnGap: "16px",
    // marginBottom: "22px",
    alignItems: "center",
  },
  jobPostsAutocomplete: {
    width: "683px",
  },
  filterSortButton: {
    width: "131.86px",
    height: "40px",
    padding: "7.54532px 13.2043px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    dispaly: "flex",
    alignItems: "center",
    columnGap: "10px",
    whiteSpace: "nowrap",
  },
  agencyCountAndViewsDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "55px",
  },
  agencyCountText: {
    fontWeight: 500,
    fontSize: "12px",
    color: theme.palette.secondary.light,
  },
  viewButtonsDiv: { display: "flex", columnGap: "16px" },
  viewButton: {
    padding: "10px 20px 10px 15px",
    width: "126px",
    height: "38px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  selectedViewButton: {
    backgroundColor: "#EDEDED !important",
    border: 0,
    color: theme.palette.secondary.light,
  },
  agenciesDiv: {
    height: "1699px",
    overflowY: "auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    gap: "22px",
    marginBottom: "124px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const AgencyFeed = (props) => {
  const classes = useStyles();
  const dummyGridData = new Array(16).fill(<AgencyGridCard />);
  const dummyListData = new Array(10).fill(<AgencyListCard />);
  const [viewType, setViewType] = useState("grid");
  const dummyJobPostsData = [
    "Job 27: Frontend Developer for Contractual Project",
    "Job 28: Backend Developer for Contractual Project",
    "Job 29: Backend Developer for Full time Project",
  ]; // will get this at global level context or redux store.

  const selectedFiltersString = localStorage.getItem("selectedFilters");
  const selectedFilters = JSON.parse(
    selectedFiltersString
  ); /* this is used for further data filter*/
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hideComponent = searchParams.get("hideComponent") === "true";
  const [isLoading, setIsLoading] = useState(false);

  const isGridView = () => {
    return viewType === "grid";
  };

  const onViewChange = (updatedView) => {
    setViewType(updatedView);
  };
  return (
    <div className={classes.agencyFeedContainer}>
      <Title title="Search Agencies" />
      <div className={classes.body}>
        <div className={classes.actions}>
          {hideComponent ? null : (
            <>
              <CustomAutocomplete
                id={"job-posts-autocomplete"}
                data={dummyJobPostsData}
                defaultSelection={dummyJobPostsData[0]}
                // placeholder={"Enter Role"}
                onChange={() => {}}
                customClasses={classes.jobPostsAutocomplete}
                // disabled={disableRoleAutocomplete}
              />
              <CustomButton customClasses={classes.filterSortButton}>
                Filter & Sort
                <img
                  className="w-[15px] h-[15px]"
                  alt=""
                  src="/materialsymbolsfilterlist.svg"
                />
              </CustomButton>
            </>
          )}
        </div>
        <div className={classes.agencyCountAndViewsDiv}>
          <Typography className={classes.agencyCountText}>
            2,231 agencies found
          </Typography>
          <div className={classes.viewButtonsDiv}>
            <CustomButton
              customClasses={clsx([
                classes.viewButton,
                isGridView() && classes.selectedViewButton,
              ])}
              onClick={() => {
                onViewChange("grid");
              }}
            >
              <img src="/grid-view.svg" className="mt-px mb-0.5 mr-2.5" />
              Grid View
            </CustomButton>
            <CustomButton
              customClasses={clsx([
                classes.viewButton,
                !isGridView() && classes.selectedViewButton,
              ])}
              onClick={() => {
                onViewChange("list");
              }}
            >
              <img src="/list-view.svg" className="mt-px mb-0.5 mr-2.5" />
              List View
            </CustomButton>
          </div>
        </div>
        <div className={classes.agenciesDiv}>
          {/* isLoading ? (
          // {!candidates ? (  /* adding for seacrhloader when we get some data at the time we can us it for loading
            <div>
            <SearchLoaderModal open={isLoading} /> */}
          {[...(isGridView() ? dummyGridData : dummyListData)]?.map((Card) => (
            <>{Card}</>
          ))}
        </div>
        {/* <div className={classes.loadMoreDiv}>
          <MoreHorizIcon className={classes.moreIcon} fontSize="large" />
          <Typography className={classes.loadMoreText}>Load more</Typography>
        </div> */}
      </div>
    </div>
  );
};

AgencyFeed.propTypes = {};

export default AgencyFeed;
