import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CustomSelect from "app/v2/components/filters/CustomSelect";

const useStyles = makeStyles((theme) => ({
  iPSubDivText: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  }, // iP - interviewPanel
  recruiterDiv: {
    marginBottom: "24px",
  },
  dataTagsDiv: {
    marginBottom: "48px",
  },
  dataTagsDescText: {
    fontSize: "14px",
    marginBottom: "16px",
  },
  errorMessage: {
    fontSize: "12px",
    color: "red",
  },
}));

const InterviewPanel = ({ id: parentId, data, setData }) => {
  const classes = useStyles();
  const recruiterData = ["A", "B", "C"];
  // const allDataTags = [
  //   "Fixed Term",
  //   "Possible Long Term",
  //   "A",
  //   "B",
  //   "C",
  //   "D",
  //   "E",
  //   "F",
  //   "G",
  // ];
  const [onDataTagsChangeCalled, setOnDataTagsChangeCalled] = useState(false); // To show the tags required error message after the user interacts with the element.

  const onChange = (id, value) => {
    // let entry = {};
    // entry[`${id}`] = value;
    // id === "dataTags" && setOnDataTagsChangeCalled(true);
    // setData(parentId, entry); // setting data at parent level.
  }; // temporarily commented this part,until info received regarding this component.

  return (
    <>
      <div className={classes.recruiterDiv}>
        <Typography className={classes.iPSubDivText}>
          Assigned Recruiter
        </Typography>
        <CustomSelect
          id="recruiter"
          data={recruiterData}
          placeholder={"Select"}
          onChange={onChange}
          defaultSelection={data?.recruiter}
        />
      </div>
      {/* <div className={classes.dataTagsDiv}>
        <Typography className={classes.iPSubDivText}>Data Tags</Typography>
        <Typography className={classes.dataTagsDescText}>
          Add more data points to your listing by adding appropriate tags to
          your job post. Maximum of 6 tags per job only.
        </Typography>
        <CustomAutocomplete
          id="dataTags"
          data={allDataTags}
          placeholder="Add Tags"
          defaultSelection={data?.dataTags}
          allowMultipleSelection={true}
          allowCustomUserInput={true}
          onChange={onChange}
        />
        {onDataTagsChangeCalled && (
          <Typography className={classes.errorMessage}>
            {data?.dataTags?.length === 0 && "Please select atleast one tag."}
            {data?.dataTags?.length > 6 &&
              "Please select upto a maximum of 6 tags."}
          </Typography>
        )}
      </div> */}
    </>
  );
};

InterviewPanel.propTypes = {};

export default InterviewPanel;
