import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getCandidateInfo = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/info`);
};
// https://stage.findpro.ai/api/client/consultant/62f5159ad5870894755148c4

export const updateCandidateInfo = async (request) => {
  // return fetchWrapper(`${configs.APIUrl}/candidates/update-info`, {
  return fetchWrapper(`${configs.APIUrl}/consultant/update-info`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};


