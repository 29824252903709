import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../../../images/common";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    marginBottom: "24px",
    width: "100%",
    border: "1px solid #EDEDED",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    width: "100%",
    overflowX: "auto",
    paddingBottom: "10px",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  hideActions: {
    display: "none",
  },
  userImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const List = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();
  const [cardData, setCardData] = useState();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setCardData(data);
  }, [data]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx([
        classes.listContainer,
        "self-stretch bg-neutral-white overflow-hidden flex flex-row py-[21px] px-[18px] items-center justify-center gap-[36px] text-center text-lg text-black font-button-text-button-14  mr-auto",
      ])}
    >
      <img
        className={classes.userImage}
        alt={`"user_${cardData?._id}_image"`}
        src={cardData?.image ? cardData.image : "/user_placeholder.svg"}
      />
      <div className="w-[308px] flex flex-col  items-start justify-center gap-[19px]">
        <div className="self-stretch flex flex-row items-end justify-start gap-[8px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px] h-42 w-215">
            <div className="relative leading-[130%] font-semibold text-left">
              {/* {cardData?.name} */}
              Lacy Clark
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 text-left">
              {/* {cardData?.profile_title} */}
              Sr.Product Owner
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-[6px] text-left text-3xs text-neutral-500">
            <div className="flex flex-row items-center justify-start gap-[6px]">
              <b
                className={clsx([
                  classes.sectionHeadingText,
                  "relative uppercase",
                ])}
              >
                Match rate
              </b>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0"
                alt=""
                src="/iconoirdoublecheck.svg"
              />
            </div>
            <div className="text-blue-700 text-base font-jakarta-sans font-semibold leading-[18.2px]">
              {/* {cardData?.match_rate ?? "-"} */}
              High
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-3xs text-neutral-800">
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px] text-left">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              availability
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-center">
              {/* {cardData?.availability ?? "-"} */}
              Part Time
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-500">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Level
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {/* {cardData?.level ?? "-"} */}
              Expert
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px] ">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              location
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {/* {cardData?.city ?? "-"} {cardData?.country?? "-"} */}
              Singapore
            </div>
          </div>
        </div>
      </div>
      <div className="w-[422px] h-[106px] flex flex-col  items-start justify-center gap-[16px] text-3xs text-neutral-500">
        <div className="flex flex-col items-start justify-start gap-[8px]">
          <div
            className={clsx([classes.sectionHeadingText, "relative uppercase"])}
          >
            technology skills
          </div>
          <div
            className={clsx([
              classes.skillsChipsDiv,
              "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
            ])}
          >
            {isValidArray(cardData?.skills) ? (
              <>
                {cardData?.skills?.map((skillDetails) => (
                  <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                    <div className="relative leading-[136%] font-medium">
                      {/* {skillDetails?.skill} */}
                      React Js
                    </div>
                  </div>
                ))}
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="self-stretch relative text-xs leading-[136%] font-poppins [display:-webkit-inline-box] overflow-auto text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] h=45">
          {/* {cardData?.summary} */}
          With a strong foundation in computer science and extensive experience
          in software development, I excel at architecting software solutions
          across the entire stack. My expertise includes designing and
          implementing robust backend systems, crafting intuitive user
          interfaces, and optimizing performance for seamless user experiences
        </div>
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[19px] max-w-[247px] w-full mt-10">
        {isHovered && (
          <>
            <button
              // onClick={() => {
              //   history.push(`/client/consultant/${cardData?._id}`);
              //   window.scrollTo(0, 0);
              // }}
              onClick={() => {
                history.push("/agency/consultant-detail");
              }}
              className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 self-stretch flex flex-row items-center justify-center gap-[10px]"
            >
              <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-white text-center">
                View Profile
              </div>
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconlightup-right.svg"
              />
            </button>
            {/* <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
              <button className="cursor-pointer py-2.5 px-5 bg-[transparent] flex-1 box-border h-10 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50">
                <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-300 text-center">
                  Connect
                </div>
              </button>
              <button className="cursor-pointer p-2.5 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-5 h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/icondarkbookmark.svg"
                />
              </button>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};

List.propTypes = {};

export default List;
