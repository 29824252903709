import React from "react";

const Text = ({ children, className = "", variant="", as="span", ...restProps }) => {
  const Component = as;
  return (
    <
// @ts-ignore
    Component
      className={`${className}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
