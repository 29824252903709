import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
// import JobList from "../common/Cards/job/List";
// import JobGrid from "../common/Cards/job/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { messages } from "app/v2/Pages/Utils/constants";
import Title from "../../common/Title";
import FeatureJobListCard from "../common/Cards/FeatureJobs/FeatureJobListCard";
import FeatureJobGridCard from "../common/Cards/FeatureJobs/FeatureJobGridCard";

const useStyles = makeStyles((theme) => ({
  jobFeedContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
    paddingBottom: "44px",
  },
  searchResultText: {
    fontWeight: 600,
    fontSize: "14px",
  },
  jobTitle: {
    fontWeight: 600,
    fontSize: "36px",
    marginBottom: "11px",
  },
  jobPostsCountText: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#717171",
    marginBottom: "8px",
  },
  viewTypesDiv: {
    display: "flex",
    columnGap: "16px",
  },
  viewButton: {
    padding: "10px 20px 10px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    border: "1px solid #EDEDED",
    backgroundColor: `${theme.palette.common.white} !important`,
    color: "#717171 !important",
    fontWeight: 600,
    fontSize: "14px",
  },
  selectedViewButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
  jobFeedBodyContainer: {
    paddingTop: "234px", // headerDiv total height is 264 px(including margin,padding)
  },
  leftView: {
    padding: "48px 24px",
    width: "305px",
    position: "fixed",
    borderRight: "1px solid #EDEDED",
    overflow: "auto",
    height: "615px",
  },
  rightView: {
    margin: "24px 24px 24px 24px", // left margin - 305px +24px(here 305 is fixed leftView div's width )
  },
  gridRightView: {
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
  },

  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  workTypeCheckboxDiv: {
    margin: "12px 24px",
  },
  moreFilterDiv: {
    border: "1px solid #EDEDED",
    padding: "7.545px 13.204px",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    cursor: "pointer",
  },
  moreFilterText: {
    fontWeight: 600,
    fontSize: "14px",
  },
  filterImgAndCountDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  moreFilterCount: {
    fontWeight: 600,
    fontSize: "10px",
    color: theme.palette.primary.main,
    position: "relative",
    top: "1px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyJobData = [
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 1",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 2",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "submitted",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 3",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 4",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 5",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 6",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 7",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 8",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 9",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 10",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 11",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 12",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 13",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 14",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 15",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 16",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 17",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
    jobStatus: "active",
    applicationStatus: "invited",
  },
];

const JobFeed = (props) => {
  const classes = useStyles();

  const sortData = [
    {
      label: "Relevance",
      value: "Relevance",
    },
    {
      label: "Date Posted",
      value: "Date Posted",
    },
    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          # of Open Positions <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "# of Open Positions",
    },
  ];

  const [jobPostsData, setJobPostsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [selectedViewType, setSelectedViewType] = useState("grid");
  const [currentSortSelectionValue, setCurrentSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [statusData, setStatusData] = useState([
    {
      label: "Active (3)",
      value: "Active (3)",
      checked: false,
    },
    {
      label: "Saved (4)",
      value: "Saved (4)",
      checked: false,
    },
    {
      label: "Applied (4)",
      value: "Applied (4)",
      checked: false,
    },
    {
      label: "Invited (1)",
      value: "Invited (1)",
      checked: false,
    },
  ]);

  const isGridViewSelected = () => selectedViewType === "grid";

  const onViewButtonClick = () => {
    setSelectedViewType(isGridViewSelected() ? "list" : "grid");
  };
  const onSortChange = (id, currentValue) => {
    setCurrentSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState) => {
    setStatusData((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.value === checkboxValue) {
          return { ...item, checked: checkedState };
        }
        return item;
      });

      return updatedItems;
    });
  };

  const filters = [
    {
      title: "Status",
      component: (
        <div className={classes.workTypeCheckboxDiv}>
          <CustomCheckboxGroup
            id="status-checkbox-group"
            data={statusData}
            onChange={onCheckboxGroupChange}
          />
        </div>
      ),
    },
    {
      title: "Experience Level",
      component: (
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      ),
    },
    {
      title: "Job Type",
      component: (
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      ),
    },
    {
      title: "Salary Range",
      component: (
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      ),
    },
    {
      title: "Skills",
      component: (
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      ),
    },
    {
      title: "Location",
      component: (
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    // add right api later.
    // getEmployerInfo()
    //   .then((res) => {
    //     console.log({ res });
    //        setDataLoading(false);
    //         setJobPostsData(res);
    //   })
    //   .catch((error) => {
    // setDataLoading(false);
    //      setDataError(true);
    // });
    setDataLoading(false);
    setJobPostsData(dummyJobData);
  }, []);

  return (
    <div className={classes.jobFeedContainer}>
      <>
        {dataLoading ? (
          <CircularProgress />
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <div style={{paddingLeft:"52px"}}>
                <div className={classes.headerDiv}>
                  <Title
                    title="Featured Jobs"
                    topText="SEARCH RESULTS FOR"
                  />
                  <div className="pl-[24px]">
                    <Typography className={classes.jobPostsCountText}>
                      2,231 jobs found
                    </Typography>
                    <div className={classes.viewTypesDiv}>
                      <Button
                        className={clsx([
                          classes.viewButton,
                          isGridViewSelected() && classes.selectedViewButton,
                        ])}
                        onClick={onViewButtonClick}
                      >
                        <img src="/grid-view.svg" />
                        Grid View
                      </Button>
                      <Button
                        className={clsx([
                          classes.viewButton,
                          !isGridViewSelected() && classes.selectedViewButton,
                        ])}
                        onClick={onViewButtonClick}
                      >
                        <img src="/list-view.svg" />
                        List View
                      </Button>
                    </div>
                  </div>
                </div>

                <div className={classes.jobFeedBodyContainer}>
                  <Grid container>
                    {/* <Grid item xs={3} className={classes.leftView}>
                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Sort{" "}
                        </Typography>
                        <div className={classes.sortRadioGroupDiv}>
                          <CustomRadioGroup
                            id="sort-radio-group"
                            name="sort-radio-group"
                            defaultValue={currentSortSelectionValue}
                            data={sortData}
                            onChange={onSortChange}
                          />
                        </div>
                      </div>

                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Filter
                        </Typography>
                        {filters.map((filterData) => (
                          <div className={classes.accordionDiv}>
                            <Accordion className={classes.accordion}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.accordionTitle}>
                                  {filterData?.title}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                {filterData?.component}
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        ))}
                      </div>
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      className={clsx([
                        classes.rightView,
                        isGridViewSelected() && classes.gridRightView,
                      ])}
                    >
                      {jobPostsData?.map((jobPostData) => (
                        <>
                          {isGridViewSelected() ? (
                            <FeatureJobGridCard data={jobPostData} />
                          ) : (
                            <FeatureJobListCard data={jobPostData} />
                          )}
                        </>
                      ))}
                      <div className={classes.loadMoreDiv}>
                        <MoreHorizIcon
                          className={classes.moreIcon}
                          fontSize="large"
                        />
                        <Typography className={classes.loadMoreText}>
                          Load more
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

JobFeed.propTypes = {};

export default JobFeed;
