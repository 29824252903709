import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ContentLoader from "react-content-loader";
import { Typography, Link, Grid, CircularProgress, Alert } from "@mui/material";
import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import { Line } from "../../../common";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import {
  JobHeader,
  JobOverview,
  TextSection,
  SectionTitleIcon,
  AboutCompany,
} from "./components";
import SubmitProposal from "../../common/Proposal/SubmitProposal";
import CustomTooltip from "app/v2/components/CustomTooltip";
import SocialShare from "app/v2/Pages/common/SocialShare";
import ProposalOverview from "../../common/Proposal/ProposalOverview";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import { IconLightGrid } from "app/v2/Pages/SVGs/IconLightGrid";
import { IconLightAward } from "app/v2/Pages/SVGs/IconLightAward";
import img_icon_light_arrow_top from "../../../images/img_icon_light_arrow_top.svg";

import { messages } from "app/v2/Pages/Utils/constants";
import { isValidObject, scrollToTop } from "app/v2/Pages/Utils/utilFunctions";
import { JobDetailObj } from "./JobDetail.dummy";
import CloseIcon from '@mui/icons-material/Close';

import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import configs from "app/v2/Pages/Utils/configs";
import {
  saveOrunsaveJob,
  acceptOrRejectJobInvitation,
} from "app/v2/services/consultant/myJobs.service";
import { getJobDetail } from "app/v2/services/job/jobDetail.service";
import { getEmployerInfo } from "app/v2/services/client/profileSettings.service";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "36px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
  saveAndShareButton: {
    padding: "10px 15px 10px 20px",
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
    display: "flex",
    columnGap: "10px",
  },
  savedButton: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  applyNowButton: {
    padding: "12px 28px",
    fontSize: "16px",
    textWrap: "nowrap",
    fontWeight: 600,
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
    width: "100%",
  },
  yesOrNoButton: {
    fontSize: "12px !important",
    padding: "12px",
  },
  noButton: {
    border: "1px solid #EDEDED !important",
  },
}));

// const AcceptJobDialogContent = ({ jobID, closeDialog, setJobStage }) => {
//   const classes = useStyles();
//   const [acceptJobLoading, setAcceptJobLoading] = useState(false);
//   const [acceptJobCompleted, setAcceptJobCompleted] = useState(false);
//   const [acceptJobError, setAcceptJobError] = useState(false);

//   const onAcceptJob = () => {
//     setAcceptJobLoading(true);
//     acceptOrRejectJobInvitation({ jobId: jobID, status: "accepted" })
//       .then((response) => {
//         setAcceptJobLoading(false);
//         setAcceptJobCompleted(true);
//         setJobStage("invitationAccepted");
//       })
//       .catch((err) => {
//         setAcceptJobLoading(false);
//         setAcceptJobError(true);
//       });
//   };

//   const Content = () => {
//     if (acceptJobLoading) return <DialogLoadingBody />;
//     else if (acceptJobCompleted)
//       return (
//         <DialogSuccessBody
//           content={{
//             title: `Congratulations! You've successfully accepted the job invitation.`,
//             desc: (
//               <b>
//                 Next, please click 'Apply Now' on the job card to proceed with
//                 the application.
//               </b>
//             ),
//           }}
//         />
//       );
//     else if (acceptJobError)
//       return (
//         <DialogErrorBody
//           content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
//         />
//       );
//     else
//       return (
//         <>
//           Are you sure you want to accept the job invitation?
//           <div className="flex gap-x-[16px] justify-end">
//             <CustomButton
//               customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
//               onClick={closeDialog}
//             >
//               No
//             </CustomButton>

//             <CustomButton
//               type="button1"
//               customClasses={classes.yesOrNoButton}
//               onClick={() => {
//                 onAcceptJob();
//               }}
//             >
//               Yes
//             </CustomButton>
//           </div>
//         </>
//       );
//   };

//   return (
//     <div className="p-[36px] flex flex-col gap-y-[24px] max-w-[739px] m-auto">
//       <Content />
//     </div>
//   );
// };

const RejectJobDialogContent = ({
  jobID,
  closeDialog,
  setJobInvitationStatus,
}) => {
  const classes = useStyles();
  const [rejectJobLoading, setRejectJobLoading] = useState(false);
  const [rejectJobCompleted, setRejectJobCompleted] = useState(false);
  const [rejectJobError, setRejectJobError] = useState(false);

  const onRejectJob = () => {
    setRejectJobLoading(true);
    acceptOrRejectJobInvitation({ jobId: jobID, status: "rejected" })
      .then((response) => {
        setRejectJobLoading(false);
        setRejectJobCompleted(true);
        setJobInvitationStatus("rejected");
      })
      .catch((err) => {
        setRejectJobLoading(false);
        setRejectJobError(true);
      });
  };

  const Content = () => {
    if (rejectJobLoading) return <DialogLoadingBody />;
    else if (rejectJobCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: `You've successfully rejected the job invitation.`,
          }}
        />
      );
    else if (rejectJobError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else
      return (
        <>
          Are you sure you want to reject the job invitation?
          <div className="flex gap-x-[16px] justify-end">
            <CustomButton
              customClasses={clsx([classes.yesOrNoButton, classes.noButton])}
              onClick={closeDialog}
            >
              No
            </CustomButton>

            <CustomButton
              type="button1"
              customClasses={classes.yesOrNoButton}
              onClick={() => {
                onRejectJob();
              }}
            >
              Yes
            </CustomButton>
          </div>
        </>
      );
  };

  return (
    <div className="p-[36px] flex flex-col gap-y-[24px] max-w-[739px] m-auto">
      <Content />
    </div>
  );
};

const JobActionDialogContent = ({
  actionType,
  jobData,
  closeDialog,
  setJobType,
  setJobInvitationStatus,
}) => {
  switch (actionType) {
    case "View Application":
      return <ProposalOverview jobData={jobData} />;

    // case "Accept Job":
    //   return (
    //     <AcceptJobDialogContent
    //       jobID={jobData?._id}
    //       closeDialog={closeDialog}
    //       setJobInvitationStatus={setJobInvitationStatus}
    //     />
    //   );
    case "Reject Job":
      return (
        <RejectJobDialogContent
          jobID={jobData?._id}
          closeDialog={closeDialog}
          setJobInvitationStatus={setJobInvitationStatus}
        />
      );
    case "Accept and Apply Job":
      return (
        <SubmitProposal
          jobData={jobData}
          onSuccess={() => {
            setJobInvitationStatus("accepted");
          }}
          closeParentDialog={closeDialog}
          customSuccessMessage="You have successfully accepted and applied for the job."
        />
      );
    case "Apply Job":
      return (
        <SubmitProposal
          jobData={jobData}
          onSuccess={() => {
            setJobType("applied");
          }}
          closeParentDialog={closeDialog}
        />
      );

    default:
      return <></>;
  }
};

const leftNavData = [
  {
    icon: <IconLightUser className="w-[29px] h-[29px]" />,
    title: "Job Overview",
    component: ({ jobData, experiencesData, contractTypesData }) => (
      <JobOverview
        jobData={jobData}
        experiencesData={experiencesData}
        contractTypesData={contractTypesData}
      />
    ),
  },
  {
    icon: <IconLightFile className="w-[29px] h-[29px]" />,
    title: "Job Description",
    component: ({ jobData }) => (
      <TextSection
        data={jobData?.jobDescription?.htmlString ?? jobData?.jobDescription}
      />
    ),
  },
  {
    icon: <IconLightGrid className="w-[29px] h-[29px]" />,
    title: "Qualifications",
    component: ({ jobData }) => (
      <SkillsPicker
        id="consultantJobDetailSP" // SP - Skills Picker
        data={jobData?.skillsRequired}
        readOnly={true}
      />
    ),
  },
  {
    icon: <IconLightAward className="w-[29px] h-[29px]" />,
    title: "Responsibilities",
    component: ({ jobData }) => (
      <TextSection
        data={
          jobData?.jobResponsibilities?.htmlString ??
          jobData?.jobResponsibilities
        }
      />
    ),
  },
  {
    icon: <VerifiedUserOutlinedIcon className="w-[29px] h-[29px]" />,
    title: "About Company",
    component: ({ jobData }) => (
      <AboutCompany
        profileData={
          jobData?.companyDetail?.companyDesc?.htmlString ??
          jobData?.companyDetail?.companyDesc
        }
        cultureData={
          jobData?.companyDetail?.culture?.htmlString ??
          jobData?.companyDetail?.culture
        }
      />
    ),
    withGrayBg: true,
  },
];

const JobDetail = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar,closeSnackbar } = useSnackbar();

  const { experiences, contractTypes } = useSelector((state) => state.misc);

  const [jobData, setJobData] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [publicJobDetailURL, setPublicJobDetailURL] = useState();
  const [jobType, setJobType] = useState(); // applied/invited/none
  const [invitationStatus, setInvitationStatus] = useState(); // specific to invited job and its values could be pending,accepted,rejected.
  const [isSavedJob, setIsSavedJob] = useState(false);
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );
  const [jobActionType, setJobActionType] = useState(""); // Apply Job,View Application
  const [jobActionDialogOpen, setJobActionDialogOpen] = useState(false);

  const onNavItemClick = (navItemIndex) => {
    // sectionRefs.current[navItemIndex].scrollIntoView({ behavior: 'smooth' });

    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  const handleSaveJob = () => {
    const actionName = isSavedJob ? "unsave" : "save";
    // enqueueSnackbar(
    //   `Your action to ${actionName} the job is in progress.Please wait.  `,
    //   { variant: "info" }
    // );

    const key = enqueueSnackbar(`Your action to ${actionName} the job is in progress.Please wait.  `, {
      variant: "info",  autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
      ),
    });
    saveOrunsaveJob({ jobId: jobData?._id })
      .then((response) => {
        setIsSavedJob((prevState) => !prevState);
        // enqueueSnackbar(`Your job has been ${actionName}d successfully.`, {
        //   variant: "success",
        // });
        const key = enqueueSnackbar(`Your job has been ${actionName}d successfully.`, {
          variant: "success",  autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
          ),
        });
      })
      .catch((err) => {
        // enqueueSnackbar(
        //   `Your job could not be ${actionName}d.Please try again later.`,
        //   { variant: "error" }
        // );

        const key = enqueueSnackbar(`Your job could not be ${actionName}d.Please try again later.`, {
          variant: "error",  autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
          ),
        });
      });
  };

  const getJobType = (appliedOrInvited) => {
    if (appliedOrInvited === "applied" || appliedOrInvited === "invited")
      return appliedOrInvited;
    else return "none";
  }; // applied/invited/none
  ///////// scroll

  const sectionRefs = useRef([]);
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  useEffect(() => {
    // setJobData(JobDetailObj);
    const jobID = props?.match?.params?.jobID;
    const homeUrl = configs?.homeUrl;
    const publicJobDetailURL = jobID ? homeUrl + `job-detail/${jobID}` : null;
    setPublicJobDetailURL(publicJobDetailURL);

    getJobDetail(jobID)
      .then((res) => {
        const jobDataReceived = res?.data;
        if (isValidObject(jobDataReceived)) {
          setJobData(jobDataReceived);
          setJobType(getJobType(jobDataReceived?.appliedOrInvited)); // need to get right value added in api.
          setInvitationStatus(jobDataReceived?.hiringStatus); // need to get right value added in api.
          setIsSavedJob(jobDataReceived?.isSaved);
          setDataLoading(false);
        } else {
          setDataLoading(false);
          setDataError(true);
        }
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });
  }, []);

  useEffect(() => {
    if (jobActionType) {
      setJobActionDialogOpen(true);
    }
  }, [jobActionType]);

  useEffect(() => {
    if (!jobActionDialogOpen) {
      // Setting necessary values to its default state when the job action dialog is closed.
      setJobActionType("");
    }
  }, [jobActionDialogOpen]);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  return (
    <>
      {dataLoading ? (
        // <CircularProgress />
        <ContentLoader
          speed={2}
          width={1400}
          height={800}
          viewBox="0 0 1400 800"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="100" rx="3" ry="3" width="280" height="605" />
          <rect x="335" y="100" rx="3" ry="3" width="1200" height="605" />
        </ContentLoader>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <>
              <Grid container>
                <Grid item className={classes.leftView}>
                  <div className={classes.backDiv}>
                    <ArrowBackIcon
                      onClick={() => {}}
                      className={classes.backIcon}
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                    <span>Back</span>
                  </div>
                  <div className="flex flex-col gap-y-[24px] mb-[36px]">
                    {jobType === "applied" || jobType === "invited" ? (
                      <>
                        {jobType === "applied" ? (
                          <div className="w-full">
                            <CustomButton
                              type="button1"
                              customClasses={classes.applyNowButton}
                              onClick={() => {
                                setJobActionType("View Application");
                              }}
                            >
                              View Application
                            </CustomButton>
                          </div>
                        ) : (
                          <>
                            {invitationStatus === "pending" && (
                              <>
                                <div className="w-full">
                                  <CustomButton
                                    type="button1"
                                    customClasses={clsx([
                                      classes.applyNowButton,
                                      // classes.acceptAndApplyButton,
                                    ])}
                                    onClick={() => {
                                      setJobActionType("Accept and Apply Job");
                                    }}
                                  >
                                    Accept and Apply
                                  </CustomButton>
                                </div>
                                <div className="w-full">
                                  <CustomButton
                                    type="button1"
                                    customClasses={classes.applyNowButton}
                                    onClick={() => {
                                      setJobActionType("Reject Job");
                                    }}
                                  >
                                    Reject
                                  </CustomButton>
                                </div>
                              </>
                            )}

                            {invitationStatus === "accepted" && (
                              <CustomButton
                                type="button1"
                                customClasses={classes.applyNowButton}
                                onClick={() => {
                                  setJobActionType("View Application");
                                }}
                              >
                                View Application
                              </CustomButton>
                            )}

                            {invitationStatus === "rejected" && (
                              <CustomButton
                                type="button1"
                                customClasses={classes.applyNowButton}
                                disabled={true}
                              >
                                Invitation Rejected
                              </CustomButton>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <CustomButton
                        type="button1"
                        customClasses={classes.applyNowButton}
                        onClick={() => {
                          setJobActionType("Apply Job");
                        }}
                      >
                        Apply Job <img src="/iconlightup-right.svg" />
                      </CustomButton>
                    )}

                    <div className="flex gap-x-[20px]">
                      <div className="flex-1">
                        <CustomButton
                          customClasses={clsx([
                            classes.saveAndShareButton,
                            isSavedJob && classes.savedButton,
                          ])}
                          onClick={handleSaveJob}
                        >
                          {isSavedJob ? "Unsave" : "Save"}
                          <img
                            alt="bookmark-icon"
                            src={
                              isSavedJob
                                ? "/iconlightbookmark.svg"
                                : "/icondarkbookmark.svg"
                            }
                          />
                        </CustomButton>
                      </div>
                      <CustomTooltip
                        arrow
                        title={<SocialShare shareLink={publicJobDetailURL} />}
                        bg="white"
                      >
                        <div className="flex-1">
                          <CustomButton
                            customClasses={classes.saveAndShareButton}
                          >
                            Share <img src="/share.svg" />
                          </CustomButton>
                        </div>
                      </CustomTooltip>
                    </div>
                  </div>
                  <hr />
                  <div className={classes.navItemsDiv}>
                    {leftNavData?.map((navItemDetails, index) => (
                      <Link
                        key={`nav-item-link-${index}`}
                        className={clsx([
                          classes.navItemLinkDiv,
                          selectedNavItemIndex === `nav-item-component-${index}`
                            ? classes.selectedNavItem
                            : classes.unselectedNavItem,
                        ])}
                        onClick={() => {
                          onNavItemClick(index);
                        }}
                      >
                        {navItemDetails?.icon}

                        <Typography className={classes.navItemTitle}>
                          {navItemDetails?.title}
                        </Typography>
                      </Link>
                    ))}
                  </div>
                </Grid>
                <Grid item id="sectionContent" className={classes.rightView}>
                  <JobHeader jobData={jobData} />
                  {leftNavData?.map((navItemDetails, index) => (
                    <>
                      <div
                        ref={(el) => (sectionRefs.current[index] = el)}
                        className={`${
                          navItemDetails?.withGrayBg
                            ? "bg-[#F7F7F7] p-[36px]"
                            : "px-[24px]"
                        }`}
                        id={`nav-item-component-${index}`}
                        key={`nav-item-component-${index}`}
                      >
                        <SectionTitleIcon
                          title={navItemDetails?.title}
                          icon={navItemDetails?.icon}
                        />
                        {navItemDetails?.component({
                          jobData,
                          experiencesData: experiences,
                          contractTypesData: contractTypes,
                        })}
                      </div>
                      {!isLastNavItemComponent(index) && (
                        <Line className="bg-gray-300 h-px w-full my-[36px]" />
                      )}
                    </>
                  ))}
                  <div
                    className="flex items-center gap-x-[10px] pt-[36px] cursor-pointer"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    <img src={img_icon_light_arrow_top} />
                    <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                      Back to top
                    </div>
                  </div>
                </Grid>
              </Grid>
              <CustomDialog
                open={jobActionDialogOpen}
                setOpen={setJobActionDialogOpen}
                title={
                  <div className="text-[16px] font-semibold text-center">
                    {jobActionType}
                  </div>
                }
                content={
                  <JobActionDialogContent
                    actionType={jobActionType}
                    jobData={jobData}
                    closeDialog={() => setJobActionDialogOpen(false)}
                    setJobInvitationStatus={setInvitationStatus}
                    setJobType={setJobType}
                  />
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};

JobDetail.propTypes = {};

export default JobDetail;
