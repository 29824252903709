import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "../../Utils/configs";
import { getAccountType } from "../../Utils/utilFunctions";

export const resetPassword = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
