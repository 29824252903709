import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomButton from "app/v2/components/CustomButton";
import CallMadeIcon from "@mui/icons-material/CallMade";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useHistory } from "react-router-dom";
import CustomTooltip from "app/v2/components/CustomTooltip";
import { useSnackbar } from "notistack";
import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import { favUnfavSelection } from "app/v2/services/client/common.service";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import { getSkillName } from "app/v2/Pages/common/getIdTypeLevel";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  listCardOuterDiv: {
    maxWidth: "1340px",
    width: "100%",
    height: "148px",
    boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    marginBottom: "20px",
    padding: "21px 18px",
  },
  listCardInnerDiv: {
    maxWidth: "1185px",
    width: "100%",
    height: "100px",
    display: "flex",
    columnGap: "36px",
    alignItems: "center",
  },
  addInfoName: {
    fontSize: "10px",
    fontWeight: 700,
    color: theme.palette.secondary.light, // light grey
    marginBottom: "6px",
  },
  addInfoValue: {
    fontSize: "14px",
    fontWeight: 600,
  },
  detailsSet1: {
    maxWidth: "203px",
    width: "100%",
  },
  nameRoleDiv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "19px",
  },
  name: {
    fontWeight: 600,
  },
  role: {
    fontSize: "12px",
    color: theme.palette.secondary.light, // light grey
  },
  companyDetailsDiv: {
    display: "flex",
    columnGap: "40px",
  },
  detailsSet2: {
    maxWidth: "422px",
    width: "100%",
  },
  skillsDiv: {
    marginBottom: "12px",
  },
  skillsHeading: {
    fontSize: "10px",
    fontWeight: 600,
    color: theme.palette.secondary.light, // light grey
    marginBottom: "8px",
  },
  skillsChipsDiv: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
  },
  chip: {
    padding: "6px 8px",
    border: "1px solid #EDEDED",
    borderRadius: "6px !important",
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.secondary.light, // light grey
    marginRight: "8px",
    textTransform: "uppercase",
  },
  bio: {
    color: theme.palette.secondary.light, // light grey
    fontSize: "12px",
    height: "41px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  actionsDiv: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "247px",
    width: "100%",
    rowGap: "19px",
  },
  viewCompanyButton: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },

  deleteButton: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  viewProfileIcon: {
    marginLeft: "8px",
    width: "16px",
    height: "16px",
  },
  delandstatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
  connectdiv: {
    width: "100%",
  },
  connectedButton: {
    width: "100%",
    border: "1px solid #717171",
    fontSize: "14px",
    fontWeight: 600,
    alignSelf: "flex-start",
  },

  deleteIcondiv: {
    cursor: "pointer",
    // width: "50px",
    // height: "50px",
    // marginLeft: "auto",
    position: "relative",
    // bottom: "4px",
    alignSelf: "flexend",
    border: "1px solid #EDEDED",
    padding: "5px",
  },
  deleteIcon: {
    filter: "brightness(50%)",
  },
  favIcon: {
    marginRight: "4px",
    width: "16px",
    height: "16px",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const ListCard = ({ data, setUpdateSaveJobData }) => {
  const [isSavedJob, setIsSavedJob] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  //   const data = {
  //     image: "/company_image.png",
  //     companyName: "ABC Company",
  //     role: "Sr. Product Owner",
  //     companySize: 14,
  //     location: "Singapore",
  //     bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
  //     skills: ["React", "NodeJS", "AngularJS"],
  //   };

  const AddInfo = ({ name, value, customClasses }) => {
    return (
      <div className={customClasses}>
        <Typography className={classes.addInfoName}>{name}</Typography>
        <Typography className={classes.addInfoValue}>{value}</Typography>
      </div>
    );
  };

  const { experiences, contractTypes, skillLevels, skills, industryData } =
    useSelector((state) => state.misc);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    // if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!skillLevels?.length) dispatch(fetchSkillsThunk());
    if (!skills?.length) dispatch(fetchSkillLevelsThunk());
  }, [dispatch]);

  const handlesavedData = () => {
    if (isFunction(setUpdateSaveJobData))
      setUpdateSaveJobData((prevState) => !prevState);
  };

  const handleBookMarkClick = async (jobId, favouriteType) => {
    const actionName = isSavedJob ? "unsave" : "save";
    // enqueueSnackbar(
    //   `Your action to ${actionName} the job is in progress.Please wait.  `,
    //   { variant: "info" }
    // );

    const key = enqueueSnackbar(
      `Your action to ${actionName} the job is in progress.Please wait.  `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );

    try {
      const requestData = {
        favoriteId: jobId,
        favoriteModel: favouriteType,
      };

      const response = await favUnfavSelection(requestData);

      if (response) {
        handlesavedData();
        setIsSavedJob((prevState) => !prevState);

        // enqueueSnackbar(`Your job has been ${actionName}d  successfully.`, {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(
          `Your job has been ${actionName}d  successfully.`,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      } else {
      }
    } catch (error) {
      // enqueueSnackbar(
      //   `Your job could not be ${actionName}d.Please try again later.`,
      //   { variant: "error" }
      // );

      const key = enqueueSnackbar(
        `Your job could not be ${actionName}d.Please try again later.`,
        {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
  };

  useEffect(() => {
    setIsSavedJob(true);
  }, [data]);

  return (
    <div className={classes.listCardOuterDiv}>
      <div className={classes.listCardInnerDiv}>
        <img
          className={classes.orgImage}
          alt={`org_${data?._id}_image`}
          src={data?.favourite?.image}
        />
        <div className={classes.detailsSet1}>
          <div className={classes.nameRoleDiv}>
            <Typography variant="subtitle1" className={classes.name}>
              {data?.favourite?.name}
            </Typography>
            <Typography className={classes.role}>{data?.role}</Typography>
          </div>
          <div className={classes.companyDetailsDiv}>
            {/* <AddInfo name=" SIZE" value={data.favourite.size} /> */}

            <AddInfo
              name="LOCATION"
              value={`${data?.favourite?.city}, ${data?.favourite?.state},${data?.favourite?.country}`}
            />
          </div>
        </div>
        <div className={classes.detailsSet2}>
          {data?.favouriteType?.toLowerCase() === "consultants" ? (
            <div>
              <div className={classes.skillsDiv}>
                <Typography className={classes.skillsHeading}>
                  TECHNOLOGY SKILLS
                </Typography>
                <div className={classes.skillsChipsDiv}>
                  {data?.favourite?.skills && data?.favourite?.skills.length > 0
                    ? Array.isArray(data?.favourite?.skills) &&
                      data?.favourite?.skills.map((skill) => (
                        <Chip
                          key={skill?.skillID}
                          label={getSkillName(skill?.skillID, skills) ?? "_"}
                          variant="outlined"
                          className={classes.chip}
                        />
                      ))
                    : "-"}
                </div>
              </div>
              <Typography className={classes.bio}>
                {typeof data?.favourite?.description === "object" &&
                data?.favourite?.description > 0
                  ? JSON.stringify(data?.favourite?.description)
                  : data?.favourite?.description ?? "_"}
              </Typography>
            </div>
          ) : (
            <Typography className={classes.bio}>
              {typeof data?.favourite?.description.text === "object"
                ? JSON.stringify(data?.favourite?.description.text)
                : data?.favourite?.description?.text ?? "_"}
            </Typography>
          )}
        </div>

        <div className={classes.actionsDiv}>
          <CustomButton
            type="button1"
            customClasses={classes.viewCompanyButton}
            onClick={() => {
              const typee =
                data?.favouriteType?.toLowerCase() === "consultants"
                  ? "consultant"
                  : data?.favouriteType?.toLowerCase();
              history.push(`/client/${typee}/${data?.favourite?._id}`);
            }}
          >
            View {data.favourite.name}
            <CallMadeIcon
              fontSize="small"
              className={classes.viewProfileIcon}
            />
          </CustomButton>

          {/* <div style={{ display: "flex" }}>
            <CustomButton
              customClasses={classes.connectedButton}
              disabled={true}
            >
              Connected
            </CustomButton>

            <button
              style={{ marginLeft: "10px" }} // Add a 10px margin to create a gap
              className={`cursor-pointer ${
                isBookMarkClicked
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={handleBookMarkClick}
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src={
                  isBookMarkClicked
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
          </div> */}

          <div className={classes.delandstatus}>
            <Tooltip title="This feature will be available in next release">
              <div className={classes.connectdiv}>
                <CustomButton
                  /*onClick={(handleConnect(data._id))}*/ customClasses={
                    classes.connectedButton
                  }
                  disabled={true}
                >
                  Message
                  {/* {handleStatusConnect} */}
                </CustomButton>
              </div>
            </Tooltip>
            <button
              className={`cursor-pointer ${
                isSavedJob
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={() =>
                handleBookMarkClick(data?.favourite._id, data?.favouriteType)
              }
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src={
                  isSavedJob
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ListCard.propTypes = {};

export default ListCard;
