import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import InfoIcon from "@mui/icons-material/Info";

import CustomTooltip from "app/v2/components/CustomTooltip";

import {
  isValidArray,
  isValidObject,
  isValidString,
  capitalizeFirstLetter,
} from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";

const useStyles = makeStyles((theme) => ({
  paginationToolbar: {
    alignItems: "baseline",
  },
  paginationText: {
    fontWeight: 600,
  },
  menuText: {
    fontSize: "17px",
    fontWeight: 600,
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
}));

const getColumns = (isFeaturesTable) => {
  let headCells = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "description",
      label: "Description",
    },
  ];

  if (isFeaturesTable) {
    headCells.push(
      {
        id: "userType",
        label: "Feature For",
      },
      {
        id: "featureItems",
        label: "Feature Items",
      }
    );
  }

  return headCells;
};

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, isFeaturesTable } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {getColumns(isFeaturesTable).map((headCell) => (
          <TableCell key={headCell.id} align="left">
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  isFeaturesTable: PropTypes.bool.isRequired,
};

function EnhancedTableToolbar(props) {
  const classes = useStyles();
  const { numSelected, isFeaturesTable } = props;

  const title = isFeaturesTable ? "Feature" : "Feature Item";
  const infoTooltip = isFeaturesTable
    ? "Select atleast one feature to create a plan."
    : "Select atleast one feature item to create a feature.";
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%", fontWeight: 600 }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {title}(s) Selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", fontWeight: 600 }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Add {title}s{" "}
          <CustomTooltip title={infoTooltip}>
            <InfoIcon className={classes.infoIcon} />{" "}
          </CustomTooltip>
        </Typography>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  isFeaturesTable: PropTypes.bool.isRequired,
};

export default function UnifiedFeatureTable({
  type,
  rows,
  setSelectedRows,
  selectedRowIDs: selectedRowIDReceived,
}) {
  const classes = useStyles();
  const isFeaturesTable = type === "features"; // features or feature items table

  const [selectedRowIDs, setSelected] = React.useState(
    isValidArray(selectedRowIDReceived) ? selectedRowIDReceived : []
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selectedRowIDs.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRowIDs, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRowIDs.slice(1));
    } else if (selectedIndex === selectedRowIDs.length - 1) {
      newSelected = newSelected.concat(selectedRowIDs.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRowIDs.slice(0, selectedIndex),
        selectedRowIDs.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selectedRowIDs.indexOf(id) !== -1;

  const visibleRows = React.useMemo(
    () => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [rows, page, rowsPerPage]
  );

  useEffect(() => {
    setSelectedRows(selectedRowIDs);
  }, [selectedRowIDs]);
  return (
    <Box sx={{ width: "1000px" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selectedRowIDs.length}
          isFeaturesTable={isFeaturesTable}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              numSelected={selectedRowIDs.length}
              onSelectAllClick={handleCheckAllClick}
              rowCount={rows.length}
              isFeaturesTable={isFeaturesTable}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleCheckboxClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selectedRowIDs={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.name || "-"}</TableCell>
                    <TableCell>{row.description || "-"}</TableCell>
                    {isFeaturesTable ? (
                      <>
                        <TableCell>
                          {isValidString(row.featureType)
                            ? capitalizeFirstLetter(row.featureType)
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {isValidArray(row.featureitems) ? (
                            <ol
                              key={`row-${index}-featureItems`}
                              style={{ listStyleType: "decimal" }}
                            >
                              {row.featureitems.map((item, itemIndex) => (
                                <>
                                  {isValidObject(item) ? (
                                    <li
                                      key={`row-${index}-featureItem-${itemIndex}`}
                                    >
                                      <b>{item.name || ""}</b>
                                      {item.description ? (
                                        ` - ${item.description}`
                                      ) : (
                                        <></>
                                      )}
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </ol>
                          ) : (
                            <>-</>
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          classes={{
            toolbar: classes.paginationToolbar,
            menuItem: classes.menuText,
            select: classes.menuText,
            selectLabel: classes.paginationText,
            displayedRows: classes.paginationText,
          }}
        />
      </Paper>
    </Box>
  );
}
