const HiringBoardJobDetailsObj = {
  title: "Development Team Lead",
  role: "Hiring Manager",
  roleName: "Mike Fitz",
  logo: "https://source.unsplash.com/250x250/?company-logo",
  jobNo: "FP23147",
  status: "active",
  status_: "active|inactive|closed|draft",
  openingPositions: 1,
  acceptedApplicants: 0,
  rejectedApplicants: 4,
  hourlyRate: 30,
  level: "junior",
  contract: "Part Time",
  workType: "Hybrid",
  salaryRange: "$30k - $50k/yr",
  totalApplicants: 43,
  accepted: 0,
  pending: 0,
  shortlisted: 0,
  interview1: 0,
  interview2: 0,
  testing: 0,
  final: 0,
  _created_at: "Yesterday",
  _updated_at: "Today at 11:23",
  language: "Arabic",
  location: "Singapore City , Singapore",
  timezone: "SGST (GMT + 8.0)",
};
export { HiringBoardJobDetailsObj };
