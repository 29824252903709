import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "configs";

export const getEmployerInfo = async (email) => {
  return fetchWrapper(`${configs.APIUrl}/employers/info`);
};

export const updateEmployerInfo = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/employers/update-info`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const updateEmployerItAssessmentTokens= async (request) => {
  return fetchWrapper(`${configs.APIUrl}/employers/update-it-aasessment-token`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getItAssessmentTokens = async () => {
  
  return fetchWrapper(`${configs.APIUrl}/employers/it-aasessment-tokens`);
};

export const getEmployerSubsInfo = async (email) => {
  return fetchWrapper(`${configs.APIUrl}/employers/subscriber-details`);
};
