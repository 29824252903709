import React, { useRef, useEffect } from "react";
import { Chart as ChartJS } from "chart.js";
import { Line } from "react-chartjs-2";

const options = {
  // scales: {
  //   x: {
  //     title: {
  //       display: true,
  //       text: 'Month',
  //     },
  //   },
  //   y: {
  //     title: {
  //       display: true,
  //       text: 'Sales',
  //     },
  //   },
  // },
  // plugins: {
  //   legend: {
  //     display: true,
  //     position: 'top',
  //   },
  // },
  // maintainAspectRatio: false, // Disable aspect ratio to allow explicit control over width and height
};
const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [1, 2, 3, 4, 5, 6, 7],
      borderColor: "#1E5DFF",
      backgroundColor: "#F4F8FF",
    },
  ],
};

const LineChart = () => {
  const chartRef = useRef(null);

  return <Line options={options} data={data} />;
};

export default LineChart;
