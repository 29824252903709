export const devConfigs = {
  NeAPIUrl: "https://ne.findpros.ai",
  APIUrl: "https://dev.findpros.ai/api",
  homeUrl: "https://dev.findpros.ai/",
  linkedinAuthHome: "https://dev.findpros.ai/api",
  paymentConfig: {
    clientKey:
      // "ARd3YN8zUJP9H4Poyt2ZiSqGyP3AOuvaIokMcjBtJAUNxyOz0liCZ4Wbe5bLu890qtWxAU2wGuO9OKHT",
      "AYSgvI3MEjK2-Jfo6Hd5ujkN4tFskXmAfmjH9Q2QHDJNbWbqToLZaLPyB4ML6NhKc8ZiORAdFreWT2LN",
  },
};

//  "https://localhost:8080/api/v2" // right after MVP we'll need to touch base on this
