import React from "react";
import PropTypes from "prop-types";

import JobStatus from "../../../common/Status/Job";
import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import CustomTooltip from "app/v2/components/CustomTooltip";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  jOSet1: {
    flex: 1,
    display: "flex",
    columnGap: "24px",
    borderBottom: "1px solid #EDEDED",
  }, // jO - job overview.
  flexEqualWidthElement: {
    flex: 1,
  },
  jOSetElementTitle: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  jOSetElementValue: {
    fontSize: "16px",
  },

  postedLastUpdatedText: {
    fontSize: "12px",
    color: "#717171",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

export const SectionTitleIcon = ({ title, icon }) => (
  <div className="text-[24px] font-semibold mb-[24px] flex items-center gap-x-[8px]">
    {icon} {title}
  </div>
);

export const JobHeader = ({ data }) => {
  const classes = useStyles();
  return (
    <div className="flex gap-x-[24px] mb-[36px]">
      <img
        className={classes.orgImage}
        alt={`org_${data?._id}_image`}
        src={data?.logo ? data.logo : "/org_placeholder.svg"}
      />
      <div className="flex-1 flex flex-col gap-y-[12px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-x-[12px]">
            <span className={classes.postedLastUpdatedText}>
              Posted {data?.createdAt ? moment(data?.createdAt).fromNow() : "-"}
            </span>
            <span className={classes.postedLastUpdatedText}>
              Last Updated{" "}
              {data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-"}
            </span>
          </div>
          <div>
            <JobStatus type={data?.status} />
          </div>
        </div>
        <div className="text-[36px] font-semibold">{data?.jobTitle}</div>
        <div className="text-[14px] text-[#717171]">{data?.companyName}</div>
      </div>
    </div>
  );
};

export const JobOverview = ({ data, experiencesData, contractTypesData }) => {
  const classes = useStyles();
  const location =
    (data?.country ?? "") +
    ", " +
    (data?.state ?? "") +
    ", " +
    (data?.city ?? "");

  const salaryDetails = data?.salaryDetails ?? data?.salary;
  const salaryRange = salaryDetails?.range;
  const salaryValue = salaryRange
    ? `Min- $ ${salaryRange?.min ?? "-"}, Max - $ ${salaryRange?.max ?? "-"}`
    : `$ ${salaryDetails?.fixedRate ?? "-"}`;
  let salaryType = salaryDetails?.type ?? "";
  salaryType = salaryType?.toUpperCase();
  return (
    <div className="flex flex-col gap-y-[36px]">
      <div className="flex gap-x-[16px]">
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>
            {salaryType ?? ""} RATE
          </div>
          <div className={classes.jOSetElementValue}>{salaryValue ?? "-"}</div>
        </div>
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>LEVEL</div>
          <div className={classes.jOSetElementValue}>
            {experiencesData?.[data?.experienceLevelId]?.level ?? "-"}
          </div>
        </div>
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>CONTRACT</div>
          <div className={classes.jOSetElementValue}>
            {contractTypesData?.[data?.contractType]?.level ?? "-"}
          </div>
        </div>
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>WORK MODE</div>
          <div className={classes.jOSetElementValue}>
            {data?.workType ?? "-"}
          </div>
        </div>
        {/* <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>CATEGORY</div>
          <div className={classes.jOSetElementValue}>
            {data?.category ?? "-"}
          </div>
        </div> */}
      </div>
      <div className="flex gap-x-[16px]">
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>WEBSITE</div>
          <div className={classes.jOSetElementValue}>
            {data?.website ?? "-"}
          </div>
        </div>
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>LOCATION</div>
          <div className={classes.jOSetElementValue}>{location ?? "-"}</div>
        </div>
        <div className={classes.flexEqualWidthElement}>
          <div className={classes.jOSetElementTitle}>TIMEZONE</div>
          <div className={classes.jOSetElementValue}>
            {data?.timezone ?? "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TextSection = ({ data }) => {
  return (
    <div className="text-[16px] text-[#717171]">
      {data ? <iframe srcDoc={data} className="w-full"></iframe> : "-"}
    </div>
  );
};
