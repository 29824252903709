import React, { useEffect, useState } from "react";
import PlanDetails from "./PlanDetails";
import Billing from "./Billing";
import Invoice from "./Invoice";
import { candidateInfoIn } from "app/candidate/myaccount/profile-settings/profile-settings.slice";
import { candidateInfoThunk } from "app/v2/store/features/consultant/consultantProfile.thunk";
import { useDispatch } from "react-redux";
import { getCandidateInfo } from "app/v2/services/consultant/profileSettings.service";

const SubscriptionBody = () => {
  const dispatch = useDispatch();
  const [subscriptionData, setSubscriptionData] = useState({});

  useEffect(() => {
    getCandidateInfo()
      .then((res) => {
        if (res) {
          setSubscriptionData(res?.consultant);
        }
      });
  }, []);
  return (
    <div className="flex flex-col gap-[36px] ">
      <PlanDetails profileSubscriptionData={subscriptionData} />
      {/* <hr />
      <Billing />
      <hr />
      <Invoice /> */}
    </div>
  );
};

export default SubscriptionBody;
