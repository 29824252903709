import { useState } from "react";
import useToggleLoader from "./useToggleLoader";

const useFetch = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const toggleLoader = useToggleLoader();

  const fetchAPI = (promise) => {
    toggleLoader(true);
    return promise
      // .then((res) => {
      //   setResponse(res);
      //   return res;
      // })
      // .catch((err) => {
      //   setError(err);
      // })
      .finally(() => {
        toggleLoader(false);
      });
  };

  return [fetchAPI, response, error];
};

export default useFetch;
