import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { fetchFAQsThunk } from "app/v2/store/features/misc/miscThunk";
import { isValidArray } from "../../Utils/utilFunctions";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";

import { messages } from "../../Utils/constants";

const useStyles = makeStyles((theme) => ({
  faqContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "36px",
    maxWidth: "707px",
    padding: "0 16px",
    boxSizing: "content-box",
  },
  sectionTitle: {
    fontWeight: 600,
    fontSize: "24px",
  },
  accordionDiv: {
    boxShadow: "none",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    "&::before": {
      height: 0,
    },
  },
  accordionTitle: {
    fontWeight: 700,
    fontSize: "14px",
    color: theme.palette.primary.main,
    padding: "32px 0",
  },
  expandIcon: {
    color: theme.palette.primary.main,
  },
  accordionContent: {
    fontSize: "14px",
    color: theme.palette.secondary.light,
    padding: "0 0 32px 0",
  },
  contactButton: {
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
  },
}));
const HelpSupport = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [faqs, setFaqs] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    // setDataLoading(true);
    dispatch(fetchFAQsThunk())
      .unwrap()
      .then((res) => {
        if (isValidArray(res)) {
          const groupedFAQs = res.reduce((acc, item) => {
            const category = item?.category;
            if (category) {
              if (!acc[category]) acc[category] = [];
              acc[category].push(item);
            }

            return acc;
          }, {});

          const faqs = Object.keys(groupedFAQs).map((category) => ({
            title: category,
            accordions: groupedFAQs[category].map((item) => ({
              title: item?.ques,
              content: item?.ans,
            })),
          }));

          setFaqs(faqs);
        }
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
        console.error(err);
      });
    // setDataLoading(false);
  }, []);

  return (
    <>
      <div className="text-[32px] font-semibold mb-[36px]">FAQs</div>

      {/* FAQs Section- Start */}

      <div className={classes.faqContainer}>
        {isValidArray(faqs) ? (
          <>
            {faqs?.map((section) => (
              <div>
                <Typography className={classes.sectionTitle}>
                  {section?.title}
                </Typography>
                {isValidArray(section?.accordions) ? (
                  <div className="p-[8px]">
                    {section.accordions.map((accordionData) => (
                      <Accordion className={classes.accordionDiv}>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className={classes.expandIcon} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="p-0"
                        >
                          <Typography className={classes.accordionTitle}>
                            {accordionData?.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionContent}>
                          {accordionData?.content}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div>

      {/* FAQs Section- End */}

      <hr className="my-[36px]" />

      {/* Contact Support - Start */}
      <div className="text-[32px] font-semibold mb-[9px]">
        Contact A Tech Support
      </div>
      <div className="text-[12px] mb-[60px]">
        Please enter the details of your request and, if you have any questions
        regarding our Terms of Use, please include specific samples of the usage
        you wish to give our resouces. If you’re reporting a problem, make sure
        to include as much information as possible. Please include any
        screenshots or videos of issues since this will also help us resolve
        problems much sooner. Once your request is submitted, a member of our
        support staff will respond as soon as possible.
      </div>
      <div className="flex flex-col gap-y-[32px]">
        <CustomTextfield
          id="subject"
          label="subject or inquiry"
          placeholder="Text Input"
        />
        <CustomTextfield
          id="email"
          type="email"
          label="your email"
          placeholder="Text Input"
        />
        <CustomTextfield
          id="message"
          label="message"
          placeholder="Write us a message."
          multiline={true}
          minRows={8}
        />
        <CustomButton type="button1" customClasses={classes.contactButton}>
          Contact Support
        </CustomButton>
      </div>
      {/* Contact Support - End */}
    </>
  );
};

export default HelpSupport;
