import React, { useState } from "react";

import { List, Text } from "../../common";

import CustomTooltip from "app/v2/components/CustomTooltip";

import CustomButton from "app/v2/components/CustomButton";
const Button = CustomButton;


const JobDetailOverview = ( {props} ) => {


    return (
    <>
       <div className="flex flex-col items-center justify-start mx-auto  md:px-10 sm:px-5 w-full">
        <div className="flex flex-col items-center justify-start max-w-[890px] mb-9 mt-6 mx-auto w-full">
          <div className="flex flex-col gap-6 items-start justify-start max-w-[890px] sm:px-5 px-6 w-full" >
            <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto">
              Job Overview
            </Text>
            <div className="flex flex-col gap-9 items-start justify-start w-full">
              <div className="relative w-full">
                <List
                  className="border-b border-gray-300 border-solid flex-1 sm:flex-col flex-row gap-6 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-4 justify-start max-w-[842px] mx-auto w-full"
                  orientation="horizontal"
                >

                <CustomTooltip
                  title={
                      <Text >
                      Round 2: 2 Applicants
                      </Text>
                  }
                  placement="top-end"
                  >

                  <div className="flex flex-1 flex-col gap-0.5 items-start justify-center py-6 w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-auto">Total Applicants</Text>
                    <Text className="font-bold text-base text-gray-900 w-auto" > {props.totalApplicants} </Text>
                  </div>

                </CustomTooltip>


                  <div className="flex flex-1 flex-col gap-0.5 items-start justify-center py-6 w-full">
                    <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Total Openings</Text>
                    <Text className="font-bold text-base  text-gray-900 w-auto"> {props.totalOpenning}  </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-0.5 items-start justify-center py-6 w-full">
                    <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">accepted applicants</Text>
                    <Text className="font-bold text-base  text-gray-900 w-auto"> {props.acceptedApplicants} </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-0.5 items-start justify-center py-6 w-full">
                    <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Rejected Applicants</Text>
                    <Text className="font-bold text-base  text-gray-900 w-auto"> {props.rejectedApplicants}  </Text>
                  </div>

                </List>
              </div>
              <div className="bg-gray-100 flex flex-col gap-3 items-start justify-center p-4 w-full">
                <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Tags</Text>
                <div className="flex sm:flex-col flex-row gap-3 items-start justify-start w-auto sm:w-full">
                 
                  <Text className="bg-[#ececec] font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                    Team: {" "}
                    {props?.jobName}
                  </Text>

                  {props?.tags?.map((tag, index) => (
                    <Text key={index} className="bg-[#ececec] font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                      {tag}
                    </Text>
                  ))}
                 
                 
                </div>
              </div>
              <List
                className="sm:flex-col flex-row gap-4 grid sm:grid-cols-1 md:grid-cols-3 grid-cols-5 justify-start w-full"
                orientation="horizontal"
              >
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Hourly Rate</Text>
                  <Text className="font-normal text-base  text-gray-900 w-auto"> ${props.rate} </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Level</Text>
                  <Text className="font-normal text-base  text-gray-900 w-auto"> {props.level} </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold  text-gray-600 text-xs uppercase w-full">contract</Text>
                  <Text className="font-normal text-base  text-gray-900 w-auto"> {props.contract}  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Work Type</Text>
                  <Text className="font-normal text-base  text-gray-900 w-auto"> {props.workType}  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold  text-gray-600 text-xs uppercase w-auto">Category</Text>
                  <Text className="font-normal text-base  text-gray-900 w-auto"> {props.category} </Text>
                </div>
              </List>
            </div>
          </div>
        </div>
      </div>
    </>
    );
};

JobDetailOverview.propTypes = {};

export default JobDetailOverview;