import React, { useEffect, useState } from "react";
import images from "app/v2/images/common";
import { Button, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { messages } from "../Utils/constants";
import { verifyEmail } from "app/common/login/login.service";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "603px",
    margin: "auto",
    padding: "120px 60px",
    boxSizing: "content-box",
  },
  logo: {
    width: "156px",
    height: "42px",
    marginBottom: "48px",
  },
  title: {
    fontSize: "36px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  submitButton: {
    marginTop: "72px",
    width: "74px",
    boxSizing: "content-box",
    marginLeft: "auto",
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
    padding: "10px 15px 10px 20px",
    "&:disabled": {
      backgroundColor: "grey !important",
      borderColor: "#EDEDED !important",
    },
  },
  passwordChangedText: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "48px",
    padding: "16px 0",
  },
  loginNow: {
    width: "100%",
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

const SignupEmailVerified = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const accountType = pathName[1];
  const token = props.match.params.token;
  const [verified, setVerified] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const genericErrorMessage = messages.GENERIC_ERROR_MESSAGE;

  useEffect(() => {
    if (token) {
      setLoading(true);
      verifyEmail({ token })
        .then((res) => {
          setVerified(true);
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err.message || genericErrorMessage;
          enqueueSnackbar(errorMessage, { variant: "error" });
          console.error(err);
          console.error(err);
        });
    } else {
      props.history.push("/");
    }
  }, []);

  return (
    <>
      {loading && !verified ? (
        <CircularProgress />
      ) : (
        <div className={classes.container}>
          <img src={images?.logo_v2} className={classes.logo} />
          <Typography className={classes.title}>Welcome to FindPro</Typography>
          <div>
            <Typography className={classes.passwordChangedText}>
              Thanks for your Confirmation. We successfully validated your mail
              id.
              <br /> Kindly login to your account.
            </Typography>
            <Button
              className={classes.loginNow}
              onClick={() => {
                history.push(`/${accountType}/login`);
              }}
            >
              Login now
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default SignupEmailVerified;
