import React, { useEffect, useState } from "react";

import DashboardPage from "./Employer/Dashboard/Dashboard.page";
import ConsultantDetailPage from "./Employer/ConsultantDetail/ConsultantDetail.page";


import { BrowserRouter as Router, Route, Switch, useRouteMatch, Link } from 'react-router-dom';
import NotFound from "./common/NotFound";
import JobDetailOverview from "./Employer/common/JobDetailOverview";
import JobDetailHeader from "./Employer/common/JobDetailHeader";
import TextSection from "./Employer/common/TextSection";
import JobDetails from "./Employer/common/JobDetails";
import Qualifications from "./Employer/common/Qualifications";
import HiringBoardJobDetails from "./Employer/common/HiringBoardJobDetails";

import {JobDetailsObj} from "./Employer/common/JobDetails.dummy.js";
import HiringBoardJobDetailsForTeam from "./Employer/common/HiringBoardJobDetailsForTeam";
  
import {consultantDetailObj} from './Employer/ConsultantDetail/ConsultantDetail.dummy.js';
import {JobPostsObj} from './Employer/common/JobPosts.dummy';
import {HiringBoardJobDetailsObj} from './Employer/common/HiringBoardJobDetails.dummy';
import {HiringBoardJobDetailsForTeamObj} from './Employer/common/HiringBoardJobDetailsForTeam.dummy';


const Components = (props) => {

  const apiUrl = "http://localhost:5000/api";
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl,{
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(JobDetailsObj)
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setJobDetails(jsonData.obj);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    // setJobDetails(JobDetailsObj); setLoading(false);
    fetchData();
  }, []);


const { path } = useRouteMatch();


if (loading) {
  return <div>Loading...</div>;
}

return (
  <>
    <div>
      <Router basename="/v2/components">
        <Switch>
          {/* <Route exact path="/" component={Homepage} /> */}
          <Route exact path="/" render={(props) => 
            <>
              <nav className="flex items-center flex-col">
                <ul>
                  <li> <Link to="/">Home</Link> </li>
                  <li> <Link to="/2"> Incomplete Dashboard </Link> </li> 
                  <li> <Link to="/3"> Complete Dashboard </Link> </li> 
                  <li> <Link to="/4"> Consultant Detail Page </Link> </li> 
                  <li> <Link to="/5"> Job Detail  </Link> </li> 
                  <li> <Link to="/6"> Text Section </Link> </li>
                  <li> <Link to="/7"> Job Details </Link> </li> 
                  <li> <Link to="/8"> Qualifications </Link> </li> 
                  <li> <Link to="/9"> Hiring Board Job Details </Link> </li> 
                  <li> <Link to="/10"> Hiring Board Job Details For Team </Link> </li> 
                  <li> <Link to="/11"> none </Link> </li> 
                  <li> <Link to="/dashboard"> Dashboard </Link> </li>
                </ul>
              </nav>
            </>
          } />

          {/* <Route path="/about" component={ConsultantDetailPage} /> */}
          {/* <Route component={ConsultantDetailPage} /> */}
          
          <Route path="/1" render={(props) => {

          }} />
          <Route path="/2" render={(props) => <DashboardPage profile={{incomplete:true}} /> } />
          <Route path="/3" render={(props) => <DashboardPage profile={{incomplete:false}} /> } />
          <Route path="/4" render={(props) => <ConsultantDetailPage {...consultantDetailObj} /> } />
          <Route path="/5" render={(props) => 
          <>
            <JobDetailHeader props={JobPostsObj} /> 
            <JobDetailOverview props={JobPostsObj} /> 
          </>
            } />
          <Route path="/6" render={(props) => <TextSection {...{"type":"company", "title":"Title" } } /> } />
          <Route path="/7" render={(props) => <JobDetails {...jobDetails} /> } />
          <Route path="/8" render={(props) => <Qualifications {...undefined} /> } />
          <Route path="/9" render={(props) => <HiringBoardJobDetails props={HiringBoardJobDetailsObj} /> } />
          <Route path="/10" render={(props) => <HiringBoardJobDetailsForTeam props={HiringBoardJobDetailsForTeamObj}  /> } />

          <Route path="/dashboard" render={(props) =>  <DashboardPage profile={{incomplete:true}} /> } />
  
          <Route render={(props) => <NotFound url={path} /> } />
        </Switch>
      </Router>

    </div>

  </>
);
};

Components.propTypes = {};

export default Components;
