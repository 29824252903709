import React from "react";
export const ArrowDownSVG = ({
  fillColor = "#000000",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 

            fill={fillColor}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            {...props}
            width="12" height="7" viewBox="0 0 12 7" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 9.53674e-07L-1.29539e-06 -9.53992e-08L6 7L12 9.53674e-07Z" fill="#0B0E1E"/>
      </svg>
    </>

  );
};
