export const getContractTypeLevel = (contractTypeID, contractTypes) => {
  // Handles alphanumeric or array index-based contractTypeID
  const contractTypeData = contractTypes?.find(
    (type, index) =>
      type?._id === contractTypeID || index === parseInt(contractTypeID)
  );
  return contractTypeData ? contractTypeData?.level : "-";
};

export const getSkillID = (skillName, skills) => {
  const skillEntry = skills?.find((skill) => skill?.name === skillName);
  return skillEntry ? skillEntry?._id : "-";
};

export const getSkillName = (skillId, skillIdTypes) => {
  const skillIdData = skillIdTypes.find((type) => type._id === skillId);
  return skillIdData ? skillIdData?.name : "-";
};

export const getSkillLevelName = (skillLevelId, skillLevelIdTypes) => {
  const skillLevelIdData = skillLevelIdTypes.find(
    (type) => type._id === skillLevelId
  );
  return skillLevelIdData ? skillLevelIdData?.name : "-";
};

export const getExperience = (experienceID, experiences) => {
  // Handles alphanumeric or array index-based experienceID
  const experienceData = experiences?.find(
    (type, index) =>
      type?._id === experienceID || index === parseInt(experienceID)
  );

  return experienceData
    ? `${experienceData?.level ?? ""} (${experienceData?.minExp ?? ""} - ${
        experienceData?.maxExp ?? ""
      } years)`
    : "-";
};

export const getIndustryName = (industryID, industries) => {
  // Handles alphanumeric or array index-based experienceID
  const industryData = industries?.find(
    (type, index) => type?._id === industryID || index === parseInt(industryID)
  );

  return industryData?.name;
};
