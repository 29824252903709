import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomButton from "app/v2/components/CustomButton";
import CustomSwitch from "app/v2/components/filters/CustomSwitch";
import { messages } from "app/v2/Pages/Utils/constants";
import { isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import { MinimizeSharp } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  salaryTypeHeading: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "24px",
    marginTop: "8px",
  },
  salaryTypesDiv: {
    display: "flex",
    columnGap: "20px",
    marginBottom: "24px",
  },
  salaryTypeOrClearButton: {
    maxWidth: "40px",
    width: "100%",
    height: "44px",
    padding: "12px 20px",
    border: "1px solid #EDEDED",
    fontSize: "12px",
    fontWeight: 600,
  },
  highlightButton: {
    border: 0,
    backgroundColor: "#F4F8FF !important",
    color: `${theme.palette.primary.main} !important`,
  },
  salaryValueHeading: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  salaryValueTypeDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "26px",
  },
  salaryValueTypeName: {
    fontSize: "16px",
    fontWeight: 600,
  },
  highlightSalaryValueType: {
    color: theme.palette.secondary.light,
  },
  fixedRateText: {
    marginRight: "24px",
  },
  setRangeText: {
    marginLeft: "24px",
  },
  fixedOrRangeSalaryDiv: {
    display: "flex",
  },
  fixedSalaryDiv: {},
  rangeSalaryDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    columnGap: "20px",
  },
  salaryOtherElement: {
    // position: "relative",
    // top: "17px",
  },
  salaryText: {
    fontSize: "14px",
    fontWeight: 700,
    marginLeft: "24px",
  },
  numberPickerAdornedStart: {
    fontSize: "14px",
    fontWeight: 600,
  },
  numberPickerText: {
    fontWeight: 400,
    fontSize: "14px",
    marginLeft: "10px",
  },
  numberPickerAdornedEnd: {
    fontSize: "14px",
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
}));

const SalaryFilter = ({ heading, data, setData }) => {
  const classes = useStyles();
  const salaryTypes = ["Hourly", "Monthly", "Annual"];
  const [selectedSalaryType, setSelectedSalaryType] = useState();
  const [range, setRange] = useState({}); // {min,max}
  const [minRangeErrorMessage, setMinRangeErrorMessage] = useState("");
  const [maxRangeErrorMessage, setMaxRangeErrorMessage] = useState("");

  const onChange = (salaryType, range) => {
    setData({ type: salaryType, range });
  };

  const onSalaryTypeChange = (salaryType) => {
    setSelectedSalaryType(salaryType);
    setRange({}); // setting range value back to default.
    onChange(salaryType, {});
  };

  const onRangeInputChange = (value, rangeType) => {
    let updatedRange = { ...range };
    let minMessage, maxMessage;
    value = parseInt(value);

    if (rangeType === "min") {
      updatedRange.min = value;
      if (value < 0 || value >= updatedRange?.max) {
        minMessage = messages.MIN_RANGE_ERROR;
        maxMessage = "";
      } else minMessage = "";
    } else {
      updatedRange.max = value;
      if (value <= updatedRange?.min) {
        maxMessage = messages.MAX_RANGE_ERROR;
        minMessage = "";
      } else maxMessage = "";
    }

    setMinRangeErrorMessage(minMessage);
    setMaxRangeErrorMessage(maxMessage);
    setRange(updatedRange);
    if (Boolean(minMessage) === false && Boolean(maxMessage) === false) {
      // Only if no error message in either min or max message we update the values at parent level.
      onChange(selectedSalaryType, updatedRange);
    }
  };

  const onClear = () => {
    setData({});
    setSelectedSalaryType(null);
    setRange({});
  };

  useEffect(() => {
    setSelectedSalaryType(data?.type);
    if (isValidObject(data?.range)) setRange(data?.range);
  }, [data]);

  return (
    <div>
      <Typography className={classes.salaryTypeHeading}>
        {heading || "Select salary type"}
      </Typography>
      <div className={classes.salaryTypesDiv}>
        {salaryTypes.map((salaryType, idx) => (
          <CustomButton
            key={idx}
            customClasses={clsx([
              classes.salaryTypeOrClearButton,
              salaryType === selectedSalaryType && classes.highlightButton,
            ])}
            onClick={() => {
              onSalaryTypeChange(salaryType);
            }}
          >
            {salaryType}
          </CustomButton>
        ))}
      </div>
      {selectedSalaryType && (
        <div>
          <hr />
          <Typography className={classes.salaryValueHeading}>
            Salary value
          </Typography>
          <div className={classes.fixedOrRangeSalaryDiv}>
            <div className={classes.rangeSalaryDiv}>
              <FormControl
                error={Boolean(minRangeErrorMessage)}
                variant="outlined"
                sx={{ width: "25ch" }}
              >
                <OutlinedInput
                  id={`${selectedSalaryType}-range-min`}
                  key={`${selectedSalaryType}-range-min`}
                  type="number"
                  startAdornment={
                    <span className={classes.numberPickerAdornedStart}>$</span>
                  }
                  endAdornment={
                    <span className={classes.numberPickerAdornedEnd}>min</span>
                  }
                  value={range?.min}
                  onChange={(event) => {
                    onRangeInputChange(event.target.value, "min");
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  classes={{
                    input: classes.numberPickerText,
                  }}
                />
                {minRangeErrorMessage && (
                  <FormHelperText id="component-error-text">
                    {minRangeErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
              <div className={classes.salaryOtherElement}>-</div>

              <FormControl
                error={Boolean(maxRangeErrorMessage)}
                variant="outlined"
                sx={{ width: "25ch" }}
              >
                <OutlinedInput
                  id={`${selectedSalaryType}-range-max`}
                  key={`${selectedSalaryType}-range-max`}
                  type="number"
                  startAdornment={
                    <span className={classes.numberPickerAdornedStart}>$</span>
                  }
                  endAdornment={
                    <span className={classes.numberPickerAdornedEnd}>max</span>
                  }
                  value={range?.max}
                  onChange={(event) => {
                    onRangeInputChange(event.target.value, "max");
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  classes={{
                    input: classes.numberPickerText,
                  }}
                />
                {maxRangeErrorMessage && (
                  <FormHelperText id="component-error-text">
                    {maxRangeErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            {/* <Typography
              className={clsx([classes.salaryText, classes.salaryOtherElement])}
            >
              {salaryNameDescMapper[selectedSalaryType] ?? ""}
            </Typography> */}
          </div>
          <br />
          <div className="flex justify-end">
            <CustomButton
              customClasses={clsx([classes.salaryTypeOrClearButton])}
              onClick={() => {
                onClear();
              }}
            >
              Clear
            </CustomButton>
          </div>
        </div>
      )}
    </div>
  );
};

SalaryFilter.propTypes = {};

export default SalaryFilter;
