import logo_v2 from "./logo_v2.png";
import logo_v2_white from "./logo_v2_white.png";
import line from "./line.png";
import white_line from "./white_line.png";
import profile_avatar from "./profile_avatar.png";
import location_icon from "./location_icon.png";
import mail_icon from "./mail_icon.png";
import notification_icon from "./notification_icon.png";
import user_icon from "./user_icon.png";
import user_profile from "./user_profile.png";

const images = {
  logo_v2,
  logo_v2_white,
  line,
  white_line,
  profile_avatar,
  location_icon,
  mail_icon,
  notification_icon,
  user_icon,
  user_profile,
};
export default images;
