import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";
import JobStatus from "../Status/Job";
import CustomLabel from "app/v2/components/filters/CustomLabel";

import { isValidString } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  jOSetElement: {
    flex: 1,
  }, // jO - job overview.
  jOSetElementTitle: {
    flex: 1,
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },

  jOSetElementValue: {
    fontSize: "16px",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

export const JobHeader = ({ jobData }) => {
  const classes = useStyles();
  const clientData = jobData?.companyDetail;
  let createdAt = jobData?.createdAt
    ? moment(jobData?.createdAt).fromNow()
    : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  let updatedAt = jobData?.updatedAt
    ? moment(jobData?.updatedAt).fromNow()
    : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;
  return (
    <div className="flex gap-x-[24px] mb-[36px]">
      <div>
        <img
          src={
            clientData?.profilePhoto
              ? clientData.profilePhoto
              : "/org_placeholder.svg"
          }
          className={classes.orgImage}
          alt={`org_${jobData?._id}_image`}
        />
      </div>
      <div className="flex-1 flex flex-col gap-y-[12px]">
        <div className="flex justify-between items-center">
          <div className="flex gap-x-[12px]">
            <span className="text-[12px] text-[#717171]">
              Posted {createdAt}
            </span>
            <span className="text-[12px] text-[#717171]">
              Last Updated {updatedAt}
            </span>
          </div>
          <div>
            <JobStatus type={jobData?.status} />
          </div>
        </div>
        <div className="text-[36px] font-semibold">{jobData?.jobTitle}</div>
        <div className="flex gap-x-[36px]">
          <span className="text-[14px] font-semibold text-[#8C8C8C]">
            Job no.{" "}
            <span className="text-[black]">{jobData?.jobNo ?? "-"}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export const JobOverview = ({
  jobData,
  experiencesData,
  contractTypesData,
}) => {
  const classes = useStyles();
  const location =
    (jobData?.country ?? "") +
    ", " +
    (jobData?.state ?? "") +
    ", " +
    (jobData?.city ?? "");

  const salaryDetails = jobData?.salaryDetails ?? jobData?.salary;
  const salaryRange = salaryDetails?.range;
  const salaryValue = salaryRange
    ? `Min- $ ${salaryRange?.min ?? "-"}, Max - $ ${salaryRange?.max ?? "-"}`
    : `$ ${salaryDetails?.fixedRate ?? "-"}`;
  let salaryType = salaryDetails?.type ?? "";
  salaryType = salaryType?.toUpperCase();
  return (
    <div className="flex flex-col gap-y-[36px]">
      <div className="flex gap-x-[16px]">
        <div className={classes.jOSetElement}>
          <div className={classes.jOSetElementTitle}>
            {salaryType ?? ""} RATE
          </div>
          <div className={classes.jOSetElementValue}>{salaryValue ?? "-"}</div>
        </div>
        <div className={classes.jOSetElement}>
          <div className={classes.jOSetElementTitle}>LEVEL</div>
          <div className={classes.jOSetElementValue}>
            {experiencesData?.[jobData?.experienceLevelId] ?? "-"}
          </div>
        </div>
        <div className={classes.jOSetElement}>
          <div className={classes.jOSetElementTitle}>CONTRACT</div>
          <div className={classes.jOSetElementValue}>
            {contractTypesData?.[jobData?.contractType] ?? "-"}
          </div>
        </div>
        <div className={classes.jOSetElement}>
          <div className={classes.jOSetElementTitle}>WORK TYPE</div>
          <div className={classes.jOSetElementValue}>
            {jobData?.workType ?? "-"}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[16px]">
        <div className={classes.jOSetElement}>
          <div className={classes.jOSetElementTitle}> LOCATION</div>
          <div className={classes.jOSetElementValue}>{location ?? "-"}</div>
        </div>
        <div className={classes.jOSetElement}>
          <div className={classes.jOSetElementTitle}>TIMEZONE</div>
          <div className={classes.jOSetElementValue}>
            {jobData?.timezone ?? "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export const TextSection = ({ data }) => {
  return (
    <div className="text-[16px] text-[#717171]">
      {data ? <iframe srcDoc={data} className="w-full"></iframe> : "-"}
    </div>
  );
};

export const AboutCompany = ({ profileData, cultureData }) => {
  return (
    <div className="text-[16px] text-[#717171]">
      {isValidString(profileData) || isValidString(cultureData) ? (
        <>
          {isValidString(profileData) ? (
            <>
              <CustomLabel label="company profile" />
              <iframe
                srcDoc={profileData}
                className="w-full"
                title="company profile"
              ></iframe>
              <br />
            </>
          ) : (
            <></>
          )}
          {isValidString(cultureData) ? (
            <>
              <CustomLabel label="company culture" />
              <iframe
                srcDoc={cultureData}
                className="w-full"
                title="company culture"
              ></iframe>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};
