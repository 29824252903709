import { createSlice } from "@reduxjs/toolkit";
import {
  getAppliedCandidatesThunk,
  getAppliedVendorCandidatesThunk,
  getInvitedCandidatesThunk,
} from "./jobs.thunk";

const initialState = {
  candidates: [],
  invitedCandidates: [],
  vendorCandidates: [],
};

const jobDetailsSlice = createSlice({
  name: "job_details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppliedCandidatesThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.candidates = action.payload;
        }
      })
      .addCase(getInvitedCandidatesThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.invitedCandidates = action.payload;
        }
      })
      .addCase(getAppliedVendorCandidatesThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.vendorCandidates = action.payload;
        }
      });
  },
});

// Action creators are generated for each case reducer function
// export const { candidateInfoIn, candidateInfoOut } = resumeSettingsSlice.actions;

export default jobDetailsSlice.reducer;
