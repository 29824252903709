import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "configs";
import { getAccountType } from "../utils/loginUtil";

export const getSubscriptionDetails = async () => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/subscriptionDetails`);
};


export const postSubscribePayment = (paymentDetails) => {
  const options = {
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
    body: paymentDetails,
  };
  return fetchWrapper(`${configs.APIUrl}/misc/subscribe-payment`, options);
}

