import React, { useEffect, useState } from "react";
import tw from 'twin.macro';

import { List, Text } from "../../common";

import { JobDetailsObj } from "./JobDetails.dummy.js";

import { 
  EditSVG,
  DeactivateSVG, 
  DeleteSVG,
  ShareSVG
} from "../../SVGs/Index";
import { CheckSVG } from "../../SVGs/Check";
import { IconDot } from "../../SVGs/IconDot";

import clsx from 'clsx';
import CustomButton from "app/v2/components/CustomButton";
const Button = CustomButton;


const JobDetailsContainer = tw.div` flex flex-col items-center sm:gap-10 md:gap-10 gap-[168px] justify-center mx-auto p-[92px] md:px-10 sm:px-5 w-full`;
const FlexContainer = tw.div`flex flex-col gap-9 items-start justify-start max-w-[842px] ml-3.5 md:ml-[0] mt-3.5 w-full`;
const JobTitleContainer = tw.div`flex flex-col gap-6 items-start justify-start w-full`;
const JobStatusContainer = tw.div`flex md:flex-col flex-row gap-[19px] items-start justify-start w-full`;
const JobStatusBadge = tw(Text)`flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm`;
const TotalApplicantsContainer = tw.div`bg-gray-100 flex flex-row gap-1.5 items-start justify-start p-3 w-[270px]`;

const JobDetails = (props) => {
  return (
    <>
      <JobDetailsContainer>
        <FlexContainer>
          <JobTitleContainer>
            <JobStatusContainer>
              <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                <Text className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto">
                  {props.jobName}
                </Text>
                <div className="flex sm:flex-col flex-row gap-3 items-center justify-start w-auto sm:w-full">
                  <Text className="font-normal text-gray-600 text-sm w-auto">Posted {props._created_at} </Text>
                  <Text className="font-normal text-gray-600 text-sm w-auto">Last Updated {props._updated_at} </Text>
                  <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">job no. {props.jobNo}</Text>
                </div>
                <div className="flex flex-row gap-1 items-center justify-start w-auto">
                  <Text className="font-semibold text-gray-400 text-sm w-auto"> {props.role} </Text>
                  <Text className="font-semibold text-gray-900 text-sm w-auto"> {props.roleName} </Text>
                </div>
              </div>
              {props?.status === 'active' ? (
                <JobStatusBadge className="bg-green-50">
                  <IconDot fillColor="green" className="mt-0.5 mb-[3px] mr-[5px]" />
                  <div className="font-bold text-green-700 text-left text-xs">Active</div>
                </JobStatusBadge>
              ) : props?.status === 'closed' ? (
                <JobStatusBadge className="bg-blue-50">
                  <IconDot fillColor="blue" className="mt-0.5 mb-[3px] mr-[5px]" />
                  <div className="font-bold text-blue-700 text-left text-xs">Closed</div>
                </JobStatusBadge>
              ) : props?.status === 'inactive' ? (
                <JobStatusBadge className="bg-gray-50">
                  <IconDot fillColor="gray" className="mt-0.5 mb-[3px] mr-[5px]" />
                  <div className="font-bold text-gray-600 text-left text-xs">Inactive</div>
                </JobStatusBadge>
              ) : (
                <>
                  Error
                </>
              )}
            </JobStatusContainer>
            <List className="sm:flex-col flex-row gap-4 grid sm:grid-cols-1 md:grid-cols-3 grid-cols-5 justify-start w-full" orientation="horizontal">
              <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">Open Positions</Text>
                <Text className="font-bold text-center text-gray-900 text-lg w-auto"> {props.openPositions} </Text>
              </div>
              <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">level</Text>
                <Text className="font-bold text-center text-gray-900 text-lg w-auto">{props.level} </Text>
              </div>
              <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">Contract type</Text>
                <Text className="font-bold text-center text-gray-900 text-lg w-auto"> {props.contract} </Text>
              </div>
              <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">work type</Text>
                <Text className="font-bold text-center text-gray-900 text-lg w-auto"> {props.workType} </Text>
              </div>
              <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">hourly rate</Text>
                <Text className="font-bold text-center text-gray-900 text-lg w-auto">${props.rate} </Text>
              </div>
            </List>
            <div className="flex md:flex-col flex-row gap-4 items-center justify-start w-full">
              <TotalApplicantsContainer>
                <div className="flex flex-col gap-0.5 items-center justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">
                    Total Applicants
                  </Text>
                  <Text className="font-bold text-base text-center text-gray-900"> {props.totalApplicants} </Text>
                </div>
                <div className="flex flex-col gap-0.5 items-center justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">Accepted</Text>
                  <Text className="font-bold text-base text-center text-gray-900 w-full"> {props.acceptedApplicants} </Text>
                </div>
              </TotalApplicantsContainer>
              <div className="flex flex-1 sm:flex-col flex-row gap-4 items-start justify-start px-4 py-3 w-full">
                <List className="sm:flex-1 sm:flex-col flex-row gap-4 grid grid-cols-4 w-[66%] sm:w-full" orientation="horizontal">
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">pending</Text>
                    <Text className="font-bold text-base text-center text-gray-900 w-auto"> {props.pending} </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">shortlisted</Text>
                    <Text className="font-bold text-base text-center text-gray-900 w-auto"> {props.shortlisted} </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">interview1</Text>
                    <Text className="font-bold text-base text-center text-gray-900 w-auto"> {props.interview1} </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">testing</Text>
                    <Text className="font-bold text-base text-center text-gray-900 w-auto"> {props.testing} </Text>
                  </div>
                </List>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold text-gray-600 text-xs uppercase w-full">interview2</Text>
                  <Text className="font-bold text-base text-center text-gray-900 w-auto"> {props.interview2} </Text>
                </div>
              </div>
            </div>
          </JobTitleContainer>
          <div className="flex sm:flex-col flex-row gap-9 items-end justify-between w-full">
            <div className="flex sm:flex-1 sm:flex-col flex-row gap-3 items-start justify-start w-auto sm:w-full">
              {props?.status === 'active' ? (
                <>
                  <Button
                    onClick={() => {

                    }}
                    rightIcon={
                      <IconDot fillColor="green" className="mb-[3px] ml-2.5" />
                    }
                    type="button1"
                    customClasses={clsx("h-10 px-4 py-2.5")}
                  >
                    <div className="font-semibold leading-[normal] text-center text-white">
                      View Job Hiring Board
                    </div>
                    <IconDot fillColor="green" className="ml-2.5" />
                  </Button>
                  <Button
                    variant="outlined"
                    type="button2"
                    customClasses={clsx("border border-gray-900 h-10 px-4 py-2.5")}
                  >
                    Find Consultants
                  </Button>
                </>
              ) : props?.status === 'closed' ? (
                <Button
                  type="button1"
                  customClasses={clsx("px-5 py-2.5")}
                >
                  Check Consultant Activity
                </Button>
              ) : props?.status === 'inactive' ? (
                <Button
                  type="button2"
                  customClasses={clsx("border border-gray-200 text-white px-5 py-2.5")}
                >
                  <CheckSVG className=" mt-px mb-0.5 m-2.5" fillColor="#000" />
                  Reactivate
                </Button>
              ) : (
                <>
                  Error
                </>
              )}

                <Button
                  type="button2"
                  customClasses={clsx("border border-gray-200 border-solid flex h-10 items-center justify-center w-10")}
                  >
                  <EditSVG />
                </Button>
                <Button
                  type="button2"
                  customClasses={clsx("border border-gray-200 border-solid flex h-10 items-center justify-center w-10")}
                  >
                  <DeactivateSVG />
                </Button>
                {props?.status === 'active' && (
                  <Button
                    type="button2"
                    customClasses={clsx("border border-gray-200 border-solid flex h-10 items-center justify-center w-10")}
                    >
                    <ShareSVG />
                  </Button>
                )}
            </div>
                <Button
                  type="button2"
                  customClasses={clsx("border border-gray-200 border-solid flex h-10 items-center justify-center w-10")}
                  >
                  <DeleteSVG className="h-6 w-6" />
                </Button>
          </div>
        </FlexContainer>
      </JobDetailsContainer>
    </>
  );
};

JobDetails.propTypes = {};
JobDetails.defaultProps = JobDetailsObj;

export default JobDetails;