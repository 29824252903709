import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
} from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Keyboard,
  Scrollbar,
  Navigation,
  Pagination,
  A11y,
  Controller,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../images/hire_developer_team/common";

const useStyles = makeStyles((theme) => ({
  testimonialsRoot: {
    marginLeft: "180px",
    paddingBottom: "97px",
    [theme.breakpoints.down("1400")]: {
      marginLeft: "100px",
    },
    [theme.breakpoints.down("750")]: {
      marginLeft: "50px",
    },
    [theme.breakpoints.down("700")]: {
      marginLeft: "0",
      paddingBottom: "173px",
    },
  },
  quoteIcon: {
    marginRight: "11px", // Setting as 14-3px=11 px as the mui card header already has some spacing.
  },
  titleAndDesc: {
    maxWidth: "875px",
    width: "100%",
    paddingBottom: "113px",
    [theme.breakpoints.down("700")]: {
      maxWidth: "450px",
      margin: "auto",
      paddingBottom: "48px",
    },
    [theme.breakpoints.down("500")]: {
      maxWidth: "307px",
      margin: "auto",
    },
  },
  title: {
    marginBottom: "56px",
    [theme.breakpoints.down("700")]: {
      marginBottom: "28px",
      fontSize: "30px",
    },
  },
  description: {
    fontWeight: 500,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down("700")]: {
      textAlign: "justify",
      fontSize: "15px",
    },
  },
  swiperContainer: {
    paddingBottom: "40px",
    [theme.breakpoints.down("700")]: {
      marginLeft: "47px",
    },
  },
  cardContainer: {
    borderRadius: "6px",
    maxWidth: "572px",
    width: "100% !important",
    boxShadow: "0px 10.3501px 119.601px rgba(0, 0, 0, 0.07)",
  },
  cardHeader: {
    backgroundColor: theme.palette.common.black,
    height: "79px",
    display: "flex",
    alignItems: "center",
  },
  cardContent: {
    width: "fit-content",
    margin: "38px auto 0px auto",
    [theme.breakpoints.down("700")]: {
      marginTop: "29px",
    },
  },
  bodyText: {
    fontWeight: 500,
    marginBottom: "128px",
    [theme.breakpoints.down("700")]: {
      marginBottom: "58px",
      fontSize: "14px",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px", // setting as 64-24=40 (since the mui card has 24 px padding already).
  },
  footerIcon: {
    maxWidth: "48px",
    width: "100%",
    position: "relative",
    right: "10px",
  },
  footerText: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#1C81FF",
    width: "88%",
    // marginLeft: "auto",
    [theme.breakpoints.down("700")]: {
      fontSize: "10px",
    },
  },
}));

const Testimonials = (props) => {
  const classes = useStyles();
  const smDown = useMediaQuery("(max-width:700px)");
  const clientIcons = commonImages.clientReviewIcons;
  const data = {
    title: "Top clients review FindPro",
    description:
      "FindPro has helped 400+ Fortune 500 companies and fast-scaling startups hire pre-vetted developers on demand. Here’s what they have to say about us.",
    testimonials: [
      {
        reviewText:
          "Working with FindPro has been a game-changer for our company. Their staffing services have allowed us to quickly and efficiently build up teams on demand with confidence in the quality of talent we're hiring. Their vetting process is thorough and reliable, saving us time and resources that we used to spend on lengthy interviews. I highly recommend FindPro to any company looking to streamline their hiring process and find top-quality talent.",
        footer: {
          icon: clientIcons[0],
          text: "VP of Operations at a fast-growing tech startup.",
        },
      },
      {
        reviewText:
          "FindPro has been an invaluable partner for our company. Their staffing services have helped us quickly fill critical positions with talented and experienced professionals. Their team is responsive, professional, and easy to work with, and they always go above and beyond to ensure that our staffing needs are met. I highly recommend FindPro to any company looking for top-notch staffing services.",
        footer: {
          icon: clientIcons[1],
          text: "HR Manager at a mid-sized financial services company.",
        },
      },
      {
        reviewText:
          "We've been using FindPro for the past year and have been extremely impressed with their staffing services. Their team deeply understands our industry and staffing needs, and they have consistently provided us with high-quality candidates that meet our requirements. Their platform is easy to use and their customer service is excellent. I highly recommend FindPro to any company looking for a reliable and efficient staffing partner.",
        footer: {
          icon: clientIcons[2],
          text: "Director of Operations at a healthcare company.",
        },
      },
    ],
  };

  const TestimonialCard = ({ cardData, cardNumber }) => {
    const classes = useStyles();
    const isFirstCard = cardNumber === 0;

    return (
      <Card>
        <CardHeader
          className={classes.cardHeader}
          action={
            <img src={commonImages.quotes_icon} className={classes.quoteIcon} />
          }
        />
        <CardContent className={classes.cardContent}>
          <Typography variant="body1" className={classes.bodyText}>
            {cardData.reviewText}
          </Typography>
          <div className={classes.footer}>
            <div className={classes.footerIcon}>
              <img src={cardData.footer.icon} />
            </div>
            <Typography className={classes.footerText}>
              {cardData.footer.text}
            </Typography>
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={classes.testimonialsRoot}>
      <div className={classes.titleAndDesc}>
        <Typography variant="h2" className={classes.title}>
          {data.title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {data.description}
        </Typography>
      </div>
      <Swiper
        slidesPerView={smDown ? 1.1 : "auto"}
        spaceBetween={smDown ? 10 : 31}
        grabCursor={true}
        // loop={true}
        className={clsx(["mySwiper", classes.swiperContainer])}
        // injectStyles={[
        //   `
        //       .swiper-button-next,
        //       .swiper-button-prev {
        //         background-color:#fff;
        //       }
        //   `,
        // ]}
      >
        {data.testimonials.map((testimonial, index) => (
          <SwiperSlide className={classes.cardContainer}>
            <TestimonialCard cardData={testimonial} cardNumber={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Testimonials.propTypes = {};

export default Testimonials;
