import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography, Link, Grid } from "@mui/material";
import CustomButton from "../../components/CustomButton";
import clsx from "clsx";
import images from "../../images/landingpage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    columnGap: "6.1rem",
    margin: "5rem 0 5.6rem 6.3rem",
    [theme.breakpoints.down("1400")]: {
      columnGap: "3rem",
    },
    [theme.breakpoints.down("1200")]: {
      columnGap: "1rem",
    },
  },
  smallScreenRoot: {
    display: "flex",
    flexDirection: "column",
    rowGap: "1.7rem",
    textAlign: "center",
    margin: "1rem",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    width: "55rem",
    marginTop: "7.3rem",
    [theme.breakpoints.down("1400")]: {
      marginTop: "4rem",
      width: "40rem",
    },
    [theme.breakpoints.down("1300")]: {
      marginTop: "3rem",
      width: "35rem",
    },
    [theme.breakpoints.down("1100")]: {
      marginTop: "2rem",
      width: "30rem",
    },
  },
  heading: {
    marginBottom: "2.1rem",
    [theme.breakpoints.down("1400")]: {
      fontSize: "3.8rem",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("1200")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("600")]: {
      fontSize: "1.8rem",
      marginBottom: 0,
    },
  },
  body: { marginLeft: "0.5rem" },
  aboutDesc: {
    maxWidth: "55rem",
    color: theme.palette.secondary.light,
    marginBottom: "3.6rem",
    [theme.breakpoints.down("1100")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("1000")]: {
      margin: "1.6rem auto 2.1em auto",
      width: "48rem",
    },
    [theme.breakpoints.down("800")]: {
      width: "38rem",
    },
    [theme.breakpoints.down("600")]: {
      width: "21rem",
      margin: "auto",
    },
  },
  buttonGroup: {
    display: "flex",
    columnGap: "2.8rem",
    marginBottom: "5rem",
    [theme.breakpoints.down("1000")]: {
      margin: "auto",
      columnGap: "0.5rem",
    },
  },
  buttonRoot: {
    width: "19.3rem",
    height: "4.8rem",
    padding: "0.9rem 4.4rem",
    borderRadius: 0,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("800")]: {
      width: "12.3rem",
      height: "3.8rem",
    },
    [theme.breakpoints.down("600")]: {
      width: "10rem",
      height: "2.3rem",
    },
  },
  buttonText: {
    fontSize: "1.3rem",
    fontWeight: theme.typography.button.fontWeight,
    [theme.breakpoints.down("800")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("600")]: {
      fontSize: "0.6rem",
    },
  },
  teamButton: {
    border: "2px solid #C8C8C8",
  },
  highlightText: {
    color: `${theme.palette.primary.main} !important`,
    textDecoration: "none",
    fontSize: "inherit",
    fontWeight: theme.typography.button.fontWeight,
    fontFamily: "inherit",
    cursor: "pointer",
  },
  cardGroupImage: {
    width: "51.7rem",
    height: "51.3rem",
    [theme.breakpoints.down("1000")]: {
      margin: "auto",
      marginBottom: "1.6rem",
      width: "45.7rem",
      height: "45.3rem",
    },
    [theme.breakpoints.down("800")]: {
      margin: "auto",
      marginBottom: "2.3rem",
      width: "35.7rem",
      height: "35.3rem",
    },
    [theme.breakpoints.down("600")]: {
      width: "21.5rem",
      height: "fit-content",
    },
  },
  applyNow: {
    fontSize: "1rem",
    fontWeight: 600,
  },
}));

const About = (props) => {
  const classes = useStyles();
  const mdDown = useMediaQuery("(max-width:1000px)");
  const history = useHistory();
  const Heading = () => {
    return (
      <Typography variant="h1" className={classes.heading}>
        Global IT Talent <br />
        Perfectly Vetted by Ai
      </Typography>
    );
  };
  const Description = () => {
    return (
      <Typography variant="body1" className={classes.aboutDesc}>
        FindPro's <span className={classes.highlightText}>real-time</span>{" "}
        deep-vetting AI platform enables clients to connect with the top IT
        professionals and tech experts at the push of a button. Whether you're
        seeking individual talent, dedicated teams, or comprehensive technology
        services, FindPro delivers the modern solutions you need to scale your
        business.{" "}
      </Typography>
    );
  };

  const ApplyNow = () => {
    return (
      <Typography variant="body1" className={classes.applyNow}>
        Looking for jobs instead?{" "}
        <Link
          className={classes.highlightText}
          onClick={() => {
            history.push("/candidate/sign-up");
          }}
        >
          Apply for jobs
        </Link>{" "}
      </Typography>
    );
  };

  const ButtonGroup = () => {
    return (
      <div className={classes.buttonGroup}>
        <CustomButton
          onClick={() => {
            history.push("/hire-developer-details");
          }}
          type="button1"
          customClasses={clsx([classes.buttonRoot, classes.buttonText])}
        >
          Hire a Developer
        </CustomButton>
        <CustomButton
          onClick={() => {
            history.push("/hire-team-details");
          }}
          type="button2"
          customClasses={clsx([
            classes.buttonRoot,
            classes.buttonText,
            classes.teamButton,
          ])}
        >
          Hire a Team
        </CustomButton>
      </div>
    );
  };

  const CardGroup = () => {
    return (
      <div className={classes.cardGroupImage}>
        <img src={images.cardGroup} alt="Candidate info card" />
      </div>
    );
  };
  return (
    <>
      {mdDown ? (
        <div className={classes.smallScreenRoot}>
          <Heading />
          <ButtonGroup />
          <ApplyNow />
          <CardGroup />
          <Description />
        </div>
      ) : (
        <div className={classes.root}>
          <div className={classes.textContainer}>
            <Heading />
            <div className={classes.body}>
              <Description />
              <ButtonGroup />
              <ApplyNow />
            </div>
          </div>
          <CardGroup />
        </div>
      )}
    </>
  );
};

About.propTypes = {};

export default About;
