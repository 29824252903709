import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import ContentLoader from "react-content-loader";
import CheckIcon from "@mui/icons-material/Check";

import {
  Button,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import "bootstrap/dist/css/bootstrap.min.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CustomDialog from "app/v2/components/CustomDialog";
import CustomButton from "app/v2/components/CustomButton";

import { messages } from "app/v2/Pages/Utils/constants";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

import { getSubscriptionPlans } from "app/v2/services/client/subscriptions.service";
import configs from "app/v2/Pages/Utils/configs";

const useStyles = makeStyles((theme) => ({
  yourPlanText: {
    fontSize: "32px",
    fontWeight: 600,
  },
  managePlanButton: {
    padding: "12px 28px",
    backgroundColor: "#EDEDED !important",
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  planNameText: {
    fontSize: "18px",
    fontWeight: 600,
    textAlign: "center",
  },
  planDetails: {
    border: "1px solid #EDEDED",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    rowGap: "19px",
  },
  includedPlanText: {
    fontSize: "16px",
    fontWeight: 500,
  },
  planFeatures: {
    listStyleType: "decimal",
    listStylePosition: "inside",
    display: "flex",
    flexDirection: "column",
    rowGap: "19px",
    marginLeft: "12px",
    marginBottom: 0,
    fontSize: "14px",
  },
  featureItems: {
    listStyleType: "disc",
    marginLeft: "36px",
  },
  featureItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontWeight: 600, // Set the font weight to semibold
  },
  checkIcon: {
    color: theme.palette.primary.main, // Set the color of the check icon to blue
  },
  dialogBody: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  dialogBody2: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  dialogActionButton: {
    fontWeight: 600,
    paddingLeft: "28px",
    paddingRight: "28px",
  },

  plansDiv: {
    display: "flex",
    gap: "20px",
    flexWrap: "wrap",
    maxWidth: "795px",
    justifyContent: "center",
  },
  planCard: {
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    border: "0.5px solid #717171",
    maxHeight: "370px",
    maxWidth: "210px",
    flex: "1 1 50%",
    // position: "relative",
    overflowY: "auto",
  },
  currentPlanCard: {
    border: "0.5px solid #717171",
    backgroundColor: "#1c81ff",
  },
  planShowMoreAccordion: {
    width: "100% !important",
    boxShadow: "none",
    "&::before": {
      height: 0,
    },
  },
  currentPlanShowMoreAccordion: {
    backgroundColor: "#F7F7F7",
  },
  planShowMoreAccordionDetails: {
    padding: "8px 0 !important",
  },
  currentPlanDiv: {
    // position: "absolute",
    right: 0,
    top: 0,
    padding: "6px 12px",
    fontSize: "10px",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
  },
  selectPlanButton: {
    fontWeight: 600,
    paddingLeft: "20px",
    paddingRight: "20px",
    margin: "auto",
  },
  planPriceDurationText: {
    fontSize: "30px",
    fontWeight: 800,
    textAlign: "center",
  },
  priceText: {
    fontSize: "12px",
    color: "#8C8C8C",
  },
  planDescription: {
    fontSize: "12px",
    marginTop: "8px",
  },
  divider: {
    marginBottom: 0,
  },
  showMoreText: {
    fontSize: "15px",
    fontWeight: "100px",
    padding: 0,
  },
}));

const PlanDetails = ({ profileSubscriptionData }) => {
  const classes = useStyles();
  const orgId = localStorage.getItem("orgId");
  const loginId = localStorage.getItem("loginId");

  console.log("loginId", loginId);
  console.log("orgId", orgId);

  const [managePlanDialogOpen, setManagePlanDialogOpen] = useState(false);
  const [currentPlanID, setCurrentPlanID] = useState("");
  const [currentPlanIdData, setCurrentPlanIdData] = useState([]);
  const [plans, setPlans] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  const [selectedPlanDetails, setSelectedPlanDetails] = useState();
  const [cPConfirmDialogOpen, setCPConfirmDialogOpen] = useState(false);

  const reorderPlans = (plans) => {
    const selectedPlanIndex = plans.findIndex(
      (plan) => plan?.planId === currentPlanID
    ); 
    if (selectedPlanIndex !== -1) {
      const selectedPlan = plans.splice(selectedPlanIndex, 1)[0];
      plans.unshift(selectedPlan);
    }

    return plans;
  };
  console.log("profileSubscriptionData", profileSubscriptionData);

  useEffect(() => {
    setDataLoading(true)
    getSubscriptionPlans()
      .then((response) => {
        console.log("response", response);
        let plansReceived = response?.data;
        if (isValidArray(plansReceived)) {
          plansReceived = reorderPlans(plansReceived);
          setPlans(plansReceived);
          let filterCurrentPlanId = plansReceived?.filter(
            (plans) => plans?.planId === profileSubscriptionData?.planId
          );

          setCurrentPlanIdData(filterCurrentPlanId);
          setCurrentPlanID(profileSubscriptionData?.planId);

          // console.log("currentPlanID",currentPlanID)
        }
        setDataLoading(false);
      })
      .catch((error) => {
        setDataError(true);
        setDataLoading(false);
      });
  }, [profileSubscriptionData, currentPlanID]);
  console.log("currentPlanIDData", currentPlanIdData);

  const PlanCard = ({ planDetails, isCurrentPlan, onChangePlan }) => {
    const classes = useStyles();

    return (
      <div
        className={clsx([
          classes.planCard,
          isCurrentPlan && classes.currentPlanCard,
        ])}
      >
        {isCurrentPlan ? (
          <div className={classes.currentPlanDiv}>CURRENT PLAN</div>
        ) : (
          <></>
        )}
        <div className={classes.planNameText}>{planDetails?.name || "-"}</div>
        <div>
          {/* <div className={classes.priceText}>Price</div> */}
          <div className={classes.planPriceDurationText}>
            ${planDetails?.price || "-"}
          </div>
          <div className="text-center font-semibold ">
            /{planDetails?.planDurationType || "month"}
          </div>
        </div>
        {!isCurrentPlan ? (
          <CustomButton
            type="button1"
            customClasses={classes.selectPlanButton}
            onClick={() => {
              onChangePlan(planDetails);
            }}
          >
            Select Plan
          </CustomButton>
        ) : (
          <></>
        )}
        {/* <div className={classes.planDescription}>
          {planDetails.description || "-"}
        </div> */}
        <hr className={classes.divider} />

        {/* <Accordion
          className={clsx([
            classes.planShowMoreAccordion,
            isCurrentPlan && classes.currentPlanShowMoreAccordion,
          ])}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={classes.showMoreText}
          >
            SHOW MORE
          </AccordionSummary>
          <AccordionDetails className={classes.planShowMoreAccordionDetails}>
            {isValidArray(planDetails.features) ? (
              <ol className={classes.planFeatures} type="1">
                {planDetails.features.map((featureInfo) => (
                  <li>
                    {featureInfo.name || "-"}
                    {isValidArray(featureInfo.featureitems) ? (
                      <ul className={classes.featureItems}>
                        {featureInfo.featureitems.map((featureItemInfo) => (
                          <li>{featureItemInfo.name || "-"}</li>
                        ))}
                      </ul>
                    ) : (
                      <Alert severity="info" className="w-full">
                        No Feature Items Found
                      </Alert>
                    )}
                  </li>
                ))}
              </ol>
            ) : (
              <Alert severity="info" className="w-full">
                No Features Found
              </Alert>
            )}
          </AccordionDetails>
        </Accordion> */}
        <div className="flex flex-col gap-y-[16px]">
          {isValidArray(planDetails?.features) ? (
            planDetails?.features.map((feature, index) => (
              <div key={index} className={classes.featureItem}>
                <CheckIcon className={classes.checkIcon} />

                {feature?.name}
              </div>
            ))
          ) : (
            <Alert severity="info" className="w-full">
              No Features Found
            </Alert>
          )}
        </div>
      </div>
    );
  };

  const ChangePlanConfirmDialog = ({
    cPConfirmDialogOpen,
    setCPConfirmDialogOpen,
    selectedPlanDetails,
  }) => {
    console.log("selectedPlanDetails", selectedPlanDetails);
    console.log("plaId", selectedPlanDetails);

    const classes = useStyles();

    const onCloseDialog = () => {
      setCPConfirmDialogOpen(false);
    };

    const Title = () => {
      return (
        <div className="text-[16px] font-semibold text-center">Change Plan</div>
      );
    };

    const DialogBody = () => {
      return (
        <div className={classes.dialogBody}>
          <div>
            Are you sure you want to subscribe to plan{" "}
            <b>{selectedPlanDetails?.name}</b> ?
          </div>
          <PayPalScriptProvider
            options={{
              "client-id": configs.paymentConfig.clientKey,
              vault: true,
              intent: "capture",
            }}
          >
            {/* client-id will be stored in the config for now */}
            <PayPalButtons
              style={{
                layout: "horizontal",
                color: "silver",
                shape: "pill",
                label: "subscribe",
              }}
              createSubscription={(data, actions) => {
                return actions.subscription.create({
                  plan_id: `${selectedPlanDetails?.planId}`, // 14 days trial after monlty subsc
                  custom_id: `${orgId}|${loginId}|${selectedPlanDetails?._id}`, // pass the userId
                });
              }}
              onApprove={(data, actions) => {
                console.log("Subscription approved:", data);
                // localStorage.clear();//
                window.location.href = "/client/subscription-payment-success";
              }}
              onError={(err) => {
                console.error("Subscription error:", err);
                window.location.href = "/client/subscription-payment-error";
              }}
            />
          </PayPalScriptProvider>
        </div>
      );
    };

    return (
      <CustomDialog
        open={cPConfirmDialogOpen}
        setOpen={setCPConfirmDialogOpen}
        title={<Title />}
        content={<DialogBody />}
      />
    );
  };

  const PlanList = () => {
    const classes = useStyles();
    // const dummyPlansData = [
    //   {
    //     id: "1",
    //     name: "Premium Plan 1",
    //     price: "$500",
    //     planDurationType: "monthly", // monthly/yearly
    //     description:
    //       "Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu nisi fermentum. Ornare sed venenatis et libero semper sit.",
    //     features: [
    //       {
    //         name: "Feature 1",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 2",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 3",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //     ],
    //     isCurrentPlan: true,
    //   },
    //   {
    //     id: "2",
    //     name: "Premium Plan 2",
    //     price: "$800",
    //     planDurationType: "monthly", // monthly/yearly
    //     description:
    //       "Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu nisi fermentum. Ornare sed venenatis et libero semper sit.",
    //     isCurrentPlan: false,
    //     features: [
    //       {
    //         name: "Feature 1",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 2",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 3",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     id: "3",
    //     name: "Premium Plan 3",
    //     price: "$2000",
    //     planDurationType: "yearly", // monthly/yearly
    //     description:
    //       "Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu nisi fermentum. Ornare sed venenatis et libero semper sit.",
    //     isCurrentPlan: false,
    //     features: [
    //       {
    //         name: "Feature 1",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 2",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 3",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     id: "4",
    //     name: "Premium Plan 3",
    //     price: "$2000",
    //     planDurationType: "yearly", // monthly/yearly
    //     description:
    //       "Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu nisi fermentum. Ornare sed venenatis et libero semper sit.",
    //     isCurrentPlan: false,
    //     features: [
    //       {
    //         name: "Feature 1",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 2",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //       {
    //         name: "Feature 4",
    //         description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //         featureItems: [
    //           {
    //             name: "Feature Item 1",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 2",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //           {
    //             name: "Feature Item 3",
    //             description: "Lorem Ipsum.Lorem Ipsum.Lorem Ipsum.",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    // ];

    // CP - change plan

    const onChangePlan = (planDetails) => {
      setSelectedPlanDetails(planDetails);
      setCPConfirmDialogOpen(true);
    };

    // Shows current plan info first.

    return (
      <>
        {dataLoading ? (
          <ContentLoader
            speed={2}
            width={795}
            height={500}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="20" y="0" rx="3" ry="3" width="795" height="500" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <>
                {isValidArray(plans) ? (
                  <div className={classes.plansDiv}>
                    {plans.map((planDetails) => (
                      <PlanCard
                        planDetails={planDetails}
                        isCurrentPlan={planDetails.planId === currentPlanID}
                        onChangePlan={onChangePlan}
                      />
                    ))}
                  </div>
                ) : (
                  <Alert severity="info" className="w-full">
                    No Plans Found
                  </Alert>
                )}
                {cPConfirmDialogOpen && (
                  <ChangePlanConfirmDialog
                    cPConfirmDialogOpen={cPConfirmDialogOpen}
                    setCPConfirmDialogOpen={setCPConfirmDialogOpen}
                    selectedPlanDetails={selectedPlanDetails}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const ManagePlanDialog = ({
    managePlanDialogOpen,
    setManagePlanDialogOpen,
  }) => {
    const classes = useStyles();

    const Title = () => {
      return (
        <div className="text-[16px] font-semibold text-center">Manage Plan</div>
      );
    };

    const DialogBody = () => {
      return (
        <div className={classes.dialogBody2}>
          <PlanList />
        </div>
      );
    };

    return (
      <CustomDialog
        open={managePlanDialogOpen}
        setOpen={setManagePlanDialogOpen}
        title={<Title />}
        content={<DialogBody />}
      />
    );
  };

  return (
    <div className="flex flex-col gap-y-[36px]">
      <div className="flex justify-between">
        <div className={classes.yourPlanText}>Your Plan</div>
        <Button
          className={classes.managePlanButton}
          onClick={() => setManagePlanDialogOpen(true)}
        >
          Manage Plan
        </Button>
      </div>
      {currentPlanIdData && currentPlanIdData.length > 0 ? (
        currentPlanIdData?.map((feature) => (
          <div className="flex flex-col gap-y-[24px] px-[16px]">
            <div className={classes.planNameText}>
              {feature?.name ?? "No name "}
            </div>
            <div className={classes.planDetails}>
              {/* <div>
            Habitasse urna proin at id nisl nam tellus. Ut id molestie vitae eu
            nisi fermentum. Ornare sed venenatis et libero semper sit. Mauris
            duis nam amet vel. Quis quis ut pretium mauris curabitur donec.
            Tortor id imperdiet morbi lectus sagittis eget. Pellentesque auctor
            ut quis varius. Tellus ante libero auctor turpis bibendum quis porta
            condimentum. Velit egestas quis in scelerisque porttitor amet.{" "}
          </div> */}
              <div className={classes.includedPlanText}>
                INCLUDED IN YOUR PLAN
              </div>

              <ol className={classes.planFeatures}>
                {feature?.features?.map((feat, index) => (
                  <li key={index}>
                    {`${feat?.name ?? "Feature 1"}`}
                    {feat?.featureitems?.map((items, idx) => (
                      <ul className={classes.featureItems}>
                        <li>{items?.name ?? "Feature item 1"}</li>
                      </ul>
                    ))}
                  </li>
                ))}
              </ol>
            </div>
          </div>
        ))
      ) : (
        <Alert severity="info">{"No current subscirbed plans"}</Alert>
      )}
      {managePlanDialogOpen && (
        <ManagePlanDialog
          managePlanDialogOpen={managePlanDialogOpen}
          setManagePlanDialogOpen={setManagePlanDialogOpen}
        />
      )}
    </div>
  );
};

export default PlanDetails;
