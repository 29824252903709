import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import clsx from "clsx";

import { isValidString } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    columnGap: "32px",
  },
  nameAndTimeDetails: {
    display: "flex",
    flexDirection: "column",
    rowGap: "8px",
    flex: 1,
  },
  name: {
    fontSize: "24px",
    fontWeight: 600,
  },
  updatedCreatedText: {
    fontSize: "12px",
    color: "#717171",
  },
  dataElementContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    flex: 2,
  },
  dataElementRow: {
    display: "flex",
  },
  dataElement: {
    minWidth: "288px",
    flex: 1,
  },

  jobTypeDataElement: {
    minWidth: "113px",
    flex: 1,
  },
  dataElementTitle: {
    fontSize: "10px",
    fontWeight: 700,
    color: "#717171",
  },
  dataElementValue: {
    fontSize: "16px",
    fontWeight: 600,
  },
  statusElementValue: {
    color: theme.palette.success.main,
  },
  jobsInfoDiv: {
    padding: "12px",
    backgroundColor: "#F7F7F7",
    display: "flex",
    flex: 1,
  },
}));
const DataElement = ({ title, value, type }) => {
  const classes = useStyles();

  return (
    <>
      {title ? (
        <div
          className={
            type === "job" ? classes.jobTypeDataElement : classes.dataElement
          }
        >
          <div className={classes.dataElementTitle}>{title}</div>
          <div className={classes.dataElementValue}>{value ? value : "-"}</div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const StatusElement = ({ value }) => {
  const classes = useStyles();

  let statusStyle;

  if (isValidString(value)) {
    statusStyle = {
      color: value.toLowerCase() === "active" ? "#14C042" : "#ABABAB",
    };
  }

  return (
    <div className={classes.dataElement}>
      <div className={classes.dataElementTitle}>Status</div>
      <div className={classes.dataElementValue} style={statusStyle}>
        {value ? value : "-"}
      </div>
    </div>
  );
};

const List = ({ data }) => {
  const classes = useStyles();
  let name = "";
  let createdAt, updatedAt;

  if (data?.firstName) name += data?.firstName + " ";
  if (data?.lastName) name += data?.lastName;

  createdAt = data?.createdAt ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  updatedAt = data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;
  return (
    <div className={classes.container}>
      {/* 1 */}
      <div className={classes.nameAndTimeDetails}>
        <div className={classes.name}>{name}</div>
        <div>
          <div className={classes.updatedCreatedText}>Added {createdAt}</div>
          <div className={classes.updatedCreatedText}>
            Last updated {updatedAt}
          </div>
        </div>
      </div>
      {/* 2 */}
      <div className={classes.dataElementContainer}>
        <div className={classes.dataElementRow}>
          <DataElement title="LOCATION" value="India" />
          <DataElement title="USER ROLE" value="" />
        </div>
        <div className={classes.dataElementRow}>
          <StatusElement value="Active" />
          <div className={classes.jobsInfoDiv}>
            <DataElement title="JOBS MANAGING" value="" type="job" />
            <DataElement title="TOTAL JOBS MANAGED" value="" type="job" />
          </div>
        </div>
      </div>
    </div>
  );
};

List.propTypes = {};

export default List;
