import React, { useEffect, useState } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import CustomLabel from "./CustomLabel";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  numberPickerRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #EDEDED",
    height: "50px",
  },
  numberPickerTextField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 600,
    },
  },
}));

const CustomNumberPicker = React.memo(
  ({ id, label, defaultValue, min, max, onChange }) => {
    const classes = useStyles();
    const [value, setValue] = useState(defaultValue);

    const handleIncrement = () => {
      let updatedValue = value + 1;
      if (updatedValue <= max) {
        onChange(id, updatedValue);
        setValue(updatedValue);
      }
    };

    const handleDecrement = () => {
      let updatedValue = value - 1;
      if (updatedValue >= min) {
        setValue(updatedValue);
        onChange(id, updatedValue);
      }
    };

    React.useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    React.useEffect(() => {
      // console.log({ value });
    }, [value]);

    return (
      <div>
        {label && <CustomLabel label={label} />}
        <div className={classes.numberPickerRoot}>
          <IconButton onClick={handleDecrement}>
            <RemoveIcon fontSize="small" />
          </IconButton>
          <TextField
            value={value}
            variant="outlined"
            type="number"
            inputProps={{
              min,
              max,
            }}
            className={classes.numberPickerTextField}
          />
          <IconButton onClick={handleIncrement}>
            <AddIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  }
);

export default CustomNumberPicker;
