import storageUtil from "./storageUtil";

export const PROFILE_MIN_VIEW = 0;
export const MIN_JOB_POST = 0;

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
};

export const dateDiff= (a,b) => dateDiffInDays(a, b);

export const isLoggedIn = () => {
  const user = storageUtil.getItem("user");
  const accountType = getAccountType();
  if (user && user.role === accountType) {
    return true;
  }
  return false;
};

export const validSubscriber = () => {
  const user = storageUtil.getItem("user");
  const subscribeEnabled = user?.subscribeEnabled;
  const isSubscribed = user?.isSubscribed;
  if (subscribeEnabled === true || subscribeEnabled === "true") {
    return isSubscribed;
  }
  return true;
};

export const getUserInfo = () => {
  return storageUtil.getItem("user");
};

export const isUserSubscribed = () => {
  const user = storageUtil.getItem("user");
  return (user?.isSubscribed === true || user?.isSubscribed === "true");
};

export const isSubscriptionExpired = () => {
  let date = "";
  const { expiry_date } = getUserInfo();
  date = !expiry_date ? new Date((new Date()).setDate(new Date().getDate()-1)).toDateString() : expiry_date;
  
  return new Date(date) < new Date();
};

export const getNoOfDaysRemaining = () => {
  let date;
  const { expiry_date } = getUserInfo();
  // date = !expiry_date ? new Date((new Date()).setDate(new Date().getDate()+3)) : new Date(expiry_date);
  date = !expiry_date ? new Date() : new Date(expiry_date);
  return dateDiff(date, new Date());
};

export const getVendorJobView = () => {
  const { min_job_view=0 } = getUserInfo();
  return parseInt(min_job_view, 0);
};

export const getVendorMaxCanLimit = () => {
  const { max_can_limit=0 } = getUserInfo();
  return parseInt(max_can_limit, 0);
};

export const getEmpCurrentResView = () => {
  const { min_resume_view=0 } = getUserInfo();
  return parseInt(min_resume_view, 0)
};

export const getEmpCurrentJPLimit = () => {
  const { min_job=0 } = getUserInfo();
  return parseInt(min_job, 0);
};

export const isEmployerProfileViewMax = () => {
  const isEmployer = getAccountType() === "employers";
  const { min_resume_view=0 } = getUserInfo();
  return isEmployer && min_resume_view <= PROFILE_MIN_VIEW
};

export const isEmployerJobPostsMax = () => {
  const isEmployer = getAccountType() === "employers";
  const { min_job=0 } = getUserInfo();
  return isEmployer && min_job <= MIN_JOB_POST;
};

export const isProfileComplete = () => {
  const user = storageUtil.getItem("user");
  return JSON.parse(user?.isProfileComplete || "false");
};

export const isNewSSOUser = () => {
  const user = storageUtil.getItem("user");
  return JSON.parse(user?.isNewSSOUser || "false");
};

export const updateProfileComplete = (isProfileComplete) => {
  const user = storageUtil.getItem("user");
  user.isProfileComplete = isProfileComplete;
  storageUtil.setItem("user", user);
};

export const getAccountType = () => {
  if (document.location && document.location.pathname) {
    const splitPathName = document.location.pathname.split("/");
    if (splitPathName.length > 1) {
      return splitPathName[1];
    } else {
      return "";
    }
  } else {
    return "";
  }
};
