import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { resetPassword } from "./resetpassword.service";
import images from "app/v2/images/common";
import { useSnackbar } from "notistack";
import { Button, Typography } from "@mui/material";
import PasswordFieldComponent from "../PasswordFieldComponent";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation } from "react-router-dom";
import clsx from "clsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  container: {
    width: "603px",
    margin: "auto",
    padding: "120px 60px",
    boxSizing: "content-box",
  },
  logo: {
    width: "156px",
    height: "42px",
    marginBottom: "48px",
  },
  title: {
    fontSize: "36px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  submitButton: {
    marginTop: "72px",
    width: "74px",
    boxSizing: "content-box",
    marginLeft: "auto",
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
    padding: "10px 15px 10px 20px",
    "&:disabled": {
      backgroundColor: "grey !important",
      borderColor: "#EDEDED !important",
    },
  },
  passwordChangedText: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "48px",
    padding: "16px 0",
  },
  loginNow: {
    width: "100%",
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

const ResetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const pathName = location?.pathname?.split("/");
  const accountType = pathName[1];
  const { enqueueSnackbar ,closeSnackbar} = useSnackbar();
  const token = props?.match?.params?.token;

  //   if (!token) {
  //     props.history.push("/");
  //   }
  const initialValues = {
    password: "",
    confirmpassword: "",
  };
  const [viewType, setViewType] = useState("resetPassword");

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    resetPassword({ ...values, token })
      .then((res) => {
        resetForm({ values: "" });
        setViewType("passwordChanged");
      })
      .catch((err) => {
        // enqueueSnackbar(err.message, {
        //   variant: "error",
        // });

        const key = enqueueSnackbar(err.message, {
          variant: "error",autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
          ),
        });
        console.error(err);
      });
  };

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "password"
      ),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const viewTypes = {
    resetPassword: {
      title: "Reset Password",
      body: (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className={`${classes.form} dez-form`}>
              <PasswordFieldComponent name="password" label="New password" />
              <PasswordFieldComponent
                name="confirmpassword"
                label="Confirm password"
              />
              <Button
                type="submit"
                disabled={isSubmitting}
                className={clsx([classes.actionButton, classes.submitButton])}
              >
                Submit
                <ArrowForwardIcon />
              </Button>
            </Form>
          )}
        </Formik>
      ),
    },
    passwordChanged: {
      title: "Password changed successfully!",
      body: (
        <div>
          <Typography className={classes.passwordChangedText}>
            Please login to your email account again.
          </Typography>
          <Button
            className={classes.loginNow}
            onClick={() => {
              history.push(`/${accountType}/login`);
            }}
          >
            Login now
          </Button>
        </div>
      ),
    },
  };

  return (
    <div className={classes.container}>
      <img src={images?.logo_v2} className={classes.logo} />
      <Typography className={classes.title}>
        {viewTypes[viewType].title}
      </Typography>
      <>{viewTypes[viewType].body}</>
    </div>
  );
};
export default ResetPassword;
