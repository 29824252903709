import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { Typography, Link, Grid, CircularProgress, Alert } from "@mui/material";
import { Overview, AboutCompany, Locations } from "./components";
import { Line } from "app/v2/Pages/common";
import CustomButton from "app/v2/components/CustomButton";

import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import { IconLightPlay } from "app/v2/Pages/SVGs/IconLightPlay";
import img_icon_light_arrow_top from "app/v2/Pages/images/img_icon_light_arrow_top.svg";
import { scrollToTop } from "app/v2/Pages/Utils/utilFunctions";

import { messages } from "app/v2/Pages/Utils/constants";

import { getClientInfoPublic } from "app/v2/services/client/common.service";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  knowMoreButton: {
    width: "100%",
    marginBottom: "24px",
    padding: "12px",
    fontSize: "16px",
    fontWeight: 600,
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "16px 48px",
    width: "956px",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    rowGap: "36px",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
  line: {
    marginBottom: "0 !important",
  },
}));

const clientDummyData = {
  overview: {
    id: "",
    image: "/company_image.png",
    agencyName: "Haus Tech",
    linkedinUrl: "https://linkedin.com",
    facebookUrl: "https://facebook.com",
    qrCode: "https://dev.findpro.ai/consultant/",
    activeTime: "2023-04-06T05:17:11.955Z",
    status: "available",
    consultantCount: 8,
    companySizeRange: { start: 15, end: 30 },
    website: "haustech.io",
    country: "Singapore",
    city: "Singapore City",
    state: "Central Singapore",
    timezone: "Singapore Standard Time (GMT + 8.0)",
    summary:
      "Nullam neque donec quam auctor porttitor. Tortor aliquet sit aliquet vel morbi commodo adipiscing. Enim turpis nisl rhoncus lectus cursus justo placerat magna. Morbi elementum scelerisque felis eros amet vel pellentesque et. Urna curabitur nisi consequat mollis arcu eu nunc dictum. Dignissim id aliquam justo ut ultrices pharetra. Sit est suspendisse laoreet sed. Tristique ut pulvinar sagittis ultricies. Diam ut vestibulum egestas ipsum elementum mi in posuere neque felis risus in egestas rhoncus.",
    companyVideoAvailable: true,
    backgroundCheck: true,
  },
  profileDesc: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
  profileCulture: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
  companyLocation: [
    {
      city: "-",
      state: "Calilabad Rayonu",
      country: "Azerbaijan",
      timezone: "China Taiwan Time (GMT+8:00)",
      _id: "655d9f97af03dc01bd95bd21",
    },
  ],
};

const PublicClientDetail = ({ clientID }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [orgData, setOrgData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  useEffect(() => {
    getClientInfoPublic(clientID)
      .then((res) => {
        console.log({ res });
        setDataLoading(false);
        setOrgData(res?.data?.orgDetails);
      })
      .catch(() => {
        setDataLoading(false);
        setDataError(true);
      });
  }, []);

  useEffect(() => {
    console.log({ orgData });
  }, [orgData]);

  return (
    <Grid container>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <>
              <Grid item className={classes.leftView}>
                <CustomButton
                  type="button1"
                  customClasses={classes.knowMoreButton}
                  onClick={() => {
                    history.push("/client/onboarding");
                  }}
                >
                  Register as Client
                  <CallMadeOutlinedIcon
                    fontSize="small"
                    className={classes.rightArrowIcon}
                  />
                </CustomButton>
              </Grid>
              <Grid item id="sectionContent" className={classes.rightView}>
                <Overview data={orgData} clientID={clientID} />
                <hr className={classes.line} />
                <div className="flex flex-col gap-y-[24px]">
                  <AboutCompany
                    profileData={
                      orgData?.companyDesc?.htmlString ?? orgData?.companyDesc
                    }
                    cultureData={
                      orgData?.culture?.htmlString ?? orgData?.culture
                    }
                  />
                </div>
                <Locations data={orgData?.companyLocation} />
                <div
                  className="flex items-center gap-x-[10px] pl-[12px] pt-[24px] cursor-pointer"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <img src={img_icon_light_arrow_top} />
                  <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                    Back to top
                  </div>
                </div>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

PublicClientDetail.propTypes = {};

export default PublicClientDetail;
