import React from "react";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const BootstrapTooltip = styled(({ className, bg, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, bg }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: bg,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: bg,
    color: bg === "white" ? "black" : "white",
  },
}));

const CustomTooltip = (props) => {
  const { title, arrow, placement, bg, children } = props;

  return (
    <BootstrapTooltip
      title={title}
      arrow={arrow}
      placement={placement}
      bg={bg ?? "black"} // default is black
    >
      {children}
    </BootstrapTooltip>
  );
};

CustomTooltip.propTypes = {};

export default CustomTooltip;
