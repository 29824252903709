import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import storageUtil from "app/common/utils/storageUtil";
import {
  getResumeSettings,
  updateResumeSettings,
} from "./resume-settings.service";

export const getResumeSettingsThunk = createAsyncThunk(
  "resume-settings/get",
  async (request, thunkAPI) => {
    return getResumeSettings();
  }
);

export const updateResumeSettingsThunk = createAsyncThunk(
  "resume-settings/put",
  async (request, thunkAPI) => {
    const { _id } = storageUtil.getItem("user");
    if (!_id) {
      throw new Error("Check if you're logged in.");
    }
    const reqBody = { ...request, _id };
    return updateResumeSettings(reqBody).then(({ isProfileComplete }) => {
      thunkAPI.dispatch(profileComplete(isProfileComplete));
      return { isProfileComplete };
      // updateProfileComplete(isProfileComplete);
    });
  }
);
