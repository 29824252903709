import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";
import CustomTooltip from "app/v2/components/CustomTooltip";

import { updateHackerRankAPIKey } from "app/v2/services/client/profileSettings.service";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import CloseIcon from "@mui/icons-material/Close";

import { messages } from "app/v2/Pages/Utils/constants";
import { isValidString } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    fontSize: "16px",
    fontWeight: 600,
    padding: "12px 28px",
  },
}));

const Integrations = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const personalDataBuf = useSelector(
    (state) => state.clientProfileInfo.clientInfo.employer
  );
  const [hackerRankAPIKey, setHackerRankAPIKey] = useState("");

  const onSaveClick = () => {
    // enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
    //   variant: "info",
    // });

    const key = enqueueSnackbar(messages.ACTION_LOADING_MESSAGE, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });
    updateHackerRankAPIKey({
      testConfigs: { hackerRankApiKey: hackerRankAPIKey },
    })
      .then((res) => {
        // enqueueSnackbar("Your hacker rank key has been updated successfully!", {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(
          "Your hacker rank key has been updated successfully!",
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      })

      .catch(() => {
        // enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
        //   variant: "error",
        // });
        const key = enqueueSnackbar(messages?.GENERIC_ERROR_MESSAGE, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  useEffect(() => {
    // write the logic to check if data already exists in redux store after the state values are corrected(Above value accessed in personalDataBuf).
    dispatch(getEmployerInfoThunk())
      .unwrap()
      .then((res) => {
        const hRAPIKey =
          res?.data?.clientDetail?.orgDetails?.testConfigs?.hackerRankApiKey;

        if (isValidString(hRAPIKey)) setHackerRankAPIKey(hRAPIKey);
      })
      .catch((err) => {
        // enqueueSnackbar(
        //   "Something went wrong while fetching HackerRank api key,Please try again later.",
        //   {
        //     variant: "error",
        //   }
        // );

        const key = enqueueSnackbar(
          "Something went wrong while fetching HackerRank api key,Please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  }, []);
  return (
    <div className="flex flex-col gap-y-[8px] w-fit">
      <CustomTextfield
        id="hackerRankApiKey"
        label="Hacker Rank API Key"
        value={hackerRankAPIKey}
        onChange={(id, value) => {
          setHackerRankAPIKey(value);
        }}
      />
      <CustomTooltip
        title={hackerRankAPIKey === "" && "Please enter the hacker rank key."}
      >
        <div className="flex justify-end">
          <CustomButton
            type="button1"
            customClasses={classes.actionButton}
            onClick={onSaveClick}
            disabled={hackerRankAPIKey === ""}
          >
            Save
          </CustomButton>
        </div>
      </CustomTooltip>
    </div>
  );
};

Integrations.propTypes = {};

export default Integrations;
