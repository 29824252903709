import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../images/hire_developer_team/common";
import devImages from "../../../images/hire_developer_team/developer";

const useStyles = makeStyles((theme) => ({
  featuresRoot: {
    marginBottom: "154px",
  },
  featureDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "96px",
    border: "1px solid #000000",
    width: "90%",
    margin: "auto",
    marginBottom: "79px",
    padding: "60px",
    [theme.breakpoints.down("1000")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("500")]: {
      padding: "40px 28px 0 28px",
    },
  },
  featureImage: {},
  featureInfo: {
    maxWidth: "613px",
    width: "100%",
  },
  featureTitle: {
    marginBottom: "36px",
    [theme.breakpoints.down("1600")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.down("1400")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("1200")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("1000")]: {
      fontSize: "2rem",
      textAlign: "center",
      marginTop: "40px",
    },
  },
  featureDetails: {},
  featureEntry: {
    maxWidth: "572px",
    width: "100%",
    display: "flex",
  },
  featureIcon: {
    marginTop: "2px",
    [theme.breakpoints.down("300")]: {
      marginRight: "8px",
    },
  },
  featureEntryTextDiv: {
    width: "88%",
    marginLeft: "auto",
    marginBottom: "40px",
  },
  featureText: {
    fontWeight: 500,
    marginBottom: "5px",
  },
  featureSubText: {
    fontWeight: 300,
  },
}));

const Features = (props) => {
  const classes = useStyles();
  const data = [
    {
      title: "High quality/cost ratio",
      image: devImages.dev_feature_1,
      details: [
        {
          icon: commonImages.dollar_icon,
          text: "Hire deeply vetted developers at half the cost",
          subText:
            "Hire the top 1% of 2 million+ developers from 150+ countries who have applied to FindPro.",
        },
        {
          icon: commonImages.code_icon1,
          text: "100+ skills available",
          subText:
            "Hire React, Node, Python, Angular, Swift, React Native, Android, Java, Rails, Golang, DevOps, ML, Data Engineers, and more.",
        },
        {
          icon: commonImages.verified_icon,
          text: "100+ skills available",
          subText:
            "Hire React, Node, Python, Angular, Swift, React Native, Android, Java, Rails, Golang, DevOps, ML, Data Engineers, and more.",
        },
      ],
    },
    {
      title: "Rigorous Vetting",
      image: devImages.dev_feature_2,
      details: [
        {
          icon: commonImages.todo_check_icon,
          text: "5+ hours of tests and interviews",
          subText:
            "More rigorous than Silicon Valley job interviews. We test for 100+ skills, data structures, algorithms, systems design, software specializations & frameworks.",
        },
        {
          icon: commonImages.badge_icon,
          text: "Seniority tests",
          subText:
            "We select excellent communicators who can proactively take ownership of business and product objectives without micromanagement.",
        },
      ],
    },
    {
      title: "Effective collaboration",
      image: devImages.dev_feature_3,
      details: [
        {
          icon: commonImages.bell_icon,
          text: "Daily updates",
          subText:
            "FindPro’s Workspace gives you even more visibility into your remote developer’s work with automatic time tracking & virtual daily stand-ups.",
        },
        {
          icon: commonImages.visual_data_icon,
          text: "Easy administration",
          subText:
            "High visibility makes FindPro developers easy to engage and ensures their work aligns with what’s valuable to you.",
        },
        {
          icon: commonImages.globe_icon,
          text: "Match your timezone",
          subText:
            "Our developers match your time zone and overlap a minimum of 4 hours with your workday.",
        },
      ],
    },
  ];

  const FeatureDiv = ({ featureData, featureNumber }) => {
    return (
      <div className={classes.featureDiv}>
        <div className={classes.featureImage}>
          <img src={featureData.image} alt={`feature-image-${featureNumber}`} />
        </div>
        <div className={classes.featureInfo}>
          <Typography variant="h2" className={classes.featureTitle}>
            {featureData.title}
          </Typography>
          <div className={classes.featureDetails}>
            {featureData.details.map((featureEntry, index) => (
              <div className={classes.featureEntry}>
                <div className={classes.featureIcon}>
                  <img
                    src={featureEntry.icon}
                    alt={`featureEntry-icon-${index}`}
                  />
                </div>
                <div className={classes.featureEntryTextDiv}>
                  <Typography variant="body1" className={classes.featureText}>
                    {featureEntry.text}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.featureSubText}
                  >
                    {featureEntry.subText}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.featuresRoot}>
      {data.map((feature, index) => (
        <FeatureDiv featureData={feature} featureNumber={index} />
      ))}
    </div>
  );
};

Features.propTypes = {};

export default Features;
