import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormControl,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomButton from "app/v2/components/CustomButton";
import CustomSwitch from "app/v2/components/filters/CustomSwitch";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  capitalizeFirstLetter,
  isValidObject,
  isValidString,
} from "../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  salaryTypeHeading: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  salaryTypesDiv: {
    display: "flex",
    columnGap: "20px",
    marginBottom: "48px",
  },
  salaryTypeButton: {
    maxWidth: "91px",
    width: "100%",
    height: "44px",
    padding: "12px 20px",
    border: "1px solid #EDEDED",
    fontSize: "16px",
    fontWeight: 600,
  },
  highlightButton: {
    border: 0,
    backgroundColor: "#F4F8FF !important",
    color: `${theme.palette.primary.main} !important`,
  },
  salaryValueHeading: {
    fontSize: "18px",
    fontWeight: 700,
    marginBottom: "24px",
  },
  salaryValueTypeDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "26px",
  },
  salaryValueTypeName: {
    fontSize: "16px",
    fontWeight: 600,
  },
  highlightSalaryValueType: {
    color: theme.palette.secondary.light,
  },
  fixedRateText: {
    marginRight: "24px",
  },
  setRangeText: {
    marginLeft: "24px",
  },
  fixedOrRangeSalaryDiv: {
    display: "flex",
  },
  fixedSalaryDiv: {},
  rangeSalaryDiv: {
    display: "flex",
    flexDirection: "row",
    // alignItems: "flex-start",
    columnGap: "20px",
  },
  salaryOtherElement: {
    position: "relative",
    top: "17px",
  },
  salaryText: {
    fontSize: "18px",
    fontWeight: 700,
    marginLeft: "24px",
  },
  numberPickerAdornedStart: {
    fontSize: "24px",
    fontWeight: 600,
  },

  numberPickerText: {
    fontWeight: 400,
    fontSize: "18px",
    marginLeft: "10px",
    boxShadow: "none !important",
  },
  numberPickerAdornedEnd: {
    fontSize: "18px",
    fontWeight: 700,
    color: theme.palette.secondary.light,
  },
}));

const SalaryPicker = ({ heading, data, setData }) => {
  const classes = useStyles();
  const salaryTypesDefaultRates = [
    {
      type: "hourly",
      fixedRate: 24,
      range: {
        min: 24,
        max: 32,
      },
    },
    {
      type: "monthly",
      fixedRate: 3600,
      range: {
        min: 2600,
        max: 3600,
      },
    },
    {
      type: "annual",
      fixedRate: 80000,
      range: {
        min: 70000,
        max: 90000,
      },
    },
  ];
  const salaryNameDescMapper = {
    hourly: "per hour",
    monthly: "per month",
    annual: " annually",
  };
  const [salaryTypeSelectedInfo, setSalaryTypeSelectedInfo] = useState(); // entire info of selected salary type. eg : {type: "Hourly", fixedRate: 24,range: {min: 24, max: 32}}.
  const [salaryValue, setSalaryValue] = useState("fixed"); // fixed or range are the two possible values for this, onChange of number textfields under this gets updated in salaryTypeSelectedInfo state.
  const [fixedRateErrorMessage, setFixedRateErrorMessage] = useState("");
  const [minRangeErrorMessage, setMinRangeErrorMessage] = useState("");
  const [maxRangeErrorMessage, setMaxRangeErrorMessage] = useState("");
  const getDefaultSalaryTypeRates = (salaryType) => {
    const salaryTypeDetails = salaryTypesDefaultRates.find(
      (salaryTypeInfo) => salaryTypeInfo?.type === salaryType
    );
    return salaryTypeDetails;
  };

  const onChange = (salaryTypeSelectedInfo, salaryValue) => {
    const salaryDetails = {};
    salaryDetails.type = salaryTypeSelectedInfo?.type;
    if (salaryValue === "fixed")
      salaryDetails.fixedRate = salaryTypeSelectedInfo?.fixedRate;
    else salaryDetails.range = salaryTypeSelectedInfo?.range;
    setData(salaryDetails);
  };

  const onSalaryTypeChange = (salaryTypeInfo) => {
    setSalaryTypeSelectedInfo(salaryTypeInfo); // Setting it to the default rates of the changed salary type.
    onChange(salaryTypeInfo, salaryValue);
  };

  const onSalaryValueChange = () => {
    const updatedSalaryValue = salaryValue === "fixed" ? "range" : "fixed";
    const salaryType = salaryTypeSelectedInfo?.type;
    const salaryTypeDetails = getDefaultSalaryTypeRates(salaryType); // on salary value change also we have to set the rates back to the default rates.

    setSalaryValue(updatedSalaryValue);
    setSalaryTypeSelectedInfo(salaryTypeDetails);
    setFixedRateErrorMessage("");
    setMinRangeErrorMessage("");
    setMaxRangeErrorMessage("");
    onChange(salaryTypeDetails, updatedSalaryValue);
  };

  const onFixedRateInputChange = (value) => {
    let updatedSalaryTypeSelectedInfo = { ...salaryTypeSelectedInfo };
    let fixedRateMessage;
    value = parseInt(value);
    updatedSalaryTypeSelectedInfo.fixedRate = value;
    fixedRateMessage =
      isNaN(value) || value <= 0 ? messages.FIXED_RATE_ERROR : "";
    setFixedRateErrorMessage(fixedRateMessage);
    setSalaryTypeSelectedInfo(updatedSalaryTypeSelectedInfo);
    onChange(updatedSalaryTypeSelectedInfo, salaryValue);
  };

  const onRangeInputChange = (value, rangeType) => {
    let updatedSalaryTypeSelectedInfo = { ...salaryTypeSelectedInfo };
    let minMessage, maxMessage;
    value = parseInt(value);

    if (rangeType === "min") {
      updatedSalaryTypeSelectedInfo.range.min = value;
      if (isNaN(value) || value >= updatedSalaryTypeSelectedInfo.range.max) {
        minMessage = messages.MIN_RANGE_ERROR;
        maxMessage = "";
      } else minMessage = "";
    } else {
      updatedSalaryTypeSelectedInfo.range.max = value;
      if (isNaN(value) || value <= updatedSalaryTypeSelectedInfo.range.min) {
        maxMessage = messages.MAX_RANGE_ERROR;
        minMessage = "";
      } else maxMessage = "";
    }

    setMinRangeErrorMessage(minMessage);
    setMaxRangeErrorMessage(maxMessage);
    setSalaryTypeSelectedInfo(updatedSalaryTypeSelectedInfo);
    onChange(updatedSalaryTypeSelectedInfo, salaryValue);
  };

  useEffect(() => {
    let salaryTypeReceived = data?.type;
    let salaryValueReceived, salaryTypeInfo;

    if (isValidString(salaryTypeReceived) && (data?.fixedRate || data?.range)) {
      salaryTypeReceived = salaryTypeReceived.toLowerCase();
      salaryTypeInfo = salaryTypesDefaultRates.find(
        (salaryTypeDetail) => salaryTypeDetail?.type === salaryTypeReceived
      );
      // Updating salaryTypeInfo with info received.

      if (data?.fixedRate) {
        salaryValueReceived = "fixed";
        salaryTypeInfo.fixedRate = data?.fixedRate;
      } else {
        salaryValueReceived = "range";
        salaryTypeInfo.range = data?.range;
      }

      setSalaryValue(salaryValueReceived);
      setSalaryTypeSelectedInfo(salaryTypeInfo);
    }
  }, [data]);

  return (
    <div>
      <Typography className={classes.salaryTypeHeading}>
        {heading || "Select salary type"}
      </Typography>
      <div className={classes.salaryTypesDiv}>
        {salaryTypesDefaultRates.map((salaryType, idx) => (
          <CustomButton
            key={idx}
            customClasses={clsx([
              classes.salaryTypeButton,
              salaryTypeSelectedInfo?.type === salaryType?.type &&
                classes.highlightButton,
            ])}
            onClick={() => {
              onSalaryTypeChange(salaryType);
            }}
          >
            {capitalizeFirstLetter(salaryType.type)}
          </CustomButton>
        ))}
      </div>
      <hr />
      {salaryTypeSelectedInfo && (
        <div>
          <Typography className={classes.salaryValueHeading}>
            Salary value
          </Typography>
          <div className={classes.salaryValueTypeDiv}>
            <Typography
              className={clsx([
                classes.salaryValueTypeName,
                salaryValue === "fixed" && classes.highlightSalaryValueType,
                classes.fixedRateText,
              ])}
            >
              Fixed rate
            </Typography>
            <CustomSwitch onChange={onSalaryValueChange} />
            <Typography
              className={clsx([
                classes.salaryValueTypeName,
                salaryValue === "range" && classes.highlightSalaryValueType,
                classes.setRangeText,
              ])}
            >
              Set range
            </Typography>
          </div>
          <div className={classes.fixedOrRangeSalaryDiv}>
            {salaryValue === "fixed" ? (
              <FormControl
                sx={{ width: "25ch" }}
                error={Boolean(fixedRateErrorMessage)}
                className={classes.fixedSalaryDiv}
                variant="outlined"
              >
                <OutlinedInput
                  key={`${salaryTypeSelectedInfo?.type}-fixed-rate`}
                  id={`${salaryTypeSelectedInfo?.type}-fixed-rate`}
                  type="number"
                  startAdornment={"$"}
                  value={salaryTypeSelectedInfo?.fixedRate}
                  onChange={(event) => {
                    onFixedRateInputChange(event.target.value);
                  }}
                  inputProps={{
                    min: 0,
                  }}
                  classes={{
                    adornedStart: classes.numberPickerAdornedStart,
                    input: classes.numberPickerText,
                  }}
                />
                {fixedRateErrorMessage && (
                  <FormHelperText id="component-error-text">
                    {fixedRateErrorMessage}
                  </FormHelperText>
                )}
              </FormControl>
            ) : (
              <div className={classes.rangeSalaryDiv}>
                <FormControl
                  error={Boolean(minRangeErrorMessage)}
                  variant="outlined"
                  sx={{ width: "25ch" }}
                >
                  <OutlinedInput
                    id={`${salaryTypeSelectedInfo?.type}-range-min`}
                    key={`${salaryTypeSelectedInfo?.type}-range-min`}
                    type="number"
                    startAdornment={
                      <span className={classes.numberPickerAdornedStart}>
                        $
                      </span>
                    }
                    endAdornment={
                      <span className={classes.numberPickerAdornedEnd}>
                        min
                      </span>
                    }
                    value={salaryTypeSelectedInfo?.range?.min}
                    onChange={(event) => {
                      onRangeInputChange(event.target.value, "min");
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    classes={{
                      input: classes.numberPickerText,
                    }}
                  />
                  {minRangeErrorMessage && (
                    <FormHelperText id="component-error-text">
                      {minRangeErrorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
                <div className={classes.salaryOtherElement}>-</div>

                <FormControl
                  error={Boolean(maxRangeErrorMessage)}
                  variant="outlined"
                  sx={{ width: "25ch" }}
                >
                  <OutlinedInput
                    id={`${salaryTypeSelectedInfo?.type}-range-max`}
                    key={`${salaryTypeSelectedInfo?.type}-range-max`}
                    type="number"
                    startAdornment={
                      <span className={classes.numberPickerAdornedStart}>
                        $
                      </span>
                    }
                    endAdornment={
                      <span className={classes.numberPickerAdornedEnd}>
                        max
                      </span>
                    }
                    value={salaryTypeSelectedInfo?.range?.max}
                    onChange={(event) => {
                      onRangeInputChange(event.target.value, "max");
                    }}
                    inputProps={{
                      min: 0,
                    }}
                    classes={{
                      input: classes.numberPickerText,
                    }}
                  />
                  {maxRangeErrorMessage && (
                    <FormHelperText id="component-error-text">
                      {maxRangeErrorMessage}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            )}
            <Typography
              className={clsx([classes.salaryText, classes.salaryOtherElement])}
            >
              {salaryNameDescMapper[salaryTypeSelectedInfo?.type] ?? ""}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

SalaryPicker.propTypes = {};

export default SalaryPicker;
