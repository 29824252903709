import React from "react";
import PropTypes from "prop-types";
import PandaDocDetails from "./PandaDocDetails";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  contractDetailContainer: {
    padding: "12px 36px 36px",
  },
}));
const ContractDetail = (props) => {
  const classes = useStyles();
  const docsData = [
    {
      docName:
        "CarolHemmings.CompanyABC.EmploymentAgreement - Open in Pandadoc.13241",
      createdDate: "March 2, 2023",
      docNo: "FP23147",
      docType: "CONTRACT",
    },
    {
      docName:
        "CarolHemmings.CompanyABC.EmploymentAgreement - Open in Pandadoc.13241",
      createdDate: "March 2, 2023",
      docNo: "FP23147",
      docType: "NON-DISCLOSURE AGREEMENT",
    },
  ];
  const isLastDoc = (index) => index === docsData?.length - 1;
  return (
    <div className={classes.contractDetailContainer}>
      {docsData?.map((docData, index) => (
        <>
          <PandaDocDetails data={docData} />
          {!isLastDoc() && <hr />}
        </>
      ))}
    </div>
  );
};

ContractDetail.propTypes = {};

export default ContractDetail;
