import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "configs";

export const getPostedJobs = async () => {
  return fetchWrapper(`${configs.APIUrl}/employers/posted-jobs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getAllTest = async (testName) => {
  return fetchWrapper(`${configs.APIUrl}/employers/get-all-tests/${testName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getAllTestsByJobId = async (jobId) => {
  return fetchWrapper(
    `${configs.APIUrl}/employers/candidate-test-list/${jobId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getJobDetail = async (job_id) => {
  return fetchWrapper(`${configs.APIUrl}/employers/job-detail/${job_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getAppliedCandidates = async (job_id) => {
  return fetchWrapper(
    `${configs.APIUrl}/employers/applied-candidates/${job_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getAppliedVendorCandidates = async (job_id) => {
  return fetchWrapper(`${configs.APIUrl}/employers/applied-vendors/${job_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getInvitedCandidates = async (job_id) => {
  return fetchWrapper(
    `${configs.APIUrl}/employers/invited-candidates/${job_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateJobStatus = async (job) => {
  return fetchWrapper(`${configs.APIUrl}/employers/update-job-status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: job,
  });
};

export const updateJob = async (job) => {
  return fetchWrapper(`${configs.APIUrl}/employers/update-job`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: job,
  });
};

export const scheduleMeeting = async (req) => {
  return fetchWrapper(`${configs.APIUrl}/employers/schedule-meeting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: req,
  });
};

export const inviteTest = async (req) => {
  return fetchWrapper(`${configs.APIUrl}/employers/invite-test`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: req,
  });
};

export const cancelMeeting = async (req) => {
  return fetchWrapper(`${configs.APIUrl}/employers/cancel-meeting`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: req,
  });
};

export const updateApplicationStatus = async (req) => {
  return fetchWrapper(`${configs.APIUrl}/employers/application-status`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: req,
  });
};

export const scheduleEmployer = async (req) => {
  return fetchWrapper(`${configs.APIUrl}/sales/enquiry-book-slot`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: req,
  });
};
