import React from "react";

import { Text } from "..";
import { handleSectionNavigation } from "../../Utils/handleSectionNavigation";

const CustomListItem = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-3 items-start justify-start w-full">

        {props?.sections.map((section, index) => (
            <div key={index} className="flex flex-row gap-2 items-center justify-start px-0 py-2.5 w-full cursor-pointer"
            onClick={()=>{
              handleSectionNavigation(section?.id)
            }}
            >
              {section?.icon}
              <div className="flex flex-col items-center justify-start">
                <Text
                  className="font-semibold text-gray-900"
                >
                  {section?.title}
                </Text>
              </div>
            </div>
        ))}

          
        </div>
      </div>
    </>
  );
};

CustomListItem.defaultProps = {};

export default CustomListItem;
