import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

import { Typography, Link } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const useStyles = makeStyles((theme) => ({
  body: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px 84px",
    textAlign: "center",
    height: "400px",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    flexWrap: "wrap",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "33px",
  },
  successIconDiv: {
    width: "76px",
    height: "76px",
    padding: "14.1px",
    borderRadius: "100px",
    backgroundColor: "#EBFFF0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  contentTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "12px",
  },
  arrowIcon: {
    width: "20px",
    height: "20px",
  },
  subscriptionLink: {
    textDecoration: "underline !important",
    color: `${theme.palette.primary.main} !important`,
    cursor: "pointer",
  },
}));
const PaymentSuccessPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.body}>
      <div className={classes.content}>
        <div className={classes.successIconDiv}>
          <img src="/success-check.svg" />
        </div>
        <Typography className={classes.contentTitle}>
          You have successfully subscribed to the plan!
        </Typography>
        <Link
          onClick={() => {
            history.push({
              pathname: "/client/settings",
              state: { parentSettingIndex: 0 },
            });
          }}
          className={classes.subscriptionLink}
        >
          Go to Subscription Page{" "}
          <ArrowForwardIcon className={classes.arrowIcon} />{" "}
        </Link>
      </div>
    </div>
  );
};

PaymentSuccessPage.propTypes = {};

export default PaymentSuccessPage;
