import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAllInvoices = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/getAllInvoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   body: {
    //       "searchTags": ["INV00005", "INV00002"],
    //     "sortBy": "date", //Sort By date( payment due date ) or status
    //     "limit": 100,
    //     "offset": 0
    // }
    // searchTags will be added later
  });
};

export const createInvoice = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/invoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};

export const recreateInvoice = async (bodyRequest) => {
  // console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/admin/reCreateInvoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     "jobId": "6583be203ae5caf12a8161b7",
    //     "userId":"6583012e3ae5caf12a81551b", // consultant id or agency id
    //     "userType": "consultant", // consultant  or agency
    //     "projectDescription": "Recreate invoice ",
    //     "refId": "65cfaee520de3fb37b421b45",
    //     "amount": 500,
    //     "discount": 10,
    //     "notes": "Recreate notes"
    // }
  });
};

export const cancelInvoice = async (invoiceID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/cancelInvoice/${invoiceID}`);
};

export const getActiveConsultants = async (bodyRequest) => {
  console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/admin/activeConsultant`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   body: {
    //     "filter": "Edwards",
    //     "sortBy": "date", //Sort By date( payment due date ) or status
    //     "limit": 100,
    //     "offset": 0
    // }
    // searchTags will be added later
  });
};

export const uploadDocuments = async (bodyRequest) => {
  console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/admin/uploadDocuments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};

export const addDocuments = async (bodyRequest) => {
  console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/admin/addDocument`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};

export const updateDocuments = async (bodyRequest, documentId) => {
  console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/admin/updateDocument/${documentId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};

export const deleteDocument = async (documentId) => {
  return fetchWrapper(`${configs.APIUrl}/admin/deleteDocument/${documentId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getDocumentTypes = async () => {
  return fetchWrapper(`${configs.APIUrl}/misc/documentTypes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
