import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import { Alert } from "@mui/material";
import QRCode from "qrcode.react";
import img_iconlightcheckmark from "app/v2/Pages/images/img_iconlightcheckmark.svg";

import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";
import AgencyStatus from "app/v2/Pages/common/Status/Agency";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  qrCodeDiv: {
    padding: "16px",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  workDetailType: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  chipRoot: {
    padding: "0px 8px",
    fontSize: "12px",
    fontWeight: 700,
    border: 0,
    backgroundColor: "#EDEDED !important",
  },
  chipLabel: {
    padding: 0,
  },
  sectionSubHeading: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  skillDiv: {
    // flex: "0 0 25%",
    flex: "0 0 44%",
    display: "flex",
    columnGap: "8px",
    // margin: "12px 0",
    alignItems: "center",
    height: "fit-content",
  },
  actionButton: {
    boxSizing: "content-box",
    fontSize: "16px",
    fontWeight: 600,
  },
  inviteButton: {
    width: "136px",
    height: "20px",
    padding: "12px 28px !important",
    display: "flex",
    columnGap: "10px",
    whiteSpace: "nowrap",
  },
  connectButton: {
    width: "68px",
    height: "20px",
    padding: "12px 28px !important",
    border: "1px solid #EDEDED",
  },
  bookmarkShareIcon: {
    border: "1px solid #EDEDED",
    padding: "10px",
    width: "20px",
    height: "20px",
    boxSizing: "content-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  orgImage: {
    width: "200px",
    height: "200px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginBottom: "24px",
  },
}));

const DetailText = ({ type, value }) => {
  const classes = useStyles();
  return (
    <div className="flex-1">
      <div className="text-[12px] font-semibold text-[#717171] uppercase">
        {type ?? ""}
      </div>
      <div className="text-[16px]">{value ?? "-"}</div>
    </div>
  );
};

export const SectionTitleIcon = ({ title, icon }) => (
  <div className="text-[24px] font-semibold mb-[24px] flex items-center gap-x-[8px]">
    {icon} {title}
  </div>
);

export const Overview = ({ data }) => {
  const classes = useStyles();

  const companySizeRange = data?.companySizeRange;
  const companySizeStartValue = companySizeRange?.start;
  const companySizeEndValue = companySizeRange?.end;
  const companySizeRangeText = `${companySizeStartValue ?? ""} - ${
    companySizeEndValue ?? ""
  }`;
  let activeTime = data?.activeTime ? moment(data?.activeTime).fromNow() : "-";
  activeTime = activeTime === "Invalid date" ? "-" : activeTime;

  return (
    <div className="flex gap-x-[48px]">
      <div>
        <img
          className={classes.orgImage}
          alt={`org_${data?._id}_image`}
          src={data?.image ? data?.image : "/org_placeholder.svg"}
        />

        <div className="flex gap-x-[12px] items-center mb-[16px]">
          <div className="text-[12px] font-semibold text-[#717171]">
            SOCIAL PROOF
          </div>
          <div className="flex gap-x-[12px]">
            {data?.linkedinUrl && (
              <a href={data?.linkedinUrl}>
                <img
                  src="/linkedin-logo-black.png"
                  className="w-[24px] h-[24px] cursor-pointer"
                />
              </a>
            )}
            {data?.facebookUrl && (
              <a href={data?.facebookUrl}>
                <img
                  src="/facebook-logo-black.svg"
                  className="w-[24px] h-[24px]"
                />
              </a>
            )}
          </div>
        </div>
        <hr />
        <div className={classes.qrCodeDiv}>
          <div className="text-[16px] font-medium mb-[12px]">QR SHARE</div>
          {data?.qrCode ? (
            <QRCode value={data?.qrCode} className="h-[89px] w-[89px]" />
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-y-[24px]">
        <div className="flex justify-between">
          <div>
            <div className="text-[32px] font-semibold">
              {data?.agencyName ?? "-"}
            </div>
            <div className="text-[12px] text-[#717171]">
              Active - {activeTime}
            </div>
          </div>
          <div className="h-[18px] px-[8px] flex gap-x-[5px] items-center bg-green-50">
            <AgencyStatus type={data?.status} />
          </div>
        </div>
        <div className="flex gap-x-[16px]">
          <DetailText
            type={"FINDPRO CONSULTANTS"}
            value={data?.consultantCount}
          />
          <DetailText type="COMPANY SIZE" value={companySizeRangeText} />
          <DetailText type="WEBSITE" value={data?.website} />
        </div>
        <div className="flex gap-x-[16px]">
          <DetailText
            type="LOCATION"
            value={`${data?.country ?? "-"}, ${data?.city ?? "-"}`}
          />
          <DetailText type="TIMEZONE" value={data?.timezone} />
        </div>
        <div className="text-[14px] text-[#717171] ">{data?.summary}</div>
        {(data?.companyVideoAvailable || data?.backgroundCheck) && (
          <div className="flex gap-x-[24px]">
            {data?.companyVideoAvailable && (
              <div className="flex gap-x-[10px]">
                <img src={img_iconlightcheckmark} />
                <span className="font-semibold text-xs">
                  Company video Available
                </span>
              </div>
            )}
            {data?.backgroundCheck && (
              <div className="flex gap-x-[10px]">
                <img src={img_iconlightcheckmark} />
                <span className="font-semibold text-xs">
                  Background Checked
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const TestimonyCard = ({ data }) => {
  return (
    <div className="min-w-[452px] flex flex-col gap-y-[8px] p-[24px] border-[1px] border-solid border-[#717171] box-content">
      <div className="text-[16px] text-[#717171] ">
        {data?.testimony ?? "-"}
      </div>
      <div className="text-[18px] font-semibold">{data?.givenBy ?? "-"}</div>
      <div className="text-[14px] font-semibold">{data?.role ?? "-"}</div>
      <div className="text-[14px] font-semibold uppercase text-[#ABABAB]">
        {data?.projectName ?? "-"}
      </div>
    </div>
  );
};

export const Testimonials = ({ data }) => {
  return (
    <div className="max-w-[983px] overflow-x-auto p-[16px]">
      {isValidArray(data) ? (
        <div className="flex gap-x-[24px] ">
          {data.map((card, index) => (
            <TestimonyCard data={card} />
          ))}
        </div>
      ) : (
        <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
      )}
    </div>
  );
};

export const CompanyVideo = ({ data }) => {
  return (
    <>
      <div
        id="videoresume"
        className="flex flex-col gap-6 items-start justify-center w-full"
      >
        <div className="bg-gray-900 h-[433px] relative w-[92%] md:w-full">
          {/* <video controls>
              <source src="https://youtu.be/IXoMDwh4Cq8" type="video/mp4" />
              Your browser does not support the video tag.
          </video> */}
          <iframe
            className="video-player w-[100%] h-full"
            src={data}
            title="Video Player"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </>
  );
};
