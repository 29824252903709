import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  userImage: {
    width: "30px",
    height: "30px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));
const ConsultantTile = ({ displayPic, name, email }) => {
  const classes = useStyles();
  return (
    <div className="flex gap-x-[16px] justfiy-center items-center">
      <img
        className={classes.userImage}
        alt={`"user_${name}_image"`}
        src={displayPic ? displayPic : "/user_placeholder.svg"}
      />

      <div className="text-[16px] font-semibold">{name}</div>
      <div className="text-[16px] font-semibold text-[#ABABAB]">{email}</div>
    </div>
  );
};

ConsultantTile.propTypes = {};

export default ConsultantTile;
