import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import commonImages from "../../../../../images/common";
import { useHistory } from "react-router-dom";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: "317px",
    width: "100%",
    transition: "transform 0.3s", // Add a transition for smooth movement
    "&:hover": {
      transform: "translateY(-10px)", // Move the card up on hover
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    overflowX: "auto",
    width: "269px",
    paddingBottom: "10px",
  },

  hideActions: {
    display: "none",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));
const Agency = ({}) => {
  const history = useHistory();
  const classes = useStyles();

  const cardData = {
    _id: "123",
    // image: "/company_image.png",
    name: "ABC Company",
    role: "Sr. Product Owner",
    matchRate: "86%",
    companySize: "14",
    location: "USA",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [isBookMarkClicked, setIsBookMarkClicked] = useState(false);

  const handleBookMarkClick = () => {
    setIsBookMarkClicked(!isBookMarkClicked);
  };
  return (
    <div className="h-[440px] flex items-center">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx([
          classes.cardContainer,
          "flex flex-col p-6 items-start justify-center gap-[19px] text-left text-3xs text-neutral-500 font-button-text-button-10 border-[1px] border-solid border-neutral-50",
        ])}
      >
        <div className="self-stretch flex flex-row items-start justify-start gap-[19px] text-lg text-black">
          <img
            className={classes.orgImage}
            alt={`org_${cardData?._id}_image`}
            src={cardData?.image ? cardData.image : "/org_placeholder.svg"}
          />
          <div className="flex flex-col items-start justify-start gap-[2px]">
            <div className="relative leading-[130%] font-semibold inline-block w-[150px]">
              {cardData?.name}
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 inline-block w-[150px]">
              {cardData?.role}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center">
          <div className="w-[85.33px] flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx(
                ["relative uppercase"],
                classes.sectionHeadingText
              )}
            >
              Match Rate
            </b>
            <div className="relative text-sm font-medium font-poppins text-neutral-900">
              {cardData?.matchRate}
            </div>
          </div>
          <div className="w-[85.33px] flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx(
                ["relative uppercase"],
                classes.sectionHeadingText
              )}
            >
              company size
            </b>
            <div className="relative text-sm font-medium font-poppins text-neutral-900">
              {cardData?.companySize}
            </div>
          </div>
          <div className="w-[85.33px] flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx(
                ["relative uppercase"],
                classes.sectionHeadingText
              )}
            >
              location
            </b>
            <div className="relative text-sm font-medium font-poppins text-neutral-900">
              {cardData?.location}
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs leading-[136%] font-poppins [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical] h-12 shrink-0">
          {cardData?.bio}
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px] text-center">
          <div
            className={clsx([
              "relative uppercase font-semibold",
              classes.sectionHeadingText,
            ])}
          >
            technology Skills
          </div>
          <div
            className={clsx([
              classes.skillsChipsDiv,
              "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
            ])}
          >
            {cardData?.skills?.map((skillName) => (
              <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                <div className="relative leading-[136%] font-medium">
                  {skillName}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={clsx([
            "self-stretch flex flex-row items-start justify-start gap-[12px]",
            !isHovered && classes.hideActions,
          ])}
        >
          <button
            onClick={() => {
              history.push(`/client/agency-detail`);
              window.scrollTo(0, 0);
            }}
            className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 flex-1 h-10 flex flex-row box-border items-center justify-center gap-[10px]"
          >
            <div className="relative text-sm font-semibold font-button-text-button-10 text-neutral-white text-center whitespace-nowrap">
              View{" "}
              {cardData?.name
                ? cardData.name.length > 4
                  ? `${cardData.name.substring(0, 4)}...`
                  : cardData.name.split(" ")[0]
                : ""}
              's Profile
            </div>
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconlightup-right.svg"
            />
          </button>
          <button
            className={`cursor-pointer ${
              isBookMarkClicked
                ? "bg-neutral-900 border-none h-10"
                : "border-[1px] border-solid border-neutral-50"
            } p-2.5 flex flex-row box-border items-center justify-center`}
            onClick={handleBookMarkClick}
          >
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src={
                isBookMarkClicked
                  ? "/iconlightbookmark.svg"
                  : "/icondarkbookmark.svg"
              }
            />
          </button>
        </div>
      </div>
    </div>
  );
};

Agency.propTypes = {};

export default Agency;
