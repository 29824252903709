import commonImages from "app/v2/images/common";

// export let dummyUsersData = [
//   /** Invited users-START */
//   {
//     id: 1,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 1",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//     status: "pending", // or rejected. - specific to invited column.(Once accepted it will move to applied stage).
//   },
//   {
//     id: 2,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 2",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//     status: "rejected",
//   },
//   /** Invited users-END */
//   /** Applied users-START */
//   {
//     id: 3,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 3",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 4,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 4",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 5,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 5",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 6,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 6",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Applied users-END */
//   /** Shortlisted users-START */
//   {
//     id: 7,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 7",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 8,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 8",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 9,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 9",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 10,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 10",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 11,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 11",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 12,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 12",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Shortlisted users-END */
//   /** Screening users-START */
//   {
//     id: 13,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 13",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 14,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 14",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//     interviewDetails: {
//       isVirtual: true,
//       virtualInvitationLink: "https://meet.google.com/pdh-fiap-fct?authuser=0", // If isVirtual is true then this field will have a value else it will be empty.
//       inPersonMeetingLocationDetails: {}, // If isVirtual is false then this field will have name,googleMapURL values else it will be an empty object.
//       date: "2022-12-11T05:00:00.000Z",
//       time: {
//         start: "09:30 AM",
//         end: "10:00 AM",
//         timezone: "IST",
//       },
//     }, // If this entry does not exist it means invite needs to be sent out for the first time.
//   },
//   {
//     id: 15,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 15",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//     interviewDetails: {
//       isVirtual: false,
//       virtualInvitationLink: "", // If isVirtual is true then this field will have a value else it will be empty.
//       inPersonMeetingLocationDetails: {
//         name: "Findpro Global Pvt Ltd",
//         googleMapURL:
//           "https://www.google.com/maps/search/FindPro+Global+Pvt+Ltd,+Plot+No.97,+1st+floor,+Ayyappa+Society+Main+Rd,+Ayyappa+Society,+Mega+Hills,+Madhapur,+Hyderabad,+Telangana+500081,+India?source=mc",
//       }, // If isVirtual is false then this field will have name,googleMapURL values else it will be an empty object.
//       date: "2022-12-11T05:00:00.000Z",
//       time: {
//         start: "09:30 AM",
//         end: "10:00 AM",
//         timezone: "IST",
//       },
//     }, // If this entry does not exist it means invite needs to be sent out for the first time.
//   },
//   {
//     id: 16,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 16",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 17,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 17",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Screening users-END */
//   /** Test assessment users-START */
//   {
//     id: 18,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 18",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 19,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 19",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//     testDetails: {
//       agent: "HackerRank",
//       testID: 12,
//       testName: "Test No 123: ReactJS Entry level Round",
//       testURL: "",
//       dueDate: "2022-12-11T05:00:00.000Z",
//       testCompleted: false,
//       testPercentage: "", // This field will have a value if testCompleted is true. It is out of 100.
//       testReportURL: "", // This field will have a value if testCompleted is true.
//     }, // if this entry does not exist it means test invite needs to be sent out for first time.
//   },
//   {
//     id: 20,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 20",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//     testDetails: {
//       agent: "HackerRank",
//       testID: 12,
//       testName: "ReactJS Entry level test",
//       testURL: "",
//       dueDate: "2022-12-11T05:00:00.000Z",
//       testCompleted: true,
//       testPercentage: "87", // This field will have a value if testCompleted is true. It is out of 100.
//       testReportURL: "https://www.google.com", // This field will have a value if testCompleted is true.
//     }, // if this entry does not exist it means test invite needs to be sent out for first time.
//   },
//   {
//     id: 21,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 21",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Test assessment users-END */
//   /** Offered users-START */
//   {
//     id: 22, // offered card
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 22",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 23, // offered card
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 23",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   {
//     id: 24, // offered card
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 24",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Offered users-END */
//   /** Accepted users-START */
//   {
//     id: 25,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 25",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Accepted users-END */
//   /** Withdrew users-START */
//   {
//     id: 26,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 26",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Withdrew users-END */
//   /** Rejected users-START */
//   {
//     id: 27,
//     profileImage: commonImages?.user_profile,
//     firstName: "Lacy",
//     lastName: "Clark 27",
//     jobTitle: "Senior Backend Developer",
//     experienceLevel: "0",
//     availability: "2",
//     country: "India",
//     consultantMatched: 87.4,
//   },
//   /** Rejected users-END */
// ];

export let dummyColumnsData = [
  {
    type: "invited", // camel case of title.
    title: "Invited",
    users: [
      {
        consultantId: 1,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 1",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
        status: "pending", // or rejected. - specific to invited column.(Once accepted it will move to applied stage).
      },
      {
        consultantId: 2,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 2",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
        status: "rejected",
      },
    ],
    showMenu: false, // show menu for stage or not
    movable: false, // Denotes if stage is movable or not
    moveCardFromAndTo: false, // This value decides if cards within a stage can be moved to other stages and vice-versa.
    info: "Lorem ipsum invited",
    defaultOrCustomStage: "default", // default or custom
    customStageVariant: "", // This attribute is valid if defaultOrCustomStage is 'custom'. 'test'/'meeting' for custom defaultOrCustomStage,"" for default defaultOrCustomStage.
  },
  {
    type: "applied",
    title: "Applied",
    users: [
      {
        consultantId: 3,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 3",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },

      {
        consultantId: 4,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 4",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 5,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 5",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 6,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 6",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: false,
    movable: false,
    moveCardFromAndTo: true,
    info: "Lorem ipsum applied",
    defaultOrCustomStage: "default",
    customStageVariant: "",
  },
  {
    type: "shortlisted",
    title: "Shortlisted",
    users: [
      {
        consultantId: 7,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 7",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 8,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 8",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 9,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 9",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 10,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 10",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 11,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 11",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 12,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 12",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: false,
    movable: false,
    moveCardFromAndTo: true,
    info: "Lorem ipsum shortlisted",
    defaultOrCustomStage: "default",
    customStageVariant: "",
  },
  /** START-Placeholder columns for users to understand the two types of rounds they can create */
  {
    type: "screening",
    title: "Screening",
    users: [
      {
        consultantId: 13,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 13",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 14,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 14",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
        interviewDetails: {
          isVirtual: true,
          virtualInvitationLink:
            "https://meet.google.com/pdh-fiap-fct?authuser=0", // If isVirtual is true then this field will have a value else it will be empty.
          inPersonMeetingLocationDetails: {}, // If isVirtual is false then this field will have name,googleMapURL values else it will be an empty object.
          date: "2022-12-11T05:00:00.000Z",
          time: {
            start: "09:30 AM",
            end: "10:00 AM",
            timezone: "IST",
          },
        }, // If this entry does not exist it means invite needs to be sent out for the first time.},
      },
      {
        consultantId: 15,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 15",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
        interviewDetails: {
          isVirtual: false,
          virtualInvitationLink: "", // If isVirtual is true then this field will have a value else it will be empty.
          inPersonMeetingLocationDetails: {
            name: "Findpro Global Pvt Ltd",
            googleMapURL:
              "https://www.google.com/maps/search/FindPro+Global+Pvt+Ltd,+Plot+No.97,+1st+floor,+Ayyappa+Society+Main+Rd,+Ayyappa+Society,+Mega+Hills,+Madhapur,+Hyderabad,+Telangana+500081,+India?source=mc",
          }, // If isVirtual is false then this field will have name,googleMapURL values else it will be an empty object.
          date: "2022-12-11T05:00:00.000Z",
          time: {
            start: "09:30 AM",
            end: "10:00 AM",
            timezone: "IST",
          },
        }, // If this entry does not exist it means invite needs to be sent out for the first time.
      },
      {
        consultantId: 16,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 16",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 17,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 17",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: true,
    movable: true,
    moveCardFromAndTo: true,
    info: "Lorem ipsum screening",
    defaultOrCustomStage: "custom",
    customStageVariant: "meeting",
  },
  {
    type: "testAssessment",
    title: "Test Assessment",
    users: [
      {
        consultantId: 18,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 18",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 19,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 19",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
        testDetails: {
          agent: "HackerRank",
          testID: 12,
          testName: "Test No 123: ReactJS Entry level Round",
          testURL: "",
          dueDate: "2022-12-11T05:00:00.000Z",
          testCompleted: false,
          testPercentage: "", // This field will have a value if testCompleted is true. It is out of 100.
          testReportURL: "", // This field will have a value if testCompleted is true.
        }, // if this entry does not exist it means test invite needs to be sent out for first time.
      },
      {
        consultantId: 20,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 20",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
        testDetails: {
          agent: "HackerRank",
          testID: 12,
          testName: "ReactJS Entry level test",
          testURL: "",
          dueDate: "2022-12-11T05:00:00.000Z",
          testCompleted: true,
          testPercentage: "87", // This field will have a value if testCompleted is true. It is out of 100.
          testReportURL: "https://www.google.com", // This field will have a value if testCompleted is true.
        }, // if this entry does not exist it means test invite needs to be sent out for first time.
      },
      {
        consultantId: 21,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 21",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: true,
    movable: true,
    moveCardFromAndTo: true,
    info: "Lorem ipsum test assessment",
    defaultOrCustomStage: "custom",
    customStageVariant: "test",
  },
  /** END-Placeholder columns for users to understand the two types of rounds they can create */
  {
    type: "offered",
    title: "Offered",
    users: [
      {
        consultantId: 22,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 22",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 23,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 23",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
      {
        consultantId: 24,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 24",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: false,
    movable: false,
    moveCardFromAndTo: true,
    info: "Lorem ipsum offered",
    defaultOrCustomStage: "default",
    customStageVariant: "",
  },
  {
    type: "accepted",
    title: "Accepted",
    users: [
      {
        consultantId: 25,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 25",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: false,
    movable: false,
    moveCardFromAndTo: true,
    info: "Lorem ipsum accepted",
    defaultOrCustomStage: "default",
    customStageVariant: "",
  },
  {
    type: "withdrew",
    title: "Withdrew",
    users: [
      {
        consultantId: 26,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 26",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: false,
    movable: false,
    moveCardFromAndTo: true,
    info: "Lorem ipsum withdrew",
    defaultOrCustomStage: "default",
    customStageVariant: "",
  },
  {
    type: "rejected",
    title: "Rejected",
    users: [
      {
        consultantId: 27,
        profileImage: commonImages?.user_profile,
        firstName: "Lacy",
        lastName: "Clark 27",
        jobTitle: "Senior Backend Developer",
        experienceLevel: "0",
        availability: "2",
        country: "India",
        consultantMatched: 87.4,
      },
    ],
    showMenu: false,
    movable: false,
    moveCardFromAndTo: true,
    info: "Lorem ipsum rejected",
    defaultOrCustomStage: "default",
    customStageVariant: "",
  },
];

// export let dummyColumnsData = [
//   {
//     type: "invited", // camel case of title.
//     title: "Invited",
//     userIDs: [1, 2],
//     showMenu: false, // show menu for stage or not
//     movable: false, // Denotes if stage is movable or not
//     moveCardFromAndTo: false, // This value decides if cards within a stage can be moved to other stages and vice-versa.
//     info: "Lorem ipsum invited",
//     defaultOrCustomStage: "default", // default or custom
//     customStageVariant: "", // This attribute is valid if defaultOrCustomStage is 'custom'. 'test'/'meeting' for custom defaultOrCustomStage,"" for default defaultOrCustomStage.
//   },
//   {
//     type: "applied",
//     title: "Applied",
//     userIDs: [3, 4, 5, 6],
//     showMenu: false,
//     movable: false,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum applied",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   {
//     type: "shortlisted",
//     title: "Shortlisted",
//     userIDs: [7, 8, 9, 10, 11, 12],
//     showMenu: false,
//     movable: false,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum shortlisted",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   /** START-Placeholder columns for users to understand the two types of rounds they can create */
//   {
//     type: "screening",
//     title: "Screening",
//     userIDs: [13, 14, 15, 16, 17],
//     showMenu: true,
//     movable: true,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum screening",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   {
//     type: "testAssessment",
//     title: "Test Assessment",
//     userIDs: [18, 19, 20, 21],
//     showMenu: true,
//     movable: true,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum test assessment",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   /** END-Placeholder columns for users to understand the two types of rounds they can create */
//   {
//     type: "offered",
//     title: "Offered",
//     userIDs: [22, 23, 24],
//     showMenu: false,
//     movable: false,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum offered",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   {
//     type: "accepted",
//     title: "Accepted",
//     userIDs: [25],
//     showMenu: false,
//     movable: false,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum accepted",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   {
//     type: "withdrew",
//     title: "Withdrew",
//     userIDs: [26],
//     showMenu: false,
//     movable: false,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum withdrew",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
//   {
//     type: "rejected",
//     title: "Rejected",
//     userIDs: [27],
//     showMenu: false,
//     movable: false,
//     moveCardFromAndTo: true,
//     info: "Lorem ipsum rejected",
//     defaultOrCustomStage: "default",
//     customStageVariant: "",
//   },
// ];
