import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import { UserContext } from "app/v2";

const useStyles = makeStyles((theme) => ({
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));
const ConsultantContractHeader = () => {
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);

  const contractActivityData = drawerDetails?.contractActivity;

  const headerData = {
    _id: contractActivityData?._id, // "123",
    companyName: contractActivityData?.organizationDetails?.company,
    companyImage: contractActivityData?.organizationDetails?.profilePhoto, // commonImages?.user_profile,
    acceptedDate: new Date(contractActivityData?.createdAt).toLocaleString(), //"April 2, 2023",
    appNo: contractActivityData?.appNo, //"FP23147",
    jobFilled: `Job ${contractActivityData?.jobDetails?.jobNo}:${contractActivityData?.jobDetails?.jobTitle}`, // "Job 27: Frontend Developer for Contractual Project",
  };
  const classes = useStyles();
  return (
    <div className="bg-neutral-white w-[1080px] flex flex-col p-9 box-border items-start justify-start text-center text-5xl text-black font-body-text-medium-12">
      <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
        <div className="flex-1 flex flex-row items-center justify-start gap-[19px]">
          <img
            className={classes.orgImage}
            alt={`"org_${headerData?._id}_image"`}
            src={
              headerData?.companyImage
                ? headerData.companyImage
                : "/org_placeholder.svg"
            }
          />
          <div className="flex-1 flex flex-col items-start justify-center gap-[12px]">
            <div className="flex flex-col items-start justify-start">
              <div className="relative leading-[120%] font-semibold">
                {headerData?.companyName ?? "-"}
              </div>
              <div className="flex flex-row items-center justify-start gap-[12px] text-sm text-neutral-500">
                <div className="relative leading-[160%]">
                  Accepted {headerData?.acceptedDate}
                </div>
                <div className="relative leading-[160%] text-left">
                  App No. {headerData?.appNo}
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-center gap-[6px] text-3xs text-neutral-500">
              <b className="relative uppercase">job Filled</b>
              <div className="self-stretch relative text-base leading-[130%] font-semibold text-neutral-900 text-left inline-block overflow-hidden text-ellipsis whitespace-nowrap h-[21px] shrink-0">
                {headerData?.jobFilled}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-end gap-[24px]">
          {/* <button className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-col items-end justify-center">
            <div className="bg-neutral-900 flex flex-row py-2.5 pr-[15px] px-5 items-center justify-center gap-[10px]">
              <div className="relative text-sm font-semibold font-body-text-medium-12 text-neutral-white text-center">
                Manage in Deel App
              </div>
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconlightup-right.svg"
              />
            </div>
          </button> */}
        </div>
      </div>
    </div>
  );
};

ConsultantContractHeader.propTypes = {};

export default ConsultantContractHeader;
