import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import CustomTabs from "app/v2/components/CustomTabs";
import ContractOnboarding from "./ContractOnboarding";
import ContractDetail from "./ContractDetail";
import ConsultantContractHeader from "./ConsultantContractHeader";
import commonImages from "app/v2/images/common";

const useStyles = makeStyles((theme) => ({
  contractModalRoot: {
    maxWidth: "1080px",
    width: "100%",
  },
}));
const JobConsultantContract = (props) => {
  const headerData = {
    _id: "123",
    userName: "Carol Hemmings",
    userImage: commonImages?.user_profile,
    acceptedDate: "April 2, 2023",
    appNo: "FP23147",
    jobFilled: "Job 27: Frontend Developer for Contractual Project",
  };
  const classes = useStyles();
  const tabsData = [
    {
      label: "Onboarding",
      component: <ContractOnboarding />,
    },
    {
      label: "Contract Detail",
      component: <ContractDetail />,
    },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return (
    <div className={classes.contractModalRoot}>
      <ConsultantContractHeader data={headerData} />
      <CustomTabs
        tabsData={tabsData}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
      />
    </div>
  );
};

JobConsultantContract.propTypes = {};

export default JobConsultantContract;
