import React, { useState, useEffect, useContext } from "react";
import { FormControl, FormHelperText } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { UserContext } from "app/v2";
import clsx from "clsx";



import {
  isUndefined,
  isDateStringValidFormat,
  isTimeStringValidFormat,
  isValidString,
} from "app/v2/Pages/Utils/utilFunctions";
import CustomTimeRangePicker from "app/v2/Pages/common/CustomTimeRangePicker";

dayjs.extend(utc);
dayjs.extend(timezone); // Extend dayjs with timezone plugin

const useStyles = makeStyles((theme) => ({
  dateOrTimePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  dateOrTimePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
}));

const OnboardingDetailsConsultant = () => {
  const classes = useStyles();

  const statuses = ["Documents under Assessment", "Completed"];
  const workModeData = ["On site", "Hybrid", "Remote"];

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  console.log("drawer detailssssss",drawerDetails)
//   const onboardingDetails = drawerDetails?.contractActivity?.onboardingDetails;
  const [onboardingDetails, setOnBoardingDetails] = useState({});
  const [startDate, setStartDate] = useState(drawerDetails?.contractActivity?.onboardingDetails?.startDate);
  const [workingTime, setWorkTime] = useState({
    start: drawerDetails?.contractActivity?.onboardingDetails?.workingTime?.start,
    end: drawerDetails?.contractActivity?.onboardingDetails?.workingTime?.end,
  });
  const [onboardingStatus, setOnboardingStatus] = useState(drawerDetails?.contractActivity?.onboardingDetails?.onboardingStatus);
  const [workMode, setWorkMode] = useState(drawerDetails?.contractActivity?.onboardingDetails?.workMode);


  const isDateInvalid = () => {
    /**
     * Condition -> (!isUndefined(startDate) && startDate === "")
     * 1. While initially setting the start date it will be undefined,
     * and we dont have to show error message immediately,only when there's a change in date and it is invalid
     * (invalid check is handled-check date picker's onChange logic,
     * selecting older date isa disabled in the picker itself),then we have to show the message.
     * 2. But for edit case we specifically set it to null if its not a valid truthy value(since we dont show error if value is undefined in point 1).
     */

    return !isUndefined(startDate) && startDate === "";
  };

//   useEffect(() => {
//     validateEntries();
//   }, [onboardingStatus, startDate, workingTime, workMode]);
  return (
    <div className="flex flex-col gap-y-[16px] p-[32px]">
      <div className="flex flex-row gap-x-10">
        <CustomSelect
          id="onboarding status"
          data={statuses}
          label="Onboarding status"
          placeholder={"Select"}
        //   onChange={onStatusChange}
          defaultSelection={onboardingStatus}
          disabled
        />
        <CustomSelect
          id="workType"
          data={workModeData}
          label="work mode"
          placeholder={"Select"}
        //   onChange={(id, value) => {
        //     setWorkMode(value);
        //   }}
          defaultSelection={workMode}
          disabled
        />
      </div>
      <div className="flex flex-col">
        <CustomLabel label="start date" />
        <FormControl error={isDateInvalid()}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={
                isDateStringValidFormat(startDate, "YYYY-MM-DD")
                  ? dayjs(startDate)
                  : null
              }
              disabled
              onChange={(date) => {
                // handleDateChange(date);
              }}
              className={clsx([
                classes.dateOrTimePicker,
                // isDateInvalid() && classes.dateOrTimePickerError,
              ])}
            />
          </LocalizationProvider>
          {/* {isDateInvalid() && (
            <FormHelperText className="ml-0">
              Please enter a valid date.
            </FormHelperText>
          )} */}
        </FormControl>
      </div>

      <CustomTimeRangePicker
        label="work time"
        ampm={true}
        start={workingTime?.start}
        end={workingTime?.end}
        noStartEndComparison={true}
        // onChange={(formattedStartTime, formattedEndTime) => {
        //   setWorkTime({
        //     start: formattedStartTime,
        //     end: formattedEndTime,
        //   });
        // }}
        disabled
      />

      {/* <CustomTooltip
        title={!activateUpdateButton ? "Please fill the above entries." : null}
      >
        <div className="flex justify-center">
          <CustomButton
            type="button1"
            customClasses="text-[16px] font-semibold py-3 px-7 flex gap-x-[8px]"
            onClick={onUpdate}
            disabled={!activateUpdateButton}
          >
            Update
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconfilledsend.svg"
            />
          </CustomButton>
        </div>
      </CustomTooltip> */}
      <hr className="border-t my-4" />
    </div>
  );
};

export default OnboardingDetailsConsultant;
