import fetchWrapper from "app/v2/Pages/Utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";
import moment from "moment";

export const notificationsService = {
  async getNotifications(request) {
    const rawNotifications = await fetchWrapper(
      `${configs.NeAPIUrl}/notification/getNotifications`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: request,
      }
    );

    const notifications = rawNotifications.map((notification) => {
      return {
        // notification
        id: notification?.id,
        link: notification?.link,
        is_read: notification?.is_read,
        type: notification.type,
        message: notification.description,
        image: "notification.type",
        // time: notification.createdAt
        time: moment(new Date(notification.createdAt)).fromNow(true),
      };
    });

    // createdAt
    // description
    // id
    // is_read
    // link
    // nToken
    // notification_type
    // title
    // // // updatedAt
    // userID
    // user_type

    return notifications;
  },
  // export const getConnectionsData = async (userId) => {
  //   return fetchWrapper(`${configs.APIUrl}/connection/${userId}`, {
  //     method: "GET", // Use GET method
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  // };

  // export const getNotifications = async (request) => {
  //   // return fetchWrapper(`${configs.APIUrl}/connection`, {
  //   return fetchWrapper(`https://ne.findpros.ai/notification/getNotifications`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     // body: request,
  //     body: {
  //       "userID":"6557e3f09b7363581b1bc9dc"
  //     //   "user_email": "edward@findpros.ai"
  //     },
  //   });
  // };

  // import commonImages from "../images/common";

  // export const notificationsService = {
  // 	async fetchList() {
  //         // Dummy data
  // 		return new Promise((resolve, error) => {
  //             resolve([
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <span>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       sent you an invite.
  //                     </span>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "b",
  //                   message: `
  //                     <span>
  //                       <span className="font-semibold">ABC Company </span>
  //                       wants to connect with you!
  //                     </span>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "c",
  //                   message: `
  //                     <span>
  //                       You and <span className="font-semibold">Interactiv Tech </span>
  //                       are now connected
  //                     </span>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //                 {
  //                   type: "a",
  //                   message: `
  //                     <>
  //                       <span className="font-semibold">Julia Mitchell </span>
  //                       <span>sent you an invite.</span>
  //                     </>
  //                   `,
  //                   image: commonImages?.user_profile,
  //                   time: "5h ago",
  //                 },
  //               ])
  //         })
  // 	},
};

export const setNotificationAsRead = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/notification/read`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const setNToken = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/notification/setToken`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
