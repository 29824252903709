import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import {
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import Overview from "./OverView";

import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomSelect from "app/v2/components/filters/CustomSelect";

import { defaultSkillList, messages } from "app/v2/Pages/Utils/constants";
import { isValidArray, isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import useFetch from "app/common/utils/useFetch";
import { s3Upload } from "app/v2/services/miscService";
import { EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";
import {
  getRolesThunk,
  sendEmployeeInvitationThunk,
  UpdateEmployeeDetailsThunk,
} from "app/v2/store/features/employer/employee.thunk";
import CustomLabel from "app/v2/components/filters/CustomLabel";
const employeeData = ["a", "b"];

const useStyles = makeStyles((theme) => ({
  sendInviteButton: {
    width: "100%",
    padding: "16px 28px",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    columnGap: "10px",
    fontSize: "16px",
    fontWeight: 600,
  },
  line: {
    marginBottom: 0,
  },
  resendInviteText: {
    fontSize: "14px",
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
    marginTop: "12px",
    cursor: "pointer",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
}));

const SendInviteDialog = ({
  sendInviteDialogOpen,
  setSendInviteDialogOpen,
  sendInviteActionLoading,
  sendInviteCompleted,
  selectedEmployee,
  sendInviteError,
}) => {
  const classes = useStyles();

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {sendInviteActionLoading && "Action in progress"}
        {sendInviteError && "Error"}
        {sendInviteCompleted && "Changes Saved"}
      </Typography>
    );
  };

  const DialogBody = (selectedEmployee) => {
    if (sendInviteActionLoading) return <DialogLoadingBody />;
    else if (sendInviteCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: selectedEmployee
              ? "Employee Updated successfully."
              : "Employee added successfully.",
          }}
        />
      );
    else if (sendInviteError)
      return (
        <DialogErrorBody
          content={{ title: " Email is already exists ", desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={sendInviteDialogOpen}
      setOpen={setSendInviteDialogOpen}
      noCloseAction={sendInviteActionLoading}
      title={<Title />}
      content={<DialogBody selectedEmployee={selectedEmployee} />}
    />
  );
};

const AddEmployee = ({ selectedEmployee, handleDialoguesAndApi }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [get] = useFetch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const clientDetail = useSelector(
    (state) => state?.clientProfileInfo?.clientInfo?.data?.clientDetail
  );

  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [employeeRolesData, setEmployeeRolesData] = useState([]);
  const [selectedEmployeeRole, setSelectedEmployeeRole] = useState("");
  useEffect(() => {
    // Set the default roleType based on selectedEmployee.role
    if (selectedEmployee?.role) {
      setSelectedEmployeeRole(selectedEmployee?.role);
    }
  }, []);
  useEffect(() => {
    setConsultantData({
      ...consultantData,
      ["orgId"]: clientDetail.orgId,
    });
  }, [clientDetail]);

  useEffect(() => {
    dispatch(getRolesThunk())
      .unwrap()
      .then((res) => {
        if (res && res.status === true) {
          setEmployeeRoles(res?.data);
        }
      })
      .catch((err) => {
        if (err.message) {
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        } else {
          // enqueueSnackbar("Problem in fetching data!", { variant: "error" });

          const key = enqueueSnackbar("Problem in fetching data!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  }, [dispatch]);

  const {
    experiences,
    contractTypes: contractTypesReceived,
    skills,
    industryData,
  } = useSelector((state) => state.misc);

  const [consultantData, setConsultantData] = useState(
    // {

    //   overview: {
    //     first_name: "",
    //     last_name: "",
    //     jobTitle: "",
    //     displayPicture: "",
    //     salaryDetails: {},
    //     experienceLevel: 0, // experienceLevel index as per experienceLevel data(we fetch that data below).
    //     availability: 0, // contractType index as per contractTypes data(we fetch that data below).
    //     country: "",
    //     city: "",
    //     state: "",
    //     timezone: "",
    //     summary: "",
    //   },
    //   socialLinks: {
    //     website: "",
    //     facebook: "",
    //     linkedin: "",
    //   },
    //   skills: defaultSkillList,
    //   workHistory: [],
    //   education: [],
    //   certification: [],
    //   languages: [],
    //   awards: [],
    //   testimonials: [],
    //   resumeDoc: "",
    //   videoResume: "",
    //   userRole:""
    // }
    {
      email: "",
      firstName: "",
      lastName: "",
      orgId: "",
      timezone: "",
      country: "",
      state: "",
      city: "",
      phoneNumber: "",
      roleId: "",
      companyTitle: "",
    }
  );

  const [consultantEmail, setConsultantEmail] = useState();
  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
  // {
  //   'id':'name'
  // }
  const [activateSendInvite, setActivateSendInvite] = useState(true);
  const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
  const [sendInviteActionLoading, setSendInviteActionLoading] = useState(false);
  const [sendInviteCompleted, setSendInviteCompleted] = useState(false);
  const [sendInviteError, setSendInviteError] = useState(false);

  const onOverviewEntryChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = {
        ...prevProfileData,
        [id]: value,
      };
      return updatedProfileData;
    });
  };

  const onOtherInfoChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      updatedProfileData[id] = value;

      return updatedProfileData;
    });
  };

  const onSocialLinksEntryChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      let socialLinksData = { ...updatedProfileData?.socialLinks };
      socialLinksData[id] = value;
      updatedProfileData.socialLinks = socialLinksData;
      return updatedProfileData;
    });
  };

  const onFileUploadToS3 = async (file, fileId) => {
    let s3UploadedFileDetails;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      // enqueueSnackbar(
      //   `Problem in uploading the ${fileId},please try again later!`,
      //   { variant: "error" }
      // );
      const key = enqueueSnackbar(
        `Problem in uploading the ${fileId},please try again later!`,
        {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
    return s3UploadedFileDetails?.[0]?.location;
  };

  const handleDialoguesAndApi2 = () => {
    handleDialoguesAndApi();
  };

  const onSendInviteClick = async () => {
    setSendInviteError(false);

    let displayPictureUrl, resumeDocUrl, videoResumeUrl;

    // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).
    const displayPictureUrlOrDetails = consultantData?.overview?.displayPicture;
    const resumeDocUrlOrDetails = consultantData?.resumeDoc;
    const videoResumeUrlOrDetails = consultantData?.videoResume;

    const displayPictureFile = displayPictureUrlOrDetails?.file;
    const resumeDocFile = resumeDocUrlOrDetails?.file;
    const videoResumeFile = videoResumeUrlOrDetails?.file;

    setSendInviteDialogOpen(true);
    setSendInviteActionLoading(true);

    // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    const updatedConsultantData = { ...consultantData };

    setTimeout(() => {
      dispatch(sendEmployeeInvitationThunk(consultantData))
        .unwrap()
        .then((res) => {
          if (res) {
            if (res?.message) {
              // enqueueSnackbar(res.message, { variant: "success" });
              const key = enqueueSnackbar(res.message, {
                variant: "success",
                autoHideDuration: 3000,
                action: (key) => (
                  <CloseIcon
                    onClick={() => closeSnackbar(key)}
                    style={{
                      cursor: "pointer",
                      fontSize: "15",
                      marginTop: "-1px",
                    }}
                  />
                ),
              });
            }
            setSendInviteActionLoading(false);
            // setSendInviteCompleted(true);
            setSendInviteDialogOpen(false);
            handleDialoguesAndApi2();
          }
        })
        .catch((err) => {
          setSendInviteActionLoading(false);
          setSendInviteError(true);
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });

          console.error(err);
        });

      /** Confirm case */
      // setSendInviteActionLoading(false);
      // setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
    }, 1000);
  };

  const handleOnUpdate = () => {
    setSendInviteDialogOpen(true);
    setSendInviteActionLoading(true);
    const request = {
      id: selectedEmployee._id,
      roleId: employeeRoles?.find(
        (emp) => emp?.roleType === selectedEmployeeRole
      )?._id,
    };

    setTimeout(() => {
      dispatch(UpdateEmployeeDetailsThunk(request))
        .unwrap()
        .then((res) => {
          if (res && res?.message) {
            // enqueueSnackbar(res.message, { variant: "success" });
            const key = enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            });

            setSendInviteActionLoading(false);
            // setSendInviteCompleted(true);
            setSendInviteDialogOpen(false);

            handleDialoguesAndApi2();
          }
        })
        .catch((err) => {
          setSendInviteActionLoading(false);
          setSendInviteError(true);
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        });

      /** Confirm case */
      // setSendInviteActionLoading(false);
      // setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
    }, 1000);
  };

  const validateEntries = () => {
    // no validation for experience level, availability
    // const overviewData = consultantData?.overview;
    const overviewData = consultantData;

    const useRoleData = consultantData?.roleId;
    // const isValidFirstName = overviewData?.firstName?.length > 0;
    const isValidFirstName = overviewData?.firstName?.length > 0;
    // const isValidLastName = overviewData?.lastName?.length > 0;
    const isValidLastName = overviewData?.lastName?.length > 0;

    const isUserRoleData = useRoleData?.length > 0;
    const countrySelected = Boolean(overviewData?.country);
    const stateSelected = Boolean(overviewData?.state);
    const cityEntered = overviewData?.city?.length > 0;
    const timezoneSelected = Boolean(overviewData?.timezone);

    const validOverviewDetails =
      // isValidFirstName && isValidLastName && isUserRoleData;
      // countrySelected &&
      // stateSelected &&
      // cityEntered &&
      //   isUserRoleData &&
      // timezoneSelected
      (selectedEmployee || (isValidFirstName && isValidLastName)) &&
      isUserRoleData;

    // const validConsultantEmail = EMAIL_PATTERN.test(consultantEmail);
    const validConsultantEmail = EMAIL_PATTERN.test(consultantData.email);

    // console.log({
    //   validOverviewDetails,
    //   skillsSelected,
    //   workExperienceAdded,
    //   educationAdded,
    //   resumeAvailable,
    //   validConsultantEmail,
    // });
    setActivateSendInvite(
      selectedEmployee
        ? validOverviewDetails
        : validOverviewDetails && validConsultantEmail
    );
  };

  const onEmailChange = (id, value) => {
    setConsultantEmail(value);
  };

  const handleOnChangeofRole = (id, value) => {
    setSelectedEmployeeRole(value);
    setConsultantData({
      ...consultantData,
      roleId: employeeRoles?.find((emp) => emp?.roleType === value)?._id,
    });
  };

  useEffect(() => {
    console.log({ consultantData });
    validateEntries();
  }, [consultantData]);
  useEffect(() => {
    const roletypeValues = employeeRoles?.map((role) => role.roleType);
    setEmployeeRolesData(roletypeValues);
  }, [employeeRoles]);
  return (
    <div className="p-[48px] flex flex-col gap-y-[24px]">
      <Overview
        // overviewData={consultantData?.overview}
        // socialLinks={consultantData?.socialLinks}
        // experienceTypes={experienceTypes}
        // contractTypes={contractTypes}
        onOverviewEntryChange={onOverviewEntryChange}
        // onSocialLinksEntryChange={onSocialLinksEntryChange}
        selectedEmployee={selectedEmployee}
      />

      <div className="text-[24px] font-semibold">Invitation Email</div>
      <div className="px-[16px]">
        <CustomTextfield
          id="email"
          type="email"
          label="email"
          placeholder="Add Email"
          // value={consultantEmail}
          // onChange={onEmailChange}
          value={
            selectedEmployee ? selectedEmployee.email : consultantData.email
          }
          onChange={(email, val) => {
            setConsultantData({
              ...consultantData,
              ["email"]: val,
            });
          }}
          disabled={!!selectedEmployee}
        />
        <Link className={classes.resendInviteText} onClick={() => {}}>
          Resend Invite
        </Link>
      </div>
      <div className="text-[24px] font-semibold">Role</div>

      <Box
        sx={{
          border: "1px solid lightgray",
          margin: "5px 15px",
          padding: "15px",
          backgroundColor: "#F4F8FF",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "14px", color: "#1E5DFF" }}
        >
          User Role
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "12px", color: "lightgray" }}
        >
          Select the role that applies to the duties of this employee within the
          Findpro App. This will dictate the parameters by which they can make
          changes in the company
        </Typography>
        <Box sx={{ margin: "5px 0px" }}>
          {/* <CustomSelect data={employeeData} placeholder={""} onChange={onOverviewEntryChange} overviewData={consultantData.userRole} /> */}

          <Box>
            {/* <FormControl fullWidth>
              <Select
                labelId={`role-select-label`}
                id={`role-select`}
                value={selectedEmployeeRole?.roleType}
                // label={"placeholder"}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSelectedEmployeeRole(e.target.value);
                  setConsultantData({
                    ...consultantData,
                    ["roleId"]: e.target.value._id,
                  });
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }}
              >
                {employeeRoles?.map((menuItemValue, idx) => (
                  <MenuItem
                    key={idx}
                    value={menuItemValue}
                  // className={classes.menuItem}
                  >
                    {menuItemValue?.roleType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <CustomSelect
              id="roleType"
              name="roleType"
              data={employeeRolesData}
              // label="Time Sheet  Type"
              placeholder={"Role type"}
              defaultSelection={selectedEmployee?.role ?? ""}
              onChange={handleOnChangeofRole}
              // defaultSelection={onboardingStatus}
            />
          </Box>
        </Box>
      </Box>

      <CustomTooltip
        title={!activateSendInvite && "Please enter the mandatory details."}
      >
        <div>
          <CustomButton
            type="button1"
            customClasses={classes.sendInviteButton}
            disabled={!activateSendInvite}
            onClick={() => {
              if (selectedEmployee) {
                handleOnUpdate();
              } else {
                onSendInviteClick();
              }
            }}
          >
            {selectedEmployee ? "Update" : "Send Invite"}{" "}
            <img src="/iconfilledsend.svg" />
          </CustomButton>
        </div>
      </CustomTooltip>
      <SendInviteDialog
        sendInviteDialogOpen={sendInviteDialogOpen}
        setSendInviteDialogOpen={setSendInviteDialogOpen}
        sendInviteActionLoading={sendInviteActionLoading}
        sendInviteCompleted={sendInviteCompleted}
        sendInviteError={sendInviteError}
      />
    </div>
  );
};

AddEmployee.propTypes = {};

export default AddEmployee;
