import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import Title from "../../common/Title";
import {
  CircularProgress,
  Alert,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import ContractListCard from "../common/Cards/MyJobs/Contract/List";
import { messages } from "app/v2/Pages/Utils/constants";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({
  activeWorkContainerLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  body: {
    paddingTop: "102px",
  },
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    overflow: "auto",
    height: "615px",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  searchTextDiv: {
    marginBottom: "26px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  workTypeCheckboxDiv: {
    margin: "12px 24px",
  },
  moreFilterDiv: {
    border: "1px solid #EDEDED",
    padding: "7.545px 13.204px",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    cursor: "pointer",
  },
  moreFilterText: {
    fontWeight: 600,
    fontSize: "14px",
  },
  filterImgAndCountDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  moreFilterCount: {
    fontWeight: 600,
    fontSize: "10px",
    color: theme.palette.primary.main,
    position: "relative",
    top: "1px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyActiveWorkData = [
  {
    _id: "1",
    companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    activeConsultants: ["/person1.png", "/person2.png", "/person3.png"],
    jobStatus: "active",
    onBoardingStatus: "assessment",
    hiringManager: "Mike Fitz",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
  {
    _id: "2",
    companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    activeConsultants: ["/person1.png", "/person2.png", "/person3.png"],
    jobStatus: "active",
    onBoardingStatus: "assessment",
    hiringManager: "Mike Fitz",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
  {
    _id: "3",
    companyImage: "/company_image.png",
    jobTitle: "Software Developer 1",
    jobNo: "FP23147",
    companyName: "ABC Company",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    activeConsultants: ["/person1.png", "/person2.png", "/person3.png"],
    jobStatus: "active",
    onBoardingStatus: "assessment",
    hiringManager: "Mike Fitz",
    teamMembersProfilePics: [
      "/person1.png",
      "/person2.png",
      "/person3.png",
      "/person4.png",
    ],
  },
];

const ActiveWork = (props) => {
  const classes = useStyles();

  const sortData = [
    {
      label: "Date Accepted",
      value: "Date Accepted",
    },

    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          Expertise <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "Expertise",
    },
  ];

  const [activeWorksData, setActiveWorksData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [searchText, setSearchText] = useState();
  const [currentSortSelectionValue, setCurrentSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [onBoardingStatusData, setOnboardingStatusData] = useState([
    {
      label: "Complete (2)",
      value: "Complete (2)",
      checked: false,
    },
    {
      label: "Ongoing (4)",
      value: "Ongoing (4)",
      checked: false,
    },
  ]);

  const onSortChange = (id, currentValue) => {
    setCurrentSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState) => {
    setOnboardingStatusData((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.value === checkboxValue) {
          return { ...item, checked: checkedState };
        }
        return item;
      });

      return updatedItems;
    });
  };

  useEffect(() => {
    // add right api later.
    // getEmployerInfo()
    //   .then((res) => {
    //     console.log({ res });
    //        setDataLoading(false);
    //         setActiveWorksData(res);
    //   })
    //   .catch((error) => {
    // setDataLoading(false);
    //      setDataError(true);
    // });
    setDataLoading(false);
    setActiveWorksData(dummyActiveWorkData);
  }, []);

  return (
    <div className={dataLoading && classes.activeWorkContainerLoading}>
      <>
        {dataLoading ? (
          // <CircularProgress />
          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="80" rx="3" ry="3" width="280" height="500" />
            <rect x="305" y="80" rx="3" ry="3" width="1080" height="500" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <div>
                <div className={classes.headerDiv}>
                  <Title title="Active Work" />
                </div>

                <div className={classes.body}>
                  <Grid container>
                    <Grid item className={classes.leftView}>
                      <div className={classes.searchTextDiv}>
                        <CustomTextfield
                          id="searchText"
                          value={searchText}
                          placeholder="Search by name"
                          onChange={(id, value) => {
                            setSearchText(value);
                          }}
                          endAdornment={<SearchRoundedIcon />}
                        />
                      </div>
                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Sort{" "}
                        </Typography>
                        <div className={classes.sortRadioGroupDiv}>
                          <CustomRadioGroup
                            id="sort-radio-group"
                            name="sort-radio-group"
                            defaultValue={currentSortSelectionValue}
                            data={sortData}
                            onChange={onSortChange}
                          />
                        </div>
                      </div>

                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Filter
                        </Typography>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Onboarding Status
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <div className={classes.workTypeCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="worktype-checkbox-group"
                                  data={onBoardingStatusData}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Contract
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>

                      <div className={classes.moreFilterDiv}>
                        <Typography className={classes.moreFilterText}>
                          More Filters
                        </Typography>
                        <div className={classes.filterImgAndCountDiv}>
                          <img src="/filter-list.svg" />
                          <span className={classes.moreFilterCount}>2</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item className={classes.rightView}>
                      {activeWorksData?.map((contract) => (
                        <ContractListCard data={contract} />
                      ))}
                      <div className={classes.loadMoreDiv}>
                        <MoreHorizIcon
                          className={classes.moreIcon}
                          fontSize="large"
                        />
                        <Typography className={classes.loadMoreText}>
                          Load more
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

ActiveWork.propTypes = {};

export default ActiveWork;
