import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography, Divider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import images from "../images/common";
import EmployerProfileMenu from "../Pages/Employer/ProfileMenu";
import ConsultantProfileMenu from "../Pages/Consultant/ProfileMenu";
import AgencyProfileMenu from "../Pages/Agency/ProfileMenu";
import AdminProfileMenu from "../Pages/Admin/ProfileMenu";

const useStyles = makeStyles((theme) => ({}));
const SideMenu = (props) => {
  const location = useLocation();
  const pathName = location?.pathname.split("/");
  const view = pathName[1];
  const menuComponent = {
    client: <EmployerProfileMenu />,
    consultant: <ConsultantProfileMenu />,
    agency: <AgencyProfileMenu />,
    admin: <AdminProfileMenu />,
  };

  return <div>{menuComponent?.[view]}</div>;
};

SideMenu.propTypes = {};

export default SideMenu;
