import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import TitleWithConnector from "../../../components/TitleWithConnector";
import TitleAndImageGroup from "../../../components/TitleAndImageGroup";
import images from "../../../images/hire_developer_team/developer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  connectorCompaniesRoot: {
    padding: "129px 140px",
    marginBottom: "160px",
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down("1700")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("600")]: {
      fontSize: "1rem",
    },
  },
  greyText: {
    color: theme.palette.secondary.light,
  },
}));

const ConnectorAndCompanies = (props) => {
  const title =
    "Join 400+ Fortune 500 companies and fast-scaling startups that trust FindPro";
  const companyImages = images.companies;
  const classes = useStyles();
  return (
    <div className={classes.connectorCompaniesRoot}>
      <TitleWithConnector
        titleComponent={
          <Typography variant="body1" className={classes.title}>
            Real-Time <span className={classes.greyText}>Talent</span>{" "}
          </Typography>
        }
        bgColor="black"
      />
      <TitleAndImageGroup
        title={title}
        images={companyImages}
        bgColor="black"
      />
    </div>
  );
};

ConnectorAndCompanies.propTypes = {};

export default ConnectorAndCompanies;
