import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { UserContext } from "../index";
import CustomTextfield from "./filters/CustomTextfield";
import { getAccountType } from "../Pages/Utils/utilFunctions";


const useStyles = makeStyles((theme) => ({
  searchDiv: {
    "& .MuiInputBase-input": {
      width: "447px",
      height: "25.9px",
      padding: "7px 15px",
      [theme.breakpoints.down("1300")]: {
        width: "230px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.943166px solid #EDEDED",
    },
  },
}));
const SearchBox = ({ placeHolder }) => {
  const classes = useStyles();
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const accountType = getAccountType();
  


  const onInputClick = (id, value) => {
    setDrawerDetails((prevState) => ({
      ...prevState,
      showDrawer: true,
      componentType: "filter",
      showHeading: true,
      accountType: accountType, 

    }));
  };
  return (
    <div>
      <CustomTextfield
        placeholder={placeHolder}
        onClick={onInputClick}
        endAdornment={<SearchIcon />}
        customClasses={{ root: classes.searchDiv }}
      />
    </div>
  );
};

SearchBox.propTypes = {};

export default SearchBox;
