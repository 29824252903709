import React from "react";
export const IconDot= ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
        width="10" height="10" viewBox="0 0 10 10" >
        <g clipPath="url(#clip0_0_711)">
        <path d="M9.33366 4.99984C9.33366 5.78601 9.13994 6.51105 8.7525 7.17497C8.36505 7.83889 7.83937 8.36457 7.17546 8.75201C6.51154 9.13945 5.78649 9.33317 5.00033 9.33317C4.21416 9.33317 3.48911 9.13945 2.8252 8.75201C2.16128 8.36457 1.6356 7.83889 1.24816 7.17497C0.860713 6.51105 0.666992 5.78601 0.666992 4.99984C0.666992 4.21367 0.860713 3.48863 1.24816 2.82471C1.6356 2.16079 2.16128 1.63511 2.8252 1.24767C3.48911 0.860225 4.21416 0.666504 5.00033 0.666504C5.78649 0.666504 6.51154 0.860225 7.17546 1.24767C7.83937 1.63511 8.36505 2.16079 8.7525 2.82471C9.13994 3.48863 9.33366 4.21367 9.33366 4.99984Z" />
        </g>
        <defs>
        <clipPath id="clip0_0_711">
        <rect width="10" height="10" fill="red"/>
        </clipPath>
        </defs>
      </svg>
    </>

  );
};
