// import React, { useState,useEffect } from "react";
// import {
//   Avatar,
//   Box,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Divider,
//   FormControl,
//   Grid,
//   IconButton,
//   InputAdornment,
//   InputLabel,
//   Link,
//   MenuItem,
//   OutlinedInput,
//   Select,
//   Stack,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import CustomSelect from "app/v2/components/filters/CustomSelect";
// import CheckIcon from "@mui/icons-material/Check";
// import CustomTextfield from "app/v2/components/filters/CustomTextfield";
// import CustomTooltip from "app/v2/components/CustomTooltip";
// import { EMAIL_PATTERN } from "../../Utils/constants";
// import DialogSuccessBody from "../../common/DialogSuccessBody";
// import DialogErrorBody from "../../common/DialogErrorBody";
// import CustomDialog from "app/v2/components/CustomDialog";
// import DialogLoadingBody from "../../common/DialogLoadingBody";
// import { messages } from "../../Utils/constants";
// const consultantss = ["a", "b"];

// const useStyles = makeStyles((theme) => ({
//   alEmailFormControl: {
//     width: "100%",
//   },
//   alEmailInput: {
//     width: "100%",
//     height: "40px",
//     backgroundColor: "#F7F7F7",
//     borderRadius: "0",
//     "& .MuiInputBase-input": {
//       padding: "9px 12px",
//       fontSize: "1px",
//       [theme.breakpoints.down("1250")]: {
//         fontSize: "10px",
//       },
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "0",
//     },
//   },
//   box: {
//     // textAlign: "center",
//   },
//   formControl: {
//     "& .MuiOutlinedInput-notchedOutline": {
//       borderRadius: 0,
//       border: "1px solid #EDEDED",
//     },
//   },
//   selectRoot: {
//     height: "50px",
//     fontSize: "16px",
//   },
//   selectLabel: {
//     fontSize: "16px",
//     color: theme.palette.secondary.light,
//   },
//   menuItem: {
//     fontSize: "16px",
//   },
// }));

// const SendInviteDialog = ({
//     sendInviteDialogOpen,
//     setSendInviteDialogOpen,
//     sendInviteActionLoading,
//     sendInviteCompleted,
//     sendInviteError,
//   }) => {
//     const classes = useStyles();

//     const Title = () => {
//       return (
//         <Typography className={classes.dialogTitle}>
//           {sendInviteActionLoading && "Action in progress"}
//           {sendInviteError && "Error"}
//           {sendInviteCompleted && "Changes Saved"}
//         </Typography>
//       );
//     };

//     const DialogBody = () => {
//       if (sendInviteActionLoading) return <DialogLoadingBody />;
//       else if (sendInviteCompleted)
//         return (
//           <DialogSuccessBody
//             content={{ title: "Your changes have been saved." }}
//           />
//         );
//       else if (sendInviteError)
//         return (
//           <DialogErrorBody
//             content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
//           />
//         );
//       else return <></>;
//     };

//     return (
//       <CustomDialog
//         open={sendInviteDialogOpen}
//         setOpen={setSendInviteDialogOpen}
//         noCloseAction={sendInviteActionLoading}
//         title={<Title />}
//         content={<DialogBody />}
//       />
//     );
//   };

// const AddTeamMembers = () => {
//   const classes = useStyles();
//   const [consultantEmail, setConsultantEmail] = useState();
//   const [activateSendInvite, setActivateSendInvite] = useState(true);
//   const [isButtonDisabled, setIsButtonDisabled] = useState(true);
//   const [selectedConsultant, setSelectedConsultant] = useState("");
//   const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
//   const [sendInviteActionLoading, setSendInviteActionLoading] = useState(false);
//   const [sendInviteCompleted, setSendInviteCompleted] = useState(false);
//   const [sendInviteError, setSendInviteError] = useState(false);

//   const consultants = [
//     {
//       name: "Lacy Clark",
//       email: "lacy.clark@gmail.com",
//       avatar: "/avatar.png",
//     },
//     {
//       name: "Mark Smit",
//       email: "m.smit@gmail.com",
//       avatar: "/avatar.png",
//     },
//     {
//       name: "John Doe",
//       email: "john.doe@gmail.com",
//       avatar: "/avatar.png",
//     },
//     // Add more consultant data as needed
//   ];

//   const onSendInviteClick = async () => {

//     // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).

//     setSendInviteDialogOpen(true);
//     setSendInviteActionLoading(true);

//     // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
//     //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

//     setTimeout(() => {
//       /** Confirm case */
//       setSendInviteActionLoading(false);
//       setSendInviteCompleted(true);
//       /** Error case */
//       // setSendInviteActionLoading(false);
//       // setSendInviteError(true);
//     }, 2000);

//     // dispatch(updateCandidateInfoThunk(updatedProfileDataFormat))
//     //   .unwrap()
//     //   .then((res) => {
//     //     setSendInviteActionLoading(false);
//     //     setSendInviteCompleted(true);
//     //   })
//     //   .catch((err) => {
//     //     setSendInviteActionLoading(false);
//     //     setSendInviteError(true);

//     //     console.error(err);
//     //   });
//   };

//   const onEmailChange = (id, value) => {
//     setConsultantEmail(value);
//     validateEntries();
//   };

//   const onConsultantChange=(id,value)=>{

//   }

//   const validateEntries = () => {
//     // Check if a consultant is selected
//     const isConsultantSelected = selectedConsultant !== "";

//     // Check if the email is valid using the EMAIL_PATTERN
//     const validConsultantEmail = EMAIL_PATTERN.test(consultantEmail);

//     // Enable the button only if both conditions are met
//     setIsButtonDisabled(!(isConsultantSelected && validConsultantEmail));
//   };

//   return (
//     <div style={{ padding: "48px" }}>
//       <Typography
//         variant="subtitle2"
//         sx={{
//           fontSize: "24px",
//           color: "#0B0E1E",
//           fontWeight: 600,
//           marginBottom: "27px",
//         }}
//       >
//         AddTeamMember
//       </Typography>
//       <Box sx={{ margin: "0px 15px" }}>
//         <FormControl fullWidth className={classes.formControl}>
//           <InputLabel
//             id="demo-simple-select-label"
//             className={classes.selectLabel}
//           >
//             Choose Consultant
//           </InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             size="small"
//             label="Choose Consultant"
//             className={classes.selectRoot}
//             value={selectedConsultant}
//             onChange={(e) => {
//                 setSelectedConsultant(e.target.value);
//                 validateEntries();}}
//           >
//             {consultants.map((consultant, index) => (
//               <MenuItem key={index} value={consultant.email}>
//                 <div style={{ display: "flex" }}>
//                   <Avatar
//                     alt={consultant.name}
//                     src={consultant.avatar}
//                     sx={{ width: 20, height: 20 }}
//                     variant="square"
//                   />
//                   <Typography
//                     variant="subtitle2"
//                     sx={{ fontSize: "12px", color: "black" }}
//                   >
//                     {consultant.name}
//                   </Typography>
//                   <Typography
//                     variant="subtitle2"
//                     sx={{ fontSize: "12px", color: "lightgray" }}
//                   >
//                     {consultant.email}
//                   </Typography>
//                 </div>
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </Box>
//       <Typography
//         variant="subtitle2"
//         sx={{
//           fontWeight: 600,
//           fontSize: "24px",
//           color: "#0B0E1E",
//           margin: "33px 33px",
//           marginLeft: "0px",
//         }}
//       >
//         Manage Employee
//       </Typography>
//       <Box
//         sx={{
//           border: "1px solid lightgray",
//           margin: "5px 15px",
//           padding: "15px",
//           backgroundColor: "#F4F8FF",
//         }}
//       >
//         <Typography
//           variant="subtitle2"
//           sx={{ fontSize: "18px", color: "#1E5DFF", fontWeight: 600 }}
//         >
//           User Role
//         </Typography>
//         <Typography
//           variant="subtitle2"
//           sx={{ fontSize: "12px", color: "lightgray" }}
//         >
//           Select the role that applies to the duties of this employee within the
//           Findpro App. This will dictate the parameters by which they can make
//           changes in the company
//         </Typography>
//         <Box sx={{ margin: "5px 0px" }}>
//           <CustomSelect data={consultantss} placeholder={"Recruiter"} />
//         </Box>
//       </Box>
//       <Divider light sx={{ margin: "24px 13px 24px 13px" }} />
//       <Typography
//         variant="subtitle2"
//         sx={{ fontWeight: 600, fontSize: "24px", color: "#0B0E1E" }}
//       >
//         Login Details
//       </Typography>
//       <Box sx={{ margin: "15px 15px" }}>
//         {/* <FormControl variant="outlined" className={classes.alEmailFormControl}>
//           <OutlinedInput
//             id="outlined-adornment-password"
//             className={classes.alEmailInput}
//             endAdornment={
//               <InputAdornment position="end">
//                 <IconButton
//                   aria-label="toggle password visibility"
//                   //   onClick={handleClickShowPassword}
//                   //   onMouseDown={handleMouseDownPassword}
//                   edge="end"
//                 >
//                   <Typography
//                     variant="subtitle2"
//                     sx={{ fontSize: "12px", color: "#ABABAB" }}
//                   >
//                     PENDING INVITE
//                   </Typography>
//                   <CheckIcon />
//                 </IconButton>
//               </InputAdornment>
//             }
//             placeholder="eg:yourmail@mail.com"
//           />
//         </FormControl> */}

//         <CustomTextfield
//           id="email"
//           type="email"
//           label="EMAIL"
//           placeholder="Add Email"
//           value={consultantEmail}
//           onChange={onEmailChange}
//           onBlur={validateEntries}        />
//         <Link
//           href="#"
//           variant="body2"
//           sx={{ fontSize: "14px", marginTop: "12px" }}
//         >
//           Resend invite
//         </Link>

//       </Box>
//       <button
//         style={{
//           border: "1px solid gray",
//           padding: "4px",
//           background: "black",
//           color: "white",
//           width: "100%",
//         }}
//         onClick={() => {
//             onSendInviteClick();
//           }}
//         disabled={!isButtonDisabled} //check this later .Still need to implement the validations.
//       >
//         Invite Team Member
//       </button>

//       <SendInviteDialog
//         sendInviteDialogOpen={sendInviteDialogOpen}
//         setSendInviteDialogOpen={setSendInviteDialogOpen}
//         sendInviteActionLoading={sendInviteActionLoading}
//         sendInviteCompleted={sendInviteCompleted}
//         sendInviteError={sendInviteError}
//       />
//     </div>
//   );
// };

// export default AddTeamMembers;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";

import { Link, Typography } from "@mui/material";

import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomButton from "app/v2/components/CustomButton";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";
import { Box } from "@mui/material";
import CustomSelect from "app/v2/components/filters/CustomSelect";

import { defaultSkillList, messages } from "app/v2/Pages/Utils/constants";
import { isValidArray, isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import useFetch from "app/common/utils/useFetch";
import { s3Upload } from "app/v2/services/miscService";
import { EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";
const employeeData = ["a", "b"];

const useStyles = makeStyles((theme) => ({
  sendInviteButton: {
    width: "100%",
    padding: "16px 28px",
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    columnGap: "10px",
    fontSize: "16px",
    fontWeight: 600,
  },
  line: {
    marginBottom: 0,
  },
  resendInviteText: {
    fontSize: "14px",
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
    marginTop: "12px",
    cursor: "pointer",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
}));

const SendInviteDialog = ({
  sendInviteDialogOpen,
  setSendInviteDialogOpen,
  sendInviteActionLoading,
  sendInviteCompleted,
  sendInviteError,
}) => {
  const classes = useStyles();

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {sendInviteActionLoading && "Action in progress"}
        {sendInviteError && "Error"}
        {sendInviteCompleted && "Changes Saved"}
      </Typography>
    );
  };

  const DialogBody = () => {
    if (sendInviteActionLoading) return <DialogLoadingBody />;
    else if (sendInviteCompleted)
      return (
        <DialogSuccessBody
          content={{ title: "Your changes have been saved." }}
        />
      );
    else if (sendInviteError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={sendInviteDialogOpen}
      setOpen={setSendInviteDialogOpen}
      noCloseAction={sendInviteActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const AddEmployee = ({ selectedEmployee }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [get] = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    experiences,
    contractTypes: contractTypesReceived,
    skills,
    industryData,
  } = useSelector((state) => state.misc);

  console.log("iamchecking", selectedEmployee);
  // const [consultantData, setConsultantData] = useState(

  //   {

  //   overview: {
  //     first_name: "",
  //     last_name: "",
  //     jobTitle: "",
  //     displayPicture: "",
  //     salaryDetails: {},
  //     experienceLevel: 0, // experienceLevel index as per experienceLevel data(we fetch that data below).
  //     availability: 0, // contractType index as per contractTypes data(we fetch that data below).
  //     country: "",
  //     city: "",
  //     state: "",
  //     timezone: "",
  //     summary: "",
  //   },
  //   socialLinks: {
  //     website: "",
  //     facebook: "",
  //     linkedin: "",
  //   },
  //   skills: defaultSkillList,
  //   workHistory: [],
  //   education: [],
  //   certification: [],
  //   languages: [],
  //   awards: [],
  //   testimonials: [],
  //   resumeDoc: "",
  //   videoResume: "",
  //   userRole:""
  // });

  const [consultantData, setConsultantData] = useState({
    consultantEmail: "",
    consultantName: "",
    consultantUserRole: "",
  });

  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();
  // {
  //   'id':'name'
  // }
  const [activateSendInvite, setActivateSendInvite] = useState(true);
  const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false);
  const [sendInviteActionLoading, setSendInviteActionLoading] = useState(false);
  const [sendInviteCompleted, setSendInviteCompleted] = useState(false);
  const [sendInviteError, setSendInviteError] = useState(false);

  const onOverviewEntryChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      let overviewData = updatedProfileData?.overview;
      overviewData[id] = value;
      updatedProfileData.overview = overviewData;
      return updatedProfileData;
    });
  };

  const onOtherInfoChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      updatedProfileData[id] = value;

      return updatedProfileData;
    });
  };

  const onSocialLinksEntryChange = (id, value) => {
    setConsultantData((prevProfileData) => {
      let updatedProfileData = { ...prevProfileData };
      let socialLinksData = { ...updatedProfileData?.socialLinks };
      socialLinksData[id] = value;
      updatedProfileData.socialLinks = socialLinksData;
      return updatedProfileData;
    });
  };

  const onFileUploadToS3 = async (file, fileId) => {
    let s3UploadedFileDetails;
    const formData = new FormData();
    formData.append("file", file);
    try {
      s3UploadedFileDetails = await get(s3Upload(formData));
    } catch (err) {
      enqueueSnackbar(
        `Problem in uploading the ${fileId},please try again later!`,
        { variant: "error" }
      );
    }
    return s3UploadedFileDetails?.[0]?.location;
  };

  const onSendInviteClick = async () => {
    let displayPictureUrl, resumeDocUrl, videoResumeUrl;

    // the below 3 elements can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).
    const displayPictureUrlOrDetails = consultantData?.overview?.displayPicture;
    const resumeDocUrlOrDetails = consultantData?.resumeDoc;
    const videoResumeUrlOrDetails = consultantData?.videoResume;

    const displayPictureFile = displayPictureUrlOrDetails?.file;
    const resumeDocFile = resumeDocUrlOrDetails?.file;
    const videoResumeFile = videoResumeUrlOrDetails?.file;

    setSendInviteDialogOpen(true);
    setSendInviteActionLoading(true);

    // If at all upload was attempted for them we try to access the file part of the object(done above) and upload it to s3 and get the updated url of its location in s3 on successful upload.If s3 upload fails we we show the message in snackbar and update the prev file itself in the profileData.
    //  Else i.e, upload was not attempted, file value wont exist and it will be the url instead(prev/current url).

    const updatedConsultantData = { ...consultantData };

    setTimeout(() => {
      /** Confirm case */
      setSendInviteActionLoading(false);
      setSendInviteCompleted(true);
      /** Error case */
      // setSendInviteActionLoading(false);
      // setSendInviteError(true);
    }, 2000);

    // dispatch(updateCandidateInfoThunk(updatedProfileDataFormat))
    //   .unwrap()
    //   .then((res) => {
    //     setSendInviteActionLoading(false);
    //     setSendInviteCompleted(true);
    //   })
    //   .catch((err) => {
    //     setSendInviteActionLoading(false);
    //     setSendInviteError(true);

    //     console.error(err);
    //   });
  };

  const validateEntries = () => {
    // no validation for experience level, availability
    const overviewData = consultantData?.overview;

    const useRoleData = consultantData?.userRole;
    // const isValidFirstName = overviewData?.firstName?.length > 0;
    const isValidFirstName = overviewData?.first_name?.length > 0;
    // const isValidLastName = overviewData?.lastName?.length > 0;
    const isValidLastName = overviewData?.last_name?.length > 0;

    const isUserRoleData = useRoleData?.length > 0;
    const countrySelected = Boolean(overviewData?.country);
    const stateSelected = Boolean(overviewData?.state);
    const cityEntered = overviewData?.city?.length > 0;
    const timezoneSelected = Boolean(overviewData?.timezone);

    const validOverviewDetails =
      isValidFirstName &&
      isValidLastName &&
      countrySelected &&
      stateSelected &&
      cityEntered &&
      //   isUserRoleData &&
      timezoneSelected;

    // const validConsultantEmail = EMAIL_PATTERN.test(consultantEmail);
    const validConsultantEmail = EMAIL_PATTERN.test(
      consultantData.consultantEmail
    );

    // Assuming that `consultantData` is the state that stores the email value.

    // console.log({
    //   validOverviewDetails,
    //   skillsSelected,
    //   workExperienceAdded,
    //   educationAdded,
    //   resumeAvailable,
    //   validConsultantEmail,
    // });
    setActivateSendInvite(validOverviewDetails && validConsultantEmail);
  };
  const userOptions = [
    {
      name: "John Doe",
      email: "john@example.com",
      userRole: "Admin",
    },
    {
      name: "Jane Smith",
      email: "jane@example.com",
      userRole: "Manager",
    },
    {
      name: "Robert Johnson",
      email: "robert@example.com",
      userRole: "Employee",
    },
    {
      name: "Sarah Brown",
      email: "sarah@example.com",
      userRole: "Supervisor",
    },
    {
      name: "David Wilson",
      email: "david@example.com",
      userRole: "Team Lead",
    },
    {
      name: "Michael Lee",
      email: "michael@example.com",
      userRole: "Consultant",
    },
  ];
  // const onEmailChange = (id, value) => {
  //   setConsultantEmail(value);
  // };

  useEffect(() => {
    console.log({ consultantData });
    validateEntries();
  }, [consultantData]);

  return (
    <div className="p-[48px] flex flex-col gap-y-[24px]">
      <CustomSelect
        id="consultantName"
        label="Consultant Name"
        data={userOptions.map((user) => user.name)}
        // Replace with your name data
        placeholder="Choose Consultant Name"
        onChange={(id, value) =>
          setConsultantData((prevData) => ({
            ...prevData,
            consultantName: value,
          }))
        }
      />
      <Box
        sx={{
          border: "1px solid lightgray",
          margin: "5px 15px",
          padding: "15px",
          backgroundColor: "#F4F8FF",
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "14px", color: "#1E5DFF" }}
        >
          User Role
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ fontSize: "12px", color: "lightgray" }}
        >
          Select the role that applies to the duties of this employee within the
          Findpro App. This will dictate the parameters by which they can make
          changes in the company
        </Typography>
        <Box sx={{ margin: "5px 0px" }}>
          <CustomSelect
            id="consultantUserRole"
            label="Consultant User Role"
            data={userOptions.map((user) => user.userRole)}
            // Replace with your user role data
            placeholder="Choose Consultant User Role"
            onChange={(id, value) =>
              setConsultantData((prevData) => ({
                ...prevData,
                consultantUserRole: value,
              }))
            }
          />
        </Box>
      </Box>

      <div className="text-[24px] font-semibold">Login Details</div>
      <div className="px-[16px]">
        {/* <CustomTextfield
          id="consultantEmail"
          label="Consultant Email"
          // data={emailOptions} // Replace with your email data
          placeholder="Choose Consultant Email"
          onChange={(id, value) =>
            setConsultantData((prevData) => ({
              ...prevData,
              consultantEmail: value,
            }))
          }
        /> */}
        <CustomTextfield
          id="consultantEmail"
          label="Consultant Email"
          placeholder="Enter Consultant Email"
          onChange={(id, value) =>
            setConsultantData((prevData) => ({
              ...prevData,
              consultantEmail: value,
            }))
          }
        />
        <Link className={classes.resendInviteText} onClick={() => {}}>
          Resend Invitesss
        </Link>
      </div>
      <CustomTooltip
        title={!activateSendInvite && "Please enter the mandatory details."}
      >
        <div>
          <CustomButton
            type="button1"
            customClasses={classes.sendInviteButton}
            disabled={!activateSendInvite}
            onClick={() => {
              onSendInviteClick();
            }}
          >
            Send Invite <img src="/iconfilledsend.svg" />
          </CustomButton>
        </div>
      </CustomTooltip>
      <SendInviteDialog
        sendInviteDialogOpen={sendInviteDialogOpen}
        setSendInviteDialogOpen={setSendInviteDialogOpen}
        sendInviteActionLoading={sendInviteActionLoading}
        sendInviteCompleted={sendInviteCompleted}
        sendInviteError={sendInviteError}
      />
    </div>
  );
};

AddEmployee.propTypes = {};

export default AddEmployee;
