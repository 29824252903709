import React from "react";
import PropTypes from "prop-types";

const Title = ({ title, topText }) => {
  return (
    <div className="my-[24px] ml-[24px]">
      {topText && (
        <div className="text-[14px] font-semibold uppercase">{topText}</div>
      )}
      <div className="text-[36px] font-semibold">{title}</div>
    </div>
  );
};

Title.propTypes = {};

export default Title;
