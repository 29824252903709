export const ERROR_MESSAGE = "Something went wrong.Please try again later.";

export const ROLES = {
  CANDIDATE: "candidate",
  EMPLOYER: "employer",
  VENDOR: "vendors",
};

export const incompleteProfileMessages = {
  candidates:
    "Kindly fill your Profile Settings, Profile Summary, IT Skills and upload your resume to apply for job.",
  employers:
    "Please complete your profile settings so that you can continue to post jobs or look for candidates.",
  vendors:
    "  Please complete your profile settings so that you can continue to search for jobs and upload candidates.",
};
