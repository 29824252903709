const dashboardHeroObj = {
  total_jobs_filled: 12,
  jobs_filled: 80,
  avg_time_jobs_filled: "13 hours",
  job_retention_rate: 98,
  consultant_satisfaction_rate: 4.6,
  total_interview: 24,
  total_job_offers: 123,
  chart: [
    {
      job_id: 11231313,
      ti: 2,
      tj: 8,
      timestamp: 32431341234,
    },
    {
      ti: 2,
      tj: 8,
      timestamp: 32431341234,
    },
  ],
  _id: "62bcb8c1ec04bcd9ef2c10a2",
  name: "Edward",
};

export { dashboardHeroObj };
