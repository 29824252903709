import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  IconButton,  
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({  
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiPaper-root.MuiDialog-paper": {
    minWidth: "40vw",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 3}}
      {...other}
    >      
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CustomDialog = ({
  open,
  setOpen,
  title,
  children,
  subTitle = "",
  id = "",
  className = "",
}) => {
  const handleClose = (e, reason) => {
    setOpen(false, reason);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      id={id}
      className={className}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>        
          {title}
          {subTitle && <>
          <br/>
          <span style={{
            fontWeight: 400,
            fontSize: "15px"
          }}>{subTitle}</span>
        </>}
      </BootstrapDialogTitle>
      {children}
    </BootstrapDialog>
  );
};

export default CustomDialog;
