import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
  Fade,
  CircularProgress,
  Select,
  TextField,
} from "@mui/material";
import ContentLoader from "react-content-loader";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";

import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";
import DialogSuccessBody from "../../common/DialogSuccessBody";
import DialogLoadingBody from "../../common/DialogLoadingBody";
import DialogErrorBody from "../../common/DialogErrorBody";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomButton from "app/v2/components/CustomButton";
import UnifiedFeatureTable from "./UnifiedFeatureTable";
import CustomLabel from "app/v2/components/filters/CustomLabel";

import { messages } from "../../Utils/constants";
import {
  capitalizeFirstLetter,
  isValidString,
  isValidArray,
  isValidObject,
} from "../../Utils/utilFunctions";

import {
  addPlan,
  updatePlan,
  deletePlan,
  addFeature,
  updateFeature,
  deleteFeature,
  getFeatureItems,
  addFeatureItem,
  updateFeatureItem,
  deleteFeatureItem,
  payPalPlansListApi,
} from "app/v2/services/admin/subscriptions.service";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
  },
  dialogActionButton: {
    fontWeight: 600,
    paddingLeft: "28px",
    paddingRight: "28px",
  },
  paginationContainer: {
    float: "right",
    marginBottom: "16px",
    paddingRight: "38px",
  },
  actionMenu: {
    "& .MuiPaper-root": {
      boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    },
  },
  actionMenuItem: {
    fontWeight: 600,
    fontSize: "14px",
  },
  addFeatureItemButton: {
    fontWeight: 600,
    padding: "8px",
  },
  alert: {
    width: "auto !important",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    marginLeft: "2px",
  },
}));

const userTypes = ["Client", "Consultant"];

const AddOrUpdatePlanBody = ({
  planInfo,
  otherDetails,
  isAddPlanAction,
  onAddOrUpdate,
  onCancel,
}) => {
  const classes = useStyles();

  let currentUserType = otherDetails.userType;
  currentUserType = capitalizeFirstLetter(currentUserType);
  const otherUserType =
    currentUserType === "Consultant" ? "Client" : "Consultant";
console.log("planInfo",planInfo)
  const [payPalPlansList, setPaypalPlansList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [selectedPlanId, setSelectedPlanId] = useState();
  const [billingCycles, setBillingCycles] = useState([]);
  console.log("payPalPlansList", payPalPlansList);
  const [planName, setPlanName] = useState(planInfo?.name);
  console.log("planName",planName)
  const [planPrice, setPlanPrice] = useState(planInfo?.price);
  const [planInterval, setPlanInterval] = useState(planInfo?.interval);
  const [selectedFeatureIDs, setSelectedFeatureIDs] = useState(
    !isAddPlanAction && isValidArray(planInfo?.features)
      ? planInfo.features.map((feature) => feature._id).filter(Boolean)
      : []
  );
  const [activateAddOrUpdateButton, setActivateAddOrUpdateButton] =
    useState(false);

  const otherPlanNames = useMemo(() => {
    if (isValidArray(otherDetails.planNames)) {
      let names = otherDetails.planNames.map((planName) =>
        planName.toLowerCase()
      ); // (storing in lower case to prevent same plan name irrespective of case).
      if (!isAddPlanAction)
        names = names.filter(
          (planName) => planName !== planInfo.name.toLowerCase()
        ); // filtering out current plan's name in update case.
      return names;
    }
    return [];
  }, []);

  const features = useMemo(() => {
    let features = [...otherDetails.features];
    if (!isAddPlanAction && isValidArray(selectedFeatureIDs)) {
      features.sort((feature1, feature2) => {
        const feature1ID = selectedFeatureIDs.indexOf(feature1._id);
        const feature2ID = selectedFeatureIDs.indexOf(feature2._id);

        // If both IDs are in the selected list, sort based on their order in the list
        if (feature1ID !== -1 && feature2ID !== -1) {
          return feature1ID - feature2ID;
        }

        // If only one ID is in the selected list, prioritize it
        if (feature1ID !== -1) {
          return -1;
        }

        if (feature2ID !== -1) {
          return 1;
        }

        // If neither ID is in the selected list, maintain the original order
        return 0;
      });
    } // logic to show selected features first(for update plan case).
    return features;
  }, []);

  // const handlePlanChange = (event) => {
  //   const selectedPlanId = event.target.value;
  //   const selectedPlanData = payPalPlansList.find(
  //     (plan) => plan.id === selectedPlanId
  //   );
  //   setSelectedPlan(selectedPlanData?.name);
  //   setBillingCycles(selectedPlanData?.billing_cycles);
  // };

  const handlePlanChange = (id, value) => {
    const selectedPlanData = payPalPlansList.find(
      (plan) => plan?.name === value
    );
    setPlanName(selectedPlanData?.name);
    setBillingCycles(selectedPlanData?.billing_cycles);
    setSelectedPlanId(selectedPlanData?.id);
    setPlanPrice(selectedPlanData?.billing_cycles?.[0]?.pricing_scheme?.fixed_price?.value)
    setPlanInterval(selectedPlanData?.billing_cycles?.[0]?.frequency?.interval_unit==="MONTH"? "30":"1")
    // setActivateAddOrUpdateButton(true)
    
  };
 

  useEffect(() => {
    payPalPlansListApi().then((res) => {
      setPaypalPlansList(res?.data?.plans);
    });
  }, []);

  useEffect(() => {
    const isValidPlanName =
      isValidString(planName) && !otherPlanNames.includes(planName);
    const validFeatureIDSelection = selectedFeatureIDs.length > 0;
    setActivateAddOrUpdateButton(
      isValidPlanName &&
        planPrice >= 0 &&
        // planInterval > 0 &&
        validFeatureIDSelection
    );
  }, [planName, planPrice, planInterval, selectedFeatureIDs]);

  useEffect(()=>{

    setActivateAddOrUpdateButton(false)
  },[])

  return (
    <div className="flex flex-col gap-y-[16px] ">
      <div className="flex flex-col gap-x-[8px] justify-between">
        <div className="flex gap-x-[10px] ">
          <div className="flex-1 ">
            <div>
              <CustomLabel label="user type" />
              <CustomTooltip
                title={
                  isAddPlanAction
                    ? `Switch to the ${otherUserType} tab in the parent view to create a plan for the ${otherUserType}.`
                    : "You cannot update the user type of the plan."
                }
              >
                <InfoIcon className={classes.infoIcon} />
              </CustomTooltip>
            </div>

            <CustomTextfield
              id="userType"
              data={userTypes}
              value={currentUserType}
              disabled={true}
            />
          </div>
          <div className="flex-1">
            
            <CustomSelect
              id="paypalplans"
              name="paypalplans"
              data={payPalPlansList?.map((plan) => plan?.name)}
              label="Select Plans"
              // value={planName}
              onChange={handlePlanChange}
              defaultSelection={planName??""}
            />
          </div>
        </div>

        {/* <div className="w-full gap-y-[16px]" >
          <Select value={selectedPlan} onChange={handlePlanChange}>
            {payPalPlansList?.map((plan) => (
              <MenuItem key={plan.id} value={plan.id}>
                {plan.name}
              </MenuItem>
            ))}
          </Select>

          {billingCycles.map((cycle, index) => (
            <div key={index}>
              <TextField
                label={`Interval ${index + 1}`}
                value={`${cycle.frequency.interval_count} ${cycle.frequency.interval_unit}`}
                disabled
              />
              <TextField
                label="Price"
                value={`${cycle.pricing_scheme.fixed_price.value} ${cycle.pricing_scheme.fixed_price.currency_code}`}
                disabled
              />
            </div>
          ))}
        </div> */}

        <div className="flex-1 gap-y-[20px]">
          <div className="flex gap-x-[8px] justify-between">
            {/* <CustomTextfield
              label={`Plan name `}
              value={planName}
              disabled
            /> */}
            <CustomTextfield
              label="Price"
              value={planPrice}
              disabled
            />
            <CustomTextfield
            label="Plan interval"
            value={planInterval}
            disabled/>
          </div>
        </div>
        {/* <div className="flex-1">
          <CustomTextfield
            id="planName"
            label="plan name"
            onChange={(id, value) => {
              setPlanName(value);
            }}
            customErrorMessage={
              otherPlanNames.includes(planName?.toLowerCase())
                ? "Plan name already exists."
                : ""
            }
            value={planName}
          />
        </div> */}
      </div>

      {/* <div className="flex gap-x-[8px] justify-between">
        <CustomTextfield
          id="planPrice"
          label="plan price"
          type="number"
          startAdornment={<b>$</b>}
          inputProps={{ min: 0 }}
          onChange={(id, value) => {
            setPlanPrice(value);
          }}
          // errorMessage={messages.ENTER_CITY}
          value={planPrice}
        />
        <CustomTextfield
          id="planInterval"
          label="plan interval"
          type="number"
          endAdornment={<b>days</b>}
          inputProps={{ min: 1 }}
          onChange={(id, value) => {
            setPlanInterval(value);
          }}
          // errorMessage={messages.ENTER_CITY}
          value={planInterval}
        />
      </div> */}

      {isValidArray(features) ? (
        <UnifiedFeatureTable
          type="features"
          rows={features}
          setSelectedRows={(featureIDs) => setSelectedFeatureIDs(featureIDs)}
          selectedRowIDs={selectedFeatureIDs}
        />
      ) : (
        <Alert severity="info" className="w-auto">
          No features found.Add new features from the parent view (Actions ->
          Add Feature).
        </Alert>
      )}

      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </CustomButton>
        <CustomTooltip
          title={
            !activateAddOrUpdateButton ? "Please fill the plan details." : null
          }
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.dialogActionButton}
              disabled={!activateAddOrUpdateButton}
              onClick={() => {
                let data = {
                  name: planName,
                  userType: otherDetails.userType,

                  planId:selectedPlanId,
                  price: planPrice,
                  interval: planInterval,
                  featureids: selectedFeatureIDs,
                };
                // let data2 ={
                //   userType: otherDetails?.userType,
                //   planId:selectedPlanId,
                  


                // }

                if (!isAddPlanAction) data.id = planInfo?._id;
                onAddOrUpdate(data);
              }}
            >
              {isAddPlanAction ? "Add" : "Update"}
            </CustomButton>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

const DeletePlanBody = ({ planInfo, onNoClick, onYesClick }) => {
  const classes = useStyles();
  return (
    <div>
      Are you sure you want to delete the <b>{planInfo.name}</b> plan?
      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onNoClick();
          }}
        >
          No
        </CustomButton>

        <CustomButton
          type="button1"
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onYesClick(planInfo._id);
          }}
        >
          Yes
        </CustomButton>
      </div>
    </div>
  );
};

const AddOrUpdateFeatureBody = ({
  featureInfo,
  isAddAction,
  otherDetails,
  onAddOrUpdate,
  onCancel,
}) => {
  const classes = useStyles();

  let currentUserType = otherDetails.userType;
  currentUserType = capitalizeFirstLetter(currentUserType);
  const otherUserType =
    currentUserType === "Consultant" ? "Client" : "Consultant";

  const [featureName, setFeatureName] = useState(featureInfo?.name);
  const [featureDescription, setFeatureDescription] = useState(
    featureInfo?.description
  );
  const [allFeatureItems, setAllFeatureItems] = useState([]);
  const [fITableLoading, setFITableLoading] = useState(true); // FI - Feature Item
  const [fITableError, setFITableError] = useState(false); // FI - Feature Item
  const [selectedFeatureItemIDs, setSelectedFeatureItemIDs] = useState(
    isValidArray(featureInfo?.featureitems)
      ? featureInfo.featureitems.map((item) => item._id).filter(Boolean)
      : []
  );
  const [activateAddOrUpdateButton, setActivateAddOrUpdateButton] =
    useState(false);

  const otherFeatureNames = useMemo(() => {
    if (isValidArray(otherDetails.features)) {
      let names = otherDetails.features.map((feature) =>
        feature.name.toLowerCase()
      ); // (storing in lower case to prevent same feature name irrespective of case).
      if (!isAddAction)
        names = names.filter(
          (featureName) => featureName !== featureInfo.name.toLowerCase()
        ); // filtering out current feature's name in update case.
      return names;
    }
    return [];
  }, []);

  useEffect(() => {
    getFeatureItems()
      .then((response) => {
        let allFeatureItems = response?.data;

        if (isValidArray(allFeatureItems)) {
          if (isValidArray(selectedFeatureItemIDs)) {
            allFeatureItems.sort((feature1, feature2) => {
              const feature1ID = selectedFeatureItemIDs.indexOf(feature1._id);
              const feature2ID = selectedFeatureItemIDs.indexOf(feature2._id);
              // If both IDs are in the selected list, sort based on their order in the list
              if (feature1ID !== -1 && feature2ID !== -1) {
                return feature1ID - feature2ID;
              }
              // If only one ID is in the selected list, prioritize it
              if (feature1ID !== -1) {
                return -1;
              }
              if (feature2ID !== -1) {
                return 1;
              }
              // If neither ID is in the selected list, maintain the original order
              return 0;
            });
          }
          setAllFeatureItems(allFeatureItems);
        }
        setFITableLoading(false);
      })
      .catch((response) => {
        setFITableLoading(false);
        setFITableError(true);
      });
  }, []);

  useEffect(() => {
    const isValidFeatureName =
      isValidString(featureName) && !otherFeatureNames.includes(featureName);
    const validFeatureItemsSelection = selectedFeatureItemIDs.length > 0;
    setActivateAddOrUpdateButton(
      isValidFeatureName &&
        isValidString(featureDescription) &&
        validFeatureItemsSelection
    );
  }, [featureName, featureDescription, selectedFeatureItemIDs]);

  return (
    <div className="flex flex-col gap-y-[16px] ">
      <div className="flex gap-x-[8px] justify-between">
        <div className="flex-1">
          <div>
            <CustomLabel label="user type" />
            <CustomTooltip
              title={
                isAddAction
                  ? `Switch to the ${otherUserType} tab in the parent view to add a feature for the ${otherUserType}.`
                  : "You cannot update the user type of the feature."
              }
            >
              <InfoIcon className={classes.infoIcon} />
            </CustomTooltip>
          </div>

          <CustomTextfield
            id="userType"
            data={userTypes}
            value={currentUserType}
            disabled={true}
          />
        </div>

        <div className="flex-1">
          <CustomTextfield
            id="featureName"
            label="feature name"
            onChange={(id, value) => {
              setFeatureName(value);
            }}
            customErrorMessage={
              otherFeatureNames.includes(featureName?.toLowerCase())
                ? "Feature name already exists."
                : ""
            }
            value={featureName}
          />
        </div>
      </div>

      <CustomTextfield
        id="description"
        label="description"
        onChange={(id, value) => {
          setFeatureDescription(value);
        }}
        value={featureDescription}
      />

      {fITableLoading ? (
        <div className="text-center mt-[12px]">
          <CircularProgress />
          <div className="text-[14px]">Fetching feature items..</div>
        </div>
      ) : (
        <>
          {fITableError ? (
            <Alert severity="error">
              Something went wrong while fetching feature items.Please try again
              later.
            </Alert>
          ) : (
            <>
              {isValidArray(allFeatureItems) ? (
                <UnifiedFeatureTable
                  type="featureItems"
                  rows={allFeatureItems}
                  setSelectedRows={(itemIDs) =>
                    setSelectedFeatureItemIDs(itemIDs)
                  }
                  selectedRowIDs={selectedFeatureItemIDs}
                />
              ) : (
                <Alert severity="info" className="w-auto">
                  No feature items found. Add new feature items from the parent
                  view (Actions -> View Feature Items -> Add Feature Item).
                </Alert>
              )}
            </>
          )}
        </>
      )}

      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </CustomButton>
        <CustomTooltip
          title={
            !activateAddOrUpdateButton
              ? "Please fill the feature details."
              : null
          }
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.dialogActionButton}
              disabled={!activateAddOrUpdateButton}
              onClick={() => {
                let data = {
                  name: featureName,
                  description: featureDescription,
                  featureType: otherDetails.userType,
                  featureItemids: selectedFeatureItemIDs,
                };
                if (!isAddAction) data.id = featureInfo._id;
                onAddOrUpdate(data);
              }}
            >
              {isAddAction ? "Add" : "Update"}
            </CustomButton>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

const DeleteFeatureBody = ({ featureInfo, onNoClick, onYesClick }) => {
  const classes = useStyles();
  return (
    <div>
      <div className="text-[18px] mb-[12px]">
        Are you sure you want to delete the <b>{featureInfo.name}</b> feature?
      </div>
      <b className="text-[14px]">
        Note: Deleting a feature will remove it from the plan they were added
        to.
      </b>
      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onNoClick();
          }}
        >
          No
        </CustomButton>

        <CustomButton
          type="button1"
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onYesClick(featureInfo._id);
          }}
        >
          Yes
        </CustomButton>
      </div>
    </div>
  );
};

// FI- Feature Item
const AddOrUpdateFIBody = ({
  actionEntryData,
  featureItemNames,
  isAddAction,
  onAddOrUpdate,
  onCancel,
}) => {
  const classes = useStyles();

  const [itemName, setItemName] = useState(actionEntryData?.name);
  const [itemDescription, setItemDescription] = useState(
    actionEntryData?.description
  );

  const [activateAddOrUpdateButton, setActivateAddOrUpdateButton] =
    useState(false);

  const otherFeatureItemNames = useMemo(() => {
    if (isValidArray(featureItemNames)) {
      let names = featureItemNames.map((featureItemName) =>
        featureItemName.toLowerCase()
      ); // (storing in lower case to prevent same feature name irrespective of case).
      if (!isAddAction)
        names = names.filter(
          (featureItemName) =>
            featureItemName !== actionEntryData.name.toLowerCase()
        ); // filtering out current feature item's name in update case.
      return names;
    }
    return [];
  }, []);

  useEffect(() => {
    setActivateAddOrUpdateButton(
      isValidString(itemName) && isValidString(itemDescription)
    );
  }, [itemName, itemDescription]);

  return (
    <div className="flex flex-col gap-y-[16px] ">
      <div className="flex gap-x-[8px] justify-between">
        <CustomTextfield
          id="itemName"
          label="item name"
          onChange={(id, value) => {
            setItemName(value);
          }}
          value={itemName}
          customErrorMessage={
            otherFeatureItemNames.includes(itemName?.toLowerCase())
              ? "Item name already exists."
              : ""
          }
        />
      </div>
      <CustomTextfield
        id="itemDescription"
        label="description"
        onChange={(id, value) => {
          setItemDescription(value);
        }}
        multiline={true}
        minRows={3}
        value={itemDescription}
      />
      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </CustomButton>
        <CustomTooltip
          title={
            !activateAddOrUpdateButton
              ? "Please fill the feature item details."
              : null
          }
        >
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.dialogActionButton}
              disabled={!activateAddOrUpdateButton}
              onClick={() => {
                let data = {
                  name: itemName,
                  description: itemDescription,
                };

                if (!isAddAction) data.id = actionEntryData._id;
                onAddOrUpdate(data);
              }}
            >
              {isAddAction ? "Add" : "Update"}
            </CustomButton>
          </div>
        </CustomTooltip>
      </div>
    </div>
  );
};

// FI- Feature Item
const DeleteFIBody = ({ actionEntryData, onYesClick, onNoClick }) => {
  const classes = useStyles();
  return (
    <div>
      <div className="text-[18px] mb-[12px]">
        Are you sure you want to delete the feature item{" "}
        <b>{actionEntryData?.name || ""}</b> ?
      </div>
      <b className="text-[14px]">
        Note: Deleting a feature item will remove it from the feature they were
        added to.
      </b>
      <div className="flex gap-y-[8px] justify-end mt-[16px]">
        <CustomButton
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onNoClick();
          }}
        >
          No
        </CustomButton>

        <CustomButton
          type="button1"
          customClasses={classes.dialogActionButton}
          onClick={() => {
            onYesClick(actionEntryData._id);
          }}
        >
          Yes
        </CustomButton>
      </div>
    </div>
  );
};

const FeatureItemActionDialog = ({
  actionDialogOpen,
  setActionDialogOpen,
  actionType,
  actionEntryData,
  onActionSuccess,
  featureItemNames,
}) => {
  const classes = useStyles();

  const isAdd = actionType === "add";
  const isUpdate = actionType === "update";

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);

  const onCloseDialog = () => {
    setActionDialogOpen(false);
  };

  const onAddOrUpdate = (data) => {
    const apiToCall = isAdd ? addFeatureItem : updateFeatureItem;
    setActionLoading(true);
    apiToCall(data)
      .then(() => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch(() => {
        setActionError(true);
        setActionLoading(false);
      });
  };

  const onDelete = (itemID) => {
    setActionLoading(true);
    deleteFeatureItem(itemID)
      .then(() => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch(() => {
        setActionError(true);
        setActionLoading(false);
      });
  };

  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading || actionError || actionCompleted ? (
          <>
            {actionLoading && "Action in progress"}
            {actionError && "Error"}
            {actionCompleted &&
              `Feature Item ${capitalizeFirstLetter(actionType)}${
                isAdd ? "ed" : "d"
              }`}
          </>
        ) : (
          <>{`${capitalizeFirstLetter(actionType)} Feature Item`}</>
        )}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) return <DialogLoadingBody />;
    else if (actionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: `The feature item has been ${actionType}${
              isAdd ? "ed" : "d"
            } successfully.`,
          }}
        />
      );
    else if (actionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else
      return (
        <div className={classes.dialogBody}>
          {isAdd || isUpdate ? (
            <AddOrUpdateFIBody
              actionEntryData={actionEntryData}
              isAddAction={isAdd}
              onAddOrUpdate={onAddOrUpdate}
              onCancel={onCloseDialog}
              featureItemNames={featureItemNames}
            />
          ) : (
            <DeleteFIBody
              actionEntryData={actionEntryData}
              onYesClick={onDelete}
              onNoClick={onCloseDialog}
            />
          )}
        </div>
      );
  };

  useEffect(() => {
    if (actionCompleted) onActionSuccess();
  }, [actionCompleted]);

  return (
    <CustomDialog
      open={actionDialogOpen}
      setOpen={setActionDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const ViewFeatureItemsBody = () => {
  const classes = useStyles();

  const columns = ["Item name", "Description", "Action"];
  const featureItemsPerPage = 8;

  const [featureItemsData, setFeatureItemsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  const [totalPagesCount, setTotalPagesCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  // #region action states
  const [actionMenuAnchor, setActionMenuAnchor] = useState({});
  const [actionDialogOpen, setActionDialogOpen] = useState(false);
  const [actionType, setActionType] = useState(null); // add,update,delete
  const [actionEntryData, setActionEntryData] = useState(null);
  // #endregion action states

  const fetchFeatureItems = () => {
    getFeatureItems()
      .then((response) => {
        let featureItemsReceived = response?.data;
        if (isValidArray(featureItemsReceived)) {
          const totalItemsCount = response?.count;
          const pageCount =
            totalItemsCount > 0
              ? Math.ceil(totalItemsCount / featureItemsPerPage)
              : 0;
          setFeatureItemsData(featureItemsReceived);
          setTotalPagesCount(pageCount);
        }
        setDataLoading(false);
      })
      .catch((response) => {
        setDataLoading(false);
        setDataError(true);
      });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleColumnMenuOpen = (event, data) => {
    const actionEntryData = JSON.parse(JSON.stringify(data));
    setActionMenuAnchor({ [actionEntryData.name]: event.currentTarget });
    setActionEntryData(actionEntryData);
  };
  const handleColumnMenuClose = () => {
    setActionMenuAnchor({});
  };

  const onActionMenuItemClick = (action) => {
    setActionDialogOpen(true);
    setActionType(action);
    handleColumnMenuClose();
  };

  const onActionSuccess = () => {
    setTimeout(() => fetchFeatureItems(), 1000);
  };

  useEffect(() => {
    fetchFeatureItems();
  }, []);

  useEffect(() => {
    if (!actionDialogOpen) setActionEntryData(null);
  }, [actionDialogOpen]);

  return (
    <div>
      <>
        {dataError ? (
          <Alert severity="error" className={classes.alert}>
            {messages?.GENERIC_ERROR_MESSAGE}{" "}
          </Alert>
        ) : (
          <>
            {dataLoading ? (
              <ContentLoader
                speed={2}
                width={930}
                height={800}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="20" y="0" rx="3" ry="3" width="930" height="700" />
              </ContentLoader>
            ) : (
              <>
                <div className="flex justify-end mb-[24px] ">
                  <CustomButton
                    type="button1"
                    onClick={() => {
                      onActionMenuItemClick("add");
                    }}
                    customClasses={classes.addFeatureItemButton}
                  >
                    Add Feature Item
                  </CustomButton>
                </div>
                {isValidArray(featureItemsData) ? (
                  <>
                    <TableContainer
                      sx={{
                        width: "1000px",
                        maxHeight: 440,
                        marginBottom: "24px",
                      }}
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column, index) => (
                              <TableCell
                                key={`column-${index}`}
                                // align={column.align}
                                sx={{
                                  backgroundColor: "#F7F7F7",
                                  fontWeight: 600,
                                }}
                              >
                                {column}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {featureItemsData
                            .slice(
                              currentPage * featureItemsPerPage -
                                featureItemsPerPage,
                              currentPage * featureItemsPerPage
                            )
                            .map((itemData) => (
                              <>
                                {isValidObject(itemData) ? (
                                  <TableRow sx={{ backgroundColor: "#F7F7F7" }}>
                                    <TableCell>{itemData.name || ""}</TableCell>
                                    <TableCell>
                                      {itemData.description || ""}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        id={`${itemData.name}-action-button`}
                                        onClick={(event) => {
                                          handleColumnMenuOpen(event, itemData);
                                        }}
                                        className="p-0"
                                      >
                                        <MoreVertIcon fontSize="small" />
                                      </IconButton>
                                      <Menu
                                        id={`${itemData.name}-menu`}
                                        MenuListProps={{
                                          "aria-labelledby": `${itemData.name}-menu`,
                                        }}
                                        anchorEl={
                                          actionMenuAnchor[itemData.name]
                                        }
                                        open={Boolean(
                                          actionMenuAnchor[itemData.name]
                                        )}
                                        onClose={() => {
                                          handleColumnMenuClose();
                                        }}
                                        TransitionComponent={Fade}
                                        className={classes.actionMenu}
                                      >
                                        <MenuItem
                                          className={classes.actionMenuItem}
                                          onClick={() => {
                                            onActionMenuItemClick("update");
                                          }}
                                        >
                                          Update
                                        </MenuItem>
                                        <MenuItem
                                          className={classes.actionMenuItem}
                                          onClick={() => {
                                            onActionMenuItemClick("delete");
                                          }}
                                        >
                                          Delete
                                        </MenuItem>
                                      </Menu>
                                    </TableCell>
                                  </TableRow>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      color="primary"
                      count={totalPagesCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      classes={{ ul: classes.paginationContainer }}
                    />{" "}
                  </>
                ) : (
                  <Alert severity="info" className={classes.alert}>
                    No feature item found.
                  </Alert>
                )}
              </>
            )}
          </>
        )}
      </>

      {actionDialogOpen && (
        <FeatureItemActionDialog
          actionDialogOpen={actionDialogOpen}
          setActionDialogOpen={setActionDialogOpen}
          actionType={actionType}
          actionEntryData={actionEntryData}
          featureItemNames={featureItemsData
            .map((featureItem) => featureItem.name)
            .filter(Boolean)}
          onActionSuccess={onActionSuccess}
        />
      )}
    </div>
  );
};

const ActionDialog = ({
  actionDialogOpen,
  setActionDialogOpen,
  actionType, // addPlan,viewUpdatePlan,deletePlan,addFeature,viewUpdateFeature,deleteFeature,viewFeatureItems
  actionEntryData,
  otherDetails,
  onActionSuccess,
}) => {
  const classes = useStyles();

  const isAddOrUpdatePlan =
    actionType === "addPlan" || actionType === "viewUpdatePlan";
  const isDeletePlan = actionType === "deletePlan";
  const isAddOrUpdateFeature =
    actionType === "addFeature" || actionType === "viewUpdateFeature";
  const isDeleteFeature = actionType === "deleteFeature";
  const isViewFeatureItems = actionType === "viewFeatureItems";

  const dialogTitle = {
    addPlan: "Add Plan",
    viewUpdatePlan: "View & Update Plan",
    deletePlan: "Delete Plan",
    addFeature: "Add Feature",
    viewUpdateFeature: "View & Update Feature",
    deleteFeature: "Delete Feature",
    viewFeatureItems: "View Feature Items",
  };

  const successMessages = {
    addPlan: "Your plan has been added successfully.",
    viewUpdatePlan: "Your plan has been updated successfully.",
    deletePlan: "Your plan has been deleted successfully.",
    addFeature: "Your feature has been added successfully.",
    viewUpdateFeature: "Your feature has been updated successfully.",
    deleteFeature: "Your feature has been deleted successfully.",
  };

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState(false);
  const [actionCompleted, setActionCompleted] = useState(false);

  const onCloseDialog = () => {
    setActionDialogOpen(false);
  };

  const onAddOrUpdatePlan = (data) => {
    const apiToCall = actionType === "addPlan" ? addPlan : updatePlan;

    setActionLoading(true);
    apiToCall(data)
      .then((response) => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch((response) => {
        setActionError(true);
        setActionLoading(false);
      });
  };

  const onDeletePlan = (planID) => {
    setActionLoading(true);
    deletePlan(planID)
      .then((response) => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch((response) => {
        setActionError(true);
        setActionLoading(false);
      });
  };

  const onAddOrUpdateFeature = (data) => {
    const apiToCall = actionType === "addFeature" ? addFeature : updateFeature;

    setActionLoading(true);
    apiToCall(data)
      .then((response) => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch((response) => {
        setActionError(true);
        setActionLoading(false);
      });
  };

  const onDeleteFeature = (featureID) => {
    setActionLoading(true);
    deleteFeature(featureID)
      .then((response) => {
        setActionCompleted(true);
        setActionLoading(false);
      })
      .catch((response) => {
        setActionError(true);
        setActionLoading(false);
      });
  };

  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading || actionError || actionCompleted ? (
          <>
            {actionLoading && "Action in progress"}
            {actionError && "Error"}
            {actionCompleted && `${dialogTitle[actionType]}`}
          </>
        ) : (
          <>{dialogTitle[actionType]}</>
        )}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) return <DialogLoadingBody />;
    else if (actionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title:
              successMessages[actionType] || messages.GENERIC_SUCCESS_MESSAGE,
          }}
        />
      );
    else if (actionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else
      return (
        <div className={classes.dialogBody}>
          {isAddOrUpdatePlan ? (
            <AddOrUpdatePlanBody
              planInfo={actionEntryData}
              otherDetails={otherDetails}
              isAddPlanAction={actionType === "addPlan"}
              onAddOrUpdate={onAddOrUpdatePlan}
              onCancel={onCloseDialog}
            />
          ) : (
            <></>
          )}
          {isDeletePlan ? (
            <DeletePlanBody
              planInfo={actionEntryData}
              onNoClick={onCloseDialog}
              onYesClick={onDeletePlan}
            />
          ) : (
            <></>
          )}
          {isAddOrUpdateFeature ? (
            <AddOrUpdateFeatureBody
              featureInfo={actionEntryData}
              isAddAction={actionType === "addFeature"}
              otherDetails={otherDetails}
              onAddOrUpdate={onAddOrUpdateFeature}
              onCancel={onCloseDialog}
            />
          ) : (
            <></>
          )}
          {isDeleteFeature ? (
            <DeleteFeatureBody
              featureInfo={actionEntryData}
              onNoClick={onCloseDialog}
              onYesClick={onDeleteFeature}
            />
          ) : (
            <></>
          )}
          {isViewFeatureItems ? <ViewFeatureItemsBody /> : <></>}
        </div>
      );
  };

  useEffect(() => {
    if (actionCompleted)
      onActionSuccess(
        isAddOrUpdatePlan || isDeletePlan ? "plan" : "feature",
        successMessages[actionType]
      );
  }, [actionCompleted]);

  return (
    <CustomDialog
      open={actionDialogOpen}
      setOpen={setActionDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

ActionDialog.propTypes = {};

export default ActionDialog;
