import { createSlice } from "@reduxjs/toolkit";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
  fetchRolesThunk,
  fetchSkillLevelsThunk,
  fetchSkillsThunk,
  fetchIndustryThunk,
} from "./miscThunk";

const initialState = {
  roles: [],
  skillLevels: [],
  skills: [],
  experiences: [],
  contractTypes: [],
  industryData: [],
};

export const miscSlice = createSlice({
  name: "misc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        fetchRolesThunk.fulfilled.match,
        (state, { payload: roles }) => {
          state.roles = roles;
        }
      )
      .addMatcher(
        fetchSkillLevelsThunk.fulfilled.match,
        (state, { payload: skillLevels }) => {
          state.skillLevels = skillLevels;
        }
      )
      .addMatcher(
        fetchSkillsThunk.fulfilled.match,
        (state, { payload: skills }) => {
          state.skills = skills;
        }
      )
      .addMatcher(
        fetchExperiencesThunk.fulfilled.match,
        (state, { payload: experiences }) => {
          state.experiences = experiences;
        }
      )
      .addMatcher(
        fetchContractTypesThunk.fulfilled.match,
        (state, { payload: contractTypes }) => {
          state.contractTypes = contractTypes;
        }
      )
      .addMatcher(
        fetchIndustryThunk.fulfilled.match,
        (state, { payload: industryData }) => {
          state.industryData = industryData;
        }
      );
  },
});

export const miscReducer = miscSlice.reducer;
