import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useHistory, Link } from "react-router-dom";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import CustomButton from "./CustomButton";
import { Typography, Button, Avatar } from "@mui/material";
import SearchBox from "./SearchBox";
import CustomSearch from "./filters/CustomSearch";
import CustomTooltip from "./CustomTooltip";
import CallMadeIcon from "@mui/icons-material/CallMade";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsThunk } from "app/v2/store/features/notifications/notificationsThunk";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import { candidateInfoThunk } from "app/v2/store/features/consultant/consultantProfile.thunk";

import images from "../images/common";
import { UserContext } from "../index";
import { isValidArray } from "../Pages/Utils/utilFunctions";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";
import { useLocation } from "react-router-dom";
import { getUserInfo } from "../utils/loginUtil";

import { NotificationContext } from "../index";

const useStyles = makeStyles((theme) => ({
  navbarRoot: {
    height: "56px", // 56px -> 6vh
    padding: "22px 24px", // 22px -> 2.4vh
    boxSizing: "content-box",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    cursor: "pointer",
    maxWidth: "196px",
    maxHeight: "5.1rem",
    height: "auto",
    width: "100%",
    [theme.breakpoints.down("600")]: {
      maxWidth: "7.6rem",
      maxHeight: "2.1rem",
    },
  },
  logoSearchFilterSortDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "16px",
  },
  searchDiv: {
    width: "447px",
    [theme.breakpoints.down("1300")]: {
      width: "300px",
    },
  },
  searchDivForAgency: {
    width: "351px",

    [theme.breakpoints.down("1350")]: {
      width: "280px",
    },
  },
  filterSortButton: {
    maxWidth: "131.86px",
    height: "40px",
    padding: "7.54532px 13.2043px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    dispaly: "flex",
    alignItems: "center",
    columnGap: "10px",
    whiteSpace: "nowrap",
  },
  agencyActionsDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "20px",
    textdecoration: "none",
  },
  link: {
    fontWeight: 600,
    fontSize: "16px",
    textDecoration: "none",
    color: "grey",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("1400")]: {
      whiteSpace: "normal",
    },
    [theme.breakpoints.down("1250")]: {
      fontSize: "14px",
    },
  },
  activeLink: {
    fontWeight: 600,
    fontSize: "16px",

    textDecoration: "none",
    color: "black",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("1400")]: {
      whiteSpace: "normal",
    },
    [theme.breakpoints.down("1250")]: {
      fontSize: "14px",
    },
  },
  employerOrConsultantActionsDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "12px",
  },
  feedBackDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    borderBottom: "1px solid #EDEDED",
    cursor: "pointer",
  },
  feedBackText: {
    fontSize: "12px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  feedbackIcon: {
    width: "13px",
    height: "13px",
    position: "relative",
    bottom: "1px",
    right: "1px",
  },
  hiringBoardButon: {
    width: "84px",
    height: "20px",
    padding: "10px 20px",
    boxSizing: "content-box",
    whiteSpace: "nowrap",
    color: `${theme.palette.primary.main} !important`,
    fontSize: "14px",
    fontWeight: `${600} !important`,
    backgroundColor: "#F4F8FF !important",
  },
  myJobsButton: {
    width: "82px",
    height: "20px",
    padding: "10px 20px 10px 15px",
    boxSizing: "content-box",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: `${600} !important`,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    border: "0.777413px solid #DAE5FF",
  },
  bookmarkIcon: {
    width: "15px",
    height: "15px",
    color: `${theme.palette.primary.main} !important`,
  },
  notificationProfileDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    columnGap: "12px",
  },
  notiicationButton: {
    width: "20px",
    height: "20px",
    padding: "10px",
    boxSizing: "content-box",
    border: "0.777413px solid #DAE5FF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  },
  profileDiv: {
    border: "0.777413px solid #DAE5FF",
    width: "56px",
    height: "28px",
    padding: "6px 12px",
    boxSizing: "content-box",
    cursor: "pointer",
    display: "flex",
    columnGap: "8px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  notificationCount: {
    fontSize: "5px",
    fontWeight: 600,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    position: "absolute",
    left: "21px",
    bottom: "18px",
    width: "11px",
    height: "11px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profilePicture: {
    width: "26px",
    height: "26px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    "& .MuiAvatar-img": {
      objectFit: "scale-down",
    },
  },
  adminActionsDiv: {
    display: "flex",
    columnGap: "48px",
    alignItems: "center",
  },
  adminLinksDiv: {
    display: "flex",
    columnGap: "20px",
  },
}));

const searchPlaceholders = {
  consultant: "Search jobs globally...",
  client: "Search talents globally...",
  agency: "Search for job opportunities",
};

const Navbar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [notificationDetails, setNotificationDetails] =
    useContext(NotificationContext);

  const pathName = window?.location?.pathname;
  const view = pathName.split("/")?.[1];
  const isConsultantView = view === "consultant";
  const isEmployerView = view === "client";
  const isAgencyView = view === "agency";
  const isAdminView = view === "admin";
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const hideComponent = searchParams.get("hideComponent") === "true";

  const { notifications } = useSelector((state) => state.notifications);
  const clientProfilePhoto = useSelector(
    (state) =>
      state?.clientProfileInfo?.clientInfo?.data?.clientDetail?.orgDetails
        ?.profilePhoto
  );
  const consultantProfilePhoto = useSelector(
    // (state) => state?.consultant?.value?.consultant?.displayPicture
    (state) =>
      state?.consultant?.value?.consultant?.consultantDetails?.overview
        ?.displayPicture
  );
  const agencyProfilePhoto = useSelector(
    (state) => state?.consultant?.value?.consultant?.displayPicture
  ); // update agency info state later.
  let profileInfoThunk, profilePicture;

  if (isEmployerView) {
    profileInfoThunk = getEmployerInfoThunk;
    profilePicture = clientProfilePhoto;
  } else if (isConsultantView) {
    profileInfoThunk = candidateInfoThunk;
    profilePicture = consultantProfilePhoto;
  } else {
    // profileInfoThunk = getEmployerInfoThunk;
    profilePicture = "/user_image.png";
  } // add right values for agency case later.

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const [unreadNotificationsCount, setUnReadNotificationsCount] = useState();

  const pathname = location.pathname;

  const isActive = (link) => {
    return pathname === link ? classes.activeLink : "";
  };

  const onProfileClick = () => {
    setDrawerDetails((prevState) => ({
      ...prevState,
      showDrawer: true,
      componentType: "sidemenu",
      showHeading: true,
    }));
  };
  const onNotificationClick = () => {
    setDrawerDetails((prevState) => ({
      ...prevState,
      showDrawer: true,
      componentType: "notification",
      showHeading: true,
    }));
  };

  const openFiltersView = () => {
    setDrawerDetails((prevState) => ({
      ...prevState,
      showDrawer: true,
      componentType: "filter",
      showHeading: true,
      accountType: view,
    }));
  };

  useEffect(() => {
    if (!isValidArray(notifications)) {
      const user = getUserInfo();
      if (!user) return;
      dispatch(fetchNotificationsThunk({ userID: user._id }));
      // {
      //   userID:"6557e3f09b7363581b1bc9dc"
      //   // user_email: "edward@findpros.ai"
      // }
    }

    if (!profilePicture) {
      dispatch(profileInfoThunk());
    }
  }, [dispatch,profileInfoThunk,profilePicture]);

  useEffect(() => {
    if (isEmployerView) {
      dispatch(getEmployerInfoThunk());
    } else if (isConsultantView) {
      dispatch(candidateInfoThunk());
    }
  }, [dispatch, isEmployerView, isConsultantView]);

  useEffect(() => {
    if (isValidArray(notifications)) {
      let unreadNotifications = notifications?.filter(
        (entry) => !entry?.is_read
      );
      const notificationIDsMarkedAsRead =
        notificationDetails?.readNotificationIDS; // From the notifications side drawer,When an unread notification is selected, it is marked as read and we should show the updated unread notification count here.

      if (isValidArray(notificationIDsMarkedAsRead)) {
        unreadNotifications = unreadNotifications?.filter(
          (entry) => !notificationIDsMarkedAsRead.includes(entry?.id)
        );
      }
      setUnReadNotificationsCount(unreadNotifications?.length);
    }
  }, [notifications, notificationDetails]);

  const NotificationProfileDiv = () => {
    return (
      <div className={classes.notificationProfileDiv}>
        {/* <CustomTooltip
          title={"This feature will be available in future release."}
        > */}
        <button
          className={classes.notiicationButton}
          onClick={() => {
            onNotificationClick();
          }}
          // disabled={true}
        >
          <img src="/notification-bell.svg" alt="notification-icon" />
          {unreadNotificationsCount ? (
            <span className={classes.notificationCount}>
              {unreadNotificationsCount}
            </span>
          ) : (
            <></>
          )}
        </button>
        {/* </CustomTooltip> */}
        <div className={classes.profileDiv} onClick={onProfileClick}>
          <Avatar
            className={classes.profilePicture}
            src={profilePicture ? profilePicture : "/user_placeholder.svg"}
            variant="square"
          />
          <img src="/arrow-drop-down-rounded.svg" alt="drop-down-arrow-icon" />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.navbarRoot}>
      {/* START- Logo,Search,Filter & Sort Section */}
      <div className={classes.logoSearchFilterSortDiv}>
        <div
          className={classes.logo}
          onClick={() => {
            history.push(`/${view}/dashboard`);
          }}
        >
          <img src={images.logo_v2} alt="Findpro logo" />
        </div>
        {!isAdminView && (
          <div
            className={
              isAgencyView ? classes.searchDivForAgency : classes.searchDiv
            }
          >
            <CustomSearch placeholder={searchPlaceholders[view] ?? ""} />
          </div>
        )}

        {!hideComponent ? null : (
          <CustomButton
            customClasses={classes.filterSortButton}
            onClick={() => {
              openFiltersView();
            }}
          >
            Filter & Sort
            <img
              className="w-[15px] h-[15px]"
              alt=""
              src="/materialsymbolsfilterlist.svg"
            />
          </CustomButton>
        )}
      </div>
      {/* END- Logo,Search,Filter & Sort Section */}

      {/* START- Actions specific to agency */}
      {isAgencyView && (
        <>
          <div className={classes.agencyActionsDiv}>
            <Link to="/agency/my-jobs" className={classes.link}>
              My Jobs
            </Link>
            {/* <Link to="/agency/my-proposals" className={classes.link}>
              My Proposals
            </Link> */}
            <Link to="/agency/manage-consultants" className={classes.link}>
              Manage Consultants
            </Link>
          </div>
          <NotificationProfileDiv />
        </>
      )}
      {/* END- Actions specific to agency */}

      {/* START- Actions specific to client or consultant */}
      {(isEmployerView || isConsultantView) && (
        <div className={classes.employerOrConsultantActionsDiv}>
          <div className={classes.feedBackDiv}>
            <Typography
              className={classes.feedBackText}
              onClick={() => {
                window.open("https://findpros.ai/contact-us", "_blank");
              }}
            >
              {" "}
              Give us feedback !
            </Typography>
            <CallMadeIcon className={classes.feedbackIcon} />
          </div>
          {isEmployerView ? (
            <CustomButton
              customClasses={classes.hiringBoardButon}
              variant="caption"
              onClick={() => {
                history.push("/client/job-feed");
              }}
            >
              Hiring Board
            </CustomButton>
          ) : (
            <CustomButton
              customClasses={classes.myJobsButton}
              variant="caption"
              onClick={() => {
                history.push("/consultant/my-jobs");
              }}
            >
              <BookmarkBorderIcon className={classes.bookmarkIcon} />
              My Jobs
            </CustomButton>
          )}

          <NotificationProfileDiv />
        </div>
      )}
      {/* END- Actions specific to admin*/}
      {isAdminView && (
        <>
          <div className={classes.adminActionsDiv}>
            <div className={classes.adminActionsDiv}>
              <div className={classes.adminLinksDiv}>
                <Link
                  to="/admin/manage-clients"
                  className={`${classes.link} ${isActive(
                    "/admin/manage-clients"
                  )}`}
                >
                  Manage Clients
                </Link>
                <Link
                  to="/admin/subscription-plans"
                  className={`${classes.link} ${isActive(
                    "/admin/subscription-plans"
                  )}`}
                >
                  Subscription Plans
                </Link>
                <Link
                  to="/admin/invoices"
                  className={`${classes.link} ${isActive("/admin/invoices")}`}
                >
                  Invoices
                </Link>
                <Link
                  to="/admin/active-consultants"
                  className={`${classes.link} ${isActive(
                    "/admin/active-consultants"
                  )}`}
                >
                  Active Consultants
                </Link>
              </div>
            </div>
            <NotificationProfileDiv />
          </div>
        </>
      )}
    </div>
  );
};

Navbar.propTypes = {};

export default Navbar;
