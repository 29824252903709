import React from "react";
export const IconLightGrid = ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
        width="26" height="26" viewBox="0 0 26 26" >
        <path d="M10.2862 4.22461H5.39498C4.70842 4.22461 4.15186 4.78118 4.15186 5.46773V10.359C4.15186 11.0455 4.70842 11.6021 5.39498 11.6021H10.2862C10.9728 11.6021 11.5294 11.0455 11.5294 10.359V5.46773C11.5294 4.78118 10.9728 4.22461 10.2862 4.22461Z" fill="#8C8C8C"/>
        <path d="M15.7137 4.72461H20.605C21.0154 4.72461 21.3481 5.05732 21.3481 5.46773V10.359C21.3481 10.7694 21.0154 11.1021 20.605 11.1021H15.7137C15.3033 11.1021 14.9706 10.7694 14.9706 10.359V5.46773C14.9706 5.05732 15.3033 4.72461 15.7137 4.72461Z" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.39498 14.8975H10.2862C10.6966 14.8975 11.0294 15.2302 11.0294 15.6406V20.5318C11.0294 20.9423 10.6966 21.275 10.2862 21.275H5.39498C4.98456 21.275 4.65186 20.9423 4.65186 20.5318V15.6406C4.65186 15.2302 4.98456 14.8975 5.39498 14.8975Z" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.7137 14.8975H20.605C21.0154 14.8975 21.3481 15.2302 21.3481 15.6406V20.5318C21.3481 20.9423 21.0154 21.275 20.605 21.275H15.7137C15.3033 21.275 14.9706 20.9423 14.9706 20.5318V15.6406C14.9706 15.2302 15.3033 14.8975 15.7137 14.8975Z" stroke="#8C8C8C" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </>

  );
};
