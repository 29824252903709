import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Typography, Alert } from "@mui/material";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";

import { isValidArray, isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";

import { inviteConsultantForJob } from "app/v2/services/client/consultants.service";
import CustomButton from "app/v2/components/CustomButton";

const useStyles = makeStyles((theme) => ({
  inviteDialogContainer: {
    minWidth: "891px",
    margin: "auto",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  activeJobsAlert: {
    width: "100% !important",
  },
}));
const InviteJobDialog = ({
  open,
  setOpen,
  consultantData,
  currentJob,
  activeJobs,
}) => {
  const classes = useStyles();

  const [consultantName, setConsultantName] = useState("");
  const [jobSelectorData, setJobSelectorData] = useState([]); // array of jobNoAndTile values.
  const [selectedJobNoAndTitle, setSelectedJobNoAndTitle] = useState();
  const [inviteActionLoading, setInviteActionLoading] = useState(false);
  const [inviteActionSuccess, setInviteActionSuccess] = useState(false);
  const [inviteActionError, setInviteActionError] = useState(false);
  const [inviteActionErrorMessage, setInviteActionErrorMessage] =
    useState(false);

  const getJobIDOFSelectedJob = () => {
    const selectedJobEntry = activeJobs?.find(
      (activeJobEntry) =>
        activeJobEntry?.jobNoAndTitle === selectedJobNoAndTitle
    );
    return selectedJobEntry?._id;
  };

  const onJobSelectorChange = (id, value) => {
    setSelectedJobNoAndTitle(value);
  };

  const onInviteClick = () => {
    setInviteActionLoading(true);
    inviteConsultantForJob({
      consultantId: consultantData?._id,
      jobId: currentJob?._id ? currentJob?._id : getJobIDOFSelectedJob(),
    })
      .then((response) => {
        setInviteActionSuccess(true);
        setInviteActionLoading(false);
      })
      .catch((err) => {
        setInviteActionError(true);
        setInviteActionLoading(false);
        if (err?.message) setInviteActionErrorMessage(err.message);
      });
  };

  const Title = () => {
    return (
      <Typography className={classes.title}>
        {inviteActionLoading || inviteActionError || inviteActionSuccess ? (
          <>
            {inviteActionLoading && "Action in progress"}
            {inviteActionError && "Error"}
            {inviteActionSuccess && "Invitation Sent"}
          </>
        ) : (
          <>Send Invite</>
        )}
      </Typography>
    );
  };

  const Content = () => {
    if (inviteActionLoading) return <DialogLoadingBody />;
    else if (inviteActionSuccess)
      return (
        <DialogSuccessBody
          content={{
            title: `You've successfully invited ${
              consultantName ? consultantName : "the consultant"
            } for the job.`,
          }}
        />
      );
    else if (inviteActionError)
      return (
        <DialogErrorBody
          content={{
            title: messages.GENERIC_ERROR_MESSAGE,
            desc: inviteActionErrorMessage ? inviteActionErrorMessage : "",
          }}
        />
      );
    else
      return (
        <div
          className={clsx([
            classes.inviteDialogContainer,
            "self-stretch bg-wireframe-ff-text flex flex-col p-12 items-start justify-center gap-[33px] text-left text-5xl text-neutral-900",
          ])}
        >
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
            <div className="self-stretch flex flex-row items-start justify-between">
              <div className="relative leading-[120%] font-semibold">
                Invite {consultantName} to apply
              </div>
            </div>

            <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-xs">
              {isValidObject(currentJob) ? (
                <>
                  <div className="relative leading-[120%] font-semibold">
                    JOB POST
                  </div>
                  <div className="relative text-lg leading-[130%] font-semibold text-neutral-800">
                    {currentJob?.jobNoAndTitle}
                  </div>
                </>
              ) : (
                <>
                  <CustomSelect //there was CustomAutocomplete component here ,I cahnged it to CustoSelect as the Custo austocomplete was getting blue borders
                    id={"job-posts-autocomplete"}
                    data={jobSelectorData}
                    disabled={!isValidArray(jobSelectorData)}
                    defaultSelection={selectedJobNoAndTitle}
                    label="Select job"
                    placeholder={"Select Job"}
                    onChange={onJobSelectorChange}
                    // customClasses={classes.jobSelector}
                    // disabled={disableRoleAutocomplete}
                  />
                  {!isValidArray(jobSelectorData) ? (
                    <Alert severity="info" className={classes.activeJobsAlert}>
                      You dont have any active jobs to invite the consultant.
                    </Alert>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-full">
            <CustomButton
              type="button1"
              customClasses=" py-3 px-7 w-full gap-[10px]"
              disabled={
                isValidObject(currentJob)
                  ? false
                  : !isValidArray(jobSelectorData)
              }
              onClick={() => {
                onInviteClick();
              }}
            >
              <div className="relative text-base font-semibold font-button-text-button-16 text-white_A700 text-center">
                Invite
              </div>
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconfilledsend.svg"
              />
            </CustomButton>
          </div>
        </div>
      );
  };

  useEffect(() => {
    if (!isValidObject(currentJob) && isValidArray(activeJobs)) {
      const activeJobsNoAndTitleData = activeJobs.map(
        (job) => job?.jobNoAndTitle
      ); // active jobs- job number and title string data.
      setJobSelectorData(activeJobsNoAndTitleData);
      setSelectedJobNoAndTitle(activeJobsNoAndTitleData?.[0]);
    }
  }, [activeJobs]);

  useEffect(() => {
    let consultantName = "";
    if (consultantData?.first_name)
      consultantName += consultantData?.first_name;
    if (consultantData?.last_name)
      consultantName += " " + consultantData?.last_name;
    setConsultantName(consultantName);
  }, [consultantData]);

  return (
    <div>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        title={<Title />}
        content={<Content />}
        classes={{
          paper: classes.dialog,
        }}
      />
    </div>
  );
};

InviteJobDialog.propTypes = {};

export default InviteJobDialog;
