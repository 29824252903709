import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getAccountType } from "app/common/login/login.service";
import Breadcrumb from "./breadcrumb";
import { Typography } from "@mui/material";
import { getCandidateInfo } from "app/common/candidate-info/candidate-info.service";

const TitleCardComponent = (props) => {
  const location = useLocation();
  const previousPagePath = location?.state?.path;
  const autoRenew = props?.autoRenew; // specifically for subscribe-payment component.
  const candidatePathsTitle = {
    "/candidates/dashboard": "All Jobs",
    "/candidates/myjobs/applied-job": "Applied Jobs",
    "/candidates/myjobs/job-alerts": "Job Alerts",
  }; // parent paths leading to children page with breadcrumbs(of these parent paths) and specific title to be shown.

  const vendorPathsTitle = {
    "/vendors/dashboard": "All Jobs",
    "/vendors/myjobs/applied-job": "Applied Jobs",
  }; // parent paths leading to children page with breadcrumbs(of these parent paths) and specific title to be shown.

  const getCandidateInfoBreadCrumb = () => {
    const fromDashboard = location?.state?.fromDashboard;
    const fromManageJobs = location?.state?.fromManageJobs;
    let candidateInfoBreadcrumb;
    if (fromDashboard) candidateInfoBreadcrumb = ["Candidates", "Profile"];
    else if (fromManageJobs)
      candidateInfoBreadcrumb = [
        " Manage Jobs",
        "Job Details",
        "Candidate Profile",
      ];
    return candidateInfoBreadcrumb;
  }; // under employer login.

  const pageTitles = {
    candidates: {
      "/dashboard": {
        title: "Find Your Dream Job",
        subTitle: "Get the most exciting tech jobs around the world",
      },
      "/job-detail": {
        title: "Job Details",
        breadcrumbData: [candidatePathsTitle[previousPagePath], "Job Details"], // src/app/candidate/job-detail/job-detail.component.js
      }, // via all jobs/applied jobs/job alerts
      "/myjobs/applied-job": {
        title: "Applied Jobs",
      },
      "/myjobs/job-alerts": {
        title: "Job Alerts",
      },
      "/myaccount/profile-settings": {
        title: "Profile",
      },
    },
    employers: {
      "/dashboard": {
        title: "Find Your Top Talent",
        subTitle: "Bring onboard the top talent from around the world",
      },
      "/candidate-info": {
        title: "Profile View",
        breadcrumbData: getCandidateInfoBreadCrumb(), // src/app/common/candidate-info/candidate-info.component.js
      },
      "/myjobs/manage-job": {
        title: "Manage Jobs",
      },
      "/myjobs/manage-job-detail": {
        title: "Job Details",
        breadcrumbData: ["Manage Jobs", "Job Details"], // src/app/employer/myjobs/MyJobs.js
      },
      "/invoices-info": {
        title: "Payments",
      },
      "/upgrade": {
        title: "Choose your Plan",
      },
      "/subscribe-payment": {
        title: autoRenew ? "Make Recurring Payment" : "Select Payment Mode",
        breadcrumbData: ["Choose Plan", "Payment"], // src/app/common/subscribe/subscribe-payment.component.js
      },
      "/myjobs/post-jobs": {
        title: "Post a job",
      },
      "/myaccount/company-profile": {
        title: "Company Profile",
      },
    },
    vendors: {
      "/dashboard": {
        title: "Find Your Dream Job",
        subTitle: "Get the most exciting tech jobs around the world",
      },
      "/job-detail": {
        title: "Job Details",
        breadcrumbData: [vendorPathsTitle[previousPagePath], "Job Details"], // src/app/vendor/job-details/job-details.component.js || src/app/vendor/job-details/job-bulk-candidates.js
      }, // via all jobs/applied jobs
      "/myjobs/applied-job": {
        title: "Applied Jobs",
      },
      "/manage-candidates": {
        title: "Manage Candidates",
      },
      "/myaccount/profile-settings": {
        title: "Profile",
      },
    },
    admin: {
      "/manage-candidates": {
        title: "Manage Candidates",
      },
      "/candidate-info": {
        title: "Profile View",
        breadcrumbData: ["Candidates", "Profile"],
      },
      "/manage-employers": {
        title: "Manage Employers",
      }, // employer->job detail->candidate info yet to be verified.
      "/employer-detail": {
        title: "Employer Details",
        breadcrumbData: ["Employer Profiles", "Posted Jobs"], // src/app/admin/employer/employer-detail.component.js
      },
      "/manage-vendors": {
        title: "Manage Vendors",
      },
      "/vendor-job-request": {
        title: "Vendor Details",
        breadcrumbData: ["Vendor Profiles", "Job Requests"], // src/app/admin/vendor/vendor-job-request.component.js
      },
      "/settings/subscriptions": {
        title: "Settings",
      },
    },
  };
  const accountType = getAccountType();
  const isEmployer = accountType === "employers";
  const isAdmin = accountType === "admin";
  const [pageDetails, setPageDetails] = useState({});
  const setPageInfo = () => {
    const currentPath = location?.pathname;
    const accountPaths = Object.keys(pageTitles[accountType]);
    const eciPath = "/candidate-info"; // eci- Employer candidate info.
    let path, ispathEci, isCurrentPathEci, pageInfo, iterator;
    for (iterator = 0; iterator < accountPaths?.length; iterator++) {
      path = accountPaths[iterator];
      ispathEci = path === eciPath;
      isCurrentPathEci =
        (isEmployer || isAdmin) && ispathEci && currentPath.includes(path);
      if (currentPath === path || isCurrentPathEci) {
        // extra condition isCurrentPathEci because that path has candidate id also in url and first condition wont work for it.
        pageInfo = pageTitles[accountType][path];
        break;
      }
    }
    setPageDetails(pageInfo);
  };

  const isBreadCrumbArrayValid = (breadcrumbData) => {
    return breadcrumbData?.length > 0;
  };

  useEffect(() => {
    setPageInfo();
  }, [location?.pathname]);

  return (
    <div className="card-cap">
      <div className="titleCard">
        {pageDetails?.title && <p className="title">{pageDetails?.title}</p>}
        {pageDetails?.subTitle && (
          <p className="subTitle">{pageDetails?.subTitle}</p>
        )}
        {isBreadCrumbArrayValid(pageDetails?.breadcrumbData) && (
          <Breadcrumb
            breadcrumbData={pageDetails?.breadcrumbData}
            subTitleExists={pageDetails?.subTitle}
          />
        )}

        {/* <p className="title">Find Your Dream Job</p>
        <p className="subTitle">
          Get the most exciting tech jobs around the world
        </p>
        <Breadcrumb
          breadcrumbData={["Vendor Profiles", "Job Requests"]}
          subTitleExists={pageDetails?.subTitle}
        /> */}
      </div>
    </div>
  );
};

export default TitleCardComponent;
