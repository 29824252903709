import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import {
  Typography,
  Alert,
  Chip,
  FormControl,
  FormHelperText,
  Tooltip,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import LeftRightSelect from "app/v2/components/filters/LeftRightSelect";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomCheckbox from "app/v2/components/CustomCheckbox";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomButton from "app/v2/components/CustomButton";
import SectionTitleAndIcon from "./SectionTitleAndIcon";
import AddIcon from "@mui/icons-material/Add";
import {
  isValidArray,
  capitalizeFirstLetter,
  isUndefined,
  isValidArrayIndex,
  isValidAlphaNumericID,
  isValidString,
} from "app/v2/Pages/Utils/utilFunctions";
import {
  defaultSkillList,
  messages,
  monthNames,
  DEFAULT_CONTRACT_ID,
} from "app/v2/Pages/Utils/constants";
import LocationSelector from "app/v2/components/filters/LocationSelector";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import DialogTitle from "./DialogTitle";

import { getContractTypeLevel } from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  workDetailType: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  chipRoot: {
    padding: "0px 8px",
    fontSize: "12px",
    fontWeight: 700,
    border: 0,
    backgroundColor: "#EDEDED !important",
  },
  chipLabel: {
    padding: 0,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  addButton: {
    columnGap: "10px",
    border: "1px solid #EDEDED",
    padding: "12px 28px",
    fontSize: "14px",
    fontWeight: 600,
  },
  editOrDeleteIconButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
  currentlyEmployedCBLabel: {
    fontSize: "10px",
    fontWeight: "700",
    color: "#717171",
  },
  workHistoryDialogActionButton: {
    padding: "16px 28px",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
  },
  projectBriefRoot: {
    "& .MuiInputBase-inputMultiline": {
      fontSize: "14px !important",
      color: "#717171 !important",
    },
  },
  datePicker: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  datePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
  actionButton: {
    fontSize: "14px",
    fontWeight: 600,
    padding: "12px 28px",
  },
  cancelButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
}));

const WorkHistoryCard = ({
  data,
  cardIndex,
  onActionClick,
  skillIDToNameMapper,
  contractTypes,
  industries,
}) => {
  const classes = useStyles();
  const duration = data?.duration;
  const startTime = duration?.start;
  const endTime = duration?.end;
  const isCurrentlyEmployed = duration?.isCurrentlyEmployed;
  const skills = data?.skills;
  return (
    <div className=" basis-6/12 max-w-[404px] flex flex-col gap-y-[24px] p-[24px] border-[1px] border-solid border-[#717171] box-content">
      {data?.projectName && (
        <div className="text-[18px] font-semibold">{data?.projectName}</div>
      )}
      <div>
        <Typography className={classes.workDetailType}>CLIENT</Typography>
        <div className="text-[16px] font-bold">{data?.clientName ?? "-"}</div>
      </div>
      <div>
        <Typography className={classes.workDetailType}>
          PROJECT BRIEF
        </Typography>
        <div className="text-[16px] text-[#717171] h-[44px] overflow-y-auto">
          {data?.projectBrief ?? "-"}
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>POSITION</Typography>
          <div className="text-[16px]">{data?.position ?? "-"}</div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>
            EMPLOYMENT TYPE
          </Typography>
          <div className="text-[16px]">
            {/* {contractTypes?.[data?.employmentType] ?? "-"} */}
            {getContractTypeLevel(data?.employmentType, contractTypes)}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>
            PROJECT DURATION
          </Typography>
          <div className="text-[16px]">
            {`${startTime?.month ?? ""} ${startTime?.year ?? ""}`} -{" "}
            {isCurrentlyEmployed
              ? "Present"
              : `${endTime?.month ?? "-"} ${endTime?.year ?? "-"}`}
          </div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>INDUSTRY</Typography>
          <div className="text-[16px]">{data?.industry ?? "-"}</div>
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>LOCATION</Typography>
          <div className="text-[16px]">{`${data?.country ?? ""},${
            data?.state ?? ""
          } ${data?.city ?? ""}`}</div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>TIMEZONE</Typography>
          <div className="text-[16px]">{data?.timezone ?? "-"}</div>
        </div>
      </div>
      <div>
        <Typography className={classes.workDetailType}>SKILLS</Typography>
        <div className="flex gap-x-[12px] w-full overflow-x-auto pb-[16px]">
          {skills?.map((skill) => (
            <Chip
              label={skillIDToNameMapper?.[skill?.skillID] ?? "-"}
              variant="outlined"
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
            />
          ))}
        </div>
      </div>
      <div className="flex gap-x-[24px]">
        <button
          className={classes.editOrDeleteIconButton}
          onClick={() => {
            onActionClick("edit", cardIndex);
          }}
        >
          <img src="/edit.svg" />
        </button>
        <button
          className={classes.editOrDeleteIconButton}
          onClick={() => {
            onActionClick("delete", cardIndex);
          }}
        >
          <img src="/delete.svg" />
        </button>
      </div>
    </div>
  );
};

const DialogContent = ({
  workHistoryData,
  selectedCardIndex,
  contractTypes,
  industries,
  actionType,
  onOtherInfoChange,
  onCloseActionDialog,
}) => {
  const classes = useStyles();

  const [contractLevels, setContractLevels] = useState([]);
  const [cardData, setCardData] = useState({
    projectName: "",
    clientName: "", // client or company name
    projectBrief: "",
    position: "",
    employmentType: 0,
    // employmentType:DEFAULT_CONTRACT_ID, // add this instead once the change is done from update settings api end as well (to receive this as a string).
    country: "",
    state: "",
    city: "",
    timezone: "",
    duration: {
      start: {
        month: "",
        year: "",
      },
      end: {
        month: "",
        year: "",
      },
      isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
    },
    industry: 0,
    skills: defaultSkillList,
  });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [validStartDate, setValidStartDate] = useState();
  const [validEndDate, setValidEndDate] = useState();
  const [disableAddOrEdit, setDisableAddOrEdit] = useState();

  const isEditActionSelected = () => actionType === "edit";
  const isDeleteActionSelected = () => actionType === "delete";
  const onCardDataChange = (id, value) => {
    setCardData((prevCardData) => ({ ...prevCardData, [id]: value }));
  };

  const onSkillsPickerChange = (skillsPickerID, value) => {
    onCardDataChange("skills", value);
  };

  const onCurrentlyEmployedCBChange = (id, value) => {
    const updatedDuration = {
      ...cardData?.duration,
      isCurrentlyEmployed: value,
      end: {
        month: "",
        year: "",
      }, // setting default empty values for end.
    };

    setEndDate(undefined);
    onCardDataChange("duration", updatedDuration);
  };

  const isValidDate = (dateDetails) => {
    return Boolean(dateDetails?.month) && Boolean(dateDetails?.year);
  };

  const handleDateChange = (dateType, date) => {
    const monthIndex = date?.month();
    const year = date?.year();
    const updatedDateDetails = { month: monthNames?.[monthIndex], year };
    let updatedDuration = cardData?.duration;
    updatedDuration[dateType] = updatedDateDetails;

    if (dateType === "start") {
      setStartDate(date);
      setValidStartDate(isValidDate(updatedDateDetails));
    } else {
      setEndDate(date);
      setValidEndDate(isValidDate(updatedDateDetails));
    }
    onCardDataChange("duration", updatedDuration);
  };

  const areSkillsSelected = () => {
    const skillsData = cardData?.skills;
    return isValidArray(skillsData);
  };

  const onActivateAddOrEditButton = () => {
    // no validation for employmentType,industry as we have default selection for it.
    const duration = cardData?.duration;
    const isValidProjectName = cardData?.projectName?.length > 0;
    const isValidClientName = cardData?.clientName?.length > 0;
    const isValidProjectBrief = cardData?.projectBrief?.length > 0;
    const isValidPosition = cardData?.projectName?.length > 0;
    const isValidEmploymentType =
      isValidArrayIndex(cardData?.employmentType, contractTypes) ||
      isValidAlphaNumericID(cardData?.employmentType, contractTypes); // array based index or entry's alphanumeric id.;
    const isValidIndustry = isValidString(cardData?.industry);

    const validLocationDetails =
      Boolean(cardData?.country) &&
      Boolean(cardData?.state) &&
      Boolean(cardData?.timezone) &&
      cardData?.city?.length > 0;
    const validDuration = duration?.isCurrentlyEmployed
      ? validStartDate
      : validStartDate && validEndDate && startDate <= endDate;

    const disableButton = !(
      isValidProjectName &&
      isValidClientName &&
      isValidProjectBrief &&
      areSkillsSelected() &&
      isValidPosition &&
      validLocationDetails &&
      validDuration &&
      isValidEmploymentType &&
      isValidIndustry
    );

    console.log({
      isValidProjectName,
      isValidClientName,
      isValidProjectBrief,
      skillValidation: areSkillsSelected(),
      isValidPosition,
      validLocationDetails,
      validDuration,
      disableButton,
    });

    setDisableAddOrEdit(disableButton);
  };

  const isDateInvalid = (dateType) => {
    const isStartDateType = dateType === "start";
    const dateValidityToConsider = isStartDateType
      ? validStartDate
      : validEndDate;
    return !isUndefined(dateValidityToConsider) && !dateValidityToConsider; // on initial load it'll be undefined,and we dont have to show error message immediately,only when there's a change in date and it is invalid,then we have to show the message.
  };

  const onActionButtonClick = () => {
    let updatedWorkHistoryData = workHistoryData;
    if (isDeleteActionSelected()) {
      updatedWorkHistoryData = updatedWorkHistoryData?.filter(
        (cardData, index) => index !== selectedCardIndex
      );
    } else if (isEditActionSelected()) {
      updatedWorkHistoryData[selectedCardIndex] = cardData;
    } else updatedWorkHistoryData.push(cardData);
    console.log({ selectedCardIndex, workHistoryData, updatedWorkHistoryData });
    onOtherInfoChange("workHistory", updatedWorkHistoryData);
    onCloseActionDialog();
  };

  const getArrayBasedIndex = (data, entryIDOrArrayIndex) => {
    const indexOrID = Number(entryIDOrArrayIndex);
    const isArrayIndex = indexOrID >= 0 && indexOrID < data?.length;
    if (isArrayIndex) return indexOrID;
    else {
      const arrayIndex = data?.findIndex(
        (entry) => entry?._id === entryIDOrArrayIndex
      );

      return arrayIndex !== -1 ? arrayIndex : null;
    }
  };

  const getEntryID = (data, arrayIndex) => {
    return data?.[arrayIndex]?._id;
  };

  const onContractChange = (id, value) => {
    // const contractTypeID = getEntryID(contractTypes, value); // add this instead once the change is done from api end as well(to receive this as a string).
    onCardDataChange(id, value);
  };

  useEffect(() => {
    if (isEditActionSelected()) {
      const selectedCardData = workHistoryData?.[selectedCardIndex];
      const duration = selectedCardData?.duration;
      const startDetails = duration?.start;
      const endDetails = duration?.end;

      setCardData(selectedCardData);
      setStartDate((dateState) => {
        const year = startDetails?.year;
        const monthIndex = monthNames?.indexOf(startDetails?.month);
        dateState = dayjs().year(year).month(monthIndex);
        return dateState;
      });
      setValidStartDate(isValidDate(startDetails));

      if (!duration?.isCurrentlyEmployed) {
        setEndDate((dateState) => {
          const year = endDetails?.year;
          const monthIndex = monthNames?.indexOf(endDetails?.month);
          dateState = dayjs().year(year).month(monthIndex);
          return dateState;
        });

        setValidEndDate(isValidDate(endDetails));
      }
    }
  }, []);

  useEffect(() => {
    const contractData = contractTypes?.map(
      (contractEntry) => contractEntry?.level
    );
    setContractLevels(contractData);
  }, [contractTypes]);

  useEffect(() => {
    console.log({ cardData });
    onActivateAddOrEditButton();
  }, [cardData]);

  return (
    // Setting a fixed width for now,later responsiveness will be checked
    <div className="p-[48px] w-[739px] box-content">
      {isDeleteActionSelected() ? (
        <>
          Are you sure you want to delete the selected work experience?{" "}
          <div className="flex justify-end gap-x-[16px] pt-[24px]">
            <CustomButton
              customClasses={clsx([classes.actionButton, classes.cancelButton])}
              onClick={onCloseActionDialog}
            >
              Cancel
            </CustomButton>
            <CustomButton
              type="button1"
              customClasses={classes.actionButton}
              onClick={onActionButtonClick}
            >
              Delete
            </CustomButton>
          </div>
        </>
      ) : (
        <>
          <div className="text-[24px] font-semibold mb-[33px]">
            {capitalizeFirstLetter(actionType)} Work Experience
          </div>
          <div className="px-[16px] flex flex-col gap-y-[24px]">
            <CustomTextfield
              id="projectName"
              label="project title"
              value={cardData?.projectName}
              onChange={onCardDataChange}
            />

            <CustomTextfield
              id="clientName"
              label="Agency/Company name"
              value={cardData?.clientName}
              onChange={onCardDataChange}
            />

            <div className="flex flex-col">
              <CustomTextfield
                id="projectBrief"
                label="project brief"
                value={cardData?.projectBrief}
                customClasses={{
                  root: classes.projectBriefRoot,
                }}
                onChange={onCardDataChange}
                multiline={true}
                minRows={8}
              />
            </div>
            <div>
              <CustomLabel label="skills" />
              <div className="p-[16px]">
                <SkillsPicker
                  id="consultantProfileSettingsWHSP" // WH-work history SP - Skills Picker
                  data={cardData?.skills}
                  setData={onSkillsPickerChange}
                />
              </div>
            </div>
            <CustomTextfield
              id="position"
              label="Position"
              value={cardData?.position}
              onChange={onCardDataChange}
            />
            <div className="flex gap-x-[24px]">
              <div className="flex-1">
                <CustomSelect
                  id="industry"
                  data={industries}
                  label="industry"
                  placeholder={"Select"}
                  onChange={onCardDataChange}
                  defaultSelection={cardData?.industry}
                />
              </div>
              <div>
                <CustomLabel label="employment type" />
                <LeftRightSelect
                  id="employmentType"
                  data={contractLevels}
                  selectedIndex={
                    Boolean(cardData?.employmentType) ||
                    cardData?.employmentType === 0
                      ? getArrayBasedIndex(
                          contractTypes,
                          cardData?.employmentType
                        )
                      : null
                  } // Passing null if the value does not exist to show a '-' in the component.
                  onChange={onContractChange}
                />
              </div>
            </div>
            <div>
              <CustomLabel label="LOCATION" />
              <div className="p-[16px]">
                <LocationSelector
                  defaultCountry={cardData?.country}
                  defaultState={cardData?.state}
                  defaultCity={cardData?.city}
                  defaultTimezone={cardData?.timezone}
                  onChange={onCardDataChange}
                />
              </div>
            </div>
            <div className="flex gap-x-[24px]">
              <div className="flex-1 flex flex-col">
                <CustomLabel label="start date" />
                <FormControl
                  error={isDateInvalid("start") || startDate > endDate}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month"]}
                      disableFuture={true}
                      className={
                        isDateInvalid("start") || startDate > endDate
                          ? classes.datePickerError
                          : classes.datePicker
                      }
                      value={startDate}
                      onChange={(date) => {
                        handleDateChange("start", date);
                      }}
                    />
                  </LocalizationProvider>
                  {(isDateInvalid("start") || startDate > endDate) && (
                    <FormHelperText className="ml-0">
                      {isDateInvalid("start")
                        ? "Please enter a valid date."
                        : "Start date should be on or before the end date."}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className="flex-1 flex flex-col">
                {cardData?.duration?.isCurrentlyEmployed ? (
                  <span className="relative top-[46px] text-[16px] font-semibold w-full">
                    - Present
                  </span>
                ) : (
                  <>
                    {" "}
                    <CustomLabel label="end date" />
                    <FormControl
                      error={isDateInvalid("end") || endDate < startDate}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          views={["year", "month"]}
                          disableFuture={true}
                          className={
                            isDateInvalid("end") || endDate < startDate
                              ? classes.datePickerError
                              : classes.datePicker
                          }
                          value={endDate}
                          onChange={(date) => {
                            handleDateChange("end", date);
                          }}
                        />
                      </LocalizationProvider>
                      {(isDateInvalid("end") || endDate < startDate) && (
                        <FormHelperText className="ml-0">
                          {isDateInvalid("end")
                            ? "Please enter a valid date."
                            : "End date should be on or after the start date."}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                )}
              </div>
            </div>
            <CustomCheckbox
              label="I CURRENTLY WORK HERE"
              id="isCurrentlyEmployed"
              checked={cardData?.duration?.isCurrentlyEmployed}
              onChange={onCurrentlyEmployedCBChange}
              customClasses={{ label: classes.currentlyEmployedCBLabel }}
              size="small"
            />

            <Tooltip
              arrow
              title={disableAddOrEdit && "Please enter all the details."}
            >
              <div className="w-full">
                <CustomButton
                  type="button1"
                  customClasses={classes.workHistoryDialogActionButton}
                  disabled={disableAddOrEdit}
                  onClick={onActionButtonClick}
                >
                  {capitalizeFirstLetter(actionType)}
                </CustomButton>
              </div>
            </Tooltip>
          </div>
        </>
      )}
    </div>
  );
};

const WorkHistory = ({
  data,
  industries,
  contractTypes,
  onOtherInfoChange,
  skillIDToNameMapper,
}) => {
  const classes = useStyles();

  const [actionType, setActionType] = useState(""); // add,edit, or delete.
  const [selectedCardIndex, setSelectedCardIndex] = useState();
  const [actionDialogOpen, setActionDialogOpen] = useState(false);

  const onCloseActionDialog = () => {
    setActionDialogOpen(false);
  };

  const onActionClick = (actionType, cardIndex) => {
    console.log({ actionType, cardIndex });
    setActionType(actionType);
    setActionDialogOpen(true);
    setSelectedCardIndex(cardIndex);
  };

  return (
    <>
      <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
        <div className="flex justify-between items-start mb-[12px]">
          <SectionTitleAndIcon
            title="Work History & Industry *"
            iconPath="/dashboard.svg"
          />
          <CustomButton
            customClasses={classes.addButton}
            onClick={() => {
              onActionClick("add");
            }}
          >
            <AddIcon fontSize="small" />
            <div>Add Work History</div>
          </CustomButton>
        </div>

        <div className="max-h-[956px] overflow-y-auto pb-[16px]">
          {isValidArray(data) ? (
            <div className="flex flex-wrap gap-[24px] ">
              {data.map((card, index) => (
                <WorkHistoryCard
                  data={card}
                  cardIndex={index}
                  onActionClick={onActionClick}
                  skillIDToNameMapper={skillIDToNameMapper}
                  contractTypes={contractTypes}
                  industries={industries}
                />
              ))}
            </div>
          ) : (
            <Alert severity="warning">
              Please add atleast one work experience.{" "}
            </Alert>
          )}
        </div>
      </div>
      <CustomDialog
        open={actionDialogOpen}
        setOpen={setActionDialogOpen}
        title={<DialogTitle title="Work Experience" />}
        content={
          <DialogContent
            workHistoryData={data}
            selectedCardIndex={selectedCardIndex}
            contractTypes={contractTypes}
            industries={industries}
            actionType={actionType}
            onOtherInfoChange={onOtherInfoChange}
            onCloseActionDialog={onCloseActionDialog}
          />
        }
      />
    </>
  );
};

WorkHistory.propTypes = {};

export default WorkHistory;
