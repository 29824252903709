import React, { useEffect, useState } from "react";
import NotificationCard from "./NotificationCard";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsThunk } from "app/v2/store/features/notifications/notificationsThunk";
import ContentLoader from "react-content-loader";
import { getUserInfo } from "app/v2/utils/loginUtil";

const useStyles = makeStyles(() => ({
  notificationContainer: { padding: "0 24px", overflowY: "auto" },
  line: {
    margin: 0,
  },
}));
const Notification = ({ setDrawerDetails }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { notifications } = useSelector((state) => state.notifications);

  useEffect(() => {
    const user = getUserInfo();
    if (!user) return;
    dispatch(fetchNotificationsThunk({ userID: user._id }));
    // {
    //   userID:"6557e3f09b7363581b1bc9dc"
    //   // user_email: "edward@findpros.ai"
    // }
  }, [dispatch]);

  const isLastCard = (index) => index === notifications?.length - 1;

  const onCloseDrawer = () => {
    setDrawerDetails({
      showDrawer: false,
      componentType: "",
    });
  };

  return (
    <div className={classes.notificationContainer}>
      {/* {notifications?.map((entry, index) => (
        <>
          <NotificationCard data={entry} />
          {!isLastCard() && <hr className={classes.line} />}
        </>
      ))} */}

      {loading ? (
        // Display a loading indicator while fetching notifications
        <ContentLoader
          speed={2}
          width={910}
          height={800}
          viewBox="0 0 910 800"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="0" rx="3" ry="3" width="910" height="110" />
          <rect x="0" y="115" rx="3" ry="3" width="910" height="110" />
          <rect x="0" y="230" rx="3" ry="3" width="910" height="110" />
          <rect x="0" y="345" rx="3" ry="3" width="910" height="110" />
          <rect x="0" y="460" rx="3" ry="3" width="910" height="110" />
          <rect x="0" y="575" rx="3" ry="3" width="910" height="110" />
        </ContentLoader>
      ) : notifications ? (
        notifications.map((entry, index) => (
          <div key={index}>
            <NotificationCard data={entry} onCloseDrawer={onCloseDrawer} />
            {!isLastCard(index) && <hr className={classes.line} />}
          </div>
        ))
      ) : (
        <p style={{ textAlign: "center", padding: "10px" }}>
          There is no notification
        </p>
      )}
    </div>
  );
};

Notification.propTypes = {};

export default Notification;
