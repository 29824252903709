import { getConsultantQuestionnaire, hasValidConsultantData } from 'app/v2/utils/onboardingUtils';
import React from 'react';
import { Redirect } from 'react-router-dom';

const withConsultantDataCheck = (Component) => {
    return (props) => {
        if (hasValidConsultantData()) {
            return <Component {...props} />;
        }

        return <Redirect to="/client/onboarding" />;
    };
};

export default withConsultantDataCheck;