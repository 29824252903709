import React, { useState, useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import ForgotPassword from "../Pages/common/forgotpassword/ForgotPassword";
import ResetPassword from "../Pages/common/resetpassword/ResetPassword";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Signup from "../Pages/common/signup/Signup";
import Login from "../Pages/common/login/Login";
import Questionnaire from "../Pages/Agency/onboarding/Questionnaire";
import DashboardPage from "../Pages/Agency/dashboard/Dashboard.page";
import JobFeed from "../Pages/Agency/searchresultsandfeed/JobFeed";
import ManageConsultant from "../Pages/Agency/ManageConsultants/ManageConsultant";
import AccountActivation from "../Pages/common/AccountActivation";
import SignupEmailVerified from "../Pages/common/SignupEmailVerified";
import Settings from "../Pages/Agency/settings";
import PrivateRoute from "./PrivateRoute";
import JobDetail from "../Pages/Agency/details/job";
import ConsultantDetail from "../Pages/Agency/details/consultant";
import MyJobs from "../Pages/Agency/MyJobs";
import Connections from "../Pages/Agency/Connections/Connections";
import ActiveWork from "../Pages/Agency/ActiveWork";

const useStyles = makeStyles((theme) => ({
  bannerAndNavbar: {
    position: "fixed",
    top: 0,
    maxWidth: "1440px", // setting max-width again since the element has fixed position and does not inherit it from parent.
    width: "100%",
    zIndex: 999,
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  bodyWithF: {
    minHeight: "calc(100vh - 421px)", // total viewport height minus navbar(143) and footer's(278) heights.
  }, // F-Footer
  bodyWithBNF: {
    minHeight: "calc(100vh - 278px)", // total viewport height minus footer's heights.
    paddingTop: "134px", // fixed banner and navbar's height
  }, // BNF-Banner,Navbar,Footer
  bodyWithNF: {
    minHeight: "calc(100vh - 278px)", // total viewport height minus footer's heights.
    paddingTop: "100px", // fixed navbar's height
  }, // NF- Navbar,Footer
}));

const withBannerNavbarFooter = (WrappedComponent) => {
  const ComponentWithBNF = (props) => {
    const classes = useStyles();
    const [showBanner, setShowBanner] = useState(false); // Setting default as false -> Not showing banner for MVP.

    const onBannerClose = () => {
      setShowBanner(false);
    };

    return (
      <>
        <div className={classes.bannerAndNavbar}>
          <Banner showBanner={showBanner} onClose={onBannerClose} />
          <Navbar />
        </div>
        <div
          className={clsx([
            classes.body,
            showBanner ? classes.bodyWithBNF : classes.bodyWithNF,
          ])}
        >
          <WrappedComponent {...props} />
        </div>

        <Footer />
      </>
    );
  };

  return ComponentWithBNF;
};

const withFooter = (WrappedComponent) => {
  const ComponentWithFooter = (props) => {
    const classes = useStyles();
    return (
      <>
        <div className={clsx([classes.body, classes.bodyWithF])}>
          <WrappedComponent {...props} />
        </div>
        <Footer />
      </>
    );
  };

  return ComponentWithFooter;
};

const AgencyRoute = () => {
  const ConsultantDetailView = withBannerNavbarFooter(ConsultantDetail);
  const JobDetailView = withBannerNavbarFooter(JobDetail);
  return (
    <Switch>
      <Route path="/agency/sign-up" component={withFooter(Signup)} />
      <Route path={"/agency/login"} component={withFooter(Login)} />
      <Route
        path="/agency/forgot-password"
        component={withFooter(ForgotPassword)}
      />
      <Route
        path="/agency/reset-password/:token"
        component={withFooter(ResetPassword)}
      />
      <Route
        path="/agency/account-activation"
        component={withFooter(AccountActivation)}
      />
      <Route
        path="/agency/verify-email/:token"
        component={SignupEmailVerified}
      />
      <Route path={"/agency/onboarding"} component={Questionnaire} />
      <PrivateRoute
        path={"/agency/dashboard"}
        component={withBannerNavbarFooter(DashboardPage)}
      />
      <PrivateRoute
        path={"/agency/job-feed"}
        component={withBannerNavbarFooter(JobFeed)}
      />
      <PrivateRoute
        path={"/agency/job-detail"}
        render={(props) => <JobDetailView />}
      />
      <PrivateRoute
        path={"/agency/consultant-detail"}
        render={(props) => <ConsultantDetailView props={props} />}
      />
      <PrivateRoute //consultant detail by consultantID - Job post should be hidden in this case.
        path={"/agency/consultant/:consultant_id"}
        render={(props) => <ConsultantDetailView props={props} />}
      />
      <PrivateRoute //consultant detail by jobID and consultantID - Job post should be shown based on job id in this case.
        path={"/agency/:jobID/consultant/:consultant_id"}
        render={(props) => <ConsultantDetailView props={props} />}
      />

      <PrivateRoute
        path={"/agency/settings"}
        component={withBannerNavbarFooter(Settings)}
      />
      <PrivateRoute
        path={"/agency/manage-consultants"}
        component={withBannerNavbarFooter(ManageConsultant)}
      />
      <PrivateRoute
        path={"/agency/my-jobs"}
        component={withBannerNavbarFooter(MyJobs)}
      />
      <PrivateRoute
        path={"/agency/connections"}
        component={withBannerNavbarFooter(Connections)}
      />
      <PrivateRoute
        path={"/agency/active-work"}
        component={withBannerNavbarFooter(ActiveWork)}
      />
    </Switch>
  );
};

export default AgencyRoute;
