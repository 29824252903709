import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Select, InputLabel, MenuItem, FormControl, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import ConsultantTile from "./ConsultantTile";

const useStyles = makeStyles((theme) => ({
  box: {
    // textAlign: "center",
  },
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
      border: "1px solid #EDEDED",
    },
  },
  selectRoot: {
    height: "50px",
    fontSize: "16px",
  },
  selectLabel: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.secondary.light,
  },
  menuItem: {
    fontSize: "16px",
  },
}));

const ConsultantSelect = (props) => {
  const classes = useStyles();
  let {
    consultantCompIndex, // consultant component index
    selectedValue,
    data,
    disabled,
    placeholder,
    onChange,
  } = props;
  placeholder = placeholder ?? "Select";

  const handleSelectChange = (event) => {
    onChange(consultantCompIndex, event.target.value, selectedValue); //current value,previous value (here value refers to consultant id).
  };

  return (
    <>
      <Box className={classes.box}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel
            id={`${consultantCompIndex}-select-label`}
            className={classes.selectLabel}
          >
            {placeholder}
          </InputLabel>
          <Select
            labelId={`${consultantCompIndex}-select-label`}
            id={`${consultantCompIndex}-select`}
            value={selectedValue}
            label={placeholder}
            onChange={handleSelectChange}
            className={classes.selectRoot}
            disabled={disabled}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
            }}
          >
            {data?.map((menuItem, idx) => (
              <MenuItem
                key={idx}
                value={menuItem?.id}
                className={classes.menuItem}
              >
                <ConsultantTile
                  displayPic={menuItem?.displayPic}
                  name={menuItem?.name}
                  email={menuItem?.email}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

ConsultantSelect.propTypes = {};

export default ConsultantSelect;
