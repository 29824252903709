import React, { useState } from "react";
import { Alert } from "@mui/material";
import { getAccountType } from "../login/login.service";
import { incompleteProfileMessages } from "../utils/constants";

const IncompleteProfileAlertBox = () => {
  const [open, setOpen] = useState(true);
  const accountType = getAccountType();
  const messageToShow = incompleteProfileMessages[accountType];
  return (
    <>
      {open && messageToShow && (
        <Alert
          severity="warning"
          id="profileIncompleteAlert"
          onClose={() => {
            setOpen(false);
          }}
        >
          {messageToShow}
        </Alert>
      )}
    </>
  );
};

export default IncompleteProfileAlertBox;
