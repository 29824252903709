import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Link, Grid, CircularProgress, Alert } from "@mui/material";
import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import { Line } from "../../../common";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import {
  JobHeader,
  JobOverview,
  TextSection,
  SectionTitleIcon,
} from "./components";
import SubmitProposal from "../../common/Proposal/SubmitProposal";
import WithdrawProposal from "../../common/Proposal/WithdrawProposal";
import ProposalOverview from "../../common/Proposal/ProposalOverview";
import CustomTooltip from "app/v2/components/CustomTooltip";
import SocialShare from "app/v2/Pages/common/SocialShare";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import { IconLightGrid } from "app/v2/Pages/SVGs/IconLightGrid";
import { IconLightAward } from "app/v2/Pages/SVGs/IconLightAward";
import img_icon_light_arrow_top from "../../../images/img_icon_light_arrow_top.svg";

import { messages } from "app/v2/Pages/Utils/constants";
import { scrollToTop } from "app/v2/Pages/Utils/utilFunctions";
import { JobDetailObj } from "./JobDetail.dummy";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import configs from "app/v2/Pages/Utils/configs";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "36px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
  saveAndShareButton: {
    padding: "10px 15px 10px 20px",
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
    display: "flex",
    columnGap: "10px",
  },
  applyNowButton: {
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
    width: "100%",
  },
}));

const leftNavData = [
  {
    icon: <IconLightUser className="w-[29px] h-[29px]" />,
    title: "Job Overview",
    component: ({ jobData, experiencesData, contractTypesData }) => (
      <JobOverview
        data={jobData}
        experiencesData={experiencesData}
        contractTypesData={contractTypesData}
      />
    ),
  },
  {
    icon: <IconLightFile className="w-[29px] h-[29px]" />,
    title: "Job Description",
    component: ({ jobData }) => <TextSection data={jobData?.jobDescription} />,
  },
  {
    icon: <IconLightGrid className="w-[29px] h-[29px]" />,
    title: "Qualifications",
    component: ({ jobData }) => (
      <SkillsPicker
        id="agencyJobDetailSP" // SP- Skills Picker
        data={jobData?.skills}
        readOnly={true}
      />
    ),
  },
  {
    icon: <IconLightAward className="w-[29px] h-[29px]" />,
    title: "Responsibilities",
    component: ({ jobData }) => (
      <TextSection data={jobData?.jobResponsibilities} />
    ),
  },
  {
    icon: <VerifiedUserOutlinedIcon className="w-[29px] h-[29px]" />,
    title: "About Company",
    component: ({ jobData }) => <TextSection data={jobData?.aboutCompany} />,
    withGrayBg: true,
  },
];

const JobDetail = ({ props }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // const jobData = history?.location?.state?.jobData ?? {};
  const jobData = JobDetailObj;

  const homeUrl = configs?.homeUrl;
  const publicJobDetailURL = jobData?._id
    ? homeUrl + `job-detail/${jobData?._id}`
    : null;
  const { experiences, contractTypes } = useSelector((state) => state.misc);

  const [submitProposalDialogOpen, setSubmitProposalDialogOpen] =
    useState(false);
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );

  ///////// scroll
  const sectionRefs = useRef([]);
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  const onNavItemClick = (navItemIndex) => {
    // sectionRefs.current[navItemIndex].scrollIntoView({ behavior: 'smooth' });

    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  return (
    <>
      <Grid container>
        <Grid itemv className={classes.leftView}>
          <div className={classes.backDiv}>
            <ArrowBackIcon
              onClick={() => {}}
              className={classes.backIcon}
              onClick={() => {
                history.goBack();
              }}
            />
            <span>Back</span>
          </div>
          <div className="flex flex-col gap-y-[24px] mb-[36px]">
            <CustomButton
              type="button1"
              customClasses={classes.applyNowButton}
              onClick={() => {
                setSubmitProposalDialogOpen(true);
              }}
            >
              Submit Proposal <img src="/iconlightup-right.svg" />
            </CustomButton>
            <div className="flex gap-x-[20px]">
              <div className="flex-1">
                <CustomButton customClasses={classes.saveAndShareButton}>
                  Save <img src="/icondarkbookmark.svg" />
                </CustomButton>
              </div>
              <CustomTooltip
                arrow
                title={<SocialShare shareLink={publicJobDetailURL} />}
                bg="white"
              >
                <div className="flex-1">
                  <CustomButton customClasses={classes.saveAndShareButton}>
                    Share <img src="/share.svg" />
                  </CustomButton>
                </div>
              </CustomTooltip>
            </div>
          </div>
          <hr />
          <div className={classes.navItemsDiv}>
            {leftNavData?.map((navItemDetails, index) => (
              <Link
                key={"l" + index}
                className={clsx([
                  classes.navItemLinkDiv,
                  selectedNavItemIndex === `nav-item-component-${index}`
                    ? classes.selectedNavItem
                    : classes.unselectedNavItem,
                ])}
                onClick={() => {
                  onNavItemClick(index);
                }}
              >
                {navItemDetails?.icon}

                <Typography className={classes.navItemTitle}>
                  {navItemDetails?.title}
                </Typography>
              </Link>
            ))}
          </div>
        </Grid>
        <Grid item id="sectionContent" className={classes.rightView}>
          <JobHeader data={jobData} />
          {leftNavData?.map((navItemDetails, index) => (
            <>
              <div
                ref={(el) => (sectionRefs.current[index] = el)}
                className={`${
                  navItemDetails?.withGrayBg
                    ? "bg-[#F7F7F7] p-[36px]"
                    : "px-[24px]"
                }`}
                id={`nav-item-component-${index}`}
                key={`nav-item-component-${index}`}
              >
                <SectionTitleIcon
                  title={navItemDetails?.title}
                  icon={navItemDetails?.icon}
                />
                {navItemDetails?.component({
                  jobData,
                  experiencesData: experiences,
                  contractTypesData: contractTypes,
                })}
              </div>
              {!isLastNavItemComponent(index) && (
                <Line className="bg-gray-300 h-px w-full my-[36px]" />
              )}
            </>
          ))}
          <div
            className="flex items-center gap-x-[10px] pt-[36px] cursor-pointer"
            onClick={() => {
              scrollToTop();
            }}
          >
            <img src={img_icon_light_arrow_top} />
            <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
              Back to top
            </div>
          </div>
        </Grid>
      </Grid>
      <CustomDialog
        open={submitProposalDialogOpen}
        setOpen={setSubmitProposalDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            Submit Proposal
          </div>
        }
        content={
          <SubmitProposal
            jobData={jobData}
            closeParentDialog={() => setSubmitProposalDialogOpen(false)}
          />
        }
      />

      {/* <CustomDialog
        open={submitProposalDialogOpen}
        setOpen={setSubmitProposalDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            Proposal Overview
          </div>
        }
        content={
          <ProposalOverview
            jobData={jobData}
            // proposalsData={proposalsData}
          />
        }
      /> */}

      {/* <CustomDialog
        open={submitProposalDialogOpen}
        setOpen={setSubmitProposalDialogOpen}
        title={
          <div className="text-[16px] font-semibold text-center">
            Withdraw Proposal
          </div>
        }
        content={
          <WithdrawProposal
            closeParentDialog={() => setSubmitProposalDialogOpen(false)}
          />
        }
      /> */}
    </>
  );
};

JobDetail.propTypes = {};

export default JobDetail;
