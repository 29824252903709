import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import TabbedCardView from "../common/TabbedCardView";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import CardCollection from "../../common/CardCollection";
import ConsultantGridCard from "../common/Cards/Grid/Consultant";
import ConsultantListCard from "../common/Cards/List/Consultant";

const useStyles = makeStyles((theme) => ({}));

const CompactCardContainer = ({ title, view = "consultant" }) => {
  const classes = useStyles();
  const joborTeamPostsExists = false; // add condition to show the specific job posts drop down.
  const isConsultantView = view === "consultant";
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const gridCardsData = new Array(6).fill(ConsultantGridCard);
  const listCardsData = new Array(6).fill(ConsultantListCard);
  const tabsData = [
    {
      label: "Grid view",
      component: (
        <CardCollection cardsData={gridCardsData} viewType="grid" view={view} />
      ),
    },
    {
      label: "List view",
      component: (
        <CardCollection cardsData={listCardsData} viewType="list" view={view} />
      ),
    },
  ];
  const dummyJobPostsData = [
    "Job 27: Frontend Developer for Contractual Project",
    "Job 28: Backend Developer for Contractual Project",
    "Job 29: Backend Developer for Full time Project",
  ]; // will get this at global level context or redux store.
  const dummyTeamPostsData = [
    "Team 4: Costco App Development Project",
    "Team 5: Costco App Development Project",
    "Team 6: Costco App Development Project",
  ]; // will get this at global level context or redux store.
  const autocompeleteData = isConsultantView
    ? dummyJobPostsData
    : dummyTeamPostsData;
  return (
    <div>
      <Typography>
        {!joborTeamPostsExists && "Featured"} {title}{" "}
      </Typography>
      {joborTeamPostsExists && (
        <div>
          <CustomAutocomplete
            id={"job-posts-autocomplete"}
            data={autocompeleteData}
            defaultSelection={autocompeleteData[0]}
            // placeholder={"Enter Role"}
            // onChange={onRoleChange}
            // customClasses={classes.autoCompleteRoot}
            // disabled={disableRoleAutocomplete}
          />
          <div>
            <Typography>2,231 consultants match this job post</Typography>
            <Link>Manage {isConsultantView ? "Job" : "Team"}</Link>
          </div>
        </div>
      )}
      <TabbedCardView
        tabsData={tabsData}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
        viewAllOnClick={() => {}}
      />
    </div>
  );
};

CompactCardContainer.propTypes = {};

export default CompactCardContainer;
