import React from "react";
export const IconLightAward = ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 
          fill={fillColor}
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          {...props}
          width="26" height="26" viewBox="0 0 26 26">
          <path d="M15.1667 15.6855C16.9867 14.2988 19.7167 12.1755 19.7167 4.46212V3.85547H6.23999V4.46212C6.23999 12.2188 8.96999 14.2988 10.79 15.6855C11.83 16.5088 12.35 16.9421 12.35 17.8955V19.7588C11.3533 19.8455 10.4 20.2355 10.0533 20.9721H8.66666C7.97332 20.9721 7.45332 21.5355 7.45332 22.1855H18.46C18.46 21.4921 17.8967 20.9721 17.2467 20.9721H15.86C15.5133 20.2355 14.56 19.8888 13.5633 19.7588V17.8955C13.6067 16.8988 14.1267 16.4655 15.1667 15.6855ZM13 16.0321C12.61 15.5121 12.09 15.1221 11.57 14.7321C9.92332 13.4321 7.62666 11.6988 7.49666 5.0688H18.5033C18.3733 11.6988 16.12 13.4321 14.43 14.7321C13.91 15.1221 13.39 15.5121 13 16.0321Z" fill="#8C8C8C"/>
          <path d="M9.22998 14.2117C9.14331 14.2117 2.59998 12.825 2.59998 5.67501V5.06836H6.88998V6.28169H3.85664C4.20331 11.915 9.40331 12.9984 9.44664 13.0417L9.22998 14.2117Z" fill="#8C8C8C"/>
          <path d="M16.77 14.2121L16.51 12.9987C16.7267 12.9554 21.7533 11.8721 22.1 6.23875H19.0667V5.02539H23.4V5.63207C23.4 12.8254 16.8567 14.2121 16.77 14.2121Z" fill="#8C8C8C"/>
      </svg>          
    </>

  );
};
