import React from "react";
import ReactDOM from "react-dom";
import { Provider, useSelector } from "react-redux";
import { Route, BrowserRouter as Router } from "react-router-dom";
import store from "./app/store";
import App from "./app";
import { SnackbarProvider } from "notistack";
import * as serviceWorker from "./serviceWorker";
import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";
import "./css/skin/skin-1.css";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";
import {
  Backdrop,
  CircularProgress,
  createTheme,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { useLocation } from "react-router-dom";

const Routes = () => {
  const isActive = useSelector(
    (state) => !!state.loader.isLoadingRequestIds.length
  );

  const theme = createTheme({
    palette: {
      secondary: {
        light: "#d6eafb",
        main: "#7FB2F0",
        dark: "#4E7AC7",
        contrastText: "#fff",
      },
      primary: {
        light: "#4E7AC7",
        main: "#35478C",
        dark: "#16193B",
        contrastText: "#fff",
      },
    },
    components: {
      MuiPaper: {
        defaultProps: {
          elevation: 3,
        },
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiAccordion: {
        defaultProps: {
          elevation: 0,
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0,0,0,0.2)",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isActive}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <RouterComp />
    </ThemeProvider>
  );
};

const RouterComp = React.memo(() => (
  <Router>
    <div>
      <Route path="/" component={App} />
      {/* <Route path="/404" exact component={() => <div>Not Found</div>} />
          <Redirect to="/404" /> */}
    </div>
  </Router>
));

ReactDOM.render(
  <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "right" }}>
    <Provider store={store}>
      <Routes />
    </Provider>
  </SnackbarProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
