import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

import { Chip } from "@mui/material";

import ProposalStatus from "app/v2/Pages/common/Status/Proposal";
import JobStatus from "app/v2/Pages/common/Status/Job";
import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomTooltip from "app/v2/components/CustomTooltip";
import SocialShare from "app/v2/Pages/common/SocialShare";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import configs from "app/v2/Pages/Utils/configs";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px",
    border: "1px solid #ABABAB",
  },
  skillsChipsDiv: {
    display: "flex",
    maxWidth: "245px",
    overflowX: "auto",
    padding: "8px 8px 8px 0",
  },
  chip: {
    padding: "6px 8px",
    border: "1px solid #EDEDED",
    borderRadius: "6px !important",
    fontSize: "12px",
    fontWeight: 700,
    color: theme.palette.secondary.light, // light grey
    marginRight: "8px",
    textTransform: "uppercase",
  },
  jobDetailsButton: {
    padding: "10px 15px 10px 20px",
    height: "20px",
    boxSizing: "content-box",
    fontSize: "14px",
    fontWeight: 600,
    columnGap: "10px",
  },
  viewProposalsButton: {
    padding: "10px 15px 10px 20px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #363636",
  },
  bookmarkShareIcon: {
    border: "1px solid #EDEDED",
    padding: "10px",
    width: "20px",
    height: "20px",
    boxSizing: "content-box",
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  teamMemberImage: {
    width: "48px",
    height: "48px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const DetailSection = ({ type, value, center = false }) => {
  console.log({ type, value });
  value = value === "" ? "-" : value;
  return (
    <div className={`flex-1 ${center ? "text-center" : ""}`}>
      <div className="uppercase text-[12px] font-semibold text-[#717171]">
        {type}
      </div>
      <div className="text-[18px] font-bold">{value ?? "-"}</div>
    </div>
  );
};

const List = ({ data }) => {
  // data={    image: "/company_image.png",
  // jobTitle: "Software Developer 1",
  // jobNo:"FP23147",
  // companyName: "ABC Company",
  // createdAt: "2023-04-06T05:17:11.955Z",
  // updatedAt: "2023-04-06T05:17:11.955Z",
  // teamCount:4,
  // experienceLevel: "Junior",
  // contractType: "Part Time",
  // workType: "Hybrid",
  // salaryRange: "$30k - $50k/yr",
  // matchRate:"90%",
  // location: "Singapore City, Singapore",
  // timeZone: "SGST (GMT + 8.0)",
  // skills: ["React", "NodeJS", "AngularJS"],
  // jobStatus: "active",
  // proposalStatus:"invited"
  // };
  const classes = useStyles();
  const history = useHistory();

  const homeUrl = configs?.homeUrl;
  const publicJobDetailURL = data?.jobID
    ? homeUrl + `job-detail/${data?.jobID}`
    : null;

  let createdAt = data?.createdAt ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  let updatedAt = data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;

  return (
    <>
      <div className={classes.root}>
        {/* Section 1-start */}
        <div className="mb-[24px] flex gap-x-[19px] items-start">
          <img
            className={classes.orgImage}
            alt={`org_${data?._id}_image`}
            src={
              data?.companyImage ? data.companyImage : "/org_placeholder.svg"
            }
          />
          <div className="flex-1 flex flex-col gap-x-[6px]">
            <div className="text-[24px] font-semibold">
              Frontend Javascript Developer Needed
            </div>
            <div className="flex gap-x-[12px] text-[14px] text-[#717171]">
              <span>Posted {createdAt}</span>
              <span>Last Updated {updatedAt}</span>
              <span className="font-semibold">JOB NO. {data?.jobNo}</span>
            </div>
          </div>

          <ProposalStatus type={data?.proposalStatus} />
          <JobStatus type={data?.jobStatus} />
        </div>
        {/* Section 1-end */}
        {/* Section 2- start */}
        <div className="flex mb-[36px]">
          <DetailSection type="team count" value={data?.teamCount} />
          <DetailSection
            type="experience level"
            value={data?.experienceLevel}
          />
          <DetailSection type="contract type" value={data?.contractType} />
          <DetailSection type="work type" value={data?.workType} />
          <DetailSection type="salary range" value={data?.salaryRange} />
        </div>
        {/* Section 2-end */}
        {/* Section 3-start */}
        <div className="flex mb-[36px]">
          <DetailSection type="match rate" value={data?.matchRate} />
          <DetailSection type="location" value={data?.location} />
          <DetailSection type="timezone" value={data?.timezone} />
          <DetailSection
            type="technology skills"
            value={
              isValidArray(data?.skills) ? (
                <div className={classes.skillsChipsDiv}>
                  {data.skills.map((skill) => (
                    <Chip
                      label={skill}
                      variant="outlined"
                      className={classes.chip}
                    />
                  ))}
                </div>
              ) : (
                "-"
              )
            }
          />
        </div>
        {/* Section 3-end */}
        <div className="flex items-center gap-x-[15px]">
          <CustomButton
            type="button1"
            customClasses={classes.jobDetailsButton}
            onClick={() => {
              history.push("/agency/job-detail");
            }}
          >
            View Job Details
            <img
              className="w-[15px] h-[15px]"
              alt=""
              src="/iconlightup-right.svg"
            />
          </CustomButton>
          <CustomTooltip
            arrow
            title={<SocialShare shareLink={publicJobDetailURL} />}
            bg="white"
          >
            <button className={classes.bookmarkShareIcon}>
              <img src={"/share.svg"} alt="share-icon" />
            </button>
          </CustomTooltip>
          <span className="text-[18px] font-semibold">Team Members</span>
          <div>
            {isValidArray(data?.teamMembersProfilePics) ? (
              <div className="flex gap-x-[8px]">
                {data.teamMembersProfilePics.map((pic, index) => (
                  <img
                    src={pic ? pic : "/user_placeholder.svg"}
                    alt={`"user_${index}_image"`}
                    className={classes.teamMemberImage}
                  />
                ))}
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </>
  );
};

List.propTypes = {};

export default List;
