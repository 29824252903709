import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import {
  getEmployerInfo,
  // updateEmployerInfo,
  updateEmployeeInfo,
  updateClientOrganizationInfo,
  updateEmployerItAssessmentTokens,
  getEmployeeDetail,
} from "../../../../services/client/profileSettings.service";

// First, create the thunk
export const getEmployerInfoThunk = createAsyncThunk(
  "clientInfo",
  async (request, thunkAPI) => {
    return getEmployerInfo();
  }
);
export const getEmployeeDetailThunk = createAsyncThunk(
  "EmployeeDetail",
  async (request, thunkAPI) => {
    return getEmployeeDetail(request).then((res)=>res);
  }
);


export const updateProfilePictureThunk = createAsyncThunk(
  "profile-settings/profile-picture/put",
  async (request, thunkAPI) => {
    return updateEmployeeInfo(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

// export const updateEmployerInfoThunk = createAsyncThunk(
export const updateEmployeeInfoThunk = createAsyncThunk(
  "profile-settings/put",
  async (request, thunkAPI) => {
    return updateEmployeeInfo(request).then((res) => {
      // thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

export const updateClientOrganizationInfoThunk = createAsyncThunk(
  "client-organization/put",
  async (request, thunkAPI) => {
    return updateClientOrganizationInfo(request).then((res) => {
      return res;
    });
  }
);

export const updateEmployerItAssessmentThunk = createAsyncThunk(
  "it-assessment/put",
  async (request, thunkAPI) => {
    return updateEmployerItAssessmentTokens(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);
