import React from "react";
export const IconLightFile = ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 
          fill={fillColor}
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          {...props}
          width="26" height="26" viewBox="0 0 26 26" >
          <path d="M19.55 10.4004V10.3504H19.5H14.35V5.20039V5.15039H14.3H6.49995H6.44995V5.20039V20.8004V20.8504H6.49995H19.5H19.55V20.8004V10.4004ZM18.9618 9.15039H19.0825L18.9971 9.06504L15.6353 5.70324L15.55 5.61788V5.73859V9.10039V9.15039H15.6H18.9618ZM5.84995 3.95039H15.5792L20.75 9.1211V21.4504C20.75 21.6095 20.6867 21.7621 20.5742 21.8747C20.4617 21.9872 20.3091 22.0504 20.15 22.0504H5.84995C5.69082 22.0504 5.53821 21.9872 5.42569 21.8747C5.31317 21.7621 5.24995 21.6095 5.24995 21.4504V4.55039C5.24995 4.39126 5.31317 4.23865 5.42569 4.12613C5.53821 4.0136 5.69082 3.95039 5.84995 3.95039ZM9.14995 13.0504H16.85V14.2504H9.14995V13.0504ZM9.14995 9.15039H12.3V10.3504H9.14995V9.15039ZM9.14995 16.9504H16.85V18.1504H9.14995V16.9504Z" fill="#8C8C8C" stroke="#8C8C8C" strokeWidth="0.1"/>
      </svg>
    </>

  );
};
