import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Link, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import SubscriptionBody from "./subscription/SubscriptionBody";
import Documents from "./Documents";
import HelpSupport from "./HelpSupport";
import OrganizationDetails from "./OrganizationDetails";
import PersonalDetails from "./PersonalDetails";
import EmployeesListCard from "./EmployeesListCard";
import Integrations from "./Integrations";

import Title from "../../common/Title";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  titleDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 101,
  },
  settingsContainer: {
    paddingTop: "102px", // titleDiv's total hieght is 102.
  },
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "32px",
    margin: "16px 0",
  },
  navItemLink: {
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.primary.main} !important`,
  },
  rightArrowIcon: {
    width: "21px",
    height: "21px",
    float: "right",
  },
  subMenuDiv: {
    display: "flex",
    flexDirection: "column",
    margin: "16px 12px 0",
    rowGap: "12px",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightViewWithFixedTitle: {
    padding: "0 48px 48px",
  },
}));

const Settings = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const location = useLocation();
  const classes = useStyles();
  const leftNavData = [
    {
      title: "Subscription",
      component: <SubscriptionBody />,
    },
    {
      title: "DocuCenter",
      component: <Documents />,
    },
    {
      title: "Profile Settings",
      subMenu: [
        {
          title: "Organization Details ",
          component: <OrganizationDetails />,
        },
        {
          title: "Personal Details",
          component: <PersonalDetails />,
        },
      ],
    },

    {
      title: "Employees",
      component: <EmployeesListCard />,
    },
    {
      title: "Integrations",
      component: <Integrations />,
    },
    {
      title: "Help & Support",
      component: <HelpSupport />,
    },
  ];
  const componentIndicesWithFixedTitle = [2]; // indices as per above leftNavData. 2-> Profile settings

  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(0);
  const [selectedPSMenuIndex, setSelectedPSMenuIndex] = useState(0); // PS - Profile Setting.

  useEffect(() => {
    const locationState = location?.state;
    const parentSettingIndex = locationState?.parentSettingIndex;
    const childSettingIndex = locationState?.childSettingIndex;
    if (parentSettingIndex) setSelectedNavItemIndex(parentSettingIndex);
    if (childSettingIndex) setSelectedPSMenuIndex(childSettingIndex); // currently child settings is only available for profle settings.
  }, []);

  const isNavItemProfileSetting = (index) => {
    return index === 2;
  };
  const isProfileSettingSelected = () => {
    return selectedNavItemIndex === 2;
  };

  const onNavItemClick = (navItemIndex) => {
    setSelectedNavItemIndex(navItemIndex);
    if (isProfileSettingSelected()) setSelectedPSMenuIndex(0); // setting to first item in menu.
  };

  const isCurrentSelectedNavItem = (navItemIndex) => {
    return selectedNavItemIndex === navItemIndex;
  };

  const onPSItemClick = (navItemIndex) => {
    setSelectedNavItemIndex(2); // setting to profile setting nav item.
    setSelectedPSMenuIndex(navItemIndex);
  };

  const isCurrentSelectedPSItem = (navItemIndex) => {
    return selectedPSMenuIndex === navItemIndex;
  };

  const SelectedNavItemComponent = () => {
    return <>{leftNavData?.[selectedNavItemIndex]?.component}</>;
  };

  const PSSelectedItemComponent = () => {
    const subMenuDetails = leftNavData?.[selectedNavItemIndex]?.subMenu;
    const selectedItemComponent =
      subMenuDetails?.[selectedPSMenuIndex]?.component;
    return selectedItemComponent;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedNavItemIndex]);

  return (
    <>
      <div className={classes.titleDiv}>
        <Title
          title={leftNavData?.[selectedNavItemIndex]?.title ?? "Settings"}
        />
      </div>

      <Grid container className={classes.settingsContainer}>
        <Grid item className={classes.leftView}>
          <div className={classes.navItemsDiv}>
            {leftNavData?.map((navItemDetails, navItemIndex) => (
              <div>
                <Link
                  className={clsx([
                    classes.navItemLink,
                    isCurrentSelectedNavItem(navItemIndex) &&
                      classes.selectedNavItem,
                  ])}
                  onClick={() => {
                    onNavItemClick(navItemIndex);
                  }}
                >
                  <Typography className={classes.navItemTitle}>
                    {navItemDetails?.title}{" "}
                    {isCurrentSelectedNavItem(navItemIndex) && (
                      <ArrowForwardIosIcon className={classes.rightArrowIcon} />
                    )}
                  </Typography>
                </Link>
                {isNavItemProfileSetting(navItemIndex) && (
                  <div className={classes.subMenuDiv}>
                    {navItemDetails?.subMenu?.map(
                      (subMenuItem, subMenuIndex) => (
                        <Link
                          className={clsx([
                            classes.navItemLink,
                            isProfileSettingSelected() &&
                              isCurrentSelectedPSItem(subMenuIndex) &&
                              classes.selectedNavItem,
                          ])}
                          onClick={() => {
                            onPSItemClick(subMenuIndex);
                          }}
                        >
                          <Typography className={classes.navItemTitle}>
                            {subMenuItem?.title}
                          </Typography>
                        </Link>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Grid>

        <Grid
          item
          className={clsx([
            classes.rightView,
            componentIndicesWithFixedTitle.includes(selectedNavItemIndex) &&
              classes.rightViewWithFixedTitle,
          ])}
        >
          {isProfileSettingSelected() ? (
            <PSSelectedItemComponent />
          ) : (
            <SelectedNavItemComponent />
          )}
        </Grid>
      </Grid>
    </>
  );
};

Settings.propTypes = {};

export default Settings;
