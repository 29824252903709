import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";

import {
  Alert,
  Button,
  Typography,
  OutlinedInput,
  FormControl,
  Tooltip,
} from "@mui/material";
import QRCode from "qrcode.react";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

import CustomLabel from "app/v2/components/filters/CustomLabel";

import { isValidArray, isValidString } from "app/v2/Pages/Utils/utilFunctions";
import { messages, URL_PATTERN } from "app/v2/Pages/Utils/constants";
import configs from "../../Utils/configs";

const useStyles = makeStyles((theme) => ({
  qrCodeDiv: {
    padding: "16px",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "240px",
    boxSizing: "border-box",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  linkRoot: { border: "1px solid #EDEDED", borderRadius: "2px" },
  linkFieldset: {
    border: "0",
  },
  linkTextField: {
    // width: "216px",
    boxSizing: "content-box",
    padding: "6px 12px",
    fontSize: "12px",
    textOverflow: "ellipsis",
  },
  copyButton: {
    width: "30px",
    height: "13px",
    padding: "5px",
    boxSizing: "content-box",
    backgroundColor: "#EDEDED !important",
    color: theme.palette.common.black,
    fontSize: "10px",
    fontWeight: 600,
  },
  socialIcon: {
    minWidth: "24px",
    width: "24px !important",
    height: "24px !important",
    cursor: "pointer",
  },
}));

const DetailText = ({ type, value }) => {
  return (
    <div className="flex-1">
      <div className="text-[12px] font-semibold text-[#717171] uppercase">
        {type ?? ""}
      </div>
      <div className="text-[16px]">{value ?? "-"}</div>
    </div>
  );
};

export const Overview = ({ data, clientID }) => {
  const classes = useStyles();

  const location = data?.companyLocation?.[0] ?? {};
  const shareLink = clientID
    ? configs.homeUrl + "client-detail/" + clientID
    : "";
  const socialLinks = data?.socialLinks;
  let linkedinURL, facebookURL, websiteURL, instagramURL, twitterURL;
  if (socialLinks?.linkedIn) {
    linkedinURL = URL_PATTERN.test(socialLinks?.linkedIn)
      ? socialLinks?.linkedIn
      : "https://" + socialLinks?.linkedIn;
  }
  if (socialLinks?.facebook) {
    facebookURL = URL_PATTERN.test(socialLinks?.facebook)
      ? socialLinks?.facebook
      : "https://" + socialLinks?.facebook;
  }
  if (socialLinks?.website) {
    websiteURL = URL_PATTERN.test(socialLinks?.website)
      ? socialLinks?.website
      : "https://" + socialLinks?.website;
  }
  if (socialLinks?.instagram) {
    instagramURL = URL_PATTERN.test(socialLinks?.instagram)
      ? socialLinks?.instagram
      : "https://" + socialLinks?.instagram;
  }
  if (socialLinks?.twitter) {
    twitterURL = URL_PATTERN.test(socialLinks?.twitter)
      ? socialLinks?.twitter
      : "https://" + socialLinks?.twitter;
  }

  const [showTooltip, setShowTooltip] = useState(false);

  const onCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      setShowTooltip(true);

      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    } catch (err) {
      console.error("Failed to copy to clipboard: ", err);
    }
  };

  return (
    <div className="flex flex-col gap-y-[24px]">
      <div className="flex gap-x-[24px] align-center">
        <img
          className={classes.orgImage}
          src={data?.profilePhoto ? data?.profilePhoto : "/org_placeholder.svg"}
        />
        <div className="flex flex-col gap-y-[8px]">
          <div className="text-[32px] font-semibold">
            {data?.company ?? "-"}
          </div>
          <div className="flex gap-x-[12px] items-center mb-[16px]">
            <div className="text-[12px] font-semibold text-[#717171]">
              SOCIAL PROOF
            </div>
            {linkedinURL ||
            facebookURL ||
            websiteURL ||
            instagramURL ||
            twitterURL ? (
              <div className="flex gap-x-[12px]">
                {linkedinURL && (
                  <a href={linkedinURL ?? ""} target="_blank">
                    <img
                      src="/linkedin-logo-black.png"
                      className={classes.socialIcon}
                    />
                  </a>
                )}
                {facebookURL && (
                  <a href={facebookURL} target="_blank">
                    <img
                      src="/facebook-logo-black.svg"
                      className={classes.socialIcon}
                    />
                  </a>
                )}
                {websiteURL && (
                  <a href={websiteURL} target="_blank">
                    <TravelExploreIcon className={classes.socialIcon} />
                  </a>
                )}
                {instagramURL && (
                  <a href={instagramURL} target="_blank">
                    <InstagramIcon className={classes.socialIcon} />
                  </a>
                )}
                {twitterURL && (
                  <a href={twitterURL} target="_blank">
                    <TwitterIcon className={classes.socialIcon} />
                  </a>
                )}
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[16px]">
        <DetailText type="INDUSTRY" value={data?.industry} />
        <DetailText
          type="LOCATION"
          value={`${location?.country ?? "-"}, ${location?.state ?? "-"}, ${
            location?.city ?? "-"
          }`}
        />
        <DetailText type="TIMEZONE" value={location?.timezone} />
      </div>

      <hr />
      <div className={classes.qrCodeDiv}>
        <div className="text-[16px] font-medium mb-[12px]">QR SHARE</div>
        {shareLink ? (
          <QRCode value={shareLink} className="h-[89px] w-[89px]" />
        ) : (
          "-"
        )}
      </div>

      <FormControl variant="outlined" className="w-[240px]">
        <OutlinedInput
          type="text"
          startAdornment={<img src="/link.svg" />}
          endAdornment={
            <Tooltip title="Copied!" placement="top" arrow open={showTooltip}>
              <Button className={classes.copyButton} onClick={onCopyClick}>
                COPY
              </Button>
            </Tooltip>
          }
          value={shareLink}
          disabled={true}
          classes={{
            notchedOutline: classes.linkFieldset,
            root: classes.linkRoot,
            input: classes.linkTextField,
          }}
        />
      </FormControl>
    </div>
  );
};

export const TextSection = ({ title, data }) => {
  return (
    <div className="p-[36px] bg-[#F7F7F7]">
      <div className="text-[24px] font-semibold">{title}</div>
      <div className="text-[16px] text-[#717171]">
        {isValidString(data) ? (
          <iframe srcDoc={data} className="w-full"></iframe>
        ) : (
          "-"
        )}
      </div>
    </div>
  );
};

export const AboutCompany = ({ profileData, cultureData }) => {
  return (
    <>
      <div className="text-[24px] font-semibold">About Company</div>
      <div className="p-[36px] bg-[#F7F7F7]">
        <div className="text-[16px] text-[#717171]">
          {isValidString(profileData) || isValidString(cultureData) ? (
            <>
              {isValidString(profileData) ? (
                <>
                  <CustomLabel label="company profile" />
                  <iframe
                    srcDoc={profileData}
                    className="w-full"
                    title="company profile"
                  ></iframe>
                  <br />
                </>
              ) : (
                <></>
              )}
              {isValidString(cultureData) ? (
                <>
                  <CustomLabel label="company culture" />
                  <iframe
                    srcDoc={cultureData}
                    className="w-full"
                    title="company culture"
                  ></iframe>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
    </>
  );
};

const LocationTile = ({ data, index }) => {
  return (
    <div>
      <div className="text-[16px] font-semibold mb-[16px]">
        Location {index}
      </div>

      <div className="flex flex-col gap-y-[8px] pl-[24px] border-l-[1px] border-solid border-l-[#717171]">
        {data?.country && (
          <div className="text-[16px] font-bold ">{data.country}</div>
        )}
        <div className="flex gap-x-[8px]">
          {data?.state && (
            <div className="text-[14px] font-semibold uppercase text-[#717171]">
              {data.state}
            </div>
          )}
        </div>
        {data?.city && (
          <div className="text-[12px] text-[#717171]">{data?.city}</div>
        )}

        {data?.timezone && (
          <div className="text-[14px] text-[#717171]">{data?.timezone}</div>
        )}
      </div>
    </div>
  );
};

export const Locations = ({ data }) => {
  console.log({ data });
  return (
    <div>
      <div className="text-[24px] font-semibold mb-[24px]">Locations</div>
      {isValidArray(data) ? (
        <div>
          {data?.map((locationEntry, index) => (
            <LocationTile data={locationEntry} index={index + 1} />
          ))}
        </div>
      ) : (
        <>-</>
      )}
    </div>
  );
};
