import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  switchTrackOn: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
  },
  switchTrackOff: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  switchThumbOn: {
    backgroundColor: `${theme.palette.common.black} !important`,
  },
  switchThumbOff: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  formControlLabelRoot: {
    margin: 0,
  },
  switchLabel: {
    fontSize: "14px",
    fontWeight: "700",
  },
}));

const CustomSwitch = (props) => {
  const classes = useStyles();
  const { id, onChange, label, defaultSelection, disabled } = props;
  const [checked, setChecked] = React.useState(defaultSelection);
  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
    onChange(id, event.target.checked);
  };
  React.useEffect(() => {
    setChecked(defaultSelection);
  }, [defaultSelection]);
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleSwitchChange}
          classes={{
            track: checked ? classes.switchTrackOn : classes.switchTrackOff,
            thumb: checked ? classes.switchThumbOn : classes.switchThumbOff,
          }}
        />
      }
      label={label}
      classes={{
        root: classes.formControlLabelRoot,
        label: classes.switchLabel,
      }}
      disabled={disabled}
    />
  );
};

CustomSwitch.propTypes = {};

export default CustomSwitch;
