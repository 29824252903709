import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";

const useStyles = makeStyles((theme) => ({
  clienteleRoot: {
    margin: "129px 0 249px 0",
    [theme.breakpoints.down("600")]: {
      margin: "48px 0",
    },
  },
  clientImage: {
    objectFit: "none",
  },
  clientGroup1: {
    marginBottom: "136px",
    [theme.breakpoints.down("600")]: {
      marginBottom: "46px",
    },
  },
}));
const Clientele = (props) => {
  const classes = useStyles();
  const clientGroup1 = images.clientele[0];
  const clientGroup2 = images.clientele[1];
  const mdDown = useMediaQuery("(max-width:1000px)");
  const lgDown = useMediaQuery("(max-width:1500px)");

  const ImageGroupCarousel = ({ imageGroup, groupNumber }) => {
    return (
      <Swiper
        slidesPerView={mdDown || lgDown ? 5 : imageGroup.length}
        spaceBetween={20}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          reverseDirection: groupNumber % 2 === 0,
        }}
        speed={500}
        modules={[Autoplay]}
      >
        {imageGroup?.map((image, index) => (
          <SwiperSlide>
            <img src={image} alt={`client-image-${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };
  return (
    <div className={classes.clienteleRoot}>
      <div className={classes.clientGroup1}>
        <ImageGroupCarousel imageGroup={clientGroup1} groupNumber={1} />
      </div>
      <ImageGroupCarousel imageGroup={clientGroup2} groupNumber={2} />
    </div>
  );
};

Clientele.propTypes = {};

export default Clientele;
