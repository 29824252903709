import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import FileViewer from "react-file-viewer";
import { makeStyles } from "@mui/styles";

import { Alert, CircularProgress } from "@mui/material";

import { fileExtensionMimeType, messages } from "../Utils/constants";
import {
  getFileExtensionFromFileUrlorName,
  isValidString,
  isValidObject,
  getFileNameFromPublicUrlFile,
} from "../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  fileViewerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#363636",
    width: "100%",
    height: "357px",
    padding: "12px 4px 12px 0",
    boxSizing: "border-box",

    "& canvas": {
      margin: "auto",
      width: "80%",
      height: "100%",
    },
    "& .pg-viewer-wrapper": {
      "&::-webkit-scrollbar-track": {
        background: "#363636",
      },
      scrollbarColor: "#363636 !important",
    },
  },
  textDiv: {
    backgroundColor: theme.palette.common.white,
    width: "80%",
    margin: "auto",
    height: "100%",
    "&::-webkit-scrollbar-track": {
      background: "#363636",
    },
  },
  alert: {
    width: "100% !important",
  },
}));

/**
 * @description This component is useful to preview a public file url or a locally uploaded file(doc,docx,txt,pdf) for which we pass the uploadedFile object. Its used at places where there's resume upload.
 * @param {object || string} fileUrlOrContent string for url, object for uploaded file - structure {file,localFileUrl,prevFileUrl}. // file is used for doc,docx preview; localFileUrl is used for pdf preview; prevFileUrl (prev public url) is not used in the component herebut its needed in the parent component.
 */
const FilePreview = ({ fileUrlOrContent }) => {
  const classes = useStyles();

  const [uploadedFilePreviewContent, setUploadedFilePreviewContent] =
    useState(null);
  const [loadingUploadedFile, setLoadingUploadedFile] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState();

  /**
   * Using helper functions below and not storing in states as when upload is attempted many times, faced issues.
   * Eg: Uploaded a pdf file first->Renders in an iframe perfectly.
   * Then on uploading a doc file, the isUploadPDf state is still true initialy,so the doc file is getting downloaded initially( default browser behaviour for a doc file in an iframe->downloads it).
   *  */

  const isPublicUrl = () => isValidString(fileUrlOrContent);
  const isUploadedFile = () => isValidObject(fileUrlOrContent);
  const getUploadedFileMimeType = () => fileUrlOrContent?.file?.type;

  const isUploadedFileDOCOrDOCX = () => {
    const uploadedFileMimeType = getUploadedFileMimeType();
    return (
      uploadedFileMimeType === fileExtensionMimeType.doc ||
      uploadedFileMimeType === fileExtensionMimeType.docx
    );
  };
  const isUploadedFilePDF = () =>
    getUploadedFileMimeType() === fileExtensionMimeType.pdf;
  const isUploadedFileTXT = () =>
    getUploadedFileMimeType() === fileExtensionMimeType.txt;
  const isUploadedFilePPTOrPPTX = () => {
    const uploadedFileMimeType = getUploadedFileMimeType();
    return (
      uploadedFileMimeType === fileExtensionMimeType.ppt ||
      uploadedFileMimeType === fileExtensionMimeType.pptx
    );
  };

  const downloadFile = () => {
    // Create an anchor element
    const anchor = document.createElement("a");

    // Set the download attribute to specify the file name
    anchor.download = uploadedFileName;

    // Set the href attribute to the file URL
    anchor.href = fileUrlOrContent?.localFileUrl ?? fileUrlOrContent;

    // Trigger a click event on the anchor element
    anchor.click();
  };

  useEffect(() => {
    const uploadedFileMimeType = fileUrlOrContent?.file?.type;
    const isUploadedFileDOCOrDOCX =
      uploadedFileMimeType === fileExtensionMimeType.doc ||
      uploadedFileMimeType === fileExtensionMimeType.docx;
    const isUploadedFileTXT =
      uploadedFileMimeType === fileExtensionMimeType.txt;
    const fileName = isUploadedFile()
      ? fileUrlOrContent?.file?.name
      : getFileNameFromPublicUrlFile(fileUrlOrContent);

    setUploadedFileName(fileName);

    if (isUploadedFileDOCOrDOCX || isUploadedFileTXT) {
      setLoadingUploadedFile(true);
      const reader = new FileReader();
      reader.onload = () => {
        setUploadedFilePreviewContent(reader?.result);
        setLoadingUploadedFile(false);
      };
      if (isUploadedFileDOCOrDOCX) reader.readAsDataURL(fileUrlOrContent?.file);
      else reader.readAsText(fileUrlOrContent?.file); // txt case
    }
  }, [fileUrlOrContent]);

  useEffect(() => {
    // console.log({
    //   fileUrlOrContent,
    //   pdf: isUploadedFilePDF,
    //   docOrDocx: isUploadedFileDOCOrDOCX,
    //   txt: isUploadedFileTXT,
    //   ppt: isUploadedFilePPTOrPPTX,
    //   uploadedFilePreviewContent,
    //   isPublicUrl: isPublicUrl,
    //   isUploadedFile: isUploadedFile,
    // });
  }, [fileUrlOrContent, uploadedFilePreviewContent]);

  return (
    <>
      {isPublicUrl() || isUploadedFile() ? (
        <>
          {(isPublicUrl() || isUploadedFilePDF() || isUploadedFileTXT()) && (
            <div className={classes.fileViewerContainer}>
              {/* Only public url and uploaded pdf's localFileUrl (blob url) can be previewed in an iframe without any issue. */}
              {isPublicUrl() && (
                <iframe
                  src={`https://docs.google.com/viewer?url=${fileUrlOrContent}&embedded=true`}
                  allowFullScreen
                  width="100%"
                  height="100%"
                  key={uploadedFileName}
                  title={uploadedFileName}
                ></iframe>
              )}

              {isUploadedFilePDF() && (
                <iframe
                  src={fileUrlOrContent?.localFileUrl}
                  allowFullScreen
                  width="100%"
                  height="100%"
                  key={uploadedFileName}
                  title={uploadedFileName}
                ></iframe>
              )}

              {isUploadedFileTXT() && (
                <>
                  {loadingUploadedFile ? (
                    <CircularProgress />
                  ) : (
                    <pre className={classes.textDiv}>
                      {uploadedFilePreviewContent}
                    </pre>
                  )}
                </>
              )}
            </div>
          )}

          {/* Kept this out separately as we couldn't find a reliable way to preview doc,docx,ppt,pptx so showing the download option for now */}
          {(isUploadedFileDOCOrDOCX() || isUploadedFilePPTOrPPTX()) && (
            // <FileViewer
            //   key={uploadedFileName}
            //   fileType={getFileExtensionFromFileUrlorName(
            //     uploadedFileName
            //   )}
            //   filePath={uploadedFilePreviewContent}
            //   // errorComponent={
            //   //   <Alert severity="info">
            //   //     {messages.GENERIC_ERROR_MESSAGE}
            //   //   </Alert>
            //   // }
            //   // onError={() => {
            //   //   console.log("file viewer erros");
            //   // }}
            // />
            <>
              <Alert severity="info" className={classes.alert}>
                Unable to preview the document at the moment. Please click the
                link below to download and view it.
              </Alert>
            </>
          )}

          <button
            onClick={() => {
              downloadFile();
            }}
            className="flex gap-x-[4px] items-center justify-center font-semibold mt-[8px] text-[#1c81ff]"
          >
            {uploadedFileName} <img src="/download.svg" />
          </button>
        </>
      ) : (
        <Alert severity="info">{messages.NO_PREVIEW_AVAILABLE}</Alert>
      )}
    </>
  );
};

FilePreview.propTypes = {};

export default FilePreview;
