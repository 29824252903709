import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getPlans = async (userType) => {
  // userType- client or consultant
  return fetchWrapper(
    `${configs.APIUrl}/admin/subscriptionPlan?userType=${userType}`
  );
};

export const payPalPlansListApi = async () => {
  // userType- client or consultant
  return fetchWrapper(
    `${configs.APIUrl}/subscription/getPlan?page_size=10&page=1&total_required=true`
  );
};

export const addPlan = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/subscriptionPlan`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     "name" : "free3",
    //     "userType" : "client", // client, consultant, agency
    //     "planId" :"", // paypal plan id
    //     "price" : 0, // Subscription amount
    //     "interval" : 30,  // in days
    //     "featureids": [
    //         "65ce7cb53cb8da09cbfc223d",
    //         "65ce7cd93cb8da09cbfc223f"
    //     ]
    // }
  });
};

export const updatePlan = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/subscriptionPlan`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     id:"",
    //     "name" : "free3",
    //     "userType" : "client", // client, consultant, agency
    //     "planId" :"", // paypal plan id
    //     "price" : 0, // Subscription amount
    //     "interval" : 30,  // in days
    //     "featureids": [
    //         "65ce7cb53cb8da09cbfc223d",
    //         "65ce7cd93cb8da09cbfc223f"
    //     ]
    // }
  });
};

export const deletePlan = async (planID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/subscriptionPlan/${planID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getFeatures = async (userType) => {
  // userType - client or consultant
  return fetchWrapper(
    `${configs.APIUrl}/admin/getFeature?featureType=${userType}`
  );
};

export const addFeature = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/feature`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     "name": "interview scheduling 1",
    //     "description": "Enable clients to schedule and manage interviews with candidates directly through the platform, with calendar integration for easy coordination",
    //     "featureType": "client", // client, consultant
    //     "featureItemids": [
    //         "65ce7a973cb8da09cbfc2233"
    //     ]
    // }
  });
};

export const updateFeature = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/feature`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     "id": "65ce7cd93cb8da09cbfc223f",
    //     "name" : "Interview Scheduling",
    //     "description": "Enable clients to schedule and manage interviews with candidates directly through the platform, with calendar integration for easy coordination",
    //     "featureType": "client", // client, consultant
    //     "featureItemids": [
    //         "65cfb04bd8548aedcdbb7c40",
    //         "65cfb06ed8548aedcdbb7c44",
    //         "65cfb1b7d8548aedcdbb7c48"
    //     ]
    // }
  });
};

export const deleteFeature = async (featureID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/feature/${featureID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getFeatureItems = async () => {
  return fetchWrapper(`${configs.APIUrl}/admin/getFeatureItem`);
};

export const addFeatureItem = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/featureItem`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     "name": "Onboarding",
    //     "description": "User is able to onboarding consultant",
    // }
  });
};

export const updateFeatureItem = async (bodyRequest) => {
  return fetchWrapper(`${configs.APIUrl}/admin/featureItem`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
    //   {
    //     "id": 1234,
    //     "name": "Onboarding",
    //     "description": "User is able to onboarding consultant",
    // }
  });
};

export const deleteFeatureItem = async (itemID) => {
  return fetchWrapper(`${configs.APIUrl}/admin/featureItem/${itemID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};
