import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
// import Carousel from "react-material-ui-carousel";
import { Typography } from "@mui/material";
import images from "../../images/landingpage";
import { makeStyles } from "@mui/styles";
import CustomSlickCarousel from "../../components/CustomSlickCarousel";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    // marginBottom: "6rem",
    // [theme.breakpoints.down("600")]: {
    //   marginBottom: "3.6rem",
    // },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  heading: {
    maxWidth: "715px",
    margin: "auto",
    textAlign: "center",
    marginBottom: "10rem",
    [theme.breakpoints.down("1500")]: {
      marginBottom: "6rem",
    },
    [theme.breakpoints.down("800")]: {
      marginBottom: "4.5rem",
    },
    [theme.breakpoints.down("600")]: {
      fontSize: "18px",
      margin: "0 16px 57px 16px",
    },
  },
  slickCarouselDiv: {
    maxWidth: "1350px",
    margin: "auto",
    // paddingBottom: "45px",
    [theme.breakpoints.down("1400")]: {
      maxWidth: "950px",
    },
    [theme.breakpoints.down("1000")]: {
      maxWidth: "750px",
    },
    [theme.breakpoints.down("800")]: {
      maxWidth: "550px",
    },
    [theme.breakpoints.down("600")]: {
      width: "100%",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  slide: {
    width: "fit-content !important",
    // marginRight: "109px",
    padding: "66px",
    marginBottom: "45px",
    [theme.breakpoints.down("1000")]: {
      padding: "33px",
    },
    [theme.breakpoints.down("400")]: {
      padding: "16px",
    },
  },
  inActiveSlide: {
    // position: "relative",
    // top: "20px";
  },
  activeSlide: {
    // objectFit: "none",
    // [theme.breakpoints.down("1500")]: {
    //   maxWidth: "20%",
    //   objectFit: "scale-down",
    // },

    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.15)",
  },
}));
const Publishers = (props) => {
  const classes = useStyles();
  const publisherImages = images.publishers;
  const publisherReviews = [
    "Fast and Efficient Hiring Process at FindPro - 5 Day Turnaround Time",
    "FindPro builds remote teams with top notch talent",
    "Tech talent have the full spectrum of FindPro's services right in their pocket",
    "FindPro is a network designed to connect technically vetted software developers with hiring organizations in need of high-demand talent",
  ];
  const sliderRef = useRef(null);
  const [currentPublisherIndex, setCurrentPublisherIndex] = useState(0);

  const handleSlideChange = (activeSlideIndex) => {
    setCurrentPublisherIndex(activeSlideIndex);
  };
  const carouselSettings = {
    slidesToShow: publisherReviews.length - 1,
    infinite: true,
    autoplay: true,
    // speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    afterChange: (current) => handleSlideChange(current),
    className: classes.slickCarouselDiv,
    centerMode: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        const slick = sliderRef.current.slick;
        const nextSlide = (currentPublisherIndex + 1) % slick.slideCount;
        slick.slickGoTo(nextSlide);
      }
    }, 3000); // Set the desired delay in milliseconds

    return () => {
      clearTimeout(timer);
    };
  }, [currentPublisherIndex]);

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.heading}>
        "{publisherReviews[currentPublisherIndex]}"
      </Typography>
      {/* <div className={classes.imageContainer}>
        {publisherImages?.map((publisher) => (
          <img src={publisher} className={classes.image} />
        ))}
      </div> */}
      <CustomSlickCarousel settings={carouselSettings} ref={sliderRef}>
        {publisherImages?.map((publisher, index) => (
          <img
            src={publisher}
            className={clsx([
              classes.slide,
              index === currentPublisherIndex
                ? classes.activeSlide
                : classes.inActiveSlide,
            ])}
          />
        ))}
      </CustomSlickCarousel>
      {/* <CustomSwiper
        slidesPerView={"auto"}
        spaceBetween={109}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1000}
        swiperClasses={classes.swiper}
        onSwiper={handleSwiper}
        onSlideChange={handleSlideChange}
      >       
      </CustomSwiper> */}
    </div>
  );
};

Publishers.propTypes = {};

export default Publishers;
