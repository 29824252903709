import React, { useEffect, useState } from "react";
import tw from 'twin.macro';

import { List, Line, Text } from "../../common";
import Tooltip from "../../common/ToolTip";

import {HiringBoardJobDetailsObj} from "./HiringBoardJobDetails.dummy.js";

import { 
  EditSVG, 
  DeactivateSVG, 
  DeleteSVG,
  ShareSVG
} from "../../SVGs/Index";
import { IconDot } from "../../SVGs/IconDot";
import { PinSVG } from "../../SVGs/Pin";
import { CheckSVG } from "../../SVGs/Check";

import CustomButton from "app/v2/components/CustomButton";
const Button = CustomButton;


const HiringBoardJobDetails = ({props}) => {


    return (
    <>
      <div className="bg-white flex flex-col sm:gap-10 md:gap-10 gap-10 items-center justify-start mx-auto p-10 md:px-10 sm:px-5 w-full">
        <div className="border border-gray-600 border-solid flex flex-col gap-9 items-start justify-start max-w-[1088px]  mx-auto sm:px-5  p-9 py-6 w-full">
          <div className="flex flex-col gap-9 items-start justify-start w-full">
            <div className="flex flex-col gap-6 items-start justify-start w-full">
              <div className="flex flex-col gap-4 items-start justify-start w-full">
                <div className="flex md:flex-col flex-row gap-[19px] items-start justify-start max-w-[1040px] w-full">
                  <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                    <Text className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto">
                      Costco App Development Team
                    </Text>
                    <div className="flex sm:flex-col flex-row gap-3 items-center justify-start w-auto sm:w-full">
                      <Text className="font-normal text-gray-600 text-sm w-auto">Posted {props._created_at} </Text>
                      <Text className="font-normal text-gray-600 text-sm w-auto">Last Updated {props._updated_at} </Text>
                      <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">job no. {props.jobNo}</Text>
                    </div>
                    <div className="flex flex-row gap-1 items-center justify-start w-auto">
                      <Text className="font-semibold text-gray-400 text-sm w-auto"> {props.role} </Text>
                      <Text className="font-semibold text-gray-900 text-sm w-auto"> {props.roleName} </Text>
                    </div>
                  </div>
                  
                  {props?.status === 'active' ? (
                    <div className="bg-green-50 flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm">
                        <IconDot className="mt-0.5 mb-[3px] mr-[5px]" fillColor="#14C042" />
                      <div className="font-bold text-green-700 text-left text-xs">Active</div>
                    </div>
                  ) : props?.status === 'inactive' ? (
                    <div className="bg-gray-300 flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm">
                        <IconDot className="mt-0.5 mb-[3px] mr-[5px]" fillColor="#555" />
                      <div className="font-bold text-gray-600 text-left text-xs">Inactive</div>
                    </div>
                  ) : props?.status === 'closed' ? (
                    <div className="bg-[#f5f7ff] flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm">
                        <IconDot className="mt-0.5 mb-[3px] mr-[5px]" fillColor="#1d5dff" />
                      <div className="font-bold text-blue-700 text-left text-xs">Closed</div>
                    </div>
                  ) : (
                    <>
                    Error
                    </>
                  )}

                </div>
                <div className="flex md:flex-col flex-row gap-4 items-center justify-start max-w-[1040px] w-full">
                  <List
                    className="md:flex-1 sm:flex-col flex-row gap-4 grid grid-cols-2 w-[37%] md:w-full"
                    orientation="horizontal"
                  >
                    <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        team size
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto"> {props.teamSize} </Text>
                    </div>
                    <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        job positions
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto"> {props.jobPositions} </Text>
                    </div>
                  </List>
                  <div className="bg-gray-100 flex sm:flex-1 sm:flex-col flex-row gap-2.5 items-start justify-start p-3 w-auto sm:w-full">
                    <div className="flex flex-col gap-0.5 items-center justify-center w-[126px]">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">
                        Total Applicants
                      </Text>
                      <Text className="font-bold text-base text-center text-gray-900 w-full"> {props.totalApplicants} </Text>
                    </div>
                    <div className="flex flex-col gap-0.5 items-center justify-center w-[126px]">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">Accepted</Text>
                      <Text className="font-bold text-base text-center text-gray-900 w-full"> {props.accepted} </Text>
                    </div>
                    <div className="flex flex-col gap-0.5 items-center justify-center w-[126px]">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">rejected</Text>
                      <Text className="font-bold text-base text-center text-gray-900 w-full"> {props.rejected} </Text>
                    </div>
                  </div>
                  <div className="bg-gray-50 flex flex-col items-start justify-start p-2.5 w-auto">
                    <div className="flex flex-col gap-0.5 items-start justify-center w-[182px]">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">team tag</Text>
                      <Text className="bg-white font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto">
                        Team: {props.tag} 
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
              
              <Line className="bg-gray-200 h-px w-full" />

              <div className="flex flex-col gap-4 items-start justify-start w-auto md:w-full">
                <Text className="font-semibold text-black-900 text-lg w-auto">Jobs Status</Text>
                <List
                  className="sm:flex-col flex-row gap-4 grid sm:grid-cols-1 md:grid-cols-3 grid-cols-5 justify-start max-w-[1040px] w-full"
                  orientation="horizontal"
                >

                  {props?.jobs?.map((job, index) => (
                    <div key={index} className="flex flex-1 flex-col gap-1.5 items-start justify-center w-full">
                      <Text className="text-gray-600 text-xs w-full"> { job.jobName } </Text>
                      <Text className="font-bold text-base text-center text-gray-900 w-auto cap capitalize"> { job.status } </Text>
                    </div>
                  )
                  )}

                </List>
              </div>
            </div>

            <div className="flex md:flex-col flex-row gap-9 items-end justify-between w-full">
              <div className="flex sm:flex-1 sm:flex-col flex-row gap-3 items-start justify-start w-auto sm:w-full">
                {props?.status === 'active' ? (
                  <>
                  <Button
                    onClick={() => {
                      
                    }}
                    rightIcon={
                      <IconDot fillColor="green" className="mb-[3px] ml-2.5" />
                    }
                    type="button1"
                    customClasses={"h-10 px-4 py-2.5"}
                    >
                    <div className="font-semibold leading-[normal] text-center text-white">
                      View Job Hiring Board
                    </div>
                    <IconDot fillColor="green" className="ml-2.5" />
                  </Button>
                  <Button
                    variant="outlined"
                    type="button2"
                    customClasses={"border border-gray-900 h-10 px-4 py-2.5"}
                    >
                    Find Consultants
                  </Button>
                  </>
                ) : props?.status === 'closed' ? (
                <Button
                  type="button1"
                  customClasses={"px-5 py-2.5"}
                  >
                  Check Consultant Activity
                </Button>
                ) : props?.status === 'inactive' ? (
                <Button
                  type="button2"
                  customClasses={"border border-gray-200 text-white px-5 py-2.5"}
                  >
                  <CheckSVG className=" mt-px mb-0.5 m-2.5" fillColor="#000" />
                  Reactivate
                </Button>
                ) : (
                  <>
                  Error
                  </>
                )}

                <Button
                type="button2"
                customClasses={"border border-gray-200 border-solid flex h-10 items-center justify-center w-10"}
                >
                <EditSVG />
                </Button>
                <Button
                type="button2"
                customClasses={"border border-gray-200 border-solid flex h-10 items-center justify-center w-10"}
                >
                <DeactivateSVG />
                </Button>
                {props?.status === 'active' && (
                <Button
                type="button2"
                customClasses={"border border-gray-200 border-solid flex h-10 items-center justify-center w-10"}
                >
                <PinSVG />
                </Button>
                )}

                <Button
                  type="button2"
                  customClasses={"border border-gray-200 border-solid flex h-10 items-center justify-center w-10"}
                  >
                  <ShareSVG />
                </Button>
              </div>

              <Button
                type="button2"
                customClasses={"border border-gray-200 border-solid flex h-10 items-center justify-center w-10"}
                >
                <DeleteSVG className="h-6 w-6" />
              </Button>
              
            </div>
          </div>
        </div>

      </div>

    </>
    );
};

HiringBoardJobDetails.propTypes = {};
HiringBoardJobDetails.defaultProps = HiringBoardJobDetailsObj;

export default HiringBoardJobDetails;