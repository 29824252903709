import React from "react";
import { Typography, FormControl, OutlinedInput } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchInput: {
    fontSize: "16px !important",
  },
  searchIcon: {
    color: theme.palette.secondary.light,
  },
}));

const Invoice = () => {
  const classes = useStyles();
  return (
    <div className="self-stretch flex flex-col items-start justify-start relative gap-[51px] text-left text-13xl text-neutral-800 font-button-text-button-14">
      <div className="w-[975px] h-11 flex flex-row items-start justify-between z-[0] text-neutral-900">
        <div className="relative leading-[120%] font-semibold inline-block w-96 shrink-0">
          Subscription Invoices
        </div>
        <FormControl variant="outlined" sx={{ width: "25ch" }}>
          <OutlinedInput
            type="text"
            placeholder="Search by order number, plan name, and more"
            endAdornment={<SearchIcon className={classes.searchIcon} />}
            // value={salaryTypeSelectedInfo?.range?.min}
            // onChange={(event) => {

            // }}
            className={`bg-[transparent] box-border w-[477px] h-[41px]  py-[7.545324802398682px] border-[0.9px] border-solid border-neutral-50 ${classes.searchInput}`}
          />
        </FormControl>
      </div>
      <div className="absolute my-0 mx-[!important] top-[50px] left-[0px] text-xs leading-[180%] z-[1]">
        Review your billing history and manage your invoices.
      </div>
      <div className="bg-f7-background overflow-hidden flex flex-col py-[41px] px-[58px] items-start justify-start z-[2] text-sm">
        <div className="flex flex-row items-start justify-start gap-[72px]">
          <div className="flex flex-col items-start justify-start gap-[36px]">
            <b className="relative text-3xs uppercase text-neutral-500">Date</b>
            <div className="relative leading-[160%]">Apr 4, 2023</div>
            <div className="relative leading-[160%]">Mar 4, 2023</div>
            <div className="relative leading-[160%]">Feb 4, 2023</div>
            <div className="relative leading-[160%]">Jan 4, 2023</div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[36px]">
            <b className="relative text-3xs uppercase text-neutral-500">
              order number
            </b>
            <div className="relative leading-[160%]">ADD058960000EDPH</div>
            <div className="relative leading-[160%]">ADD058960000EDPH</div>
            <div className="relative leading-[160%]">ADD058960000EDPH</div>
            <div className="relative leading-[160%]">ADD058960000EDPH</div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[36px]">
            <b className="relative text-3xs uppercase text-neutral-500">
              Plans
            </b>
            <div className="relative leading-[160%]">Premium 500</div>
            <div className="relative leading-[160%]">Premium 500</div>
            <div className="relative leading-[160%]">Premium 500</div>
            <div className="relative leading-[160%]">Premium 500</div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[36px]">
            <b className="relative text-3xs uppercase text-neutral-500">
              Amount
            </b>
            <div className="relative leading-[160%]">$500</div>
            <div className="relative leading-[160%]">$500</div>
            <div className="relative leading-[160%]">$500</div>
            <div className="relative leading-[160%]">$500</div>
          </div>
          <div className="flex flex-col items-start justify-start gap-[21px] text-3xs text-neutral-500">
            <b className="relative uppercase">Actions</b>
            <div className="flex flex-row items-start justify-start gap-[9px]">
              <button className="cursor-pointer bg-[transparent] box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px]"
                  alt=""
                  src="/view_icon.svg"
                />
              </button>
              <button className="cursor-pointer bg-[transparent] box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/download.svg"
                />
              </button>
              <button className="cursor-pointer bg-[transparent] box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/email.svg"
                />
              </button>
            </div>
            <div className="flex flex-row items-start justify-start gap-[9px]">
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px]"
                  alt=""
                  src="/view_icon.svg"
                />
              </div>
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/download.svg"
                />
              </div>
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/email.svg"
                />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[9px]">
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col  items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px]"
                  alt=""
                  src="/view_icon.svg"
                />
              </div>
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/download.svg"
                />
              </div>
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/email.svg"
                />
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[9px]">
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col  items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px]"
                  alt=""
                  src="/view_icon.svg"
                />
              </div>
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/download.svg"
                />
              </div>
              <div className="box-border w-[39px] h-[39.56px] overflow-hidden shrink-0 flex flex-col items-center justify-center border-[1px] border-solid border-neutral-50">
                <img
                  className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                  alt=""
                  src="/email.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
