import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getFavoritesByStatus = async (type = "") => {
  let url = `${configs.APIUrl}/client/favorite`;

  if (type) {
    url += `?type=${type}`;
  }

  return fetchWrapper(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const favUnfavSelection = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/favorite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getClientInfoPublic = async (clientID) => {
  return fetchWrapper(`${configs.APIUrl}/client/${clientID}`);
};
