import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import About from "../About";
import RTTAndCompanies from "../common/ConnectorAndCompanies";
import TeamInfo from "./TeamInfo";
import WhyHireTeam from "./WhyHireTeam";
import BuildTeam from "./BuildTeam";
import HiringSteps from "./HiringSteps";
import Testimonials from "../common/Testimonials";

const useStyles = makeStyles((theme) => ({}));
const HireTeamDetails = (props) => {
  const classes = useStyles();
  return (
    <>
      <About type="team" />
      <RTTAndCompanies />
      <TeamInfo />
      <WhyHireTeam />
      <BuildTeam />
      {/* <HiringSteps />
        <Testimonials /> */}
    </>
  );
};

HireTeamDetails.propTypes = {};

export default HireTeamDetails;
