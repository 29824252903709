import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Box,
  TextField,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomLabel from "./CustomLabel";
import {
  WEBSITE_PATTERN,
  PASSWORD_PATTERN,
  EMAIL_PATTERN,
  URL_PATTERN,
} from "app/v2/Pages/Utils/constants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { isFunction } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
      border: "1px solid #EDEDED",
    },
  },
  textFieldRoot: {
    fontSize: "16px",
    // padding: 0,
    "& .MuiFormLabel-root": {
      fontSize: "16px",
    },
    "& .MuiInputBase-input": {
      height: "26px",
      padding: "12px",
      fontSize: "16px",
    },
  },
  helperText: {
    marginLeft: 0,
  },
  endAdornmentIcon: {
    cursor: "pointer",
  },
}));

const getErrorMessage = (type, value, label, min, max) => {
  const labelOrTypeInMessage = label ? label?.toLowerCase() : type;
  const invalidValueMessage = `Enter a valid ${labelOrTypeInMessage}.`;
  const emptyValueMessage = `Enter the ${labelOrTypeInMessage}.`;

  const isValidUrl = URL_PATTERN.test(value);
  const isValidPassword = PASSWORD_PATTERN.test(value);
  const isValidEmail = EMAIL_PATTERN.test(value);

  if (value === "") return emptyValueMessage;

  if (type === "number") {
    value = Number(value);

    if (typeof min === "number" && value < min)
      return `Please enter a value greater than ${min - 1}.`;
    if (typeof max === "number" && value > max)
      return `Please enter a value lesser than ${max}`;
  }

  if (type === "url" && !isValidUrl)
    return "Invalid URL. Please make sure you have included 'http://' or 'https://'.";
  else if (
    (type === "password" && !isValidPassword) ||
    (type === "email" && !isValidEmail)
  )
    return invalidValueMessage;
  else return null;
};

/**
 * CustomTextField Component
 *
 * This component renders an input element with extended features such as support for various input types (text, email, url, password) and optional configurations.
 *
 * @component
 * @example
 * // Example usage:
 * <CustomTextfield
 *   id="username"
 *   label="Username"
 *   placeholder="Enter your username"
 *   value={username}
 *   onChange={(id, value) => handleInputChange(id, value)}
 *   customClasses={{ outlinedInput: { fontSize: '16px', color: 'blue' }, root: {display:'flex'} }}
 *   type="text"
 *   startAdornment={<AccountCircleIcon />}
 *   endAdornment={<CheckCircleIcon />}
 *   inputProps={{ maxLength: 20 }}
 *   multiline={false}
 *   minRows={3}
 *   reset={resetState}
 *   autoFocus={true}
 *   noErrorMessage={false}
 *   customErrorMessage="Custom error message"
 *   onClick={() => handleOnClick()}
 *   showMandatorySymbol={true}
 * />
 *
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.id - The unique identifier for the input element. Useful when there are multiple similar textfield elements in the view.
 * @param {string} [props.value] - The value of the input element.
 * @param {string} [props.label] - The label for the input element shown above the element as per design.
 * @param {string} [props.placeholder] - The placeholder text for the input element.
 * @param {boolean} [props.disabled] - If true, the input element will be disabled.
 * @param {function} [props.onChange] - The callback function invoked when the input value changes.
 * @param {Object} [props.customClasses] - An object containing custom CSS classes to be applied to different parts of the `OutlinedInput` component.
 * @param {string} [props.type] - The type of the input element (text, email, url, password,number etc->All types of input element). default is "text".
 * @param {ReactNode} [props.startAdornment] - The start adornment(decorative elements or icons) for the input element.
 * @param {ReactNode} [props.endAdornment] - The end adornment(decorative elements or icons) for the input element.
 * @param {Object} [props.inputProps] - Additional attributes to be passed to the input element(specific to the type of input element).
 * @param {boolean} [props.multiline=false] - If true, the input element will be a textarea.
 * @param {number} [props.minRows=3] - The minimum number of rows for a multiline input.
 * @param {boolean} [props.reset] - A trigger to reset the error message to its default state (null).
 * @param {boolean} [props.noErrorMessage] - If true, error messages will be suppressed.
 * @param {string} [props.customErrorMessage] - A custom error message to be displayed instead of the error messages set by the component.
 * @param {function} [props.onClick] - The callback function invoked on click of the input element.
 * @param {boolean} [props.showMandatorySymbol] - If true, a mandatory symbol (*) will be displayed next to the label.
 *
 * @returns {ReactElement} The rendered CustomTextField component.
 */
const CustomTextfield = (props) => {
  const classes = useStyles();
  let {
    id,
    value: valueReceived,
    label,
    placeholder,
    disabled,
    onChange,
    customClasses,
    type,
    startAdornment,
    endAdornment,
    inputProps,
    multiline,
    minRows,
    reset, // to reset error message to default state i.e, null. (Check Client->Organization Details view).
    noErrorMessage, // If we dont want error message to be shown even if there is an error. (useful for page leftside filters where the field is not mandatory).
    customErrorMessage,
    onClick,
    showMandatorySymbol,
  } = props;

  type = type ?? "text";
  const [elementType, setElementType] = useState(type);
  const [value, setValue] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const isPasswordElement = () => elementType === "password";
  const handleChange = (event) => {
    const updatedValue = event.target.value;
    setValue(updatedValue);
    if (!noErrorMessage) {
      const updatedErrorMessage = getErrorMessage(
        type,
        updatedValue,
        label,
        inputProps?.min,
        inputProps?.max
      );
      setErrorMessage(updatedErrorMessage);
    }

    if (isFunction(onChange)) onChange(id, updatedValue);
  };

  const onTogglePassword = () => {
    const updatedElementType = isPasswordElement() ? "text" : "password";
    setElementType(updatedElementType);
  };

  useEffect(() => {
    setValue(valueReceived);
  }, [valueReceived]);

  useEffect(() => {
    setErrorMessage(null);
  }, [reset]);

  return (
    <div className="w-full">
      {label && (
        <CustomLabel label={`${label} ${showMandatorySymbol ? "*" : ""}`} />
      )}
      <FormControl
        fullWidth
        className={classes.formControl}
        error={
          noErrorMessage ? false : Boolean(errorMessage || customErrorMessage)
        }
      >
        <OutlinedInput
          data-testid={id}
          id={id}
          // key
          type={elementType}
          placeholder={placeholder}
          startAdornment={startAdornment}
          endAdornment={
            type === "password" ? (
              isPasswordElement() ? (
                <VisibilityOffIcon
                  className={classes.endAdornmentIcon}
                  onClick={onTogglePassword}
                  id="offIcon"
                />
              ) : (
                <VisibilityIcon
                  className={classes.endAdornmentIcon}
                  onClick={onTogglePassword}
                  id="onIcon"
                />
              )
            ) : (
              endAdornment
            )
          }
          value={value}
          onChange={handleChange}
          onClick={onClick}
          inputProps={inputProps}
          disabled={disabled}
          variant="outlined"
          multiline={multiline}
          minRows={minRows}
          classes={customClasses}
          className={classes.textFieldRoot}
          error={
            noErrorMessage ? false : Boolean(errorMessage || customErrorMessage)
          }
        />
        {!noErrorMessage && (errorMessage || customErrorMessage) && (
          <FormHelperText className={classes.helperText}>
            {errorMessage || customErrorMessage}
          </FormHelperText>
        )}
        {type === "password" && (
          <span>
            <sup>*</sup>One Uppercase, One Number, One Special Character &
            Minimum 8 characters.
          </span>
        )}
      </FormControl>
    </div>
  );
};

CustomTextfield.propTypes = {};

export default CustomTextfield;
