import { createSlice } from "@reduxjs/toolkit";
import { loginInfoThunk, loginUser } from "./login.thunk";

const initialState = {
  value: {
    token: "",
    first_name: "",
    last_name: "",
    email: "",
    isProfileComplete: null,
  },
};

const loginSlice = createSlice({
  name: "login_info",
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    loggedOut: (state) => {
      state = initialState;
    },
    profileComplete: (state, action) => {
      state.value.isProfileComplete = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.value = action.payload;
      })
      .addCase(loginInfoThunk.fulfilled, (state, action) => {
        state.value = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { loggedIn, loggedOut, profileComplete } = loginSlice.actions;

export default loginSlice.reducer;
