import React from "react";
import PropTypes from "prop-types";
import { NoProfileImageSVG } from "../SVGs/NoProfile";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    rowGap: "30px",
  },
  flexColContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  userDiv: {
    padding: "16px",
    border: "1px solid #EDEDED",
    fontSize: "24px",
  },
}));

const AccountTypes = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const onUserClick = (userType) => {
    history.push(`/${userType}/onboarding`);
  };
  return (
    <div className={clsx([classes.flexColContainer, classes.container])}>
      <div className="text-[36px] font-semibold">Signup to proceed as a</div>
      <div className="flex gap-x-[36px] w-full">
        <button
          className={clsx([classes.flexColContainer, classes.userDiv])}
          onClick={() => {
            onUserClick("client");
          }}
        >
          <NoProfileImageSVG />
          Client
        </button>
        <button
          className={clsx([classes.flexColContainer, classes.userDiv])}
          onClick={() => {
            onUserClick("consultant");
          }}
        >
          <NoProfileImageSVG />
          Consultant
        </button>
        <button
          className={clsx([classes.flexColContainer, classes.userDiv])}
          onClick={() => {
            onUserClick("agency");
          }}
        >
          <NoProfileImageSVG />
          Agency
        </button>
      </div>
    </div>
  );
};

AccountTypes.propTypes = {};

export default AccountTypes;
