import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import JobStatus from "app/v2/Pages/common/Status/Job";
import CustomButton from "app/v2/components/CustomButton";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import { UserContext } from "app/v2";

import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "36px",
    display: "flex",
    flexDirection: "column",
    rowGap: "17px",
    border: "1px solid #ABABAB",
  },
  jobDetailsButton: {
    padding: "10px 15px 10px 20px",
    fontSize: "14px",
    fontWeight: 600,
    columnGap: "10px",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  teamMemberImage: {
    width: "48px",
    height: "48px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const List = ({ data }) => {
  // data structure-
  // {
  //   image: "/company_image.png",
  //   jobTitle: "Software Developer 1",
  //   jobNo: "FP23147",
  //   companyName: "ABC Company",
  //   location: "Singapore City, Singapore",
  //   timeZone: "SGST (GMT + 8.0)",
  //   jobDescription:
  //     "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
  //   activeConsultants: ["/person1.png", "/person2.png", "/person3.png"],
  //   jobStatus: "active",
  //   onBoardingStatus: "assessment",
  // },
  const classes = useStyles();

  const onBoardingStatusData = {
    assessment: {
      text: "Documents under Assessment",
      style: { color: "#FBBC05" },
    },
  };

  const onBoardingStatusText =
    onBoardingStatusData[data?.onBoardingStatus]?.text;
  const onBoardingStatusStyle =
    onBoardingStatusData[data?.onBoardingStatus]?.style;

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);

  return (
    <div className={classes.root}>
      {/* Section 1-start */}
      <div className="flex gap-x-[19px] items-start">
        <img
          className={classes.orgImage}
          alt={`org_${data?._id}_image`}
          src={data?.companyImage ? data.companyImage : "/org_placeholder.svg"}
        />

        <div className="flex-1">
          <div className="text-[24px] font-semibold">
            {data?.jobTitle ?? "-"}
          </div>
          <div className="text-[14px] text-[#717171] font-semibold">
            JOB NO {data?.jobNo ?? "-"}
          </div>
          <div className="text-[14px] text-[#8C8C8C] font-semibold">
            Hiring Manager{" "}
            <span className="text-black">{data?.hiringManager ?? "-"}</span>
          </div>
        </div>
        <JobStatus type={data?.jobStatus} />
      </div>
      {/* Section 1-end */}
      {/* Section 2 -start */}
      <div>
        <div className="text-[10px] font-bold">ONBOARDING STATUS</div>
        <div className="`text-[14px] font-medium" style={onBoardingStatusStyle}>
          {onBoardingStatusText ?? "-"}
        </div>
      </div>
      {/* Section 2 -end */}
      {/* Section 3 -start */}
      <div>
        <div className="text-[10px] font-semmibold">ACTIVE CONSULTANT</div>
        <div>
          {isValidArray(data?.teamMembersProfilePics) ? (
            <div className="flex gap-x-[8px]">
              {data.teamMembersProfilePics.map((pic, index) => (
                <img
                  src={pic ? pic : "/user_placeholder.svg"}
                  alt={`"user_${index}_image"`}
                  className={classes.teamMemberImage}
                />
              ))}
            </div>
          ) : (
            "-"
          )}
        </div>
      </div>
      {/* Section 3 -end */}
      <CustomButton
        type="button1"
        customClasses={classes.jobDetailsButton}
        onClick={() => {
          setDrawerDetails((prevState) => ({
            ...prevState,
            showDrawer: true,
            componentType: "agencyJobContract",
            showHeading: true,
          }));
        }}
      >
        View Contract Activity
        <img
          className="w-[15px] h-[15px]"
          alt=""
          src="/iconlightup-right.svg"
        />
      </CustomButton>
    </div>
  );
};

List.propTypes = {};

export default List;
