import { createSlice } from "@reduxjs/toolkit";
import { candidateInfoThunk, updateProfilePictureThunk } from "./profile-settings.thunk";

const initialState = {
  value: {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    personal_info: {},
    profile_picture: ""
  },
};

const profileSettingsSlice = createSlice({
  name: "candidate_info",
  initialState,
  reducers: {
    candidateInfoIn: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    candidateInfoOut: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(candidateInfoThunk.fulfilled, (state, action) => {
      state.value = action.payload;
    }).addCase(updateProfilePictureThunk.fulfilled, (state, action) => {
      state.value.profile_picture = action.meta.arg.profile_picture;
    });
  },
});

// Action creators are generated for each case reducer function
export const { candidateInfoIn, candidateInfoOut } = profileSettingsSlice.actions;

export default profileSettingsSlice.reducer;
