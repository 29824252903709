// @ts-nocheck
import React, { useEffect, useState, createContext } from "react";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import theme from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Homepage from "./landingpages/homepage";
import HirePage from "./landingpages/hire_developer_team/HirePage";
import HireDeveloperDetails from "./landingpages/hire_developer_team/developer/HireDeveloperDetails";
import HireTeamDetails from "./landingpages/hire_developer_team/team/HiringTeamDetails";
import ApplyJob from "./landingpages/apply_job/ApplyJob";
import CustomDrawer from "./components/CustomDrawer";
/** Old pages below - These are used until new designs are ready */
import PrivacyPolicies from "../landingPage/PrivacyPolicies";
import Terms from "../landingPage/Terms";
import Calendy from "../common/components/calendy/calendy";
import CalendyTeam from "../common/components/calendy/calendy_team";
import {
  ConsultantRoute,
  EmployerRoute,
  AgencyRoute,
  AdminRoute,
} from "./routes";
import Feed from "./Pages/Employer/Dashboard/Feed";
import FeedViewAll from "./Pages/Employer/searchresultsandfeed/FeedViewAll";
import NotFound from "./Pages/common/NotFound";
import PublicJobDetail from "./Pages/common/PublicJobDetail";
import PublicConsultantDetail from "./Pages/common/PublicConsultantDetail";
import PublicAgencyDetail from "./Pages/common/PublicAgencyDetail";
import PublicClientDetail from "./Pages/common/PublicClientDetail";

import Components from "./Pages";
import ResetPassword from "./Pages/common/resetpassword/ResetPassword";
import configs from "./Pages/Utils/configs";
import fetchWrapper from "./Pages/Utils/fetchWrapper";
import store from "./store";
import { Provider } from "react-redux";
import AccountTypes from "./Pages/common/AccountTypes";
import images from "app/v2/images/common";

import moment from "moment";

// import GoogleAuth from "app/common/login/google-auth.component";

import { useSnackbar, SnackbarProvider } from "notistack";

import { useDispatch, useSelector } from "react-redux";
import { appendNotification } from "./store/features/notifications/notificationsSlice";

import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { setNToken } from "app/v2/services/notificationsService";
import { isLoggedIn } from "./utils/loginUtil";

const firebaseConfig = {
  apiKey: "AIzaSyCBrAj6GG5dolHGQ6e-E4530e7egC8f3-4",
  authDomain: "findprosnotification.firebaseapp.com",
  projectId: "findprosnotification",
  storageBucket: "findprosnotification.appspot.com",
  messagingSenderId: "990598615009",
  appId: "1:990598615009:web:409858024b314904208ca0",
  measurementId: "G-CG7PRCXRTH",
};
const app = initializeApp(firebaseConfig);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1440px",
    margin: "auto",
  },
  bodyWithNF: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 278px)", // total viewport height minus footer's heights.
    paddingTop: "100px", // fixed navbar's height
  }, // NF- Navbar,Footer
  navbar: {
    position: "fixed",
    top: 0,
    maxWidth: "1440px",
    width: "100%",
    zIndex: 999,
    padding: "22px 24px",
    backgroundColor: theme.palette.common.white,
  },
  logo: {
    cursor: "pointer",
    maxWidth: "196px",
    maxHeight: "5.1rem",
    height: "auto",
    width: "100%",
    [theme.breakpoints.down("600")]: {
      maxWidth: "7.6rem",
      maxHeight: "2.1rem",
    },
  },
}));

export const UserContext = createContext();
export const NotificationContext = createContext();

const withNavbarFooter = (WrappedComponent) => {
  const ComponentWithNF = (props) => {
    const classes = useStyles();
    return (
      <>
        <div className={classes.navbar}>
          <div
            className={classes.logo}
            onClick={() => {
              // history.push("/homepage");
            }}
          >
            <img src={images.logo_v2} alt="Findpro logo" />
          </div>
        </div>
        <div className={classes.bodyWithNF}>
          <WrappedComponent {...props} />
        </div>

        <Footer />
      </>
    );
  };
  return ComponentWithNF;
};

export const setupNotificationHandling = (enqueueSnackbar, closeSnackbar) => {
  let unsubscribe;
  const messaging = getMessaging();
  const handleFirebaseMessaging = () => {
    getToken(messaging)
      .then((currentToken) => {
        if (currentToken) {
          // console.log("nToken:", currentToken);
          setNToken({ nToken: currentToken })
            .then((response) => {
              unsubscribe = onMessage(messaging, (payload) => {
                const newNotification = {
                  // type: notification.type,
                  title: payload?.notification?.title,
                  message: payload?.notification?.body,
                  link: payload?.fcmOptions?.link,
                  // image: "notification.type",
                  time: moment(new Date()).fromNow(true),
                };
                store.dispatch(appendNotification(newNotification));

                enqueueSnackbar("", {
                  variant: "info",
                  autoHideDuration: 3000,
                  content: (key) => (
                    <div
                      style={{
                        display: "flex",
                        // alignItems: 'center',
                        // justifyContent: 'space-between',
                        // minWidth: 300,
                        borderRadius: 0,
                        border: "1px solid #1b81ff",
                        padding: "12px",
                        background: "#1b81ff",
                      }}
                    >
                      {/* <span>{payload?.notification.title}</span>  */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html: payload?.notification.body,
                        }}
                        style={{ color: "#eee" }}
                      />
                      {/* <button
                            onClick={() => {
                              closeSnackbar(key);
                            }}
                          >
                            Dismiss
                          </button> */}
                      <CloseIcon
                        onClick={() => closeSnackbar(key)}
                        style={{
                          cursor: "pointer",
                          fontSize: "16",
                          marginTop: "-2px",
                          margin: 5,
                          borderRadius: 0,
                          color: "#fff",
                        }}
                      />
                    </div>
                  ),
                });
              });
            })
            .catch((error) => {
              enqueueSnackbar(
                "Error while setting the notification token. Please try again later.",
                {
                  variant: "error",
                  autoHideDuration: 5000,
                  action: (key) => (
                    <CloseIcon
                      onClick={() => closeSnackbar(key)}
                      style={{
                        cursor: "pointer",
                        fontSize: "15",
                        marginTop: "-1px",
                      }}
                    />
                  ),
                }
              );
            });
        } else {
          enqueueSnackbar(
            "Error while fetching notification token. Try refreshing the page or check permissions in the browser settings.",
            {
              variant: "error",
              autoHideDuration: 5000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{
                    cursor: "pointer",
                    fontSize: "15",
                    marginTop: "-1px",
                  }}
                />
              ),
            }
          );
        }
      })
      .catch((error) => {
        console.log({ error }, "notification token fetch error");
        enqueueSnackbar(
          "Error while fetching notification token. Try refreshing the page or check permissions in the browser settings.",
          {
            variant: "error",
            autoHideDuration: 5000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{
                  cursor: "pointer",
                  fontSize: "15",
                  marginTop: "-1px",
                }}
              />
            ),
          }
        );
      });
  };

  Notification.requestPermission().then((permission) => {
    console.log({ permission });

    if (permission === "granted") handleFirebaseMessaging();
    else {
      enqueueSnackbar(
        "Stay up-to-date! Enable notifications to receive timely updates. Click the lock icon in the address bar and allow notifications.",
        {
          variant: "info",
          autoHideDuration: 5000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
  });

  return unsubscribe;
};

const V2Pages = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const { path } = useRouteMatch();
  const PublicClientDetailView = withNavbarFooter(PublicClientDetail);

  const [drawerDetails, setDrawerDetails] = useState({
    showDrawer: false,
    componentType: "",
    showHeading: true,
  });
  const [notificationDetails, setNotificationDetails] = useState({});

  useEffect(() => {
    console.log({ val: isLoggedIn() });
    if (isLoggedIn()) {
      const unsubscribe = setupNotificationHandling(
        enqueueSnackbar,
        closeSnackbar
      );
      setNotificationDetails({ unsubscribe });
    }
  }, []);

  return (
    <>
      <Provider store={store}>
        <NotificationContext.Provider
          value={[notificationDetails, setNotificationDetails]}
        >
          <UserContext.Provider value={[drawerDetails, setDrawerDetails]}>
            <ThemeProvider theme={theme}>
              {/* <BrowserRouter basename="/v2"> */}
              <BrowserRouter basename="/">
                <ScrollToTop />
                <CustomDrawer />
                <div className={classes.root}>
                  <Switch>
                    {/* <Route path={"/homepage"} component={Homepage} /> */}
                    {/* <Route path={"/hire-developer-details"} component={HireDeveloperDetails} />
                    <Route path={"/hire-team-details"} component={HireTeamDetails} /> */}
                    {/* <Route path={"/apply-job"} component={ApplyJob} />
                    <Route path={"/privacy_policy"} component={PrivacyPolicies} />
                    <Route path={"/terms_conditions"} component={Terms} />
                    <Route path={"/ask-an-expert"} component={Calendy} />
                    <Route path={"/hire-a-team"} component={CalendyTeam} /> */}

                    {/* <Route path="/client/google-auth" component={GoogleAuth} /> */}

                    <Route path={"/client"} component={EmployerRoute} />
                    <Route path={"/consultant"} component={ConsultantRoute} />
                    <Route path={"/agency"} component={AgencyRoute} />
                    <Route path={"/admin"} component={AdminRoute} />
                    <Route
                      path={"/job-detail/:job_id"}
                      component={withNavbarFooter(PublicJobDetail)}
                    />
                    <Route
                      path={"/consultant-detail/:consultant_id"}
                      component={withNavbarFooter(PublicConsultantDetail)}
                    />
                    <Route
                      path={"/agency-detail/:agency_id"}
                      component={withNavbarFooter(PublicAgencyDetail)}
                    />
                    <Route
                      path={"/client-detail/:clientID"}
                      render={(props) => (
                        <PublicClientDetailView
                          clientID={props?.match?.params?.clientID}
                        />
                      )}
                    />
                    <Route
                      path={"/account-types"}
                      component={withNavbarFooter(AccountTypes)}
                    />

                    <Route path={"/components"} component={Components} />
                    <Route render={(props) => <NotFound url={path} />} />
                  </Switch>
                </div>
              </BrowserRouter>
            </ThemeProvider>
          </UserContext.Provider>
        </NotificationContext.Provider>
      </Provider>
    </>
  );
};

export default V2Pages;
