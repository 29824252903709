import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import { Typography, Button, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import JobList from "../common/Cards/job/List";
import JobGrid from "../common/Cards/job/Grid";
import Title from "../../common/Title";
import SearchLoaderModal from "../../common/SearchLoaderModal";

import { jobAdvanceSearch } from "app/v2/services/consultant/common.service";

import { isValidArray, isValidString } from "../../Utils/utilFunctions";
import { messages } from "../../Utils/constants";

const useStyles = makeStyles((theme) => ({
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
    paddingBottom: "44px",
  },
  jobCountAndViewTypesDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  jobPostsCountText: {
    fontWeight: 500,
    fontSize: "12px",
    color: "#717171",
    marginLeft: "25px",
    marginTop: "11px",
    marginBottom: "8px",
  },
  viewTypesDiv: {
    display: "flex",
    columnGap: "16px",
    marginRight: "24px",
  },
  viewButton: {
    padding: "10px 20px 10px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    border: "1px solid #EDEDED",
    backgroundColor: `${theme.palette.common.white} !important`,
    color: "#717171 !important",
    fontWeight: 600,
    fontSize: "14px",
  },
  selectedViewButton: {
    backgroundColor: `#EDEDED !important`,
    color: `${theme.palette.common.black} !important`,
  },
  jobFeedBodyContainer: {
    paddingTop: "234px",
    display: "flex",
    gap: "24px",
    flexWrap: "wrap",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyJobData = [
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 1",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 2",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 3",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 4",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 5",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 6",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 7",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 8",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 9",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 10",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 11",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 12",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 13",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 14",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 15",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 16",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 17",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
];

const JobFeed = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const advanceSearchParams = new URLSearchParams(location.search);
  const searchJobTitle = advanceSearchParams.get("jobTitle") || "";
  const jobsPerScroll = 8;

  const [jobsOffset, setJobsOffset] = useState(0);
  const [reachedLastItem, setReachedLastItem] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  const [jobPostsData, setJobPostsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedViewType, setSelectedViewType] = useState("grid");

  const isGridViewSelected = () => selectedViewType === "grid";

  const onViewButtonClick = () => {
    setSelectedViewType(isGridViewSelected() ? "list" : "grid");
  };

  const fetchJobs = async () => {
    const country = advanceSearchParams.get("country") || "";
    const state = advanceSearchParams.get("state") || "";
    const city = advanceSearchParams.get("city") || "";
    const timezone = advanceSearchParams.get("timezone") || "";
    const experienceID = advanceSearchParams.get("experience") || "";
    const contractTypeID = advanceSearchParams.get("contractType") || "";
    const jobMode = advanceSearchParams.get("jobMode") || "";
    let sortBy = advanceSearchParams.get("sortBy") || "ztoa";
    let skillIDs = advanceSearchParams.get("skills") || "";
    let priceRange = advanceSearchParams.get("priceRange") || "";

    sortBy = sortBy.toLowerCase().replace(/ /g, "");
    skillIDs = isValidString(skillIDs) ? skillIDs.split(",") : [];
    priceRange = isValidString(priceRange) ? priceRange.split(",") : [];

    setDataLoading(true);
    jobAdvanceSearch({
      jobTitle: searchJobTitle,
      sortBy: sortBy, // atoz, ztoa,latest,lowrate,highrate,
      country,
      state,
      city,
      timezone,
      skills: skillIDs, // ["646f1c2f96656ed53aa32478"]
      jobType: contractTypeID, // jobType = contractType(db field), Need to pass the id.
      jobMode, // jobMode = workType( db field) // hybrid , remote , onsite
      workHistory: experienceID, // workHistory = experienceLevelId(db field),Need to pass the id.
      priceRange: priceRange,
      limit: jobsPerScroll,
      offset: jobsOffset,
    })
      .then((res) => {
        console.log({ res });
        const jobDataReceived = res?.data;
        setDataLoading(false);
        if (isValidArray(jobDataReceived)) {
          setJobPostsData((prevJobs) => [...prevJobs, ...jobDataReceived]);
          setTotalCount(res.count);
        } else setReachedLastItem(true);
      })
      .catch((error) => {
        enqueueSnackbar(
          "Something went wrong while fetching jobs,please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };

  useEffect(() => {
    fetchJobs();
  }, [jobsOffset]);

  useEffect(() => {
    const handleScrollFetch = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (
        scrollTop + windowHeight >= documentHeight - 0 &&
        !reachedLastItem &&
        !dataLoading
      ) {
        setJobsOffset((prevOffset) => prevOffset + jobsPerScroll);
      }
    };
    if (!reachedLastItem) {
      window.addEventListener("scroll", handleScrollFetch);
      return () => {
        window.removeEventListener("scroll", handleScrollFetch);
      };
    }
  }, [reachedLastItem, dataLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedViewType]);

  return (
    <div>
      {dataLoading ? (
        <SearchLoaderModal open={dataLoading} />
      ) : (
        <div>
          <div className={classes.headerDiv}>
            <Title
              title={searchJobTitle ? searchJobTitle : "SEARCH RESULTS"}
              topText={`${searchJobTitle ? "SEARCH RESULTS FOR" : ""}`}
            />
            <div className={classes.jobCountAndViewTypesDiv}>
              <Typography className={classes.jobPostsCountText}>
                {totalCount || 0} jobs found
              </Typography>
              <div className={classes.viewTypesDiv}>
                <Button
                  className={clsx([
                    classes.viewButton,
                    isGridViewSelected() && classes.selectedViewButton,
                  ])}
                  onClick={onViewButtonClick}
                >
                  <img src="/grid-view.svg" />
                  Grid View
                </Button>
                <Button
                  className={clsx([
                    classes.viewButton,
                    !isGridViewSelected() && classes.selectedViewButton,
                  ])}
                  onClick={onViewButtonClick}
                >
                  <img src="/list-view.svg" />
                  List View
                </Button>
              </div>
            </div>
          </div>

          <div className={classes.jobFeedBodyContainer}>
            {isValidArray(jobPostsData) ? (
              <>
                {jobPostsData?.map((jobPostData) => (
                  <>
                    {isGridViewSelected() ? (
                      <JobGrid data={jobPostData} />
                    ) : (
                      <JobList data={jobPostData} />
                    )}
                  </>
                ))}
              </>
            ) : (
              <Alert severity="info" className="w-full">
                {" "}
                {messages?.NO_DATA_FOUND}{" "}
              </Alert>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

JobFeed.propTypes = {};

export default JobFeed;
