import React from "react";

import { Img, Text } from "../../common";

import { ArrowDownSVG } from "../../SVGs/ArrowDown";

const Tooltip = (props) => {
  return (
    <>
      <div className={props.className}>
        <ArrowDownSVG  className="rotate-90 mr-[-4px] pr-0" />
        <div className="flex flex-col items-center justify-center w-auto">
          <div className="bg-gray_900 flex flex-col items-center justify-center p-2.5 rounded-[5px] w-auto">
            <Text className="font-normal leading-[180.00%] max-w-[124px] md:max-w-full text-center text-white_A700 text-xs">
              {props?.description}
            </Text>
          </div>
        </div>
      </div>
    </>
  );
};

Tooltip.defaultProps = {
  description: (
    <>
      Round 1: 14 Applicants
      <br />
      Round 2: 2 Applicants
    </>
  ),
};

export default Tooltip;
