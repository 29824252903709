import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppointmentBooking from "./AppointmentBooking";

const useStyles = makeStyles((theme) => ({
  hirePageRoot: {
    display: "flex",
  },
  leftSection: {
    height: "100vh",
    width: "40vw",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  rightSection: {
    width: "100%",
  },
}));

const HireDeveloper = (props) => {
  const { type } = props;
  const classes = useStyles();

  return (
    <div className={classes.hirePageRoot}>
      <div className={classes.leftSection}>
        <Typography>Hire a Developer</Typography>
      </div>
      <div className={classes.rightSection}>
        <AppointmentBooking />
      </div>
    </div>
  );
};

HireDeveloper.propTypes = {};

export default HireDeveloper;
