import React from "react";
export const IconLightPlay = ({
  fillColor = "none",
  className = "",
  ...props
}) => {
  return (
    <>
      <svg 
        fill={fillColor}
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
        width="26" height="26" viewBox="0 0 26 26">
        <path d="M13 2.64062C10.9511 2.64062 8.94823 3.24819 7.24464 4.38649C5.54105 5.5248 4.21327 7.14271 3.42919 9.03564C2.64511 10.9286 2.43996 13.0115 2.83968 15.021C3.2394 17.0305 4.22604 18.8764 5.67482 20.3252C7.1236 21.774 8.96947 22.7606 10.979 23.1603C12.9885 23.56 15.0714 23.3549 16.9644 22.5708C18.8573 21.7867 20.4752 20.459 21.6135 18.7554C22.7518 17.0518 23.3594 15.0489 23.3594 13C23.3562 10.2535 22.2637 7.62044 20.3216 5.67838C18.3796 3.73632 15.7465 2.64385 13 2.64062ZM13 22.1406C11.1922 22.1406 9.42491 21.6045 7.92175 20.6002C6.41858 19.5958 5.247 18.1682 4.55517 16.498C3.86334 14.8277 3.68232 12.9899 4.03501 11.2168C4.38771 9.44365 5.25827 7.81494 6.53661 6.5366C7.81495 5.25826 9.44365 4.3877 11.2168 4.03501C12.9899 3.68232 14.8277 3.86333 16.498 4.55516C18.1682 5.247 19.5958 6.41857 20.6002 7.92174C21.6045 9.42491 22.1406 11.1922 22.1406 13C22.1379 15.4234 21.174 17.7468 19.4604 19.4604C17.7468 21.174 15.4234 22.1379 13 22.1406ZM16.5882 12.4922L11.7132 9.24219C11.6214 9.18092 11.5146 9.14575 11.4044 9.14043C11.2941 9.13511 11.1845 9.15984 11.0872 9.21199C10.9899 9.26413 10.9086 9.34173 10.8519 9.43649C10.7953 9.53125 10.7655 9.63961 10.7656 9.75V16.25C10.7655 16.3604 10.7953 16.4688 10.8519 16.5635C10.9086 16.6583 10.9899 16.7359 11.0872 16.788C11.1845 16.8402 11.2941 16.8649 11.4044 16.8596C11.5146 16.8543 11.6214 16.8191 11.7132 16.7578L16.5882 13.5078C16.672 13.4522 16.7407 13.3768 16.7883 13.2882C16.8359 13.1996 16.8607 13.1006 16.8607 13C16.8607 12.8994 16.8359 12.8004 16.7883 12.7118C16.7407 12.6232 16.672 12.5478 16.5882 12.4922ZM11.9844 15.1115V10.8885L15.1511 13L11.9844 15.1115Z" fill="#8C8C8C"/>
        <path d="M13 2.64062C10.9511 2.64062 8.94823 3.24819 7.24464 4.38649C5.54105 5.5248 4.21327 7.14271 3.42919 9.03564C2.64511 10.9286 2.43996 13.0115 2.83968 15.021C3.2394 17.0305 4.22604 18.8764 5.67482 20.3252C7.1236 21.774 8.96947 22.7606 10.979 23.1603C12.9885 23.56 15.0714 23.3549 16.9644 22.5708C18.8573 21.7867 20.4752 20.459 21.6135 18.7554C22.7518 17.0518 23.3594 15.0489 23.3594 13C23.3562 10.2535 22.2637 7.62044 20.3216 5.67838C18.3796 3.73632 15.7465 2.64385 13 2.64062ZM13 22.1406C11.1922 22.1406 9.42491 21.6045 7.92175 20.6002C6.41858 19.5958 5.247 18.1682 4.55517 16.498C3.86334 14.8277 3.68232 12.9899 4.03501 11.2168C4.38771 9.44365 5.25827 7.81494 6.53661 6.5366C7.81495 5.25826 9.44365 4.3877 11.2168 4.03501C12.9899 3.68232 14.8277 3.86333 16.498 4.55516C18.1682 5.247 19.5958 6.41857 20.6002 7.92174C21.6045 9.42491 22.1406 11.1922 22.1406 13C22.1379 15.4234 21.174 17.7468 19.4604 19.4604C17.7468 21.174 15.4234 22.1379 13 22.1406ZM16.5882 12.4922L11.7132 9.24219C11.6214 9.18092 11.5146 9.14575 11.4044 9.14043C11.2941 9.13511 11.1845 9.15984 11.0872 9.21199C10.9899 9.26413 10.9086 9.34173 10.8519 9.43649C10.7953 9.53125 10.7655 9.63961 10.7656 9.75V16.25C10.7655 16.3604 10.7953 16.4688 10.8519 16.5635C10.9086 16.6583 10.9899 16.7359 11.0872 16.788C11.1845 16.8402 11.2941 16.8649 11.4044 16.8596C11.5146 16.8543 11.6214 16.8191 11.7132 16.7578L16.5882 13.5078C16.672 13.4522 16.7407 13.3768 16.7883 13.2882C16.8359 13.1996 16.8607 13.1006 16.8607 13C16.8607 12.8994 16.8359 12.8004 16.7883 12.7118C16.7407 12.6232 16.672 12.5478 16.5882 12.4922ZM11.9844 15.1115V10.8885L15.1511 13L11.9844 15.1115Z" stroke="#8C8C8C"/>
      </svg>
    </>

  );
};
