import React, { useEffect, useState, useContext } from "react";
import Title from "../../common/Title";
import CustomButton from "app/v2/components/CustomButton";
import { updateTimesheetSettings } from "app/v2/services/client/timesheet.service";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "app/v2";

const CheckBoxTable = ({ activeWorkId, timesheetSettings }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);

  const [clientOptions, setClientOptions] = useState({});

  const [consultantOptions, setConsultantOptions] = useState({
    payAsYouGo: timesheetSettings?.consultant?.payasyougo,
    milestone: timesheetSettings?.consultant?.milestone,
    fixedTerm: timesheetSettings?.consultant?.fixedrate,
  });
  useEffect(() => {
    setClientOptions({
      payAsYouGo: timesheetSettings?.client?.payasyougo,
      milestone: timesheetSettings?.client?.milestone,
      fixedTerm: timesheetSettings?.client?.fixedrate,
    });

    setConsultantOptions({
      payAsYouGo: timesheetSettings?.consultant?.payasyougo,
      milestone: timesheetSettings?.consultant?.milestone,
      fixedTerm: timesheetSettings?.consultant?.fixedrate,
    });
  }, [timesheetSettings]);

  const handleClientCheckboxChange = (option) => {
    setClientOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));

    setConsultantOptions((prevOptions) => ({
      ...prevOptions,
      [option]: false,
    }));

    setUpdateButtonDisabled(false);
  };

  const handleConsultantCheckboxChange = (option) => {
    setConsultantOptions((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
    setUpdateButtonDisabled(false);
  };
  const handleButtonClick = () => {
    const request = {
      id: activeWorkId,
      client: {
        payasyougo: clientOptions?.payAsYouGo,
        milestone: clientOptions?.milestone,
        fixedrate: clientOptions?.fixedTerm,
      },
      consultant: {
        payasyougo: consultantOptions?.payAsYouGo,
        milestone: consultantOptions?.milestone,
        fixedrate: consultantOptions?.fixedTerm,
      },
    };

    const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });
    updateTimesheetSettings(request)
      .then((res) => {
        if (res?.success === true) {
          const key = enqueueSnackbar(res?.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setUpdateButtonDisabled(true);
        } else {
        }
      })
      .catch(() => {
        const key = enqueueSnackbar(
          `Your action was not succesfull. Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };

  useEffect(() => {
    setUpdateButtonDisabled(true);
  }, []);
  return (
    <div>
      <table className=" w-full">
        <thead>
          <tr>
            <th className=" p-2"></th>
            <th className=" p-2">Client</th>
            <th className=" p-2">Consultant</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-base font-semibold p-2">
              <span>Pay As You Go</span>
            </td>
            <td className=" p-2">
              <input
                type="checkbox"
                checked={clientOptions?.payAsYouGo}
                onChange={() => handleClientCheckboxChange("payAsYouGo")}
              />
            </td>
            <td className=" p-2">
              <input
                type="checkbox"
                checked={consultantOptions?.payAsYouGo}
                onChange={() => handleConsultantCheckboxChange("payAsYouGo")}
                disabled={!clientOptions.payAsYouGo} // Disable consultant checkbox if client checkbox is not selected
              />
            </td>
          </tr>

          <tr>
            <td className="text-base font-semibold p-2">
              <span>Milestone</span>
            </td>
            <td className=" p-2">
              <input
                type="checkbox"
                checked={clientOptions?.milestone}
                onChange={() => handleClientCheckboxChange("milestone")}
              />
            </td>
            <td className=" p-2">
              <input
                type="checkbox"
                checked={consultantOptions?.milestone}
                onChange={() => handleConsultantCheckboxChange("milestone")}
                disabled={!clientOptions.milestone}
              />
            </td>
          </tr>
          <tr>
            <td className="text-base font-semibold p-2">
              <span>Fixed Rate</span>
            </td>
            <td className=" p-2">
              <input
                type="checkbox"
                checked={clientOptions?.fixedTerm}
                onChange={() => handleClientCheckboxChange("fixedTerm")}
              />
            </td>
            <td className=" p-2">
              <input
                type="checkbox"
                checked={consultantOptions?.fixedTerm}
                onChange={() => handleConsultantCheckboxChange("fixedTerm")}
                disabled={!clientOptions.fixedTerm}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex justify-center">
        <CustomButton
          type="button1"
          customClasses="text-[16px] font-semibold py-3 px-7 flex gap-x-[8px]"
          onClick={handleButtonClick}
          disabled={updateButtonDisabled}
        >
          Update Timesheet Settings
          <img
            className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
            alt=""
            src="/iconfilledsend.svg"
          />
        </CustomButton>
      </div>
    </div>
  );
};

export default CheckBoxTable;
