import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "configs";
import storageUtil from "../utils/storageUtil";

export const postLogin = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const handleLinkedInAuth = () => {
  // const clientId = "86k0ih4d11opxr";
  const clientId = "86jsh87eujksnc";
  const accountType = getAccountType();
  const redirectUrl =  `${configs.linkedinAuthHome}/${accountType}/linkedin/auth`;
  const oauthurl = "https://www.linkedin.com/oauth/v2/authorization?response_type=code";
  const scope = "r_liteprofile%20r_emailaddress";
  const state = "12345";
  const oauthUrl = `${oauthurl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
  window.open(oauthUrl, '_self');
};

export const loginInfo = async () => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/loginInfo`);
}

export const logout = async () => {
  const user = storageUtil.getItem("user");
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: user,
  });
}

export const postSignUp = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const postSignUpAdmin = async (request, to) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/signup/${to}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const forgotPassword = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/forgot-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const resetPassword = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/reset-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const changePassword = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/update-password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const verifyEmail = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/verify-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getAccountType = () => {
  if (document.location && document.location.pathname) {
    const splitPathName = document.location.pathname.split("/");
    if (splitPathName.length > 1) {
      return splitPathName[1];
    } else {
      return "";
    }
  } else {
    return "";
  }
};
