import React from "react";

import { Img, Text } from "..";
import { IconDot } from "../../SVGs/IconDot";

import VisibilityIcon from "@mui/icons-material/Visibility";


const C7ConsultantDetailPageVThreeButton = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="absolute h-[18px] md:h-[19px] inset-[0] w-full">
          <div className="h-[19px] pb-0"></div>
          {/* <IconDot fillColor="blue" className="absolute h-[15px] left-[0] top-[0] w-[15px]" /> */}
          <VisibilityIcon fontSize="smaller" className="absolute h-[12px] left-[0] top-[4px] w-[12px] fill-blue" />
        </div>
        <Text
          className="absolute font-semibold right-[0] text-blue-700 text-left top-[0] w-[120px]"
        >
          {props?.userjob}
        </Text>
      </div>
    </>
  );
};

C7ConsultantDetailPageVThreeButton.defaultProps = { userjob: "View Job" };

export default C7ConsultantDetailPageVThreeButton;
