import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";

import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import TinyMceEditor from "app/v2/Pages/common/Editor/TinyMceEditor";
import CustomButton from "app/v2/components/CustomButton";
import ConsultantSelect from "./ConsultantSelect";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";

import {
  messages,
  validDocFileMimeTypes,
  validPPTMimeTypes,
} from "app/v2/Pages/Utils/constants";
import { isValidObject, isValidString } from "app/v2/Pages/Utils/utilFunctions";
import FilePreview from "app/v2/Pages/common/FilePreview";

const useStyles = makeStyles((theme) => ({
  projectDetailDiv: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  projectDetailValue: {
    fontSize: "18px",
    fontWeight: 700,
  },
  actionButton: {
    padding: "16px 28px",
    fontSize: "16px",
    fontWeight: 600,
    width: "100%",
    display: "flex",
    columnGap: "10px",
    alignItems: "center",
  },
  cancelButton: {
    border: "1px solid #EDEDED",
  },
  uploadFileDiv: {
    width: "100%",
    height: "101px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  uploadFileButton: {
    padding: "6px 8px",
    border: "1px solid #EDEDED",
    fontSize: "12px",
    color: "#717171 !important",
    fontWeight: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "8px",
  },
  deleteButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
    position: "relative",
    left: "4px",
    bottom: "2px",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const dummyConsultantData = [
  {
    id: 1,
    displayPic: "/user_image.png",
    name: "Lacy Clark 1",
    email: "lacy.clark@gmail.com",
  },
  {
    id: 2,
    displayPic: "/user_image.png",
    name: "Lacy Clark 2",
    email: "lacy.clark@gmail.com",
  },
  {
    id: 3,
    displayPic: "/user_image.png",
    name: "Lacy Clark 3",
    email: "lacy.clark@gmail.com",
  },
];

const SubmitActionDialog = ({
  isResubmitProposal,
  submitDialogOpen,
  setSubmitDialogOpen,
  submitActionLoading,
  submitActionCompleted,
  submitActionError,
}) => {
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {submitActionLoading && "Action in progress"}
        {submitActionError && "Error"}
        {submitActionCompleted &&
          `Proposal ${isResubmitProposal ? "Resubmitted" : "Submitted"}`}
      </div>
    );
  };

  const DialogBody = () => {
    if (submitActionLoading) return <DialogLoadingBody />;
    else if (submitActionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: `Your proposal has been ${
              isResubmitProposal ? "resubmitted" : "submitted"
            }.`,
          }}
        />
      );
    else if (submitActionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={submitDialogOpen}
      setOpen={setSubmitDialogOpen}
      noCloseAction={submitActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

/**
 * @component SubmitProposal- Content/Body-(contains the actions as well) of the submit/resubmit proposal dialog.
 * @param {object} jobData - data of the job for which we submit proposals(consultants and their bid/expected salary value).
 * @param {array} proposalData - data of the proposal for which a resubmit is attempted. In this case we show the elements with prefilled proposal data, else in a fresh submission all the elements are empty and to be filled.
 * Structure: 
 * {
        consultantID: 1,
        displayPic: "user_image.png",
        role: "Senior Developer",
        name: "Lacy Clark 1",
        matchRateType: "High",
        coverLetter: {
          htmlString:
            "<p>s simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>",
        },
        attachment:
          "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx", // on file upload this key's structure will be {file:object,localFileUrl:String}.
        salaryType: "Hourly",
        salaryValue: 3000,
        projectDetails: {
          totalPrice: "$ 0.00",
          serviceFee: "$ 0.00",
          candidateEarnings: "$ 0.00",
        },
        status: "pending",
  * },  
  * @param {function} updateProposalData - function to update existing proposal data in the overview view after resubmision is successful.
 * @param {function} closeParentDialog - Function to close the parent dialog(dialog in which this component is shown).
 */

const SubmitProposal = ({
  jobData,
  proposalData,
  updateProposalData,
  closeParentDialog,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const defaultSelectedConsultantEntry = {
    consultantID: "",
    coverLetter: { htmlString: "" },
    attachment: "", // on file upload this key's structure will be {file:object,localFileUrl:String}.
    salaryType: "Hourly",
    salaryValue: 0.0,
    projectDetails: {
      totalPrice: "0.00",
      serviceFee: "0.00",
      candidateEarnings: "0.00",
    },
  };

  const fileRef = React.createRef();
  const [consultantData, setConsultantData] = useState(dummyConsultantData); // has all received consultant data.
  const [consultantDropdownData, setConsultantDropdownData] =
    useState(dummyConsultantData); // this dropdown consultant data is specific to what data is to be shown in the dropdown at that time(Eg: If a consultant is selected first, while adding another consultant info that consultant should not be shown for new addition).
  const salaryTypes = ["Hourly", "Monthly", "Annually"];
  const [selectedConsultantsDetails, setSelectedConsultantDetails] = useState([
    defaultSelectedConsultantEntry,
  ]); // Storing default values of one consultant initially.
  const [activateSubmitButton, setActivateSubmitButton] = useState(false);
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false);
  const [submitActionLoading, setSubmitActionLoading] = useState(false);
  const [submitActionCompleted, setSubmitActionCompleted] = useState(false);
  const [submitActionError, setSubmitActionError] = useState(false);
  const [isResubmitProposal, setIsResubmitProposal] = useState(false);
  const [hideAddConsultantsButton, setHideAddConsultantsButton] =
    useState(false); // We hide this button in resubmit proposal case(just like update specific proposal).

  const onConsultantDropdownChange = (
    index,
    currentConsultantID,
    prevConsultantID
  ) => {
    onSCInfoChange(index, "consultantID", currentConsultantID);
    setConsultantDropdownData((prevState) => {
      let updatedState = [...prevState];
      updatedState = updatedState?.filter(
        (consultant) => consultant?.id !== currentConsultantID
      ); // removing currently selected consultant in the 'Select Consultant' dropdown.
      if (prevConsultantID) {
        const prevConsultantInfo = consultantData?.find(
          (consultant) => consultant.id === prevConsultantID
        );
        updatedState.push(prevConsultantInfo);
      } // adding back prev selected consultant(if any) in the 'Select Consultant' dropdown.
      return updatedState;
    });
  };
  const getConsultantDataForDropdown = (consultantID, compIndex) => {
    let updatedDropDownData = [
      ...consultantDropdownData,
      consultantID
        ? consultantData?.find((consultant) => consultant?.id === consultantID)
        : null,
    ]; // Adding the current selected consultant(if selection was done else null is added) as well in the dropdown data passed to the dropdown component to render/show the selected consultant.
    updatedDropDownData = updatedDropDownData.filter(Boolean); // returns truth values and removes any false values (specifically the null value that could have been added in above logic).
    return updatedDropDownData;
  };
  const onAddConsultant = () => {
    setSelectedConsultantDetails((prevState) => [
      ...prevState,
      defaultSelectedConsultantEntry,
    ]);
  };
  const onSCInfoChange = (consultantCompIndex, infoType, value) => {
    setSelectedConsultantDetails((prevState) => {
      let updatedState = [...prevState];
      const currentConsultant = updatedState[consultantCompIndex];
      currentConsultant[infoType] = value;
      updatedState[consultantCompIndex] = currentConsultant;
      return updatedState;
    });
  }; // SC - selected consultant, consultantCompIndex - consultant component index

  const onSalaryTypeChange = (consultantCompIndex, value) => {
    onSCInfoChange(consultantCompIndex, "salaryType", value);
  };

  const onSalaryValueChange = (consultantCompIndex, value) => {
    onSCInfoChange(consultantCompIndex, "salaryValue", value);
  };

  const onCoverLetterEditorChange = (consultantCompIndex, value) => {
    // here value is -> {htmlString,text}
    onSCInfoChange(consultantCompIndex, "coverLetter", value);
  };

  const onFileUpload = (consultantCompIndex, event) => {
    const file = event.target.files[0];
    if (file) {
      const localFileUrl = URL.createObjectURL(file);
      const higherFileSize = file.size > 1048576 * 5; // 5 mb
      const isValidDoc = validDocFileMimeTypes.includes(file.type);
      const isValidPPT = validPPTMimeTypes.includes(file.type);
      if (higherFileSize || (!isValidDoc && !isValidPPT)) {
        enqueueSnackbar(
          higherFileSize
            ? messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO
            : messages.UPLOAD_VALID_DOC_WARNING,
          {
            variant: "error",
          }
        );
        return;
      }
      onSCInfoChange(consultantCompIndex, "attachment", {
        file,
        localFileUrl,
      });
    }
  };

  const onDeleteSCInfo = (consultantCompIndex) => {
    const selectedConsultantInfo =
      selectedConsultantsDetails?.[consultantCompIndex];
    const consultantID = selectedConsultantInfo?.consultantID;

    setConsultantDropdownData((prevState) => {
      let updatedState = [...prevState];
      const consultantInfo = consultantData?.find(
        (consultant) => consultant.id === consultantID
      );
      updatedState.push(consultantInfo); // adding back the deleted consultant(if any) in the 'Select Consultant' dropdown.
      return updatedState;
    });

    setSelectedConsultantDetails((prevState) => {
      let updatedState = [...prevState];
      return updatedState?.filter(
        (consultant, index) => index !== consultantCompIndex
      );
    });
  };

  const isLastConsultantComp = (consultantCompIndex, consultantArrayLength) =>
    consultantCompIndex === consultantArrayLength - 1;

  const validateEntries = () => {
    let allEntriesEntered = true; // setting it as true initially, as we iterate below if any consultant's info is not entered this value is set to false;
    for (let index = 0; index < selectedConsultantsDetails?.length; index++) {
      // no validation added for salaryType as it has a default value and will have a selection for sure.
      const consultantInfo = selectedConsultantsDetails[index];
      const validConsultantID = Boolean(consultantInfo?.consultantID);
      const validCoverLetter = consultantInfo?.coverLetter?.text?.length > 100;
      const validAttachment =
        consultantInfo?.attachment?.localFileUrl?.length > 0 ||
        consultantInfo?.attachment?.length > 0;
      const validSalaryValue =
        consultantInfo?.salaryValue !== "" && consultantInfo?.salaryValue >= 0;
      const validConsultantInfo =
        validConsultantID &&
        validCoverLetter &&
        validAttachment &&
        validSalaryValue;
      if (!validConsultantInfo) allEntriesEntered = false;
      // console.log({
      //   validConsultantID,
      //   validCoverLetter,
      //   validAttachment,
      //   validSalaryValue,
      //   index,
      //   consultantInfo,
      // });

      if (!allEntriesEntered) break;
    }
    setActivateSubmitButton(allEntriesEntered);
  };

  const onSubmitProposalClick = () => {
    setSubmitDialogOpen(true);
    setSubmitActionLoading(true);

    // const updatedSCDetails=selectedConsultantsDetails?.map(consultantInfo=>{
    //   let attachmentUrl;

    // // the below element can be the url or an object(if upload of any of those were attempted, structure- {file,localFileUrl,prevFileUrl}).
    // const attachmentUrlOrDetails = consultantInfo?.attachment;

    // const attachmentFile = attachmentUrlOrDetails?.file;

    // }); // add the attachment(s) to s3 upload logic and api integration later.

    setTimeout(() => {
      /** Confirm case */
      setSubmitActionLoading(false);
      setSubmitActionCompleted(true);
      /** Error case */
      // setSubmitActionLoading(false);
      // setSubmitActionError(true);
      // setSubmitActionCompleted(false);
    }, 2000);
  };

  useEffect(() => {
    console.log({ proposalData });
    if (isValidObject(proposalData)) {
      /* Resubmit proposal case */
      const selectedConsultantID = proposalData?.consultantID;
      setConsultantDropdownData((prevState) => {
        let updatedState = [...prevState];
        updatedState = updatedState?.filter(
          (consultant) => consultant?.id !== selectedConsultantID
        ); // removing received selected consultant in the 'Select Consultant' dropdown.
        return updatedState;
      });

      setSelectedConsultantDetails([proposalData]); // will have additional info wrt consultant which is not used here.
      setIsResubmitProposal(true);
    }
  }, [proposalData]);

  useEffect(() => {
    console.log({ selectedConsultantsDetails });
    validateEntries();
  }, [selectedConsultantsDetails]);

  useEffect(() => {
    if (!submitDialogOpen && (submitActionCompleted || submitActionError))
      closeParentDialog(); // close the parent submit proposal dialog after the SubmitActionDialog is closed only after the action is done-completed/error case.
  }, [submitDialogOpen]);

  useEffect(() => {
    if (submitActionCompleted && isResubmitProposal)
      updateProposalData(selectedConsultantsDetails?.[0]); // passing back updated proposal details only if the submit proposal api was successful.
  }, [submitActionCompleted]);

  return (
    <>
      <div className="flex flex-col gap-y-[33px] px-[48px] pb-[48px]">
        {/* Job Overview - start */}
        <div className="text-[24px] font-semibold pt-[30px]">Job Overview</div>
        <div className="flex gap-x-[24px]">
          <img
            className={classes.orgImage}
            alt={`"org_${jobData?._id}_image"`}
            src={jobData?.image ? jobData.image : "/org_placeholder.svg"}
          />
          <div>
            <div className="text-[36px] font-semibold mb-[12px]">
              {jobData?.jobTitle}
            </div>
            <div>
              <span className="mr-[36px] text-[14px] text-[#8C8C8C] font-semibold">
                Job no.
                <span className="text-[#363636] ml-[8px]">
                  {jobData?.jobNo}
                </span>
              </span>
              <span className="text-[14px] text-[#8C8C8C] font-semibold">
                Hiring Manager
                <span className="text-[#363636] ml-[8px]">
                  {jobData?.hiringManager}
                </span>
              </span>
            </div>
          </div>
        </div>
        {/* Job Overview - end */}
        {/* Selected Consultants Details - start */}
        <div
          className={clsx([
            "flex flex-col gap-y-[33px] h-[1185px] overflow-y-scroll pr-[12px]",
            selectedConsultantsDetails?.length > 1 && "h-[1350px]", // increasing height if another consultant is added(to show a bit of the next conusltant section and hence conveying to the user that the div is scrollable).
          ])}
        >
          {selectedConsultantsDetails?.map((selectedConsultantInfo, index) => (
            <>
              <div className="flex flex-col gap-y-[33px]">
                <div className="pl-[24px]">
                  <div className="flex justify-between items-start">
                    <div className="text-[24px] font-semibold mb-[27px]">
                      Select Consultant{" "}
                      {selectedConsultantsDetails?.length > 1
                        ? `${index + 1}`
                        : ""}
                    </div>
                    {selectedConsultantsDetails?.length > 1 ? (
                      <button
                        className={classes.deleteButton}
                        onClick={() => {
                          onDeleteSCInfo(index);
                        }}
                      >
                        <img src="/delete.svg" />
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>

                  <ConsultantSelect
                    consultantCompIndex={index}
                    disabled={isResubmitProposal}
                    placeholder="Choose Consultant"
                    selectedValue={selectedConsultantInfo?.consultantID}
                    data={getConsultantDataForDropdown(
                      selectedConsultantInfo?.consultantID,
                      index
                    )}
                    onChange={onConsultantDropdownChange}
                  />
                </div>
                <div className="pl-[24px]">
                  <div className="text-[24px] font-semibold mb-[32px]">
                    Additional Details
                  </div>

                  <div className="mb-[33px]">
                    <CustomLabel label="cover letter" />
                    <TinyMceEditor
                      id={index}
                      data={selectedConsultantInfo?.coverLetter?.htmlString}
                      setData={onCoverLetterEditorChange}
                      placeholder=""
                      requiredCharCount={100}
                      insufficientCharCountMessage="Cover letter should have atleast 100 characters."
                    />
                  </div>

                  <div>
                    <div className="flex">
                      <CustomLabel label="attachment" />
                      <CustomTooltip
                        title={
                          <>
                            <div>
                              1.{" "}
                              {messages.DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO}
                            </div>
                            <div>
                              2. {messages.DOC_FILE_UPLOAD_EXTENSION_INFO}
                            </div>
                          </>
                        }
                        arrow
                        placement="top"
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </CustomTooltip>
                    </div>
                    <div className={classes.uploadFileDiv}>
                      <input
                        type="file"
                        accept=".doc, .docx, .pdf, .txt, .ppt, .pptx"
                        className="hidden"
                        onChange={(event) => {
                          onFileUpload(index, event);
                        }}
                        ref={fileRef}
                      />
                      <CustomButton
                        type="button2"
                        customClasses={classes.uploadFileButton}
                        onClick={() => fileRef.current.click()}
                      >
                        Drag or upload file <img src="/download.svg" />
                      </CustomButton>
                    </div>
                    <div className="mb-[24px] mt-[8px]">
                      {isValidString(selectedConsultantInfo?.attachment) ||
                      isValidObject(selectedConsultantInfo?.attachment) ? (
                        <FilePreview
                          fileUrlOrContent={selectedConsultantInfo?.attachment}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="pl-[24px]">
                  <div className="text-[24px] font-semibold mb-[27px]">
                    Terms
                  </div>
                  <div className="flex gap-x-[44px] mb-[32px]">
                    <div className="flex-1">
                      <CustomSelect
                        id={index}
                        data={salaryTypes}
                        label="salary type"
                        placeholder={"Select"}
                        onChange={onSalaryTypeChange}
                        defaultSelection={selectedConsultantInfo?.salaryType}
                      />
                    </div>
                    <div className="flex-1">
                      <CustomTextfield
                        id={index}
                        type="number"
                        inputProps={{ step: "0.01", min: 0 }}
                        label="salary value"
                        onChange={onSalaryValueChange}
                        value={selectedConsultantInfo?.salaryValue}
                        customErrorMessage={
                          selectedConsultantInfo?.salaryValue < 0
                            ? "Please enter a value greater than or equal to 0."
                            : ""
                        }
                        startAdornment={"$"}
                      />
                    </div>
                  </div>
                  {/* <div className="flex">
                    <div className={classes.projectDetailDiv}>
                      <CustomLabel label="total price of project" />{" "}
                      <span className={classes.projectDetailValue}>
                        $ {selectedConsultantInfo?.projectDetails?.totalPrice}
                      </span>
                    </div>
                    <div className={classes.projectDetailDiv}>
                      <CustomLabel label="service fee" />{" "}
                      <span className={classes.projectDetailValue}>
                        $ {selectedConsultantInfo?.projectDetails?.serviceFee}
                      </span>
                    </div>
                    <div className={classes.projectDetailDiv}>
                      <CustomLabel label="you'll receive" />{" "}
                      <span className={classes.projectDetailValue}>
                        ${" "}
                        {
                          selectedConsultantInfo?.projectDetails
                            ?.candidateEarnings
                        }
                      </span>
                    </div>
                  </div> */}
                  {/* The business logic of above is not finalised yet so commenting it for now. */}
                </div>
              </div>
              {!isLastConsultantComp(
                index,
                selectedConsultantsDetails?.length
              ) && <hr />}
            </>
          ))}
        </div>
        {/* Selected Consultants Details - end */}

        {/* Actions- start */}
        <div className="flex gap-x-[24px] py-[12px] px-[131px]">
          {/* Hiding add consultant in resubmit proposal case (basically update existing proposal ) */}
          {!isResubmitProposal && (
            <CustomTooltip
              title={
                selectedConsultantsDetails?.length === consultantData?.length &&
                "Cannot add more consultants."
              }
            >
              <div className="flex-1">
                <CustomButton
                  customClasses={clsx([
                    classes.actionButton,
                    classes.cancelButton,
                  ])}
                  onClick={onAddConsultant}
                  disabled={
                    selectedConsultantsDetails?.length ===
                    consultantData?.length
                  }
                >
                  Add Consultant
                </CustomButton>
              </div>
            </CustomTooltip>
          )}

          <CustomTooltip
            title={
              !activateSubmitButton && "Please enter the consultant(s) details."
            }
          >
            <div className="flex-1">
              <CustomButton
                type="button1"
                customClasses={clsx([classes.actionButton])}
                disabled={!activateSubmitButton}
                onClick={onSubmitProposalClick}
              >
                {isResubmitProposal ? "Resubmit" : "Submit"} Proposal
                <img src="/iconfilledsend.svg" />
              </CustomButton>
            </div>
          </CustomTooltip>
        </div>
        {/* Actions- start */}
      </div>
      <SubmitActionDialog
        isResubmitProposal={isResubmitProposal}
        submitDialogOpen={submitDialogOpen}
        setSubmitDialogOpen={setSubmitDialogOpen}
        submitActionLoading={submitActionLoading}
        submitActionCompleted={submitActionCompleted}
        submitActionError={submitActionError}
      />
    </>
  );
};

SubmitProposal.propTypes = {};

export default SubmitProposal;
