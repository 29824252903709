// Services
import { useSnackbar } from "notistack";
// import {
//   candidatesFilter,
//   getCandidateList,
//   getJobList,
//   jobRequestForCandidate,
// } from "../../../services/employerDashboard.service";
// Services

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getConsultantsForFeaturedThunk,
  getConsultantsThunk,
} from "../../../store/features/employer/employerDashboard.thunk";
import { getEmployerInfoThunk } from "../../../store/features/employer/profile-settings/profile-settings.thunk";
import {
  getMyJobsThunk,
  getPostedJobsThunk,
} from "../../../store/features/employer/jobFeed/jobFeed.thunk";

import { Text } from "../../common";

import DashboardHero from "../common/DashboardHero";

import { dashboardHeroObj } from "./DashboardHero.dummy";
import { ArrowRightAlt } from "@mui/icons-material";
import CardCollection from "../../common/CardCollection";
import ConsultantGridCard from "../common/Cards/Grid/Consultant";
import ConsultantListCard from "../common/Cards/List/Consultant";
import AgencyGridCard from "../common/Cards/Grid/Agency";
import AgencyListCard from "../common/Cards/List/Agency";

import { Alert, CircularProgress } from "@mui/material";

import CustomButton from "app/v2/components/CustomButton";
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CallMadeIcon from "@mui/icons-material/CallMade";
import storageUtil from "../../Utils/storageUtil";
import ContentLoader from "react-content-loader";
import CustomSelect from "app/v2/components/filters/CustomSelect";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import CloseIcon from "@mui/icons-material/Close";

const Button = CustomButton;

const useStyles = makeStyles((theme) => ({
  dashboardContainer: {
    minHeight: "calc(100vh - 382px)", // total viewport height minus navbar and footer's heights, minHeight:100% did not work,hence had to set it as pre parent div defined in EmployerRoute.
    display: "flex",
  },
  root: {
    position: 'relative',
  },
  disabledOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.05)', 
    zIndex: 999, 
    pointerEvents: 'auto', 
    backdropFilter: 'blur(5px)', // adds background blur

  },
  autoCompleteDiv: {
    width: "100%",
  },
  joborTeamAutocomplete: {
    marginBottom: "12px",
  },
  jobTeamInfoDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  jobPostText: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#717171",
  },
  manageText: {
    fontSize: "14px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  arrowIcon: {
    width: "15px",
    height: "15px",
  },
  viewButton: {
    padding: "10px 20px 10px 15px",
    width: "126px",
    height: "38px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  selectedViewButton: {
    backgroundColor: "#EDEDED !important",
    border: 0,
    color: theme.palette.secondary.light,
  },
  viewAllButton: {
    border: "1px solid #EDEDED",
    padding: "10px 15px 10px 20px",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    color: `${theme.palette.primary.main} !important`,
    fontSize: "14px",
    fontWeight: 600,
  },
}));

const JobSelector = ({ activeJobs, setSelectedJob, candidates }) => {
  const classes = useStyles();

  const [jobSelectorData, setJobSelectorData] = useState([]); // array of jobNoAndTile values.
  const [selectedJobNoAndTitle, setSelectedJobNoAndTitle] = useState();

  const getActiveJobEntry = (jobNoAndTitle) => {
    return activeJobs?.find(
      (activeJobEntry) => activeJobEntry?.jobNoAndTitle === jobNoAndTitle
    );
  };

  const onChange = (id, value) => {
    setSelectedJobNoAndTitle(value);
    const selectedActiveJob = getActiveJobEntry(value);
    setSelectedJob(selectedActiveJob);
  };

  useEffect(() => {
    // No valid array checks required here as we show this component only if activeJobs array is valid.
    const activeJobsNoAndTitleData = activeJobs.map(
      (job) => job?.jobNoAndTitle
    ); // active jobs- job number and title string data.
    setJobSelectorData(activeJobsNoAndTitleData);
    setSelectedJobNoAndTitle(activeJobsNoAndTitleData[0]);
  }, [activeJobs]);

  return (
    <div className={classes.autoCompleteDiv}>
      <CustomSelect //there was CustomAutocomplete component here ,I cahnged it to CustoSelect as the Custo austocomplete was getting blue borders
        id={"job-posts-autocomplete"}
        data={jobSelectorData}
        defaultSelection={selectedJobNoAndTitle}
        placeholder={"Enter Role"}
        onChange={onChange}
        customClasses={classes.joborTeamAutocomplete}
        // disabled={disableRoleAutocomplete}
      />
      <div className={classes.jobTeamInfoDiv}>
        <Typography className={classes.jobPostText}>
          {candidates?.length} consultants match this job post
        </Typography>
        <Typography className={classes.manageText}>
          Manage Job
          <CallMadeIcon className={classes.arrowIcon} />
        </Typography>
      </div>
    </div>
  );
};

const TeamSelector = ({ teamPostsData }) => {
  const classes = useStyles();
  const onChange = (id, value) => {};

  return (
    <div className={classes.autoCompleteDiv}>
      <CustomSelect //there was CustomAutocomplete component here ,I cahnged it to CustoSelect as the Custo austocomplete was getting blue borders
        id={"team-posts-autocomplete"}
        data={teamPostsData}
        defaultSelection={teamPostsData[0]}
        placeholder={"Enter Role"}
        onChange={onChange}
        customClasses={classes.joborTeamAutocomplete}
        // disabled={disableRoleAutocomplete}
      />
      <div className={classes.jobTeamInfoDiv}>
        {/* <Typography className={classes.jobPostText}>
          2,231 consultants match this job post
        </Typography> */}
        <Typography className={classes.manageText}>
          Manage Team
          <CallMadeIcon className={classes.arrowIcon} />
        </Typography>
      </div>
    </div>
  );
};

const DashboardPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const jobsBuf = useSelector((state) => state?.jobs?.jobs?.data);
  const candidatesBuf = useSelector(
    (state) => state?.clientDashboard.employerDashboardConsultants
  );
  const employerInfoBuf = useSelector(
    (state) => state?.clientProfileInfo.value
  );
  let questionnaireData = storageUtil.getItem("consultant_questionnaire");
  const profile = location?.state;
  const gridCardsData = {
    consultant: new Array(6).fill(ConsultantGridCard),
    agency: new Array(4).fill(AgencyGridCard),
  };
  const listCardsData = {
    consultant: new Array(4).fill(ConsultantListCard),
    agency: new Array(4).fill(AgencyListCard),
  };
  const dummyTeamPostsData = [
    "Team 4: Costco App Development Project",
    "Team 5: Costco App Development Project",
    "Team 6: Costco App Development Project",
  ]; // will get this at global level context or redux store.

  const [isLoading, setIsLoading] = useState(false);
  const [candidates, setCandidates] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  let [postedJobs, setPostedJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [onBoardingId, setOnBoardingId] = useState(
    questionnaireData?.onBoardingId
  );
  const [disabled, setDisabled] = useState(true);

  const [consultantSelectedView, setConsultantSelectedView] = useState("grid");
  const [agencySelectedView, setAgencySelectedView] = useState("grid");
  const [selectedJob, setSelectedJob] = useState(postedJobs[0]);
  const [selectedTeam, setSelectedTeam] = useState(dummyTeamPostsData[0]);
  const [updateApiOfConsultant, setUpdateApiOfConsultan] = useState(false);

  const onConsultantViewClick = (viewType) => {
    setConsultantSelectedView(viewType);
  };

  const onAgencyViewClick = (viewType) => {
    setAgencySelectedView(viewType);
  };

  const isConsultantGridViewSelected = () => {
    return consultantSelectedView === "grid";
  };

  const isAgencyGridViewSelected = () => {
    return agencySelectedView === "grid";
  };

  const isProfileCompleteAndActiveJobsExist = () => {
    return (
      personalData?.clientDetail.isProfileComplete && isValidArray(activeJobs)
    );
  };

  useEffect(() => {
    if (isValidArray(postedJobs)) {
      const activeJobsWithJobNoAndTitle = [];
      postedJobs.forEach((jobEntry) => {
        if (jobEntry?.status === "active") {
          activeJobsWithJobNoAndTitle.push({
            ...jobEntry,
            jobNoAndTitle: `Job ${jobEntry?.jobNo}: ${jobEntry?.jobTitle}`,
          });
        }
      });

      if (isValidArray(activeJobsWithJobNoAndTitle)) {
        setActiveJobs(activeJobsWithJobNoAndTitle);
        setSelectedJob(activeJobsWithJobNoAndTitle[0]);
      }
    }
  }, [postedJobs]);

  useEffect(() => {
    setIsLoading(true);

    if (!employerInfoBuf?.length) {
      dispatch(getEmployerInfoThunk())
        .unwrap()
        .then((res) => {
          if (res) {
            setPersonalData(res?.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          // if (err.message) { }
          // enqueueSnackbar("Problem in fetching data!", {
          //   variant: "error",
          // });

          const key = enqueueSnackbar("Problem in fetching data!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          console.error(err);
          setIsLoading(false);
        });
    } else {
      setPersonalData(employerInfoBuf);
    }

    if (!jobsBuf?.length) {
      // dispatch(getPostedJobsThunk())
      dispatch(getMyJobsThunk())
        .unwrap()
        .then((res) => {
          if (res && isValidArray(res?.data)) {
            setPostedJobs(res.data);
          }
          // setIsLoading(false);
        })
        .catch((err) => {
          // if (err.message) { }
          // enqueueSnackbar("Problem in fetching candidates!", {
          //   variant: "error",
          // });

          const key = enqueueSnackbar("Problem in fetching candidates!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          console.error(err);
          // setIsLoading(false);
        });
    } else {
      setPostedJobs(jobsBuf);
    }

    // if (!candidatesBuf?.length) {
    //   // dispatch(getConsultantsForFeaturedThunk({"limit":4, "offset":0, filter:{"skills": ["React"], "keywords": "Assam"}}))
    //   //edss
    //   dispatch(getConsultantsForFeaturedThunk({ onBoardingId }))
    //     .unwrap()
    //     .then((res) => {
    //       if (res) {
    //         setCandidates(res);
    //       }
    //       setIsLoading(false);
    //     })
    //     .catch((err) => {
    //       // if (err.message) { }
    //       enqueueSnackbar("Problem in fetching candidates!", {
    //         variant: "error",
    //       });
    //       console.error(err);
    //       setIsLoading(false);
    //     });
    // } else {
    //   setCandidates(candidatesBuf);
    // }

    // getCandidateList()
    //   .then((res) => {
    //     if (res) {
    //       setCandidates(res);
    //     }
    //     setIsLoading(false);
    //   })
    //   // .catch((err) => {
    //   //   enqueueSnackbar("Problem in fetching candidates!", {
    //   //     variant: "error",
    //   //   });
    //   //   console.error(err);
    //   //   setIsLoading(false);
    //   // })
    //   ;
    // // }
  }, []);

  useEffect(() => {
    if (!selectedJob?._id && !onBoardingId) return false;

    //edss
    const request = {
      onBoardingId,
      jobId: selectedJob?._id,
    };

    // dispatch(getConsultantsForFeaturedThunk({ onBoardingId }))
    dispatch(getConsultantsForFeaturedThunk(request))
      .unwrap()
      .then((res) => {
        if (res) {
          setCandidates(res);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        // if (err.message) { }
        // enqueueSnackbar("Problem in fetching candidates!", {
        //   variant: "error",
        // });

        const key = enqueueSnackbar("Problem in fetching candidates!", {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
        console.error(err);
        setIsLoading(false);
      });
  }, [selectedJob, updateApiOfConsultant]);

  localStorage.setItem("orgId", personalData?.clientDetail?.orgId);
  localStorage.setItem("roleId", personalData?.clientDetail?.roleId);
  localStorage.setItem("loginId", personalData?.clientDetail?._id);

  localStorage.setItem(
    "planId",
    personalData?.clientDetail?.subscriptionData?.planId ??
      "65f16cdbf5be17c41672bdc1"
  );

  return (
    <>
      {/* {dashboardHeroObj ? ( */}

      {personalData ? (
        <div className={classes.dashboardContainer}>
          <div
            className={`mb-[184px] flex flex-col sm:gap-10 md:gap-10 gap-[54px] items-center justify-center mx-auto px-[45px] md:px-10 sm:px-5 w-full`}
          >
            <DashboardHero
              // DashboardHeroObj={dashboardHeroObj}
              props={personalData}
              // Profile={profile}
              // Profile={{incomplete:!personalData.employer.isProfileComplete}}
            />

            <div className="flex flex-col gap-[33px] items-start justify-center  mx-auto w-full xl:max-w-[1920px] ">
              <Text className="font-semibold text-black-900 w-auto text-[32px] font-600">
                {/* {personalData?.employer.isProfileComplete && */}
                {isProfileCompleteAndActiveJobsExist()
                  ? "Consultants"
                  : "Featured Consultants"}
              </Text>
              {/* {personalData?.employer.isProfileComplete && */}
              {isProfileCompleteAndActiveJobsExist() && (
                <JobSelector
                  activeJobs={activeJobs}
                  setSelectedJob={setSelectedJob}
                  candidates={candidates}
                />
              )}

              <div className="flex sm:flex-col flex-row md:gap-5 items-center justify-between  w-full">
                <div className="flex flex-row gap-4 items-center justify-end w-auto">
                  <Button
                    customClasses={clsx([
                      classes.viewButton,
                      isConsultantGridViewSelected() &&
                        classes.selectedViewButton,
                    ])}
                    onClick={() => {
                      onConsultantViewClick("grid");
                    }}
                  >
                    <img src="/grid-view.svg" className="mt-px mb-0.5 mr-2.5" />
                    <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                      Grid View
                    </div>
                  </Button>
                  <Button
                    customClasses={clsx([
                      classes.viewButton,
                      !isConsultantGridViewSelected() &&
                        classes.selectedViewButton,
                    ])}
                    onClick={() => {
                      onConsultantViewClick("list");
                    }}
                  >
                    <img src="/list-view.svg" className="mt-px mb-0.5 mr-2.5" />
                    <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                      List View
                    </div>
                  </Button>
                </div>
                <Button
                  customClasses={classes.viewAllButton}
                  size="md"
                  type="button2"
                  onClick={() => {
                    // history.push("/client/consultant-feed");
                    selectedJob?._id
                      ? history.push(`/client/${selectedJob?._id}/consultants`)
                      : history.push("/client/consultant-feed");
                  }}
                >
                  <div className="font-semibold leading-[normal] text-[#1e5dff] text-center text-sm">
                    View All
                  </div>
                  <ArrowRightAlt />
                </Button>
              </div>
              <div className="flex flex-col items-center mt-4 justify-start w-full ">
                <div className="flex flex-wrap items-center gap-[24px] justify-start w-full max-w-[1340px] h-fit ">
                  {/* <CardCollection
                          cardsData={gridCardsData?.consultant}
                          viewType="grid"
                        /> */}

                  {!candidates || isLoading ? (
                    <CircularProgress
                      sx={{ display: "flex", margin: "auto", height: "100%" }}
                    />
                  ) : (
                    <>
                      {
                        // candidates?.map((candidate, index) => (
                        //   (candidates?.sort(() => 0.5 - Math.random()).slice(0, 4)).map((candidate, index) => (
                        //     <ConsultantGridCard key={index} props={candidate} />
                        // ))
                        candidates && candidates.length > 0 ? (
                          isConsultantGridViewSelected() ? (
                            candidates?.map((candidate, index) => (
                              <ConsultantGridCard
                                key={index}
                                data={candidate}
                                setUpdateApiOfConsultan={
                                  setUpdateApiOfConsultan
                                }
                                jobID={
                                  isProfileCompleteAndActiveJobsExist()
                                    ? selectedJob?._id
                                    : null
                                }
                              />
                            ))
                          ) : (
                            candidates?.map((candidate, index) => (
                              <ConsultantListCard
                                data={candidate}
                                setUpdateApiOfConsultan={
                                  setUpdateApiOfConsultan
                                }
                                jobID={
                                  isProfileCompleteAndActiveJobsExist()
                                    ? selectedJob?._id
                                    : null
                                }
                              />
                            ))
                          )
                        ) : (
                          <Alert severity="info">
                            There are no matched consultants yet. We'll notify
                            you once we find some.
                          </Alert>
                        )
                      }
                    </>
                  )}

                  {/* {[...(gridCardsData.consultant)]?.map((Card) => (
                          <>{Card}</>
                        ))} */}
                </div>
              </div>
            </div>

            <div className={`flex flex-col gap-[33px] items-start justify-center mx-auto w-full xl:max-w-[1920px] ${classes.root}`}>
      {disabled && <div className={classes.disabledOverlay} />}
              
              
              <Text className="font-semibold text-black-900 w-auto text-[32px] font-600">
                Featured Agencies
              </Text>
              <TeamSelector teamPostsData={dummyTeamPostsData} />
              <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between  w-full">
                <div className="flex flex-row gap-4 items-center justify-end w-auto">
                  <Button
                    customClasses={clsx([
                      classes.viewButton,
                      isAgencyGridViewSelected() && classes.selectedViewButton,
                    ])}
                    onClick={() => {
                      onAgencyViewClick("grid");
                    }}
                  >
                    <img src="/grid-view.svg" className="mt-px mb-0.5 mr-2.5" />
                    <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                      Grid View
                    </div>
                  </Button>
                  <Button
                    customClasses={clsx([
                      classes.viewButton,
                      !isAgencyGridViewSelected() && classes.selectedViewButton,
                    ])}
                    onClick={() => {
                      onAgencyViewClick("list");
                    }}
                  >
                    <img src="/list-view.svg" className="mt-px mb-0.5 mr-2.5" />
                    <div className="font-semibold leading-[normal] text-center text-gray-600 text-sm">
                      List View
                    </div>
                  </Button>
                </div>
                <Button
                  customClasses={classes.viewAllButton}
                  size="md"
                  type="button2"
                  onClick={() => {
                    history.push("/client/agency-feed");
                    // window.scrollTo(0, 0);
                  }}
                >
                  <div className="font-semibold leading-[normal] text-[#1e5dff] text-center text-sm">
                    View All
                  </div>
                  <ArrowRightAlt />
                </Button>
              </div>
              <div className="flex flex-col items-center justify-start w-full">
                <div
                  className={`flex flex-wrap items-center gap-[24px] justify-center w-full max-w-[1340px] h-fit`}
                >
                  {isAgencyGridViewSelected() ? (
                    <CardCollection
                      cardsData={gridCardsData.agency}
                      viewType="grid"
                    />
                  ) : (
                    <CardCollection
                      cardsData={listCardsData.agency}
                      viewType="list"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.dashboardContainer}>
          {/* <CircularProgress
            sx={{ display: "flex", margin: "auto", height: "100%" }}
          /> */}

          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            {/* Define the skeleton structure */}
            <rect x="135" y="40" rx="3" ry="3" width="210" height="43" />
            <rect x="135" y="100" rx="3" ry="3" width="270" height="20" />
            <rect x="135" y="170" rx="3" ry="3" width="320" height="150" />
            <rect x="485" y="170" rx="3" ry="3" width="320" height="150" />
            <rect x="135" y="360" rx="3" ry="3" width="208" height="190" />
            <rect x="365" y="360" rx="3" ry="3" width="208" height="190" />
            <rect x="597" y="360" rx="3" ry="3" width="208" height="190" />
            <rect x="950" y="140" rx="3" ry="3" width="540" height="340" />

            {/* <rect x="0" y="40" rx="3" ry="3" width="200" height="10" />
          <rect x="0" y="60" rx="3" ry="3" width="250" height="10" /> */}
          </ContentLoader>
        </div>
      )}
    </>
  );
};

DashboardPage.propTypes = {};

export default DashboardPage;
