import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import CustomButton from "../../components/CustomButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1600px",
    margin: "auto",
    background: `url(${images.globe})`,
    backgroundSize: "contain",
    backgroundRepeat: "repeat-x",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "7.8rem",
    alignItems: "center",
    [theme.breakpoints.down("700")]: {
      paddingBottom: "4.4rem",
    },
  },
  title: {
    fontSize: "6rem",
    fontWeight: 700,
    textTransform: "uppercase",
    textAlign: "center",
    margin: "7rem 0 2.8rem 0",
    [theme.breakpoints.down("1200")]: {
      fontSize: "4rem",
    },
    [theme.breakpoints.down("700")]: {
      fontSize: "1.6rem",
      margin: "3.7rem 0 1.5rem 0",
    },
  },
  blueText: {
    color: theme.palette.primary.main,
  },
  buttonRoot: {
    width: "21.6rem",
    height: "4.7rem",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    borderRadius: 0,
    whiteSpace: "nowrap",
    margin: "0 auto",
    [theme.breakpoints.down("700")]: {
      width: "10.6rem",
      height: "2.3rem",
      paddingTop: "0.7rem",
      paddingBottom: "0.7rem",
    },
  },
  buttonText: {
    fontSize: theme.typography.button.fontSize,
    fontWeight: theme.typography.button.fontWeight,
    [theme.breakpoints.down("700")]: {
      fontSize: "0.5rem",
      fontWeight: 500,
    },
  },
}));

const GlobalITTalent = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        <span className={classes.blueText}>Global</span> IT Talent
      </Typography>
      <CustomButton
        type="button1"
        customClasses={clsx([classes.buttonRoot, classes.buttonText])}
        onClick={() => {
          history.push("/hire-developer-details");
        }}
      >
        Hire a Developer <TrendingFlatIcon />
      </CustomButton>
    </div>
  );
};

GlobalITTalent.propTypes = {};

export default GlobalITTalent;
