import { localConfigs } from "./local.config.js";
import { prodConfigs } from "./prod.config.js";
import { devConfigs } from "./dev.config.js";
import { stageConfigs } from "./stage.config.js";
import { qaConfigs } from "./qa.config.js";

let configs = devConfigs;
// let configs = localConfigs;

// if (process.env.NODE_ENV === "production") {
if (process.env.REACT_APP_ENV === "production") {
  configs = prodConfigs;
} else if (process.env.REACT_APP_ENV === "qa") {
  configs = qaConfigs;
} else if (process.env.REACT_APP_ENV === "stage") {
  configs = stageConfigs;
} else if (process.env.REACT_APP_ENV === "local") {
  configs = localConfigs;
} else { //if (process.env.REACT_APP_ENV === "development") {
  configs = devConfigs;
}

export default configs;
