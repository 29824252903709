import fetchWrapper from "app/v2/Pages/Utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getConnectionsData = async (userId) => {
  return fetchWrapper(`${configs.APIUrl}/connection/${userId}`, {
    method: "GET", // Use GET method
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const putConnectionsData = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/connection`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};



// export const postConnectionsData = async (request) => {
//   return fetchWrapper(`${configs.APIUrl}/connections`, {
//     method: "POST", // Use GET method
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(request), // Convert the request to a JSON string
//   });
// };

export const deleteConnectionsData = async (userId) => {
  return fetchWrapper(`${configs.APIUrl}/connection/${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    // body: JSON.stringify(request),
    // Convert the request to a JSON string
  });
};

export const UpdateConnectionsData = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/connection`, {
    method: "PUT", // Use GET method
    headers: {
      "Content-Type": "application/json",
    },
     body: request, // Convert the request to a JSON string
  });
};
