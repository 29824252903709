import "@adyen/adyen-web/dist/adyen.css";
import { Box, Card } from "@mui/material";
import configs from "configs";
import React, { useEffect } from "react";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const ButtonWrapper = ({
  amount,
  currency,
  showSpinner,
  onAuthorised,
  onPaymentError,
}) => {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && <div className="spinner" />}
      <PayPalButtons
        style={{ layout: "vertical" }}
        disabled={false}
        forceReRender={[amount, currency]}
        fundingSource={undefined}
        createOrder={(data, actions) => {
          return actions.order
            .create({
              purchase_units: [
                {
                  amount: {
                    currency_code: currency,
                    value: amount,
                  },
                },
              ],
            })
            .then((orderId) => {
              // Your code here after create the order
              return orderId;
            });
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function (orderData) {
            if (orderData.status === "COMPLETED") {
              onAuthorised({ reference: orderData.id });
            } else {
              onPaymentError(
                new Error("Transaction not completed. Check admin")
              );
            }
            // Your code here after capture the order
            console.log("orderData", orderData);
          });
        }}
        onError={(err) => {
          console.error(err);
          onPaymentError(err);
        }}
      />
    </>
  );
};

const CheckoutComponent = ({
  checkoutTitle,
  amount,
  onAuthorised,
  onPaymentError,
}) => {
  // This values are the props in the UI
  const currency = "USD";
  // Custom component to wrap the PayPalButtons and handle currency changes
  return (
    <Card
      sx={{ paddingTop: "5rem" , pb: 6, boxShadow:"none", minHeight: "68vh" }}
    >
      <PayPalScriptProvider
        options={{
          "client-id":
            configs.paymentConfig.clientKey,
          components: "buttons",
          currency: "USD",
        }}
      >
        <Box sx={{ margin: "auto", width: "auto" }}>
          <ButtonWrapper
            onAuthorised={onAuthorised}
            onPaymentError={onPaymentError}
            amount={amount}
            currency={currency}
            showSpinner={false}
          />
        </Box>
      </PayPalScriptProvider>
    </Card>
  );
};

export default CheckoutComponent;
