import React, { useState } from "react";

import { Img, Line, List, Text } from "../../common";

import QRCode from "qrcode.react";

import CustomDropdownButton from "../../common/CustomDropdownButton";
import CustomListItem from "../../common/CustomListItem";

import { scrollToTop } from "app/common/utils/stringUtil";

// images
import img_iconlightproject from "../../images/img_iconlightproject.svg";
import img_arrowleft from "../../images/img_arrowleft.svg";
import img_ionlogolinkedin from "../../images/img_ionlogolinkedin.svg";
import img_facebook from "../../images/img_facebook.svg";
import img_icon_light_arrow_top from "../../images/img_icon_light_arrow_top.svg";
import img_iconlightarrow from "../../images/img_iconlightarrow.svg";
import img_arrowup from "../../images/img_arrowup.svg";
import img_iconlightcheckmark from "../../images/img_iconlightcheckmark.svg";
import img_volume_blue_gray_900 from "../../images/img_volume_blue_gray_900.svg";
import img_iconlightbookmark from "../../images/img_iconlightbookmark.svg";
import img_icon_light_dropdown_bottom_blue_gray_900 from "../../images/img_icon_light_dropdown_bottom_blue_gray_900.svg";
import img_iconlightdropdown_blue_gray_900 from "../../images/img_iconlightdropdown_blue_gray_900.svg";

import { IconLightUser } from "../../SVGs/IconLightUser";
import { IconLightFile } from "../../SVGs/IconLightFile";
import { IconLightGrid } from "../../SVGs/IconLightGrid";
import { IconLightAward } from "../../SVGs/IconLightAward";
import { IconLightPlay } from "../../SVGs/IconLightPlay";

import CustomButton from "app/v2/components/CustomButton";
import { IconDot } from "../../SVGs/IconDot";
import tw from "twin.macro";
const Button = CustomButton;

const JobStatusBadge = tw(
  Text
)`flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm`;

const ConsultantDetailPage = ({props}) => {
  return (
    <>
      <div className="flex md:flex-col flex-row md:gap-5 items-start justify-evenly w-full max-w-[1440px] mx-auto">
        <div className="bg-white border-gray-200 border-r border-solid flex flex-col gap-9 h-[810px] md:h-auto items-start justify-start px-12 md:px-5 py-6 w-[368px] sticky top-[100px]">
          <div className="flex flex-row gap-2.5 items-start justify-start py-2.5 w-auto">
            <Img src={img_arrowleft} className="h-6 w-6" alt="arrowleft" />
            <Text className="font-semibold text-gray-900 text-sm w-auto">
              Back{" "}
            </Text>
          </div>
          <div className="flex flex-col items-start justify-start w-full">
            <div className="flex flex-col gap-[7px] items-start justify-start w-full">
              <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                Job Post to Preview
              </Text>
              <Button
                className="bg-white border border-gray-200 border-solid cursor-pointer flex items-center justify-center p-2.5 w-full"
                rightIcon={
                  <div className="h-[18px] mb-px ml-[17px] w-[18px] rounded-sm">
                    <Img
                      src={img_icon_light_dropdown_bottom_blue_gray_900}
                      className="rounded-sm"
                      alt="icon"
                    />
                  </div>
                }
              >
                <div className="font-semibold text-gray-900 text-left text-sm">
                  Job 27: Frontend Developer for Contractual Project
                </div>
              </Button>
              <CustomDropdownButton
                className="h-[19px] pb-1 relative w-[140px] sm:w-full"
                userjob="View Job"
              />
            </div>
          </div>
          <Line className="bg-gray-200 h-px w-full" />
          <CustomListItem
            className="flex flex-col gap-2 items-start justify-start w-full"
            sections={[
              {
                title: "Consultant Overview",
                id: "consultant",
                icon: <IconLightUser />,
              },
              { title: "Skills", id: "skills", icon: <IconLightFile /> },
              {
                title: "Work History & Industry",
                id: "workhistory",
                icon: <IconLightGrid />,
              },
              {
                title: "Education & Certification",
                id: "education",
                icon: <IconLightAward />,
              },
              {
                title: "Video Resume",
                id: "videoresume",
                icon: <IconLightPlay />,
              },
            ]}
          />
        </div>

        <div className="flex flex-1 flex-col gap-9 items-start justify-start max-w-[1072px] p-12 md:px-5 w-full">
          <div
            id="consultant"
            className="flex md:flex-col flex-row gap-12 items-start justify-start w-full"
          >
            <div className="flex flex-col gap-6 items-start justify-start w-auto">
              <Img
                src={props.profile_picture}
                className="h-[233px] md:h-auto object-cover w-[201px] sm:w-full"
                alt="image_alt"
              />
              <div className="flex flex-col gap-4 items-start justify-start w-full">
                <div className="flex flex-row gap-3 items-center justify-start w-[201px]">
                  <Text className="font-semibold text-gray-600 text-xs uppercase w-auto">
                    Social proof
                  </Text>
                  <Img
                    src={img_ionlogolinkedin}
                    className="h-6 w-6"
                    alt="ionlogolinkedin"
                  />
                  <div className="flex flex-col h-6 items-center justify-start p-0.5 w-6">
                    <div className="flex flex-col h-5 items-center justify-start w-5">
                      <div className="flex flex-col h-5 items-center justify-start w-5">
                        <Img
                          src={img_facebook}
                          className="h-5 w-5"
                          alt="facebook"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Line className="bg-gray-200 h-px w-full" />
                <div className="border border-gray-300 border-solid flex flex-col gap-3 items-center justify-center p-4 w-[201px]">
                  <Text className="font-medium text-base text-center text-gray-900 uppercase w-auto">
                    QR Share
                  </Text>
                  <QRCode
                    value={"https://dev.findpro.ai/consultant/" + props._id}
                    className="h-[89px] w-[89px]"
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-1 flex-col gap-6 items-start justify-start w-full">
              <div className="flex flex-row gap-6 items-start justify-between w-full">
                <div className="flex flex-col gap-0.5 items-start justify-center w-[245px]">
                  <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-auto">
                    {props.first_name} {props.last_name}
                  </Text>
                  <Text className="font-normal font-poppins text-center text-gray-600 text-xs w-auto">
                    {props.lastSeen}
                  </Text>
                </div>

                {props?.status === "active" || props?.status === "Available" ? (
                  <JobStatusBadge className="bg-green-50">
                    <IconDot
                      fillColor="#14C042"
                      className="mt-0.5 mb-[3px] mr-[5px]"
                    />
                    <div className="font-bold text-[#14C042] text-left text-xs">
                      Available
                    </div>
                  </JobStatusBadge>
                ) : props?.status === "inactive" ? (
                  <JobStatusBadge className="bg-gray-50">
                    <IconDot
                      fillColor="gray"
                      className="mt-0.5 mb-[3px] mr-[5px]"
                    />
                    <div className="font-bold text-gray-600 text-left text-xs">
                      Inactive
                    </div>
                  </JobStatusBadge>
                ) : (
                  <>Error</>
                )}
              </div>
              <div className="flex flex-col gap-9 items-start justify-start w-full">
                <div className="flex md:flex-col flex-row gap-[19px] items-center justify-start w-full">
                  <Text className="bg-gray-50 font-bold h-14 px-4 flex items-center justify-center  text-blue-700 text-center text-sm">
                    {props.match_rate}% Match Rate
                  </Text>
                  <Button
                    type="button1"
                    customClasses="bg-gray-900 cursor-pointer flex h-14 items-center justify-center min-w-[192px] px-4 py-3"
                  >
                    Invite to Apply
                    <div className=" w-3.5 ml-2.5 bg-white">
                      <Img
                        src={img_arrowleft}
                        style={{ transform: "rotate(135deg)", fill: "#FF0000" }}
                        alt="icon"
                      />
                    </div>
                    {/* </div> */}
                  </Button>
                  <Button
                    type="button2"
                    customClasses="
                        border border-gray-200 border-solid 
                        h-14 font-semibold leading-[normal] min-w-[124px] sm:px-5 px-4 py-3 text-base text-gray-900"
                  >
                    Connect
                  </Button>
                  <Button
                    type="button2"
                    customClasses="border border-gray-200 border-solid flex h-14 items-center justify-center p-2.5 w-10"
                  >
                    <Img
                      src={img_iconlightbookmark}
                      className="h-5"
                      alt="icon"
                    />
                  </Button>
                  <Button
                    type="button2"
                    customClasses="border border-gray-200 border-solid flex h-14 items-center justify-center p-2.5 w-10"
                  >
                    <Img
                      src={img_volume_blue_gray_900}
                      className="h-5"
                      alt="icon"
                    />
                  </Button>
                </div>
                <div className="flex md:flex-col flex-row gap-4 items-start justify-start w-full">
                  <div className="flex flex-col gap-0.5 items-start justify-center w-[130px]">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      Hourly Rate
                    </Text>
                    <Text className="font-normal text-base text-gray-900 w-full">
                      ${props.profile_info.profile_summary.rate}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-[130px]">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      Level
                    </Text>
                    <Text className="font-normal text-base text-gray-900 w-full">
                      {props.level}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-[130px]">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      availability
                    </Text>
                    <Text className="font-normal text-base text-gray-900 w-full">
                      {props.profile_info.profile_summary.availability}
                      /wk
                    </Text>
                  </div>
                  <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      location
                    </Text>
                    <Text className="font-normal text-base text-gray-900 w-full">
                      {props.personal_info.city}, {props.personal_info.country}
                    </Text>
                  </div>
                </div>
                <Text className="font-normal leading-[160.00%] max-w-[727px] md:max-w-full text-gray-600 text-sm">
                  {props.profile_info.selfIntro}
                </Text>
              </div>
              <div className="flex flex-row gap-6 items-start justify-start w-auto">
                <Button customClasses="bg-white border-b border-gray-200 border-solid cursor-pointer flex items-center justify-center min-w-[152px] pb-1">
                  <Img
                    src={img_iconlightcheckmark}
                    className="mb-px mr-2.5"
                    alt="icon"
                  />
                  <div className="font-semibold leading-[normal] text-center text-gray-900 text-xs">
                    Video Resume Available
                  </div>
                </Button>

                <Button className="bg-white border-b border-gray-200 border-solid cursor-pointer flex items-center justify-center min-w-[152px] pb-1">
                  <Img
                    src={img_iconlightcheckmark}
                    className="mb-px mr-2.5"
                    alt="icon"
                  />
                  <div className="font-semibold leading-[normal] text-center text-gray-900 text-xs">
                    Background Checked
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full" />

          <div
            id="skills"
            className="flex flex-col gap-6 items-start justify-center sm:px-5 px-6 w-full"
          >
            <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">
              Skills
            </Text>
            <div className="flex flex-col items-start justify-start w-full">
              <div className="bg-gray-100 flex flex-col items-start justify-start w-full">
                <div className="border-b border-gray-200 border-solid flex flex-row gap-2.5 items-center justify-between sm:px-5 px-6 py-4 w-full">
                  <div className="flex flex-col gap-1.5 items-start justify-start w-auto">
                    <div className="flex flex-row gap-3 items-center justify-start w-auto">
                      <Text className="font-semibold text-base text-black_900 w-auto">
                        Backend Development
                      </Text>
                      <Text className="bg-gray-100 font-semibold justify-center px-2 py-0.5 rounded-sm text-center text-gray-600 text-sm uppercase w-auto">
                        24
                      </Text>
                    </div>
                    <Text className="font-semibold text-gray-500 text-xs uppercase w-auto">
                      overall: expert
                    </Text>
                  </div>
                  <Img src={img_arrowup} className="h-6 w-6" alt="arrowup" />
                </div>

                <List
                  className="sm:flex-col flex-row gap-1 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-start p-6 sm:px-5 w-full"
                  orientation="horizontal"
                >
                  {props?.profile_info?.skills?.map((skill, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-2 justify-center min-h-[auto] w-full"
                    >
                      <div className="flex flex-1 flex-col h-12 md:h-auto items-start justify-center px-2 py-3 w-full">
                        <Text className="font-semibold text-black_900 text-sm w-auto">
                          {" "}
                          {skill.skill}{" "}
                        </Text>
                      </div>
                      <div className="flex flex-1 flex-col h-12 md:h-auto items-start justify-center px-2 py-3 w-full">
                        <Text className="font-semibold text-gray-500 text-sm w-auto">
                          {" "}
                          {skill.level}{" "}
                        </Text>
                      </div>
                    </div>
                  ))}
                </List>
              </div>
              <List
                className="flex-col gap-px grid items-center w-full"
                orientation="vertical"
              >
                <div className="flex flex-1 flex-row gap-2.5 items-center justify-between p-4 w-full">
                  <div className="flex flex-col gap-1.5 items-start justify-center w-auto">
                    <div className="flex flex-row gap-3 items-center justify-start w-auto">
                      <Text className="font-semibold text-base text-black_900 w-auto">
                        Team/People Management
                      </Text>
                      <Text className="bg-gray-100 font-semibold justify-center px-2 py-0.5 rounded-sm text-center text-gray-600 text-sm uppercase w-auto">
                        2
                      </Text>
                    </div>
                    <Text className="font-semibold text-gray-500 text-xs uppercase w-auto">
                      overall: junior
                    </Text>
                  </div>
                  <Img
                    src={img_iconlightdropdown_blue_gray_900}
                    className="h-6 w-6"
                    alt="iconlightdropdo"
                  />
                </div>
                <div className="flex flex-1 flex-row gap-2.5 items-center justify-between p-4 w-full">
                  <div className="flex flex-col gap-1.5 items-start justify-center w-auto">
                    <div className="flex flex-row gap-3 items-center justify-start w-auto">
                      <Text className="font-semibold text-base text-black_900 w-auto">
                        Business Development
                      </Text>
                      <Text className="bg-gray-100 font-semibold justify-center px-2 py-0.5 rounded-sm text-center text-gray-600 text-sm uppercase w-auto">
                        2
                      </Text>
                    </div>
                    <Text className="font-semibold text-gray-500 text-xs uppercase w-auto">
                      overall: beginner
                    </Text>
                  </div>
                  <Img
                    src={img_iconlightdropdown_blue_gray_900}
                    className="h-6 w-6"
                    alt="iconlightdropdo"
                  />
                </div>
                <div className="flex flex-1 flex-row gap-2.5 items-center justify-between p-4 w-full">
                  <div className="flex flex-col gap-1.5 items-start justify-center w-auto">
                    <div className="flex flex-row gap-3 items-center justify-start w-auto">
                      <Text className="font-semibold text-base text-black_900 w-auto">
                        Frontend Development
                      </Text>
                      <Text className="bg-gray-100 font-semibold justify-center px-2 py-0.5 rounded-sm text-center text-gray-600 text-sm uppercase w-auto">
                        3
                      </Text>
                    </div>
                    <Text className="font-semibold text-gray-500 text-xs uppercase w-auto">
                      overall: beginner
                    </Text>
                  </div>
                  <Img
                    src={img_iconlightdropdown_blue_gray_900}
                    className="h-6 w-6"
                    alt="iconlightdropdo"
                  />
                </div>
              </List>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full" />
          <div
            id="workhistory"
            className="flex flex-col gap-6 items-start justify-center w-full relative mr-[-40]"
          >
            <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-full">
              Work History & Industry
            </Text>
            <List
              className="sm:flex-col flex-row gap-6 grid md:grid-cols-1 grid-cols-2 justify-start w-full"
              orientation="horizontal"
            >
              {props?.profile_info?.experiences?.map((experience, index) => (
                <div
                  key={index}
                  className="border border-gray-600 border-solid flex flex-1 flex-col gap-6 items-start justify-start p-6 sm:px-5 w-full"
                >
                  <Text className="font-semibold text-gray-900 text-lg w-full">
                    {experience?.projectName}
                  </Text>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      client
                    </Text>
                    <Text className="font-bold text-base text-gray-900 w-full">
                      {experience?.companyName}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      project brief
                    </Text>
                    <Text className="font-normal leading-[160.00%] max-w-[428px] md:max-w-full text-base text-gray-600">
                      {experience?.projectDesc}
                    </Text>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-[428px] sm:w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      position
                    </Text>
                    <Text className="font-normal text-base text-gray-900 w-full">
                      {experience?.position}
                    </Text>
                  </div>
                  <div className="flex sm:flex-col flex-row gap-2 items-start justify-start w-full">
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                        project duration
                      </Text>
                      <Text className="font-normal text-base text-gray-900 w-full">
                        {experience?.startDate} - {experience?.endDate}
                      </Text>
                    </div>
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                        industry
                      </Text>
                      <Text className="font-normal text-base text-gray-900 w-full">
                        {experience?.industryType}
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                    <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                      skills
                    </Text>
                    <div className="flex flex-wrap flex-row gap-3 items-start justify-start w-auto">
                      {experience?.skills.map((skill, index) => (
                        <Text
                          key={index}
                          className="bg-gray-200 font-bold justify-center px-2 py-0.5 rounded-sm text-gray-900 text-center text-xs w-auto"
                        >
                          {skill}
                        </Text>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              <div className="absolute translate-y-1/2  right-[0] h-14 inset-y-[0] my-auto ">
                <Button
                  className="bg-white border border-gray-600 border-solid flex h-14 inset-y-[0] items-center justify-center my-auto p-[13px] rounded-[50%] w-14 mr-[-40px]"
                  shape="icbCircleBorder28"
                  size="icbPaddingAll13"
                  variant="icbOutlineGray600"
                >
                  <Img
                    src={img_iconlightarrow}
                    className="h-[30px]"
                    alt="iconlightarrow"
                  />
                </Button>
              </div>
            </List>
          </div>
          <Line className="bg-gray-300 h-px w-full" />
          <div
            id="education"
            className="flex flex-col gap-6 items-start justify-center w-full"
          >
            <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-full">
              Education & Certification
            </Text>
            <div className="flex flex-col gap-6 items-start justify-start p-4 w-full">
              <div className="flex flex-col gap-4 items-start justify-start w-auto md:w-full">
                <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto">
                  Educational History
                </Text>
                <List
                  className="flex-col gap-4 grid items-center w-full"
                  orientation="vertical"
                >
                  {props?.profile_info?.educations?.map((education, index) => (
                    <div
                      key={index}
                      className="border-gray-600 border-l-2 border-solid flex flex-1 flex-col items-center justify-start max-w-[858px] sm:pl-5 pl-6 w-full"
                    >
                      <div className="flex flex-col gap-2 items-start justify-start w-full">
                        <Text className="font-bold text-base text-gray-900 w-full">
                          {education.education}
                        </Text>
                        <div className="flex sm:flex-col flex-row gap-2 items-center justify-start w-auto sm:w-full">
                          <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                            {education.university}
                          </Text>
                          <div className="bg-gray-600 h-2 rounded-[50%] w-2"></div>
                          <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                            {education.course}
                          </Text>
                        </div>
                        <Text className="font-normal text-gray-600 text-xs w-auto">
                          {education.startDate} - {education.endDate}
                        </Text>
                      </div>
                    </div>
                  ))}
                  <div className="border-gray-600 border-l-2 border-solid flex flex-1 flex-col items-center justify-start max-w-[858px] sm:pl-5 pl-6 w-full">
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <Text className="font-bold text-base text-gray-900 w-full">
                        Bachelor’s Diploma
                      </Text>
                      <div className="flex sm:flex-col flex-row gap-2 items-center justify-start w-auto sm:w-full">
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                          Mt. Albany University
                        </Text>
                        <div className="bg-gray-600 h-2 rounded-[50%] w-2"></div>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                          Information & Technology
                        </Text>
                      </div>
                      <Text className="font-normal text-gray-600 text-xs w-auto">
                        May 2022 - May 2022
                      </Text>
                    </div>
                  </div>
                </List>
              </div>
              <div className="flex flex-col gap-3.5 items-start justify-start w-full">
                <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">
                  Certification
                </Text>
                {props?.certificates?.map((certificate, index) => (
                  <div
                    key={index}
                    className="border-gray-600 border-l-2 border-solid flex flex-col items-center justify-start sm:pl-5 pl-6 w-full"
                  >
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <Text className="font-bold text-base text-gray-900 w-full">
                        {certificate.name}
                      </Text>
                      <div className="flex sm:flex-col flex-row gap-2 items-center justify-start w-auto sm:w-full">
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                          {certificate.organization}
                        </Text>
                        <div className="bg-gray-600 h-2 rounded-[50%] w-2"></div>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                          {certificate.course}
                        </Text>
                      </div>
                      <Text className="font-normal text-gray-600 text-xs w-auto">
                        Issued {certificate.issuedOn}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-3.5 items-start justify-start w-full">
                <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">
                  Language Proficiency
                </Text>
                {props?.languages?.map((language, index) => (
                  <div
                    key={index}
                    className="border-gray-600 border-l-2 border-solid flex flex-col items-center justify-start sm:pl-5 pl-6 w-full"
                  >
                    <div className="flex flex-col gap-2 items-start justify-start w-full">
                      <Text className="font-bold text-base text-gray-900 w-full">
                        {language.name}
                      </Text>
                      <div className="flex sm:flex-col flex-row gap-2 items-center justify-start w-auto sm:w-full">
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                          {language.Institute}
                        </Text>
                      </div>
                      <Text className="font-normal text-gray-600 text-xs w-auto">
                        Issued {language.issuedOn}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full" />
          <div className="flex flex-col gap-6 items-start justify-center w-full relative">
            <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-full">
              Testimonials
            </Text>
            <div className="flex md:flex-col flex-row gap-6 items-start justify-start w-full">
              {props?.testimonials?.map((testimonial, index) => (
                <div
                  key={index}
                  className="border border-gray-600 border-solid flex flex-1 flex-col gap-2 items-start justify-start p-6 sm:px-5 w-full"
                >
                  <Text className="font-normal leading-[160.00%] max-w-[428px] md:max-w-full text-base text-gray-600">
                    {testimonial.text}
                  </Text>
                  <Text className="font-semibold text-gray-900 text-lg w-full">
                    {testimonial.name}
                  </Text>
                  <Text className="font-semibold text-gray-900 text-sm w-auto">
                    {testimonial.project}
                  </Text>
                  <Text className="font-semibold text-gray-500 text-sm uppercase w-auto">
                    {testimonial.role} - {testimonial.company}
                  </Text>
                </div>
              ))}
            </div>
            <div className="absolute translate-y-1/2  right-[0] h-14 inset-y-[0] my-auto ">
              <Button
                className="bg-white border border-gray-600 border-solid flex h-14 inset-y-[0] items-center justify-center my-auto p-[13px] rounded-[50%] w-14 mr-[-40px]"
                shape="icbCircleBorder28"
                size="icbPaddingAll13"
                variant="icbOutlineGray600"
              >
                <Img
                  src={img_iconlightarrow}
                  className="h-[30px]"
                  alt="iconlightarrow"
                />
              </Button>
            </div>
          </div>
          <Line className="bg-gray-300 h-px w-full" />

          <div className="flex flex-col gap-6 items-start justify-center max-w-[976px] mt-4 w-full">
            <div className="flex flex-row gap-2 items-start justify-start w-auto">
              <Img
                src={img_iconlightproject}
                className="h-[38px] w-[38px]"
                alt="iconlightprojec"
              />
              <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-auto">
                Resumes
              </Text>
            </div>
            <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto">
              Resume Document
            </Text>
            <div className="flex flex-col h-[357px] md:h-auto items-start justify-start w-full">
              <div className="bg-gray-900 flex md:flex-col flex-row md:gap-5 items-start justify-start pt-[9px] px-[9px] w-full">
                <iframe
                  src={
                    "https://docs.google.com/viewer?url=" +
                    props?.profile_info?.resume +
                    "&embedded=true"
                  }
                  allowFullScreen
                  height="357px"
                  width="100%"
                >
                  {/* 872 */}
                </iframe>
              </div>
            </div>
          </div>
          <div
            id="videoresume"
            className="flex flex-col gap-6 items-start justify-center w-full"
          >
            <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto">
              Video Resume
            </Text>
            <div className="bg-gray-900 h-[433px] relative w-[92%] md:w-full">
              {/* <video controls>
                                <source src="https://youtu.be/IXoMDwh4Cq8" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> */}
              <iframe
                className="video-player w-[100%] h-full"
                src={props?.profile_info?.videoResume}
                title="Video Player"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <Button
            className="bg-transparent cursor-pointer flex items-center justify-center min-w-[114px] pb-1"
            leftIcon={
              <Img
                src={img_icon_light_arrow_top}
                className="mt-px mb-[5px] mr-2.5"
              />
            }
            onClick={() => {
              scrollToTop();
            }}
          >
            <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
              Back to top
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

ConsultantDetailPage.propTypes = {};

export default ConsultantDetailPage;
