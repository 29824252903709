import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 600,
    textTransform: "uppercase",
    marginBottom: "12px",
  },
}));

const CustomLabel = (props) => {
  const classes = useStyles();
  const { label, customClasses } = props;
  return (
    <label className={clsx([customClasses, classes.labelRoot])}>{label}</label>
  );
};

CustomLabel.propTypes = {};

export default CustomLabel;
