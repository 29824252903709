import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, FormHelperText } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import dayjs from "dayjs";

import InfoIcon from "@mui/icons-material/Info";

import CustomLabel from "app/v2/components/filters/CustomLabel";
import CustomTooltip from "app/v2/components/CustomTooltip";
import { isTimeStringValidFormat } from "../Utils/utilFunctions";
import {
  TWELVE_HOUR_TIME_FORMAT,
  TWENTY_FOUR_HOUR_TIME_FORMAT,
} from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  timePicker: {
    width: "100% !important",
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        fontSize: "16px",
      },
    },
  },
  timePickerError: {
    "& .MuiInputBase-root": {
      border: "1px solid red",
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid red",
      },
    },
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
}));

/**
 * @description
 * A custom time range picker that allows us to select time range.
 * Can pass start,end values to be rendered in the time range by  default when the component loads.Works as expected if start and end is received in the format 'hh:mm AM/PM', else no values are shown in the pickers.
 * Validations such as invalid values, start should be before end, and end should be after start are handled.
 * */

const CustomTimeRangePicker = ({
  label,
  ampm,
  start,
  end,
  onChange,
  noStartEndComparison = false,
  disabled
}) => {
  const expectedTimeFormat = ampm
    ? TWELVE_HOUR_TIME_FORMAT
    : TWENTY_FOUR_HOUR_TIME_FORMAT;
  const classes = useStyles();

  const [startTimeDateObject, setStartTimeDateObject] = useState(null);
  const [formattedStartTime, setFormattedStartTime] = useState();
  const [startTimeErrorMessage, setStartTimeErrorMessage] = useState();

  const [endTimeDateObject, setEndTimeDateObject] = useState(null);
  const [formattedEndTime, setFormattedEndTime] = useState();
  const [endTimeErrorMessage, setEndTimeErrorMessage] = useState();
  const [anyOnChangeCalled, setAnyOnChangeCalled] = useState(false);

  const onStartTimeChange = (
    startTimeDOReceived,
    endTimeDOReceived,
    callParentOnChange = true
  ) => {
    let formattedStartTime = startTimeDOReceived?.format(expectedTimeFormat);
    formattedStartTime =
      formattedStartTime === "Invalid Date" ? "" : formattedStartTime;
    let endTimeDO, startTimeErrorMessage, invalidDateCondition; // DO - date object

    endTimeDO = endTimeDOReceived ?? dayjs(null);
    invalidDateCondition = !startTimeDOReceived?.isValid(); // invalid date check.

    if (!noStartEndComparison)
      invalidDateCondition =
        invalidDateCondition ||
        startTimeDOReceived?.isAfter(endTimeDO) ||
        startTimeDOReceived?.isSame(endTimeDO); // Check if start time is greater than or equal to end time.

    if (invalidDateCondition) {
      startTimeErrorMessage = "Please set a valid start time";
      if (!noStartEndComparison)
        startTimeErrorMessage += " that is before the end time";
      startTimeErrorMessage += ".";
    } else {
      startTimeErrorMessage = null;
      if (endTimeDO?.isValid()) setEndTimeErrorMessage(null);
    }

    setFormattedStartTime(formattedStartTime);
    setStartTimeDateObject(startTimeDOReceived);
    setStartTimeErrorMessage(startTimeErrorMessage);

    if (callParentOnChange) onChange(formattedStartTime, formattedEndTime);
  };

  const onEndTimeChange = (
    startTimeDOReceived,
    endTimeDOReceived,
    callParentOnChange = true
  ) => {
    let formattedEndTime = endTimeDOReceived?.format(expectedTimeFormat);
    formattedEndTime =
      formattedEndTime === "Invalid Date" ? "" : formattedEndTime;
    let startTimeDO, endTimeErrorMessage, invalidDateCondition; // DO - date object

    startTimeDO = startTimeDOReceived ?? dayjs(null);
    invalidDateCondition = !endTimeDOReceived?.isValid(); // invalid date check.

    if (!noStartEndComparison) {
      invalidDateCondition =
        invalidDateCondition ||
        endTimeDOReceived?.isBefore(startTimeDO) ||
        endTimeDOReceived?.isSame(startTimeDO); // Check if end time is less than or equal to start time.
    }

    if (invalidDateCondition) {
      endTimeErrorMessage = "Please set a valid end time";
      if (!noStartEndComparison)
        endTimeErrorMessage += " that is after the start time";
      endTimeErrorMessage += ".";
    } else {
      endTimeErrorMessage = null;

      if (startTimeDO?.isValid()) setStartTimeErrorMessage(null);
    }

    setFormattedEndTime(formattedEndTime);
    setEndTimeDateObject(endTimeDOReceived);
    setEndTimeErrorMessage(endTimeErrorMessage);

    if (callParentOnChange) onChange(formattedStartTime, formattedEndTime);
  };

  useEffect(() => {
    // Added below !anyOnChangeCalled logic to call its inner logic only on initial load when we recieve start,end values. Later when any of the time pickers are changed we dont have to execute this again.
    if (!anyOnChangeCalled) {
      let startTimeDO, endTimeDO;
      if (isTimeStringValidFormat(start, expectedTimeFormat))
        startTimeDO = dayjs(start, expectedTimeFormat, true);

      if (isTimeStringValidFormat(end, expectedTimeFormat))
        endTimeDO = dayjs(end, expectedTimeFormat, true);

      if (startTimeDO) onStartTimeChange(startTimeDO, endTimeDO, false);
      if (endTimeDO) onEndTimeChange(startTimeDO, endTimeDO, false);
    }
  }, [start, end]);

  return (
    <div>
      <div className="flex gap-x-[8px] align-center">
        <CustomLabel label={label ?? "time"} />
        {!ampm ? (
          <CustomTooltip
            title={
              "Time selection follows the 24-hour format(e.g., 14:30 instead of 2:30 PM)"
            }
            arrow
            placement="top"
          >
            <InfoIcon className={classes.infoIcon} />
          </CustomTooltip>
        ) : null}
      </div>
      <div className="flex justify-between gap-x-[8px]">
        <div className="flex-1">
          <CustomLabel label="start" />
          <FormControl error={startTimeErrorMessage} className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  ampm={ampm}
                  value={startTimeDateObject}
                  onChange={(value) => {
                    if (!anyOnChangeCalled) setAnyOnChangeCalled(true);
                    onStartTimeChange(value, endTimeDateObject);
                  }}
                  className={clsx([
                    classes.timePicker,
                    startTimeErrorMessage && classes.timePickerError,
                    "w-full",
                  ])}
                  disabled={disabled}
                />
              </DemoContainer>
            </LocalizationProvider>
            {startTimeErrorMessage && (
              <FormHelperText className="ml-0">
                {startTimeErrorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="flex-1">
          <CustomLabel label="end" />
          <FormControl error={endTimeErrorMessage} className="w-full">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["TimePicker"]}>
                <TimePicker
                  ampm={ampm}
                  value={endTimeDateObject}
                  onChange={(value) => {
                    if (!anyOnChangeCalled) setAnyOnChangeCalled(true);
                    onEndTimeChange(startTimeDateObject, value);
                  }}
                  className={clsx([
                    classes.timePicker,
                    endTimeErrorMessage && classes.timePickerError,
                    "w-full",
                  ])}
                  disabled={disabled}

                />
              </DemoContainer>
            </LocalizationProvider>
            {endTimeErrorMessage && (
              <FormHelperText className="ml-0">
                {endTimeErrorMessage}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      </div>
    </div>
  );
};

CustomTimeRangePicker.propTypes = {};

export default CustomTimeRangePicker;
