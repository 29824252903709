import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import dayjs from "dayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomButton from "../../components/CustomButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

const useStyles = makeStyles((theme) => ({
  bookingRoot: {
    fontFamily: "Montserrat !important",
  },
  overviewAndConfirmDiv: {
    display: "flex",
    columnGap: "245px",
  },
  overViewDiv: {},

  detailsDiv: {
    display: "flex",
    textTransform: "capitalize",
    justifyContent: "space-between",
    fontWeight: 700,
    fontSize: "1rem",
    columnGap: "43px",
  },
  overViewTitle: {
    fontWeight: "400",
    fontSize: "1rem",
    marginBottom: "24px",
  },
  confirmButton: {
    width: "232px",
    padding: "17px 60px",
    background:
      "radial-gradient(90.3% 642.78% at -3.23% -68.18%, #1C7CFD 0%, #1A53ED 100%)",
  },
}));

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const EmptyComponent = () => <></>;

const AppointmentBooking = (props) => {
  const classes = useStyles();
  const CustomActions = React.forwardRef((props, ref) => <></>);
  const todayDate = new Date();
  const [bookingDetails, setBookingDetails] = useState({
    startTime: "12:00pm",
    endTime: "12:30pm",
  });

  const onDateChange = (value) => {
    const updatedDate = new Date(value.$d);
    updateBookingDetails(updatedDate);
    // console.log({ value, updatedDate });
  };

  const updateBookingDetails = (dateReceived) => {
    const year = dateReceived.getFullYear();
    const date = dateReceived.getDate();
    const dayValue = dateReceived.getDay();
    const dayName = dayNames[dayValue];
    let monthValue = dateReceived.getMonth();
    const monthName = monthNames[monthValue];

    if (monthValue.length === 1) monthValue = `0${monthValue}`;
    setBookingDetails((prevState) => ({
      ...prevState,
      dayValue,
      dayName,
      monthValue,
      monthName,
      year,
      entireDate: `${monthName} ${date} ${year}`,
      entireDateNumbers: `${year}-${monthValue}-${date}`,
    }));
  };

  useEffect(() => {
    console.log({ todayDate });
    updateBookingDetails(todayDate);
  }, []);

  useEffect(() => {
    console.log({ bookingDetails });
  }, [bookingDetails]);

  const slots = {
    toolbar: EmptyComponent,
    actionBar: EmptyComponent,
  }; // Added the empty component as a custom toolbar/actionbar to get rid of existing toolbar and action bar.
  return (
    <div className={classes.bookingRoot}>
      <div className={classes.overviewAndConfirmDiv}>
        <div>
          <Typography class={classes.overViewTitle}>
            Appointment Overview
          </Typography>
          <div className={classes.detailsDiv}>
            <span>{bookingDetails?.entireDate}</span>
            <span>{bookingDetails?.dayName}</span>
            <span>
              {bookingDetails?.startTime} - {bookingDetails?.endTime}
            </span>
          </div>
        </div>
        <Button className={classes.confirmButton}>Confirm</Button>
      </div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDatePicker
          disablePast={true}
          onChange={onDateChange}
          defaultValue={dayjs(bookingDetails?.entireDateNumbers)}
          slots={slots}
          sx={{
            "& .MuiDateCalendar-root": {
              width: "fit-content",
            }, // calendar root
            "& .MuiPickersDay-root,& .MuiPickersCalendarHeader-label,& .MuiTypography-root":
              {
                fontFamily: "Montserrat !important",
                fontSize: "18px",
              }, // each date,month year, day name
            "& .MuiPickersDay-root, & .MuiIconButton-root": {
              borderRadius: 0,
            }, // each date,arrows
            "& .MuiPickersDay-root.Mui-selected": {
              backgroundColor: "black",
            },
            "& .MuiPickersCalendarHeader-label": {
              fontFamily: "Montserrat !important",
              fontWeight: 600,
            }, // month and year
            "& .MuiTypography-root": {}, // day name
            "& .MuiDayCalendar-header,& .MuiDayCalendar-weekContainer": {
              columnGap: "38px",
            }, // days container,dates containers
          }}
          className={classes.datePickerRoot}
        />
      </LocalizationProvider>
    </div>
  );
};

AppointmentBooking.propTypes = {};

export default AppointmentBooking;
