import cardGroup from "./cardGroup.png";
import publisher1 from "./publisher1.png";
import publisher2 from "./publisher2.png";
import publisher3 from "./publisher3.png";
import publisher4 from "./publisher4.png";
import feature1 from "./feature1.png";
import feature2 from "./feature2.png";
import feature3 from "./feature3.png";
import ut_employer from "./ut_employer.png";
import ut_employer_feature1 from "./ut_employer_feature1.png";
import ut_employer_feature2 from "./ut_employer_feature2.png";
import ut_employer_feature3 from "./ut_employer_feature3.png";
import ut_consultant from "./ut_consultant.png";
import ut_consultant_feature1 from "./ut_consultant_feature1.png";
import ut_consultant_feature2 from "./ut_consultant_feature2.png";
import ut_consultant_feature3 from "./ut_consultant_feature3.png";
import tech1 from "./tech1.svg";
import tech2 from "./tech2.png";
import tech3 from "./tech3.png";
import tech4 from "./tech4.png";
import tech5 from "./tech5.png";
import tech6 from "./tech6.png";
import tech7 from "./tech7.png";
import tech8 from "./tech8.png";
import tech9 from "./tech9.png";
import tech10 from "./tech10.png";
import tech11 from "./tech11.png";
import tech12 from "./tech12.png";
import add_feature1 from "./add_feature1.png";
import add_feature2 from "./add_feature2.png";
import add_feature3 from "./add_feature3.png";
import add_feature4 from "./add_feature4.png";
import globe from "./globe.png";
import client_review from "./client_review.png";
import client1 from "./client1.png";
import client2 from "./client2.png";
import client3 from "./client3.png";
import client4 from "./client4.png";
import client5 from "./client5.png";
import client6 from "./client6.png";
import client7 from "./client7.png";
import client8 from "./client8.png";
import client9 from "./client9.png";
import client10 from "./client10.png";
import client11 from "./client11.png";
import client12 from "./client12.png";
import client13 from "./client13.png";
import client14 from "./client14.png";
import client15 from "./client15.png";

const images = {
  cardGroup,
  publishers: [publisher1, publisher2, publisher3, publisher4],
  features: [feature1, feature2, feature3],
  userTypes: {
    employers: {
      main: ut_employer,
      features: [
        ut_employer_feature1,
        ut_employer_feature2,
        ut_employer_feature3,
      ],
    },
    consultants: {
      main: ut_consultant,
      features: [
        ut_consultant_feature1,
        ut_consultant_feature2,
        ut_consultant_feature3,
      ],
    },
  },
  tech: [
    [tech1, tech2, tech3, tech4, tech5, tech6],
    [tech7, tech8, tech9, tech10, tech11, tech12],
  ],
  addFeatures: [add_feature1, add_feature2, add_feature3, add_feature4],
  globe,
  client_reviews: [client_review],

  clientele: [
    [client1, client2, client3, client4, client5, client6],
    [
      client7,
      client8,
      client9,
      client10,
      client11,
      client12,
      client13,
      client14,
      client15,
    ],
  ],
};
export default images;
