import React from "react";
import tw from "twin.macro";

import { Text } from "../../common";

const TextSection = (props) => {
  const TextContainer = tw.div`bg-white flex flex-col sm:gap-10 md:gap-10 gap-[133px] items-center justify-center mx-auto md:px-10 sm:px-5 w-full`;
  const ContentContainer = tw.div`flex flex-col gap-6 items-start justify-center max-w-[890px] w-full`;
  const JobContentContainer = tw(ContentContainer)``;
  const CompanyContentContainer = tw(ContentContainer)`p-9 w-full bg-[#F7F7F7]`;
  const TitleText = tw(
    Text
  )`font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full`;
  const DescriptionText = tw(
    Text
  )`font-normal leading-[160.00%] text-base text-gray-600`;
  const CompanyDescriptionText = tw(
    DescriptionText
  )`font-normal leading-[136.00%]`;

  return (
    <>
      <TextContainer>
        {props?.type === "job" && (
          <JobContentContainer>
            <DescriptionText>
              <>{props?.description}</>
            </DescriptionText>
          </JobContentContainer>
        )}
        {props?.type === "company" && (
          <CompanyContentContainer className={`bg-${props?.bg}`}>
            <CompanyDescriptionText>
              <>{props?.description}</>
            </CompanyDescriptionText>
          </CompanyContentContainer>
        )}
      </TextContainer>
    </>
  );
};

TextSection.defaultProps = {
  type: "company",
  title: "About Company",
  description: (
    <>
      Description 1
      <br />
      Description 2
    </>
  ),
  bg: "#333333",
  // bg:"transparent"
};

export default TextSection;
