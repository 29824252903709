import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import "./customverticalstepper.scss";

// const steps = [
//   {
//     label: "Select campaign settings",
//     description: `For each ad campaign that you create, you can control how much
//               you're willing to spend on clicks and conversions, which networks
//               and geographical locations you want your ads to show on, and more.`,
//   },
//   {
//     label: "Create an ad group",
//     description:
//       "An ad group contains one or more ads which target a shared set of keywords.",
//   },
//   {
//     label: "Create an ad",
//     description: `Try out different ad text to see what brings in the most customers,
//               and learn how to enhance your ads using features like ad extensions.
//               If you run into any problems with your ads, find out how to tell if
//               they're running and how to resolve approval issues.`,
//   },
// ];

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#717171",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
  activeStepLabel: {
    color: theme.palette.common.black,
    fontWeight: "700 !important",
    fontSize: "14px !important",
  },
  completedStepLabel: {
    color: "#E0E0E0 !important",
    fontWeight: "700 !important",
    fontSize: "14px !important",
  },
  labelDiv: {
    position: "relative",
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
  },
  leftLabelDiv: {
    position: "absolute",
    left: "-149px",
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
  },
  rightLabelDiv: {
    color: "inherit",
    fontWeight: "inherit",
    fontSize: "inherit",
  },
  continueBackButtonsDiv: {
    padding: "10px",
  },
  stepsCompletedText: {
    fontWeight: "700",
    fontSize: "14px",
  },
}));

export default function CustomVerticalStepper({ stepperData }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className="stepperContainer"
      >
        {stepperData?.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              classes={{
                root: classes.stepLabel,
                active: classes.activeStepLabel,
                completed: classes.completedStepLabel,
                disabled: classes.stepLabel,
              }}
              className="stepLabelContainer"
            >
              <div className={classes.labelDiv}>
                <div className={classes.leftLabelDiv}>
                  {step?.leftLabel} {step?.leftLabelIcon}
                </div>
                <div className={classes.rightLabelDiv}>
                  {step?.rightLabel} {step?.rightlabelIcon}
                </div>
              </div>
            </StepLabel>
            <StepContent>
              <Typography>{step?.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>{step?.componentData?.component}</div>
                <div className={classes.continueBackButtonsDiv}>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === stepperData?.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, color: "white" }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === stepperData?.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography className={classes.stepsCompletedText}>
            All steps completed - you&apos;re finished
          </Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1, color: "white" }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
