import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "../../Utils/configs";
import { getAccountType } from "../../Utils/utilFunctions";

export const handleLinkedInAuth = () => {
  const clientId = "86k0ih4d11opxr";
  const accountType = getAccountType();
  const redirectUrl = `${configs.linkedinAuthHome}/${accountType}/linkedin/auth`;
  const oauthurl =
    "https://www.linkedin.com/oauth/v2/authorization?response_type=code";
  const scope = "r_liteprofile%20r_emailaddress";
  const state = "12345";
  const oauthUrl = `${oauthurl}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
  window.open(oauthUrl, "_self");
};

export const postSignUp = async (request) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const postSignUpAdmin = async (request, to) => {
  const accountType = getAccountType();
  return fetchWrapper(`${configs.APIUrl}/${accountType}/signup/${to}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
