import React from "react";
import PropTypes from "prop-types";

const DialogTitle = ({ title }) => {
  return <div className="text-[16px] font-semibold text-center">{title}</div>;
};

DialogTitle.propTypes = {};

export default DialogTitle;
