import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import CustomTabs from "../../components/CustomTabs";
import CustomButton from "../../components/CustomButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "103rem",
    margin: "auto",
    padding: "0 4.9rem",
    marginBottom: "7.5rem",
    [theme.breakpoints.down("600")]: {
      padding: "0 2.6rem",
      marginBottom: "3.7rem",
    },
  },
  tabPanelRoot: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    columnGap: "180px",
    marginTop: "10rem",
    [theme.breakpoints.down("1600")]: {
      columnGap: "100px",
    },
    [theme.breakpoints.down("1400")]: {
      columnGap: "70px",
      marginTop: "6rem",
    },
    [theme.breakpoints.down("1200")]: {
      columnGap: "40px",
    },
    [theme.breakpoints.down("1000")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("600")]: {
      marginTop: "3.2rem",
    },
  },
  image: {
    maxHeight: "44.3rem",
    maxWidth: "59.5rem",
    [theme.breakpoints.down("1000")]: {
      display: "none",
    },
  },
  textContainer: {
    maxWidth: "732px",
  },
  heading: {
    // marginTop: "2.25rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("1400")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("1200")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("1100")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("600")]: {
      marginBottom: "0.5rem",
    },
  },
  description: {
    fontSize: "25px",
    fontWeight: 500,
    marginBottom: "28px",
    [theme.breakpoints.down("600")]: {
      fontSize: "16px",
    },
  },
  featureList: {
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    rowGap: "1.125rem",
    marginBottom: "51px",
    [theme.breakpoints.down("600")]: {
      marginBottom: "2.6rem",
    },
  },
  listItem: {
    display: "flex",
  },
  listItemText: {
    [theme.breakpoints.down("600")]: {
      fontSize: "0.8rem",
    },
  },
  listItemIcon: {
    marginTop: "4px",
    marginRight: "41px",
  },
  buttonRoot: {
    borderRadius: 0,
    width: "32rem",
    height: "5.5rem",
    [theme.breakpoints.down("1000")]: {
      width: "100%",
    },
    [theme.breakpoints.down("600")]: {
      height: "3.4rem",
    },
  },
  buttonText: {
    fontSize: "1.5rem",
    fontWeight: 500,
    [theme.breakpoints.down("600")]: {
      fontSize: "1rem",
    },
  },
  icon: {
    marginLeft: "0.8rem",
  },
}));

const UserTypes = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const userTypeImages = images.userTypes;
  const employerImages = userTypeImages.employers;
  const consultantImages = userTypeImages.consultants;
  const userTypesData = {
    employers: {
      title: "Discover the best tech talent, remotely",
      description:
        "Findpro's top-tier specialists are pre-vetted and ready to meet your needs, no matter the specialization",
      image: employerImages.main,
      featuresData: [
        {
          text: "Share your needs: We listen and match you with the best talent.",
          icon: employerImages.features[2],
        },
        {
          text: "Get top candidates: Choose from a curated list of pre-vetted developers.",
          icon: employerImages.features[1],
        },
        {
          text: "Start building with ease: Interview, trial, and hire the perfect candidate.",
          icon: employerImages.features[0],
        },
      ],
      buttonText: "Hire a Developer",
      buttonOnClick: () => {
        history.push("/hire-developer-details");
      },
    },
    consultants: {
      title: "Consulting without borders with FindPro",
      image: consultantImages.main,
      featuresData: [
        {
          text: "Craft your profile: Create an attractive profile to showcase your skills and experience.",
          icon: consultantImages.features[2],
        },
        {
          text: "Ace our tests and interviews: Prove your skills through our tests and interviews to stand out.",
          icon: consultantImages.features[1],
        },
        {
          text: "Secure your dream job: Get matched with top opportunities and secure your dream job.",
          icon: consultantImages.features[0],
        },
      ],
      buttonText: "Apply a job",
      buttonOnClick: () => {
        history.push("/candidate/sign-up");
      },
    },
  };
  const UserType = ({ type }) => {
    const data = userTypesData[`${type}`];
    const isConsultant = type === "consultants";
    return (
      <div className={classes.tabPanelRoot}>
        <div className={classes.image}>
          <img src={data.image} alt={`user-type-${type}-image`} />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="h2" className={classes.heading}>
            {data.title}
          </Typography>
          {data.description && (
            <Typography className={classes.description}>
              {data.description}
            </Typography>
          )}
          <ul className={classes.featureList}>
            {data.featuresData.map((feature) => (
              <div className={classes.listItem}>
                <div className={classes.listItemIcon}>
                  <img src={feature.icon} alt={`${feature.text}-icon`} />{" "}
                </div>
                <Typography variant="body1" className={classes.listItemText}>
                  {feature.text}
                </Typography>
              </div>
            ))}
          </ul>
          <CustomButton
            type="button1"
            customClasses={clsx([classes.buttonRoot, classes.buttonText])}
            onClick={data.buttonOnClick}
          >
            {data.buttonText}
            <TrendingFlatIcon className={classes.icon} />
          </CustomButton>
        </div>
      </div>
    );
  };
  const tabsData = [
    {
      label: "For employers",
      component: <UserType type="employers" />,
    },
    {
      label: "For consultants",
      component: <UserType type="consultants" />,
    },
  ];
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  return (
    <div className={classes.root}>
      <CustomTabs
        tabsData={tabsData}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
      />
    </div>
  );
};

UserTypes.propTypes = {};

export default UserTypes;
