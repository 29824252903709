import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import moment from "moment";
import FilePreview from "../../common/FilePreview";

import configs from "../../Utils/configs";

import { Typography, Button, Chip, Alert, Tooltip } from "@mui/material";
import QRCode from "qrcode.react";
import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import img_iconlightcheckmark from "app/v2/Pages/images/img_iconlightcheckmark.svg";
import img_icon_light_arrow_top from "app/v2/Pages/images/img_icon_light_arrow_top.svg";
import { isValidArray, scrollToTop } from "app/v2/Pages/Utils/utilFunctions";
import { messages, URL_PATTERN } from "app/v2/Pages/Utils/constants";
import SkillsPicker from "../../common/SkillsPicker";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { NoProfileImageSVG } from "../../SVGs/NoProfile";
import {
  getExperience,
  getContractTypeLevel,
} from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  qrCodeDiv: {
    padding: "16px",
    border: "1px solid #E0E0E0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  workDetailType: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  chipRoot: {
    padding: "0px 8px",
    fontSize: "12px",
    fontWeight: 700,
    border: 0,
    backgroundColor: "#EDEDED !important",
  },
  chipLabel: {
    padding: 0,
  },
  sectionSubHeading: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  skillDiv: {
    // flex: "0 0 25%",
    flex: "0 0 44%",
    display: "flex",
    columnGap: "8px",
    // margin: "12px 0",
    alignItems: "center",
    height: "fit-content",
  },
  socialIcon: {
    minWidth: "24px",
    width: "24px !important",
    height: "24px !important",
    cursor: "pointer",
  },

  testimonyCard: {
    minWidth: "452px",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    padding: "24px",
    border: "1px solid #717171",
  },
  userImage: {
    width: "200px",
    height: "200px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginBottom: "24px",
  },
}));

const DetailText = ({ type, value }) => {
  const classes = useStyles();
  return (
    <div className="flex-1">
      <div className="font-semibold text-[#717171 12px] uppercase">
        {type ?? ""}
      </div>
      <div className="text-[16px]">{value ?? "-"}</div>
    </div>
  );
};

const Status = ({ type }) => {
  const classes = useStyles();
  const statusTypeInfo = {
    available: {
      text: "Available",
      textColor: "#14C042",
      divColor: "#EBFFF0",
    },
    // invited: {
    //   text: "Invited you to apply",
    //   textColor: "#1E5DFF",
    //   divColor: "#F4F8FF",
    // },
    // notSelected: {
    //   text: "Not Selected",
    //   textColor: "#D80027",
    //   divColor: "#FFECEA",
    // },
    // testSent: {
    //   text: "Assessment Test Sent",
    //   textColor: "#FBBC05",
    //   divColor: "#FFF5D9",
    //   icon: <ErrorOutlineRoundedIcon className={classes.testSentIcon} />,
    // },
  };
  const statusInfo = statusTypeInfo[type];
  return (
    <>
      {statusInfo ? (
        <div
          style={{ backgroundColor: statusInfo?.divColor }}
          className="flex gap-x-[5px] items-center px-[8px]"
        >
          {statusInfo?.icon ?? <IconDot fillColor={statusInfo?.textColor} />}
          <Typography
            style={{
              color: statusInfo?.textColor,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {statusInfo?.text}
          </Typography>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const SectionTitleAndIcon = ({ title, iconPath }) => (
  <div className="text-[24px] font-semibold pb-[24px] flex items-center gap-x-[8px]">
    <img src={iconPath} /> {title}
  </div>
);

const Overview = ({
  overviewData,
  socialLinks,
  activityDetails,
  videoResumeAvailable,
  experiences,
  contractTypes,
  consultantId,
}) => {
  const classes = useStyles();

  const salaryDetails = overviewData?.salaryDetails;
  const salaryType = salaryDetails?.type;
  const salaryRange = salaryDetails?.range;
  const salaryValue = salaryRange
    ? `Min- $ ${salaryRange?.min ?? "-"}, Max - $ ${salaryRange?.max ?? "-"}`
    : `$ ${salaryDetails?.fixedRate ?? "-"}`;
  let linkedinURL, facebookURL, websiteURL;
  if (socialLinks?.linkedin) {
    linkedinURL = URL_PATTERN.test(socialLinks?.linkedin)
      ? socialLinks?.linkedin
      : "https://" + socialLinks?.linkedin;
  }
  if (socialLinks?.facebook) {
    facebookURL = URL_PATTERN.test(socialLinks?.facebook)
      ? socialLinks?.facebook
      : "https://" + socialLinks?.facebook;
  }
  if (socialLinks?.website) {
    websiteURL = URL_PATTERN.test(socialLinks?.website)
      ? socialLinks?.website
      : "https://" + socialLinks?.website;
  }

  let activeTime = activityDetails?.activeTime
    ? moment(activityDetails?.activeTime).fromNow()
    : "-";
  activeTime = activeTime === "Invalid date" ? "-" : activeTime;

  return (
    <div className="flex gap-x-[48px] py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <div>
        <img
          className={classes.userImage}
          alt={`org_${overviewData?._id}_image`}
          src={
            overviewData?.displayPicture
              ? overviewData.displayPicture
              : "/user_placeholder.svg"
          }
        />

        <div className="flex gap-x-[12px] items-center mb-[16px]">
          <div className="text-[12px] font-semibold text-[#717171]">
            SOCIAL PROOF
          </div>
          {linkedinURL || facebookURL || websiteURL ? (
            <div className="flex gap-x-[12px]">
              {linkedinURL && (
                <a href={linkedinURL ?? ""} target="_blank">
                  <img
                    src="/linkedin-logo-black.png"
                    className={classes.socialIcon}
                  />
                </a>
              )}
              {facebookURL && (
                <a href={facebookURL} target="_blank">
                  <img
                    src="/facebook-logo-black.svg"
                    className={classes.socialIcon}
                  />
                </a>
              )}
              {websiteURL && (
                <a href={websiteURL} target="_blank">
                  <TravelExploreIcon className={classes.socialIcon} />
                </a>
              )}
            </div>
          ) : (
            "-"
          )}
        </div>
        <hr />
        <div className={classes.qrCodeDiv}>
          <div className="text-[16px] font-medium mb-[12px]">QR SHARE</div>

          {consultantId ? (
            <QRCode
              value={configs.homeUrl + "consultant-detail/" + consultantId}
              className="h-[89px] w-[89px]"
            />
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="w-full flex flex-col gap-y-[24px]">
        <div className="flex justify-between">
          <div>
            <div className="text-[32px] font-semibold">
              {/* {overviewData?.firstName ?? "-"} {overviewData?.lastName ?? "-"} */}
              {overviewData?.first_name ?? "-"} {overviewData?.last_name ?? "-"}
            </div>
            <div className="text-[12px] text-[#717171]">
              Active {activeTime}
            </div>
            <div>{overviewData?.jobTitle ?? "-"}</div>
          </div>
          <div className="h-[18px] px-[8px] flex gap-x-[5px] items-center bg-green-50">
            <Status type={overviewData?.status} />
          </div>
        </div>
        <div className="flex gap-x-[16px]">
          <DetailText type={`${salaryType ?? ""} RATE`} value={salaryValue} />
          <DetailText
            type="LEVEL"
            // value={experiences?.[overviewData?.experienceLevel]}
            value={getExperience(overviewData?.experienceLevel, experiences)}
          />
          <DetailText
            type="AVAILABILITY"
            // value={contractTypes?.[overviewData?.availability]}
            value={getContractTypeLevel(
              overviewData?.availability,
              contractTypes
            )}
          />
        </div>
        <div className="flex gap-x-[16px]">
          <DetailText
            type="LOCATION"
            value={`${overviewData?.country ?? "-"}, ${
              overviewData?.state ?? "-"
            }, ${overviewData?.city ?? "-"}`}
          />
          <DetailText type="TIMEZONE" value={overviewData?.timezone} />
        </div>
        <div className="text-[14px] text-[#717171] ">
          {overviewData?.summary ?? "-"}
        </div>
        {(videoResumeAvailable || overviewData?.backgroundCheck) && (
          <div className="flex gap-x-[24px]">
            {videoResumeAvailable && (
              <div className="flex gap-x-[10px]">
                <img src={img_iconlightcheckmark} />
                <span className="font-semibold text-xs">
                  Video Resume Available
                </span>
              </div>
            )}
            {overviewData?.backgroundCheck && (
              <div className="flex gap-x-[10px]">
                <img src={img_iconlightcheckmark} />
                <span className="font-semibold text-xs">
                  Background Checked
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const Skills = ({ data, skillIDToNameMapper, skillLevelIDToRatingMapper }) => {
  const classes = useStyles();

  const isAllSkillsValidArray = isValidArray(data);

  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <SectionTitleAndIcon title="Skills" iconPath="/file.svg" />
      <div className="px-[16px]">
        <SkillsPicker
          id="consultantProfileSettingsViewSP" // SP - Skills Picker
          data={data}
          readOnly={true}
        />
        {/* {isAllSkillsValidArray ? (
          <div className="max-h-[250px] overflow-y-auto">
            <div className="flex flex-wrap gap-x-[28px] gap-y-[16px]">
              {data?.map((skill) => (
                <div className={classes.skillDiv}>
                  <span className="flex-1 font-semibold text-base text-black_900 text-center">
                    {skillIDToNameMapper?.[skill?.skillID]}
                  </span>
                  <span className=" flex-1 bg-gray-100 font-semibold p-[8px] rounded-sm text-center text-gray-600 text-sm uppercase">
                    {
                      skillLevelIDToRatingMapper?.[skill?.skillLevelID]
                        ?.ratingText
                    }
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )} */}
      </div>
    </div>
  );
};

const WorkHistoryCard = ({
  data,
  skillIDToNameMapper,
  contractTypes,
  industries,
}) => {
  const classes = useStyles();

  const duration = data?.duration;
  const startTime = duration?.start;
  const endTime = duration?.end;
  const isCurrentlyEmployed = duration?.isCurrentlyEmployed;
  const skills = data?.skills;
  return (
    <div className={classes.testimonyCard}>
      <div className="text-[18px] font-semibold">
        {data?.projectName ?? "-"}
      </div>

      <div>
        <Typography className={classes.workDetailType}>CLIENT</Typography>
        <div className="text-[16px] font-bold">{data?.clientName ?? "-"}</div>
      </div>
      <div>
        <Typography className={classes.workDetailType}>
          PROJECT BRIEF
        </Typography>
        <div className="text-[16px] text-[#717171] h-[44px] overflow-y-auto">
          {data?.projectBrief ?? "-"}
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>POSITION</Typography>
          <div className="text-[16px]">{data?.position ?? "-"}</div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>
            EMPLOYMENT TYPE
          </Typography>
          <div className="text-[16px]">
            {/* {contractTypes?.[data?.employmentType] ?? "-"} */}
            {getContractTypeLevel(data?.employmentType, contractTypes)}
            {}
          </div>
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>
            PROJECT DURATION
          </Typography>
          <div className="text-[16px]">
            {`${startTime?.month ?? ""} ${startTime?.year ?? ""}`} -{" "}
            {isCurrentlyEmployed
              ? "Present"
              : `${endTime?.month ?? ""} ${endTime?.year ?? ""}`}
          </div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>INDUSTRY</Typography>
          <div className="text-[16px]">{data?.industry ?? "-"}</div>
        </div>
      </div>
      <div className="flex gap-x-[8px]">
        <div className="flex-1">
          <Typography className={classes.workDetailType}>LOCATION</Typography>
          <div className="text-[16px]">{`${data?.country ?? ""},${
            data?.state ?? ""
          } ${data?.city ?? ""}`}</div>
        </div>
        <div className="flex-1">
          <Typography className={classes.workDetailType}>TIMEZONE</Typography>
          <div className="text-[16px]">{data?.timezone ?? "-"}</div>
        </div>
      </div>
      <div>
        <Typography className={classes.workDetailType}>SKILLS</Typography>
        <div className="flex gap-x-[12px] w-full overflow-x-auto pb-[16px]">
          {skills?.map((skill) => (
            <Chip
              label={skillIDToNameMapper?.[skill?.skillID]}
              variant="outlined"
              classes={{ root: classes.chipRoot, label: classes.chipLabel }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const WorkHistory = ({
  data,
  skillIDToNameMapper,
  contractTypes,
  industries,
}) => {
  const classes = useStyles();

  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <SectionTitleAndIcon
        title="Work History & Industry"
        iconPath="/dashboard.svg"
      />
      <div className="max-w-[983px] overflow-x-auto p-[16px]">
        {isValidArray(data) ? (
          <div className="flex gap-x-[24px] ">
            {data.map((card, index) => (
              <WorkHistoryCard
                data={card}
                skillIDToNameMapper={skillIDToNameMapper}
                contractTypes={contractTypes}
                industries={industries}
              />
            ))}
          </div>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div>
    </div>
  );
};

const DataTile = ({ data }) => {
  const duration = data?.duration; // for education.
  const isCurrentlyPursuing = duration?.isCurrentlyPursuing; // for education.
  const startTime = duration?.start;
  const endTime = duration?.end;
  const issuedOn = data?.issuedOn; // for certification or language or award.

  return (
    <div className="flex flex-col gap-y-[8px] pl-[24px] border-l-[1px] border-solid border-l-[#717171]">
      <div className="text-[16px] font-bold">
        {data?.certificateTitle ??
          data?.languageCertificateTitle ??
          data?.awardTitle ??
          "-"}
      </div>
      <div className="flex gap-x-[8px]">
        {(data?.universityName || data?.issuedBy) && (
          <div className="text-[14px] font-semibold uppercase text-[#717171]">
            {data?.universityName ?? data?.issuedBy}
          </div>
        )}
        {data?.courseName && (
          <div className="flex items-center gap-x-[8px] text-[14px] font-semibold uppercase text-[#717171]">
            <IconDot fillColor="#555" /> {data?.courseName}{" "}
          </div>
        )}
      </div>
      {(duration || issuedOn) && (
        <div className="text-[12px] text-[#717171]">
          {duration ? (
            <>
              {`${startTime?.month ?? ""} ${startTime?.year ?? ""}`} -{" "}
              {isCurrentlyPursuing
                ? "Present"
                : `${endTime?.month ?? ""} ${endTime?.year ?? ""}`}
            </>
          ) : (
            <>{`${issuedOn?.month ?? ""} ${issuedOn?.year ?? ""}`}</>
          )}
        </div>
      )}

      {data?.description && (
        <div className="text-[14px] text-[#717171]">{data?.description}</div>
      )}
    </div>
  );
};

const EducationAndCertification = ({ educationData, certificationData }) => {
  const classes = useStyles();
  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <SectionTitleAndIcon
        title="Education & Certification"
        iconPath="/award.svg"
      />
      <div className="p-[16px]">
        <Typography className={classes.sectionSubHeading}>
          Educational History
        </Typography>
        <div className="flex flex-col gap-y-[16px] mb-[24px] max-h-[269px] overflow-y-auto">
          {isValidArray(educationData) ? (
            <>
              {educationData?.map((entry) => (
                <DataTile data={entry} />
              ))}
            </>
          ) : (
            <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
          )}
        </div>
        <Typography className={classes.sectionSubHeading}>
          Certification
        </Typography>
        <div className="flex flex-col gap-y-[16px] max-h-[269px] overflow-y-auto">
          {isValidArray(certificationData) ? (
            <>
              {certificationData?.map((entry) => (
                <DataTile data={entry} />
              ))}
            </>
          ) : (
            <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
          )}
        </div>
      </div>
    </div>
  );
};

const LanguageProficiency = ({ data }) => {
  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <SectionTitleAndIcon title="Language Proficiency" iconPath="/award.svg" />
      <div className="flex flex-col gap-y-[16px] p-[16px] max-h-[269px] overflow-y-auto">
        {isValidArray(data) ? (
          <>
            {data?.map((entry) => (
              <DataTile data={entry} />
            ))}
          </>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div>
    </div>
  );
};

const Awards = ({ data }) => {
  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <SectionTitleAndIcon title="Awards" iconPath="/award.svg" />
      <div className="flex flex-col gap-y-[16px] p-[16px] max-h-[269px] overflow-y-auto">
        {isValidArray(data) ? (
          <>
            {data?.map((entry) => (
              <DataTile data={entry} />
            ))}
          </>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div>
    </div>
  );
};

const TestimonyCard = ({ data }) => {
  const classes = useStyles();
  return (
    <div className="min-w-[452px] flex flex-col gap-y-[8px] p-[24px] border-[1px] border-solid border-[#717171] box-content">
      <div className="text-[16px] text-[#717171] ">{data?.testimony ?? ""}</div>
      <div className="text-[18px] font-semibold">{data?.givenBy ?? ""}</div>
      <div className="text-[14px] font-semibold">{data?.role ?? ""}</div>
      <div className="text-[14px] font-semibold uppercase text-[#ABABAB]">
        {data?.projectName ?? ""}
      </div>
    </div>
  );
};

const Testimonials = ({ data }) => {
  return (
    <Tooltip title="featue will available in next release" placement="left">
      <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
        <SectionTitleAndIcon title="Testimonials" iconPath="/comment.svg" />
        {/* <div className="max-w-[983px] overflow-x-auto p-[16px]">
        {isValidArray(data) ? (
          <div className="flex gap-x-[24px] ">
            {data.map((card, index) => (
              <TestimonyCard data={card} />
            ))}
          </div>
        ) : (
          <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
        )}
      </div> */}
      </div>
    </Tooltip>
  );
};

const Resumes = ({ doc, video }) => {
  const classes = useStyles();

  return (
    <div className="py-[36px]">
      <SectionTitleAndIcon title="Resumes" iconPath="/comment.svg" />
      <div className="p-[16px]">
        <Typography className={classes.sectionSubHeading}>
          Resume Document
        </Typography>
        <div className="mb-[24px]">
          <FilePreview fileUrlOrContent={doc} />
        </div>

        <Typography className={classes.sectionSubHeading}>
          Video Resume
        </Typography>
        {video ? (
          <video className="video-player w-[100%]  h-[433px]" controls>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <Alert severity="info">{messages.NO_PREVIEW_AVAILABLE}</Alert>
        )}
      </div>
    </div>
  );
};

const ProfileView = ({
  data,
  experiences,
  contractTypes,
  industries,
  skillIDToNameMapper,
  skillLevelIDToRatingMapper,
}) => {
  const classes = useStyles();

  return (
    <>
      <Overview
        overviewData={data?.overview}
        // socialLinks={data?.socialLinks?.[0]}
        // activityDetails={data?.activity?.[0]}
        socialLinks={data?.socialLinks}
        activityDetails={data?.activity}
        videoResumeAvailable={Boolean(data?.videoResume)}
        experiences={experiences}
        contractTypes={contractTypes}
        consultantId={data?._id}
      />
      <Skills
        data={data?.skills}
        skillIDToNameMapper={skillIDToNameMapper}
        skillLevelIDToRatingMapper={skillLevelIDToRatingMapper}
      />
      <WorkHistory
        data={data?.workHistory}
        skillIDToNameMapper={skillIDToNameMapper}
        contractTypes={contractTypes}
        industries={industries}
      />
      <EducationAndCertification
        educationData={data?.education}
        certificationData={data?.certification}
      />
      <LanguageProficiency data={data?.languages} />
      <Awards data={data?.awards} />

      {/* <Testimonials data={data?.testimonials} /> */}
      <Resumes doc={data?.resumeDoc} video={data?.videoResume} />
      <div
        className="flex gap-x-[10px] items-center text-[16px] font-semibold cursor-pointer"
        onClick={scrollToTop}
      >
        <img src={img_icon_light_arrow_top} /> Back to top
      </div>
    </>
  );
};

ProfileView.propTypes = {};

export default ProfileView;
