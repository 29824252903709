import React from "react";
import PropTypes from "prop-types";

const SectionTitleAndIcon = ({ title, iconPath }) => {
  return (
    <div className="text-[24px] font-semibold pb-[24px] flex items-center gap-x-[8px]">
      {title}
    </div>
  );
};

SectionTitleAndIcon.propTypes = {};

export default SectionTitleAndIcon;
