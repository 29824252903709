import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../images/common";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "2.4rem",
    [theme.breakpoints.down("600")]: {
      paddingBottom: "1rem",
    },
  },
  bgBlack: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("600")]: {
      fontSize: "1rem",
    },
  },
  greyText: {
    color: theme.palette.secondary.light,
  },
  lineDiv: {
    width: "fit-content",
    margin: "auto",
    marginTop: "2.1rem",
    [theme.breakpoints.down("600")]: {
      marginTop: "1rem",
    },
  },
  lineImg: {
    [theme.breakpoints.down("600")]: {
      height: "65px",
    },
  },
}));

const TitleWithConnector = ({ titleComponent, bgColor = "white" }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, bgColor === "black" && classes.bgBlack)}>
      {titleComponent}

      <div className={classes.lineDiv}>
        <img
          src={bgColor === "black" ? images.white_line : images.line}
          className={classes.lineImg}
          alt="Real-Time Talent"
        />
      </div>
    </div>
  );
};

TitleWithConnector.propTypes = {};

export default TitleWithConnector;
