import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Autocomplete, Chip, TextField, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomLabel from "./CustomLabel";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./filters.scss";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  textFieldRoot: {
    minHeight: "46px",
    padding: 0,
    whiteSpace: "nowrap" /* Prevents text from wrapping to a new line */,
    overflow: "hidden" /* Hides any overflowing text */,
    textOverflow:
      "ellipsis" /* Displays an ellipsis (...) when text overflows */,
    border: "1px solid #EDEDED",
    "& .MuiInputBase-root": {
      padding: "10px !important",
      backgroundColor: theme.palette.common.white,
      "& .MuiInputBase-input": {
        backgroundColor: theme.palette.common.white,
        fontSize: "14px",
      },
      "& .MuiAutocomplete-endAdornment": {
        position: "static",
      },
    },
    "& .MuiInputBase-root:before,& .MuiInputBase-root:after": {
      border: 0,
    },
  },
  autocompleteOption: {
    fontSize: "14px",
  },
  chipRoot: {
    border: "1px solid #EDEDED",
    borderRadius: "2px",
    fontSize: "10px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  chipLabel: {
    position: "relative",
    top: "2px",
  },
  deleteIcon: {
    width: "16px !important",
    height: "16px !important",
    color: `${theme.palette.common.black} !important`,
  },
}));

const CustomAutocomplete = (props) => {
  const classes = useStyles();
  const {
    id,
    onChange,
    label,
    placeholder,
    data,
    defaultSelection,
    alwaysShowDefaultSelection, // needed in skills picker component.
    allowMultipleSelection,
    allowCustomUserInput,
    customClasses,
    disabled,
    showSearchIcon,
  } = props;
  const [selectedValue, setSelectedValue] = React.useState(defaultSelection);
  const handleOnChange = (event, value) => {
    // here value can be single or multiple.
    if (!alwaysShowDefaultSelection) setSelectedValue(value);
    onChange(id, value);
  };

  ////////
  useEffect(() => {
    setSelectedValue(defaultSelection);
  }, [defaultSelection]);

  ///////

  return (
    <>
      {label && <CustomLabel label={label} />}
      <Autocomplete
        key={
          (isValidArray(selectedValue)
            ? selectedValue?.join(",")
            : selectedValue) || data?.join(",")
        } // Provide a unique value based on the data array, rerenders the component whenever there's a change in data array.
        multiple={allowMultipleSelection}
        id="tags-filled"
        limitTags={2}
        options={data?.map((option) => option)}
        classes={{
          root: customClasses,
          option: classes.autocompleteOption,
        }}
        value={selectedValue}
        disabled={disabled}
        disableClearable={
          (allowMultipleSelection && selectedValue?.length === 0) ||
          !selectedValue
        } // array or single value case.
        freeSolo={allowCustomUserInput}
        onChange={handleOnChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
              // className={classes.chipRoot}
              classes={{
                root: classes.chipRoot,
                label: classes.chipLabel,
                deleteIcon: classes.deleteIcon,
              }}
              deleteIcon={<CloseIcon fontSize="small" />}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            placeholder={placeholder}
            classes={{ root: classes.textFieldRoot }}
            className="autocompletetextfield"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {showSearchIcon ? (
                    <IconButton>
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

CustomAutocomplete.propTypes = {};

export default CustomAutocomplete;
