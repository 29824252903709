import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getJobsForConsultant = async () => {
  return fetchWrapper(
    `${configs.APIUrl}/jobs/doMatchConsultantsByJob?consultantId=6538fe90d762aef517da8d2d`
  );
};

export const getConsultantDetailPublic = async (consultantID) => {
  return fetchWrapper(`${configs.APIUrl}/consultant/${consultantID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const viewApplication = async (jobId) => {
  return fetchWrapper(`${configs.APIUrl}/consultant/job-proposal/${jobId}`);
};

export const acceptOrWithdrawApplication = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/consultant/acceptedOrWithdrawMyStage`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    }
  );
};

export const jobAdvanceSearch = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/consultant/advanceSearch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
