import { createSlice } from "@reduxjs/toolkit";
import { getActiveConsultants } from "./ActiveConsultant.thunk";
const initialState = {
  consultants: [],
};

const activeConsultantsslice = createSlice({
  name: "activeconsultants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveConsultants.fulfilled, (state, action) => {
      if (action.payload) {
        // state.consultants = action.payload;
        state.consultants.push(...action.payload);
      }
    })
  }
});

export default activeConsultantsslice.reducer;
