import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../utils/loginUtil";


const PrivateRoute = ({ component: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          Component ? <Component {...props} /> : render(props)
        ) : (
          <Redirect to="login" />
        )
      }
    />
  );
};

export default PrivateRoute;



// const PrivateRoute = ({ component: Component, ...rest }) => {
//   return (
//     // Show the component only when the user is logged in
//     // Otherwise, redirect the user to /login page
//     <Route
//       {...rest}
//       render={(props) =>
//         // isLoggedIn() ? <Component {...props} /> : <Redirect to="/login" />
//         isLoggedIn() ? <Component {...props} /> : <Redirect to="login" />
//       }
//     />
//   );
// };

// export default PrivateRoute;
