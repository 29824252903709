const consultantDetailObj = {
  _id: "3252523523523535",
  first_name: "Marc",
  last_name: "Lacy",
  profile_picture: "https://source.unsplash.com/250x250/?business-women",
  personal_info: {
    city: "Singapore City",
    country: "Singapore",
    linkedinUrl: "",
    facebookUrl: "https://facebook.com/lacy",
  },
  profile_info: {
    skills: [
      {
        skill: "React",
        icon: "/html.png",
        level: "Intermediate",
      },
      {
        skill: "HTML5",
        icon: "/vuejs.png",
        level: "Expert",
      },
      {
        skill: "CSS3",
        icon: "/html.png",
        level: "Intermediate",
      },
      {
        skill: "JavaScript",
        icon: "/vuejs.png",
        level: "Expert",
      },
      {
        skill: "Redux",
        icon: "/html.png",
        level: "Beginner",
      },
      {
        skill: "React Router",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Responsive Web Design",
        icon: "/html.png",
        level: "Beginner",
      },
      {
        skill: "Git",
        icon: "/vuejs.png",
        level: "Expert",
      },
      {
        skill: "Version Control",
        icon: "/html.png",
        level: "Beginner",
      },
      {
        skill: "RESTful APIs",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Webpack",
        icon: "/html.png",
        level: "Beginner",
      },
      {
        skill: "Babel",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "ES6+",
        icon: "/html.png",
        level: "Expert",
      },
      {
        skill: "Unit Testing (Jest, Enzyme)",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Debugging",
        icon: "/html.png",
        level: "Beginner",
      },
      {
        skill: "UI/UX Design Principles",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Agile Methodologies",
        icon: "/html.png",
        level: "Expert",
      },
      {
        skill: "Scrum",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Problem Solving",
        icon: "/html.png",
        level: "Beginner",
      },
      {
        skill: "Collaboration",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Time Management",
        icon: "/html.png",
        level: "Expert",
      },
      {
        skill: "Communication",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
      {
        skill: "Adaptability",
        icon: "/html.png",
        level: "Expert",
      },
      {
        skill: "Attention to Detail",
        icon: "/vuejs.png",
        level: "Intermediate",
      },
    ],
    experiences: [
      {
        projectName: "Navigation App",
        projectDesc:
          "Fiat Australia is the Australian division of the Italian automotive brand Fiat. With a rich heritage spanning over a century, Fiat has established a strong presence in Australia, offering a range of stylish and compact cars that embody Italian design and engineering excellence.",
        companyName: "Fiat Australia",
        position: "Web Developer",
        startDate: " Aug 2022",
        endDate: "May 2023",
        industryType: "IT",
        skills: ["Java", "Python"],
      },
      {
        projectName: "XYZ Company",
        projectDesc:
          "XYZ Company is a leading provider of financial services, delivering exceptional solutions to individuals and businesses worldwide.",
        companyName: "Microsoft",
        position: "UX/UI Designer",
        startDate: " Aug 2021",
        endDate: "May 2022",
        industryType: "Digital Design",
        skills: [
          "Adobe",
          "Figma",
          "Sketch",
          "InVision",
          "Blender",
          "Unity",
          "Blender",
          "Unity",
        ],
      },
    ],
    resume:
      "https://findpro-s3.s3.us-east-2.amazonaws.com/MANJU%20Latest%20CV_1655472663139.pdf",
    videoResume:
      "https://findpro-s3.s3.us-east-2.amazonaws.com/Intro_1659512499129.mp4",
    selfIntro:
      "I'm a skilled React frontend developer with a passion for creating dynamic and engaging user interfaces. With over [X] years of experience in web development, I have a strong understanding of HTML, CSS, and JavaScript, along with expertise in building responsive layouts and integrating APIs. I excel at translating design mockups into pixel-perfect UIs and have a keen eye for detail. Proficient in Redux, React Router, and other essential React libraries, I strive to write clean and maintainable code. I'm a fast learner, a collaborative team player, and always eager to stay up-to-date with the latest frontend trends and best practices. Let's build amazing user experiences together!",
    profile_summary: {
      title: "Frontend Developer",
      headline: "I have good expertise in web    development",
      availability: "60 hrs",
      rate: 30,
    },
    educations: [
      {
        education: "Matsers of Technology",
        course: "Computer Science",
        university: "Amity University",
        startDate: "",
        endDate: "",
      },
      {
        education: "Bachelor of Science in Computer Science",
        course: "Computer Science",
        university: "University of Technology",
        startDate: "2015",
        endDate: "2019",
      },
      {
        education: "Master of Business Administration",
        course: "Business Administration",
        university: "Global Business School",
        startDate: "2020",
        endDate: "2022",
      },
      {
        education: "Certificate in Digital Marketing",
        course: "Digital Marketing",
        university: "Online Academy",
        startDate: "2023",
        endDate: "Present",
      },
    ],
  },
  match_rate: 68,
  level: "experts",
  isBackGruondChecked: true,
  status: "Available",
  lastSeen: "10 Minutes ago",
  qrData: "",
  testimonials: [
    {
      name: "Sam",
      role: "Supervisor",
      project: "Alpha",
      company: "ABC tech",
      text: "Quisque feugiat ac posuere mauris interdum at libero massa.",
    },
    {
      name: "Samy",
      role: "Supervisor",
      project: "Alpha",
      company: "XYZ tech",
      text: "Quisque feugiat ac posuere mauris interdum at libero massa.",
    },
  ],
  certificates: [
    {
      name: "CISCO Computer Component Build Certification",
      course: "Information & Human Design",
      organization: "Mt. Albany University",
      issuedOn: "May 2022",
    },
  ],
  languages: [
    {
      name: "English",
      Institute: "Princeton University",
      issuedOn: "April 2022",
    },
  ],
  ratings: [
    {
      teamManagement: 3,
      businessDevelopment: 2,
      frontendDevelopment: 3,
    },
  ],
};

export { consultantDetailObj };
