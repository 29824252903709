import { createSlice } from "@reduxjs/toolkit";
import { getConsultantsThunk } from "./consultants.thunk";

const initialState = {
  consultants: [],
};

const consoltants = createSlice({
  name: "consultants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConsultantsThunk.fulfilled, (state, action) => {
      if (action.payload) {
        // state.consultants = action.payload;
        state.consultants.push(...action.payload);
      }
    })
  }
});

export default consoltants.reducer;
