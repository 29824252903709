import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import CustomButton from "app/v2/components/CustomButton";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";

const modalStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  padding: "20px",
  border: "1px solid #ccc",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  zIndex: 1000,
  visibility: "hidden",
  opacity: 0,
  transition: "visibility 0s, opacity 0.3s ease-in-out",
  maxWidth: "30%", // Set your desired max width
  width: "100%",
};

const visibleModalStyle = {
  visibility: "visible",
  opacity: 1,
};

const contentStyle = {
  textAlign: "center",
};

const hrStyle = {
  margin: "10px 0",
  border: "0",
  borderTop: "1px solid #ccc",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
  marginBottom: "10px",
  boxSizing: "border-box",
  height: "100px",
  lineHeight: "1",
  fontSize: "16px", // Adjust font size
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: "15px",
  gap: "20px",
};

const errorMessageStyle = {
  color: "red",
  marginTop: "5px",
};

const Modal = ({ task, isOpen, onClose, onSubmit }) => {
  const [reviewData, setReviewData] = useState({
    id: task?._id,
    status: "",
    statusReason: "",
  });

  const [showCommentError, setShowCommentError] = useState(false);
  const [resetTextfield, setResetTextField] = useState(false);

  useEffect(() => {
    // Disable Reject button initially
    setShowCommentError(true);

    // Reset statusReason when isOpen changes to true
    if (isOpen) {
      setReviewData((prevData) => ({
        ...prevData,
        statusReason: "",
      }));
    }
  }, [isOpen]);

  const handleAccept = () => {
    const updatedReviewData = {
      ...reviewData,
      id: task?._id,
      status: "approved",
    };

    setReviewData(updatedReviewData);
    onSubmit(updatedReviewData);
    onClose();
    setReviewData({
      id: null,
      status: "",
      statusReason: "",
    });
  };

  const handleReject = () => {
    if (reviewData?.statusReason.length >= 10) {
      setShowCommentError(false);
    } else {
      setShowCommentError(true);
      return;
    }

    if (reviewData?.statusReason.length <= 200) {
      const updatedReviewData = {
        ...reviewData,
        id: task?._id,
        status: "declined",
      };

      setReviewData(updatedReviewData);
      onSubmit(updatedReviewData);
      onClose();
      setReviewData({
        id: null,
        status: "",
        statusReason: "",
      });
    } else {
      setShowCommentError(true);
    }
  };

  const handleTextFieldChange = (id, value) => {
    setReviewData({ ...reviewData, statusReason: value });
    if (value.length >= 10) {
      setShowCommentError(false);
    } else {
      setShowCommentError(true);
    }
    if (value.length > 200) {
      setShowCommentError(true);
    }
  };

  useEffect(() => {
    // Reset statusReason when isOpen changes to true
    if (isOpen) {
      setReviewData((prevData) => ({
        ...prevData,
        statusReason: "",
      }));
    }
    setResetTextField((prevState) => !prevState);
  }, [isOpen]);

  const isRejectButtonDisabled =
    showCommentError || reviewData?.statusReason.length > 200;

  return (
    <div style={{ ...modalStyle, ...(isOpen ? visibleModalStyle : {}) }}>
      <div style={contentStyle}>
        <div className="flex justify-end">
          {/* <Tooltip title="Close" arrow> */}
          <CloseIcon
            style={{
              position: "absolute",
              // top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
          {/* </Tooltip> */}
        </div>
        <h3>Task Review</h3>
        <hr style={hrStyle} />
        <label
          style={{ display: "block", fontWeight: "bold", textAlign: "left" }}
        >
          Comment
        </label>
        <CustomTextfield
          type="text"
          multiline={true}
          minRows={3}
          placeholder="Write your comments here"
          value={reviewData?.statusReason}
          onChange={handleTextFieldChange}
          style={inputStyle}
          reset={resetTextfield}
        />
        {showCommentError && reviewData?.statusReason.length > 200 && (
          <div style={errorMessageStyle}>
            Please enter a comment with fewer than 200 characters.
          </div>
        )}
        <div style={buttonContainerStyle}>
          {/* <Tooltip title="Enter the comment" arrow> */}
          <div>
            <CustomButton
              onClick={handleReject}
              type="button1"
              customClasses="px-4 py-2 mb-2 !important"
              disabled={isRejectButtonDisabled}
            >
              Reject
            </CustomButton>
          </div>
          {/* </Tooltip> */}
          <CustomButton
            onClick={handleAccept}
            type="button1"
            customClasses="px-4 py-2 mb-2 !important"
          >
            Accept
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default Modal;
