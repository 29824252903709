import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, RadioGroup, Radio, FormControlLabel, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import employerImages from "../images";
import CustomButton from "app/v2/components/CustomButton";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";
import images from "../../../images/common";

const useStyles = makeStyles((theme) => ({
  hireContainer: {
    maxWidth: "1046px",
    width: "100%",
    margin: "60px",
    fontFamily: "Montserrat !important",
  },
  logoDiv: {
    marginBottom: "48px",
  },
  logo: {
    width: "175px",
    height: "47px",
  },
  headingDiv: {
    marginBottom: "33px",
  },
  heading: {
    fontWeight: 600,
  },
  subHeading: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#ABABAB",
  },
  imageGroupSelector: {
    display: "flex",
    columnGap: "14px",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "33px",
  },
  radioButton: {
    position: "absolute",
    opacity: 0,
    width: 0,
    height: 0,
  },
  imageRadio: {
    cursor: "pointer",
    padding: "16px",
  },
  radioText: {
    fontSize: "14px",
    fontWeight: 700,
    textAlign: "center",
    marginTop: "8px",
  },
  currentSelection: {
    outline: "1px solid #618EFF",
  },
  actionsSegment1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    fontSize: "14px",
    marginBottom: "48px",
  },
  subSegment: {
    width: "235px",
  },
  continueLater: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.common.black,
  },
  continueLaterDesc: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#717171",
    marginTop: "12px",
  },
  submitButton: {
    boxSizing: "content-box",
    // maxWidth: "287px",
    maxWidth: "297px",
    width: "100%",
    fontSize: "14px",
    padding: "10px 20px",
  },
  arrowIcon: {
    marginLeft: "11px",
  },
  actionsSegment2: {
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
  },
  loginLink: {
    color: theme.palette.primary.main,
  },
}));

const HireConsultantOrTeam = ({
  hireOptions,
  hireSelection,
  setHireSelection,
  onSearchClick
}) => {
  const classes = useStyles();
  const [isConsultantSelected, setIsConsultantSelected] = useState(
    hireSelection === "consultant"
  );
  const handleChange = (event) => {
    setHireSelection(event.target.value);
    setIsConsultantSelected(event.target.value === "consultant");
  };

  const HeadingDiv = () => {
    return (
      <div className={classes.headingDiv}>
        <Typography variant="h5" className={classes.heading}>
          Start your search
        </Typography>
        <Typography className={classes.subHeading}>
          You can choose to hire per role or as a team.
        </Typography>
      </div>
    );
  };
  const Body = () => {
    return (
      <div className={classes.imageGroupSelector}>
        <label>
          <Radio
            name="hire-selection"
            value="consultant"
            checked={isConsultantSelected}
            onChange={handleChange}
            className={classes.radioButton}
          />
          <div
            className={clsx([
              classes.imageRadio,
              isConsultantSelected && classes.currentSelection,
            ])}
          >
            <img src={employerImages.hire_consultant} alt="consultant_image" />
            <Typography className={classes.radioText}>
              I’d like to hire Consultants individually
            </Typography>
          </div>
        </label>
{/* the below code will be used in further updates */}
        <Tooltip title="Feature will be available in the next release" placement="top" >
          <span>
          <label style={{ opacity: 0.5, pointerEvents: 'none' }} >
            <Radio
              name="hire-selection"
              value="team"
              checked={!isConsultantSelected}
              onChange={handleChange}
              className={classes.radioButton}
            />
            <div
              className={clsx([
                classes.imageRadio,
                !isConsultantSelected && classes.currentSelection,
              ])}
            >
              <img src={employerImages.hire_team} alt="team_image" />
              <Typography className={classes.radioText}>
                I’d like to hire by Teams of consultants
              </Typography>
            </div>
          </label>
          </span>
          </Tooltip>
      </div>
    );
  };
  const ActionsDiv = () => {
    return (
      <div>
        <div className={classes.actionsSegment1}>
          <div className={classes.subSegment}>
            {/* <Link to="sign-up" className={classes.continueLater}>Continue later?</Link>
            <Typography className={classes.continueLaterDesc}>
              We will still ask you to complete this before you’re able to list
              a job.
            </Typography> */}
          </div>
          <div>
            <CustomButton
              type="button1"
              customClasses={classes.submitButton}
              onClick={() => {
                onSearchClick();
              }}
            >
              Tell us what{" "}
              {hireSelection === "consultant"
                ? hireSelection
                : `kind of ${hireSelection}`}{" "}
              you need{" "}
              <EastIcon fontSize="small" className={classes.arrowIcon} />
            </CustomButton>
          </div>
        </div>

        <Typography className={classes.actionsSegment2}>
          Have an account already?{" "}
          <Link to="login" className={classes.loginLink}>Login</Link>
        </Typography>
      </div>
    );
  };
  return (
    <div className={classes.hireContainer}>
      <div className={classes.logoDiv}>
        <img src={images?.logo_v2} className={classes.logo} />
      </div>
      <HeadingDiv />
      <Body />
      <ActionsDiv />
    </div>
  );
};

HireConsultantOrTeam.propTypes = {};

export default HireConsultantOrTeam;
