import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Chip, Toolbar, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomButton from "app/v2/components/CustomButton";
import CallMadeIcon from "@mui/icons-material/CallMade";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { getSkillName } from "app/v2/Pages/common/getIdTypeLevel";
import CloseIcon from "@mui/icons-material/Close";

import {
  UpdateConnectionsData,
  deleteConnectionsData,
} from "app/v2/services/consultant/connections.service";
import CustomTooltip from "app/v2/components/CustomTooltip";
import DeleteConnectionDialogue from "app/v2/Pages/Employer/common/DeleteConnectDialogue";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
const useStyles = makeStyles((theme) => ({
  listCardOuterDiv: {
    maxWidth: "1340px",
    width: "100%",
    height: "148px",
    boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    marginBottom: "20px",
    padding: "21px 18px",
  },
  listCardInnerDiv: {
    maxWidth: "1185px",
    width: "100%",
    height: "100px",
    display: "flex",
    columnGap: "36px",
    alignItems: "center",
  },
  addInfoName: {
    fontSize: "10px",
    fontWeight: 700,
    color: theme.palette.secondary.light, // light grey
    marginBottom: "6px",
  },
  addInfoValue: {
    fontSize: "14px",
    fontWeight: 600,
  },
  detailsSet1: {
    maxWidth: "203px",
    width: "100%",
  },
  nameRoleDiv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "19px",
  },
  name: {
    fontWeight: 600,
  },
  role: {
    fontSize: "12px",
    color: theme.palette.secondary.light, // light grey
  },
  companyDetailsDiv: {
    display: "flex",
    columnGap: "40px",
  },
  detailsSet2: {
    maxWidth: "422px",
    width: "100%",
  },
  skillsDiv: {
    marginBottom: "12px",
  },
  skillsHeading: {
    fontSize: "10px",
    fontWeight: 600,
    color: theme.palette.secondary.light, // light grey
    marginBottom: "8px",
  },
  skillsChipsDiv: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
  },
  chip: {
    padding: "6px 8px",
    border: "1px solid #EDEDED",
    borderRadius: "6px !important",
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.secondary.light, // light grey
    marginRight: "8px",
    textTransform: "uppercase",
  },
  bio: {
    color: theme.palette.secondary.light, // light grey
    fontSize: "12px",
    height: "41px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  actionsDiv: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "247px",
    width: "100%",
    rowGap: "19px",
  },
  viewCompanyButton: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },

  deleteButton: {
    width: "100%",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
  },
  viewProfileIcon: {
    marginLeft: "8px",
    width: "16px",
    height: "16px",
  },
  connectedButton: {
    width: "100%",
    border: "1px solid #717171",
    fontSize: "14px",
    fontWeight: 600,
    alignSelf: "flex-start",
  },
  favIcon: {
    marginRight: "4px",
    width: "16px",
    height: "16px",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },

  connectdiv: {
    width: "100%",
  },

  //  deleteIconDiv: {
  // flex: 0.5,
  // },
  deleteIcondiv: {
    cursor: "pointer",
    // width: "50px",
    // height: "50px",
    // marginLeft: "auto",
    position: "relative",
    // bottom: "4px",
    alignSelf: "flexend",
    border: "1px solid #EDEDED",
    padding: "5px",
  },

  deleteIcon: {
    filter: "brightness(50%)",
  },
  delandstatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "20px",
  },
}));

const ListCard = ({ data, setUpdateConnectionsApi }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteJobLoading, setDeleteJobLoading] = useState(false);
  const [deleteJobCompleted, setDeleteJobCompleted] = useState(false);
  const [deleteJobError, setDeleteJobError] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});

  const [handleStatusConnect, setHandlestatusConnect] = useState("");
  //   const data = {
  //     image: "/company_image.png",
  //     companyName: "ABC Company",
  //     role: "Sr. Product Owner",
  //     companySize: 14,
  //     location: "Singapore",
  //     bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
  //     skills: ["React", "NodeJS", "AngularJS"],
  //   };

  const { experiences, contractTypes, skillLevels, skills, industryData } =
    useSelector((state) => state.misc);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    // if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!skillLevels?.length) dispatch(fetchSkillsThunk());
    if (!skills?.length) dispatch(fetchSkillLevelsThunk());
  }, [dispatch]);

  const handleAcceptConnection = async (connectionsId) => {
    // enqueueSnackbar(`Your action in progress.Please wait.  `, {
    //   variant: "info",
    // });

    const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });

    try {
      const requestData = {
        id: connectionsId,
        status: "accepted",
      };

      const response = await UpdateConnectionsData(requestData);

      if (response) {
        setHandlestatusConnect(response.status);
        setUpdateConnectionsApi(true);

        // enqueueSnackbar(`Your action has been successfull.`, {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(`Your action has been successfull.`, {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // enqueueSnackbar(
      //   `Your action was not succesfull. Please try again later.`,
      //   { variant: "error" }
      // );

      const key = enqueueSnackbar(
        `Your action was not succesfull. Please try again later.`,
        {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
  };

  const handleRejectConnection = async (connectionsId) => {
    // enqueueSnackbar(`Your action in progress. Please wait.  `, {
    //   variant: "info",
    // });

    const key = enqueueSnackbar(`Your action in progress. Please wait.  `, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });

    try {
      const requestData = {
        id: connectionsId,
        status: "rejected",
      };

      const response = await UpdateConnectionsData(requestData);

      if (response) {
        setHandlestatusConnect(response.status);
        setUpdateConnectionsApi(true);

        // enqueueSnackbar(`Your action has been successfully.`, {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(`Your action has been successfully.`, {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // enqueueSnackbar(
      //   `Your action was not succesfull. Please try again later.`,
      //   { variant: "error" }
      // );

      const key = enqueueSnackbar(
        `Your action was not succesfull. Please try again later.`,
        {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
  };

  const handleDelete = async () => {
    setDeleteJobLoading(true);

    try {
      // const requestData = {
      //   id: data._id,
      // };

      const response = await deleteConnectionsData(data._id);

      if (response) {
        setHandlestatusConnect(response.status);
        setDeleteJobLoading(false);
        setUpdateConnectionsApi(true);
        setDeleteJobDialogOpen(false);
        // enqueueSnackbar(`Your action to delete has been successfull.`, {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(
          `Your action to delete has been successfull.`,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);

      setDeleteJobLoading(false);
      setDeleteJobError(true);
      console.error("Error in  deleting connection:", error);
    }
  };

  const AddInfo = ({ name, value, customClasses }) => {
    return (
      <div className={customClasses}>
        <Typography className={classes.addInfoName}>{name}</Typography>
        <Typography className={classes.addInfoValue}>{value}</Typography>
      </div>
    );
  };

  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteJobLoading(false);
      setDeleteJobCompleted(false);
      setDeleteJobError(false);
    }
  }, [deleteJobDialogOpen]);

  // useEffect(()=>{

  //   const storedUserData = localStorage.getItem("user");
  //   const user_id = JSON.parse(storedUserData);
  //   const userId=user_id._id

  //   if(userId===data.sender._id){

  //     setSelectedDetails({
  //       image:data?.receiver?.image,
  //       description:data?.receiver?.description.text,
  //       name:data?.receiver?.name,
  //       state:data?.receiver?.state,
  //       country:data?.receiver?.country,
  //       city:data?.receiver?.city,
  //       status:data?.status,
  //     })
  //     if(selectedDetails.status==="pending"){
  //       selectedDetails.status="pending1"
  //     }
  //   }else{

  //     setSelectedDetails({
  //       image:data?.sender?.image,
  //       description:data?.sender?.description.text,
  //       name:data?.sender?.name,
  //       state:data?.sender?.state,
  //       country:data?.sender?.country,
  //       city:data?.sender?.city,
  //       status:data?.status,
  //     })

  //     if(selectedDetails.status==="pending"){
  //       selectedDetails.status="pending2"
  //     }
  //   }

  // },[data])

  useEffect(() => {
    // const storedUserData = localStorage.getItem("orgId");
    // const user_id = JSON.stringify(storedUserData);
    // const userId = user_id;
    const orgId = localStorage.getItem("orgId");

    if (data) {
      const newData =
        orgId === data?.sender?._id ? data?.receiver : data?.sender;
      // let newData = {};
      // if (data?.sender?.userType === "client" || data?.receiver?.userType === "client" ) {
      //   // newData = data?.sender?.userType === "client" ? data?.receiver : data?.sender;
      //   newData = orgId === data?.sender?._id ? data?.receiver : data?.sender;
      // }

      console.log("orgId === data?.sender?._id ", orgId === data?.sender?._id);
      console.log("new", newData);

      const newStatus =
        data?.status === "pending"
          ? orgId === data?.sender?._id
            ? "pending1" // show the pending view
            : "pending2" // show the accept/reject view
          : data?.status;

      setSelectedDetails({
        _id: newData?._id,
        image: newData?.image,
        description:
          typeof newData?.description === "object"
            ? JSON.stringify(newData?.description)
            : newData?.description ?? "_",
        name: newData?.name,
        state: newData?.state,
        country: newData?.country,
        city: newData?.city,
        status: newStatus, //new status logic is written  above
        skills: newData?.skills?.map((skill) =>
          getSkillName(skill?.skillID, skills)
        ),
      });
    }
  }, [data]);
  console.log("selected detais", selectedDetails);
  return (
    <>
      {data?.status !== "rejected" && (
        <div className={classes.listCardOuterDiv}>
          <div className={classes.listCardInnerDiv}>
            <img
              className={classes.orgImage}
              alt={`org_${data?._id}_image`}
              src={selectedDetails.image}
            />
            <div className={classes.detailsSet1}>
              <div className={classes.nameRoleDiv}>
                <Typography variant="subtitle1" className={classes.name}>
                  {selectedDetails.name ? selectedDetails?.name : "--"}
                </Typography>
                {/* <Typography className={classes.role}>{data.role}</Typography> */}
              </div>
              <div className={classes.companyDetailsDiv}>
                {/* <AddInfo name="COMPANY SIZE" value={data.companySize?data.companySize:"--"} /> */}
                <AddInfo
                  name="LOCATION"
                  value={
                    selectedDetails?.country
                      ? `${selectedDetails?.country},  ${selectedDetails?.state}, ${selectedDetails?.city}`
                      : "--"
                  }
                />
              </div>
            </div>

            <div className={classes.detailsSet2}>
              {selectedDetails?.skills &&
                selectedDetails?.skills?.length > 0 && (
                  <div className={classes.skillsDiv}>
                    <Typography className={classes.skillsHeading}>
                      TECHNOLOGY SKILLS
                    </Typography>
                    <div className={classes.skillsChipsDiv}>
                      {selectedDetails?.skills.map((skill, index) => (
                        <Chip
                          key={index}
                          label={skill}
                          variant="outlined"
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  </div>
                )}
              <Typography className={classes.bio}>
                {selectedDetails.description
                  ? selectedDetails.description
                  : "--"}
              </Typography>
            </div>

            {selectedDetails.status === "accepted" && (
              <div className={classes.actionsDiv}>
                <CustomButton
                  type="button1"
                  customClasses={classes.viewCompanyButton}
                  onClick={() => {
                    // history.push(`/consultant/job-detail/${data?._id}`);
                    history.push(`/client/consultant/${selectedDetails?._id}`);
                  }}
                >
                  {/* View jobs */}
                  View Profile
                  <CallMadeIcon
                    fontSize="small"
                    className={classes.viewProfileIcon}
                  />
                </CustomButton>

                <div className={classes.delandstatus}>
                  <Tooltip title="This feature will be available in next release">
                    <div className={classes.connectdiv}>
                      <CustomButton
                        /*onClick={(handleConnect(data._id))}*/ customClasses={
                          classes.connectedButton
                        }
                        disabled={true}
                      >
                        Message
                        {/* {handleStatusConnect} */}
                      </CustomButton>
                    </div>
                  </Tooltip>
                  <div className={classes.deleteIcondiv}>
                    <img
                      alt="delete-icon"
                      src="/delete-gray.svg"
                      className={classes.deleteIcon}
                      onClick={() => {
                        setDeleteJobDialogOpen(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {selectedDetails.status === "pending1" && (
              <div className={classes.actionsDiv}>
                <CustomButton
                  type="button1"
                  customClasses={classes.viewCompanyButton}
                  disabled={"true"}

                  // onClick={() => {
                  // history.push(`/${view}-detail`);
                  // }}
                >
                  View Profile
                  <CallMadeIcon
                    fontSize="small"
                    className={classes.viewProfileIcon}
                  />
                </CustomButton>

                <div className={classes.delandstatus}>
                  <div className={classes.connectdiv}>
                    <CustomButton
                      /*onClick={(handleConnect(data._id))}*/ customClasses={
                        classes.connectedButton
                      }
                      disabled={true}
                    >
                      pending
                      {/* {handleStatusConnect} */}
                    </CustomButton>
                  </div>
                </div>
              </div>
            )}

            {selectedDetails.status === "pending2" && (
              <div className={classes.actionsDiv}>
                <CustomButton
                  type="button1"
                  customClasses={classes.viewCompanyButton}
                  onClick={() => {
                    handleAcceptConnection(data._id);
                  }}
                >
                  Accept
                  <CallMadeIcon
                    fontSize="small"
                    className={classes.viewProfileIcon}
                  />
                </CustomButton>

                <div className={classes.delandstatus}>
                  <div className={classes.connectdiv}>
                    <CustomButton
                      customClasses={classes.connectedButton}
                      type="button1"
                      onClick={() => {
                        handleRejectConnection(data._id);
                      }}
                    >
                      Reject
                      {/* {handleStatusConnect} */}
                    </CustomButton>
                  </div>
                </div>
              </div>
            )}
          </div>

          {deleteJobDialogOpen && (
            <DeleteConnectionDialogue
              open={deleteJobDialogOpen}
              setOpen={setDeleteJobDialogOpen}
              connectionDetails={{
                jobNo: data?._id,
                jobName: data?.jobTitle,
              }}
              onDeleteClick={handleDelete}
              deleteLoading={deleteJobLoading}
              deleteCompleted={deleteJobCompleted}
              deleteError={deleteJobError}
              onSuccessRedirect={() => {
                history.push("/consultant/connections");
              }}
              onErrorRedirect={() => {
                history.push("/consultant/connections");
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

ListCard.propTypes = {};

export default ListCard;

// import React from "react";
// import PropTypes from "prop-types";
// import { Typography, Chip } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import clsx from "clsx";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import CustomButton from "app/v2/components/CustomButton";
// import CallMadeIcon from "@mui/icons-material/CallMade";
// import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
// import { useHistory } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   listCardOuterDiv: {
//     maxWidth: "1340px",
//     width: "100%",
//     height: "148px",
//     boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
//     marginBottom: "20px",
//     padding: "21px 18px",
//   },
//   listCardInnerDiv: {
//     maxWidth: "1185px",
//     width: "100%",
//     height: "100px",
//     display: "flex",
//     columnGap: "36px",
//     alignItems: "center",
//   },
//   addInfoName: {
//     fontSize: "10px",
//     fontWeight: 700,
//     color: theme.palette.secondary.light, // light grey
//     marginBottom: "6px",
//   },
//   addInfoValue: {
//     fontSize: "14px",
//     fontWeight: 600,
//   },
//   detailsSet1: {
//     maxWidth: "203px",
//     width: "100%",
//   },
//   nameRoleDiv: {
//     display: "flex",
//     flexDirection: "column",
//     marginBottom: "19px",
//   },
//   name: {
//     fontWeight: 600,
//   },
//   role: {
//     fontSize: "12px",
//     color: theme.palette.secondary.light, // light grey
//   },
//   companyDetailsDiv: {
//     display: "flex",
//     columnGap: "40px",
//   },
//   detailsSet2: {
//     maxWidth: "422px",
//     width: "100%",
//   },
//   skillsDiv: {
//     marginBottom: "12px",
//   },
//   skillsHeading: {
//     fontSize: "10px",
//     fontWeight: 600,
//     color: theme.palette.secondary.light, // light grey
//     marginBottom: "8px",
//   },
//   skillsChipsDiv: {
//     display: "flex",
//     width: "100%",
//     overflowX: "auto",
//   },
//   chip: {
//     padding: "6px 8px",
//     border: "1px solid #EDEDED",
//     borderRadius: "6px !important",
//     fontSize: "12px",
//     fontWeight: 500,
//     color: theme.palette.secondary.light, // light grey
//     marginRight: "8px",
//     textTransform: "uppercase",
//   },
//   bio: {
//     color: theme.palette.secondary.light, // light grey
//     fontSize: "12px",
//     height: "41px",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//   },
//   actionsDiv: {
//     display: "flex",
//     flexDirection: "column",
//     maxWidth: "247px",
//     width: "100%",
//     rowGap: "19px",
//   },
//   viewCompanyButton: {
//     width: "100%",
//     fontSize: "14px",
//     fontWeight: 600,
//     whiteSpace: "nowrap",
//   },

//   deleteButton: {
//     width: "100%",
//     fontSize: "14px",
//     fontWeight: 600,
//     whiteSpace: "nowrap",
//   },
//   viewProfileIcon: {
//     marginLeft: "8px",
//     width: "16px",
//     height: "16px",
//   },
//   connectedButton: {
//     width: "100%",
//     border: "1px solid #717171",
//     fontSize: "14px",
//     fontWeight: 600,
//   },
//   favIcon: {
//     marginRight: "4px",
//     width: "16px",
//     height: "16px",
//   },
//   orgImage: {
//     width: "100px",
//     height: "100px",
//     objectFit: "scale-down",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #EDEDED",
//   },
// }));

// const ListCard = ({ data }) => {
//   const history = useHistory();
//   const classes = useStyles();

//   const AddInfo = ({ name, value, customClasses }) => {
//     return (
//       <div className={customClasses}>
//         <Typography className={classes.addInfoName}>{name}</Typography>
//         <Typography className={classes.addInfoValue}>{value}</Typography>
//       </div>
//     );
//   };

//   return (
//     <div className={classes.listCardOuterDiv}>
//       <div className={classes.listCardInnerDiv}>
//         <img
//           className={classes.orgImage}
//           alt={`org_${data?._id}_image`}
//           src={data?.image ? data.image : "/org_placeholder.svg"}
//         />
//         <div className={classes.detailsSet1}>
//           <div className={classes.nameRoleDiv}>
//             <Typography variant="subtitle1" className={classes.name}>
//               {data.companyName}
//             </Typography>
//             <Typography className={classes.role}>{data.role}</Typography>
//           </div>
//           <div className={classes.companyDetailsDiv}>
//             <AddInfo name="COMPANY SIZE" value={data.companySize} />
//             <AddInfo name="LOCATION" value={data.location} />
//           </div>
//         </div>
//         <div className={classes.detailsSet2}>
//           <div className={classes.skillsDiv}>
//             <Typography className={classes.skillsHeading}>
//               TECHNOLOGY SKILLS
//             </Typography>
//             <div className={classes.skillsChipsDiv}>
//               {data.skills.map((skill) => (
//                 <Chip
//                   label={skill}
//                   variant="outlined"
//                   className={classes.chip}
//                 />
//               ))}
//             </div>
//           </div>
//           <Typography className={classes.bio}>{data.bio}</Typography>
//         </div>
//         <div className={classes.actionsDiv}>
//           <CustomButton
//             type="button1"
//             customClasses={classes.viewCompanyButton}
//             onClick={() => {
//               // history.push(`/${view}-detail`);
//             }}
//           >
//             View {data.companyName}
//             <CallMadeIcon
//               fontSize="small"
//               className={classes.viewProfileIcon}
//             />
//           </CustomButton>
//           <CustomButton customClasses={classes.connectedButton} disabled={true}>
//             Connected
//           </CustomButton>
//         </div>
//         <CustomButton type="button1" customClasses={classes.deleteButton}>
//           Delete
//         </CustomButton>
//       </div>
//     </div>
//   );
// };

// ListCard.propTypes = {};

// export default ListCard;
