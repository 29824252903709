import { createSlice } from "@reduxjs/toolkit";
import { getConsultantsThunk } from "./employerDashboard.thunk";

const initialState = {
  employerDashboardConsultants: [],
};

const employerDashboard = createSlice({
  name: "employerDashboardConsultants",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConsultantsThunk.fulfilled, (state, action) => {
      state.employerDashboardConsultants = action.payload;
    })
  }
});

export default employerDashboard.reducer;
