import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import About from "../About";
import TitleWithConnector from "../../../components/TitleWithConnector";
import Features from "./Features";
import RTTAndCompanies from "../common/ConnectorAndCompanies";
import Testimonials from "../common/Testimonials";
import HiringProcess from "./HiringProcess";
import ConsultantInfo from "./ConsultantInfo";
import DeveloperTypes from "./DeveloperTypes";

const useStyles = makeStyles((theme) => ({}));
const HireDeveloperDetails = (props) => {
  const classes = useStyles();
  return (
    <>
      <About type="developer" />
      <TitleWithConnector />
      <Features />
      <RTTAndCompanies />
      <Testimonials />
      <TitleWithConnector />
      <HiringProcess />
      <ConsultantInfo />
      <TitleWithConnector />
      <DeveloperTypes />
    </>
  );
};

HireDeveloperDetails.propTypes = {};

export default HireDeveloperDetails;
