import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { Typography, Link, Grid, CircularProgress, Alert } from "@mui/material";
import {
  Overview,
  Testimonials,
  SectionTitleIcon,
  CompanyVideo,
} from "./components";
import { Line } from "../../../common";

import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import { IconLightPlay } from "app/v2/Pages/SVGs/IconLightPlay";
import img_icon_light_arrow_top from "app/v2/Pages/images/img_icon_light_arrow_top.svg";
import { scrollToTop } from "app/v2/Pages/Utils/utilFunctions";

import { messages } from "app/v2/Pages/Utils/constants";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "36px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },

  jobPostPreviewText: {
    fontWeight: 600,
    fontSize: "12px",
    color: theme.palette.secondary.main,
    marginBottom: "7px",
  },
  jobPreviewDiv: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    columnGap: "12px",
    marginTop: "7px",
    marginBottom: "36px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
}));

const agencyDummyData = {
  overview: {
    _id: 123,
    image: "/company_image.png",
    agencyName: "Haus Tech",
    linkedinUrl: "https://linkedin.com",
    facebookUrl: "https://facebook.com",
    activeTime: "2023-04-06T05:17:11.955Z",
    status: "available",
    consultantCount: 8,
    companySizeRange: { start: 15, end: 30 },
    website: "haustech.io",
    country: "Singapore",
    city: "Singapore City",
    state: "Central Singapore",
    timezone: "Singapore Standard Time (GMT + 8.0)",
    summary:
      "Nullam neque donec quam auctor porttitor. Tortor aliquet sit aliquet vel morbi commodo adipiscing. Enim turpis nisl rhoncus lectus cursus justo placerat magna. Morbi elementum scelerisque felis eros amet vel pellentesque et. Urna curabitur nisi consequat mollis arcu eu nunc dictum. Dignissim id aliquam justo ut ultrices pharetra. Sit est suspendisse laoreet sed. Tristique ut pulvinar sagittis ultricies. Diam ut vestibulum egestas ipsum elementum mi in posuere neque felis risus in egestas rhoncus.",
    companyVideoAvailable: true,
    backgroundCheck: true,
  },
  skills: [
    {
      skillID: "646f1c2f96656ed53aa3250d",
      skillLevelID: "649a7fce75ed0920604c595d",
    },
    {
      skillID: "646f1c2f96656ed53aa3238b",
      skillLevelID: "649a7fa875ed0920604c595b",
    },
    {
      skillID: "646f1c2f96656ed53aa324a3",
      skillLevelID: "649a7fbd75ed0920604c595c",
    },
  ],

  testimonials: [
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
  ],
  resumeDoc:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx", // https://findpro-s3.s3.us-east-2.amazonaws.com/1641827500408.pdf
  videoResume:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/1641827327575.mp4",
};

const leftNavData = [
  {
    icon: <IconLightUser className="w-[29px] h-[29px]" />,
    title: "Agency Overview",
    component: ({ agencyData }) => <Overview data={agencyData?.overview} />,
  },
  {
    icon: <IconLightFile className="w-[29px] h-[29px]" />,
    title: "Services Offered",
    component: ({ agencyData }) => (
      <SkillsPicker
        id="clientAgencyDetailsSP" // SP - Skills Picker
        data={agencyData?.skills}
        readOnly={true}
      />
    ),
  },
  {
    icon: <img src="/comment.svg" width="29" height="29" />,
    title: "Testimonials",
    component: ({ agencyData }) => (
      <Testimonials data={agencyData?.testimonials} />
    ),
  },
  {
    icon: <IconLightPlay className="w-[29px] h-[29px]" />,
    title: "Company Video",
    component: ({ agencyData }) => (
      <CompanyVideo data={agencyData?.videoResume} />
    ),
  },
];

const DetailsPage = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [agencyData, setAgencyData] = useState(agencyDummyData);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);

  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );

  ///////// scroll
  const sectionRefs = useRef([]);
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  const onNavItemClick = (navItemIndex) => {
    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  const isNotOverviewSection = (title) => title !== "Agency Overview";

  // Services
  useEffect(() => {}, []);

  // Services

  return (
    <Grid container>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <>
              <Grid item className={classes.leftView}>
                <div className={classes.backDiv}>
                  <ArrowBackIcon
                    onClick={() => {}}
                    className={classes.backIcon}
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                  <span>Back</span>
                </div>

                <div>
                  <Typography className={classes.jobPostPreviewText}>
                    JOB POST TO PREVIEW
                  </Typography>
                  <div className="text-[14px] font-semibold">
                    Job 27: Frontend Developer for Contractual Project
                  </div>
                </div>
                <div className={classes.jobPreviewDiv}>
                  <VisibilityIcon fontSize="small" />
                  View job
                </div>

                <hr />

                <div className={classes.navItemsDiv}>
                  {leftNavData?.map((navItemDetails, index) => (
                    <Link
                      key={"l" + index}
                      className={clsx([
                        classes.navItemLinkDiv,
                        selectedNavItemIndex === `nav-item-component-${index}`
                          ? classes.selectedNavItem
                          : classes.unselectedNavItem,
                      ])}
                      onClick={() => {
                        onNavItemClick(index);
                      }}
                    >
                      {navItemDetails?.icon}

                      <Typography className={classes.navItemTitle}>
                        {navItemDetails?.title}
                      </Typography>
                    </Link>
                  ))}
                </div>
              </Grid>
              <Grid item id="sectionContent" className={classes.rightView}>
                {leftNavData?.map((navItemDetails, index) => (
                  <>
                    <div
                      ref={(el) => (sectionRefs.current[index] = el)}
                      // className={`${
                      //   isNotOverviewSection(navItemDetails?.title) &&
                      //   "px-[24px]"
                      // }`}
                      id={`nav-item-component-${index}`}
                      key={`nav-item-component-${index}`}
                    >
                      {isNotOverviewSection(navItemDetails?.title) ? (
                        <SectionTitleIcon
                          title={navItemDetails?.title}
                          icon={navItemDetails?.icon}
                        />
                      ) : (
                        <></>
                      )}
                      {navItemDetails?.component({
                        agencyData,
                      })}
                    </div>
                    {!isLastNavItemComponent(index) && (
                      <Line className="bg-gray-300 h-px w-full my-[36px]" />
                    )}
                  </>
                ))}

                <div
                  className="flex items-center gap-x-[10px] pl-[12px] pt-[24px] cursor-pointer"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <img src={img_icon_light_arrow_top} />
                  <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                    Back to top
                  </div>
                </div>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

DetailsPage.propTypes = {};

export default DetailsPage;
