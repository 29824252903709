const JobDetailsObj = {
  "status_":"active|inactive|closed",
  "status":"active",
  "jobName":"Development Team Lead",
  "logo":"https://source.unsplash.com/250x250/?company-logo",
  "role": "Hiring Manager",
  "roleName": "Mike Fitz",
  "jobNo": "FP23117",
  "openPositions": 1,
  "acceptedApplicants": "2",
  "rejectedApplicants": 4,
  "rate": 30,
  "level": "Junior",
  "contract": "Part Time",
  "workType": "Hybrid",
  "totalApplicants": "36",
  "pending": "2",
  "shortlisted": 1,
  "interview1": 1,
  "interview2": 1,
  "testing": 1,
  "final": 2,
  "_created_at": "Yesterday",
  "_updated_at": "Today at 11:23",
  "teams": [
    {
      "teamName": "Master of 1",
      "teamProfileImage": "https://source.unsplash.com/random/38x38/?team 1"
    },
    {
      "teamName": "Master of 2",
      "teamProfileImage": "https://source.unsplash.com/random/38x38/?team 2"
    },
    {
      "teamName": "Master of Puppets",
      "teamProfileImage": "https://source.unsplash.com/random/38x38/?team 3" 
    },
    {
      "teamName": "The Thunderbolts",
      "teamProfileImage": "https://source.unsplash.com/random/38x38/?team 4"
    },
    {
      "teamName": "The Vortex Squad",
      "teamProfileImage": "https://source.unsplash.com/random/38x38/?team 5"
    },
    {
      "teamName": "The Phoenix Tribe",
      "teamProfileImage": "https://source.unsplash.com/random/38x38/?team 6"
    }
  ]
};
export { JobDetailsObj };
