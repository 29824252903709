import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../../../images/common";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    width: "100%",
    border: "1px solid #EDEDED",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    width: "100%",
    overflowX: "auto",
    paddingBottom: "10px",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const ListCard = () => {
  const history = useHistory();
  const classes = useStyles();
  const cardData = {
    _id: "123",
    image: "/company_image.png",
    name: "ABC Company",
    role: "Sr. Product Owner",
    matchRate: "86%",
    companySize: "14",
    location: "USA",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  };

  const [isBookMarkClicked, setIsBookMarkClicked] = useState(false);

  const handleBookMarkClick = () => {
    setIsBookMarkClicked(!isBookMarkClicked);
  };

  return (
    <div
      className={clsx([
        classes.listContainer,
        "self-stretch bg-neutral-white overflow-hidden flex flex-row py-[21px] px-[18px] items-center justify-center gap-[36px] text-center text-lg text-black font-button-text-button-14",
      ])}
    >
      <img
        className={classes.orgImage}
        alt={`org_${cardData?._id}_image`}
        src={cardData?.image ? cardData.image : "/org_placeholder.svg"}
      />
      <div className="w-[308px] flex flex-col items-start justify-center gap-[19px]">
        <div className="self-stretch flex flex-row items-end justify-start gap-[8px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative leading-[130%] font-semibold">
              {cardData?.name}
            </div>
            <div className="relative text-xs font-poppins text-neutral-500">
              {cardData?.role}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-3xs text-neutral-800">
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px] text-left">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Match rate
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-center">
              {cardData?.matchRate}
            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-500">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Company size
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.companySize}
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              location
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.location}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[422px] h-[106px] flex flex-col items-start justify-center gap-[16px] text-3xs text-neutral-500">
        <div className="flex flex-col items-start justify-start gap-[8px]">
          <div
            className={clsx([classes.sectionHeadingText, "relative uppercase"])}
          >
            technology skills
          </div>
          <div
            className={clsx([
              classes.skillsChipsDiv,
              "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
            ])}
          >
            {cardData.skills.map((skillName) => (
              <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                <div className="relative leading-[136%] font-medium">
                  {skillName}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="self-stretch relative text-xs leading-[136%] font-poppins text-left [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
          {cardData?.bio}
        </div>
      </div>
      <div className="flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-col items-start justify-start gap-[19px]">
          <button
            onClick={() => {
              history.push(`/client/agency-detail`);
              window.scrollTo(0, 0);
            }}
            className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 self-stretch flex flex-row items-center justify-center gap-[10px]"
          >
            <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-white text-center">
              View{" "}
              {cardData?.name
                ? cardData.name.length > 4
                  ? `${cardData.name.substring(0, 4)}...`
                  : cardData.name.split(" ")[0]
                : ""}
              's Profile
            </div>
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconlightup-right.svg"
            />
          </button>
          <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
            <button className="cursor-pointer py-2.5 px-5 bg-[transparent] flex-1 box-border h-10 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50">
              <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-300 text-center">
                Connect
              </div>
            </button>
            <button
              className={`cursor-pointer ${
                isBookMarkClicked
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={handleBookMarkClick}
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src={
                  isBookMarkClicked
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
            {/* <div className="hidden flex-row p-2.5 items-center justify-center border-[1px] border-solid border-neutral-50"> */}
            {/* <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src="/iconlightshare.svg"
              /> */}
            {/* <ShareSVG className="relative w-5 h-5 overflow-hidden shrink-0" /> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

ListCard.propTypes = {};

export default ListCard;
