import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";
import { Text } from "../../common";
import DashboardHero from "../common/DashboardHero";
import { ArrowRightAlt } from "@mui/icons-material";
import JobList from "../common/Cards/job/List";
import JobGrid from "../common/Cards/job/Grid";
import CustomButton from "app/v2/components/CustomButton";
import { CircularProgress, Alert } from "@mui/material";
import { dashboardHeroObj } from "./DashboardHero.dummy";
import { messages } from "../../Utils/constants";
import { isValidArray } from "../../Utils/utilFunctions";
import { useDispatch, useSelector } from "react-redux";
import { loginInfoThunk } from "../../common/login/login.thunk";
import CloseIcon from '@mui/icons-material/Close';

import {
  getAlljobs,
  getAppliedjobs,
  getMySavedjobs,
  getInvitedjobs,
} from "../../../services/consultant/myJobs.service";
import { getCandidateInfo } from "app/v2/services/consultant/profileSettings.service";
import { useSnackbar } from "notistack";
import { getJobsForConsultant } from "app/v2/services/consultant/common.service";

const useStyles = makeStyles((theme) => ({
  viewButton: {
    padding: "10px 20px 10px 15px",
    width: "126px",
    height: "38px",
    border: "1px solid #EDEDED",
    fontWeight: 600,
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  selectedViewButton: {
    backgroundColor: "#EDEDED !important",
    border: 0,
    color: theme.palette.secondary.light,
  },
  viewAllButton: {
    border: "1px solid #EDEDED",
    padding: "10px 15px 10px 20px",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    color: `${theme.palette.primary.main} !important`,

    fontSize: "14px",
    fontWeight: 600,
    height: "38px",
  },
}));
const dummyJobData = [
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 1",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 2",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 3",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    image: "/company_image.png",
    jobTitle: "Software Developer 4",
    companyName: "ABC Company",
    jobPostedDate: "Posted Yesterday",
    openPositions: "2",
    matchRate: "86%",
    experienceLevel: "junior",
    contractType: "Part time",
    workType: "Hybrid",
    salaryRange: "$30k - $50k/yr",
    location: "Singapore City, Singapore",
    timeZone: "SGST (GMT + 8.0)",
    jobDescription:
      "Nunc ultrices morbi in habitant. Nulla egestas sit at lobortis dictumst commodo sed sed. Maecenas quis quis eget et nulla. Id ut quisque lacus suscipit porta sem eget. Accumsan proin fermentum egestas ut mattis sit adipiscing vitae non. Amet sodales senectus placerat et sed. Bibendum venenatis non nunc donec integer sit. Vulputate aliquet ligula sit sit ut massa nullam amet. Nunc ullamcorper purus libero diam. Diam vulputate molestie pretium eget commodo quam eu egestas. A rhoncus quis integer sit. Velit nam cursus pharetra quis faucibus varius sagittis. Orci felis diam commodo ipsum duis molestie vel purus. Vel imperdiet et eget vitae ultricies. Suscipit quis vulputate hac urna faucibus duis.Bibendum diam enim mattis potenti tellus purus quam massa. Volutpat egestas in nunc blandit dui nunc nibh volutpat. Quisque dolor eget nisl nunc maecenas fringilla convallis vitae nisl. Volutpat id morbi urna mattis sagittis morbi egestas placerat. Sed ornare sodales tortor vehicula. Turpis quisque nunc massa mauris pharetra placerat nibh. Id turpis adipiscing morbi velit. Elementum a cras consequat volutpat neque ultrices. Dignissim risus donec ut proin. Diam ullamcorper pellentesque habitant amet pharetra viverra amet. Euismod penatibus est dolor adipiscing adipiscing adipiscing in turpis. Nulla ullamcorper nisi convallis iaculis.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
];
const DashboardPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const profile = location?.state;
  const dispatch = useDispatch();
  // const isProfileComplete = useSelector( (state) => state?.login_info?.value?.isProfileComplete );
  const { enqueueSnackbar,closeSnackbar } = useSnackbar();
  const [dashboardHeroData, setDashboardHeroData] = useState();
  const [savedJobs, setSavedJobs] = useState([]);
  const [recJobs, setRecJobs] = useState([]); // recommended jobs
  const [dataLoading, setDataLoading] = useState(true);
  const [dataLoadingRecJobs, setDataLoadingRecJobs] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [savedJobSelectedView, setSavedJobSelectedView] = useState("grid");
  const [recJobSelectedView, setRecJobSelectedView] = useState("grid");
  const [saveJobData, setSavejobData] = useState([]);
  const [UpdateSaveJobData, setUpdateSaveJobData] = useState(false);
  const onJobViewClick = (viewType, jobType) => {
    if (jobType === "saved") setSavedJobSelectedView(viewType);
    else setRecJobSelectedView(viewType);
  };
  const isJobGridViewSelected = (jobType) => {
    const isgridViewSelected =
      (jobType === "saved" ? savedJobSelectedView : recJobSelectedView) ===
      "grid";
    return isgridViewSelected;
  };
  // useEffect(() => {
  //   // dispatch(loginInfoThunk());
  //   getMySavedjobs()
  //   .then((res) => {
  //     if (res) {
  //       console.log( "res of saved jobs ", res );
  //       setSavedJobs(res)
  //     }
  //   })
  //   .catch((err) => {
  //     enqueueSnackbar("Problem in getting resume settings", {
  //       variant: "error",
  //     });
  //     console.error(err);
  //   });
  // }, []);
  const fetchData = async () => {
    setDataLoading(true);

    try {
      const res = await getMySavedjobs();
      if (res) {
        console.log("res of saved jobs ", res);
        setSavejobData(res);
        setDataLoading(false);
        setDataError(false);
      }
    } catch (err) {
      // enqueueSnackbar("Problem in getting saved jobs data settings", {
      //   variant: "error",
      // });
      const key = enqueueSnackbar("Problem in getting saved jobs data settings", {
        variant: "error",  autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
        ),
      });
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [UpdateSaveJobData]);

  // useEffect(() => {

  //   const fetchJobs = async () => {
  //     try {
  //       await getJobsForConsultant()
  //         .then((res) => {
  // setRecJobs(res.matched);
  // setDataLoading(false);
  // setDataError(false);
  //         })
  //         .catch((error) => {
  //           setDataLoading(false);
  //           setDataError(true);

  //         });
  //     } catch (error) {
  //       // Error
  //       enqueueSnackbar("Problem in getting recommendation jobs ", {
  //         variant: "error",
  //       });
  //     }
  //   };
  //   fetchJobs();
  // }, []);

  const fetchRecomJobs = async () => {
    setDataLoadingRecJobs(true);

    try {
      const res = await getJobsForConsultant();
      if (res) {
        console.log("res of saved jobs ", res);
        setRecJobs(res.matched);
        // setDataLoading(false);
        // setDataError(false);
        setDataLoadingRecJobs(false);
      }
    } catch (err) {
      // enqueueSnackbar("Problem in getting saved jobs data settings", {
      //   variant: "error",
      // });

      const key = enqueueSnackbar("Problem in getting saved jobs data settings", {
        variant: "error",  autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
        ),
      });
      console.error(err);
    }
  };
  useEffect(() => {
    fetchRecomJobs();
  }, [UpdateSaveJobData]);

  useEffect(() => {
    // dispatch(loginInfoThunk());
    getCandidateInfo()
      .then((res) => {
        if (res) {
          // console.log("res ", res);
          setDashboardHeroData(res);
        }
      })

      .catch((err) => {
        // enqueueSnackbar("Problem in getting resume settings", {
        //   variant: "error",
        // });

        const key = enqueueSnackbar("Problem in getting resume settings", {
          variant: "error",  autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
          ),
        });
        console.error(err);
      });
  }, []);
  return (
    <>
      {dataError ? (
        <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
      ) : (
        <div className="max-w-[1440px] w-full mx-auto pt-0">
          <div className="bg-white flex flex-col sm:gap-10 md:gap-10 gap-[101px] items-start justify-start mx-auto p-[45px] md:px-10 sm:px-5 w-full">
            <DashboardHero
              props={dashboardHeroData}
              // Profile={profile}
            />
            {/** Saved Jobs */}
            <div className="flex flex-col gap-[33px] items-start justify-center  mx-auto w-full xl:max-w-[1920px] bg-white z-10">
              <Text className="text-[32px] font-semibold">My Saved Jobs</Text>

              {isValidArray(saveJobData) ? (
                <>
                  <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between  w-full">
                    <div className="flex flex-row gap-4 items-center justify-end w-auto">
                      <CustomButton
                        customClasses={clsx([
                          classes.viewButton,
                          isJobGridViewSelected("saved") &&
                            classes.selectedViewButton,
                        ])}
                        onClick={() => {
                          onJobViewClick("grid", "saved");
                        }}
                      >
                        <img
                          src="/grid-view.svg"
                          className="mt-px mb-0.5 mr-2.5"
                        />
                        <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                          Grid View
                        </div>
                      </CustomButton>
                      <CustomButton
                        customClasses={clsx([
                          classes.viewButton,
                          !isJobGridViewSelected("saved") &&
                            classes.selectedViewButton,
                        ])}
                        onClick={() => {
                          onJobViewClick("list", "saved");
                        }}
                      >
                        <img
                          src="/list-view.svg"
                          className="mt-px mb-0.5 mr-2.5"
                        />
                        <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                          List View
                        </div>
                      </CustomButton>
                    </div>
                    <CustomButton
                      customClasses={classes.viewAllButton}
                      type="button2"
                      onClick={() => {
                        history.push("/consultant/my-jobs", {
                          jobTab: "Saved",
                        });
                      }}
                    >
                      View All
                      <ArrowRightAlt />
                    </CustomButton>
                  </div>
                  <div
                    className={`flex ${
                      isJobGridViewSelected("saved")
                        ? "flex-row overflow-x-auto"
                        : "flex-col overflow-y-auto w-full "
                    } gap-x-[16px] items-start justify-start  pr-[12px] pb-[12px]`}
                  >
                    {dataLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {dataError ? (
                          <Alert severity="error">
                            {messages?.NO_DATA_FOUND}{" "}
                          </Alert>
                        ) : (
                          <>
                            {isJobGridViewSelected("saved") &&
                            isValidArray(saveJobData) ? (
                              saveJobData && saveJobData.length > 0 ? (
                                saveJobData
                                  ?.slice(0, 4)
                                  .map((jobPostData, index) => (
                                    <JobGrid
                                      key={index}
                                      data={jobPostData}
                                      setUpdateSaveJobData={
                                        setUpdateSaveJobData
                                      }
                                    />
                                  ))
                              ) : (
                                <Alert severity="info">
                                  {messages?.NO_DATA_FOUND}{" "}
                                </Alert>
                              )
                            ) : isValidArray(saveJobData) &&
                              saveJobData.length > 0 ? (
                              saveJobData
                                .slice(0, 4)
                                .map((jobPostData, index) => (
                                  <JobList
                                    key={index}
                                    data={jobPostData}
                                    setUpdateSaveJobData={setUpdateSaveJobData}
                                  />
                                ))
                            ) : (
                              <Alert severity="info">
                                {messages?.NO_DATA_FOUND}{" "}
                              </Alert>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
              )}
            </div>
            {/** Recommended Jobs */}
            <div className="flex flex-col gap-[33px] items-start justify-center  mx-auto w-full xl:max-w-[1920px] ">
              <Text className="text-[32px] font-semibold">
                Recommended Jobs For You
              </Text>
              {isValidArray(recJobs) ? (
                <>
                  <div className="flex sm:flex-col flex-row md:gap-10 items-center justify-between  w-full">
                    <div className="flex flex-row gap-4 items-center justify-end w-auto">
                      <CustomButton
                        customClasses={clsx([
                          classes.viewButton,
                          isJobGridViewSelected("recommended") &&
                            classes.selectedViewButton,
                        ])}
                        onClick={() => {
                          onJobViewClick("grid", "recommended");
                        }}
                      >
                        <img
                          src="/grid-view.svg"
                          className="mt-px mb-0.5 mr-2.5"
                        />
                        <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                          Grid View
                        </div>
                      </CustomButton>
                      <CustomButton
                        customClasses={clsx([
                          classes.viewButton,
                          !isJobGridViewSelected("recommended") &&
                            classes.selectedViewButton,
                        ])}
                        onClick={() => {
                          onJobViewClick("list", "recommended");
                        }}
                      >
                        <img
                          src="/list-view.svg"
                          className="mt-px mb-0.5 mr-2.5"
                        />
                        <div className="font-semibold leading-[normal] text-center text-gray-900 text-sm">
                          List View
                        </div>
                      </CustomButton>
                    </div>
                    <CustomButton
                      customClasses={classes.viewAllButton}
                      type="button2"
                      onClick={() => {
                        history.push("/consultant/job-feed");
                      }}
                    >
                      View All
                      <ArrowRightAlt />
                    </CustomButton>
                  </div>
                  <div
                    className={`flex ${
                      isJobGridViewSelected("recommended")
                        ? "flex-row overflow-x-auto"
                        : "flex-col overflow-y-auto "
                    } gap-x-[16px] items-start justify-start w-full pr-[12px] pb-[12px]`}
                  >
                    {dataLoadingRecJobs ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {dataError ? (
                          <Alert severity="error">
                            {messages?.GENERIC_ERROR_MESSAGE}{" "}
                          </Alert>
                        ) : (
                          <>
                            {isJobGridViewSelected("recommended")
                              ? recJobs?.map((jobPostData, index) => (
                                  <JobGrid
                                    data={jobPostData}
                                    setUpdateSaveJobData={setUpdateSaveJobData}
                                  />
                                ))
                              : recJobs?.map((jobPostData, index) => (
                                  <JobList
                                    data={jobPostData}
                                    setUpdateSaveJobData={setUpdateSaveJobData}
                                  />
                                ))}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <Alert severity="info"> {messages?.NO_DATA_FOUND} </Alert>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
DashboardPage.propTypes = {};
export default DashboardPage;
