import React from "react";
import PropTypes from "prop-types";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomLabel from "./CustomLabel";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  datePickerRoot: {
    display: "flex",
    flexDirection: "column",
  },
}));

const CustomDatePicker = (props) => {
  const classes = useStyles();
  const { label, placeholder, customClasses } = props;
  return (
    <div className={classes.datePickerRoot}>
      {label && <CustomLabel label={label} />}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={placeholder}
            className={customClasses}
            disablePast
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

CustomDatePicker.propTypes = {};

export default CustomDatePicker;
