import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomLabel from "./filters/CustomLabel";

const useStyles = makeStyles((theme) => ({
  radioLabelRoot: {
    margin: "0 0 14px 0",
  },
  radioLabel: {
    fontSize: "14px",
  },
  radioButton: {
    color: "black !important",
    padding: "0 8px 0 0",
  },
}));

const CustomRadioGroup = ({
  id,
  name,
  label,
  defaultValue,
  data,
  onChange,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(id, event.target.value);
  };
  return (
    <FormControl>
      {label && <CustomLabel label={label} />}
      <RadioGroup
        value={defaultValue}
        name={name}
        sx={{ my: 1 }}
        onChange={handleChange}
      >
        {data?.map((radioButton, idx) => (
          <FormControlLabel
            key={idx}
            value={radioButton?.value}
            control={<Radio classes={{ root: classes.radioButton }} />}
            label={radioButton?.label}
            classes={{
              root: classes.radioLabelRoot,
              label: classes.radioLabel,
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

CustomRadioGroup.propTypes = {};

export default CustomRadioGroup;
