import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Slider } from "@mui/material";
import CustomLabel from "./CustomLabel";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  sliderRoot: {
    height: "3px",
    color: theme.palette.common.black,
    // paddingLeft: "4px",
    touchAction: "pan-y",
  },
  box: {
    width: "fit-content",
  },
}));

const CustomSlider = (props) => {
  const classes = useStyles();
  let {
    id,
    onChange,
    label,
    min,
    max,
    selectedValue,
    startText,
    customClasses,
    disabled,
  } = props;

  const handleChange = (event, newValue) => {
    onChange(id, newValue);
  };
  const valueLabelFormat = (value) => `${startText} ${value}`;

  return (
    <Box className={classes.box}>
      {label && <CustomLabel label={label} />}
      <Slider
        key={id}
        value={selectedValue ? selectedValue : 0}
        valueLabelFormat={startText && valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto" // display min,max selection always
        min={min}
        max={max}
        className={clsx([classes.sliderRoot, customClasses])}
        disabled={disabled}
        // classes={{ root:  }}
      />
    </Box>
  );
};

CustomSlider.propTypes = {};

export default CustomSlider;
