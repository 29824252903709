import dayjs from "dayjs";

export const isValidArray = (data) => {
  return Array.isArray(data) && data.length > 0;
};

export const isFunction = (valueReceived) => {
  return typeof valueReceived === "function";
};

export const isValidObject = (data) => {
  return (
    typeof data === "object" &&
    data !== null &&
    !Array.isArray(data) &&
    Object.keys(data).length > 0
  );
};

export const isUndefined = (data) => {
  return data === undefined;
};

export const getAccountType = () => {
  if (document.location && document.location.pathname) {
    const splitPathName = document.location.pathname.split("/");
    if (splitPathName.length > 1) {
      let accountType = splitPathName[1];

      return accountType;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const removeQDataFromLocalStorage = () => {
  localStorage.removeItem("questionnaireData");
}; // QData - questionnaire data.

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

export const checkIsTodaysDate = (d) => {
  const inputDate = new Date(d);
  const todaysDate = new Date();
  if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};

export const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

export const toCamelCase = (inputString) => {
  // Split the inputString into words based on spaces and hyphens
  const words = inputString?.split(/[\s-]+/);

  // Convert the first word to lowercase and capitalize the rest of the words
  const camelCaseWords = words?.map((word, index) => {
    if (index === 0) {
      return word?.toLowerCase();
    }
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  });

  // Join the words to form the camel case string
  const camelCaseString = camelCaseWords?.join("");

  return camelCaseString;
};

export const getFileExtensionFromFileUrlorName = (urlOrName) => {
  const extension = urlOrName.split(".").pop(); // Extract the file extension
  return extension.toLowerCase(); // Convert to lowercase for consistency
};

export const getBlobUrlFromFile = (file) => {
  return URL.createObjectURL(file);
}; // useful in resume file(doc types i.e, pdf,doc etc) uploads.

export const isValidString = (str) => {
  return typeof str === "string" && str.trim() !== "";
};

// Below function is specifically for file based s3 urls, and it also removed uri encoded characters(%20 in below eg).
// Eg : "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx";
export const getFileNameFromPublicUrlFile = (url) => {
  console.log({ url });
  if (url) {
    const urlParts = url?.split("/");
    const fileNameWithJunk = urlParts[urlParts?.length - 1];
    const fileName = decodeURIComponent(fileNameWithJunk);
    return fileName;
  }
  return "";
};

export const isValidISODateFormat = (dateString) => {
  try {
    return new Date(dateString).toISOString() === dateString;
  } catch (error) {
    return false;
  }
};

export const isTimeStringValidFormat = (timeString, format) => {
  timeString = timeString === "Invalid Date" ? "" : timeString;
  return (
    isValidString(timeString) &&
    isValidString(format) &&
    dayjs(timeString, format).format(format) === timeString
  );
};

export const isDateStringValidFormat = (dateString, format) => {
  dateString = dateString === "Invalid Date" ? "" : dateString;
  return (
    isValidString(dateString) &&
    isValidString(format) &&
    dayjs(dateString, { format, strict: true }).isValid()
  );
};

export const isValidArrayIndex = (index, array) => {
  index = Number(index);

  // Check if the index is a valid number and within the range
  return Number.isInteger(index) && index >= 0 && index < array?.length;
};

export const isValidAlphaNumericID = (value, array) => {
  const ids = array?.map((item) => item._id);
  return ids.includes(value);
};

export const camelCaseToTitleCase = (camelCaseString) => {
  // Add space before capital letters and then capitalize each word
  return isValidString(camelCaseString)
    ? camelCaseString.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      })
    : "";
};
