import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EmployeeDetailssection from "./EmployeeDetailssection";
import { makeStyles } from "@mui/styles";
import CustomDialog from "app/v2/components/CustomDialog";
import AddEmployee from "./AddEmployee";
import { useSnackbar } from "notistack";
import { getAllEmployeeInfoThunk } from "app/v2/store/features/employer/employee.thunk";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Alert } from "@mui/material";
import { messages } from "../../Utils/constants";
import DeleteJobDialog from "../common/DeleteJobDialog";
import DeleteEmployeeDialog from "../common/DeleteEmployeeDialog";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  nameLabels: {
    fontSize: "10px",
    color: "#717171",
    fontWeight: 700,
  },
  labelValue: {
    color: "#0B0E1E",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "24px",
  },
}));
const members = [
  {
    name: "Safi Romano",
    first_name: "Safi",
    last_name: "Romano",
    userRole: "PROJECT MANAGER",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Julia Porter",
    first_name: "Julia",
    last_name: "Porter",
    userRole: "PROJECT MANAGER",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Mark King",
    first_name: "Julia",
    last_name: "Porter",
    userRole: "PROJECT MANAGER",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Larry Samberg",
    first_name: "Julia",
    last_name: "Porter",
    userRole: "PROJECT MANAGER",
    location: "Cario Egypt",
    status: "Deactivated",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Troy Montero",
    first_name: "Troy",
    last_name: "Montero",
    userRole: "PROJECT MANAGER",
    location: "Cario Egypt",
    status: "Deactivated",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
  {
    name: "Klair Valdez",
    first_name: "Klair",
    last_name: "Valdez",
    userRole: "PROJECT MANAGER",
    location: "Cario Egypt",
    status: "Active",
    roleType: "Recruiter",
    jobsManaging: "20",
    totalJobsManaged: "123",
    createdDate: "April 2 2023",
    lastLoggedIn: "April 26,2023",
  },
];

const EmployeesListCard = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const [teamMembers, setTeamMembers] = useState(members);
  const classes = useStyles();
  const [selectedEmployeeIsClicked, setSelectedEmployeeIsClicked] =
    useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const [addEmployeeDialogOpen, setAddEmployeeDialogOpen] = useState(false);
  const [editEmployeeDialogOpen, setEditEmployeeDialogOpen] = useState(false);
  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const [deleteActionCompleted, setDeleteActionCompleted] = useState(false);
  const [deleteActionError, setDeleteActionError] = useState(false);
  const [updateGetAllEmployeeInfoApi, setUpdateGetAllEmployeeInfoApi] =
    useState(false);

  const handleDialoguesAndApi = () => {
    setUpdateGetAllEmployeeInfoApi((prev) => !prev);
    setAddEmployeeDialogOpen(false);
    setEditEmployeeDialogOpen(false);
  };

  const onDeleteClick = () => {
    setDeleteActionLoading(false); //later on after api integration change it to true

    setTimeout(() => {
      /** Confirm case */
      setDeleteActionLoading(false);
      setDeleteActionCompleted(true);
      /** Error case */
      // setDeleteActionLoading(false);
      // setDeleteActionError(true);
    }, 2000);
    //above setieout is just for testing delete it once we inegrate Api

    //call the below api when api is ready to delete employee

    // dispatch(deleteJobByIdThunk(jobData?._id))
    //   .unwrap()
    //   .then((result) => {
    //     console.log("Job deleted successfully:", result);
    //     setDeleteActionLoading(false);
    //     setDeleteActionCompleted(true);
    //     isFunction(onJobDelete) && onJobDelete(jobData?._id);
    //  setUpdateGetAllEmployeeInfoApi(true)
    //   })
    //   .catch((error) => {
    //     setDeleteActionLoading(false);
    //     setDeleteActionError(true);
    //     console.error("Error deleting job:", error);
    //   });
  };

  const handleCardClick = (employee) => {
    // setSelectedEmployeeIsClicked(true); donot delete this it will be used in futureversions
    setSelectedEmployee(employee);
    setEditEmployeeDialogOpen(true);
  };

  const handleBackClick = () => {
    setSelectedEmployeeIsClicked(false);
    setSelectedEmployee([]);
  };

  //edsss
  useEffect(() => {
    dispatch(getAllEmployeeInfoThunk())
      .unwrap()
      .then((res) => {
        if (res && res?.success === true) {
          setAllEmployee(res?.data?.employees);
        }
      })
      .catch((err) => {
        if (err.message) {
          // enqueueSnackbar(err.message, { variant: "error" });
          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        } else {
          // enqueueSnackbar("Problem in fetching data!", { variant: "error" });
          const key = enqueueSnackbar("Problem in fetching data!", {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  }, [updateGetAllEmployeeInfoApi]);

  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteActionLoading(false);
      setDeleteActionCompleted(false);
      setDeleteActionError(false);
    }
  }, [deleteJobDialogOpen]);

  const role_Id = localStorage.getItem("roleId");
  useEffect(() => {
    const isUserAdmin = role_Id.includes(`653c12fe73a9fecb9a49b283`);
    setIsAdmin(isUserAdmin);
  }, []);
  return (
    <div style={{ width: "100%" }}>
      {selectedEmployeeIsClicked ? (
        <EmployeeDetailssection
          handleBackClick={handleBackClick}
          selectedEmployee={selectedEmployee}
        />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "957px",
              marginBottom: "36px",
            }}
          >
            <div
              style={{ fontSize: "32px", fontWeight: 600, color: "#0B0E1E" }}
            >
              {" "}
              Employees
            </div>

            <button
              style={{
                // border: "1px solid gray",
                background: "black",
                color: "white",
                fontSize: "16px",
                fontWeight: 600,
                width: "169px",
                height: "44px",
              }}
              onClick={() => {
                setAddEmployeeDialogOpen(true);
              }}
            >
              Add Employees
            </button>
          </div>

          {/* {teamMembers.map((team) => ( */}
          {allEmployee ? (
            allEmployee?.map((employee) => (
              <Grid
                container
                spacing={2}
                // onClick={() => handleCardClick(employee)}
                sx={{
                  borderBottom: "1px solid lightgray",
                  // marginTop: "10px",
                  fontSize: "24px",
                  fontWeight: 600,
                  width: "100%",
                  height: "auto",
                  marginBottom: "24px",
                  paddingTop: "24px",
                  paddingBottom: "24px",
                  marginLeft: "0px",
                }}
              >
                <Grid
                  sx={{
                    width: "208px",
                    marginRight: "36px",
                    paddingTop: "15px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "24px",
                      width: " 208px",
                    }}
                    variant="subtitle1"
                  >
                    {employee?.firstName + " " + employee?.lastName}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: "12px",
                      marginBottom: "16px",
                      fontWeight: 600,
                      lineHeight: 1,
                      color: "#0B0E1E",
                    }}
                  >
                    {employee?.companyTitle}
                  </Typography>
                  {employee?.createAt && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: "12px",
                        color: "#717171",
                        fontWeight: 400,
                      }}
                    >
                      {/* Added {employee.createAt} */}
                      Added{" "}
                      {employee?.createdAt
                        ? moment(employee?.createdAt).format(
                            "MMMM D, YYYY, h:mm A"
                          )
                        : "-"}
                    </Typography>
                  )}
                  {/* {employee.lastLoggedIn && ( */}
                  {employee?.updatedAt && (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: "12px",
                        color: "#717171",
                        fontWeight: 400,
                      }}
                    >
                      {/* Last logged in {team.lastLoggedIn} */}
                      Last logged in
                      <p>
                        {employee?.updatedAt
                          ? moment(employee?.updatedAt).format(
                              "MMMM D, YYYY, h:mm A"
                            )
                          : "-"}
                      </p>
                    </Typography>
                  )}
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "spacebetween",
                    width: "25%",
                  }}
                >
                  <Grid sx={{ width: "288px" }}>
                    <Typography
                      variant="subtitle2"
                      //   sx={{  fontSize: "10px" ,color:"#717171",fontWeight:700}}
                      className={classes.nameLabels}
                    >
                      LOCATION
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.labelValue}
                    >
                      {/* {team.location} */}
                      {employee?.country}
                    </Typography>
                  </Grid>
                  {employee?.status && (
                    <Grid sx={{ width: "288px" }}>
                      <Typography
                        variant="subtitle2"
                        className={classes.nameLabels}
                      >
                        STATUS
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          marginBottom: "24px",
                          color:
                            employee?.status === "Active"
                              ? "#14C042"
                              : "#ABABAB",
                        }}
                      >
                        {employee?.status}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "spacebetween",
                    marginRight: "36px",
                  }}
                >
                  <Grid>
                    <Typography
                      variant="subtitle2"
                      //   sx={{  fontSize: "10px" ,color:"#717171",fontWeight:700}}
                      className={classes.nameLabels}
                    >
                      USER ROLE
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.labelValue}
                    >
                      {employee?.role}
                    </Typography>
                  </Grid>
                  {employee?.analitics?.jobsManaged && (
                    <Grid
                      sx={{ display: "flex", marginLeft: "0px", gap: "5px" }}
                    >
                      <Grid>
                        <Typography
                          variant="subtitle2"
                          className={classes.nameLabels}
                        >
                          JOBS MANAGING
                        </Typography>
                        <Typography className={classes.labelValue}>
                          {employee?.analitics?.jobsManaged}{" "}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          variant="subtitle2"
                          className={classes.nameLabels}
                        >
                          TOTAL JOBS MANAGED
                        </Typography>
                        <Typography className={classes.labelValue}>
                          {employee?.analitics?.totalJobsManaged}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={2} style={{ display: "flex" }}>
                  <Tooltip title={isAdmin ? "" : "Restricted to Admin"}>
                    <Grid sx={{ width: "100%", height: "30%" }}>
                      <Button
                        disabled={!isAdmin} // Disable the button if user is not an admin
                        sx={{
                          p: 1,
                          border: "1px solid lightgray",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "white !important",
                          textAlign: "center",
                          color: "#0B0E1E",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                        onClick={() => isAdmin && handleCardClick(employee)}
                      >
                        Manage
                      </Button>
                    </Grid>
                  </Tooltip>

                  <Grid item xs={1} style={{ alignContent: "center" }}>
                    <Tooltip title={isAdmin ? "" : "Restricted to Admin"}>
                      <RemoveCircleOutlineIcon
                        disabled={!isAdmin} // Disable the icon if user is not an admin
                        style={{
                          padding: "5px",
                          border: "1px solid lightgray",
                          alignContent: "center",
                          textAlign: "center",
                          height: "40px",
                          width: "40px",
                          marginLeft: "20px",
                          cursor: isAdmin ? "pointer" : "",
                          opacity: isAdmin ? 1 : 0.5,
                        }}
                        onClick={() => isAdmin && setDeleteJobDialogOpen(true)}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>

                {/* <Grid item xs={2} style={{ display: "flex" }}>
                  <Button
                    sx={{
                      p: 1,
                      border: "1px solid lightgray",
                      width: "100%",
                      height: "30%",
                      backgroundColor: "white !important",
                      textAlign: "center",
                      color: "#0B0E1E",
                      fontSize: "16px",
                      fontWeight: 600,
                    }}
                    onClick={() => handleCardClick(employee)}
                  >
                    Manage
                  </Button>

                  <Grid item xs={1} style={{ alignContent: "center" }}>
                    <RemoveCircleOutlineIcon
                      style={{
                        padding: "5px",
                        border: "1px solid lightgray",
                        alignContent: "center",
                        textAlign: "center",
                        height: "40px",
                        width: "40px",
                        marginLeft: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setDeleteJobDialogOpen(true)}
                    />
                  </Grid>
                </Grid> */}

                {/* <Grid item xs={4}>
            <Grid style={{ borderBottom: "4px" }}>
              <Typography variant="subtitle2" className={classes.nameLabels}>
                USEROLE
              </Typography>
              <Typography variant="subtitle2" className={classes.labelValue}>
               
              </Typography>
            </Grid>
            <Grid
              sx={{
                height: "50px",
                p: 2,
                width: "70%",
                // background: "#f5f5f5",
                fontSize: "5px",
              }}
            >
              <Grid container spacing={2}>
                {team.jobsManaging && (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.nameLabels}
                    >
                      JOBS MANAGING
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.labelValue}
                    >
                      {team.jobsManaging}
                    </Typography>
                  </Grid>
                )}

                {team.totalJobsManaged && (
                  <Grid item xs={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.nameLabels}
                    >
                      TOTALJOBSMANAGED
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.labelValue}
                    >
                      {team.totalJobsManaged}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid> */}
                {/* {team.totalJobsManaged && (
            <Grid item xs={2}>
              <Typography
                sx={{
                  p: 1,
                  border: "1px solid lightgray",
                  width: "100%",
                  marginTop: "30px",
                  alignContent: "center",
                  textAlign: "center",
                }}
                variant="subtitle2"
              >
                Manager
              </Typography>
            </Grid>
          )}
          {team.totalJobsManaged && (
            <Grid item xs={1}>
              <RemoveCircleOutlineIcon
                style={{
                  marginTop: "30px",
                  padding: "5px",
                  border: "1px solid lightgray",
                  marginTop: "35px",
                  alignContent: "center",
                  textAlign: "center",
                }}
              />
            </Grid>
          )} */}
              </Grid>
            ))
          ) : (
            <Alert severity="info">
              No team members exist, invite your team to FindPro!{" "}
            </Alert>
          )}

          <CustomDialog
            open={addEmployeeDialogOpen}
            setOpen={setAddEmployeeDialogOpen}
            title={
              <div className="text-[16px] font-semibold text-center">
                Add Employee
              </div>
            }
            // title={<AddConsultantDialogTitle title="Add Consultant" />}
            content={
              <AddEmployee handleDialoguesAndApi={handleDialoguesAndApi} />
            }
          />
          <CustomDialog
            open={editEmployeeDialogOpen}
            setOpen={setEditEmployeeDialogOpen}
            title={
              <div className="text-[16px] font-semibold text-center">
                Edit Employee
              </div>
            }
            // title={<AddConsultantDialogTitle title="Add Consultant" />}
            content={
              <AddEmployee
                handleDialoguesAndApi={handleDialoguesAndApi}
                selectedEmployee={selectedEmployee}
              />
            }
          />
          {deleteJobDialogOpen && (
            <DeleteEmployeeDialog
              open={deleteJobDialogOpen}
              setOpen={setDeleteJobDialogOpen}
              employeeDetails={{
                employeeId: selectedEmployee?._id,
                employeeName: "Employee",
              }}
              onDeleteClick={onDeleteClick}
              deleteLoading={deleteActionLoading}
              deleteCompleted={deleteActionCompleted}
              deleteError={deleteActionError}
            />
          )}
        </>
      )}
    </div>
  );
};

EmployeesListCard.propTypes = {};

export default EmployeesListCard;
