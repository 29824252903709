import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const updateActiveConsultantOnboardingActivity = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/updateActiveConsultantOnboardingActivity`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    }
  );
};
