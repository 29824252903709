import CustomButton from "app/v2/components/CustomButton";
import { updateInvoiceSettings } from "app/v2/services/client/timesheet.service";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import CountryList from "app/markup/country.json";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import { TextField } from "@mui/material";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";

const InvoiceSettings = ({ invoiceSettingsDetails, activeWorkId }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const CountryListData = CountryList.map((country) => country.country);
  const [statesData, setStatesData] = useState([]);
  const paymentTypes = ["hourly", "monthly"];
  const InvoiceFreqData = ["weekly", "biweekly", "monthly"];

  const [invoiceSettings, setInvoiceSettings] = useState({});
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);


  useEffect(() => {
    
    const initialSettings = {
      paymentType: invoiceSettingsDetails?.paymentType??"",
      hourlyRate: invoiceSettingsDetails?.hourlyRate ?? "",
      minWorkHoursForMonth: invoiceSettingsDetails?.minWorkHoursForMonth ?? "",
      paymentTermsInDays:invoiceSettingsDetails?.paymentTermsInDays??"",
      invoiceFrequency: invoiceSettingsDetails?.invoiceFrequency ?? "",
      comissionRate: 10,
      taxRate: invoiceSettingsDetails?.taxRate ?? "",
      streetAddress:
        invoiceSettingsDetails?.billingAddress?.address ?? "",
      city: invoiceSettingsDetails?.billingAddress?.city ?? "",
      country: invoiceSettingsDetails?.billingAddress?.country ?? "",
      state: invoiceSettingsDetails?.billingAddress?.state ?? "",
      pincode: invoiceSettingsDetails?.billingAddress?.zipcode ?? "",
      fullName: invoiceSettingsDetails?.clientInvoiceContact?.fullName ?? "",
      companyRole:
        invoiceSettingsDetails?.clientInvoiceContact?.companyRole ?? "",
      email: invoiceSettingsDetails?.clientInvoiceContact?.email ?? "",
      phone: invoiceSettingsDetails?.clientInvoiceContact?.phone ?? "",
    };

    setInvoiceSettings(initialSettings);
    setUpdateButtonDisabled(true);
  }, [activeWorkId, invoiceSettingsDetails]);

  const handleChange = (name, value) => {
    // const { name, value } = e.target;
    setInvoiceSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));

    setUpdateButtonDisabled(false);
  };

  //   const onCountryChange = (country) => {
  //     setInvoiceSettings((prevSettings) => ({
  //         ...prevSettings,
  //         [country]: country,
  //       }));
  //   const countryData = CountryList.find((entry) => entry?.country === country);
  //   const statesOfCountry = countryData?.states ?? [];
  //   console.log({ country, statesOfCountry });
  //   setStatesData(statesOfCountry);
  //   };

  useEffect(() => {
    const countryData = CountryList.find(
      (entry) => entry?.country === invoiceSettings?.country
    );
    const statesOfCountry = countryData?.states ?? [];
    // console.log({ country, statesOfCountry });
    setStatesData(statesOfCountry);
  }, [invoiceSettings.country,]);

  const handleSubmit = () => {
    const request = {
      id: activeWorkId,
      paymentType: invoiceSettings?.paymentType,
      hourlyRate: invoiceSettings?.paymentType === "hourly" ? invoiceSettings?.hourlyRate : 0,
      minWorkHoursForMonth: invoiceSettings?.paymentType === "monthly" ? invoiceSettings?.minWorkHoursForMonth : 0,
      paymentTermsInDays:invoiceSettings?.paymentTermsInDays,
      invoiceFrequency: invoiceSettings?.invoiceFrequency,
      comissionRate:invoiceSettings?.comissionRate,
      taxRate:invoiceSettings?.taxRate,
      billingAddress: {
        address: invoiceSettings?.streetAddress,
        country: invoiceSettings?.country,
        state: invoiceSettings?.state,
        city: invoiceSettings?.city,
        zipcode: invoiceSettings?.pincode,
      },
      clientInvoiceContact: {
        fullName: invoiceSettings.fullName,
        companyRole: invoiceSettings.companyRole,
        email: invoiceSettings.email,
        phone: invoiceSettings.phone,
      },
    };

    const key = enqueueSnackbar(`Your action in progress. Please wait.`, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });

    updateInvoiceSettings(request)
      .then((res) => {
        if (res?.success === true) {
          const key = enqueueSnackbar(res?.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setUpdateButtonDisabled(true);
        } else {
          // Handle error cases
        }
      })
      .catch(() => {
        const key = enqueueSnackbar(
          `Your action was not successful. Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };
  console.log("invoice values", invoiceSettings);

  return (
    <div className="flex flex-col space-y-8">
      <div className="flex justify-between space-x-3">
        <div className="flex items-center w-[13%] space-x-2">
          <CustomSelect
            id="paymentType"
            name="paymentType"
            data={paymentTypes}
            label="Payment Type"
            onChange={handleChange}
            defaultSelection={invoiceSettings?.paymentType}
          />
        </div>

        <div className="flex items-center w-[20%] space-x-2">
          {invoiceSettings?.paymentType === "hourly" && (
            <CustomTextfield
              id="hourlyRate"
              type="number"
              name="hourlyRate"
              label="hourly Rate"
              value={invoiceSettings?.hourlyRate}
              onChange={handleChange}
            />
          )}

          {invoiceSettings?.paymentType === "monthly" && (
            <CustomTextfield
              id="minWorkHoursForMonth"
              type="number"
              name="minWorkHoursForMonth"
              label="Min Work Hours for Month"
              value={invoiceSettings?.minWorkHoursForMonth}
              onChange={handleChange}
            />
          )}
        </div>

        <div className="flex items-center w-40 space-x-2">
          <CustomSelect
            id="invoiceFrequency"
            name="invoiceFrequency"
            label="Invoice Frequency"
            data={InvoiceFreqData}
            value={invoiceSettings?.invoiceFrequency}
            onChange={handleChange}
            defaultSelection={invoiceSettings?.invoiceFrequency}
            // className="border border-gray-300 rounded-md"
          />
        </div>

         <div className="flex-1 flex items-center space-x-2">
          <CustomTextfield
            id="paymentTermsInDays"
            type="text"
            label="PAYMENT TERMS"
            name="paymentTermsInDays"
            value={invoiceSettings?.paymentTermsInDays}
            onChange={handleChange}
            disabled={true}

            // className="border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="flex-1 flex items-center space-x-2">
          <CustomTextfield
            id="comissionRate"
            type="text"
            name="comissionRate"
            label="Commission Rate(%)"
            value={invoiceSettings?.comissionRate}
            onChange={handleChange}
            disabled={true}
            // className="border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="flex-1 flex items-center space-x-2">
          <CustomTextfield
            id="taxRate"
            type="text"
            label="Tax Rate(%)"
            name="taxRate"
            value={invoiceSettings?.taxRate}
            onChange={handleChange}
            disabled={true}

            // className="border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>

      {/* <div className="flex gap-6">
        
      </div> */}

      <label className="text-sm font-bold">Invoice Contact Information:</label>
      <div className="flex gap-6">
        <div className="flex-1 flex items-center space-x-6">
          <CustomTextfield
            id="fullName"
            type="text"
            name="fullName"
            label="Full Name"
            value={invoiceSettings?.fullName}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="flex-1 flex items-center ">
          <CustomTextfield
            id="companyRole"
            type="text"
            name="companyRole"
            label="Company Role"
            value={invoiceSettings?.companyRole}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex-1 flex items-center ">
          <CustomTextfield
            id="email"
            type="text"
            name="email"
            label="Email Address"
            value={invoiceSettings?.email}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="flex-1 flex items-center ">
          <CustomTextfield
            id="phone"
            type="text"
            name="phone"
            label="Phone Number"
            value={invoiceSettings?.phone}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>

      <label className="text-sm font-bold">Billing Address:</label>
      <div className="flex gap-6">
        <div className="flex-1 flex items-center ">
          <CustomTextfield
            id="streetAddress"
            type="text"
            name="streetAddress"
            label="Street Name"
            value={invoiceSettings?.streetAddress}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="flex-1 flex items-center ">
          <CustomSelect
            id="country"
            name="country"
            label="Country"
            data={CountryListData}
            value={invoiceSettings?.country}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
            defaultSelection={invoiceSettings?.country}

          />
        </div>
      </div>

      <div className="flex gap-6">
        <div className="flex-1 flex items-center space-x-2">
          <CustomSelect
            id="state"
            name="state"
            data={statesData}
            label="State"
            value={invoiceSettings?.state}
            onChange={handleChange}
            defaultSelection={invoiceSettings?.state}

            className="border  rounded-md w-full"
          />
        </div>

        <div className="flex-1 flex items-center space-x-2">
          <CustomTextfield
            id="city"
            type="text"
            name="city"
            label="City"
            value={invoiceSettings?.city}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>
        <div className="flex-1 flex items-center space-x-2">
          <CustomTextfield
            id="pincode"
            type="text"
            name="pincode"
            label="ZipCode"
            value={invoiceSettings?.pincode}
            onChange={handleChange}
            className="border border-gray-300 rounded-md w-full"
          />
        </div>
      </div>

      <div className="flex justify-center mt-3">
        <CustomButton
          type="button1"
          customClasses={`text-[16px] font-semibold py-3 px-7 flex gap-x-[8px] ${
            updateButtonDisabled
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          }`}
          onClick={handleSubmit}
          disabled={updateButtonDisabled}
        >
          Update Invoice Settings
          <img
            className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
            alt=""
            src="/iconfilledsend.svg"
          />
        </CustomButton>
      </div>
    </div>
  );
};

export default InvoiceSettings;
