import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10.3rem",
  },
  heading: {
    textAlign: "center",
    marginBottom: "9.6rem",
    [theme.breakpoints.down("1500")]: {
      marginBottom: "6rem",
    },
    [theme.breakpoints.down("800")]: {
      marginBottom: "4.5rem",
    },
    [theme.breakpoints.down("600")]: {
      fontSize: theme.typography.body1.fontSize,
      marginBottom: "3.6rem",
    },
  },
  carouselDiv: {
    padding: "0 1rem",
  },
  techImages: {
    display: "flex",
    justifyContent: "space-between",
  },
  techImage: {
    objectFit: "none",
    [theme.breakpoints.down("600")]: {
      maxWidth: "50%",
      objectFit: "scale-down",
    },
  },
  set1TechImages: {
    marginBottom: "5rem",
  },
}));

const Technologies = (props) => {
  const classes = useStyles();
  const set1TechImages = images.tech[0];
  const set2TechImages = images.tech[1];
  const mdDown = useMediaQuery("(max-width:1400px)");
  const smDown = useMediaQuery("(max-width:1000px)");

  const ImageGroupCarousel = () => {
    return (
      <div className={classes.carouselDiv}>
        <Swiper
          slidesPerView={smDown ? 4 : 6}
          spaceBetween={10}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          speed={500}
          modules={[Autoplay]}
          className={classes.set1TechImages}
        >
          {set1TechImages?.map((techImage, index) => (
            <SwiperSlide>
              <img
                src={techImage}
                alt={`tech-image-${index}`}
                className={classes.techImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          slidesPerView={smDown ? 4 : 6}
          spaceBetween={10}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            reverseDirection: true,
          }}
          speed={500}
          modules={[Autoplay]}
          className="mySwiperSet2"
        >
          {set2TechImages?.map((techImage, index) => (
            <SwiperSlide>
              <img
                src={techImage}
                alt={`tech-image-${index}`}
                className={classes.techImage}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.heading}>
        Get Ahead of the Game <br />
        with FindPro's Diverse Tech Talent
      </Typography>
      {mdDown ? (
        <ImageGroupCarousel />
      ) : (
        <div className={classes.techImages}>
          {[...set1TechImages, ...set2TechImages]?.map((techImage, index) => (
            <img
              src={techImage}
              alt={`tech-image-${index}`}
              className={classes.techImage}
              // width={65}
              // height={65}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Technologies.propTypes = {};

export default Technologies;
