import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  Tab,
} from "@mui/material";
import { getResumeFileName } from "app/candidate/myaccount/resume-settings/attach-resume.component";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Button as BButton,
} from "react-bootstrap";
import Form from "react-bootstrap/esm/Form";
import { BsDownload } from "react-icons/bs";
import Trash from "../../../images/trash.svg";
import ResumeIcon from "../../../images/resume.svg";
import CustomDialog from "../modal-wrapper/modal.wrapper.component";
import useToggleLoader from "app/common/utils/useToggleLoader";
import s3Upload from "../utils/s3Upload";
import CountryList from "app/markup/country.json";
import classnames from "classnames";
import "./bulkUpload.scss";
import FilterSidebarComponent from "../sidebar/filter-sidebar.component";
import NoDataFound from "../../../images/noData.svg";
import {
  bulkUploadCandidates,
  deleteUser,
  getBulkCandidates,
  resendInvite,
  updateBulkListCandidate,
} from "./bulkService";
import { getInitials } from "../candidates-view/candidates-list.component";
import ReactPaginate from "react-paginate";
import OverlayFileViewer from "app/common/components/OverlayFileViewer";
import PreviewIcon from "@mui/icons-material/Preview";

const XLSX = require("sheetjs-style");

export default function BulkUpload() {
  const [tabValue, setTabValue] = useState("1");
  const inputRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [uploadedData, setUploadedData] = useState([]);
  const [availableCandidates, setAvailableCandidates] = useState([]);

  const fetchCandidatesList = () => {
    enableLoader(true);
    // setShowLoader(true);
    getBulkCandidates()
      .then((res) => {
        setAvailableCandidates(
          res.users?.map((can) => {
            const {
              first_name,
              last_name,
              email,
              state,
              country,
              isProfileComplete,
              jobs_applied,
              profile_info,
            } = can;
            return {
              name: `${first_name} ${last_name}`,
              email,
              state,
              country,
              rate: profile_info.profile_summary
                ? profile_info.profile_summary.rate
                : 0 || null,
              resume: "",
              isProfileComplete,
              ...can,
            };
          })
        );
        setShowLoader(false);
      })
      .catch((err) => {
        enqueueSnackbar("Problem in fetching candidates list!", {
          variant: "error",
        });
        console.error(err);
        setShowLoader(false);
      })
      .finally(() => {
        enableLoader(false);
      });
  };

  useEffect(() => {
    fetchCandidatesList();
  }, []);

  useEffect(() => {
    setFilteredItem(availableCandidates || []);
  }, [availableCandidates]);

  const onFileUpload = async (event) => {
    setUploadedData([]);
    const file = event.target.files[0];
    if (file.name.split(".")[file.name.split(".")?.length - 1] !== "xlsx") {
      enqueueSnackbar(
        "Invalid file format. Please use the provided template.",
        {
          variant: "error",
        }
      );
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const validHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Bid Rate",
        "State",
        "Country",
      ];
      let isValidHeaders = true;

      dataParse[0]?.forEach((data) => {
        if (!validHeaders.includes(data)) isValidHeaders = false;
      });

      // @ts-ignore
      if (!dataParse[1]) {
        enqueueSnackbar("Please upload template wih atleast one record.", {
          variant: "error",
        });
        return;
      }

      if (!isValidHeaders) {
        enqueueSnackbar(
          "Column header mismatch. Please use the provided template.",
          {
            variant: "error",
          }
        );
        return;
      }

      // console.log("dataParse ", dataParse);
      setUploadedData(dataParse.filter((dp) => dp.length === 6));
    };
    reader.readAsBinaryString(file);
    event.currentTarget.value = null;
  };

  const getTableBody = () => {
    return (uploadedData?.length > 1 && uploadedData.slice(1)) || [];
  };

  const [uploadResume, setUploadResume] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const hideModal = () => setShowModal(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isEditCandidate, setIsEditCandidate] = useState(false);
  // @ts-ignore
  const enableLoader = useToggleLoader();
  const [filteredItem, setFilteredItem] = useState([]);
  const [filterKeys, setFilterKeys] = useState([]);

  const nameRegex = /^[a-zA-Z\s]+$/g;
  const [formValidated, setFormValidated] = useState(false);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 4;

  let initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    bidRate: 0,
    personalInfo: {
      state: "",
      country: "",
    },
    profileInfo: {
      resume: "",
    },
    userId: "",
  };

  const [initialValues, setInitialValues] = useState({
    ...initialFormState,
  });

  // @ts-ignore
  const inputSingleRef = React.useRef();
  const [isBulkUploadInProgress, setIsBulkUploadInProgress] = useState(false);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredItem.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset || 0);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(filteredItem.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredItem.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredItem]);

  function Items({ currentItems }) {
    return (
      <>
        {filteredItem &&
          filteredItem?.map((can, idx) => (
            <div className="file-info d-flex justify-content-between flex-column position-relative">
              {!can.isProfileComplete && (
                <span className="banner-badge position-absolute">
                  Reg., Pending
                </span>
              )}
              <div className="d-flex justify-content-between">
                <div className="d-flex name-container">
                  <div className="mr-3">
                    <span className="avatar">
                      {getInitials(can?.name?.toUpperCase())}
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <h4 className="m-0 p-0">{can.name}</h4>
                    <span>{can.email}</span>
                    <p className="m-0 mt-2 state-country">
                      {can.state}
                      {can.country ? `, ${can.country}` : ""}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div className="rate-resume">
                    {can.rate && (
                      <span>
                        <b className="rate">${can.rate}</b> per hr
                      </span>
                    )}
                    {can.profile_info?.resume && (
                      <span className="split" style={{ marginRight: "2rem" }} />
                    )}
                    {can.profile_info?.resume && (
                      <span className="resume">
                        Resume&emsp;
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M22 11.5799V12.4999C21.9988 14.6563 21.3005 16.7545 20.0093 18.4817C18.7182 20.2088 16.9033 21.4723 14.8354 22.0838C12.7674 22.6952 10.5573 22.6218 8.53447 21.8744C6.51168 21.1271 4.78465 19.746 3.61096 17.9369C2.43727 16.1279 1.87979 13.9879 2.02168 11.8362C2.16356 9.68443 2.99721 7.63619 4.39828 5.99694C5.79935 4.35768 7.69279 3.21525 9.79619 2.74001C11.8996 2.26477 14.1003 2.4822 16.07 3.35986"
                            stroke="#59CBA2"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M22 4.5L12 14.51L9 11.51"
                            stroke="#59CBA2"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                  <div className="position-relative">
                    <svg
                      id="drop-click"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={(event) => {
                        // console.log(
                        //   "*** event ",
                        //   event.target.id,
                        //   event.target
                        // );
                        handleDropClick(idx, can.isDropOpen);
                      }}
                      style={{
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <g opacity="0.3" id="drop-click">
                        <path
                          id="drop-click"
                          d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="drop-click"
                          d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="drop-click"
                          d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                    {can.isDropOpen && (
                      <div className="dropdown position-absolute">
                        <Dropdown.Item
                          id="drop-click"
                          onClick={() => {
                            handleDropClick(idx, true);
                            setShowLoader(false);
                            updateInitialValues(can);
                            setShowModal(true);
                            setIsEditCandidate(true);
                          }}
                        >
                          Edit
                        </Dropdown.Item>
                        {can.profile_info?.resume && (
                          <Dropdown.Item
                            id="drop-click"
                            onClick={() => {
                              handleDropClick(idx, true);
                              uploadRef?.current?.click();
                              updateInitialValues(can);
                            }}
                          >
                            Update Resume
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          id="drop-click"
                          onClick={() => {
                            handleDropClick(idx, true);
                            setShowLoader(true);
                            resendInvite({ userId: can._id })
                              .then((res) => {
                                enqueueSnackbar("Invite sent successfully!", {
                                  variant: "success",
                                });
                              })
                              .catch((err) => {
                                enqueueSnackbar(
                                  "Problem in sending the invite!",
                                  { variant: "error" }
                                );
                                console.error(err);
                              })
                              .finally(() => {
                                setShowLoader(false);
                              });
                          }}
                        >
                          Resend Invite
                        </Dropdown.Item>
                        <Dropdown.Item
                          id="drop-click"
                          onClick={() => {
                            handleDropClick(idx, true);
                            setShowLoader(true);
                            deleteUser({ user_id: can._id })
                              .then((res) => {
                                enqueueSnackbar("User deleted successfully!", {
                                  variant: "success",
                                });
                                fetchCandidatesList();
                              })
                              .catch((err) => {
                                enqueueSnackbar(
                                  "Problem in deleting the user!",
                                  { variant: "error" }
                                );
                                console.error(err);
                              });
                          }}
                        >
                          Delete user
                        </Dropdown.Item>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!can.profile_info?.resume && (
                <div className="subs-banner profile-view mt-3">
                  <div
                    className="subs-info"
                    style={{
                      flexDirection: "row",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        fill="#1C81FF"
                        stroke="#1C81FF"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 8V12"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 16H12.01"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    &nbsp;
                    <span className="info">Candidate’s resume is missing</span>
                  </div>
                  <BButton
                    variant="light"
                    className="mt-3 rewmp-btn"
                    onClick={() => {
                      uploadRef.current.click();
                      updateInitialValues(can);
                    }}
                  >
                    Upload Resume
                  </BButton>
                </div>
              )}
            </div>
          ))}
      </>
    );
  }

  useEffect(() => {
    if (filterKeys?.length) {
      const fl = [];
      const handleItemsPush = (can, filterCondition) => {
        // console.log(
        //   "range check ",
        //   filterKeys.find((k) => k.includes("range-"))
        // );
        const rangeIndex = filterKeys.findIndex((k) => k.includes("range-"));
        const rangeCondition =
          rangeIndex !== -1 &&
          parseInt(can.rate) <= parseInt(filterKeys[rangeIndex]?.split("-")[1]);
        const isNew = !can.isProfileComplete;
        const isCompleted = can.isProfileComplete;
        const isMissingResume = !can.profile_info.resume;

        if (rangeIndex > -1 && can.rate) {
          if (filterKeys?.length === 1 && rangeCondition) fl.push(can);
          if (
            filterKeys.includes("all") &&
            filterKeys?.length === 2 &&
            rangeCondition
          )
            fl.push(can);
          if (
            filterKeys.includes("new") &&
            !filterKeys.includes("resumes") &&
            isNew &&
            rangeCondition
          )
            fl.push(can);
          if (filterKeys.includes("completed") && isCompleted && rangeCondition)
            fl.push(can);
          if (
            filterKeys.includes("resumes") &&
            !filterKeys.includes("new") &&
            isMissingResume &&
            rangeCondition
          )
            fl.push(can);

          if (
            filterKeys.includes("resumes") &&
            filterKeys.includes("new") &&
            isMissingResume &&
            isNew &&
            rangeCondition
          )
            fl.push(can);
        }

        if (rangeIndex === -1) {
          // if (filterKeys.includes("all") && filterKeys?.length === 1) fl.push(can);
          if (filterKeys.includes("all")) {
            fl.push(can);
            return;
          }
          if (
            filterKeys.includes("new") &&
            !filterKeys.includes("resumes") &&
            isNew
          )
            fl.push(can);
          if (filterKeys.includes("completed") && isCompleted) fl.push(can);
          if (
            filterKeys.includes("resumes") &&
            !filterKeys.includes("new") &&
            isMissingResume
          )
            fl.push(can);
          if (
            filterKeys.includes("resumes") &&
            filterKeys.includes("new") &&
            isNew &&
            isMissingResume
          )
            fl.push(can);
        }
      };
      availableCandidates.forEach((can) => {
        handleItemsPush(can);
      });
      setFilteredItem([...fl]);
    } else setFilteredItem(availableCandidates);
  }, [filterKeys]);

  // @ts-ignore
  const onSingleFileUpload = async (event, isUpdate) => {
    enableLoader(true);
    // setShowLoader(true);
    const file = event.target.files[0];
    if (file.size > 1048576 * 5) {
      enqueueSnackbar("Please upload a file with size less than 5MB.", {
        variant: "error",
      });
      setShowLoader(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    try {
      const s3files = await s3Upload(formData);
      setUploadResume(s3files[0].location);

      if (isUpdate) {
        updateCan(s3files[0].location);
      }
      if (isEditCandidate) {
        setInitialValues({
          ...initialValues,
          profileInfo: {
            resume: s3files[0].location,
          },
        });
      }
    } catch (err) {
      enqueueSnackbar("Problem in uploading resume", { variant: "error" });
    } finally {
      enableLoader(false);
      setShowLoader(false);
      event.target.value = "";
    }
  };

  const resetForm = () => {
    const form = document.getElementById("add-candidate-form");
    // @ts-ignore
    form?.reset();
    setUploadResume("");
    hideModal();
    setShowLoader(false);
    setFormValidated(false);
    setInitialValues({
      ...initialFormState,
    });
  };

  const updateCan = (resumeUrl) => {
    const _initialValues = resumeUrl
      ? {
          ...initialValues,
          profileInfo: {
            resume: resumeUrl,
          },
        }
      : initialValues;
    if (isEditCandidate) setInitialValues(_initialValues);
    updateBulkListCandidate(_initialValues)
      .then((res) => {
        enqueueSnackbar("Candidate updated successfully!", {
          variant: "success",
        });
        fetchCandidatesList();
      })
      .catch((err) => {
        enqueueSnackbar("Problem in updating candidate info!", {
          variant: "error",
        });
        console.error(err);
        setShowLoader(false);
      })
      .finally(() => {
        setShowModal(false);
        setShowLoader(false);
        resetForm();
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (!formValidated) setFormValidated(true);
    if (form.checkValidity() === false) return;

    // if (uploadResume === "") return;
    setShowLoader(true);
    if (isEditCandidate) {
      // console.log("here", initialValues);
      if (!initialValues.profileInfo.resume) return;
      updateCan(uploadResume);
    } else {
      // console.log("here2", initialValues);
      handleBulkUpload([
        {
          ...initialValues,
          email_id: initialValues.email,
          profileInfo: {
            resume: uploadResume,
          },
        },
      ]);
    }
  };

  const checkIsFormValid = () => {
    const form = document.getElementById("add-candidate-form");
    if (!formValidated) return false;
    if (uploadResume === "") return true;
    // @ts-ignore
    return !form?.checkValidity();
  };

  const getFormattedData = (data) => {
    let formattedData = [];
    [...data.slice(1)].forEach((d) => {
      formattedData.push({
        ...initialValues,
        firstName: d[0],
        lastName: d[1],
        email: d[2],
        email_id: d[2],
        personalInfo: {
          state: d[5],
          country: d[4],
        },
        profileInfo: {
          resume: "",
        },
        bidRate: d[3].toString(),
        userId: "",
      });
    });
    return formattedData;
  };

  const handleBulkUpload = (payload) => {
    // console.log("bulkupload", payload);
    setIsBulkUploadInProgress(true);
    bulkUploadCandidates(payload)
      .then((res) => {
        if (res) {
          const { existedUsers = [] } = res;
          // if (!showBulkModal && existedUsers.length === 1) {
          //   enqueueSnackbar(`Candidate with ${existedUsers[0]} exists!`, {
          //     variant: "error",
          //   });
          // }
          if (showBulkModal && existedUsers.length > 0) {
            const eu = uploadedData
              .splice(1)
              .filter((ud) => existedUsers.includes(ud[2]));
            setUploadedData([uploadedData[0], ...eu]);
            enqueueSnackbar(
              `${existedUsers.length} user${
                existedUsers.length > 1 ? "s" : ""
              } already exists. Change email or remove the user from the bulk upload list`,
              {
                variant: "error",
              }
            );
            fetchCandidatesList();
            return;
          } else {
            enqueueSnackbar("Candidates List Added Successfully!", {
              variant: "success",
            });
          }

          setShowBulkModal(false);
          setUploadedData([]);
          setShowModal(false);
          fetchCandidatesList();
        } else {
          enqueueSnackbar("Problem in adding Candidates list!", {
            variant: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Problem in adding Candidates list!", {
          variant: "error",
        });
      })
      .finally(() => setIsBulkUploadInProgress(false));
  };

  const handleDropClick = (idx, skipIdx) => {
    const ls = [...filteredItem];
    ls.forEach((f) => (f.isDropOpen = false));
    if (!skipIdx) ls[idx].isDropOpen = !ls[idx].isDropOpen;
    setFilteredItem(ls);
  };

  const uploadRef = useRef();

  const updateInitialValues = (can) => {
    setInitialValues({
      ...initialValues,
      firstName: can.first_name,
      lastName: can.last_name,
      email: can.email,
      profileInfo: {
        resume: can.profile_info?.resume,
      },
      personalInfo: {
        country: can.personal_info?.country?.replaceAll("_", " "),
        state: can.personal_info?.state,
      },
      bidRate: can.rate?.toString(),
      userId: can._id,
    });
  };

  const isBulkRequestDisabled = () => {
    const ul = uploadedData.slice(1).filter((d) => !d.includes(""));
    return ul.length !== uploadedData.slice(1).length;
  };

  return (
    <div
      className="bulk-upload"
      onClick={({ target }) => {
        if (target?.id === "drop-click") return;
        handleDropClick("", true);
      }}
    >
      <Container className="body-spacing vendor-manage-candidates upper-spacing">
        <Grid container columnSpacing={2}>
          <Grid md={12} xs={12} item className="sub-section">
            <div className="pull-right">
              <button
                type="button"
                className="rewmp-btn btn btn-light"
                onClick={() => {
                  resetForm();
                  setShowModal(true);
                }}
              >
                Add Candidate
              </button>
              &emsp;
              <button
                type="button"
                className="rewmp-btn btn btn-dark"
                onClick={() => {
                  setShowBulkModal(true);
                  setIsBulkUploadInProgress(false);
                }}
              >
                Bulk Upload
              </button>
            </div>
          </Grid>
          <Grid md={3} xs={12} item className="filter-sidebar sub-section">
            <FilterSidebarComponent
              setItemFilter={setFilterKeys}
              itemFilter={{
                Status: [
                  {
                    id: "all",
                    name: "All",
                  },
                  {
                    id: "new",
                    name: "Registeration Pending",
                  },
                  // {
                  //     id: "completed",
                  //     name: "Profiles 100%"
                  // },
                  {
                    id: "resumes",
                    name: "Missing Resumes",
                  },
                ],
                Range: {
                  title: "Rates",
                  min: 0,
                  max: 100,
                },
              }}
              title=""
              isCustom
            />
          </Grid>
          <Grid md={9} xs={12} item>
            <TabContext value={tabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  p: 2,
                  pb: 0,
                  background: "#FFFFFF",
                  border: "1px solid #E3E3E3",
                  borderRadius: 0,
                  display: "none",
                }}
              >
                <TabList
                  // @ts-ignore
                  // @ts-ignore
                  onChange={(event, newValue) => {
                    setTabValue(newValue);
                  }}
                  className={""}
                >
                  <Tab label="Added Candidates" value="1" />
                  {/* <Tab label="Registeration Pending" value="2" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid item xs="12" className="can-list position-relative">
                  {!filteredItem?.length && (
                    <div className="revamp-table__nodata mt-5">
                      <img
                        src={NoDataFound}
                        alt="NoDataFound"
                        className="mb-3"
                      />
                    </div>
                  )}
                  {filteredItem?.length > 0 && (
                    <>
                      {showLoader && (
                        <div
                          style={{
                            position: "absolute",
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            zIndex: "1",
                          }}
                        >
                          <CircularProgress
                            sx={{
                              display: "flex",
                              margin: "auto",
                              height: "100%",
                            }}
                          />
                        </div>
                      )}

                      <div className="mt-2 file-sort">
                        <div className="d-flex justify-content-between">
                          <div className="count">
                            <strong>{filteredItem?.length}</strong>
                            <span>
                              {" "}
                              Candidate{filteredItem?.length > 1
                                ? "s"
                                : ""}{" "}
                              found
                            </span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <Items currentItems={currentItems} />
                          {/* <ReactPaginate
                                                breakLabel="..."
                                                nextLabel={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path></svg>}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={1}
                                                pageCount={pageCount}
                                                previousLabel={<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"></path></svg>}
                                                containerClassName={'pagination'}
                                                activeClassName={'active'}
                                            /> */}
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid item xs="12">
                  <div className="revamp-table__nodata mt-5">
                    <img src={NoDataFound} alt="NoDataFound" className="mb-3" />
                  </div>
                </Grid>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
      </Container>

      <input
        type="file"
        ref={uploadRef}
        className="site-button form-control"
        id="customFile"
        accept=".pdf,.doc,.docx"
        onChange={(event) => onSingleFileUpload(event, true)}
        style={{
          display: "none",
        }}
      />

      <CustomDialog
        open={showBulkModal}
        setOpen={(isOpen, reason) => {
          if (reason && reason === "backdropClick") return;
          setShowBulkModal(isOpen);
          setUploadedData([]);
        }}
        title={uploadedData?.length > 0 ? "Review Bulk Upload" : "Bulk Upload"}
        className={classnames("bulk-upload-modal", {
          "bulk-file-uploded": uploadedData?.length > 0,
        })}
      >
        <DialogContent
          dividers
          style={{
            padding: "1.5rem 3rem  3rem 3rem",
          }}
        >
          {isBulkUploadInProgress && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                width: "90%",
                height: "100%",
                zIndex: "1",
              }}
            >
              <CircularProgress
                sx={{ display: "flex", margin: "auto", height: "100%" }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between w-100">
            <span>Upload excel</span>
            <a target="_blank" href="/Bulk_Upload_Template.xlsx">
              Download Template
            </a>
          </div>
          <div className="intro-container d-flex justify-content-center w-100">
            <div className="mt-3 w-100">
              <span style={{ opacity: "0.7" }}>
                Attach the given template with data filled
              </span>
              <button
                type="button"
                className="rewmp-btn btn btn-light"
                style={{
                  padding: "1rem",
                  fontSize: "14px",
                  boxShadow: "0px 4px 19px rgba(147, 114, 65, 0.3)",
                }}
                // @ts-ignore
                onClick={() => inputRef?.current?.click()}
              >
                Browse
              </button>
            </div>
            <input
              type="file"
              ref={inputRef}
              className="site-button form-control"
              id="customFile"
              accept=".xlsx"
              onChange={onFileUpload}
              style={{
                display: "none",
              }}
            />
          </div>
          {uploadedData?.length > 0 && (
            <>
              <p
                style={{
                  margin: "2rem 0",
                  fontSize: "14px",
                  opacity: "0.7",
                }}
              >
                {uploadedData?.length - 1} candidates added
              </p>
              <div className="revamp-table mt-4">
                <div className="revamp-table__header">
                  {uploadedData[0]?.map((d) => (
                    <span>{d}</span>
                  ))}
                  <span> </span>
                </div>
                <div className="revamp-table__body">
                  {getTableBody().map((body, idx) => (
                    <div className="revamp-table__row">
                      {body.map((d, i) => {
                        if (i === 0 || i === 1 || i === 2 || i === 3) {
                          return (
                            <input
                              type="text"
                              value={d}
                              onChange={({ target }) => {
                                const cl = [...uploadedData];
                                cl[idx + 1][i] = target.value;
                                setUploadedData(cl);
                              }}
                            />
                          );
                        } else return <span>{d}</span>;
                      })}
                      <span>
                        <img
                          src={Trash}
                          alt="delete"
                          onClick={() => {
                            let pf = [...uploadedData];
                            pf.splice(idx + 1, 1);
                            pf = pf?.length === 1 ? [] : pf;
                            setUploadedData([...pf]);
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <button
                type="button"
                className="rewmp-btn btn btn-light pull-right"
                style={{
                  padding: "1rem",
                  margin: "1rem 2rem",
                  fontSize: "14px",
                  boxShadow: "0px 4px 19px rgba(147, 114, 65, 0.3)",
                }}
                // @ts-ignore
                onClick={() => {
                  const payload = getFormattedData(uploadedData);
                  handleBulkUpload(payload);
                }}
                disabled={isBulkRequestDisabled()}
              >
                Send Bulk Request
              </button>
            </>
          )}
        </DialogContent>
      </CustomDialog>

      <CustomDialog
        open={showModal}
        setOpen={(isOpen, reason) => {
          if (reason && reason == "backdropClick") return;
          setShowModal(isOpen);
          if (!isOpen) setFormValidated(false);
          resetForm();
          setIsEditCandidate(false);
        }}
        title={isEditCandidate ? "Edit Candidate" : "Add Candidate"}
      >
        <DialogContent
          dividers
          style={{
            padding: "1.5rem 3rem",
          }}
        >
          {showLoader && (
            <div
              style={{
                position: "absolute",
                display: "flex",
                width: "80%",
                height: "80%",
                zIndex: "1",
              }}
            >
              <CircularProgress
                sx={{ display: "flex", margin: "auto", height: "100%" }}
              />
            </div>
          )}
          <Form
            noValidate
            validated={formValidated}
            onSubmit={handleSubmit}
            id="add-candidate-form"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "2rem 0",
            }}
          >
            <Row>
              <Col md={12} lg={6}>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>Enter your first name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    pattern="^[a-zA-Z\s]+$"
                    onChange={({ target }) =>
                      setInitialValues({
                        ...initialValues,
                        firstName: target.value,
                      })
                    }
                    value={initialValues?.firstName}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {initialValues?.firstName?.length === 0 &&
                      "First name is required"}
                    {initialValues?.firstName?.length > 0 &&
                      !nameRegex.test(initialValues?.firstName) &&
                      "Please enter a valid name"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12} lg={6}>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>Enter your last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    pattern="^[a-zA-Z\s]+$"
                    name="last_name"
                    value={initialValues?.lastName}
                    onChange={({ target }) =>
                      setInitialValues({
                        ...initialValues,
                        lastName: target.value,
                      })
                    }
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {initialValues?.lastName?.length === 0 &&
                      "Last name is required"}
                    {initialValues?.lastName?.length > 0 &&
                      !nameRegex.test(initialValues?.lastName) &&
                      "Please enter a valid name"}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Enter your email id</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email Id"
                name="email"
                required
                value={initialValues?.email}
                onChange={({ target }) =>
                  setInitialValues({
                    ...initialValues,
                    email: target.value,
                  })
                }
                readOnly={isEditCandidate}
              />
              <Form.Control.Feedback type="invalid">
                Invalid Email address
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              className="mb-3 position-relative"
              controlId="profile-rate-per-hour"
            >
              <Form.Label>Bid Value</Form.Label>
              <span className="prefix-dollar" />
              <Form.Control
                type="number"
                placeholder="Bid Value"
                name="headline"
                required
                value={initialValues?.bidRate}
                min={1}
                max={1000}
                // @ts-ignore
                isValid={
                  formValidated &&
                  initialValues?.bidRate > 0 &&
                  initialValues?.bidRate <= 1000
                }
                // @ts-ignore
                // isInvalid={
                //   formValidated &&
                //   !(
                //     initialValues?.bidRate > 0 && initialValues?.bidRate <= 1000
                //   )
                // }
                onChange={({ target }) =>
                  setInitialValues({
                    ...initialValues,
                    bidRate: Number(target.value),
                  })
                }
                className="pl-4"
              />
              <Form.Control.Feedback type="invalid">
                {initialValues?.bidRate === 0 && <>Bid Value is required</>}
                {initialValues?.bidRate !== 0 &&
                  !(
                    // @ts-ignore
                    (
                      initialValues?.bidRate > 0 &&
                      initialValues?.bidRate <= 1000
                    )
                  ) && <>Bid Value should range between $1 to 1000$/hour</>}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCountryState">
              <Row>
                <Col md={12} lg={6}>
                  <Form.Group className="mb-3" controlId="formCountry">
                    <Form.Label>Select your Country</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      isInvalid={false}
                      value={initialValues?.personalInfo?.country}
                      onChange={({ target }) =>
                        setInitialValues({
                          ...initialValues,
                          personalInfo: {
                            ...initialValues.personalInfo,
                            country: target.value,
                          },
                        })
                      }
                    >
                      <option value="">Country</option>
                      {
                        // @ts-ignore
                        CountryList.map((x, i) => (
                          <option key={i} value={x.country}>
                            {x.country}
                          </option>
                        ))
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Country is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} lg={6}>
                  <Form.Group className="mb-3" controlId="formState">
                    <Form.Label>Select your State</Form.Label>
                    <Form.Select
                      required
                      className="form-control"
                      value={initialValues?.personalInfo?.state}
                      disabled={
                        initialValues?.personalInfo?.country?.length === 0
                      }
                      onChange={({ target }) =>
                        setInitialValues({
                          ...initialValues,
                          personalInfo: {
                            ...initialValues.personalInfo,
                            state: target.value,
                          },
                        })
                      }
                    >
                      <option value="">State</option>
                      {
                        // @ts-ignore
                        CountryList.find(
                          (x) =>
                            x?.country === initialValues?.personalInfo?.country
                        )?.states?.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))
                      }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      State is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group>
              {!isEditCandidate && (
                <div className="row d-justify-center m-0 p-0">
                  <div className="mt-3 d-flex w-100 justify-content-around align-items-center form-control">
                    {uploadResume === "" && (
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        Upload your resume in .doc, .docx and .pdf formats
                      </span>
                    )}
                    {uploadResume !== "" && (
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        <img src={ResumeIcon} alt="ResumeIcon" />
                        &emsp;
                        <a
                          href={uploadResume}
                          target="_blank"
                          style={{
                            color: "#1C81FF",
                          }}
                          rel="noreferrer"
                        >
                          {getResumeFileName(uploadResume)}&emsp;
                          <BsDownload
                            style={{
                              color: "#1C81FF",
                            }}
                          />
                        </a>
                        &emsp;
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => setUploadResume("")}
                        >
                          <img width="20px" src={Trash} alt="delete" />
                        </span>
                      </span>
                    )}
                    <button
                      type="button"
                      className="rewmp-btn btn btn-light"
                      style={{
                        padding: "0.75rem",
                        fontSize: "14px",
                        boxShadow: "0px 4px 19px rgba(147, 114, 65, 0.3)",
                      }}
                      // @ts-ignore
                      onClick={() => inputSingleRef?.current?.click()}
                    >
                      {uploadResume !== "" ? "Update" : "Upload File"}
                    </button>
                    <input
                      type="file"
                      ref={inputSingleRef}
                      className="site-button form-control"
                      id="customFile"
                      accept=".pdf,.doc,.docx"
                      onChange={(event) => onSingleFileUpload(event)}
                      style={{
                        display: "none",
                      }}
                    />
                  </div>
                  {formValidated && uploadResume === "" && (
                    <span
                      style={{
                        width: "100%",
                        marginTop: ".25rem",
                        fontSize: "80%",
                        color: "#dc3545",
                      }}
                      className="form-error"
                    >
                      Resume is required
                    </span>
                  )}
                </div>
              )}

              {isEditCandidate && (
                <div className="row d-justify-center m-0 p-0">
                  <div className="mt-3 d-flex w-100 justify-content-around align-items-center form-control">
                    {!initialValues?.profileInfo?.resume && (
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        Upload your resume in .doc, .docx and .pdf formats
                      </span>
                    )}
                    {initialValues?.profileInfo?.resume && (
                      <span
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        <img src={ResumeIcon} alt="ResumeIcon" />
                        &emsp;
                        <a
                          href={initialValues?.profileInfo?.resume}
                          target="_blank"
                          style={{
                            color: "#1C81FF",
                          }}
                          rel="noreferrer"
                        >
                          {getResumeFileName(
                            initialValues?.profileInfo?.resume
                          )}
                          &emsp;
                          <BsDownload
                            style={{
                              color: "#1C81FF",
                            }}
                          />
                        </a>
                        &emsp;
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setInitialValues({
                              ...initialValues,
                              profileInfo: {
                                resume: null,
                              },
                            })
                          }
                        >
                          <img width="20px" src={Trash} alt="delete" />
                        </span>
                      </span>
                    )}
                    <button
                      type="button"
                      className="rewmp-btn btn btn-light"
                      style={{
                        padding: "0.75rem",
                        fontSize: "14px",
                        boxShadow: "0px 4px 19px rgba(147, 114, 65, 0.3)",
                      }}
                      // @ts-ignore
                      onClick={() => inputSingleRef?.current?.click()}
                    >
                      {!initialValues?.profileInfo?.resume
                        ? "Update"
                        : "Upload File"}
                    </button>
                    <input
                      type="file"
                      ref={inputSingleRef}
                      className="site-button form-control"
                      id="customFile"
                      accept=".pdf,.doc,.docx"
                      onChange={(event) => onSingleFileUpload(event)}
                      style={{
                        display: "none",
                      }}
                    />
                  </div>
                  {formValidated && !initialValues?.profileInfo?.resume && (
                    <span
                      style={{
                        width: "100%",
                        marginTop: ".25rem",
                        fontSize: "80%",
                        color: "#dc3545",
                      }}
                      className="form-error"
                    >
                      Resume is required
                    </span>
                  )}
                </div>
              )}
            </Form.Group>
            <Form.Group>
              <Button
                // @ts-ignore
                variant="light"
                className="w-100 p-2 my-5 btn btn-light pull-right"
                style={{
                  background: "#222",
                  color: "#fff",
                }}
                type="submit"
                disabled={checkIsFormValid()}
              >
                {isEditCandidate ? "Update Candidate" : "Add Candidate"}
              </Button>
            </Form.Group>
          </Form>
        </DialogContent>
      </CustomDialog>
    </div>
  );
}
