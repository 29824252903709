import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { messages } from "app/v2/Pages/Utils/constants";
import Title from "../../common/Title";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import FavoritesCards from "../common/Cards/FavoritesCards";
import { getFavoritesByStatus } from "app/v2/services/client/common.service";

const useStyles = makeStyles((theme) => ({
  connectionFeedContainerLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  tabs: {
    marginTop: "38px",
    marginLeft: "24px",
    display: "flex",
    columnGap: "60px",
  },
  tab: {
    fontWeight: 600,
    fontSize: "18px",
    paddingBottom: "24px",
    color: "#ABABAB",
    cursor: "pointer",
  },
  selectedTabName: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  connectionFeedBodyContainer: {
    paddingTop: "171px", // headerDiv total height is 171 px(including margin,padding)
  },
  leftView: {
    padding: "48px 24px",
    width: "305px",
    position: "absolute",
    borderRight: "1px solid #EDEDED",
    overflow: "auto",
    height: "615px",
  },
  rightView: {
    margin: "10px 24px 24px 329px", // left margin - 305px +24px(here 305 is fixed leftView div's width )
    minHeight: "600px" /*added recently to avoid footer with main*/,
  },
  searchTextDiv: {
    marginBottom: "26px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  workTypeCheckboxDiv: {
    margin: "12px 24px",
  },
  levelCheckboxDiv: {
    margin: "12px 24px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "76px",
    rowGap: "12px",
  },
  loadMoreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyConnectionData = [
  {
    _id: "1",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "2",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "3",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "4",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "5",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "6",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "7",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
];

const Favorites = (props) => {
  const classes = useStyles();

  const sortData = [
    {
      label: "Newest",
      value: "Newest",
    },
    // {
    //   label: "Relevance",
    //   value: "Relevance",
    // },
    {
      label: "Alphabetical (A-Z)",
      value: "Alphabetical (A-Z)",
    },
    {
      label: "Alphabetical (Z-A)",
      value: "Alphabetical (Z-A)",
    },
    // {
    //   label: "Oldest",
    //   value: "Oldest",
    // },
    // {
    //   label: (
    //     <div className={classes.sortTextAndIconDiv}>
    //       # of Open Positions <img src="/iconlightarrow-top.svg" />
    //     </div>
    //   ),
    //   value: "# of Open Positions",
    // },
    // {
    //   label: (
    //     <div className={classes.sortTextAndIconDiv}>
    //       # of Applicants <img src="/iconlightarrow-top.svg" />
    //     </div>
    //   ),
    //   value: "# of Applicants",
    // },
  ];

  const tabs = ["All Favorites", "Consultant  Favorites", "Agency Favorites"];

  const [favoritesData, setFavoritesData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [selectedTabName, setSelectedTabName] = useState(tabs[0]);
  const [filteredJobData, setFilteredJobData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [jobCountries, setJobCountries] = useState([]);

  const [currentSortSelectionValue, setCurrentSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [UpdateSaveJobData, setUpdateSaveJobData] = useState(false);

  const [levelData, setLevelData] = useState([
    {
      label: "Expert",
      value: "Expert",
      checked: false,
    },
    {
      label: "Senior",
      value: "Senior",
      checked: false,
    },
    {
      label: "Junior",
      value: "Junior",
      checked: false,
    },
    {
      label: "Entry Level",
      value: "Entry Level",
      checked: false,
    },
  ]);

  const onSortChange = (id, currentValue) => {
    setCurrentSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState, elementID) => {
    if (elementID === "country") {
      setJobCountries((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
    }
    //  else {
    //   setWorkTypeData((prevItems) => {
    //     const updatedItems = prevItems?.map((item) => {
    //       if (item.value === checkboxValue) {
    //         return { ...item, checked: checkedState };
    //       }
    //       return item;
    //     });

    //     return updatedItems;
    //   });
    // }
  };

  const onTabClick = (tabName) => {
    setSelectedTabName(tabName);
  };

  const filterBySortValue = (jobDataReceived) => {
    switch (currentSortSelectionValue) {
      case "Newest":
        jobDataReceived?.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0);
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
        break;
      // case "Relevance":
      //   break;
      case "Alphabetical (A-Z)":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const companyName1 = jobEntry1?.favourite.name || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
          const companyName2 = jobEntry2?.favourite.name || "ZZZZZ";

          return companyName1.localeCompare(companyName2);
        });
        break;
      case "Alphabetical (Z-A)":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const title1 = jobEntry1?.favourite.name || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
          const title2 = jobEntry2?.favourite.name || "ZZZZZ";

          return title2.localeCompare(title1);
        });
        break;
      case "Oldest":
        // Sort the jobDataReceived based on the "published" date in descending order
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined published dates as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date1 - date2;
        });
        break;
      default:
        // If the sortSelectionValue is not recognized, default to sorting by "Date Accepted"
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined acceptedDate as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
    }

    return jobDataReceived;
  };

  useEffect(() => {
    filterBySortValue(filteredJobData);
  }, [filteredJobData]);
  const filterByCountry = (jobDataReceived) => {
    const selectedCountryValues = jobCountries
      .filter((country) => country.checked === true)
      .map((country) => country.value);

    if (isValidArray(selectedCountryValues)) {
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        selectedCountryValues.includes(jobEntry?.favourite.country)
      );
    }

    return jobDataReceived;
  };
  const updateFilteredJobData = () => {
    if (isValidArray(jobData)) {
      let filteredJobData = JSON.parse(JSON.stringify(jobData));
      if (searchText?.length > 0) {
        const searchTerm = searchText.toLowerCase();
        filteredJobData = filteredJobData.filter((jobEntry) =>
          jobEntry?.favourite.name?.toLowerCase()?.startsWith(searchTerm)
        );
      }

      filteredJobData = filterBySortValue(filteredJobData);
      // filteredJobData = filterByOnBoardingStatus(filteredJobData);
      // filteredJobData = filterByContractType(filteredJobData);
      filteredJobData = filterByCountry(filteredJobData);
      // filteredJobData = filterBySalary(filteredJobData);
      setFilteredJobData(filteredJobData);
    }
  };

  const getCountryFilters = (jobData) => {
    let filters = [];
    let countryAndCountMap = new Map();
    jobData?.forEach((jobEntry) => {
      const country = jobEntry?.favourite.country;
      const countryCountInMap = countryAndCountMap.get(country);
      countryAndCountMap.set(
        country,
        countryCountInMap ? countryCountInMap + 1 : 1
      );
    });
    countryAndCountMap.forEach((countryEntryCount, country) => {
      filters.push({
        label: `${country} (${countryEntryCount})`,
        value: `${country}`,
        checked: false,
      });
    });
    return filters;
  };

  useEffect(() => {
    let apiToCall;

    setDataLoading(true);
    setDataError(false);
    setSearchText("");

    setCurrentSortSelectionValue(sortData[0]?.value);

    if (selectedTabName === "All Favorites") apiToCall = getFavoritesByStatus();
    else if (selectedTabName === "Consultant  Favorites")
      apiToCall = getFavoritesByStatus("consultants");
    else if (selectedTabName === "Agency Favorites")
      apiToCall = getFavoritesByStatus("agency");

    if (apiToCall) {
      apiToCall
        .then((res) => {
          if (isValidArray(res?.data)) {
            setDataLoading(false);
            // setFavoritesData(res.data);
            let receivedJobData = JSON.parse(JSON.stringify(res?.data));
            setFilteredJobData(receivedJobData);
            setJobData(receivedJobData);
            setFavoritesData(receivedJobData);
            const countryFilters = getCountryFilters(receivedJobData);
            setJobCountries(countryFilters);
          } else {
            setDataLoading(false);
            setDataError(false);
            setFilteredJobData([])
            
          }
        })
        .catch((error) => {
          setDataLoading(false);
          setDataError(true);
        });
      setDataLoading(false);
      // setFavoritesData(dummyConnectionData);
    }
  }, [UpdateSaveJobData, selectedTabName]);

  useEffect(() => {
    updateFilteredJobData();
  }, [
    searchText,
    currentSortSelectionValue,
    // onBoardingStatusData,
    // contractTypeData,
    // salaryDetails,
    jobCountries,
  ]);
  // add this when scroll not moving to top when  the tab  changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTabName, jobCountries, currentSortSelectionValue]);
  return (
    <div className={dataLoading && classes.connectionFeedContainerLoading}>
      <>
        {dataLoading ? (
          <CircularProgress />
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <div>
                <div className={classes.headerDiv}>
                  <Title title="Favorites" />
                  <div className={classes.tabs}>
                    {tabs?.map((tabName) => (
                      <div
                        className={clsx([
                          classes.tab,
                          tabName === selectedTabName &&
                            classes.selectedTabName,
                        ])}
                        onClick={() => {
                          onTabClick(tabName);
                        }}
                      >
                        {tabName}
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classes.connectionFeedBodyContainer}>
                  <Grid container>
                    <Grid item xs={3} className={classes.leftView}>
                      <div className={classes.searchTextDiv}>
                        <CustomTextfield
                          id="searchText"
                          value={searchText}
                          placeholder="Search by name"
                          onChange={(id, value) => {
                            setSearchText(value);
                          }}
                          noErrorMessage={true}

                          // endAdornment={<SearchRoundedIcon />}
                        />
                      </div>
                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Sort{" "}
                        </Typography>
                        <div className={classes.sortRadioGroupDiv}>
                          <CustomRadioGroup
                            id="sort-radio-group"
                            name="sort-radio-group"
                            defaultValue={currentSortSelectionValue}
                            data={sortData}
                            onChange={onSortChange}
                          />
                        </div>
                      </div>

                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Filter
                        </Typography>
                        {/* <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Level
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <div className={classes.levelCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="level-checkbox-group"
                                  data={levelData}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div> */}
                        {/* <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Skills
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div> */}
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Country
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <div className={classes.workTypeCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="country"
                                  data={jobCountries}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={9} className={classes.rightView}>
                      {/* {favoritesData?.map((favoriteData) => (
                        <FavoritesCards data={favoriteData} setUpdateSaveJobData={setUpdateSaveJobData} />
                      ))} */}
                      {filteredJobData && filteredJobData.length > 0 ? (
                        <>
                          {filteredJobData.map((favoriteData, index) => (
                            <React.Fragment key={index}>
                              <FavoritesCards
                                data={favoriteData}
                                setUpdateSaveJobData={setUpdateSaveJobData}
                              />
                              {index !== filteredJobData.length - 1 && (
                                <hr className="my-[20px]" />
                              )}
                            </React.Fragment>
                          ))}
                          {/* <div className={classes.loadMoreDiv}>
                            <MoreHorizIcon
                              className={classes.moreIcon}
                              fontSize="large"
                            />
                            <Typography className={classes.loadMoreText}>
                              Load more
                            </Typography>
                          </div> */}
                        </>
                      ) : (
                        <Alert style={{ margin: "5px" }} severity="info">
                          No Data Available
                        </Alert>
                      )}
                      {/* <div className={classes.loadMoreDiv}>
                        <MoreHorizIcon
                          className={classes.loadMoreIcon}
                          fontSize="large"
                        />
                        <Typography className={classes.loadMoreText}>
                          Load more
                        </Typography>
                      </div> */}
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

Favorites.propTypes = {};

export default Favorites;
