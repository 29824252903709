import React from "react";
import PropTypes from "prop-types";

import { IconDot } from "app/v2/Pages/SVGs/IconDot";

/**
 *
 * @description This tile component is used for education,certification,language,award sections in profile settings.
 * @returns
 */
const DataTile = ({ data }) => {
  const duration = data?.duration; // for education.
  const isCurrentlyPursuing = duration?.isCurrentlyPursuing; // for education.
  const startTime = duration?.start;
  const endTime = duration?.end;
  const issuedOn = data?.issuedOn; // for certification or language or award.
  return (
    <div className="flex flex-col gap-y-[8px] pl-[24px] border-l-[1px] border-solid border-l-[#717171] max-w-[450px]">
      <div className="text-[16px] font-bold">
        {data?.certificateTitle ??
          data?.languageCertificateTitle ??
          data?.awardTitle}
      </div>
      <div className="flex gap-x-[8px]">
        <div className="text-[14px] font-semibold uppercase text-[#717171]">
          {data?.universityName ?? data?.issuedBy}
        </div>
        {data?.courseName && (
          <div className="flex items-center gap-x-[8px] text-[14px] font-semibold uppercase text-[#717171]">
            <IconDot fillColor="#555" /> {data?.courseName}{" "}
          </div>
        )}
      </div>
      {(duration || issuedOn) && (
        <div className="text-[12px] text-[#717171]">
          {duration ? (
            <>
              {`${startTime?.month} ${startTime?.year}`} -{" "}
              {isCurrentlyPursuing
                ? "Present"
                : `${endTime?.month} ${endTime?.year}`}
            </>
          ) : (
            <>{`${issuedOn?.month} ${issuedOn?.year}`}</>
          )}
        </div>
      )}

      {data?.description && (
        <div className="text-[14px] text-[#717171] max-h-[100px] overflow-y-auto">
          {data?.description}
        </div>
      )}
    </div>
  );
};

DataTile.propTypes = {};

export default DataTile;
