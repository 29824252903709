import fetchWrapper from "app/v2/Pages/Utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const getAlljobs = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/my-jobs`);
};

export const getAppliedjobs = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/my-jobs?status=Applied`);
};

export const getMySavedjobs = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/my-jobs?status=saved`);
};

export const getInvitedjobs = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/my-jobs?status=Invited`);
};

export const saveOrunsaveJob = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/consultant/save-unsave`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const applyJob = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/consultant/apply-job`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const acceptOrRejectJobInvitation = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/consultant/job-invitation-response
  `,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    }
  );
};

export const activeWorks = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/activeWork`);
};
