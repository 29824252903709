import React from "react";
import images from "app/v2/images/common";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "603px",
    margin: "auto",
    padding: "120px 60px",
    boxSizing: "content-box",
  },
  logo: {
    width: "156px",
    height: "42px",
    marginBottom: "48px",
  },
  title: {
    fontSize: "36px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  submitButton: {
    marginTop: "72px",
    width: "74px",
    boxSizing: "content-box",
    marginLeft: "auto",
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
    padding: "10px 15px 10px 20px",
    "&:disabled": {
      backgroundColor: "grey !important",
      borderColor: "#EDEDED !important",
    },
  },
  passwordChangedText: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "48px",
    padding: "16px 0",
  },
  loginNow: {
    width: "100%",
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
  },
}));

const AccountActivation = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img src={images?.logo_v2} className={classes.logo} />
      <Typography className={classes.title}>Welcome to FindPro</Typography>
      <div>
        <Typography className={classes.passwordChangedText}>
          Thanks for your registration. We will send you an activation link to
          your registered mail id. Kindly validate your email account for
          instant activation.
        </Typography>
      </div>
    </div>
  );
};
export default AccountActivation;
