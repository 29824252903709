import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import { getInfo, updateInfo } from "./profile-settings.service";

// First, create the thunk
export const profileInfo = createAsyncThunk(
  "profile_info",
  async (request, thunkAPI) => {
    return getInfo();
  }
);

export const updateProfileInfoThunk = createAsyncThunk(
  "profile-settings/put",
  async (request, thunkAPI) => {
    return updateInfo(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

export const updateProfilePictureThunk = createAsyncThunk(
  "profile-settings/profile-picture/put",
  async (request, thunkAPI) => {
    return updateInfo(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);
