import { createSlice } from "@reduxjs/toolkit";
import { 
  getEmployerInfoThunk, 
  updateProfilePictureThunk 
} from "./profile-settings.thunk";

const initialState = {
  clientInfo: {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    personal_info: {},
    profile_picture: ""
  },
};

const profileSettingsSlice = createSlice({
  name: "clientInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getEmployerInfoThunk.fulfilled, (state, action) => {
      state.clientInfo = action.payload;
    })
    .addCase(updateProfilePictureThunk.fulfilled, (state, action) => {
      state.clientInfo.profile_picture = action.meta.arg.profile_picture;
    });
  },
});

// Action creators are generated for each case reducer function
export default profileSettingsSlice.reducer;