import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff",
    },
    primary: {
      main: "#1c81ff", // blue
      dark: "#1E5DFF",
    },
    secondary: {
      main: "#717171", // greyish
      dark: "#363636", // dark grey
      light: "#8C8C8C", // light grey
    },
    success: {
      main: "#14C042", // green
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Montserrat !important",
    h1: {
      fontWeight: 600,
      fontSize: "4.4rem", // 71px
    },
    h2: {
      fontWeight: 600,
      fontSize: "4rem", // 64px
    },
    h3: {
      fontWeight: 500,
      fontSize: "3.5rem", // 56px
    },
    h4: {
      fontWeight: 400,
      fontSize: "2.5rem", // 40px
    },
    h5: {
      fontWeight: 400,
      fontSize: "2rem", // 32px
    },
    // h6: {
    //   fontWeight: 400,
    //   fontSize: "1rem", // 56px
    // },
    body1: {
      fontWeight: 400,
      fontSize: "1.3rem", // 22px
    },
    body2: {
      fontWeight: 400,
      fontSize: "1rem", // 16px
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.125rem", // 18px
    },
    button: {
      fontWeight: 700,
      fontSize: "1rem", // 16px
    }, // button or paragraph use this
    caption: {
      fontWeight: 400,
      fontSize: "0.875rem", // 14px
    },
    overline: {
      fontWeight: 400,
      fontSize: "0.8rem", // 12px
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
