import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import CustomSelect from "app/v2/components/filters/CustomSelect";
import LineChart from "../../common/Charts/LineChart";

import { isValidString, isValidObject } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 22px 0px #0000000F",
    padding: "21px 18px",
    zIndex: 2,
    backgroundColor: "white",
  },
  titleAndMenuDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  menu: {
    maxWidth: "203px",
    width: "100%",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#717171",
  },
  tileContainer: {
    display: "flex",
    columnGap: "16px",
  },
  tileDiv: {
    maxWidth: "368px",
    width: "100%",
  },
  tileTitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#717171",
  },
  tileValue: {
    fontSize: "32px",
    fontWeight: 600,
  },
  chartDiv: {
    maxWidth: "1131px",
    maxHeight: "421px",
    width: "100%",
    overflow: "scroll",
    padding: "32px 16px 16px 0",
    // opacity: 0,
    // transition: "all 0.5s ease-in-out", // Adjust t
    // "-webkit-transition": "all 0.5s ease-in-out", // Adjust t
    // "-moz-transition": "all 0.5s ease-in-out", // Adjust t
    // "-ms-transition": "all 0.5s ease-in-out", // Adjust t
    // "-o-transition": "all 0.5s ease-in-out", // Adjust t
  },
  chartDivVisible: {},
}));

const Tile = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.tileDiv}>
      <div className={classes.tileValue}>{value}</div>
      <div className={classes.tileTitle}>{title}</div>
    </div>
  );
};

const AnalyticCard = ({ title, data }) => {
  console.log({ data });
  const dropDownData = ["Today", "This Week"];
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={classes.container}
      onMouseLeave={handleMouseLeave}
      onMouseOver={handleMouseEnter}
    >
      <div className={classes.titleAndMenuDiv}>
        <div className={classes.cardTitle}>{title}</div>
        <div className={classes.menu}>
          <CustomSelect
            id="dropDown"
            data={dropDownData}
            defaultSelection={dropDownData[0]}
            onChange={(id, value) => {}}
          />
        </div>
      </div>
      <div className={classes.tileContainer}>
        {isValidObject(data) ? (
          <>
            {" "}
            {Object.keys(data).map((tileTitle) => (
              <Tile
                title={
                  isValidString(tileTitle) ? tileTitle.toUpperCase() : tileTitle
                }
                value={data[tileTitle]}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </div>

      {isHovered ? (
        <div
          className={clsx([
            classes.chartDiv,
            isHovered && classes.chartDivVisible,
          ])}
        >
          <LineChart />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

AnalyticCard.propTypes = {};

export default AnalyticCard;
