import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import ConsultantDetails from "./ConsultantDetails";
import { makeStyles } from "@mui/styles";
import commonImages from "../../../images/common";

const useStyles = makeStyles((theme) => ({
  questionnaireContainer: {
    fontFamily: "Montserrat !important",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "12.3rem",
    maxHeight: "3.5rem",
    height: "auto",
    width: "auto",
    cursor: "pointer",
    marginBottom: "60px",
  },
  leftView: {
    borderRight: "1px solid #EDEDED",
  },
  loginDiv: {
    maxWidth: "235px",
    width: "100%",
    margin: "71px auto 0 auto",
  },
  continueText: {
    fontWeight: 600,
    fontSize: "14px",
    marginBottom: "12px",
    cursor: "pointer",
  },
  continueDesc: {
    fontSize: "14px",
    color: theme.palette.secondary.light,
    marginBottom: "46px",
  },
  loginInitialText: {
    fontSize: "14px",
  },
  loginLink: {
    fontWeight: 600,
    color: `${theme.palette.primary.main} !important`,
    cursor: "pointer",
  },
  rightView: {
    padding: "60px",
  },
}));

const Questionnaire = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid container className={classes.questionnaireContainer}>
      <Grid item xs={3} className={classes.leftView}>
        <div className={classes.loginDiv}>
          <div
            className={classes.logo}
            onClick={() => {
              history.push("/");
            }}
          >
            <img src={commonImages.logo_v2} alt="Findpro logo" />
          </div>
          <Typography
            className={classes.continueText}
            onClick={() => {
              history.push("/consultant/sign-up");
            }}
          >
            Continue later?
          </Typography>
          <Typography className={classes.continueDesc}>
            We will still ask you to complete this before you’re able to list a
            job.
          </Typography>
          <Typography className={classes.loginInitialText}>
            Have an account already?{" "}
            <Link to="/consultant/login" className={classes.loginLink}>
              Login
            </Link>
          </Typography>
        </div>
      </Grid>
      <Grid item xs={9} className={classes.rightView}>
        <ConsultantDetails />
      </Grid>
    </Grid>
  );
};

Questionnaire.propTypes = {};

export default Questionnaire;
