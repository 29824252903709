import React, { useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../../images/hire_developer_team/developer";

const useStyles = makeStyles((theme) => ({
  devTypesContainer: {
    maxWidth: "1149px",
    margin: "0 auto 123px auto",
    [theme.breakpoints.down("1200")]: {
      maxWidth: "100%",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  title: {
    maxWidth: "875px",
    margin: "0 auto 55px auto",
    textAlign: "center",
    [theme.breakpoints.down("800")]: {
      fontSize: "32px",
      margin: "0 31px 16px 31px", // 51-20=31 was set for left,right since container already has 20px margin.
    },
  },
  description: {
    maxWidth: "881px",
    margin: "0 auto 106px auto",
    textAlign: "center",
    fontWeight: 500,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down("800")]: {
      fontSize: "15px",
      margin: "0 31px 65px 31px", // 51-20=31 was set for left,right since container already has 20px margin.
    },
  },
  chipsetDiv: {
    width: "100%",
    boxShadow: "0px 10.3501px 119.601px rgba(0, 0, 0, 0.07)",
    borderRadius: "6px",
    marginBottom: "30px",
    padding: "50px 64px",
    [theme.breakpoints.down("360")]: {
      padding: "25px 32px",
    },
  },
  chipsetDivHeading: {
    marginBottom: "22px",
    fontWeight: 700,
  },
  chipsetBody: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: "22px",
    columnGap: "16px",
    marginBottom: "22px",
  },
  chipInnerDiv: {
    flex: "0 0 calc(100% / 6)" /* for 6 items per row */,
    width: "fit-content",
  },
  showFewChips: {
    "&:nth-child(n+14):nth-last-child(n+3)": {
      display: "none",
    },
  },
  showMoreChips: {
    "&:nth-child(n+14):nth-last-child(n+3)": {
      display: "block",
    },
  },
  chip: {
    width: "190px",
    padding: "14px 25px",
    boxShadow: "0px 10.3501px 119.601px rgba(0, 0, 0, 0.07)",
    borderRadius: "6px",
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: "16px",
    fontWeight: 500,
    [theme.breakpoints.down("300")]: {
      width: "150px",
    },
  },
  chipsetDivFooter: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}));

const DeveloperTypes = (props) => {
  const classes = useStyles();
  const skillIcons = images.skills;
  const data = {
    title: "Hire Developers",
    description:
      "At FindPro, we understand the importance of staying up-to-date with the latest technologies. You can hire developers based on their skill,role and career trajectory.",
    skills: [
      {
        name: "React.js",
        icon: skillIcons[0],
      },
      {
        name: "Node.js",
        icon: skillIcons[1],
      },
      {
        name: "Python",
        icon: skillIcons[2],
      },
      {
        name: "AWS",
        icon: skillIcons[3],
      },
      {
        name: "Javascript",
        icon: skillIcons[4],
      },
      {
        name: "React Native",
        icon: skillIcons[5],
      },
      {
        name: "React.js",
        icon: skillIcons[0],
      },
      {
        name: "Node.js",
        icon: skillIcons[1],
      },
      {
        name: "Python",
        icon: skillIcons[2],
      },
      {
        name: "AWS",
        icon: skillIcons[3],
      },
      {
        name: "Javascript",
        icon: skillIcons[4],
      },
      {
        name: "React Native",
        icon: skillIcons[5],
      },
      {
        name: "React.js",
        icon: skillIcons[0],
      },
      {
        name: "Node.js",
        icon: skillIcons[1],
      },
      {
        name: "Python",
        icon: skillIcons[2],
      },
      {
        name: "AWS",
        icon: skillIcons[3],
      },
      {
        name: "Javascript",
        icon: skillIcons[4],
      },
      {
        name: "React Native",
        icon: skillIcons[5],
      },
      {
        name: "React.js",
        icon: skillIcons[0],
      },
      {
        name: "Node.js",
        icon: skillIcons[1],
      },
      {
        name: "Python",
        icon: skillIcons[2],
      },
      {
        name: "AWS",
        icon: skillIcons[3],
      },
      {
        name: "Javascript",
        icon: skillIcons[4],
      },
      {
        name: "React Native",
        icon: skillIcons[5],
      },
    ],
    roles: [
      "Full Stack Developer",
      "Back-end Developer",
      "Front-end Developer",
      "Mobile Developer",
    ],
    career: [
      "Software Developer",
      "Senior Engineer",
      "Architects",
      "Tech Leads",
      "VP of Engineer",
    ],
  };
  const ChipSetDiv = ({ heading, chipSetData, footerText }) => {
    const showSeeMoreOption = chipSetData.length > 18;
    const [seeMore, setSeeMore] = useState(false);
    const onSeeMoreOrLessClick = () => {
      setSeeMore((prevState) => !prevState);
    };
    return (
      <div className={classes.chipsetDiv}>
        <Typography variant="body2" className={classes.chipsetDivHeading}>
          {heading}
        </Typography>
        <div className={classes.chipsetBody}>
          {chipSetData.map((chipData, index) => (
            <div
              className={clsx([
                classes.chipInnerDiv,
                showSeeMoreOption && classes.showFewChips,
                seeMore && classes.showMoreChips,
              ])}
            >
              <Chip
                icon={
                  chipData?.icon ? (
                    <img src={chipData?.icon} alt={`heading-icon-${index}`} />
                  ) : null
                }
                label={chipData?.name ?? chipData}
                className={classes.chip}
                sx={{
                  height: "52px",
                  "& .MuiChip-label": {
                    display: "block",
                    whiteSpace: "normal",
                  },
                }}
              />
            </div>
          ))}
        </div>
        {showSeeMoreOption && (
          <Typography
            variant="body2"
            className={classes.chipsetDivFooter}
            onClick={onSeeMoreOrLessClick}
          >
            {seeMore ? "-" : "+"} {seeMore ? "See less" : "See more"}{" "}
            {footerText}
          </Typography>
        )}
      </div>
    );
  };
  return (
    <div className={classes.devTypesContainer}>
      <Typography className={classes.title} variant="h2">
        {data.title}
      </Typography>
      <Typography className={classes.description} variant="body1">
        {data.description}
      </Typography>
      <ChipSetDiv
        heading="Based on skill"
        chipSetData={data.skills}
        footerText="skills"
      />
      <ChipSetDiv
        heading="Based on role"
        chipSetData={data.roles}
        footerText="roles"
      />
      <ChipSetDiv
        heading="Based on career trajectory"
        chipSetData={data.career}
        footerText="positions"
      />
    </div>
  );
};

DeveloperTypes.propTypes = {};

export default DeveloperTypes;
