import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import JobStatus from "../../common/Status/Job";

import {
  getContractTypeLevel,
  getExperience,
} from "app/v2/Pages/common/getIdTypeLevel";

const useStyles = makeStyles((theme) => ({
  jobDiv: {
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
      transform: "translateY(-25px)",
    },
    padding: "36px",
  },
  draftJobDiv: {
    backgroundColor: "#F7F7F7",
  },
  pinnedJobDiv: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  pinnedIconButton: {
    backgroundColor: "#F4F8FF ",
  },
  postedUpdatedText: {
    fontSize: "14px",
    color: "#717171",
  },
}));

const DetailSection = ({ type, value, center = false }) => {
  value = value === "" ? "-" : value;
  return (
    <div className={`flex-1 ${center ? "text-center" : ""}`}>
      <div className="uppercase text-[12px] font-semibold text-[#717171]">
        {type}
      </div>
      <div className="text-[18px] font-bold">{value ?? "-"}</div>
    </div>
  );
};

const JobListCard = ({ data, experiencesData, contractTypesData }) => {
  const classes = useStyles();
  const history = useHistory();

  const salaryTypeTextMapper = {
    Hourly: "hr",
    Monthly: "month",
    Annual: "yr",
  };
  const salary = data?.salary;
  const salaryRange = salary?.range;
  const location =
    (data?.country ?? "") +
    ", " +
    (data?.state ?? "") +
    ", " +
    (data?.city ?? "");

  let createdAt = data?.createdAt ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  let updatedAt = data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;
  const isDraftJob = data?.status === "draft";

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div
        className={clsx([
          `flex flex-col gap-y-[36px]`,
          data?.is_pinned && classes.pinnedJobDiv,
          isDraftJob && classes.draftJobDiv,
          classes.jobDiv,
        ])}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="flex flex-col gap-y-[24px]">
          <div className="flex gap-x-[19px]">
            <div className="flex flex-col gap-y-[6px] flex-[3]">
              <div className="text-[24px] font-semibold w-fit">
                {data?.jobTitle ?? ""}
              </div>
              <div className="flex gap-x-[12px]">
                <span className={classes.postedUpdatedText}>
                  Posted {createdAt}
                </span>
                <span className={classes.postedUpdatedText}>
                  Last Updated {updatedAt}
                </span>
                <span className="text-[14px] text-[#717171] font-semibold">
                  JOB NO. {data?.jobNo ?? "-"}
                </span>
              </div>
              {/* <div>Hiring Manager - </div> */}
            </div>
            <div className="self-start">
              <JobStatus type={data?.status} />
            </div>
          </div>
          <div className="flex gap-x-[16px]">
            <DetailSection type="Open Positions" value={data?.openPositions} />
            <DetailSection
              type="Experience"
              value={getExperience(data?.experienceLevelId, experiencesData)}
            />
            <DetailSection
              type="Contract type"
              value={getContractTypeLevel(
                data?.contractType,
                contractTypesData
              )}
            />
            <DetailSection type="Work Type" value={data?.workType} />
            <DetailSection
              type={salaryRange ? "Salary Range" : `${salary?.type ?? ""} Rate`}
              value={
                <span>
                  ${" "}
                  {salaryRange
                    ? `${salaryRange?.min} - $ ${salaryRange?.max}/${
                        salaryTypeTextMapper?.[salary?.type] ?? "-"
                      }`
                    : `${salary?.fixedRate ?? "-"}`}
                </span>
              }
            />
          </div>
          <div className="flex gap-x-[16px]">
            <div className="flex gap-x-[16px] flex-[0.8]">
              <DetailSection type="location" value={location} />
              <DetailSection type="timezone" value={data?.timezone} />
            </div>
            <div className="p-[12px] bg-[#F7F7F7] flex gap-x-[10px] flex-[0.3]">
              <DetailSection
                type="Total Applicants"
                value={data?.totalApplicants ?? 0}
                center={true}
              />
              <DetailSection
                type="Accepted"
                value={data?.acceptedApplicants ?? 0}
                center={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

JobListCard.propTypes = {};

export default JobListCard;
