import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import {
  candidatesFilter,
  getConsultantsForFeatured,
  getCandidateList,
  getJobList,
  jobRequestForCandidate,
  getAdvanceConsultantSearchService,
} from "../../../services/client/dashboard.service";

// First, create the thunk
export const getConsultantsThunk = createAsyncThunk(
  "consultants/fetchConsultants",
  async (page) => {
    const limit = page.limit,
      offset = page.offset;
    return getCandidateList(limit, offset);
  }
);

export const getConsultantsForFeaturedThunk = createAsyncThunk(
  "consultants/getConsultantsForFeatured",
  // async (params) => {
  async (request, thunkAPI) => {
    // const limit = page.limit, offset = page.offset;
    // return candidatesFilter({limit, offset});
    return getConsultantsForFeatured(request).then((res) => {

            return res;
          });
  }
);


export const getAdvanceConsultantSearch = createAsyncThunk(
  "consultants/getConsultantsForFeatured",
  // async (params) => {
  async (request) => {
    // const limit = page.limit, offset = page.offset;
    // return candidatesFilter({limit, offset});
    return getAdvanceConsultantSearchService(request).then((res) => {

            return res;
          });
  }
)

// export const updateCandidateInfoThunk = createAsyncThunk(
//   "profile-settings/put",
//   async (request, thunkAPI) => {
//     return updateCandidateInfo(request).then((res) => {
//       thunkAPI.dispatch(profileComplete(res.isProfileComplete));
//       // updateProfileComplete(res.isProfileComplete);
//       return res;
//     });
//   }
// );

// export const updateProfilePictureThunk = createAsyncThunk(
//   "profile-settings/profile-picture/put",
//   async (request, thunkAPI) => {
//     return updateCandidateInfo(request).then((res) => {
//       thunkAPI.dispatch(profileComplete(res.isProfileComplete));
//       // updateProfileComplete(res.isProfileComplete);
//       return res;
//     });
//   }
// );
