import { createAsyncThunk } from "@reduxjs/toolkit";
import { profileComplete } from "app/common/login/login.slice";
import {
  getCandidateInfo,
  updateCandidateInfo,
} from "../../../services/consultant/profileSettings.service";

// First, create the thunk
export const candidateInfoThunk = createAsyncThunk(
  "candidate_info",
  async (request, thunkAPI) => {
    return getCandidateInfo();
  }
);

export const updateCandidateInfoThunk = createAsyncThunk(
  "profile-settings/put",
  async (request, thunkAPI) => {
    return updateCandidateInfo(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);

export const updateProfilePictureThunk = createAsyncThunk(
  "profile-settings/profile-picture/put",
  async (request, thunkAPI) => {
    return updateCandidateInfo(request).then((res) => {
      thunkAPI.dispatch(profileComplete(res.isProfileComplete));
      // updateProfileComplete(res.isProfileComplete);
      return res;
    });
  }
);
