
const JobPostsObj = 
{
  "rate": 22,
  "description": "<div data-contents=\"true\"><div data-block=\"true\" data-editor=\"2e87u\" data-offset-key=\"k0kv-0-0\"><div data-offset-key=\"k0kv-0-0\" class=\"public-DraftStyleDefault-block public-DraftStyleDefault-ltr\"><span data-offset-key=\"k0kv-0-0\"><span data-text=\"true\">uasydgwqer awiurhgai fiefuasd fhsdiu fd fsadufh hfusahdf sda aiueghrgefgsahgsadhfiuifhiueruewyurwryewquiryewqtryewtweghsdkjgfg</span></span></div></div></div>",
  "title": "Java Dev",
  "skillsets": [
  "Java"
  ],
  "published": "2022-11-04T03:48:41.136Z",
  "timezone": "MST",
  "skill_level": "Beginner",
  "status": "active",
  "state": "Uttar Pradesh",
  "country": "India",
  "invoices": [],
  "job_id": "FP75166",
  "_id": "63648b990fb50ccb61f9825b",
  "createdAt": "2023-04-06T05:17:11.955Z",
  "updatedAt": "2023-04-06T05:17:11.955Z",

  "jobName":"Development Team Lead",
  "logo":"https://source.unsplash.com/250x250/?company-logo",
  "role": "Hiring Manager",
  "roleName": "Mike Fitz",
  "createdBy": "Mike Fitz",
  "updatedBy": "Allen Fitz",
  "jobNo": "FP23117",
  "acceptedApplicants": 12,
  "rejectedApplicants": 4,
  "totalApplicants": 16,
  "totalOpenning": 14,
  "teamSize": 12,
  "category": "IT",
  "workType": "Hybrid",
  "contract": "Part Time",
  "level": "Junior",
  "tags": [
    'Teams Job', "Frontend", "Lead Position"
  ],
  "_created_at": "Yesterday",
  "_updated_at": "Today at 11:23",
};
export { JobPostsObj };
