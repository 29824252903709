import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Title from "../../common/Title";
import CustomButton from "app/v2/components/CustomButton";
import TaskCard from "./TaskCard";
import CustomDialog from "app/v2/components/CustomDialog";
import AddTask from "./AddTask";

const useStyles = makeStyles((theme) => ({
  jobFeedContainer: {
    width: "100%",
  },
  titleAndActionDiv: {
    padding:"10px",
    display: "flex",
    // position: "fixed",
    width: "97%",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1000,
    justifyContent: "space-between",
    alignItems: "center",
    height:"50%"
  },
  title:{

  },
  
  createTaskDiv: {
    display: "flex",
    justifyContent: "flex-end",
  },
  createTaskButton: {
    marginRight: "24px",
    padding: "12px 28px",
    marginBottom: "21px",
    margin: "10px",
    fontSize: "16px",
    boxSizing: "content-box",
  },
}));

const data = [
  {
    TaskTitle: "write a task to  add blue color",
    Posted: "12/12/2023",
    lastUpdated: "13/12/2023",
    TicketNo: "FRV212345",
    workType: "Pay as You Go",
    Rate: "$55/hour",
    OriginalEstimate: "6hours",
    TimeTracking: "7h 30m",
    status:"Pending",
    desciption:"  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    
  },
  {
    TaskTitle: "Create a Fiel of Login id",
    Posted: "12/12/2023",
    lastUpdated: "13/12/2023",
    TicketNo: "FRV212345",
    workType: "Pay as You Go",
    Rate: "$45/hour",
    OriginalEstimate: "8hours",
    TimeTracking: "5h 30m",
    status:"Accepted",
    desciption:"  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    
  },
  {
    TaskTitle: "Create a  sihnup page",
    Posted: "12/12/2023",
    lastUpdated: "13/12/2023",
    TicketNo: "FRV212345",
    workType: "Milestone",
    Rate: "$5500/Milestone",
    OriginalEstimate: "-",
    TimeTracking: "-",
    status:"In Progress",
    desciption:"  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    
  },
  {
    TaskTitle: "write a task to  add blue color",
    Posted: "12/12/2023",
    lastUpdated: "13/12/2023",
    TicketNo: "FRV212345",
    workType: "FixedRate",
    Rate: "$3500/Month",
    OriginalEstimate: "8hours",
    TimeTracking: "7h 30m",
    status:"Finished",
    desciption:"  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    
  },
];

const TimeSheetManagement = () => {
  const classes = useStyles();
  const [addTaskDialogueOpen,setAddTaskDialogueOpen]=useState(false)


  const handleDialogues =()=>{
    setAddTaskDialogueOpen(false)
  }

  return (
    <div >
      <div className={classes.titleAndActionDiv}>
        <div className="my-[15px] ml-[15px] text-[26px] font-semibold">Manage TimeSheet</div>
        <div className={classes.createTaskDiv}>
          <CustomButton
            type="button1"
            
            onClick={()=>{setAddTaskDialogueOpen(true)}}
          >
            Create Task
          </CustomButton>
        </div>
      </div>
      <div
        style={{
          margin: "25px",
          width: "95%",
          padding: "10px",
        }}
      >
        {data?.map((data) => (
          <TaskCard data={data} />
        ))}
      </div>
      <CustomDialog
            open={addTaskDialogueOpen}
            setOpen={setAddTaskDialogueOpen}
            title={
              <div className="text-[16px] font-semibold text-center">
                Add Task
              </div>
            }
            // title={<AddConsultantDialogTitle title="Add Consultant" />}
            content={
              <AddTask handleDialogues={handleDialogues} />
            }
          />
      
    </div>
    
  );
};

export default TimeSheetManagement;
