import { createSlice } from "@reduxjs/toolkit";
import {
  getResumeSettingsThunk,
  updateResumeSettingsThunk,
} from "./resume-settings.thunk";

const initialState = {
  profile_info: {
    profile_summary: {},
    self_intro: "",
    resume: "",
    skillsets: [],
    projects: [],
    education: [],
  }
};

const resumeSettingsSlice = createSlice({
  name: "resume_settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResumeSettingsThunk.fulfilled, (state, action) => {
        if (action.payload) {
          state.profile_info = action.payload;
        }
      })
      .addCase(updateResumeSettingsThunk.fulfilled, (state, action) => {
        state.profile_info = action.meta.arg.profile_info;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { candidateInfoIn, candidateInfoOut } = resumeSettingsSlice.actions;

export default resumeSettingsSlice.reducer;
