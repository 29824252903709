import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  docButton: {
    padding: "10px",
    border: "1px solid #EDEDED",
  },
}));

const Documents = () => {
  const classes = useStyles();
  return (
    <div className="flex gap-[16px]">
      <button className={classes.docButton}>
        <img alt="" src="/logosdropbox.svg" />
      </button>
      <button className={classes.docButton}>
        <img alt="" src="/logosgoogledrive.svg" />
      </button>
      <button className={classes.docButton}>
        <img alt="" src="/logosmicrosoftonedrive.svg" />
      </button>
    </div>
  );
};

export default Documents;
