import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { messages } from "app/v2/Pages/Utils/constants";
import Title from "../../common/Title";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ConnectionListCard from "./ConnectionsListCard"
import { getConnectionsData ,putConnectionsData,postConnectionsData} from "app/v2/services/consultant/connections.service";
import { getAccountType } from "../../Utils/utilFunctions";


const useStyles = makeStyles((theme) => ({
  connectionFeedContainerLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  tabs: {
    marginTop: "38px",
    marginLeft: "24px",
    display: "flex",
    columnGap: "60px",
  },
  tab: {
    fontWeight: 600,
    fontSize: "18px",
    paddingBottom: "24px",
    color: "#ABABAB",
    cursor: "pointer",
  },
  selectedTab: {
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  connectionFeedBodyContainer: {
    paddingTop: "171px", // headerDiv total height is 171 px(including margin,padding)
  },
  leftView: {
    padding: "48px 24px",
    width: "305px",
    position: "absolute",
    borderRight: "1px solid #EDEDED",
    overflow: "auto",
    height: "615px",
  },
  rightView: {
    margin: "10px 24px 24px 329px", // left margin - 305px +24px(here 305 is fixed leftView div's width )
  },
  searchTextDiv: {
    marginBottom: "26px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  levelCheckboxDiv: {
    margin: "12px 24px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "76px",
    rowGap: "12px",
  },
  loadMoreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyConnectionData = [
  {
    _id: "1",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "2",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "3",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "4",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "5",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "6",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
  {
    _id: "7",
    image: "/company_image.png",
    companyName: "ABC Company",
    role: "Sr. Product Owner",
    companySize: 14,
    location: "Singapore",
    bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
    skills: ["React", "NodeJS", "AngularJS"],
  },
];

const Connections = (props) => {
  const classes = useStyles();

  const sortData = [
    {
      label: "Recently Added",
      value: "Recently Added",
    },

    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          Expertise <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "Expertise",
    },
  ];

  const tabs = [
    "All Connections",
    // "Employer  Connections",
    // "Agency Connections",
  ];

  useEffect(() => {
    const storedUserData = localStorage.getItem("user");
    const user_id = JSON.parse(storedUserData);
    const userId=user_id._id
    const accountType = getAccountType();

    //  Get api 
    // const request = {
    //   user_id: "",
    //   from_id: user_id,
    //   fromUserType: accountType,
    //   toUserType: "Client",
    //   to_id: "111xxx...",
    //   status: "accepted",
    // };

    getConnectionsData(userId)
      .then((res) => {
        console.log("connections data",{ res });
        setDataLoading(false);
        setConnectionsData(res.data);
        // setConnectionsData(dummyConnectionData);
      })
      .catch((error) => {
        setDataLoading(false);
        setDataError(true);
      });

    setDataLoading(false);
    // setConnectionsData(dummyConnectionData);
  },[]);

  const [connectionsData, setConnectionsData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [searchText, setSearchText] = useState();
  const [currentSortSelectionValue, setCurrentSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [levelData, setLevelData] = useState([
    {
      label: "Expert",
      value: "Expert",
      checked: false,
    },
    {
      label: "Senior",
      value: "Senior",
      checked: false,
    },
    {
      label: "Junior",
      value: "Junior",
      checked: false,
    },
    {
      label: "Entry Level",
      value: "Entry Level",
      checked: false,
    },
  ]);

  const onSortChange = (id, currentValue) => {
    setCurrentSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState) => {
    setLevelData((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.value === checkboxValue) {
          return { ...item, checked: checkedState };
        }
        return item;
      });

      return updatedItems;
    });
  };

  const onTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  useEffect(() => {
    // add right api later.
    // getEmployerInfo()
    //   .then((res) => {
    //     console.log({ res });
    //        setDataLoading(false);
    //         setConnectionsData(res);
    //   })
    //   .catch((error) => {
    // setDataLoading(false);
    //      setDataError(true);
    // });
    setDataLoading(false);
    setConnectionsData(dummyConnectionData);
  }, []);

  return (
    <div className={dataLoading && classes.connectionFeedContainerLoading}>
      <>
        {dataLoading ? (
          <CircularProgress />
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <div>
                <div className={classes.headerDiv}>
                  <Title title="Connections" />
                  <div className={classes.tabs}>
                    {tabs?.map((tabName) => (
                      <div
                        className={clsx([
                          classes.tab,
                          tabName === selectedTab && classes.selectedTab,
                        ])}
                        onClick={() => {
                          onTabClick(tabName);
                        }}
                      >
                        {tabName}
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classes.connectionFeedBodyContainer}>
                  <Grid container>
                    <Grid item xs={3} className={classes.leftView}>
                      <div className={classes.searchTextDiv}>
                        <CustomTextfield
                          id="searchText"
                          value={searchText}
                          placeholder="Search by name"
                          onChange={(id, value) => {
                            setSearchText(value);
                          }}
                          endAdornment={<SearchRoundedIcon />}
                        />
                      </div>
                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Sort{" "}
                        </Typography>
                        <div className={classes.sortRadioGroupDiv}>
                          <CustomRadioGroup
                            id="sort-radio-group"
                            name="sort-radio-group"
                            defaultValue={currentSortSelectionValue}
                            data={sortData}
                            onChange={onSortChange}
                          />
                        </div>
                      </div>

                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Filter
                        </Typography>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Level
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <div className={classes.levelCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="level-checkbox-group"
                                  data={levelData}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Skills
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Location
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Suspendisse malesuada lacus ex,
                                sit amet blandit leo lobortis eget.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={9} className={classes.rightView}>
                      {connectionsData?.map((connectionData) => (
                        <ConnectionListCard data={connectionData} />
                      ))}
                      <div className={classes.loadMoreDiv}>
                        <MoreHorizIcon
                          className={classes.loadMoreIcon}
                          fontSize="large"
                        />
                        <Typography className={classes.loadMoreText}>
                          Load more
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

Connections.propTypes = {};

export default Connections;
