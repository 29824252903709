import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Keyboard,
  Scrollbar,
  Navigation,
  Pagination,
  A11y,
  Controller,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";
import clientImages from "../../../landingPage/logos/client";

const useStyles = makeStyles((theme) => ({
  testimonialsRoot: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    padding: "127px 114px",
    [theme.breakpoints.down("600")]: {
      padding: "99px 60px",
    },
  },
  reviewCard: {
    display: "flex",
    alignItems: "center",
    padding: "0 163px 0 108px",
    columnGap: "100px",
    [theme.breakpoints.up("1750")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("1400")]: {
      paddingRight: "100px",
    },
    [theme.breakpoints.down("1200")]: {
      columnGap: "50px",
    },
    [theme.breakpoints.down("1000")]: {
      flexDirection: "column",
      padding: 0,
      rowGap: "49px",
    },
  },
  clientLogo: {
    width: "496px",
    height: "506px",
    objectFit: "contain",
    [theme.breakpoints.down("1000")]: {
      width: "280px",
      height: "285px",
    },
  },
  reviewTextDiv: {
    display: "flex",
    flexDirection: "column",
    width: "662px",
    rowGap: "86px",
    [theme.breakpoints.down("1400")]: {
      width: "fit-content",
    },
    [theme.breakpoints.down("1000")]: {
      rowGap: "49px",
    },
  },
  review: {
    fontWeight: 600,
    color: "inherit",
    [theme.breakpoints.down("1400")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("1200")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("1000")]: {
      textAlign: "justify",
      wordSpacing: "-3px",
    },
    [theme.breakpoints.down("600")]: {
      textAlign: "left",
      fontSize: "2rem",
    },
  },
  clientName: {
    textTransform: "uppercase",
  },
}));

const Testimonials = (props) => {
  const classes = useStyles();
  const clientReviewsImages = images.client_reviews;
  const reviewsData = [
    {
      image: clientImages.r1[2], // Elements Global Services
      review:
        "Without facing any challenges, we have hired a cost effective team of engineers",
      clientName: "JOHN DOE",
    },
    {
      image: clientImages.r2[4], // Aspire health alliance
      review:
        "The team at FindPro helped us cut down the time it took to hire developers",
      clientName: "JOHN DOE",
    },
    {
      image: clientImages.r4[3], // Uncommonlogic
      review:
        "Fast, flexible, efficient. FindPro delivered top-notch data analytics and data science resources for our business in record time.",
      clientName: "JOHN DOE",
    },
    {
      image: clientImages.r4[3], // CoverIT (Incorrect logo,ask uday)
      review:
        "FindPro made hiring legacy and infra professionals a breeze. Their team was efficient and effective in finding the right fit for our needs.",
      clientName: "JOHN DOE",
    },
    {
      image: clientImages.r4[4], // Arcon
      review:
        "Hiring .NET Core web developers was made easy with their support and expertise. Highly recommend!",
      clientName: "JOHN DOE",
    },
    {
      image: clientImages.r5[4], // ADNOC
      review:
        "Streamlined AI, Blockchain & Data Science hiring process, with exceptional results.",
      clientName: "JOHN DOE",
    },
  ];
  const mdDown = useMediaQuery("(max-width:1000px)");
  const ReviewCard = ({ reviewData }) => {
    return (
      <div className={classes.reviewCard}>
        <div>
          <img src={reviewData.image} className={classes.clientLogo} />
        </div>
        <div className={classes.reviewTextDiv}>
          <Typography variant="h4" className={classes.review}>
            "{reviewData.review}"
          </Typography>
          <Typography variant="body1" className={classes.clientName}>
            {reviewData.clientName}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <div className={classes.testimonialsRoot}>
      <Swiper
        slidesPerView={1}
        spaceBetween={5}
        grabCursor={true}
        loop={true}
        keyboard={{
          enabled: true,
        }}
        navigation={!mdDown}
        modules={[
          Keyboard,
          Scrollbar,
          Navigation,
          Pagination,
          A11y,
          Controller,
        ]}
        className="mySwiper"
        injectStyles={[
          `
              .swiper-button-next,
              .swiper-button-prev {
                background-color:#fff;
              }             
          `,
        ]}
      >
        {reviewsData.map((reviewData, index) => (
          <SwiperSlide>
            <ReviewCard reviewData={reviewData} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

Testimonials.propTypes = {};

export default Testimonials;
