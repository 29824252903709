import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Import the styles
import {
  CircularProgress,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import images from "app/v2/images/common";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    rowGap: "27px",
    width: "484px",
    height: "504px",
    boxSizing: "content-box",
    padding: "35px 26px",
    border: "1px solid black",
  },
  linearProgressRoot: {
    backgroundColor: "#F7F7F7", // buffer
    borderRadius: "4px",
  },
  linearProgressBar: {
    background: "linear-gradient(#222222, #22222200)",
  },
  linearProgressBarDone: {
    backgroundColor: "#ABABAB !important",
  },
  circularProgressRoot: {
    width: "100% !important",
    height: "100% !important",
  },
  circularProgressPath: {
    backgroundColor: "#ededed !important",
    color: "#1c81ff !important",
  },
}));

const searchLoaderMessages = {
  STEP_1: {
    text: "The search is on...",
    subText: "Spotting for consultants & agencies match your location criteria",
    consultantCount: 0,
  },
  STEP_2: {
    text: "We found a few...",
    subText:
      "We found few matches for your location, now filtering basis your technical requirements.",
    consultantCount: 357,
  },
  STEP_3: {
    text: "Double checking...",
    subText:
      "We are nearly there, budget mapping should be the final step towards find the perfect matches.",
    consultantCount: 642,
  },
  STEP_4: {
    text: "Double checking...",
    subText:
      "We are nearly there, budget mapping should be the final step towards find the perfect matches.",
    consultantCount: 357,
  },
  FINAL: {
    text: "Got it!",
    subText:
      "We found few matches for your location, now filtering basis your technical requirements.",
    consultantCount: 112,
  },
};

const CircularProgressWithLabel = (props) => {
  const classes = useStyles();
  return (
    <div className="w-[273px] h-[273px] relative">
      <CircularProgress
        variant="determinate"
        {...props}
        classes={{ circle: classes.circularProgressPath }}
        className={classes.circularProgressRoot}
        thickness={1.5}
      />
      <div className="absolute top-[80px] left-[50px] text-center">
        {props?.children}
      </div>
    </div>
  );
};

const SearchLoader = (props) => {
  const classes = useStyles();

  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [lp1, setLP1] = useState(0);
  const [lp2, setLP2] = useState(0);
  const [lp3, setLP3] = useState(0);
  const [lp4, setLP4] = useState(0);
  const [searchMessage, setSearchMessage] = useState(
    searchLoaderMessages.STEP_1
  );

  const MIN = 0;
  const MAX = 25;
  const normaliseLinearLoader = (value) => ((value - MIN) * 100) / (MAX - MIN);

  useEffect(() => {
    setTimeout(() => {
      let message;

      if (loadingPercentage <= 25) setLP1(loadingPercentage);
      else if (loadingPercentage > 25 && loadingPercentage <= 50) {
        message = searchLoaderMessages.STEP_2;
        setLP2(loadingPercentage - 25);
      } else if (loadingPercentage > 50 && loadingPercentage <= 75) {
        message = searchLoaderMessages.STEP_3;
        setLP3(loadingPercentage - 50);
      } else {
        if (loadingPercentage > 75 && loadingPercentage < 100)
          message = searchLoaderMessages.STEP_4;
        else message = searchLoaderMessages.FINAL;
        setLP4(loadingPercentage - 75);
      }

      loadingPercentage < 100 && setLoadingPercentage(loadingPercentage + 1);
      message && setSearchMessage(message);
    }, 50);
  }, [loadingPercentage]);

  return (
    <div className={classes.root}>
      <div className="w-full h-auto max-w-[102px] max-h-[32px]">
        <img
          src={images.logo_v2}
          alt="Findpro logo"
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = (
              <NoProfileImageSVG className="w-full h-full object-cover" />
            ); // Set fallback image
          }}
        />
      </div>
      <div>
        <div className="text-[32px] font-semibold mb-[8px]">
          {searchMessage?.text ?? ""}
        </div>
        <div className="text-[16px]">{searchMessage?.subText ?? ""}</div>
      </div>
      <div className="flex  items-center justify-center gap-x-[24px]">
        <div className="flex-1">
          <LinearProgress
            variant="determinate"
            value={normaliseLinearLoader(lp1)}
            classes={{
              bar:
                lp1 === 25
                  ? classes.linearProgressBarDone
                  : classes.linearProgressBar,
              root: classes.linearProgressRoot,
            }}
          />
        </div>
        <div className="flex-1">
          <LinearProgress
            variant="determinate"
            value={normaliseLinearLoader(lp2)}
            classes={{
              bar:
                lp2 === 25
                  ? classes.linearProgressBarDone
                  : classes.linearProgressBar,
              root: classes.linearProgressRoot,
            }}
          />
        </div>
        <div className="flex-1">
          <LinearProgress
            variant="determinate"
            value={normaliseLinearLoader(lp3)}
            classes={{
              bar:
                lp3 === 25
                  ? classes.linearProgressBarDone
                  : classes.linearProgressBar,
              root: classes.linearProgressRoot,
            }}
          />
        </div>
        <div className="flex-1">
          <LinearProgress
            variant="determinate"
            value={normaliseLinearLoader(lp4)}
            classes={{
              bar:
                lp4 === 25
                  ? classes.linearProgressBarDone
                  : classes.linearProgressBar,
              root: classes.linearProgressRoot,
            }}
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <CircularProgressWithLabel value={loadingPercentage}>
          <div className="text-[48px] font-semibold">
            {searchMessage.consultantCount ?? ""}
          </div>
          <div className="text-[14px] font-semibold text-[#8C8C8C]">
            CONSULTANTS MATCHED
          </div>
        </CircularProgressWithLabel>
      </div>
      {/* <div className="w-[273px] h-[273px] flex justify-center">
        <CircularProgressbar
          value={loadingPercentage}
          text={`${searchMessage.consultantCount ?? ""}`}
          strokeWidth={3}
          styles={buildStyles({
            pathColor: "#1c81ff",
            trailColor: "#ededed",
            textColor: "black",
            strokeLinecap: "butt",
          })}
        />
      </div> */}
    </div>
  );
};

SearchLoader.propTypes = {};

export default SearchLoader;
