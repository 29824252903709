import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import About from "./About";
import Publishers from "./Publishers";
import TitleWithConnector from "../../components/TitleWithConnector";
import Features from "./Features";
import UserTypes from "./UserTypes";
import Technologies from "./Technologies";
import Benefits from "./Benefits";
import GlobalITTalent from "./GlobalITTalent";
import Testimonials from "./Testimonials";
import Clientele from "./Clientele";
import FAQ from "./FAQ";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
    [theme.breakpoints.down("600")]: {
      fontSize: "1rem",
    },
  },
  greyText: {
    color: theme.palette.secondary.light,
  },
}));

const Homepage = (props) => {
  const classes = useStyles();
  return (
    <>
      <About />
      <TitleWithConnector
        titleComponent={
          <Typography variant="body1" className={classes.title}>
            Media Announcements
          </Typography>
        }
      />
      <Publishers />
      <Features />
      <UserTypes />
      <TitleWithConnector
        titleComponent={
          <Typography variant="body1" className={classes.title}>
            Real-Time <span className={classes.greyText}>Talent</span>{" "}
          </Typography>
        }
      />
      <Technologies />
      <Benefits />
      <GlobalITTalent />
      <Testimonials />
      <Clientele />
      <FAQ />
    </>
  );
};

Homepage.propTypes = {};

export default Homepage;
