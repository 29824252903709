import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    textTransform: "none",
  },
  buttonContainer: {
    "& .noCurvedBorderButton": {
      borderRadius: "0px !important",
    }, // added this here to override(applying styles to child within parent container has more specificity) the other borderRadius css defined for the button in app.scss.
  },
  button1: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: theme.palette.common.black,
    },
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  },
  button2: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  },
  button3: {
    backgroundColor: `${theme.palette.common.blue} !important`,
    color: `${theme.palette.common.white} !important`,
    "&:hover": {
      backgroundColor: theme.palette.common.blue,
    },
    "&:disabled": {
      backgroundColor: `${theme.palette.secondary.light} !important`,
    },
  },
}));

const CustomButton = (props) => {
  const classes = useStyles();
  const { type, children, onClick, customClasses, disabled } = props;
  const isButton1 = type === "button1";
  const isButton3 = type === "button3";
  // black bg,white text. button2 is vice versa.
  return (
    <div className={classes.buttonContainer}>
      <Button
        className={clsx([
          customClasses,
          classes.button,
          // isButton1 ? classes.button1 : classes.button2,
          isButton1
            ? classes.button1
            : isButton3
            ? classes.button3
            : classes.button2,

          "noCurvedBorderButton",
        ])}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </div>
  );
};
CustomButton.propTypes = {};
export default CustomButton;
