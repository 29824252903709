import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  FormControl,
} from "@mui/material";
import { useField } from "formik";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  element: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        boxSizing: "border-box",
        border: 0,
        borderBottom: " 0.7px solid #717171",
      },
      "& .MuiInputBase-input": {
        padding: "12px",
        fontSize: "16px",
        "--tw-ring-shadow": "0 0 #000 !important",
      },
    },
    "& .MuiFormLabel-root": {
      fontSize: "16px",
    },
  },
}));
const PasswordFieldComponent = (props) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  const validateRegax = (pattern) => {
    return !pattern.test(field.value);
  };

  const errorMessage = () => {
    return (
      <span className="field-info px-2" style={{ color: "#dc3545" }}>
        <sup>*</sup>
        {meta?.error === "password"
          ? "One Uppercase, One Number, One Special Charater & Minimum 8 characters"
          : `${meta.error}`}
      </span>
    );
  };

  return (
    <>
      <FormControl fullWidth>
        <TextField
          fullWidth
          size="medium"
          type={showPassword ? "text" : "password"}
          error={meta.touched && !!meta.error}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          classes={{ root: classes.element }}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? errorMessage() : null}
      </FormControl>
    </>
  );
};

export default PasswordFieldComponent;
