import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import CustomSlickCarousel from "../components/CustomSlickCarousel";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("600")]: {
      marginBottom: "3.6rem",
    },
  },
  bgBlack: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  // imageContainer: {
  //   display: "flex",
  //   justifyContent: "space-evenly",
  // },
  heading: {
    textAlign: "center",
    marginBottom: "10rem",
    color: "inherit",
    maxWidth: "797px",
    margin: "auto",
    [theme.breakpoints.down("1500")]: {
      marginBottom: "6rem",
    },
    [theme.breakpoints.down("800")]: {
      marginBottom: "4.5rem",
    },
    [theme.breakpoints.down("600")]: {
      fontSize: theme.typography.body1.fontSize,
      marginBottom: "3.6rem",
    },
  },
  image: {
    objectFit: "none",
    [theme.breakpoints.down("1600")]: {
      maxWidth: "60%",
      objectFit: "scale-down",
    },
  },
}));
const TitleAndImageGroup = (props) => {
  const { title, images, bgColor } = props;
  const classes = useStyles();
  const lgDown = useMediaQuery("(max-width:1700px)");
  const carouselSettings = {
    slidesToShow: lgDown ? 3 : images?.length - 1,
    infinite: true,
    autoplay: true,
    // speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    // className: classes.slickCarouselDiv,
    centerMode: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <div className={clsx(classes.root, bgColor === "black" && classes.bgBlack)}>
      <Typography variant="h4" className={classes.heading}>
        {title}
      </Typography>
      <CustomSlickCarousel settings={carouselSettings}>
        {images.map((image, index) => (
          <img src={image} className={classes.image} />
        ))}
      </CustomSlickCarousel>
      {/* <div className={classes.imageContainer}>
        <Swiper
          slidesPerView={lgDown ? "auto" : images?.length}
          spaceBetween={5}
          grabCursor={true}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          speed={500}
          modules={[Autoplay]}
          className="mySwiper"
        >
          {images.map((image, index) => (
            <SwiperSlide>
              <img src={image} className={classes.image} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}
    </div>
  );
};

TitleAndImageGroup.propTypes = {};

export default TitleAndImageGroup;
