import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  switchContainer: {
    display: "flex",
  },
  switchBackground: {
    width: "36px", // Width of the switch background
    height: "20px", // Height of the switch background
    borderRadius: "10px", // Rounded corners
    // backgroundColor: `${theme.palette.primary.light} !important`, // Light blue background for the switch background
    backgroundColor: "gray",
    cursor: "pointer",
  },
  switchButton: {
    width: "19px", // Width of the switch button
    height: "19px", // Height of the switch button
    borderRadius: "50%", // Make it a circle
    // backgroundColor: `${theme.palette.primary.main} !important`, // Blue background for the switch button
    backgroundColor: "black",
    transform: "translateX(0px)", // Start position (left)
    transition: "transform 0.2s ease", // Smooth transition
  },
  switchButtonOn: {
    transform: "translateX(16px)", // Move the button to the right when on
  },
  switchTrackOn: {
    backgroundColor: "#ABABAB !important",
  },
  switchTrackOff: {
    // backgroundColor: "#f4f4f4 !important",
    backgroundColor: "#ABABAB !important",
  },
  switchThumb: {
    backgroundColor: `${theme.palette.common.black} !important`,
  },
  formControlLabelRoot: {
    margin: 0,
  },
  switchLabel1: {
    fontSize: "14px",
    fontWeight: "700",
    paddingRight: "8px",
  },

  switchLabel2: {
    fontSize: "14px",
    fontWeight: "700",
    marginLeft: "8px", // Adjust the spacing between the label and the switch button
  },
}));

const TwoLabelSwitch = (props) => {
  const classes = useStyles();
  const { id, onChange, label1, label2, defaultSelection, disabled } = props;
  const [checked, setChecked] = React.useState(defaultSelection);

  const handleSwitchChange = () => {
    const updatedValue = !checked;
    setChecked(updatedValue);
    onChange(id, updatedValue);
  };

  React.useEffect(() => {
    setChecked(defaultSelection);
  }, [defaultSelection]);

  React.useEffect(() => {
    console.log({ checked });
  }, [checked]);

  return (
    <div className={classes.switchContainer}>
      <span className={classes.switchLabel1}>{label1}</span>

      <div
        className={classes.switchBackground}
        onClick={!disabled ? handleSwitchChange : undefined}
      >
        <div
          className={clsx(
            classes.switchButton,
            checked && classes.switchButtonOn
          )}
        />
      </div>
      {<span className={classes.switchLabel2}>{label2}</span>}
    </div>
  );
};

TwoLabelSwitch.propTypes = {};

export default TwoLabelSwitch;
