import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomLabel from "./filters/CustomLabel";

const useStyles = makeStyles((theme) => ({
  formControlLabelRoot: {
    margin: "1px",
    width: "fit-content",
  },
  checkbox: {
    padding: "0px 8px 0 0",
    color: `${theme.palette.common.black} !important`,
  },
  checkboxDisabled: {
    color: `${theme.palette.secondary.main} !important`,
  },
  checkboxLabel: {
    fontSize: "14px",
  },
}));

const CustomCheckbox = ({
  id,
  label,
  checked,
  onChange,
  disabled,
  customClasses,
  size,
}) => {
  const classes = useStyles();
  const [checkedState, setCheckedState] = useState(checked);
  const handleChange = (event) => {
    onChange(id, event.target.checked);
  };

  useEffect(() => {
    checked = checked === undefined ? false : checked;
    setCheckedState(checked);
  }, [checked]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkedState}
          disabled={disabled}
          onChange={handleChange}
          classes={{
            root: clsx([
              classes.checkbox,
              disabled && classes.checkboxDisabled,
            ]),
            ...customClasses,
          }}
          size={size}
        />
      }
      label={label}
      classes={{
        root: classes.formControlLabelRoot,
        label: customClasses?.label ?? classes.checkboxLabel,
      }}
    />
  );
};

CustomCheckbox.propTypes = {};

export default CustomCheckbox;
