import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountType } from "../login/login.service";
import { getSubscriptionDetails } from "./subscribe.service.component";
import IncompleteProfileAlertBox from "../components/IncompleteProfileAlertBox";
import TitleCard from "app/layout/title-card";

const {
  Container,
  CircularProgress,
  Zoom,
  IconButton,
} = require("@mui/material");
const { default: FooterComponent } = require("app/layout/footer");
const { default: HeaderComponent } = require("app/layout/header");
const React = require("react");
const { default: SubscribeComponent } = require("./subscribe.component");

const SubscribePage = (props) => {
  const [plans, setPlans] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    getSubscriptionDetails()
      .then((res) => {
        if (
          !props.softSubs &&
          ((res.subscribeOptions.enabled &&
            res.userSubscription?.subscription?.status) ||
            !res.subscribeOptions.enabled)
        ) {
          enqueueSnackbar(
            "Either you're already subscribed or\nYour previous payment is in process.\nPlease login again or contact admin",
            {
              variant: "warning",
              style: { whiteSpace: "pre-line" },
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              TransitionComponent: Zoom,
              persist: true,
              action: (key) => (
                <IconButton
                  onClick={() => {
                    closeSnackbar(key);
                  }}
                >
                  <Close />
                </IconButton>
              ),
            }
          );
        } else {
          setPlans(res.subscribeOptions?.plans);
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Error fetching data", { variant: "error" });
        window.location.pathname = "/";
      });
  }, []);

  let features = {
    Starters: [
      {
        title: "5 Active Jobs",
        description:
          "Active jobs are the maximum number of jobs you have posted simultaneously during 30-day period",
      },
      {
        title: "50 Resume Views",
        description:
          "Resume views are the maximum number of resumes you can access from our searchable database during the 30-day period",
      },
    ],
    Standard: [
      {
        title: "15 Active Jobs",
        description:
          "Active jobs are the maximum number of jobs you have posted simultaneously during 30-day period",
      },
      {
        title: "150 Resume Views",
        description:
          "Resume views are the maximum number of resumes you can access from our searchable database during the 30-day period",
      },
    ],
    Premium: [
      {
        title: "25 Active Jobs",
        description:
          "Active jobs are the maximum number of jobs you have posted simultaneously during 30-day period",
      },
      {
        title: "250 Resume Views",
        description:
          "Resume views are the maximum number of resumes you can access from our searchable database during the 30-day period",
      },
    ],
    Unlimited: [
      {
        title: "Active Jobs",
        description:
          "No restrictions on number of Job Posts during the 30-day period",
      },
      {
        title: "Resume Views",
        description:
          "Unlimted access to our valuable database & download resumes during the 30-day period",
      },
      {
        title: "Vendor Profiles",
        description:
          "Gain access to large pool of profiles submitted by vendors during the 30-day period",
      },
      {
        title: "Schedule Interviews",
        description:
          "Gain access to express intreset on mulitple profiles & schedule interviews on just one click",
      },
    ],
  };

  const isProfileComplete = useSelector(
    (state) => state?.login_info?.value?.isProfileComplete
  );

  if (getAccountType() === "vendors") {
    features = {
      ...features,
      Unlimited: [
        {
          title: "Unlimited Job Views",
          description:
            "Job views are the maximum number of employer jobs you can view during 30-day period",
        },
        {
          title: "Unlimited Candidates",
          description:
            "Promote as many Candidates you want for any job posting during the 30-day period",
        },
      ],
    };
  }

  return (
    <div>
      <HeaderComponent menuList={props.menuList || []} />
      {isProfileComplete === false && <IncompleteProfileAlertBox />}
      <TitleCard />
      {!plans.length ? (
        <CircularProgress
          sx={{
            display: "flex",
            margin: "auto",
            height: "100%",
            minHeight: "68vh",
          }}
        />
      ) : (
        <div className="w-100 subscribeCompBody">
          <SubscribeComponent plans={plans} features={features} />
        </div>
      )}
      <FooterComponent />
    </div>
  );
};

export default SubscribePage;
