import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Typography } from "@mui/material";
import CustomDialog from "app/v2/components/CustomDialog";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "891px",
    margin: "auto",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  matchRateText: {
    color: theme.palette.primary.main,
  },
}));
const InviteJobDialog = ({ open, setOpen }) => {
  const classes = useStyles();

  const Title = () => {
    return <Typography className={classes.title}>Invite to Job</Typography>;
  };

  const Content = () => {
    const agencyData = {
      name: "Haus Tech",
      matchRate: "High",
      jobPost: " Job 27: Frontend Developer for Contractual Project",
      jobDetailOverview: {
        hourlyRate: "$30",
        level: "Junior",
        contract: "Part Time",
        workMode: "Hybrid",
        category: "I.T. Team",
      },
      agencyDetailOverview: {
        consultantCount: "8",
        companySize: "15-30",
        website: "haustech.io",
        location: "Singapore City, Singapore",
        timezone: "Singapore Standard Time (GMT + 8.0)",
        timeOverlap: "16:00-21:00 SGST",
      },
    };

    return (
      <div className="self-stretch bg-wireframe-ff-text flex flex-col p-12 items-start justify-center gap-[33px] text-left text-5xl text-neutral-900">
        <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative leading-[120%] font-semibold">
              Invite {agencyData?.name} to apply
            </div>
            <div className="self-stretch bg-f7-background w-[145px] flex flex-col p-2 box-border items-start justify-center gap-[2px] text-3xs text-neutral-500">
              <div className="flex flex-row items-center justify-start gap-[6px]">
                <b className="relative">MATCH RATE</b>
                <img
                  className="relative w-4 h-4 overflow-hidden shrink-0"
                  alt=""
                  src="/iconoirdoublecheck.svg"
                />
              </div>
              <div
                className={clsx([
                  classes.matchRateText,
                  "relative text-sm leading-[130%] font-semibold text-primary-600 text-center",
                ])}
              >
                {agencyData?.matchRate}
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-xs">
            <div className="relative leading-[120%] font-semibold">
              JOB POST
            </div>
            <div className="relative text-lg leading-[130%] font-semibold text-neutral-800">
              {agencyData?.jobPost}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-base">
          <div className="relative leading-[130%] font-semibold">
            Job Detail Overview
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-xs text-neutral-500">
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="relative leading-[120%] font-semibold">
                HOURLY RATE
              </div>
              <div className="relative text-base leading-[160%] text-neutral-900">
                {agencyData?.jobDetailOverview?.hourlyRate}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="relative leading-[120%] font-semibold">LEVEL</div>
              <div className="relative text-base leading-[160%] text-neutral-900">
                {agencyData?.jobDetailOverview?.level}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%] font-semibold">
                CONTRACT
              </div>
              <div className="relative text-base leading-[160%] text-neutral-900">
                {agencyData?.jobDetailOverview?.contract}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="relative leading-[120%] font-semibold">
                WORK MODE
              </div>
              <div className="relative text-base leading-[160%] text-neutral-900">
                {agencyData?.jobDetailOverview?.workMode}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="relative leading-[120%] font-semibold">
                CATEGORY
              </div>
              <div className="relative text-base leading-[160%] text-neutral-900">
                {agencyData?.jobDetailOverview?.category}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-xs text-neutral-500">
          <div className="relative text-base leading-[130%] font-semibold text-neutral-900">
            Agency Detail Overview
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%] font-semibold">
                FINDPRO CONSULTANTS
              </div>
              <div className="self-stretch relative text-base leading-[160%] text-neutral-900">
                {agencyData?.agencyDetailOverview?.consultantCount}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%] font-semibold">
                COMPANY SIZE
              </div>
              <div className="self-stretch relative text-base leading-[160%] text-neutral-900">
                {agencyData?.agencyDetailOverview?.companySize}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%] font-semibold">
                WEBSITE
              </div>
              <div className="self-stretch relative text-base leading-[160%] text-neutral-900">
                {agencyData?.agencyDetailOverview?.website}
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%]  font-semibold">
                LOCATION
              </div>
              <div className="self-stretch relative text-base leading-[160%] text-neutral-900">
                {agencyData?.agencyDetailOverview?.location}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%]  font-semibold">
                TIMEZONE
              </div>
              <div className="self-stretch relative text-base leading-[160%] text-neutral-900">
                {agencyData?.agencyDetailOverview?.timezone}
              </div>
            </div>
            <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
              <div className="self-stretch relative leading-[120%]  font-semibold">
                TIME OVERLAP
              </div>
              <div className="self-stretch relative text-base leading-[160%] text-neutral-900">
                {agencyData?.agencyDetailOverview?.timeOverlap}
              </div>
            </div>
          </div>
        </div>
        <button className="cursor-pointer [border:none] py-3 px-7 bg-neutral-900 self-stretch flex flex-row items-center justify-center gap-[10px]">
          <div className="relative text-base font-semibold font-button-text-button-16 text-white_A700 text-center">
            Invite {agencyData?.name}
          </div>
          <img
            className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
            alt=""
            src="/iconfilledsend.svg"
          />
        </button>
      </div>
    );
  };

  return (
    <div>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        title={<Title />}
        content={<Content />}
        classes={{
          paper: classes.dialog,
        }}
      />
    </div>
  );
};

InviteJobDialog.propTypes = {};

export default InviteJobDialog;
