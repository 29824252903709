import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Button, Typography } from "@mui/material";
import { UserContext } from "app/v2";
import { useHistory } from "react-router-dom";
import { getContractTypeLevel } from "app/v2/Pages/common/getIdTypeLevel";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { useDispatch, useSelector } from "react-redux";
import { isString } from "formik";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  body: {
    paddingTop: "119px",
  },
  jobDetailsDiv: {},
  orgImage: {
    width: "56px",
    height: "56px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  companyName: {
    fontSize: "24px",
    fontWeight: 600,
  },
  activeWorkText: {
    fontSize: "14px",
    fontWeight: 600,
  },
  acceptedDateandAppNo: {
    fontSize: "14px",
    color: "#717171",
    whiteSpace: "nowrap",
  },
  detailType: {
    fontSize: "10px",
    fontWeight: 700,
    color: "#717171",
    marginBottom: "6px",
  },
  detailValue: {
    fontSize: "14px",
    fontWeight: 600,
  },
  assessmentText: {
    fontSize: "14px",
    fontWeight: 500,
    color: "#FBBC05",
  },
  jobCompletedText: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#717171",
  },
  workTimeDiv: {
    borderRight: "1px solid #E0E0E0",
    paddingRight: "32px",
  },
  jobDetailsSet3: {
    background: "#F7F7F7",
    display: "flex",
  },
  viewContractButton: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
    padding: "10px 15px 10px 20px",
    display: "flex",
    columnGap: "10px",
  },
  viewJobButton: {
    border: "1px solid #EDEDED",
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    padding: "10px 20px",
  },
  actionsDiv: {},
}));

const List = ({data}) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { experiences, contractTypes, skills, industryData } = useSelector(
    (state) => state.misc
  );

  const contractTypeLevel = getContractTypeLevel(
    data?.jobDetails?.contractType,
    contractTypes
  );

  const documentStatusTypeText = {
    underAssessment: (
      <Typography className={classes.assessmentText}>
        Documents under assessment
      </Typography>
    ),
    completed: (
      <Typography className={classes.jobCompletedText}>
        {" "}
        Job Completed
      </Typography>
    ),
  };

  // const data = {
  //   image: "/company_image.png",
  //   companyName: "ABC Company",
  //   acceptedDate: "April 2, 2023",
  //   appNo: "FP23147",
  //   onboardingStatus: "underAssessment",
  //   workTime: "9:00 am - 5:00 pm",
  //   timeZone: "(SGST GMT +8.0)",
  //   startDate: "April 23, 2023",
  //   jobNo: "FP23147",
  //   jobTitle: " Frontend Developer for Contractual Project",
  //   contractType: "Part Time",
  //   workType: "Remote",
  //   acceptedRate: "$30/hr",
  //   location: "Singapore City, Singapore",
  // };

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const contractActivityClick = (contractDetails) => {
    setDrawerDetails((prevState) => ({
      ...prevState,
      showDrawer: true,
      componentType: "consultantActiveWorkContract",
      showHeading: false,
      contractActivity: { ...contractDetails },
    }));
  };
  // console.log("id details", data._id);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);
  return (
    <div className={classes.container}>
      <div className="flex gap-x-[36px] mb-[36px]">
        <div className="flex flex-col gap-y-[12px] min-w-[224px]">
          <img
            className={classes.orgImage}
            alt={`org_${data?._id}_image`}
            src={data?.organizationDetails?.profilePhoto}
          />
          <div>
            <Typography className={classes.companyName}>
              {data?.organizationDetails?.company
                ? data.organizationDetails.company
                : " -- "}
            </Typography>
            {/* <Typography className={classes.activeWorkText}>
              ACTIVE WORK
            </Typography> */}
          </div>
          <div>
            <Typography className={classes.acceptedDateandAppNo}>
              Accepted{" "}
              {data?.createdAt
                ? new Date(data?.createdAt).toLocaleString()
                : " --"}
            </Typography>
            <Typography className={classes.acceptedDateandAppNo}>
              App No. {data?.appNo ? data.appNo : " -- "}
            </Typography>
          </div>
        </div>
        <div className="flex flex-col gap-y-[24px] w-full">
          <div className="flex gap-x-[16px]">
            <div className="flex-1">
              {data?.onboardingDetails?.onboardingStatus === "completed" ? (
                <>
                  <Typography className={classes.detailType}>
                    ONBOARDING STATUS
                  </Typography>
                  {documentStatusTypeText?.completed}
                </>
              ) : (
                <>
                  <Typography className={classes.detailType}>
                    ONBOARDING STATUS
                  </Typography>
                  {documentStatusTypeText?.underAssessment}
                </>
              )}
            </div>
            <div className={classes.workTimeDiv}>
              <Typography className={classes.detailType}>WORK TIME</Typography>
              <Typography className={classes.detailValue}>
                {isString(data?.onboardingDetails?.workingTime) ? data.onboardingDetails.workingTime : " -- "}{" "}
                {/* {data?.onboardingDetails.timezone
                  ? data?.onboardingDetails.timezone
                  : "-/-"} */}
              </Typography>
            </div>
            <div className="pr-[48px]">
              <Typography className={classes.detailType}>START DATE</Typography>
              <Typography className={classes.detailValue}>
                {data?.onboardingDetails?.startDate
                  ? new Date(data?.onboardingDetails?.startDate).toLocaleString()
                  : " -- "}
              </Typography>
            </div>
          </div>
          {/* <div className="bg-[#F7F7F7] p-[16px]">
            <Typography className={classes.detailType}>
              JOB NO. {data?.jobDetails?.jobNo ? data.jobDetails.jobNo : " -- "}
            </Typography>
            <Typography className={classes.detailValue}>
              {data?.jobDetails?.jobTitle ? data.jobDetails.jobTitle : " -- "}
            </Typography>
          </div> */}
          <div className="flex">
            <div className="w-[135px]">
              <Typography className={classes.detailType}>
                CONTRACT TYPE
              </Typography>
              <Typography className={classes.detailValue}>
                {contractTypeLevel}
              </Typography>
            </div>
            <div className="w-[135px]">
              <Typography className={classes.detailType}>WORK TYPE</Typography>
              <Typography className={classes.detailValue}>
                {data?.jobDetails?.workType ? data.jobDetails.workType : " -- "}
              </Typography>
            </div>
            <div className="w-[135px]">
              <Typography className={classes.detailType}>
                ACCEPTED RATE
              </Typography>
              <Typography className={classes.detailValue}>
                {data?.onboardingDetails?.acceptedRate
                  ? data.onboardingDetails.acceptedRate
                  : " -- "}
              </Typography>
            </div>
            <div className="flex-1">
              <Typography className={classes.detailType}>LOCATION</Typography>
              <Typography className={classes.detailValue}>
                {data?.jobDetails?.country ? data.jobDetails.country : " -- "},
                {data?.jobDetails?.state ? data.jobDetails.state : " -- "}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-x-[16px]">
        <Button
          className={clsx([classes.viewContractButton, classes.detailValue])}
          onClick={() => {
            contractActivityClick(data);
          }}
        >
          View Contract Activity <img src="/iconlightup-right.svg" />
        </Button>
        <Button
          className={clsx([classes.viewJobButton, classes.detailValue])}
          onClick={() => {
            history.push(`/consultant/job-detail/${data?.jobDetails?._id}`);
          }}
        >
          View Job Details
        </Button>
      </div>
    </div>
  );
};

List.propTypes = {};

export default List;

// import React, { useContext } from "react";
// import { useHistory } from "react-router-dom";
// import PropTypes from "prop-types";
// import { makeStyles } from "@mui/styles";

// import CustomTooltip from "app/v2/components/CustomTooltip";
// import SocialShare from "app/v2/Pages/common/SocialShare";
// import OnboardingStatus from "app/v2/Pages/common/Status/Onboarding";

// import { UserContext } from "../../../../../index";
// import configs from "app/v2/Pages/Utils/configs";

// import { VisibilitySVG } from "app/v2/Pages/SVGs/Visibility";
// import commonImages from "app/v2/images/common";

// const useStyles = makeStyles((theme) => ({
//   userImage: {
//     width: "56px",
//     height: "56px",
//     objectFit: "scale-down",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #EDEDED",
//   },
// }));

// const ActiveConsultant = (props) => {
//   const history = useHistory();
//   const classes = useStyles();

//   const consultantID = 1234;
//   const homeUrl = configs?.homeUrl;
//   const publicConsultantDetailURL = consultantID
//     ? homeUrl + `consultant-detail/${consultantID}`
//     : null;

//   const cardData = {
//     _id: "123",
//     // image: commonImages?.user_profile,
//     name: "Carol Hemmings",
//     acceptedDate: "April 2, 2023",
//     applicationNo: "FP23147",
//     onBoardingStatus: "assessment",
//     workTime: "9:00 am - 5:00 pm",
//     timezone: "SGST GMT +8.0",
//     startDate: "April 23, 2023",
//     jobNo: "FP23147",
//     jobTitle: "Frontend Developer for Contractual Project",
//     experienceLevel: "Expert",
//     contractType: "Part Time",
//     workType: "Remote",
//     acceptedRate: "$30/hr",
//     location: "Singapore City, Singapore",
//   };

//   const [drawerDetails, setDrawerDetails] = useContext(UserContext);

//   const consultantActivityClick = () => {
//     setDrawerDetails((prevState) => ({
//       ...prevState,
//       showDrawer: true,
//       componentType: "clientConsultantContract",
//       showHeading: true,
//     }));
//   };
//   return (
//     <div className="self-stretch overflow-hidden shrink-0 flex flex-col p-12 items-start justify-start gap-[36px] text-left text-5xl text-black font-button-text-button-14">
//       <div className="self-stretch flex flex-row items-start justify-start gap-[36px]">
//         <div className="w-56 flex flex-col items-start justify-start gap-[12px]">
//           <img
//             className={classes.userImage}
//             alt={`"user_${cardData?._id}_image"`}
//             src={cardData?.image ? cardData.image : "/user_placeholder.svg"}
//           />
//           <div className="flex flex-col items-start justify-start gap-[2px]">
//             <div className="relative leading-[120%] font-semibold">
//               {cardData?.name ?? "-"}
//             </div>
//             <div className="relative text-sm uppercase font-semibold text-neutral-800">
//               Active Consultant
//             </div>
//           </div>
//           <div className="flex flex-col items-start justify-start gap-[2px] text-sm text-neutral-500">
//             <div className="relative leading-[160%]">
//               Accepted {cardData?.acceptedDate ?? "-"}
//             </div>
//             <div className="relative leading-[160%]">
//               App No. {cardData?.applicationNo ?? "-"}
//             </div>
//           </div>
//         </div>
//         <div className="flex-1 flex flex-col items-start justify-start gap-[24px] text-center text-3xs text-neutral-500">
//           <div className="self-stretch flex flex-row items-start justify-start gap-[16px]">
//             <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">onboarding status</b>
//               <OnboardingStatus status={cardData?.onBoardingStatus} />
//             </div>
//             <div className="flex-1 flex flex-col items-start justify-center gap-[6px] border-r-[1px] border-solid border-neutral-100">
//               <b className="relative uppercase">{`work time `}</b>
//               <div className="flex flex-row items-center justify-start gap-[12px] text-sm text-neutral-900">
//                 <div className="relative leading-[130%] font-semibold">
//                   {cardData?.workTime ?? "-"}
//                 </div>
//                 <div className="relative text-xs leading-[120%] uppercase font-semibold text-neutral-300">
//                   ({cardData?.timezone ?? ""})
//                 </div>
//               </div>
//             </div>
//             <div className="w-[135px] flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">start date</b>
//               <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
//                 {cardData?.startDate ?? "-"}
//               </div>
//             </div>
//           </div>
//           <div className="self-stretch bg-f7-background flex flex-row p-4 items-start justify-start gap-[16px]">
//             <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
//               <div className="relative uppercase font-semibold">
//                 Job no. {cardData?.jobNo ?? "-"}
//               </div>
//               <div className="self-stretch relative text-sm leading-[130%] font-semibold text-neutral-900 text-left">
//                 {" "}
//                 {cardData?.jobTitle ?? "-"}
//               </div>
//             </div>
//             <button className="cursor-pointer py-2.5 px-5 bg-[transparent] flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50">
//               <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-900 text-center">
//                 View Job Details
//               </div>
//             </button>
//           </div>
//           <div className="self-stretch flex flex-row items-start justify-start">
//             <div className="w-[135px] flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">level</b>
//               <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-left">
//                 {cardData?.experienceLevel ?? "-"}
//               </div>
//             </div>
//             <div className="w-[135px] flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">contract type</b>
//               <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-left">
//                 {cardData?.contractType ?? "-"}
//               </div>
//             </div>
//             <div className="w-[135px] flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">work type</b>
//               <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-left">
//                 {cardData?.workType ?? "-"}
//               </div>
//             </div>
//             <div className="w-[135px] flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">Accepted Rate</b>
//               <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-left">
//                 {cardData?.acceptedRate ?? "-"}
//               </div>
//             </div>
//             <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
//               <b className="relative uppercase">location</b>
//               <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-left">
//                 {cardData?.location ?? "-"}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="flex flex-row items-start justify-start gap-[12px]">
//         <button
//           onClick={() => {
//             consultantActivityClick();
//           }}
//           className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-4 bg-neutral-900 h-10 flex flex-row box-border items-center justify-center gap-[10px]"
//         >
//           <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-white text-center">
//             Check Consultant Activity
//           </div>
//           <img
//             className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
//             alt=""
//             src="/iconlightup-right.svg"
//           />
//         </button>
//         <button
//           className="cursor-pointer p-2.5 bg-[transparent] box-border h-10 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50"
//           onClick={() => {
//             history.push("/client/consultant/1234");
//           }}
//         >
//           <VisibilitySVG className="relative w-5 h-5 overflow-hidden shrink-0" />
//         </button>
//         <CustomTooltip
//           arrow
//           title={<SocialShare shareLink={publicConsultantDetailURL} />}
//           bg="white"
//         >
//           <button className="cursor-pointer p-2.5 bg-[transparent] box-border h-10 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50">
//             <img
//               src="/share.svg"
//               className="relative w-5 h-5 overflow-hidden shrink-0"
//             />
//           </button>
//         </CustomTooltip>
//       </div>
//     </div>
//   );
// };

// ActiveConsultant.propTypes = {};

// export default ActiveConsultant;

// import React, { useContext } from "react";
// import PropTypes from "prop-types";
// import { makeStyles } from "@mui/styles";
// import clsx from "clsx";

// import { Button, Typography } from "@mui/material";
// import { UserContext } from "app/v2";
// import { useHistory } from "react-router-dom";

// const useStyles = makeStyles((theme) => ({
//   container: {
//     padding: "24px",
//   },
//   headerDiv: {
//     position: "fixed",
//     width: "1440px",
//     borderBottom: "1px solid #EDEDED",
//     backgroundColor: "white",
//     zIndex: 1,
//   },
//   body: {
//     paddingTop: "119px",
//   },
//   jobDetailsDiv: {},
//   orgImage: {
//     width: "56px",
//     height: "56px",
//     objectFit: "scale-down",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     border: "1px solid #EDEDED",
//   },
//   companyName: {
//     fontSize: "24px",
//     fontWeight: 600,
//   },
//   activeWorkText: {
//     fontSize: "14px",
//     fontWeight: 600,
//   },
//   acceptedDateandAppNo: {
//     fontSize: "14px",
//     color: "#717171",
//   },
//   detailType: {
//     fontSize: "10px",
//     fontWeight: 700,
//     color: "#717171",
//     marginBottom: "6px",
//   },
//   detailValue: {
//     fontSize: "14px",
//     fontWeight: 600,
//   },
//   assessmentText: {
//     fontSize: "14px",
//     fontWeight: 500,
//     color: "#FBBC05",
//   },
//   jobCompletedText: {
//     fontSize: "18px",
//     fontWeight: 600,
//     color: "#717171",
//   },
//   workTimeDiv: {
//     borderRight: "1px solid #E0E0E0",
//     paddingRight: "32px",
//   },
//   jobDetailsSet3: {
//     background: "#F7F7F7",
//     display: "flex",
//   },
//   viewContractButton: {
//     backgroundColor: `${theme.palette.common.black} !important`,
//     color: `${theme.palette.common.white} !important`,
//     padding: "10px 15px 10px 20px",
//     display: "flex",
//     columnGap: "10px",
//   },
//   viewJobButton: {
//     border: "1px solid #EDEDED",
//     backgroundColor: `${theme.palette.common.white} !important`,
//     color: `${theme.palette.common.black} !important`,
//     padding: "10px 20px",
//   },
//   actionsDiv: {},
// }));

// const List = ({ data }) => {
//   const classes = useStyles();
//   const history = useHistory();

//   const documentStatusTypeText = {
//     underAssessment: (
//       <Typography className={classes.assessmentText}>
//         Documents under assessment
//       </Typography>
//     ),
//     completed: (
//       <Typography className={classes.jobCompletedText}>
//         {" "}
//         Job Completed
//       </Typography>
//     ),
//   };

//   // const data = {
//   //   image: "/company_image.png",
//   //   companyName: "ABC Company",
//   //   acceptedDate: "April 2, 2023",
//   //   appNo: "FP23147",
//   //   onboardingStatus: "underAssessment",
//   //   workTime: "9:00 am - 5:00 pm",
//   //   timeZone: "(SGST GMT +8.0)",
//   //   startDate: "April 23, 2023",
//   //   jobNo: "FP23147",
//   //   jobTitle: " Frontend Developer for Contractual Project",
//   //   contractType: "Part Time",
//   //   workType: "Remote",
//   //   acceptedRate: "$30/hr",
//   //   location: "Singapore City, Singapore",
//   // };

// const [drawerDetails, setDrawerDetails] = useContext(UserContext);
// const contractActivityClick = () => {
//   setDrawerDetails((prevState) => ({
//     ...prevState,
//     showDrawer: true,
//     componentType: "consultantActiveWorkContract",
//     showHeading: false,
//   }));
// };
// console.log("id details",data._id)
//   return (
//     <div className={classes.container}>
//       <div className="flex gap-x-[36px] mb-[36px]">
//         <div className="flex flex-col gap-y-[12px] min-w-[224px]">
//           <img
//             className={classes.orgImage}
//             alt={`org_${data?._id}_image`}
//             src={data?.image ? data.image : "/org_placeholder.svg"}
//           />
//           <div>
//             <Typography className={classes.companyName}>
//               {data?.companyName ? data?.companyName : " -- "}
//             </Typography>
//             <Typography className={classes.activeWorkText}>
//               ACTIVE WORK
//             </Typography>
//           </div>
//           <div>
//             <Typography className={classes.acceptedDateandAppNo}>
//               Accepted {data?.acceptedDate ? data?.acceptedDate : " --"}
//             </Typography>
//             <Typography className={classes.acceptedDateandAppNo}>
//               App No. {data?.appNo ? data?.appNo : " -- "}
//             </Typography>
//           </div>
//         </div>
//         <div className="flex flex-col gap-y-[24px] w-full">
//           <div className="flex gap-x-[16px]">
//             <div className="flex-1">
//               {data?.onboardingStatus === "completed" ? (
//                 <>{documentStatusTypeText?.[data?.onboardingStatus]}</>
//               ) : (
//                 <>
//                   <Typography className={classes.detailType}>
//                     ONBOARDING STATUS
//                   </Typography>
//                   {documentStatusTypeText?.[data?.onboardingStatus]}
//                 </>
//               )}
//             </div>
//             <div className={classes.workTimeDiv}>
//               <Typography className={classes.detailType}>WORK TIME</Typography>
//               <Typography className={classes.detailValue}>
//                 {data?.workTime ? data?.workTime : " -- "}{" "}
//                 {data?.timeZone ? data?.timeZone : "-/-"}
//               </Typography>
//             </div>
//             <div className="pr-[48px]">
//               <Typography className={classes.detailType}>START DATE</Typography>
//               <Typography className={classes.detailValue}>
//                 {data?.startDate ? data?.startDate : " -- "}
//               </Typography>
//             </div>
//           </div>
//           <div className="bg-[#F7F7F7] p-[16px]">
//             <Typography className={classes.detailType}>
//               JOB NO. {data.jobNo ? data?.jobNo : " -- "}
//             </Typography>
//             <Typography className={classes.detailValue}>
//               {data?.jobTitle ? data?.jobTitle : " -- "}
//             </Typography>
//           </div>
//           <div className="flex">
//             <div className="w-[135px]">
//               <Typography className={classes.detailType}>
//                 CONTRACT TYPE
//               </Typography>
//               <Typography className={classes.detailValue}>
//                 {data?.contractType ? data?.contractType : " -- "}
//               </Typography>
//             </div>
//             <div className="w-[135px]">
//               <Typography className={classes.detailType}>WORK TYPE</Typography>
//               <Typography className={classes.detailValue}>
//                 {data?.workType ? data?.workType : " -- "}
//               </Typography>
//             </div>
//             <div className="w-[135px]">
//               <Typography className={classes.detailType}>
//                 ACCEPTED RATE
//               </Typography>
//               <Typography className={classes.detailValue}>
//                 {data?.acceptedRate ? data?.acceptedRate : " -- "}
//               </Typography>
//             </div>
//             <div className="flex-1">
//               <Typography className={classes.detailType}>LOCATION</Typography>
//               <Typography className={classes.detailValue}>
//                 {data?.location ? data?.location : " -- "}
//               </Typography>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="flex gap-x-[16px]">
//         <Button
//           className={clsx([classes.viewContractButton, classes.detailValue])}
//           onClick={contractActivityClick}
//         >
//           View Contract Activity <img src="/iconlightup-right.svg" />
//         </Button>
//         <Button
//           className={clsx([classes.viewJobButton, classes.detailValue])}
//           onClick={() => {
//             history.push(`/consultant/job-detail/${data?._id}`);
//           }}
//         >
//           View Job Details
//         </Button>
//       </div>
//     </div>
//   );
// };

// List.propTypes = {};

// export default List;
