const LOCAL_STORAGE_CONSULTANT_QUESTIONNAIRE_KEY = 'consultant_questionnaire';

export const saveConsultantQuestionnaire = (consultantData) => {
    localStorage.setItem(LOCAL_STORAGE_CONSULTANT_QUESTIONNAIRE_KEY, JSON.stringify(consultantData));
}

export const getConsultantQuestionnaire = () => {
    const value = localStorage.getItem(LOCAL_STORAGE_CONSULTANT_QUESTIONNAIRE_KEY);
    
    try {
        return JSON.parse(value)
    } catch (e) {
        return null;
    }
}

export const hasValidConsultantData = () => {
    const consultantData = getConsultantQuestionnaire();

    return consultantData && consultantData?.roleSelected !== undefined;
}