import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  banner: {
    height: "18px", // 18px -> 2vh
    padding: "8px 0", // 8px -> 0.8vh
    boxSizing: "content-box",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
  },
  bannerMessage: {
    flex: 42,
    textAlign: "center",
    fontSize: "12px",
    fontWeight: 600,
  },
}));

const Banner = ({ showBanner, onClose }) => {
  const classes = useStyles();

  const bannerMessages = {
    client: <>Lorem ipsum-client</>,
    consultant: (
      <>
        <span className="inline-block pb-[4px] border-b-[1px] border-solid border-[white]">
          Upgrade your account for only 50% off.
        </span>{" "}
        Offer ends 00:23:38:55
      </>
    ),
    agency: <>Lorem ipsum-agency</>,
  };
  const pathName = window?.location?.pathname;
  const view = pathName.split("/")?.[1];

  return (
    <>
      {showBanner && (
        <div className={classes.banner}>
          <div className={classes.bannerMessage}>
            {bannerMessages?.[view] ?? ""}
          </div>

          <div className="flex-1 flex justify-center">
            <CloseIcon
              fontSize="sm"
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
        </div>
      )}
    </>
  );
};

Banner.propTypes = {};

export default Banner;
