const HiringBoardJobDetailsForTeamObj = {
  "team": "Costco App Development Team",
  "status": "active",
  "jobPositions": 22,
  "createdAt": "12",
  "updatedAt": "13",
  "jobId": "",
  "teamSize": 15,
  "jobOpenings": 5,
  "totalApplicants": 124,
  "accepted": 3,
  "rejected": 4,
  "tag": "costco web app",
  "jobs": [
    {
      "jobName": "Development Team Lead",
      "hiringManager": "Mike Fitz",
      "jobNo": "FP23147",
      "status": "closed",
      "openingPositions": 1,
      "acceptedApplicants": 0,
      "rejectedApplicants": 4,
      "hourlyRate": 30,
      "level": "junior",
      "contract": "Part Time",
      "workMode": "Hybrid",
      "totalApplicants": 43,
      "accepted": 0,
      "pending": 0,
      "shortlisted": 0,
      "interview 1": 0,
      "interview 2": 0,
      "testing": 0,
      "final": 0
    },
    {
      "jobName": "Backend Developer",
      "hiringManager": "Mike Fitz",
      "jobNo": "FP23147",
      "status": "active",
      "openingPositions": 1,
      "acceptedApplicants": 0,
      "rejectedApplicants": 4,
      "hourlyRate": 30,
      "level": "junior",
      "contract": "Part Time",
      "workMode": "Hybrid",
      "totalApplicants": 43,
      "accepted": 0,
      "pending": 0,
      "shortlisted": 0,
      "interview 1": 0,
      "interview 2": 0,
      "testing": 0,
      "final": 0
    },
    {
      "jobName": "Frontend Javascript Developer",
      "hiringManager": "Mike Fitz",
      "jobNo": "FP23147",
      "status": "active",
      "openingPositions": 1,
      "acceptedApplicants": 0,
      "rejectedApplicants": 4,
      "hourlyRate": 30,
      "level": "junior",
      "contract": "Part Time",
      "workMode": "Hybrid",
      "totalApplicants": 43,
      "accepted": 0,
      "pending": 0,
      "shortlisted": 0,
      "interview 1": 0,
      "interview 2": 0,
      "testing": 0,
      "final": 0
    },
    {
      "jobName": "Frontend C# Developer",
      "hiringManager": "Mike Fitz",
      "jobNo": "FP23147",
      "status": "active",
      "openingPositions": 1,
      "acceptedApplicants": 0,
      "rejectedApplicants": 4,
      "hourlyRate": 30,
      "level": "junior",
      "contract": "Part Time",
      "workMode": "Hybrid",
      "totalApplicants": 43,
      "accepted": 0,
      "pending": 0,
      "shortlisted": 0,
      "interview 1": 0,
      "interview 2": 0,
      "testing": 0,
      "final": 0
    },
    {
      "jobName": "Product Owner",
      "hiringManager": "Mike Fitz",
      "jobNo": "FP23147",
      "status": "active",
      "openingPositions": 1,
      "acceptedApplicants": 0,
      "rejectedApplicants": 4,
      "hourlyRate": 30,
      "level": "junior",
      "contract": "Part Time",
      "workMode": "Hybrid",
      "totalApplicants": 43,
      "accepted": 0,
      "pending": 0,
      "shortlisted": 0,
      "interview 1": 0,
      "interview 2": 0,
      "testing": 0,
      "final": 0
    }
  ]
};
export { HiringBoardJobDetailsForTeamObj };
