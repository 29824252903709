import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";



export const getAppSettings = async () => {
  return fetchWrapper(`${configs.APIUrl}/admin/appSettings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const addUpdateAppSettings = async (request) => {
    return fetchWrapper(`${configs.APIUrl}/admin/appSettings`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    });
  };