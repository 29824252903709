import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomNumberPicker from "app/v2/components/filters/CustomNumberPicker";
import LeftRightSelect from "app/v2/components/filters/LeftRightSelect";
import LocationSelector from "app/v2/components/filters/LocationSelector";
import SalaryPicker from "../../../common/SalaryPicker";

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    display: "flex",
  },
  leftView: {
    padding: "24px 48px",
    width: "368px",
    position: "fixed",
  },
  cancelDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "52px",
  },
  cancelIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginBottom: "24px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  saveButton: {
    fontSize: "14px",
    fontWeight: 600,
  },
  saveDraftButton: {
    backgroundColor: "#E7E7E7 !important",
    color: `${theme.palette.common.black} !important`,
    marginBottom: "16px",
    width: "100%",
  },
  saveAndPublishButton: {
    width: "100%",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    marginLeft: "368px",
    padding: "12px 60px 60px", // setting padding top as 12px as for hte first navItemComponent margin top of 48px is applied.
  },
  navItemComponent: {
    margin: "48px 0",
  },
  componentTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  componentDesc: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "48px",
    color: "#ABABAB",
  },
  jobTitleDiv: {
    marginBottom: "48px",
  },
  jOSection1: {
    marginBottom: "36px",
    display: "flex",
    columnGap: "33px",
  }, // jO- job overview
  jOSection2: {
    marginBottom: "36px",
  },
  jOSection3: {
    display: "flex",
    columnGap: "33px",
    marginBottom: "36px",
  },
  jOSection3Item: {
    flexGrow: 1,
  },
  joSection4: {},
  editorCharCountMessage: {
    fontSize: "12px",
    color: "red",
  },
  iPSubDivText: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  }, // iP - interviewPanel
  recruiterDiv: {
    marginBottom: "24px",
  },
  dataTagsDiv: {
    marginBottom: "48px",
  },
  dataTagsDescText: {
    fontSize: "14px",
    marginBottom: "16px",
  },
  scrollTopButton: {
    padding: 0,
  },
  arrowTopIcon: {
    marginRight: "10px",
  },
}));

const JobOverview = ({
  id: parentId,
  data,
  onChange,
  experiences,
  contractTypes,
  workModeData,
  // categoryData,
}) => {
  const classes = useStyles();

  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractLevels, setContractLevels] = useState([]);

  const onSalaryChange = (salaryDetailsReceived) => {
    onChange("salary", salaryDetailsReceived);
  };

  const getArrayBasedIndex = (data, entryIDOrArrayIndex) => {
    const indexOrID = Number(entryIDOrArrayIndex);
    const isArrayIndex = indexOrID >= 0 && indexOrID < data?.length;
    if (isArrayIndex) return indexOrID;
    else {
      const arrayIndex = data?.findIndex(
        (entry) => entry?._id === entryIDOrArrayIndex
      );

      return arrayIndex !== -1 ? arrayIndex : null;
    }
  };

  const getEntryID = (data, arrayIndex) => {
    return data?.[arrayIndex]?._id;
  };

  const onExperienceChange = (id, value) => {
    // const experienceID = getEntryID(experiences, value); // add this instead once the change is done from create/edit job api end as well(to receive this as a string).
    onChange(id, value);
  };

  const onContractChange = (id, value) => {
    // const contractTypeID = getEntryID(contractTypes, value); // add this instead once the change is done from create/edit job api end as well(to receive this as a string).
    onChange(id, value);
  };

  useEffect(() => {
    const experienceData = experiences?.map(
      (expEntry) =>
        `${expEntry?.level} (${expEntry?.minExp} - ${expEntry?.maxExp} years)`
    );

    setExperienceTypes(experienceData);
  }, [experiences]);

  useEffect(() => {
    const contractData = contractTypes?.map(
      (contractEntry) => contractEntry?.level
    );
    setContractLevels(contractData);
  }, [contractTypes]);

  return (
    <>
      <div className={classes.jobTitleDiv}>
        <CustomTextfield
          id="jobTitle"
          label="job title"
          placeholder="Input job title"
          value={data?.jobTitle}
          onChange={onChange}
        />
      </div>
      <div className={classes.jOSection1}>
        <div className="flex-1">
          <CustomNumberPicker
            id="openPositions"
            label="total openings"
            defaultValue={data?.openPositions}
            min={1}
            max={10}
            onChange={onChange}
          />
        </div>
        <div className="flex-1">
          <LeftRightSelect
            id="experienceLevelId"
            label="work experience level"
            data={experienceTypes}
            selectedIndex={
              Boolean(data?.experienceLevelId) || data?.experienceLevelId === 0
                ? getArrayBasedIndex(experiences, data?.experienceLevelId)
                : null
            } // Passing null if the value does not exist to show a '-' in the component.
            onChange={onExperienceChange}
          />
        </div>
        <div className="flex-1">
          <LeftRightSelect
            id="contractType"
            label="contract type"
            data={contractLevels}
            selectedIndex={
              Boolean(data?.contractType) || data?.contractType === 0
                ? getArrayBasedIndex(contractTypes, data?.contractType)
                : null
            } // Passing null if the value does not exist to show a '-' in the component.
            onChange={onContractChange}
          />
        </div>
      </div>
      <div className={classes.jOSection2}>
        <LocationSelector
          defaultCountry={data?.country}
          defaultCity={data?.city}
          defaultState={data?.state}
          defaultTimezone={data?.timezone}
          onChange={onChange}
        />
      </div>
      <div className={classes.jOSection3}>
        <div className={classes.jOSection3Item}>
          <CustomSelect
            id="workType"
            data={workModeData}
            label="work mode"
            placeholder={"Select"}
            onChange={onChange}
            defaultSelection={data?.workType}
          />
        </div>
        {/* <div className={classes.jOSection3Item}>
          <CustomSelect
            id="category"
            data={categoryData}
            label="category"
            placeholder={"Select"}
            onChange={onChange}
            defaultSelection={data?.category}
          />
        </div> */}
      </div>
      <hr />
      <div className={classes.joSection4}>
        <SalaryPicker
          heading="Salary"
          data={data?.salary}
          setData={onSalaryChange}
        />
      </div>
    </>
  );
};

// JobOverview.propTypes = {};

export default JobOverview;
