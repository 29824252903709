const prodOrigins = ["https://findpros.ai", "https://www.findpros.ai"];

export const prodConfigs = {
  /** This is production */
  NeAPIUrl: "https://ne.findpros.ai",
  APIUrl: "/api",
  homeUrl: "https://app.findpros.ai/",
  linkedinAuthHome: prodOrigins.includes(window.location.origin) ?  `https://app.findpros.ai/api` : `${window.location.origin}/api`,// for ex: https://dev.findpro.ai/api"
  paymentConfig: {
    // clientKey: "Ae02CQXRyCpBXHDr3MDORbIiofE-f6DD9s5blhbGJv4EoygdgAC4og00-NirrSQBQpYj2yEXjrPm-p9T"
    clientKey: "AYSgvI3MEjK2-Jfo6Hd5ujkN4tFskXmAfmjH9Q2QHDJNbWbqToLZaLPyB4ML6NhKc8ZiORAdFreWT2LN"
  },
};
