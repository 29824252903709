import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "app/v2/Pages/Utils/configs";

export const jobRequestForCandidate = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/candidate-request`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getCandidateList = async (limit, offset) => {
  return fetchWrapper(
    `${configs.APIUrl}/client/consultants` + `?limit=${limit}&offset=${offset}`
  );
};

export const getJobList = async () => {
  return fetchWrapper(`${configs.APIUrl}/client/job-list`);
};

export const candidatesFilter = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/consultant-filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};


export const getAdvanceConsultantSearchService = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/client/advanceSearch`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};

export const getConsultantsForFeatured = async (request) => {
  // return fetchWrapper(`${configs.APIUrl}/client/candidate-filter`, {
  return fetchWrapper(`${configs.APIUrl}/client/consultant-filter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
