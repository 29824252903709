import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Typography, Link, Grid, CircularProgress, Alert } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Line } from "app/v2/Pages/common";
import CloseIcon from "@mui/icons-material/Close";

import {
  Overview,
  WorkHistory,
  EducationAndCertification,
  LanguageProficiency,
  Awards,
  Testimonials,
  Resumes,
  SectionTitleIcon,
} from "./components";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";

import { IconLightUser } from "app/v2/Pages/SVGs/IconLightUser";
import { IconLightFile } from "app/v2/Pages/SVGs/IconLightFile";
import img_icon_light_arrow_top from "../../../images/img_icon_light_arrow_top.svg";

import { getCandidateInfo } from "app/common/candidate-info/candidate-info.service";
import storageUtil from "app/common/utils/storageUtil";
import {
  isValidArray,
  scrollToTop,
  isValidString,
} from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
  fetchSkillsThunk,
  fetchIndustryThunk,
} from "app/v2/store/features/misc/miscThunk";
import {
  getPostedJobsThunk,
  getMyJobsThunk,
} from "app/v2/store/features/employer/jobFeed/jobFeed.thunk";
import { useSnackbar } from "notistack";
import { getConsultantsForFeaturedThunk } from "app/v2/store/features/employer/employerDashboard.thunk";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  backDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "36px",
  },
  backIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },

  jobPostPreviewText: {
    fontWeight: 600,
    fontSize: "12px",
    color: theme.palette.secondary.main,
    marginBottom: "7px",
  },
  jobPreviewDiv: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    columnGap: "12px",
    marginTop: "7px",
    marginBottom: "36px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "26px",
    marginTop: "36px",
    marginLeft: "20px",
  },
  navItemLinkDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    columnGap: "12px",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",
    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  rightArrowIcon: {
    marginLeft: "10px",
  },
}));

const userprofileDummyData = {
  overview: {
    _id: "642cf1e53adee0d5d6c2c1d6", // consultant/_id
    image: "/user_image.png", // not available
    first_name: "Lacy", // consultant/first_name
    last_name: "Clark", // consultant/last_name
    jobTitle: "FrontEnd Developer", // consultant/profile_info/profile_summary/headline
    linkedinUrl: "https://linkedin.com", // not available
    facebookUrl: "https://facebook.com", // not available
    qrCode: "https://dev.findpro.ai/consultant/", // not available
    activeTime: "3 hours ago", // consultant/profile_info/updatedAt or consultant/personal_info/updatedAt ?
    status: "available", // not available
    salaryDetails: {
      type: "Hourly",
      fixedRate: 24,
    }, // consultant/profile_info/profile_summary/rate -> but incorrect structure
    experienceLevel: "Entry Level (1 - 2 years)", // not available
    availability: "Full Time", // consultant/profile_info/profile_summary/availability -> But discuss with edward about how at few places we receive an id for this(eg contractType id).
    country: "Singapore", // consultant/personal_info/country
    city: "Singapore City", // consultant/personal_info/city
    state: "Central Singapore", // consultant/personal_info/state
    timezone: "Singapore Standard Time (GMT + 8.0)", // not available
    // not available
    summary:
      "Nullam neque donec quam auctor porttitor. Tortor aliquet sit aliquet vel morbi commodo adipiscing. Enim turpis nisl rhoncus lectus cursus justo placerat magna. Morbi elementum scelerisque felis eros amet vel pellentesque et. Urna curabitur nisi consequat mollis arcu eu nunc dictum. Dignissim id aliquam justo ut ultrices pharetra. Sit est suspendisse laoreet sed. Tristique ut pulvinar sagittis ultricies. Diam ut vestibulum egestas ipsum elementum mi in posuere neque felis risus in egestas rhoncus.",
    videoResumeAvailable: true, // consultant/profile_info/self_intro ?
    backgroundCheck: true, // consultant/verified ?
  },
  skills: [
    {
      skillID: "646f1c2f96656ed53aa3250d",
      skillLevelID: "649a7fce75ed0920604c595d",
    },
    {
      skillID: "646f1c2f96656ed53aa3238b",
      skillLevelID: "649a7fa875ed0920604c595b",
    },
    {
      skillID: "646f1c2f96656ed53aa324a3",
      skillLevelID: "649a7fbd75ed0920604c595c",
    },
  ], // consultant/profile_info/skillsets or consultant/skills?
  workHistory: [
    {
      projectName: "Navigation App UI and Development 1",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 2",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 3",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyEmployed: true, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 4",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
    {
      projectName: "Navigation App UI and Development 5",
      clientName: "Fiat Australia", // client or company name
      projectBrief:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque.",
      position: "Frontend Web Developer",
      employmentType: "Full Time",
      country: "Singapore",
      city: "Singapore City",
      state: "Central Singapore",
      timezone: "Singapore Standard Time (GMT + 8.0)",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyEmployed: false, // if true,end key will contain empty values for month and year.
      },
      industry: "Automotive",
      skills: [
        {
          skillID: "646f1c2f96656ed53aa3250d",
          skillLevelID: "649a7fce75ed0920604c595d",
        },
        {
          skillID: "646f1c2f96656ed53aa3238b",
          skillLevelID: "649a7fa875ed0920604c595b",
        },
        {
          skillID: "646f1c2f96656ed53aa324a3",
          skillLevelID: "649a7fbd75ed0920604c595c",
        },
      ],
    },
  ], // consultant/profile_info/work_history or consultant/workHistory?
  education: [
    {
      certificateTitle: "Post-Graduate Certificate",
      universityName: "Mt. Albany University",
      courseName: "Information & Human Design",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    },
    {
      certificateTitle: "Bachelor’s Diploma",
      universityName: "Mt. Albany University",
      courseName: "Information & Technology",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "",
          year: "",
        },
        isCurrentlyPursuing: true, // if true,end key will contain empty values for month and year.
      },
    },
    {
      certificateTitle: "High School Diploma",
      universityName: "Mt. Albany University",
      courseName: "Information & Technology",
      duration: {
        start: {
          month: "August",
          year: "2022",
        },
        end: {
          month: "March",
          year: "2023",
        },
        isCurrentlyPursuing: false, // if true,end key will contain empty values for month and year.
      },
    },
  ], // consultant/profile_info/education
  certification: [
    {
      certificateTitle: "CISCO Computer Component Build Certification",
      issuedBy: "Mt. Albany University",
      courseName: "Information & Human Design",
      issuedOn: { month: "May", year: "2022" },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
  ], // not available
  languages: [
    {
      languageCertificateTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
    },
    {
      languageCertificateTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
    },
  ], // consultant/languages
  awards: [
    {
      awardTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
    {
      awardTitle: "English Level9",
      issuedBy: "Princeton English Examination",
      issuedOn: {
        month: "April",
        year: "2022",
      },
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vulputate, nunc quis bibendum blandit, mi lacus consequat arcu, ut scelerisque ex velit ac sapien. Integer facilisis quam et massa dignissim, in blandit tortor aliquam. Sed at libero a nunc tincidunt gravida. Ut at scelerisque ipsum. Duis sit amet quam vel mauris aliquet.",
    },
  ], // consultant/awards
  testimonials: [
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
    {
      testimony:
        "Quisque feugiat ac posuere mauris interdum at libero massa. Purus cursus platea enim turpis quisque. Pretium habitant sollicitudin semper eu laoreet nulla diam. Urna massa ultrices tortor et pulvinar egestas nibh.",
      givenBy: "Ericka Santiago",
      role: "Supervisor",
      projectName: "Project Alpha - Telecommunications Inc.",
    },
  ], // consultant/profile_info/testimonials or consultant/testimonials?
  // consultant/profile_info/resume
  resumeDoc:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/Metkel%20Isak%20Resume_1656535496051.docx", // https://findpro-s3.s3.us-east-2.amazonaws.com/1641827500408.pdf
  // consultant/profile_info/self_intro
  videoResume:
    "https://findpro-s3.s3.us-east-2.amazonaws.com/1641827327575.mp4",
};

const leftNavData = [
  {
    icon: <IconLightUser className="w-[29px] h-[29px]" />,
    title: "Consultant Overview",
    component: ({
      consultantData,
      activeJobs,
      currentJob,
      experiences,
      contractTypes,
    }) => (
      <Overview
        currentJob={currentJob}
        activeJobs={activeJobs}
        data={{
          ...consultantData?.overview,
          matchRate: consultantData?.matchRate,
          videoResumeAvailable: isValidString(consultantData?.videoResume),
        }}
        socialLinks={consultantData?.socialLinks}
        experienceTypes={experiences}
        contractTypes={contractTypes}
      />
    ),
  },
  {
    icon: <IconLightFile className="w-[29px] h-[29px]" />,
    title: "Skills",
    component: ({ consultantData }) => (
      <SkillsPicker
        id="clientConsultantDetailsSP" // SP - Skills Picker
        data={consultantData?.skills}
        readOnly={true}
      />
    ),
  },
  {
    icon: <img src="/dashboard.svg" width="29" height="29" />,
    title: "Work History & Industry",
    component: ({
      consultantData,
      skillIDToNameMapper,
      contractTypes,
      industryData,
    }) => (
      <WorkHistory
        data={consultantData?.workHistory}
        skillIDToNameMapper={skillIDToNameMapper}
        contractTypes={contractTypes}
        industryData={industryData}
      />
    ),
  },
  {
    icon: <img src="/award.svg" width="29" height="29" />,
    title: "Education & Certification",
    component: ({ consultantData }) => (
      <EducationAndCertification
        educationData={consultantData?.education}
        certificationData={consultantData?.certification}
      />
    ),
  },
  // {
  //   icon: <img src="/comment.svg" width="29" height="29" />,
  //   title: "Testimonials",
  //   component: ({ consultantData }) => (
  //     <Testimonials data={consultantData?.testimonials} />
  //   ),
  // },
  // Hidden for MVP
  {
    icon: <img src="/award.svg" width="29" height="29" />,
    title: "Language Proficiency",
    component: ({ consultantData }) => (
      <LanguageProficiency data={consultantData?.languages} />
    ),
  },
  {
    icon: <img src="/award.svg" width="29" height="29" />,
    title: "Awards",
    component: ({ consultantData }) => <Awards data={consultantData?.awards} />,
  },
  {
    icon: <img src="/project.svg" width="29" height="29" />,
    title: "Resume",
    component: ({ consultantData }) => (
      <Resumes
        doc={consultantData?.resumeDoc}
        video={consultantData?.videoResume}
      />
    ),
  },
];

const ConsultantDetail = ({ props }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { experiences, contractTypes, skills, industryData } = useSelector(
    (state) => state.misc
  );
  const [postedJobs, setPostedJobs] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [currentJob, setCurrentJob] = useState();
  const postedJobsBuf = useSelector((state) => state.jobs.jobs.data);

  const [consultantData, setConsultantData] = useState(userprofileDummyData);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [jobID, setJobID] = useState();
  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );
  const [skillIDToNameMapper, setSkillIDToNameMapper] = useState();

  const onNavItemClick = (navItemIndex) => {
    const element = sectionRefs.current[navItemIndex];
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -180;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const isLastNavItemComponent = (index) => {
    return index === leftNavData?.length - 1;
  };

  const isNotOverviewSection = (title) => title !== "Consultant Overview";

  ///////// scroll
  const sectionRefs = useRef([]);
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 180;

    sectionRefs.current.forEach((section) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;

      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  ///////// scroll

  useEffect(() => {
    if (isValidArray(skills)) {
      let skillIDToNameMapper = {};
      skills?.forEach((skillEntry) => {
        skillIDToNameMapper[skillEntry?._id] = skillEntry?.name;
      });
      setSkillIDToNameMapper(skillIDToNameMapper);
    }
  }, [skills]);

  useEffect(() => {
    if (!skills?.length) dispatch(fetchSkillsThunk());
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!industryData?.length) dispatch(fetchIndustryThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isValidArray(postedJobs)) {
      const activeJobsWithJobNoAndTitle = [];
      postedJobs.forEach((jobEntry) => {
        if (jobEntry?.status === "active") {
          activeJobsWithJobNoAndTitle.push({
            ...jobEntry,
            jobNoAndTitle: `Job ${jobEntry?.jobNo}: ${jobEntry?.jobTitle}`,
          });
        }
      });

      if (isValidArray(activeJobsWithJobNoAndTitle)) {
        const currentJob = activeJobsWithJobNoAndTitle.find(
          (job) => job._id === jobID
        );
        setActiveJobs(activeJobsWithJobNoAndTitle);
        setCurrentJob(currentJob);
      }
    }
  }, [postedJobs]);

  useEffect(() => {
    const candidateId = props?.match?.params?.candidate_id;
    const jobID = props?.match?.params?.jobID;

    setJobID(jobID);

    // Fetching jobs data
    if (!postedJobsBuf?.length) {
      dispatch(getMyJobsThunk())
        .unwrap()
        .then((res) => {
          if (isValidArray(res?.data)) {
            setPostedJobs(res.data);
          }
        })
        .catch((err) => {});
    } else {
      setPostedJobs(postedJobsBuf);
    }

    // Fetching candidate info
    // getCandidateInfo(candidateId)
    //   .then((res) => {
    //     if (res) {
    //       const { min_resume_view } = res;
    //       const user = storageUtil.getItem("user");
    //       localStorage.setItem(
    //         "user",
    //         JSON.stringify({
    //           ...user,
    //           min_resume_view,
    //         })
    //       );
    //       console.log("user", JSON.stringify({ ...user, min_resume_view }));
    //       setConsultantData({
    //         ...res?.consultantDetails,
    //         videoResume: res?.videoResume,
    //         resumeDoc: res?.resumeDoc,
    //         overview: { ...res?.consultantDetails.overview, _id: res?._id, isConnected: res?.isconnected , isFavorite: res?.isFavorite },
    //       });
    //       setDataLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setDataLoading(false);
    //     setDataError(true);
    //   });

    dispatch(
      // getConsultantsThunk({
      getConsultantsForFeaturedThunk({
        consultantId: candidateId,
        jobId: jobID,
        // limit: candidatesPerScroll,
        // offset: candidatesToShow,
      })
    )
      .unwrap()
      .then((res) => {
        if (res) {
          const { min_resume_view } = res;
          const user = storageUtil.getItem("user");
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...user,
              min_resume_view,
            })
          );
          console.log("user", JSON.stringify({ ...user, min_resume_view }));
          setConsultantData({
            ...res?.consultantDetails,
            overview: {
              ...res?.consultantDetails.overview,
              _id: res?._id,
              isConnected: res?.isconnected,
              isFavorite: res?.isFavorite,
            },
            matchRate: res?.matchingRate,
          });
          setDataLoading(false);
        }
      })
      .catch((err) => {
        // if (err.message) { }
        // enqueueSnackbar("Problem in fetching consultant details!", {
        //   variant: "error",
        // });

        const key = enqueueSnackbar("Problem in fetching consultant details!", {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
        console.error(err);
        setDataLoading(false);
      });
  }, []);

  return (
    <>
      {dataLoading ? (
        <div className="flex justify-center items-center flex-1">
          <CircularProgress />
        </div>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
          ) : (
            <Grid container>
              <Grid item className={classes.leftView}>
                <div className={classes.backDiv}>
                  <ArrowBackIcon
                    onClick={() => {}}
                    className={classes.backIcon}
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                  <span>Back</span>
                </div>

                {jobID ? (
                  <div>
                    <Typography className={classes.jobPostPreviewText}>
                      JOB POST TO PREVIEW
                    </Typography>
                    <div className="text-[14px] font-semibold">
                      {currentJob?.jobNoAndTitle ?? "-"}
                    </div>
                    <button
                      className={classes.jobPreviewDiv}
                      onClick={() => {
                        history.push("/client/job-detail" + "/" + jobID);
                      }}
                    >
                      <VisibilityIcon fontSize="small" />
                      View job
                    </button>
                  </div>
                ) : (
                  <></>
                )}

                <hr />

                <div className={classes.navItemsDiv}>
                  {leftNavData?.map((navItemDetails, index) => (
                    <Link
                      key={"l" + index}
                      className={clsx([
                        classes.navItemLinkDiv,
                        selectedNavItemIndex === `nav-item-component-${index}`
                          ? classes.selectedNavItem
                          : classes.unselectedNavItem,
                      ])}
                      onClick={() => {
                        // onNavItemClick(`nav-item-component-${index}`);
                        onNavItemClick(index);
                      }}
                    >
                      {navItemDetails?.icon}

                      <Typography className={classes.navItemTitle}>
                        {navItemDetails?.title}
                      </Typography>
                    </Link>
                  ))}
                </div>
              </Grid>
              <Grid item id="sectionContent" className={classes.rightView}>
                {leftNavData?.map((navItemDetails, index) => (
                  <>
                    <div
                      ref={(el) => (sectionRefs.current[index] = el)}
                      className={`${
                        isNotOverviewSection(navItemDetails?.title) &&
                        "px-[24px]"
                      }`}
                      id={`nav-item-component-${index}`}
                      key={`nav-item-component-${index}`}
                    >
                      {isNotOverviewSection(navItemDetails?.title) ? (
                        <SectionTitleIcon
                          title={navItemDetails?.title}
                          icon={navItemDetails?.icon}
                        />
                      ) : (
                        <></>
                      )}

                      {navItemDetails?.component({
                        currentJob,
                        activeJobs,
                        consultantData,
                        experiences,
                        contractTypes,
                        skillIDToNameMapper,
                        industryData,
                      })}
                    </div>
                    {!isLastNavItemComponent(index) && (
                      <Line className="bg-gray-300 h-px w-full my-[36px]" />
                    )}
                  </>
                ))}
                <div
                  className="flex items-center gap-x-[10px] pl-[12px] pt-[24px] cursor-pointer"
                  onClick={() => {
                    scrollToTop();
                  }}
                >
                  <img src={img_icon_light_arrow_top} />
                  <div className="font-semibold leading-[normal] text-base text-center text-gray-900">
                    Back to top
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

ConsultantDetail.propTypes = {};

export default ConsultantDetail;
