import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsService } from 'app/v2/services/notificationsService';


export const fetchNotificationsThunk = createAsyncThunk(
  'notifications/getNotifications',
  async ({ userID }, thunkAPI) => {

    try {
      const response = await notificationsService.getNotifications({userID});
      return response; 
    } catch (error) {

      throw error;
    }
  }
);