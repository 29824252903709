import React from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = () => {
  const data = {
    labels: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
    datasets: [
      {
        label: 'Total Interviews',
        backgroundColor: 'rgba(255, 255, 255, 1.6)', // White bars
        data: [12, 19, 3, 5, 2], // Data for white bars
        barPercentage: 0.1, // Reduce the width of white bars
      },
      {
        label: 'Total Jobs Offered',
        backgroundColor: 'rgba(192, 192, 192, 0.6)', // Gray bars
        data: [8, 15, 5, 8, 4], // Data for gray bars
        barPercentage: 0.1, // Reduce the width of gray bars
      },
    ],
  };

  const options = {
    scales: {
      x: {
        stacked: true, // Stack the bars
      },
      y: {
        stacked: true, // Stack the bars
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ background: 'black', padding: '20px' }}>
      <h2 style={{ color: 'white',fontSize:"18px",fontWeight:600 }}>Job Success Score</h2>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
