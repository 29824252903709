import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import * as yup from "yup";
import TextFieldComponent from "../input-controls/text-field.component";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

const ApplyJobDialog = ({ open, setOpen, onApplyJob, job, company = null }) => {
  const initialValues = {
    bid_value: job?.rate,
    cover_letter: "",
  };

  const validationSchema = yup.object({
    bid_value: yup
      .number()
      .typeError("Required")
      .min(1, "Please make sure enter more then 0")
      .max(1000, "Kindly enter rate less than $1000/hr")
      .required("Required"),
    cover_letter: yup.string().required("Required"),
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    handleClose();
    const jobData = {
      _id: job?._id,
      bid_value: values.bid_value,
      cover_letter: values.cover_letter,
    };
    setSubmitting(false);
    resetForm();
    onApplyJob(jobData);
  };

  return (
    <BootstrapDialog fullWidth onClose={handleClose} open={open}>
      <Formik
        initialValues={initialValues}
        // enableReinitialize
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, initialValues, handleSubmit, errors }) => (
          <div className="p-4 pl-5 pr-5">
            <BootstrapDialogTitle onClose={handleClose}>
              Apply to
            </BootstrapDialogTitle>
            <DialogContent>
              <p>{`'${job.company || company}' ${job.title} job`}</p>
              <Grid container>
                <Form id="apply-job" onSubmit={handleSubmit} className="w-100">
                  <FormControl fullWidth sx={{ pb: 2 }}>
                    <TextFieldComponent
                      name="bid_value"
                      label="Bid value *"
                      type="number"
                      defaultValue={initialValues.bid_value}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextFieldComponent
                      name="cover_letter"
                      multiline
                      label="Cover letter *"
                      type="text"
                      defaultValue={initialValues.bid_value}
                    />
                  </FormControl>
                </Form>
              </Grid>
            </DialogContent>
            <DialogActions className="applyjob-action">
              <Button
                variant="contained"
                type="submit"
                form="apply-job"
                className="w-100 rewmp-btn"
              >
                Submit
              </Button>
            </DialogActions>
          </div>
        )}
      </Formik>
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default ApplyJobDialog;
