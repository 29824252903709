import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "configs";

export const getResumeSettings = async () => {
    return fetchWrapper(`${configs.APIUrl}/candidates/resume-settings`);
};
  
export const updateResumeSettings = async (request) => {
  return fetchWrapper(
    `${configs.APIUrl}/candidates/resume-settings/`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: request,
    }
  );
};
