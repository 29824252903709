import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import CustomButton from "app/v2/components/CustomButton";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";

import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import { messages } from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "16px 28px",
    width: "197px",
    boxSizing: "content-box",
    fontSize: "16px",
    fontWeight: 600,
  },
  cancelButton: {
    border: "1px solid #EDEDED",
  },
}));

const WithdrawActionDialog = ({
  withdrawDialogOpen,
  setWithdrawDialogOpen,
  withdrawActionLoading,
  withdrawActionCompleted,
  withdrawActionError,
}) => {
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {withdrawActionLoading && "Action in progress"}
        {withdrawActionError && "Error"}
        {withdrawActionCompleted && "Proposal Withdrawn"}
      </div>
    );
  };

  const DialogBody = () => {
    if (withdrawActionLoading) return <DialogLoadingBody />;
    else if (withdrawActionCompleted)
      return (
        <DialogSuccessBody
          content={{ title: "Your proposal has been withdrawn." }}
        />
      );
    else if (withdrawActionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={withdrawDialogOpen}
      setOpen={setWithdrawDialogOpen}
      noCloseAction={withdrawActionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

/**
 * @component SubmitProposal- Content/Body-(contains the actions as well) of the withdraw proposal dialog.
 * * @param {object} proposalData - data of the proposal(s) that we want to withdraw.
 * @param {object} jobData - data of the job for which we want to withdraw its specific proposal.
 * @param {function} closeParentDialog - Function to close the parent dialog(dialog in which this component is shown).
 */

const WithdrawProposal = ({ proposalData, jobData, closeParentDialog }) => {
  const classes = useStyles();

  const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
  const [withdrawActionLoading, setWithdrawActionLoading] = useState(false);
  const [withdrawActionCompleted, setWithdrawActionCompleted] = useState(false);
  const [withdrawActionError, setWithdrawActionError] = useState(false);

  const onWithdrawClick = () => {
    setWithdrawDialogOpen(true);
    setWithdrawActionLoading(true);

    // Add api integration later
    setTimeout(() => {
      /** Confirm case */
      setWithdrawActionLoading(false);
      setWithdrawActionCompleted(true);
      /** Error case */
      // setWithdrawActionLoading(false);
      // setWithdrawActionError(true);
      // setWithdrawActionCompleted(false);
    }, 2000);
  };

  useEffect(() => {
    if (!withdrawDialogOpen && (withdrawActionCompleted || withdrawActionError))
      closeParentDialog(); // close the parent submit proposal dialog after the SubmitActionDialog is closed only after the action is done-completed/error case.
  }, [withdrawDialogOpen]);
  return (
    <>
      <div className="p-[48px] max-w-[771px] w-full box-content">
        <div className="text-[24px] font-semibold mb-[24px] text-center">
          Are you sure you want to withdraw your proposal?
        </div>
        <div className="text-[16px] text-[#717171] mb-[56px] text-center">
          Withdrawing the proposal will put the selection process on hold. The
          client will be informed of this action.
        </div>
        <div className="flex justify-center gap-x-[24px]">
          <CustomButton
            customClasses={clsx([classes.button, classes.cancelButton])}
            onClick={() => {
              isFunction(closeParentDialog) && closeParentDialog();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton
            type="button1"
            customClasses={classes.button}
            onClick={onWithdrawClick}
          >
            Withdraw Proposal
          </CustomButton>
        </div>
      </div>
      <WithdrawActionDialog
        withdrawDialogOpen={withdrawDialogOpen}
        setWithdrawDialogOpen={setWithdrawDialogOpen}
        withdrawActionLoading={withdrawActionLoading}
        withdrawActionCompleted={withdrawActionCompleted}
        withdrawActionError={withdrawActionError}
      />
    </>
  );
};

WithdrawProposal.propTypes = {};

export default WithdrawProposal;
