import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { Avatar, Typography, Divider, Link } from "@mui/material";
import images from "../../images/common";
import { UserContext } from "../../index";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";

import { candidateInfoThunk } from "app/v2/store/features/consultant/consultantProfile.thunk";
import { logout } from "app/v2/Pages/common/login/login.service";
import storageUtil from "../Utils/storageUtil";
import CloseIcon from "@mui/icons-material/Close";

import { NotificationContext } from "app/v2";
import { isFunction } from "../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  profileRoot: {
    margin: "24px",
    width: "417px",
  },
  personalDetails: {
    marginBottom: "42px",
  },
  name: {
    fontSize: "24px",
    fontWeight: 600,
  },
  jobTitle: {
    fontWeight: 700,
    color: "#8C8C8C",
  },
  divider: {
    marginBottom: "16px",
  },
  otherLinksDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "6px",
  },
  settingsDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "6px",
  },
  link: {},
  otherSettingLink: {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.common.black,
    margin: "16px 0",
    cursor: "pointer",
  },
  settingTitle: {
    fontSize: "12px",
    fontWeight: 600,
    margin: "16px 0",
  },
  settingLink: {
    fontSize: "16px",
    fontWeight: 600,
    margin: "12px 0",
    color: theme.palette.common.black,
    cursor: "pointer",
  },
  pLAccordionRoot: {
    boxShadow: "none",
  }, // pL - parent link
  plAccordionSummary: {
    padding: 0,
    borderBottom: "1px solid #EDEDED",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
  pLAccordionDetails: {
    display: "flex",
    flexDirection: "column",
  },
  logoutLink: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#A8C1FF !important",
    padding: "16px 0",
    cursor: "pointer",
  },
  userImage: {
    width: "56px",
    height: "56px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
    marginBotom: "12px",
    "& .MuiAvatar-img": {
      objectFit: "scale-down",
    },
  },
}));
const ProfileMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const personalDataBuf = useSelector(
    (state) => state?.consultant?.value?.consultant
  );
  const [personalData, setPersonalData] = useState(personalDataBuf);
  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const [notificationDetails, setNotificationDetails] =
    useContext(NotificationContext);

    useEffect(() => {
      if (!personalDataBuf?.length) {
        dispatch(candidateInfoThunk())
          .unwrap()
          .then((res) => {
            if (res) {
              const personalDataReceived = res?.consultant;
  
              // console.log({ personalDataReceived });
              setPersonalData(personalDataReceived);
            }
            // setIsLoading(false);
          })
          .catch((err) => {
            // if (err.message) { }
            // enqueueSnackbar("Problem in fetching profile data!", {
            //   variant: "error",
            // });
  
            const key = enqueueSnackbar("Problem in fetching profile data!", {
              variant: "error",
              autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon
                  onClick={() => closeSnackbar(key)}
                  style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
                />
              ),
            });
            console.error(err);
            // setIsLoading(false);
          });
      }
     // console.log("personalDataBuf ", personalDataBuf);
    }, []);

  const otherLinksData = [
    {
      label: "Active Work",
      url: "/consultant/active-work",
    },
    {
      label: "Connections",
      url: "/consultant/connections",
    },
  ];
  const settingsData = [
    {
      label: "Subscription",
    },
    {
      label: "DocuCenter",
    },
    {
      label: "Profile Settings",
    },
    {
      label: "Account Settings",
    },
    {
      label: "Help & Support",
    },
  ];

  const closeDrawer = () => {
    setDrawerDetails({
      showDrawer: false,
      componentType: "",
      showHeading: true,
    });
  };

  const onOtherLinkClick = (url) => {
    closeDrawer();
    history.push(url);
  };
  const onSettingsLinkClick = (settingIndex) => {
    closeDrawer();
    history.push({
      pathname: "/consultant/settings",
      state: { settingIndex },
    });
  };



  const onLogout = () => {
    logout()
      .then((res) => {
        const unsubscribeNotification = notificationDetails?.unsubscribe;
        console.log({ unsubscribeNotification });
        if (res.message) {
          // enqueueSnackbar(res.message, { variant: "success" });

          const key = enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        storageUtil.removeItem("user");
        closeDrawer();

        localStorage.removeItem("selectedFilters");
        if (isFunction(unsubscribeNotification)) unsubscribeNotification();

        history.push("/consultant/login");
      })
      .catch((err) => {
        if (err.message) {
          // enqueueSnackbar(err.message, { variant: "error" });

          const key = enqueueSnackbar(err.message, {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
        }
        console.error(err);
      });
  };

  return (
    <div className={classes.profileRoot}>
      <Avatar
        alt="Profile icon"
        src={
          personalData?.consultantDetails?.overview?.displayPicture
            ? personalData?.consultantDetails?.overview?.displayPicture
            : "/user_placeholder.svg"
        }
        className={classes.userImage}
        variant="square"
      />
      <div className={classes.personalDetails}>
        <Typography className={classes.name}>
          {/* {personalData?.first_name + " " + personalData?.last_name} */}
          {/* {personalData?.consultantDetails?.overview?.firstName + " " + personalData?.consultantDetails?.overview?.lastName} */}
          {(personalData?.consultantDetails?.overview?.first_name ?? "-") +
            " " +
            (personalData?.consultantDetails?.overview?.last_name ?? "-")}
        </Typography>
        <Typography variant="caption" className={classes.jobTitle}>
          {personalData?.consultantDetails?.overview?.jobTitle ??
            personalData?.profile_info?.profile_summary?.title ??
            "-"}
        </Typography>
      </div>
      <div className={classes.otherLinksDiv}>
        {otherLinksData.map((otherLink) => (
          <Link
            key={`link-${otherLink?.label}`}
            onClick={() => {
              onOtherLinkClick(otherLink.url);
            }}
            className={classes.otherSettingLink}
          >
            {otherLink.label}
          </Link>
        ))}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.settingsDiv}>
        <Typography className={classes.settingTitle}>SETTINGS</Typography>
        {settingsData?.map((settingInfo, settingIndex) => (
          <Link
            onClick={() => {
              onSettingsLinkClick(settingIndex);
            }}
            className={classes.settingLink}
          >
            {settingInfo?.label}
          </Link>
        ))}
        <Link onClick={onLogout} className={classes.logoutLink}>
          Logout
        </Link>
      </div>
    </div>
  );
};

ProfileMenu.propTypes = {};

export default ProfileMenu;
