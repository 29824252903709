import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Typography, Link, Grid } from "@mui/material";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    height: "fit-content",
    maxWidth: "1340px",
    width: "100%",
    overflowX: "auto",
    paddingBottom: "20px",
  },
  gridViewContainer: {
    columnGap: "20px",
  },
  listViewContainer: {
    flexDirection: "column",
    rowGap: "20px",
  },
}));

const CardCollection = ({ cardsData, viewType }) => {
  const classes = useStyles();
  const isGridView = viewType === "grid";
  return (
    <div
      className={clsx([
        classes.cardContainer,
        isGridView ? classes.gridViewContainer : classes.listViewContainer,
      ])}
    >
      {cardsData?.map((Card) => (
        <Card />
      ))}
    </div>
  );
};

CardCollection.propTypes = {};

export default CardCollection;
