import React, { useState, useEffect, useMemo } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { postSignUp, handleLinkedInAuth } from "./signup.service";
import CountryList from "app/markup/country.json";
import TimezoneList from "app/markup/timezone.json";
import { useSnackbar } from "notistack";
import { Form as BForm, Button as BButton } from "react-bootstrap";
import configs from "../../Utils/configs";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import CountriesWithCode, {
  allowedCountries,
} from "app/markup/countriesWithCode";
import { Grid, Typography } from "@mui/material";
import images from "app/v2/images/common";
import "./commonstyles.scss";
import { isValidObject } from "../../Utils/utilFunctions";
import CloseIcon from '@mui/icons-material/Close';

import {
  PASSWORD_PATTERN,
  CHAR_NUMBER_PATTERN,
  WEBSITE_PATTERN,
} from "app/v2/Pages/Utils/constants";
import { getEmployeeDetail } from "app/v2/services/client/profileSettings.service";
import { getEmployeeDetailThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
} from "app/v2/store/features/misc/miscThunk";

const useStyles = makeStyles((theme) => ({
  leftView: {
    width: "419px",
    paddingTop: "69px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "inset -11px 4px 36px rgba(218, 229, 255, 0.3)",
  },
  logoDiv: {},
  logo: {
    width: "181px",
    height: "56px",
    marginBottom: "48px",
  },
  matchedConsultantorJobDiv: {
    width: "280px",
    height: "280px",
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  matchedConsultantorJobCount: {
    fontWeight: 600,
    fontSize: "48px",
  },
  availableText: {
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#8C8C8C",
    textAlign: "center",
  },
  rightView: {
    padding: "56px 72px",
    width: "877px",
    margin: "auto",
    boxSizing: "content-box",
  },
  cReqDiv: {
    marginTop: "26px",
    width: "395px",
    padding: "24px",
    border: "1px solid #A8C1FF",
    marginBottom: "48px",
  }, // c- consultant
  cReqText: {
    fontWeight: 600,
    fontSize: "18px",
    marginBottom: "26px",
  },
  cRole: {
    fontWeight: 600,
    fontSize: "16px",
    marginBottom: "24px",
    textAlign: "center",
    wordWrap: "break-word",
  },
  cReqInfoContainer: {
    display: "flex",
    flexDirection: "column",
    columnGap: "16px",
    justifyContent: "space-between",
  },
  cReqInfoDiv: {
    width: "164px",
  },
  Rowfitter: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
  },
  cReqInfoTitle: {
    fontWeight: 600,
    fontSize: "12px",
    textTransform: "uppercase",
    color: "#717171",
  },
  cReqInfoValue: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#0B0E1E",
  },
  registerText: {
    fontWeight: 600,
    fontSize: "32px",
    marginBottom: "36px",
  },
  vettedConsultantsorJobsText: {
    color: theme.palette.primary.main,
  },
  element: {
    boxSizing: "border-box",
    border: 0,
    borderBottom: " 0.7px solid #717171",
  },
  registerButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    boxSizing: "border-box",
    padding: "14px 0",
    "&:disabled": {
      backgroundColor: "grey !important",
    },
  },
  orTextAndSSODiv: {
    width: "704px",
    margin: "auto",
    padding: "36px 0",
  },
  orTextDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: "22px",
  },
  line: {
    width: "415px",
    borderTop: "1px solid #EDEDED",
  },
  orText: {
    padding: "0 10px",
  },
  singleSignOnDiv: {
    display: "flex",
    justifyContent: "center",
    columnGap: "24px",
  },
  ssoButton: {
    width: "340px",
    padding: "12px 18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "10px",
    border: "1px solid #EDEDED",
  },
  signupText: {
    fontSize: "16px",
  },
  agreeText: {
    fontSize: "14px",
    marginLeft: "8px",
    color: `${theme.palette.common.black} !important`,
  },
  otherLink: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    textDecoration: "underline",
    textDecorationColor: theme.palette.primary.main,
  },
  loginText: {
    marginTop: "12px",
    fontSize: "14px",
    textAlign: "center",
  },
  loginLink: {
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  minMaxText: {
    fontWeight: 600,
    fontSize: "16px",
  },
}));

const googleSSOApiInitialPath = {
  consultant: "candidates",
  // client: "employers",
  client: "client",
  agency: "vendors",
}; // v1 google auth api paths.

const Signup = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar ,closeSnackbar} = useSnackbar();
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const { inviteeID } = props.match.params;

  const pathName = location?.pathname?.split("/");
  const accountType = pathName[1];
  const isConsultantView = accountType === "consultant";
  const isEmployerView = accountType === "client";
  const isAgencyView = accountType === "agency";
  // const availableConsultantCount = "-";
  // const availableJobCount = "-";
  // const consultantReqData = {
  //   roleSelected: "Frontend Javascript Developer",
  //   openPositions: 1,
  //   level: "Entry Level",
  //   location: "Singapore, SG",
  //   contractType: "Part Time",
  //   workType: "Remote",
  //   hourlyRate: "$30",
  // };
  // let questionnaireData = localStorage.getItem("questionnaireData");
  let questionnaireData = localStorage.getItem("consultant_questionnaire");
  questionnaireData = JSON.parse(questionnaireData);
  const availableConsultantCount = questionnaireData?.consultantMatched;
  const availableJobCount = "-";
  console.log("quesio", questionnaireData);
  let initialFormValues = {
    firstName: "",
    lastName: "",
    // personal_info: {
    city: "",
    country: "",
    state: "",
    phoneNumber: "",
    // },
    email: "",
    password: "",
    confirmPassword: "",
    timezone: "",
    company: "",
  };

  if (isEmployerView || isAgencyView) {
    initialFormValues.company = "";
    if (isEmployerView)
      initialFormValues.profile_info = {
        website: "",
      };
  }

  const [formValidated, setFormValidated] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [inviteeOrgDetails, setInviteeOrgDetails] = useState({});

  useEffect(() => {
    if (inviteeID) {
      dispatch(getEmployeeDetailThunk(inviteeID))
        .unwrap()
        .then((res) => {
          if (res) {
            // setInviteeOrgDetails( res.result.orgDetails );
            setInviteeOrgDetails({
              ...res.result.orgDetails,
              company: res.result.orgDetails.company,
              website: res.result.orgDetails.socialLinks.website,
              email: res.result.email,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              companyTitle: res.result.companyTitle,
              country: res.result.country,
              city: res.result.city,
              state: res.result.state,
              timezone: res.result.timezone,
              phoneNumber: res.result.phoneNumber,
            });

            setFormValues({
              ...formValues,
              company: res.result.orgDetails.company,
              website: res.result.orgDetails.socialLinks.website,
              email: res.result.email,
              firstName: res.result.firstName,
              lastName: res.result.lastName,
              companyTitle: res.result.companyTitle,
              country: res.result.country,
              city: res.result.city,
              state: res.result.state,
              timezone: res.result.timezone,
              phoneNumber: res.result.phoneNumber,
            });
          }
        })
        .catch((err) => {
          if (err.message) {
            // enqueueSnackbar(err.message, { variant: "error" });
            const key = enqueueSnackbar(err.message, {
              variant: "error",autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
              ),
            });
          } else {
            // enqueueSnackbar("Problem in fetching profile data!", {
            //   variant: "error",
            // });

            const key = enqueueSnackbar("Problem in fetching profile data!", {
              variant: "error",autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
              ),
            });
          }
          // console.error(err);
        });
    }
  }, [inviteeID]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    // if (!formValidated) setFormValidated(true);
    if (form.checkValidity() === false) return;
    // if (formValues.personal_info.phone_number.length < 5) return;
    if (formValues?.phoneNumber?.length < 5) return;
    // postSignUp(formValues)
    let questionnaireData = localStorage.getItem("consultant_questionnaire");
    questionnaireData = JSON.parse(questionnaireData);
    const onBoardingId = questionnaireData?.onBoardingId;
    // console.log(" onBoardingId ", onBoardingId);

    if (inviteeID) {
      let payload = {
        onBoardingId: onBoardingId,
        invitationID: inviteeID,
      };
      payload.firstName = formValues.firstName;
      payload.lastName = formValues.lastName;
      payload.password = formValues.password;
      // payload.confirmPassword = formValues.confirmPassword;
      payload.confirmpassword = formValues.confirmPassword;
      payload.phoneNumber = formValues.phoneNumber;
      payload.timezone = formValues.timezone;
      payload.city = formValues.city;
      payload.state = formValues.state;
      payload.country = formValues.country;

      postSignUp(payload)
        .then((res) => {
          // form.reset();
          // props.history.push(`/${accountType}/dashboard`);
          props.history.push(`/${accountType}/login`);
        })
        .catch((err) => {
          // enqueueSnackbar(err.message, {
          //   variant: "error",
          // });

          const key = enqueueSnackbar(err.message, {
            variant: "error",autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
            ),
          });
          console.error(err);
        });
    } else {
      if (isConsultantView) {
        console.log("consultant signup");

        let payload = {
          consultantDetails: {
            overview: {
              first_name: "",
              last_name: "",
              city: "",
              country: "",
              state: "",
              timezone: "",
            },
          },
          phone_number: "",
          email: "",
          password: "",
          confirmPassword: "",
          website: "",
          onBoardingId: "",
        };
        payload.consultantDetails.overview.first_name = formValues.firstName;
        payload.consultantDetails.overview.last_name = formValues.lastName;
        payload.onBoardingId = onBoardingId;
        payload.website = formValues.website;
        payload.consultantDetails.overview.city = formValues.city;
        payload.consultantDetails.overview.state = formValues.state;
        payload.consultantDetails.overview.country = formValues.country;
        payload.consultantDetails.overview.timezone = formValues.timezone;
        payload.password = formValues.password;
        payload.confirmPassword = formValues.confirmPassword;
        payload.phone_number = formValues.phoneNumber;
        payload.email = formValues.email;

        postSignUp(payload)
          .then((res) => {
            // form.reset();
            // props.history.push(`/${accountType}/dashboard`);
            props.history.push(`/${accountType}/account-activation`);
          })
          .catch((err) => {
            // enqueueSnackbar(err.message, {
            //   variant: "error",
            // });

            const key = enqueueSnackbar(err.message, {
              variant: "error",autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
              ),
            });
            console.error(err);
          });
      } else if (isEmployerView) {
        let payload = {
          organizationDetail: { socialLinks: { website: "" }, company: "" },
        };
        payload.onBoardingId = onBoardingId;
        payload.organizationDetail.socialLinks.website = formValues.website;
        payload.organizationDetail.company = formValues.company;
        // payload.organizationDetail.companyLocation.city = formValues.city;
        // payload.organizationDetail.companyLocation.state = formValues.state;
        // payload.organizationDetail.companyLocation.country = formValues.country;
        // payload.organizationDetail.companyLocation.timezone = formValues.timezone;
        payload.firstName = formValues.firstName;
        payload.lastName = formValues.lastName;
        payload.password = formValues.password;
        payload.confirmPassword = formValues.confirmPassword;
        payload.phoneNumber = formValues.phoneNumber;
        payload.timezone = formValues.timezone;
        payload.city = formValues.city;
        payload.state = formValues.state;
        payload.country = formValues.country;
        payload.email = formValues.email;

        postSignUp(payload)
          .then((res) => {
            // form.reset();
            // props.history.push(`/${accountType}/dashboard`);
            props.history.push(`/${accountType}/account-activation`);
          })
          .catch((err) => {
            // enqueueSnackbar(err.message, {
            //   variant: "error",
            // });

            const key = enqueueSnackbar(err.message, {
              variant: "error",autoHideDuration: 3000,
              action: (key) => (
                <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
              ),
            });
            console.error(err);
          });
      }
    }
  };

  const validateRegex = (pattern, text) => !pattern.test(text);

  const isPasswordPatternMatch = (value) =>
    setIsValidPassword(PASSWORD_PATTERN.test(value));

  const checkIsFormValid = () => {
    const form = document.getElementById("sign-up-form");
    if (!formValidated) {
      return false;
    }
    // if (formValues.personal_info.phone_number.length < 5) {
    // if (formValues.phone_number.length < 5) {
    if (formValues?.phoneNumber?.length < 5) {
      return true;
    }
    return !form?.checkValidity();
  };

  const getSalaryDetails = () => {
    const salaryDetails = questionnaireData?.salaryDetails;
    const salaryRange = salaryDetails?.range;
    return (
      <>
        {salaryRange ? (
          <>
            <span className={classes.minMaxText}>Min</span> -
            {`${salaryRange?.min}`} ,{" "}
            <span className={classes.minMaxText}>Max</span> -{" "}
            {`${salaryRange?.max}`}
          </>
        ) : (
          salaryDetails?.fixedRate
        )}
      </>
    );
  };

  const [statesData, setStatesData] = useState([]);

  // const onElementChange = (id, value) => {
  //   if (id === "country") {
  //     setIsCountrySelected(true);
  //     onCountryChange(value);
  //     onChange("state", "", index); // updating state to empty value in the data object.
  //     onChange("city", "", index); // updating city to empty value in the data object.
  //   } else if (id === "state") setIsStateSelected(true);

  //   onChange(id, value, index);
  // };

  const onCountryChange = (country) => {
    const countryData = CountryList.find((entry) => entry?.country === country);
    const statesOfCountry = countryData?.states ?? [];
    console.log({ country, statesOfCountry });
    setStatesData(statesOfCountry);
  };

  useEffect(() => {
    // onCountryChange(formValues.personal_info.country);
    onCountryChange(formValues.country);
  }, [formValues.country, inviteeOrgDetails.country]);

  // useEffect(() => {
  //   setIsCountrySelected(Boolean(defaultCountry));
  //   setIsStateSelected(Boolean(defaultState));
  //   if (defaultCountry) {
  //     onCountryChange(defaultCountry); // to populate states value.
  //   }
  // }, [defaultCountry, defaultState]);

  const { experiences, contractTypes } = useSelector((state) => state.misc);

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  const experiencesData = useMemo(
    () =>
      experiences?.map(
        (expEntry) =>
          `${expEntry?.level} (${expEntry?.minExp} - ${expEntry?.maxExp} years)`
      ),
    [experiences]
  );
  const contractTypesData = useMemo(
    () => contractTypes?.map((contractEntry) => contractEntry?.level),
    [contractTypes]
  );

  return (
    <Grid container>
      <Grid item className={classes.leftView}>
        <Typography className={classes.cReqText}>
          Your {isEmployerView ? "Consultant" : "Job Search"} Requirements
        </Typography>

        {isEmployerView ? (
          <div className={classes.matchedConsultantorJobDiv}>
            <Typography className={classes.matchedConsultantorJobCount}>
              {availableConsultantCount}
            </Typography>
            <Typography className={classes.availableText}>
              available
              <br /> consultants matched
            </Typography>
          </div>
        ) : (
          <div className={classes.matchedConsultantorJobDiv}>
            <Typography className={classes.matchedConsultantorJobCount}>
              {availableConsultantCount}
            </Typography>
            <Typography className={classes.availableText}>
              jobs matched
            </Typography>
          </div>
        )}

        {isValidObject(questionnaireData) && experiencesData.length > 0 && (
          <div className={classes.cReqDiv}>
            <Typography className={classes.cRole}>
              {questionnaireData?.roleSelected}
            </Typography>
            <div className={classes.cReqInfoContainer}>
              <div className={classes.Rowfitter}>
                <div className={classes.cReqInfoDiv}>
                  <Typography className={classes.cReqInfoTitle}>
                    OPEN POSITIONS
                  </Typography>
                  <Typography className={classes.cReqInfoValue}>1</Typography>
                </div>
                <div className={classes.cReqInfoDiv}>
                  <Typography className={classes.cReqInfoTitle}>
                    LEVEL
                  </Typography>
                  <Typography className={classes.cReqInfoValue}>
                    {experiencesData?.[questionnaireData.workExperience]}{" "}
                  </Typography>
                </div>
              </div>
              <div className={classes.Rowfitter}>
                <div className={classes.cReqInfoDiv}>
                  <Typography className={classes.cReqInfoTitle}>
                    LOCATION
                  </Typography>
                  <Typography className={classes.cReqInfoValue}>
                    {questionnaireData?.location?.country}
                  </Typography>
                </div>
                <div className={classes.cReqInfoDiv}>
                  <Typography className={classes.cReqInfoTitle}>
                    CONTRACT TYPE
                  </Typography>
                  <Typography className={classes.cReqInfoValue}>
                    {contractTypesData?.[questionnaireData.contractType]}
                  </Typography>
                </div>
              </div>
              <div className={classes.Rowfitter}>
                <div className={classes.cReqInfoDiv}>
                  <Typography className={classes.cReqInfoTitle}>
                    WORK TYPE
                  </Typography>
                  <Typography className={classes.cReqInfoValue}>
                    {/* {questionnaireData?.location?.timezone} */}
                    Remote
                  </Typography>
                </div>
                <div className={classes.cReqInfoDiv}>
                  <Typography className={classes.cReqInfoTitle}>
                    {questionnaireData?.salaryDetails?.type} RATE
                  </Typography>
                  <Typography className={classes.cReqInfoValue}>
                    {getSalaryDetails()}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        )}
      </Grid>
      <Grid item className={classes.rightView}>
        <div className={classes.logoDiv}>
          <img src={images?.logo_v2} className={classes.logo} />
        </div>

        {isEmployerView ? (
          <Typography className={classes.registerText}>
            Register to view over
            <span className={classes.vettedConsultantsorJobsText}>
              {" "}
              {availableConsultantCount} vetted consultants
            </span>{" "}
            who match the job description
          </Typography>
        ) : (
          <Typography className={classes.registerText}>
            Register to view over
            <span className={classes.vettedConsultantsorJobsText}>
              {" "}
              {availableConsultantCount} vetted jobs
            </span>{" "}
            matching your skills
          </Typography>
        )}

        {isEmployerView && (
          <>
            <BForm.Group className="mb-6" controlId="formGCompanyInfo">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BForm.Group controlId="formCompanyName">
                    <BForm.Label>Enter your Company name</BForm.Label>
                    <BForm.Control
                      type="text"
                      placeholder="Apple Inc"
                      name="company"
                      value={formValues?.company}
                      pattern="^[a-zA-Z0-9 ]*$"
                      onChange={({ target }) =>
                        setFormValues({
                          ...formValues,
                          company: target.value,
                        })
                      }
                      required
                      disabled={inviteeOrgDetails?.company}
                      className={classes.element}
                    />
                    <BForm.Control.Feedback type="invalid">
                      {formValues?.company?.length === 0 && (
                        <>Company name is required</>
                      )}
                      {formValues?.company?.length > 0 &&
                        validateRegex(
                          CHAR_NUMBER_PATTERN,
                          formValues?.company
                        ) && <>Company name is invalid</>}
                    </BForm.Control.Feedback>
                  </BForm.Group>
                </Grid>
                <Grid item xs={6}>
                  <BForm.Group controlId="formCompanyWebSite">
                    <BForm.Label>Enter your Website</BForm.Label>
                    <BForm.Control
                      type="text"
                      placeholder="www.apple.com"
                      name="website"
                      pattern={WEBSITE_PATTERN}
                      // value={formValues.profile_info.website}
                      value={formValues?.website}
                      // value={inviteeOrgDetails?.socialLinks?.website}
                      // onChange={({ target }) =>
                      //   setFormValues({
                      //     ...formValues,
                      //     profile_info: {
                      //       ...formValues.profile_info,
                      //       website: target.value,
                      //     },
                      //   })
                      // }
                      required
                      disabled={inviteeOrgDetails?.socialLinks?.website}
                      className={classes.element}
                    />
                    <BForm.Control.Feedback type="invalid">
                      {/* {formValues.profile_info.website.length === 0 && ( */}
                      {formValues?.website?.length === 0 && (
                        <>Website is required</>
                      )}
                      {formValues?.website?.length > 0 &&
                        validateRegex(WEBSITE_PATTERN, formValues.company) && (
                          <>
                            {" "}
                            Website is invalid.Please make sure you have
                            included 'http://' or 'https://'.
                          </>
                        )}
                    </BForm.Control.Feedback>
                  </BForm.Group>
                </Grid>
              </Grid>
            </BForm.Group>
            <br />
            <br />
            <br />
          </>
        )}

        <BForm
          id="sign-up-form"
          noValidate
          validated={formValidated}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <BForm.Group className="mb-6" controlId="formName">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BForm.Group controlId="formFName">
                  <BForm.Label>Enter your First name</BForm.Label>

                  <BForm.Control
                    type="text"
                    placeholder="John"
                    name="firstName"
                    pattern="^[a-zA-Z0-9 ]*$"
                    value={formValues.firstName}
                    onChange={({ target }) =>
                      setFormValues({
                        ...formValues,
                        firstName: target.value,
                      })
                    }
                    required
                    className={classes.element}
                  />
                  <BForm.Control.Feedback type="invalid">
                    {formValues?.firstName?.length === 0 && (
                      <>First name is required</>
                    )}
                    {formValues?.firstName?.length > 0 &&
                      validateRegex(
                        CHAR_NUMBER_PATTERN,
                        formValues.firstName
                      ) && <>First name is invalid</>}
                  </BForm.Control.Feedback>
                </BForm.Group>
              </Grid>
              <Grid item xs={6}>
                <BForm.Group controlId="formLName">
                  <BForm.Label>Enter your Last name</BForm.Label>
                  <BForm.Control
                    type="text"
                    placeholder="Doe"
                    name="lastName"
                    pattern="^[a-zA-Z0-9 ]*$"
                    value={formValues.lastName}
                    onChange={({ target }) =>
                      setFormValues({
                        ...formValues,
                        lastName: target.value,
                      })
                    }
                    required
                    className={classes.element}
                  />
                  <BForm.Control.Feedback type="invalid">
                    {formValues?.lastName?.length === 0 && (
                      <>Last name is required</>
                    )}
                    {formValues?.lastName?.length > 0 &&
                      validateRegex(
                        CHAR_NUMBER_PATTERN,
                        formValues?.lastName
                      ) && <>Last name is invalid</>}
                  </BForm.Control.Feedback>
                </BForm.Group>
              </Grid>
            </Grid>
          </BForm.Group>
          {isAgencyView && (
            <BForm.Group className="mb-6" controlId="formGCompanyInfo">
              <Grid item xs={12}>
                <BForm.Group controlId="formCompanyName">
                  <BForm.Label>Enter your company name</BForm.Label>
                  <BForm.Control
                    type="text"
                    placeholder="Apple Inc"
                    name="company"
                    value={formValues?.company}
                    pattern="^[a-zA-Z0-9 ]*$"
                    onChange={({ target }) =>
                      setFormValues({
                        ...formValues,
                        company: target.value,
                      })
                    }
                    required
                    disabled={inviteeOrgDetails?.company}
                    className={classes.element}
                  />
                  <BForm.Control.Feedback type="invalid">
                    {formValues?.company?.length === 0 && (
                      <>Company name is required</>
                    )}
                    {formValues?.company?.length > 0 &&
                      validateRegex(
                        CHAR_NUMBER_PATTERN,
                        formValues?.company
                      ) && <>Company name is invalid</>}
                  </BForm.Control.Feedback>
                </BForm.Group>
              </Grid>
            </BForm.Group>
          )}
          <BForm.Group className="mb-6" controlId="formEmail">
            <BForm.Label>Enter your Email</BForm.Label>
            <BForm.Control
              type="email"
              placeholder="john.doe@apple.com"
              name="email"
              // value={ inviteeOrgDetails?.email ? inviteeOrgDetails?.email : formValues.email}
              value={formValues.email}
              onChange={({ target }) =>
                setFormValues({
                  ...formValues,
                  email: target.value,
                })
              }
              required
              disabled={inviteeOrgDetails?.email}
              className={classes.element}
            />
            <BForm.Control.Feedback type="invalid">
              Invalid Email address
            </BForm.Control.Feedback>
          </BForm.Group>
          {/* {isEmployerView && (
            <BForm.Group className="mb-6" controlId="formGCompanyInfo">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BForm.Group controlId="formCompanyName">
                    <BForm.Label>Enter your Company name</BForm.Label>
                    <BForm.Control
                      type="text"
                      placeholder="Apple Inc"
                      name="company_name"
                      value={formValues.company}
                      pattern="^[a-zA-Z0-9 ]*$"
                      onChange={({ target }) =>
                        setFormValues({
                          ...formValues,
                          company: target.value,
                        })
                      }
                      required
                      className={classes.element}
                    />
                    <BForm.Control.Feedback type="invalid">
                      {formValues.company.length === 0 && (
                        <>Company name is required</>
                      )}
                      {formValues.company.length > 0 &&
                        validateRegex(
                          CHAR_NUMBER_PATTERN,
                          formValues.company
                        ) && <>Company name is invalid</>}
                    </BForm.Control.Feedback>
                  </BForm.Group>
                </Grid>
                <Grid item xs={6}>
                  <BForm.Group controlId="formCompanyWebSite">
                    <BForm.Label>Enter your Website</BForm.Label>
                    <BForm.Control
                      type="text"
                      placeholder="www.apple.com"
                      name="website"
                      pattern={WEBSITE_PATTERN}
                      value={formValues.profile_info.website}
                      onChange={({ target }) =>
                        setFormValues({
                          ...formValues,
                          profile_info: {
                            ...formValues.profile_info,
                            website: target.value,
                          },
                        })
                      }
                      required
                      className={classes.element}
                    />
                    <BForm.Control.Feedback type="invalid">
                      {formValues.profile_info.website.length === 0 && (
                        <>Website is required</>
                      )}
                      {formValues.profile_info.website.length > 0 &&
                        validateRegex(WEBSITE_PATTERN, formValues.company) && (
                          <> Website is invalid.Please make sure you have
                            included 'http://' or 'https://'.</>
                        )}
                    </BForm.Control.Feedback>
                  </BForm.Group>
                </Grid>
              </Grid>
            </BForm.Group>
          )} */}

          <BForm.Group controlId="formLocation">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <BForm.Group className="mb-6" controlId="formCountry">
                  <BForm.Label>Select your Country</BForm.Label>
                  <BForm.Select
                    required
                    className={`form-control ${classes.element}`}
                    isInvalid={false}
                    // value={formValues.personal_info.country}
                    value={formValues.country}
                    onChange={({ target }) =>
                      // setFormValues({
                      //   ...formValues,
                      //   personal_info: {
                      //     ...formValues.personal_info,
                      //     country: target.value,
                      //     phone_number: "",
                      //     state: "",
                      //   },
                      // })
                      setFormValues({
                        ...formValues,
                        country: target.value,
                      })
                    }
                  >
                    <option value="">Country</option>
                    {CountryList.map((x, i) => (
                      <option key={i} value={x.country}>
                        {x.country}
                      </option>
                    ))}
                  </BForm.Select>
                  <BForm.Control.Feedback type="invalid">
                    Country is required
                  </BForm.Control.Feedback>
                </BForm.Group>
              </Grid>

              <Grid item xs={6}>
                <BForm.Group className="mb-6" controlId="formState">
                  <BForm.Label>Select your State</BForm.Label>
                  <BForm.Select
                    required
                    className={`form-control ${classes.element}`}
                    isInvalid={false}
                    // value={formValues.personal_info.state}
                    value={formValues.state}
                    onChange={({ target }) =>
                      // setFormValues({
                      //   ...formValues,
                      //   personal_info: {
                      //     ...formValues.personal_info,
                      //     state: target.value,
                      //   },
                      // })
                      setFormValues({
                        ...formValues,
                        state: target.value,
                      })
                    }
                  >
                    <option value="">State</option>
                    {statesData.map((x, i) => (
                      <option key={i} value={x}>
                        {x}
                      </option>
                    ))}
                  </BForm.Select>
                  <BForm.Control.Feedback type="invalid">
                    State is required
                  </BForm.Control.Feedback>
                </BForm.Group>
              </Grid>
            </Grid>

            <BForm.Group className="mb-6" controlId="formCityTimeZone">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BForm.Group controlId="formCity">
                    <BForm.Label>Enter your City</BForm.Label>
                    <BForm.Control
                      type="text"
                      placeholder="City"
                      name="city"
                      pattern="^[a-zA-Z0-9 ]*$"
                      className={classes.element}
                      // value={formValues.personal_info.city}
                      value={formValues.city}
                      onChange={({ target }) =>
                        // setFormValues({
                        //   ...formValues,
                        //   personal_info: {
                        //     ...formValues.personal_info,
                        //     city: target.value,
                        //   },
                        // })
                        setFormValues({
                          ...formValues,
                          city: target.value,
                        })
                      }
                      required
                    />
                    <BForm.Control.Feedback type="invalid">
                      {/* {formValues.personal_info.city.length === 0 && ( */}
                      {formValues?.city?.length === 0 && <>City is required</>}
                      {formValues?.city?.length > 0 &&
                        validateRegex(
                          CHAR_NUMBER_PATTERN,
                          formValues?.city
                        ) && <>City is invalid</>}
                    </BForm.Control.Feedback>
                  </BForm.Group>
                </Grid>
                <Grid item xs={6}>
                  <BForm.Group controlId="formTimeZone">
                    <BForm.Label>Select your Timezone</BForm.Label>
                    <BForm.Select
                      required
                      className={`form-control ${classes.element}`}
                      isInvalid={false}
                      value={formValues.timezone}
                      onChange={({ target }) =>
                        setFormValues({
                          ...formValues,
                          timezone: target.value,
                        })
                      }
                    >
                      <option value="">Time zone</option>
                      {TimezoneList.map((x, i) => (
                        <option key={i} value={x.name}>
                          {x.name}
                        </option>
                      ))}
                    </BForm.Select>
                    <BForm.Control.Feedback type="invalid">
                      Timezone is required
                    </BForm.Control.Feedback>
                  </BForm.Group>
                </Grid>
              </Grid>
            </BForm.Group>
          </BForm.Group>

          <BForm.Group className="mb-12" controlId="formPhoneNumber">
            <BForm.Label>Enter your Phone number</BForm.Label>
            <PhoneInput
              isValid={(value, country) => {
                if (value?.length < 5 && formValidated) {
                  if (value?.length === 0) return "Phone number is required";
                  return "Invalid Phone number";
                } else {
                  return true;
                }
              }}
              inputProps={{
                name: "phone",
                required: true,
                // disabled: formValues.personal_info.country === "",
                disabled: formValues.country === "",
                placeholder: "",
              }}
              disableDropdown
              countryCodeEditable={false}
              country={
                formValues?.phoneNumber?.length
                  ? ""
                  : CountriesWithCode[formValues.country]
              }
              onlyCountries={allowedCountries}
              // value={formValues.personal_info.phone_number}
              value={formValues.phoneNumber}
              onChange={(val, e) => {
                // onChange={(val, e) => {
                // setFormValues({
                //   ...formValues,
                //   personal_info: {
                //     ...formValues.personal_info,
                //     country: e.name,
                //     phone_number: val,
                //   },
                // });
                setFormValues({
                  ...formValues,
                  phoneNumber: val,
                });
                console.log("formValues ", val);
              }}
              className="signupPhoneInput"
            />
          </BForm.Group>

          <BForm.Group
            className="mb-6 position-relative"
            controlId="formPassword "
          >
            <BForm.Label>Enter your password</BForm.Label>
            <BForm.Control
              type={isPassword ? "password" : "text"}
              placeholder="Password"
              name="password"
              required
              isInvalid={formValidated && !isValidPassword}
              pattern={formValues.password}
              value={formValues.password}
              onChange={({ target }) => {
                setFormValues({
                  ...formValues,
                  password: target.value,
                });
                isPasswordPatternMatch(target.value);
              }}
              className={classes.element}
            />
            {!formValidated && (
              <span className="field-info px-2">
                <sup>*</sup>One Uppercase, One Number, One Special Charater &
                Minimum 8 characters
              </span>
            )}
            {formValues?.password?.length > 0 && (
              <>
                {!isPassword && (
                  <BsFillEyeFill
                    style={{
                      position: "absolute",
                      right: "1.5rem",
                      top: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsPassword(true)}
                  />
                )}
                {isPassword && (
                  <BsFillEyeSlashFill
                    style={{
                      position: "absolute",
                      right: "1.5rem",
                      top: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsPassword(false)}
                  />
                )}
              </>
            )}
            <BForm.Control.Feedback type="invalid">
              {formValidated &&
                formValues?.password?.length === 0 &&
                "Password Field Required"}
              {formValues?.password?.length > 0 && (
                <span className="password-validation-message">
                  {validateRegex(/(?=.*[A-Z])/, formValues.password) && (
                    <span>One Uppercase</span>
                  )}
                  {validateRegex(/(?=.*[a-z])/, formValues.password) && (
                    <span>One Lowercase</span>
                  )}
                  {validateRegex(/(?=.*[0-9])/, formValues.password) && (
                    <span>One Number</span>
                  )}
                  {validateRegex(/(?=.*[#?!@$%^&*-])/, formValues.password) && (
                    <span>One Special Character</span>
                  )}
                  {formValues?.password?.length < 8 && (
                    <span>Atleast 8 Characters</span>
                  )}
                </span>
              )}
            </BForm.Control.Feedback>
          </BForm.Group>

          <BForm.Group
            className="mb-9 position-relative"
            controlId="formConfirmPassword"
          >
            <BForm.Label>Confirm password</BForm.Label>
            <BForm.Control
              type={isConfirmPassword ? "password" : "text"}
              placeholder="Retype your password here"
              name="confirmPassword"
              required
              isInvalid={
                formValidated &&
                formValues?.confirmPassword?.length > 0 &&
                formValues?.confirmPassword !== formValues.password
              }
              pattern={formValues.password}
              value={formValues.confirmPassword}
              onChange={({ target }) =>
                setFormValues({
                  ...formValues,
                  confirmPassword: target.value,
                })
              }
              className={classes.element}
            />
            {formValues?.confirmPassword?.length > 0 && (
              <>
                {!isConfirmPassword && (
                  <BsFillEyeFill
                    style={{
                      position: "absolute",
                      right: "1.5rem",
                      top: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsConfirmPassword(true)}
                  />
                )}
                {isConfirmPassword && (
                  <BsFillEyeSlashFill
                    style={{
                      position: "absolute",
                      right: "1.5rem",
                      top: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsConfirmPassword(false)}
                  />
                )}
              </>
            )}
            <BForm.Control.Feedback type="invalid">
              {formValidated &&
                formValues?.confirmPassword?.length === 0 &&
                "Confirm Password Field Required"}
              {formValidated &&
                formValues?.password?.length > 0 &&
                formValues?.confirmPassword?.length > 0 &&
                formValues?.password !== formValues?.confirmPassword &&
                "Password mismatch"}
            </BForm.Control.Feedback>
          </BForm.Group>

          <BForm.Group className="mb-9" controlId="registerSection">
            <BForm.Check
              className="mb-9"
              type="checkbox"
              id={`agree-checkbox`}
              label={
                <span className={classes.agreeText}>
                  I agree with Findpro.ai's{" "}
                  <a href="" className={classes.otherLink}>
                    Terms of Use
                  </a>{" "}
                  and
                  <a href="" className={classes.otherLink}>
                    {" "}
                    Privacy Policy
                  </a>
                </span>
              }
              defaultChecked={agreeCheckbox}
              onChange={() => {
                setAgreeCheckbox((prevState) => !prevState);
              }}
            />

            <BButton
              variant="light"
              className={`w-100 ${classes.registerButton}`}
              type="submit"
              // type="button"
              disabled={!agreeCheckbox || checkIsFormValid()}
              onClick={(e) => {
                //   history.push({
                //     pathname: "/client/dashboard",
                //     state: { incomplete: true },
                //   });
                //   window.scrollTo(0, 0);

                console.log("registerButton");
                handleSubmit(e);
              }}
            >
              Register
            </BButton>
          </BForm.Group>
        </BForm>
        <div className={classes.orTextAndSSODiv}>
          <div className={classes.orTextDiv}>
            <div className={classes.line}></div>
            <span className={classes.orText}> Or</span>
            <div className={classes.line}></div>
          </div>
          <div className={classes.singleSignOnDiv}>
            <BButton
              variant="light"
              className={classes.ssoButton}
              onClick={() => {
                const signUp = true;
                // const company = "Edwards Corp"; // pass the comany info from the from
                const company = formValues?.company;
                const url = `${
                  configs.APIUrl
                }/${accountType}/auth/google?signUp=${signUp}&company=${encodeURIComponent(
                  company
                )}`;
                window.location.href = url;

                // fetch(url)
                // .then(response => response.json())
                // .then(data => {
                //   if (data.message) {
                //     enqueueSnackbar(data.message, { variant: "error", });
                //   } else {
                //     // Continue with the Google SSO process
                //     window.location.href = url;
                //   }
                // })
                // .catch(error => {
                //   window.location.href = url; // for now

                //   // Handle network or other errors
                //   console.error(error);
                //   enqueueSnackbar('An error occurred.', { variant: "error", });

                // });
              }}
            >
              <img src="/google-logo-black.png" alt="Google Signon" />
              <Typography className={classes.signupText}>
                {" "}
                Sign up with Google
              </Typography>
            </BButton>
            <BButton
              variant="light"
              className={classes.ssoButton}
              onClick={handleLinkedInAuth}
            >
              <img src="/linkedin-logo-black.png" alt="Linkedin Signon" />
              <Typography className={classes.signupText}>
                Sign up with Linkedin
              </Typography>
            </BButton>
          </div>
          <Typography className={classes.loginText}>
            Already have an account?{" "}
            <Link to={`/${accountType}/login`} className={classes.loginLink}>
              Log in
            </Link>
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Signup;
