import React from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import SearchLoader from "./SearchLoader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const SearchLoaderModal = ({ open }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      className={classes.modal}
      // Add any other modal props as needed
    >
      <div className={classes.paper}>
        <SearchLoader />
      </div>
    </Modal>
  );
};

export default SearchLoaderModal;
