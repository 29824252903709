import { createAsyncThunk } from "@reduxjs/toolkit";
import { miscService } from "app/v2/services/miscService";

export const fetchRolesThunk = createAsyncThunk("misc/fetchRoles", async () => {
  return miscService.fetchRoles();
});

export const fetchSkillsThunk = createAsyncThunk(
  "misc/fetchSkills",
  async () => {
    return miscService.fetchSkills();
  }
);

export const fetchSkillLevelsThunk = createAsyncThunk(
  "misc/fetchSkillLevels",
  async () => {
    return miscService.fetchSkillLevels();
  }
);

export const fetchExperiencesThunk = createAsyncThunk(
  "misc/fetchExperiences",
  async () => {
    return miscService.fetchExperiences();
  }
);

export const fetchContractTypesThunk = createAsyncThunk(
  "misc/fetchContractTypes",
  async () => {
    return miscService.fetchContractTypes();
  }
);

export const fetchFAQsThunk = createAsyncThunk(
  "misc/fetchFAQsThunk",
  async () => {
    return miscService.getFAQs();
  }
);

export const fetchIndustryThunk = createAsyncThunk("industry", async () => {
  return miscService.fetchIndustryData();
});
