const prodOrigins = ["https://findpro.ai", "https://www.findpro.ai"];

export const prodConfigs = {
  /** This is production */
  APIUrl: "/api",
  homeUrl: "https://findpro.ai/",
  linkedinAuthHome: prodOrigins.includes(window.location.origin) ?  `https://app.findpro.ai/api` : `${window.location.origin}/api`,// for ex: https://dev.findpro.ai/api"
  paymentConfig: {
    clientKey: "Ae02CQXRyCpBXHDr3MDORbIiofE-f6DD9s5blhbGJv4EoygdgAC4og00-NirrSQBQpYj2yEXjrPm-p9T"
  },
};
