import React from "react";
export const EditSVG = ({
  fillColor = "#000000",
  className = "",
  ...props
}) => {
  return (
<svg 
fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="20" height="20" viewBox="0 0 20 20" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
<path d="M12.6676 3.33566L12.6676 3.33562C13.0818 2.92146 13.7558 2.92146 14.17 3.33562L16.664 5.82979C16.6641 5.82981 16.6641 5.82983 16.6641 5.82985C17.0784 6.2425 17.0782 6.91653 16.6659 7.33058L16.6658 7.33073L7.59779 16.3991L7.52708 16.3284L7.59779 16.3991C7.56686 16.43 7.53259 16.4532 7.49853 16.4712L7.49647 16.4723L7.49646 16.4723L7.48871 16.476L12.6676 3.33566ZM12.6676 3.33566L11.3083 4.6966L11.3083 4.69664L3.60122 12.404L3.67193 12.4747L3.60122 12.404C3.57139 12.4338 3.54874 12.4667 3.53099 12.4996C3.52585 12.508 3.52091 12.5176 3.51704 12.5275C3.50309 12.5579 3.49181 12.5909 3.48519 12.6253C3.48372 12.6301 3.48218 12.6362 3.4813 12.6435L3.03594 15.7628L3.03594 15.7628L3.13494 15.7769L12.6676 3.33566ZM4.49302 12.7007L11.6066 5.58841L14.4115 8.39337L7.29945 15.5072L4.49302 12.7007ZM4.11945 16.1316C4.11944 16.1316 4.11943 16.1316 4.11942 16.1316C4.04998 16.1415 3.98194 16.1186 3.9318 16.0685C3.88165 16.0184 3.85879 15.9503 3.86871 15.8809C3.86871 15.8809 3.86871 15.8808 3.86871 15.8808L4.19624 13.5925L6.40772 15.804L4.11945 16.1316ZM16.0691 6.73565L15.0065 7.79828L12.2017 4.99333L13.2643 3.9307C13.3503 3.84472 13.4905 3.84472 13.5765 3.9307L16.0691 6.42341C16.1551 6.5094 16.1551 6.64967 16.0691 6.73565Z" fill="#363636" stroke="#363636" strokeWidth="0.2"/>
</svg>
  );
};
