import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import clsx from "clsx";

import {
  FormControlLabel,
  Switch,
  Typography,
  Button,
  Checkbox,
  Tooltip,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomCheckbox from "app/v2/components/CustomCheckbox";
import CustomSwitch from "app/v2/components/filters/CustomSwitch";
import CustomDialog from "app/v2/components/CustomDialog";
import CustomSelect from "app/v2/components/filters/CustomSelect";
import CustomTooltip from "app/v2/components/CustomTooltip";
import CustomLabel from "app/v2/components/filters/CustomLabel";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";
import "./styles.scss";

import { PASSWORD_PATTERN, EMAIL_PATTERN } from "app/v2/Pages/Utils/constants";
import { messages } from "app/v2/Pages/Utils/constants";
import { getAccountType } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  actionButton: {
    padding: "12px 28px",
    fontSize: "16px",
    fontWeight: 600,
  },
  saveButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    "&:disabled": {
      backgroundColor: `${theme.palette.common.black} !important`,
    },
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  activationDialog: {
    width: "795px",
    padding: "48px",
    display: "flex",
    flexDirection: "column",
    rowGap: "33px",
  },
  activationTitle: {
    fontSize: "24px",
    fontWeight: 600,
  },
  activationNote: {
    fontSize: "14px",
    fontStyle: "italic",
  },
  verificationDiv: {
    padding: "36px",
    display: "flex",
    flexDirection: "column",
    rowGap: "16px",
    backgroundColor: "#F4F8FF",
  },
  verificationText: {
    fontSize: "18px",
    fontWeight: 600,
    color: theme.palette.primary.main,
  },
  verificationDesc: {
    fontSize: "14px",
  },
  sorAButtonText: {
    fontSize: "16px",
    fontWeight: 600,
  }, // sOrA -> submit or activation
  sOrAButtonPaddingInDialog: {
    padding: "16px 28px",
  },
  activationButtonPaddingInView: {
    padding: "12px 28px",
  },
  submitButton: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
  },
  deactivateButton: {
    backgroundColor: "#FFECEA !important",
    color: "#D80027",
  },
  reactivateButton: {
    backgroundColor: `${theme.palette.common.black} !important`,
    color: theme.palette.common.white,
  },
  deactivateButtonInView: {
    border: "1px solid #FFECEA",
  },
  reactivateButtonInView: {
    border: `1px solid ${theme.palette.common.black}`,
  },
}));

const personalDummyData = {
  firstName: "Princy",
  lastName: "Ajit",
  email: "princy@findpro.ai",
  password: "Dummypassword@123",
  personal_info: {
    phone_number: "+919959845375",
  },
  notificationTypes: {
    nT1: true,
    nT2: true,
    nT3: true,
    nT4: true,
  },
  notifications: {
    whatsapp: true,
    email: false,
    webApp: true,
  },
};

const SaveDialog = ({
  dialogOpen,
  setDialogOpen,
  actionLoading,
  actionCompleted,
  actionError,
}) => {
  const Title = () => {
    return (
      <div className="text-[16px] font-semibold text-center">
        {actionLoading && "Action in progress"}
        {actionError && "Error"}
        {actionCompleted && "Personal Details Saved"}
      </div>
    );
  };

  const DialogBody = () => {
    if (actionLoading) return <DialogLoadingBody />;
    else if (actionCompleted)
      return (
        <DialogSuccessBody
          content={{
            title: "Your details have been saved successfully.",
          }}
        />
      );
    else if (actionError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={dialogOpen}
      setOpen={setDialogOpen}
      noCloseAction={actionLoading}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

const PersonalDetails = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const accountType = getAccountType();

  // const personalDataBuf = useSelector(
  //   (state) => state.clientProfileInfo.clientInfo.employer
  // );
  const [initialPersonalData, setInitialPersonalData] = useState(
    // !personalDataBuf?.length
    //   ?
    {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobileNo: "",
      personal_info: {
        phone_number: "",
      },
      companyTitle: "ABC", // Hardcoding this for now since for isProfileComplete to be true, this value is also needed.
      notificationTypes: { nT1: true, nT2: true, nT3: true, nT4: true }, // here 1,2,3,4 corresponds to the values of the checkboxes.
      notifications: {
        whatsapp: true,
        email: true,
        webApp: true,
      },
    }
    // : personalDataBuf
  );
  const [personalData, setPersonalData] = useState(initialPersonalData);
  const [isEditMode, setIsEditMode] = useState(false);
  const [resetTextfield, setResetTextField] = useState(false);
  const [allEntriesValid, setAllEntriesValid] = useState(false);
  const [isDeactivateMode, setIsDeActivateMode] = useState(true);
  const [openActivationDialog, setOpenActivationDialog] = useState(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState();
  const [saveActionLoading, setSaveActionLoading] = useState(false);
  const [saveActionCompleted, setSaveActionCompleted] = useState(false);
  const [saveActionError, setSaveActionError] = useState(false);

  const toggleEditMode = () => {
    setIsEditMode((prevState) => !prevState);
  };

  const onCancelClick = () => {
    setPersonalData(initialPersonalData);
    setResetTextField((prevState) => !prevState);
    toggleEditMode();
  };

  const onSaveClick = () => {
    setSaveDialogOpen(true);
    setSaveActionLoading(true);
    setTimeout(() => {
      /** Confirm case */
      setIsEditMode(false);
      setSaveActionLoading(false);
      setSaveActionCompleted(true);
      setInitialPersonalData(personalData);

      /** Error case */
      // setSaveActionLoading(false);
      // setSaveActionError(true);
      // setSaveActionCompleted(false);
    }, 10000);

    // dispatch(updateEmployerInfoThunk(personalData))
    //   .unwrap()
    //   .then((res) => {
    //     if (res.message) {
    //       setIsEditMode(false);
    //       setSaveActionLoading(false);
    //       setSaveActionCompleted(true);
    //       setInitialPersonalData(personalData);
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.message) {
    //       setSaveActionLoading(false);
    //       setSaveActionError(true);
    //       setSaveActionCompleted(false);
    //     }
    //     console.error(err);
    //   }); // add right api later.
  };

  const onPersonalDataChange = (id, value) => {
    setPersonalData((prevPersonalData) => ({
      ...prevPersonalData,
      [id]: value,
    }));
  };

  const onMobileNumberChange = (newMobileNo) => {
    setPersonalData((prevPersonalData) => ({
      ...prevPersonalData,
      personal_info: {
        ...prevPersonalData?.personal_info,
        phone_number: newMobileNo,
      },
    }));
  };

  const onNotificationTypeCBChange = (id, checkboxState) => {
    setPersonalData((prevPersonalData) => {
      let updatedPersonalData = { ...prevPersonalData };
      updatedPersonalData.notificationTypes = {
        ...updatedPersonalData?.notificationTypes,
        [id]: checkboxState,
      };
      return updatedPersonalData;
    });
  }; // CB - checkbox

  const onNotificationRadioChange = (id, value) => {
    setPersonalData((prevPersonalData) => {
      let updatedPersonalData = { ...prevPersonalData };
      updatedPersonalData.notifications = {
        ...updatedPersonalData?.notifications,
        [id]: value,
      };
      return updatedPersonalData;
    });
  };

  const validateEntries = () => {
    // data validation (No condition for notification checkboxes and radio buttons since they will have a state).
    const firstNameEntered = personalData?.firstName?.length > 0;
    const lastNameEntered = personalData?.lastName?.length > 0;
    const validEmail = EMAIL_PATTERN.test(personalData?.email);
    const validPassword = PASSWORD_PATTERN.test(personalData?.password);
    const mobileNoEntered =
      personalData?.personal_info?.phone_number?.length > 0;

    setAllEntriesValid(
      firstNameEntered &&
        lastNameEntered &&
        // validEmail &&
        // validPassword && // commented since not working as expected,check later.
        mobileNoEntered
    );
  };
  useEffect(() => {
    setInitialPersonalData(personalDummyData);
    setPersonalData(personalDummyData);

    // if (!personalDataBuf?.length) {
    //   dispatch(getEmployerInfoThunk())
    //     .unwrap()
    //     .then((res) => {
    //       if (res?.employer) {
    //         setInitialPersonalData(res.employer);
    //         setPersonalData(res.employer);
    //       }
    //     })
    //     .catch((err) => {
    //       if (err.message) {
    //         enqueueSnackbar(err.message, { variant: "error" });
    //       }
    //       console.error(err);
    //     });
    // } // add right api later.
  }, [dispatch]);

  useEffect(() => {
    validateEntries();
  }, [personalData]);

  useEffect(() => {
    if (!saveDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setSaveActionLoading(false);
      setSaveActionCompleted(false);
      setSaveActionError(false);
    }
  }, [saveDialogOpen]);

  const ActivationDialogTitle = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {isDeactivateMode ? "Deactivate" : "Reactivate"} Account
      </Typography>
    );
  };

  const ActivationDialogContent = () => {
    const reactivationReasons = ["A", "B", "C"];
    const deactivationNote =
      "Please note: Deactivation will be effective immediately Upon deactivation, you may only reactivate your account by getting in touch with the Findpro Team.";
    const reactivationNote =
      "Please note: Reactivation can only be effective once the Findpro.ai Team has investivated and approved the request. Upon submission of request, standby for further directives from the Findpro.ai Team via your account email’s inbox.";

    const [password, setPassword] = useState();
    const [reactivationReason, setReactivationReason] = useState();
    const [reactivationDetails, setReactivationDetails] = useState();

    const onPasswordChange = (id, value) => {
      setPassword(value);
    };

    const onReactivationReasonChange = (id, value) => {
      setReactivationReason(value);
    };

    const onActivationClick = () => {
      setOpenActivationDialog(false);
      setIsDeActivateMode((prevState) => !prevState);
    };

    return (
      <div className={classes.activationDialog}>
        {isDeactivateMode ? (
          <>
            <Typography className={classes.activationTitle}>
              Deactivate Account
            </Typography>
            <div className={classes.verificationDiv}>
              <Typography className={classes.verificationText}>
                Verification
              </Typography>
              <Typography className={classes.verificationDesc}>
                Verify your identity by logging in your account details again.{" "}
              </Typography>
              <CustomTextfield
                id="email"
                type="email"
                label="email"
                value={personalData?.email}
                // onChange={onPersonalDataChange}
                disabled={true}
              />
              <CustomTextfield
                id="password"
                type="password"
                label="password"
                value={password}
                onChange={onPasswordChange}
              />
              <Button
                className={clsx([
                  classes.sorAButtonText,
                  classes.sOrAButtonPaddingInDialog,
                  classes.submitButton,
                ])}
              >
                Submit
              </Button>
            </div>
            <Typography className={classes.activationNote}>
              {deactivationNote}
            </Typography>
            <Button
              onClick={onActivationClick}
              className={clsx([
                classes.sorAButtonText,
                classes.sOrAButtonPaddingInDialog,
                classes.deactivateButton,
              ])}
            >
              Deactivate Account
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.activationTitle}>
              Reactivate Account
            </Typography>

            <CustomSelect
              id="reactivationReason"
              data={reactivationReasons}
              label="Reason for reactivation"
              placeholder={"Select"}
              onChange={onReactivationReasonChange}
              defaultSelection={reactivationReason}
            />

            <textarea
              value={reactivationDetails}
              onChange={setReactivationDetails}
              className={classes.reactivationDetails}
            ></textarea>
            <Typography className={classes.activationNote}>
              {reactivationNote}
            </Typography>
            <Button
              onClick={onActivationClick}
              className={clsx([
                classes.sorAButtonText,
                classes.sOrAButtonPaddingInDialog,
                classes.reactivateButton,
              ])}
            >
              Request Reactivation
            </Button>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="flex flex-col gap-[36px]">
        <div className=" flex justify-between">
          <div className="text-[32px] font-semibold">Personal Details</div>
          {isEditMode ? (
            <div className="flex flex-row gap-x-[12px]">
              <button
                onClick={onCancelClick}
                className={clsx([
                  classes.button,
                  classes.actionButton,
                  "bg-[#EDEDED]",
                ])}
              >
                Cancel
              </button>
              <CustomTooltip
                arrow
                title={!allEntriesValid && "Please enter all the details."}
              >
                <button
                  onClick={onSaveClick}
                  className={clsx([
                    classes.button,
                    classes.actionButton,
                    classes.saveButton,
                  ])}
                  disabled={!allEntriesValid}
                >
                  <div>Save</div>
                </button>
              </CustomTooltip>
            </div>
          ) : (
            <button
              onClick={toggleEditMode}
              className={clsx([
                classes.button,
                classes.actionButton,
                "bg-[#EDEDED]",
              ])}
            >
              Edit
            </button>
          )}
        </div>
        <div className=" flex flex-col px-[16px] gap-[24px] ">
          <div className=" flex gap-[12px]">
            <div className="flex-1">
              <CustomTextfield
                id="firstName"
                label="First name"
                // value={personalData?.first_name}
                value={personalData?.firstName}
                onChange={onPersonalDataChange}
                disabled={!isEditMode}
                reset={resetTextfield}
              />
            </div>
            <div className="flex-1">
              <CustomTextfield
                id="lastName"
                label="Last name"
                // value={personalData?.last_name}
                value={personalData?.lastName}
                onChange={onPersonalDataChange}
                disabled={!isEditMode}
                reset={resetTextfield}
              />
            </div>
          </div>
          <div className="mb-[6px]">
            <CustomTextfield
              id="email"
              type="email"
              label="email"
              value={personalData?.email}
              // onChange={onPersonalDataChange}
              disabled={true}
            />
            <div className="text-[12px]">
              Connected email address cannot be changed.
            </div>
          </div>
          {/* <div className=" overflow-hidden flex flex-col gap-[12px]">
              <CustomTextfield
                id="password"
                type="password"
                label="password"
                value={personalData?.password}
                onChange={onPersonalDataChange}
                disabled={!isEditMode}
                reset={resetTextfield}
              />
            </div> */}
          <div>
            <CustomLabel label="mobile number" />
            <PhoneInput
              placeholder=""
              value={personalData?.personal_info?.phone_number}
              onChange={(val, e) => {
                onMobileNumberChange(val);
              }}
              disabled={!isEditMode}
            />
          </div>

          <Link
            className="text-[14px] font-semibold underline"
            to={`/${accountType}/forgot-password`}
          >
            If you’d like to change your password please follow through.
          </Link>
        </div>
        <hr className="mb-0" />
        <div className="text-[32px] font-semibold">Notification details</div>
        <div className=" flex flex-col px-[16px] gap-[24px]">
          <div className="text-[24px] font-semibold">Notification Type</div>
          <div className="flex flex-col gap-y-[24px]">
            <CustomCheckbox
              label="I want to receive notifications about new applicants"
              id="nT1"
              checked={personalData?.notificationTypes?.nT1 === true}
              disabled={!isEditMode}
              onChange={onNotificationTypeCBChange}
            />
            <CustomCheckbox
              label="I want to receive notifications about new applicants"
              id="nT2"
              checked={personalData?.notificationTypes?.nT2 === true}
              disabled={!isEditMode}
              onChange={onNotificationTypeCBChange}
            />
            <CustomCheckbox
              label="I want to receive notifications about new applicants"
              id="nT3"
              checked={personalData?.notificationTypes?.nT3 === true}
              disabled={!isEditMode}
              onChange={onNotificationTypeCBChange}
            />
            <CustomCheckbox
              label="I want to receive notifications about new applicants"
              id="nT4"
              checked={personalData?.notificationTypes?.nT4 === true}
              disabled={!isEditMode}
              onChange={onNotificationTypeCBChange}
            />
          </div>
        </div>
        <div className=" flex flex-col px-[16px] gap-[24px]">
          <div className="text-[24px] font-semibold">Notification Platform</div>
          <div className="flex gap-[24px]">
            <CustomSwitch
              id="whatsapp"
              label="Whatsapp Notifications"
              onChange={onNotificationRadioChange}
              defaultSelection={personalData?.notifications?.whatsapp === true}
              disabled={!isEditMode}
            />

            <CustomSwitch
              id="email"
              label="Email Notifications"
              onChange={onNotificationRadioChange}
              defaultSelection={personalData?.notifications?.email === true}
              disabled={!isEditMode}
            />

            <CustomSwitch
              id="webApp"
              label=" Web App Notifications"
              onChange={onNotificationRadioChange}
              defaultSelection={personalData?.notifications?.webApp === true}
              disabled={!isEditMode}
            />
          </div>
        </div>
        {/* <hr className="mb-0" />

        <div className="text-[32px] font-semibold">Account Activation</div>
        <div className="flex flex-col gap-y-[12px] items-start px-[16px]">
          <div className="text-[16px] font-semibold">Deactivate Account</div>
          <div className="text-[14px] text-[#717171]">
            Once your account is deactivated, your listings and current data
            won’t be available publicly on this app. Only you will be able to
            access them. Upon deactivation, you may only reactivate your account
            by getting in touch with the Findpro Team. To proceed with
            reactivation click the button below.
          </div>
          <button
            className={clsx([
              classes.activationButtonPaddingInView,
              classes.sorAButtonText,
              isDeactivateMode
                ? `${classes.deactivateButton} ${classes.deactivateButtonInView}`
                : `${classes.reactivateButton} ${classes.reactivateButtonInView}`,
            ])}
            onClick={() => {
              setOpenActivationDialog(true);
            }}
          >
            {isDeactivateMode ? "Deactivate Account" : "Reactivate Account"}
          </button>
        </div> */}
        {/* Hidden for MVP */}
      </div>
      {/* <CustomDialog
        open={openActivationDialog}
        setOpen={setOpenActivationDialog}
        title={<ActivationDialogTitle />}
        content={<ActivationDialogContent />}
      /> */}
      {/* Hidden for MVP */}
      <SaveDialog
        dialogOpen={saveDialogOpen}
        setDialogOpen={setSaveDialogOpen}
        actionLoading={saveActionLoading}
        actionCompleted={saveActionCompleted}
        actionError={saveActionError}
      />
    </>
  );
};

export default PersonalDetails;
