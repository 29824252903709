import fetchWrapper from "app/common/utils/fetchWrapper";
import configs from "configs";

export const getInfo = async () => {
  return fetchWrapper(`${configs.APIUrl}/vendors/info`);
};

export const updateInfo = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/vendors/update-info`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
