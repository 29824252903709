import fetchWrapper from "../../Utils/fetchWrapper";
import configs from "../../Utils/configs";

export const getEmployerInfo = async (request) => {
  return fetchWrapper(`${configs.APIUrl}/employers/info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: request,
  });
};
export const addDocuments = async (bodyRequest) => {
  console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/consultant/addDocument`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};

export const updateDocuments = async (bodyRequest,documentId) => {
  console.log({ bodyRequest });
  return fetchWrapper(`${configs.APIUrl}/consultant/updateDocument/${documentId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: bodyRequest,
  });
};

export const deleteDocument = async (documentId) => {
  return fetchWrapper(`${configs.APIUrl}/consultant/deleteDocument/${documentId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getDocuments = async () => {
  return fetchWrapper(`${configs.APIUrl}/consultant/getDocuments`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getDocumentTypes = async () => {
  return fetchWrapper(`${configs.APIUrl}/misc/documentTypes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};


