import { useDispatch } from "react-redux";
import { disableLoader, enableLoader } from "../loaderSlice";

const useToggleLoader = () => {
  const dispatch = useDispatch();
  const randomNumber = Math.floor(Math.random() * 100000 + 1);

  return (enable) => {
    if (enable) {
      dispatch(enableLoader(randomNumber));
    } else if (enable === false) {
      dispatch(disableLoader(randomNumber));
    }
  };
};

export default useToggleLoader;
