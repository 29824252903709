import React from "react";
import PropTypes from "prop-types";
import { Typography, TextField, Link, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../components/CustomButton";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import devImages from "../../images/hire_developer_team/developer";
import teamImages from "../../images/hire_developer_team/team";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  aboutRoot: {
    margin: "5rem 0 5.6rem 6.3rem",
  },
  aboutSmallRoot: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    [theme.breakpoints.down("1201")]: {
      width: "80%",
      margin: "88px auto 44px auto",
    },
    [theme.breakpoints.down("600")]: {
      width: "100%",
    },
  },
  detailsAndImage: {
    display: "flex",
    alignItems: "center",
    columnGap: "16px",
  },
  details: {
    width: "847px",
    [theme.breakpoints.down("1500")]: {
      width: "650px",
    },
  },
  heading: {
    marginBottom: "28px",
    [theme.breakpoints.down("1800")]: {
      fontSize: "3.8rem",
    },
    [theme.breakpoints.down("1600")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("1400")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("600")]: {
      padding: "0 12px",
    },
  },
  aboutDesc: {
    color: theme.palette.secondary.light,
    marginBottom: "44px",
    [theme.breakpoints.down("600")]: {
      padding: "0 30px",
    },
  },
  hireNowDiv: {
    display: "flex",
    marginBottom: "127px ",
    [theme.breakpoints.down("1200")]: {
      marginBottom: "34px",
    },
    [theme.breakpoints.down("600")]: {
      padding: "0 12px",
    },
  },
  emailTextField: {
    maxWidth: "505px",
    width: "100%",
    fontSize: "1.3rem",
    marginRight: "12px",
    [theme.breakpoints.down("1200")]: {
      fontSize: "1rem",
    },
  },
  buttonRoot: {
    border: "2px solid #C8C8C8",
    maxWidth: "19.3rem",
    maxHeight: "4.8rem",
    height: "100%",
    padding: "0.9rem 4.4rem",
    borderRadius: 0,
    whiteSpace: "nowrap",
    fontSize: "1.3rem",
    fontWeight: theme.typography.button.fontWeight,
    [theme.breakpoints.down("1200")]: {
      width: "12.3rem",
      fontSize: "1rem",
    },
    [theme.breakpoints.down("600")]: {
      width: "10rem",
      fontSize: "0.8rem",
    },
  },

  highlightText: {
    color: `${theme.palette.primary.main} !important`,
    textDecoration: "none",
    fontSize: "inherit",
    fontWeight: theme.typography.button.fontWeight,
    fontFamily: "inherit",
    cursor: "pointer",
  },
  aboutImage: {
    display: "flex",
    [theme.breakpoints.down("1200")]: {
      marginBottom: "44px",
    },
  },
  applyNow: {
    fontSize: "1rem",
    fontWeight: 600,
    [theme.breakpoints.down("1200")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("600")]: {
      padding: "0 73px",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

const About = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const mdDown = useMediaQuery("(max-width:1200px)");
  const smDown = useMediaQuery("(max-width:800px)");

  const { type } = props;
  const data = {
    developer: {
      title: "Tired of traditional recruiting to hire software developers?",
      description:
        "Hire senior pre-vetted remote developers with strong technical and communication skills at unbeatable prices, ready to work in your timezone.",
      image: devImages.developer_about,
    },
    team: {
      title: "Hire a development team to deliver solution success",
      description:
        "Need a successful solution? Our expert team delivers customized development solutions that bring your vision to life and take your business to the next level.",
      image: teamImages.team_about,
    },
  };
  const currentViewData = data[type];

  const Heading = ({ data }) => {
    return (
      <Typography variant="h1" className={classes.heading}>
        {data}
      </Typography>
    );
  };
  const Description = ({ data }) => {
    return (
      <Typography variant="body1" className={classes.aboutDesc}>
        {data}
      </Typography>
    );
  };

  const HireNow = () => {
    return (
      <div className={classes.hireNowDiv}>
        <div className={classes.emailTextField}>
          {" "}
          <TextField
            id="outlined-basic"
            label="Your Work Email"
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                paddingTop: smDown ? "16.5px" : "1.4rem",
                paddingBottom: smDown ? "16.5px" : "1.4rem",
              },
              "& .MuiFormLabel-root": {
                fontSize: smDown && "0.8rem",
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                color: "#949494",
              },
              "& .MuiOutlinedInput-root": {
                border: "1px solid #E0E0E0 !important",
                borderRadius: 0,
              },
            }}
          />
        </div>

        <CustomButton
          onClick={() => {}}
          type="button1"
          customClasses={classes.buttonRoot}
        >
          Hire Now
        </CustomButton>
      </div>
    );
  };

  const ApplyNow = () => {
    return (
      <Typography variant="body1" className={classes.applyNow}>
        Looking for jobs instead?{" "}
        <Link
          className={classes.highlightText}
          onClick={() => {
            history.push("/candidates/sign-up");
          }}
        >
          Apply for jobs
        </Link>{" "}
      </Typography>
    );
  };

  const ImageDiv = ({ image }) => {
    return (
      <div className={classes.aboutImage}>
        <img src={image} alt="Candidate info card" />
      </div>
    );
  };
  return (
    <>
      {mdDown ? (
        <div className={classes.aboutSmallRoot}>
          <Heading data={currentViewData.title} />
          <ImageDiv image={currentViewData.image} />
          <Description data={currentViewData.description} />
          <HireNow />
          {type === "developer" && <ApplyNow />}
        </div>
      ) : (
        <div className={classes.aboutRoot}>
          <div className={classes.detailsAndImage}>
            <div className={classes.details}>
              <Heading data={currentViewData.title} />
              <Description data={currentViewData.description} />
              <HireNow />
              {type === "developer" && <ApplyNow />}
            </div>
            <ImageDiv image={currentViewData.image} />
          </div>
        </div>
      )}
    </>
  );
};

About.propTypes = {};

export default About;
