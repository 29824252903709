import React from "react";
import PropTypes from "prop-types";
import CustomSelect from "./CustomSelect";
import CustomDatePicker from "./CustomDatePicker";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  jobPreferenceRoot: {
    display: "flex",
    flexDirection:"column",
    // columnGap: "33px",
    
  },

  jobpositionchange:{
    display:"flex",
    columnGap:"66px"
  },
  jobPrefElement: {
    minWidth: "140px",
    width: "100%",
  },
}));

const JobPreference = (props) => {
  const classes = useStyles();
  const { id: parentId, onChange } = props;
  return (
    <div className={classes.jobPreferenceRoot}>
      <div className={classes.jobpositionchange}>
      <CustomSelect
        id={`${parentId}-job-type`}
        data={["Part time", "Full time", "Contract"]}
        label={"JOB TYPE"}
        placeholder={"Select"}
        onChange={onChange}
        customClasses={classes.jobPrefElement}
        // defaultSelection:""
      />
      <CustomSelect
        id={`${parentId}-job-mode`}
        data={["On-site", "Remote", "Hybrid"]}
        label={"JOB MODE"}
        placeholder={"Select"}
        onChange={onChange}
        customClasses={classes.jobPrefElement}
      />
      </div>
      <CustomSelect
        id={`${parentId}-availability`}
        data={["Immediate to 15 days", "Within 30 days", "Within 60 days"]}
        label={"AVAILABILITY"}
        placeholder={"Select"}
        onChange={onChange}
        customClasses={classes.jobPrefElement}
      />
    </div>
  );
};

JobPreference.propTypes = {};

export default JobPreference;
