import React from "react";

const DocumentIcon = ({ fileType }) => {
  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'pdf':
        return '/iconlightpdf.svg';
      case 'doc':
      case 'docx':
        return '/iconlightdoc.svg';
      case 'xls':
      case 'xlsx':
        return '/iconlightxls.svg';
      case 'ppt':
      case 'pptx':
        return '/iconlightppt.svg';
      default:
        return '/iconlightgeneric.svg';
    }
  };

  return (
    <img
      className="relative w-12 h-12 overflow-hidden shrink-0"
      alt=""
      src={getFileIcon(fileType)}
    />
  );
};

export default DocumentIcon;
