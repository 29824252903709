import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import CustomButton from "app/v2/components/CustomButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../images/hire_developer_team/common";

const useStyles = makeStyles((theme) => ({
  whyTeamRoot: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: "1px solid black",
  },
  whyTeamContainer: {
    maxWidth: "1600px", // increased from 1548 to show all three cards fully within swiper
    margin: "168px auto",
    [theme.breakpoints.down("1650")]: {
      maxWidth: "1350px",
    },
    [theme.breakpoints.down("1400")]: {
      maxWidth: "1150px",
    },
    [theme.breakpoints.down("1200")]: {
      maxWidth: "950px",
    },
    [theme.breakpoints.down("1000")]: {
      margin: "81px auto",
    },
  },
  titleDescDiv: {
    maxWidth: "875px",
    marginBottom: "103px",
    [theme.breakpoints.down("1000")]: {
      margin: "81px 52px",
    },
  },
  title: {
    color: "inherit",
    marginBottom: "45px",
    [theme.breakpoints.down("600")]: {
      fontSize: "35px",
    },
  },
  description: {
    [theme.breakpoints.down("600")]: {
      fontSize: "15px",
    },
  },
  swiperContainer: {
    [theme.breakpoints.down("1000")]: {
      marginLeft: "52px",
      marginBottom: "81px",
    },
  },
  swiperSlide: {
    [theme.breakpoints.down("1000")]: {
      marginBottom: "81px",
    },
    [theme.breakpoints.down("400")]: {
      marginBottom: "0px",
    },
    [theme.breakpoints.between("600", "1000")]: {
      width: "fit-content !important",
    },
  },
  reasonCardOuterDiv: {
    maxWidth: "498px",
    minHeight: "483px",
    width: "100%",
    border: "1px solid #6B6B6B",
    marginBottom: "61px",
    [theme.breakpoints.down("1650")]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.down("1400")]: {
      maxWidth: "325px",
    },
    [theme.breakpoints.down("1200")]: {
      maxWidth: "275px",
      maxHeight: "400px",
    },
    [theme.breakpoints.down("1000")]: {
      maxWidth: "300px",
      minHeight: "290px",
      marginBottom: "81px",
    },
    [theme.breakpoints.down("600")]: {
      maxHeight: "400px",
    },
  },
  reasonCardInnerDiv: {
    maxWidth: "378px",
    margin: "61px auto 104px auto",
    [theme.breakpoints.down("1400")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("1400")]: {
      maxWidth: "250px",
      margin: "50px auto",
    },
    [theme.breakpoints.down("1000")]: {
      maxWidth: "227px",
      margin: "37px 36px 62px 36px",
    },
    [theme.breakpoints.down("500")]: {
      maxWidth: "227px",
      margin: "37px 15px",
    },
  },
  icon: {
    marginBottom: "14px",
  },
  cardTitle: {
    marginBottom: "13px",
    fontWeight: 700,
    [theme.breakpoints.down("1000")]: {
      fontSize: "16px",
      marginBottom: "7px",
    },
    [theme.breakpoints.down("450")]: {
      fontSize: "13px",
    },
  },
  cardDesc: {
    [theme.breakpoints.down("1000")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("450")]: {
      fontSize: "10px",
    },
  },
  buttonContainer: {
    textAlign: "center",
  },
  buttonRoot: {
    maxWidth: "347px",
    width: "100%",
    fontSize: "18px !important",
    fontWeight: "500 !important",
    padding: "24px 103px",
    [theme.breakpoints.down("450")]: {
      maxWidth: "238px",
    },
  },
  buttonText: {
    marginRight: "12px",
    whiteSpace: "nowrap",
  },
}));

const WhyHireTeam = (props) => {
  const classes = useStyles();
  const data = {
    title: "Why hire a dedicated development team from FindPro?",
    description:
      "Hiring a dedicated development team from FindPro means access to experienced developers who can enhance your team's capabilities and maximize efficiency. Scale up or down as needed and ensure your development needs are met on time and within budget.",
    reasons: [
      {
        icon: commonImages.stars_icon,
        title: "Domain Expertise",
        description:
          "At FindPro, we have a dedicated team of professionals with diverse domain expertise across various industries. Whether you are in finance, healthcare, e-commerce, or any other industry, our team has the necessary knowledge and skills to understand your specific needs and challenges.",
      },
      {
        icon: commonImages.stars_icon,
        title: "Custom Governance Model",
        description:
          "FindPro offers custom governance models tailored to your specific needs and goals. Our dedicated team works with you to understand your requirements and develop a model that minimizes risk and ensures compliance with laws and regulations. This can help optimize performance and improve your overall governance and compliance posture.",
      },
      {
        icon: commonImages.stars_icon,
        title: "Save Time",
        description:
          " With FindPro, you can quickly and efficiently build your development team without spending precious time and resources on recruitment and training. We have a ready pool of qualified professionals that you can choose from, and we can help you assemble a team that is right for your business needs.",
      },
    ],
  };
  const xsDown = useMediaQuery("(max-width:800px)");
  const smDown = useMediaQuery("(max-width:1000px)");

  const ReasonCard = ({ reasonData }) => {
    return (
      <div className={classes.reasonCardInnerDiv}>
        <img src={reasonData.icon} className={classes.icon} />
        <Typography className={classes.cardTitle}>
          {reasonData.title}{" "}
        </Typography>
        <Typography className={classes.cardDesc}>
          {reasonData.description}{" "}
        </Typography>
      </div>
    );
  };
  return (
    <div className={classes.whyTeamRoot}>
      <div className={classes.whyTeamContainer}>
        <div className={classes.titleDescDiv}>
          <Typography variant="h2" className={classes.title}>
            {data.title}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {data.description}
          </Typography>
        </div>
        <Swiper
          slidesPerView={xsDown ? 1.5 : smDown ? "auto" : 3}
          spaceBetween={xsDown ? 60 : smDown ? 10 : 29}
          grabCursor={true}
          className={classes.swiperContainer}
        >
          {data.reasons.map((reason) => (
            <SwiperSlide className={classes.swiperSlide}>
              <div className={classes.reasonCardOuterDiv}>
                <ReasonCard reasonData={reason} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={classes.buttonContainer}>
          <CustomButton
            type="button2"
            customClasses={clsx([classes.buttonRoot])}
          >
            <span className={classes.buttonText}>Hire a Team</span>{" "}
            <TrendingFlatIcon />{" "}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

WhyHireTeam.propTypes = {};

export default WhyHireTeam;
