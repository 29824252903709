import React from 'react';
import tw from 'twin.macro';

const NotFoundContainer = tw.div`flex items-center justify-center h-screen bg-gray-100`;
const ContentContainer = tw.div`text-center`;

const Title = tw.h1`text-6xl font-bold text-gray-800`;
const Subtitle = tw.h2`text-2xl font-semibold text-gray-600 mb-4`;
const Description = tw.p`text-gray-500`;
const Link = tw.a`text-blue-500 hover:underline mt-4`;

const NotFound = ({ url }) => {
  return (
    <NotFoundContainer>
      <ContentContainer>
        <Title>404</Title>
        <Subtitle>Page Not Found</Subtitle>
        <Description>Oops! The page you are looking for does not exist.</Description>
        <Link href={url}>Go back to Home</Link>
      </ContentContainer>
    </NotFoundContainer>
  );
};

export default NotFound;
