import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import HeroSection from "./HeroSection";
import CompactCardContainer from "./CompactCardContainer";

const useStyles = makeStyles((theme) => ({}));
const Feed = (props) => {
  const classes = useStyles();
  return (
    <div>
      <HeroSection />
      <CompactCardContainer title="Consultants" view="consultant" />
      <CompactCardContainer title="Agencies" view="agency" />
    </div>
  );
};

Feed.propTypes = {};

export default Feed;
