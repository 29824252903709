import { CloseSVG } from "./Close";
import { SearchSVG } from "./Search";
import { ArrowDownSVG } from "./ArrowDown";
import { EditSVG } from "./Edit";
export { 
    CloseSVG, 
    SearchSVG, 
    ArrowDownSVG, 
    EditSVG 
};


export const KanbanSVG = ({
    fillColor = "none",
    className = "",
    ...props
  }) => {
    return (
<svg 
fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="15" height="15" viewBox="0 0 15 15">
<path d="M1.875 2.25V12.75C1.875 12.8495 1.91451 12.9448 1.98483 13.0152C2.05516 13.0855 2.15054 13.125 2.25 13.125H12.75C12.8495 13.125 12.9448 13.0855 13.0152 13.0152C13.0855 12.9448 13.125 12.8495 13.125 12.75V2.25C13.125 2.15054 13.0855 2.05516 13.0152 1.98483C12.9448 1.91451 12.8495 1.875 12.75 1.875H2.25C2.15054 1.875 2.05516 1.91451 1.98483 1.98483C1.91451 2.05516 1.875 2.15054 1.875 2.25ZM3.75 3.75V10V3.75ZM6.25 3.75V5.625V3.75ZM8.75 3.75V8.125V3.75ZM11.25 3.75V6.875V3.75Z" fill="white"/>
<path d="M3.75 3.75V10M6.25 3.75V5.625M8.75 3.75V8.125M11.25 3.75V6.875M1.875 2.25V12.75C1.875 12.8495 1.91451 12.9448 1.98483 13.0152C2.05516 13.0855 2.15054 13.125 2.25 13.125H12.75C12.8495 13.125 12.9448 13.0855 13.0152 13.0152C13.0855 12.9448 13.125 12.8495 13.125 12.75V2.25C13.125 2.15054 13.0855 2.05516 13.0152 1.98483C12.9448 1.91451 12.8495 1.875 12.75 1.875H2.25C2.15054 1.875 2.05516 1.91451 1.98483 1.98483C1.91451 2.05516 1.875 2.15054 1.875 2.25Z" stroke="#0B0E1E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    );
  };

export const DeleteSVG = ({
    fillColor = "none",
    className = "",
    ...props
  }) => {
    return (
<svg 

fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="30" height="30" viewBox="0 0 30 30" >
<path d="M6.75 9H5.25C5.05109 9 4.86032 8.92099 4.71967 8.78033C4.57902 8.63968 4.5 8.44892 4.5 8.25C4.5 8.05109 4.57902 7.86033 4.71967 7.71967C4.86032 7.57902 5.05109 7.5 5.25 7.5H11.25V5.2485C11.25 5.04959 11.329 4.85883 11.4697 4.71817C11.6103 4.57752 11.8011 4.4985 12 4.4985H18C18.1989 4.4985 18.3897 4.57752 18.5303 4.71817C18.671 4.85883 18.75 5.04959 18.75 5.2485V7.5H24.75C24.9489 7.5 25.1397 7.57902 25.2803 7.71967C25.421 7.86033 25.5 8.05109 25.5 8.25C25.5 8.44892 25.421 8.63968 25.2803 8.78033C25.1397 8.92099 24.9489 9 24.75 9H23.25V24.75C23.25 24.9489 23.171 25.1397 23.0303 25.2803C22.8897 25.421 22.6989 25.5 22.5 25.5H7.5C7.30109 25.5 7.11032 25.421 6.96967 25.2803C6.82902 25.1397 6.75 24.9489 6.75 24.75V9ZM17.25 7.5V6H12.75V7.5H17.25ZM8.25 24H21.75V9H8.25V24ZM12.75 21C12.5511 21 12.3603 20.921 12.2197 20.7803C12.079 20.6397 12 20.4489 12 20.25V12.75C12 12.5511 12.079 12.3603 12.2197 12.2197C12.3603 12.079 12.5511 12 12.75 12C12.9489 12 13.1397 12.079 13.2803 12.2197C13.421 12.3603 13.5 12.5511 13.5 12.75V20.25C13.5 20.4489 13.421 20.6397 13.2803 20.7803C13.1397 20.921 12.9489 21 12.75 21ZM17.25 21C17.0511 21 16.8603 20.921 16.7197 20.7803C16.579 20.6397 16.5 20.4489 16.5 20.25V12.75C16.5 12.5511 16.579 12.3603 16.7197 12.2197C16.8603 12.079 17.0511 12 17.25 12C17.4489 12 17.6397 12.079 17.7803 12.2197C17.921 12.3603 18 12.5511 18 12.75V20.25C18 20.4489 17.921 20.6397 17.7803 20.7803C17.6397 20.921 17.4489 21 17.25 21Z" fill="#363636"/>
</svg>
    );
  };

export const DeactivateSVG = ({
    fillColor = "none",
    className = "",
    ...props
  }) => {
    return (
  <svg 
fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="20" height="20" viewBox="0 0 20 20" >
  <path d="M7.22222 9.34505H12.7778C12.9212 9.34505 13.0594 9.41342 13.1616 9.53609C13.2639 9.65882 13.3216 9.82567 13.3216 10C13.3216 10.1743 13.2639 10.3412 13.1616 10.4639C13.0594 10.5866 12.9212 10.6549 12.7778 10.6549H7.22222C7.07875 10.6549 6.94061 10.5866 6.83839 10.4639C6.73611 10.3412 6.67838 10.1743 6.67838 10C6.67838 9.82567 6.73611 9.65882 6.83839 9.53609C6.94061 9.41342 7.07875 9.34505 7.22222 9.34505Z" fill="#363636" stroke="#363636" strokeWidth="0.0234375"/>
  <path d="M10 16.8571C10.9005 16.8571 11.7922 16.6798 12.6241 16.3352C13.4561 15.9906 14.212 15.4855 14.8487 14.8487C15.4855 14.212 15.9906 13.4561 16.3352 12.6241C16.6798 11.7922 16.8571 10.9005 16.8571 10C16.8571 9.09951 16.6798 8.20783 16.3352 7.37589C15.9906 6.54394 15.4855 5.78801 14.8487 5.15127C14.212 4.51452 13.4561 4.00943 12.6241 3.66483C11.7922 3.32022 10.9005 3.14286 10 3.14286C8.18137 3.14286 6.43723 3.8653 5.15127 5.15127C3.8653 6.43723 3.14286 8.18137 3.14286 10C3.14286 11.8186 3.8653 13.5628 5.15127 14.8487C6.43723 16.1347 8.18137 16.8571 10 16.8571ZM10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18Z" fill="#363636"/>
  </svg>

    );
  };
  
export const ShareSVG = ({
    fillColor = "none",
    className = "",
    ...props
  }) => {
    return (
<svg 
fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="12" height="14" viewBox="0 0 12 14">
<path d="M8.65919 11.3995L8.82127 10.9165L8.78838 10.8974L9.04685 10.6693C9.18268 10.5494 9.33311 10.4565 9.50069 10.3889C9.65866 10.3251 9.82376 10.2927 10.0005 10.2923C10.392 10.2924 10.7045 10.4224 10.9747 10.6926C11.2451 10.9629 11.375 11.2756 11.375 11.6673C11.375 12.059 11.2451 12.3717 10.9747 12.642C10.7044 12.9124 10.3917 13.0423 10 13.0423C9.60831 13.0423 9.29562 12.9124 9.02528 12.642C8.75493 12.3717 8.625 12.059 8.625 11.6673C8.625 11.6193 8.62844 11.5662 8.63621 11.5077C8.6431 11.4559 8.65196 11.421 8.65919 11.3995ZM3.17873 7.75148L3.21162 7.77061L2.95315 7.99867C2.8171 8.11872 2.66635 8.21201 2.49836 8.28017C2.34114 8.34395 2.17697 8.37596 2.00109 8.37565H2C1.60831 8.37565 1.29562 8.24572 1.02528 7.97538C0.754927 7.70503 0.625 7.39234 0.625 7.00065C0.625 6.60896 0.754927 6.29627 1.02528 6.02593C1.29562 5.75558 1.60831 5.62565 2 5.62565C2.17624 5.62565 2.3408 5.65787 2.49836 5.7218C2.66669 5.79009 2.81764 5.88338 2.95377 6.00318L3.21204 6.23045L3.17963 6.2493L3.34041 6.73163C3.34795 6.75427 3.35689 6.78985 3.36379 6.8417C3.37159 6.90036 3.375 6.9532 3.375 7.00065C3.375 7.0487 3.37157 7.10178 3.36379 7.16026C3.3569 7.21204 3.34804 7.24693 3.34081 7.26848L3.17873 7.75148ZM9.04747 3.33255L8.78879 3.1037L8.82037 3.08533L8.65959 2.60301C8.65193 2.58002 8.64301 2.54447 8.63616 2.49316C8.62845 2.43556 8.625 2.38261 8.625 2.33398C8.625 1.9423 8.75493 1.62961 9.02528 1.35926C9.29562 1.08891 9.60831 0.958984 10 0.958984C10.3917 0.958984 10.7044 1.08891 10.9747 1.35926C11.2451 1.62961 11.375 1.9423 11.375 2.33398C11.375 2.72567 11.2451 3.03836 10.9747 3.30871C10.7044 3.57906 10.3917 3.70898 10 3.70898C9.82337 3.70898 9.65848 3.67683 9.50069 3.61312C9.33344 3.54558 9.18322 3.45265 9.04747 3.33255Z" fill="#363636" stroke="#363636" strokeWidth="1.25"/>
</svg>
    );
  };

  
export const CheckmarkSVG = ({
    fillColor = "none",
    className = "",
    ...props
  }) => {
    return (
<svg 
fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="10" height="10" viewBox="0 0 10 10">
<path d="M9.42135 -3.53484e-05C9.42125 -3.498e-05 9.4212 1.36465e-05 9.42115 -3.57168e-05L0.56895 -2.12463e-05C0.254743 0.00543141 0.00443839 0.264577 0.00989023 0.578784C0.0152436 0.885328 0.262453 1.13254 0.568948 1.13784L8.04747 1.13788L0.166563 9.01879C-0.0556466 9.241 -0.0556474 9.60133 0.166561 9.82354C0.388769 10.0457 0.749098 10.0457 0.971307 9.82354L8.85222 1.94262L8.85216 9.42115C8.8467 9.73536 9.09701 9.9945 9.41122 9.99995C9.72547 10.0055 9.98467 9.7551 9.99012 9.44089C9.99022 9.43431 9.99022 9.42773 9.99007 9.4211L9.99008 0.568895C9.99018 0.254738 9.73551 6.22731e-05 9.42135 -3.53484e-05Z" fill="white"/>
</svg>
    );
  };

  
export const StarSVG = ({
    fillColor = "none",
    className = "",
    ...props
  }) => {
    return (
<svg 
fill={fillColor}
xmlns="http://www.w3.org/2000/svg"
className={className}
{...props}
width="28" height="28" viewBox="0 0 28 28" >
<path d="M14 10.3833V17.2667L16.8 19.425L15.75 15.8667L18.375 14H15.1083L14 10.3833ZM14 20.1833L9.68333 23.4792C9.46945 23.6542 9.23611 23.7366 8.98334 23.7265C8.73056 23.7172 8.50695 23.6444 8.3125 23.5083C8.11806 23.3722 7.96756 23.1875 7.861 22.9542C7.75367 22.7208 7.74861 22.468 7.84583 22.1958L9.50833 16.8L5.27917 13.7958C5.04584 13.6403 4.9 13.4361 4.84167 13.1833C4.78334 12.9305 4.79306 12.6972 4.87084 12.4833C4.94861 12.2694 5.08472 12.0797 5.27917 11.914C5.47361 11.7491 5.70695 11.6667 5.97917 11.6667H11.2L12.8917 6.06666C12.9889 5.79444 13.1398 5.58521 13.3443 5.43899C13.5481 5.29355 13.7667 5.22083 14 5.22083C14.2333 5.22083 14.4519 5.29355 14.6557 5.43899C14.8602 5.58521 15.0111 5.79444 15.1083 6.06666L16.8 11.6667H22.0208C22.2931 11.6667 22.5264 11.7491 22.7208 11.914C22.9153 12.0797 23.0514 12.2694 23.1292 12.4833C23.2069 12.6972 23.2167 12.9305 23.1583 13.1833C23.1 13.4361 22.9542 13.6403 22.7208 13.7958L18.4917 16.8L20.1542 22.1958C20.2514 22.468 20.2467 22.7208 20.1402 22.9542C20.0328 23.1875 19.8819 23.3722 19.6875 23.5083C19.4931 23.6444 19.2694 23.7172 19.0167 23.7265C18.7639 23.7366 18.5306 23.6542 18.3167 23.4792L14 20.1833Z" fill="#0B0E1E"/>
</svg>
    );
  };



