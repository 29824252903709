import { prodConfigs } from "./prod.config.js";
import { devConfigs } from "./dev.config.js";
import { stageConfigs } from "./stage.config.js";

let configs = devConfigs;

if (process.env.NODE_ENV === "production") {
  configs = prodConfigs;
} else if (process.env.NODE_ENV === "stage") {
  configs = stageConfigs;
}

export default configs;
