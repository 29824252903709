import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";

const useStyles = makeStyles((theme) => ({
  faqRoot: {
    maxWidth: "80vw",
    margin: "auto",
    marginBottom: "186px",
    [theme.breakpoints.down("600")]: {
      maxWidth: "90vw",
      marginBottom: "71px",
    },
  },
  title: {
    fontWeight: 600,
    marginBottom: "73px",
    [theme.breakpoints.down("600")]: {
      fontSize: "1.7rem",
      marginBottom: "37px",
    },
  },
  accordionRoot: {
    boxShadow: "none",
    borderTop: "2px solid #CECECE",
    padding: "22px 0", // 44/2=22px padding set since the text itself has some amount of padding.
    [theme.breakpoints.down("600")]: {
      padding: "11px 0",
    },
  },
  lastAccordion: {
    borderBottom: "2px solid #CECECE",
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  question: {
    [theme.breakpoints.down("1200")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.down("600")]: {
      fontSize: "1rem",
    },
  },
  answer: {
    marginTop: "49px",
    [theme.breakpoints.down("600")]: {
      fontSize: "0.7rem",
      marginTop: "26px",
    },
  },
}));

const FAQ = (props) => {
  const classes = useStyles();
  const faqData = [
    {
      question: "How does FindPro work?",
      answer:
        "FindPro is a cutting-edge hiring platform that makes finding and hiring top talent easy. Our platform allows you to create job listings, search and filter candidates based on your specific requirements, and streamline the recruitment process. Our team of experts is also available to provide support and guidance throughout the hiring process.",
    },
    {
      question: "What types of businesses does FindPro work with?",
      answer:
        "FindPro works with businesses of all sizes, from startups to large corporations. We specialize in a wide range of industries, including technology, healthcare, finance, and more.",
    },
    {
      question: "How does FindPro ensure the quality of candidates?",
      answer:
        "We have a rigorous screening process in place to ensure that we only present top-quality candidates to our clients. This includes skills assessments, background checks, and reference checks. We also use advanced algorithms to match candidates with job listings based on their skills and experience.",
    },
    {
      question: "What is the cost of using FindPro?",
      answer:
        "We offer flexible pricing options to meet the needs of our clients. Our pricing is based on the level of support required and the number of job listings. Contact us today to learn more about our pricing and how we can help you achieve your hiring goals.",
    },
    {
      question:
        "How long does it take to find the right candidate with FindPro?",
      answer:
        "The length of time it takes to find the right candidate with FindPro varies depending on the complexity of the position and the number of candidates that need to be sourced and screened. However, our advanced technology and streamlined processes help to speed up the hiring process and ensure that you find the right candidate as quickly as possible.",
    },
  ];

  const QAComponent = ({ data, number }) => {
    const isLastAccordion = number === faqData.length - 1;
    return (
      <Accordion
        classes={{
          root: clsx([
            classes.accordionRoot,
            isLastAccordion && classes.lastAccordion,
          ]),
        }}
        square={true}
      >
        <AccordionSummary
          expandIcon={<TrendingFlatIcon />}
          aria-controls={`question-content-${number}`}
          id={`question-header-${number}`}
          classes={{
            gutters: classes.gutters,
          }}
        >
          <Typography variant="h5" className={classes.question}>
            {data?.question}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.gutters,
          }}
        >
          <Typography variant="body1" className={classes.answer}>
            {data?.answer}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };
  return (
    <div className={classes.faqRoot}>
      <Typography variant="h3" className={classes.title}>
        FAQ
      </Typography>
      {faqData.map((entry, index) => (
        <QAComponent data={entry} number={index} />
      ))}
    </div>
  );
};

FAQ.propTypes = {};

export default FAQ;
