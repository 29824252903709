// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Box, Slider } from "@mui/material";
// import CustomLabel from "./CustomLabel";
// import { makeStyles } from "@mui/styles";
// import clsx from "clsx";
// import useMediaQuery from "@mui/material/useMediaQuery";

// const useStyles = makeStyles((theme) => ({
//   sliderRoot: {
//     height: "3px",
//     color: theme.palette.common.black,
//     paddingLeft: "4px",
//     touchAction: "pan-y",
//     marginLeft:"15px"
//   },
//   box: {
//     width: "100px",

//   },
// }));

// const MinMaxCustomslider = (props) => {
//   const classes = useStyles();
//   let {
//     id,
//     onChange,
//     label,
//     min,
//     max,
//     selectedRangeOrValue,
//     startText,
//     customClasses,
//     disabled,
//   } = props;
//   const handleChange = (event, newValue) => {
//     onChange(id, newValue);
//   };
//   const valueLabelFormat = (value) => `${startText} ${value}`;

//   React.useEffect(() => {}, []);
//   return (
//     <Box className={classes.box}>
//       {label && <CustomLabel label={label} />}
//       <Slider
//         key={id}
//         value={selectedRangeOrValue} // range for range slider,value for single value slider.
//         valueLabelFormat={startText && valueLabelFormat}
//         onChange={handleChange}
//         valueLabelDisplay="auto" // display min,max selection always
//         min={min}
//         max={max}
//         className={clsx([classes.sliderRoot, customClasses])}
//         disabled={disabled}
//         // classes={{ root:  }}
//       />
//     </Box>
//   );
// };

// MinMaxCustomslider.propTypes = {};

// export default MinMaxCustomslider;

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Slider, Typography, Tooltip } from "@mui/material";
import CustomLabel from "./CustomLabel";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  sliderRoot: {
    height: "3px",
    color: theme.palette.common.black,
    // paddingLeft: "4px",
    touchAction: "pan-y",
    // marginLeft: "15px",
  },
  box: {
    maxWidth: "470px",
    width: "100%",
  },
  valueDisplay: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
}));

const MinMaxCustomslider = (props) => {
  const classes = useStyles();
  const {
    id,
    onChange,
    label,
    min,
    max,
    selectedRange: selectedRangeReceived,
    startText,
    customClasses,
    disabled,
  } = props;

  const [selectedRange, setSelectedRange] = useState([min, max]);

  const handleChange = (event, newValue) => {
    onChange(id, [newValue[0], newValue[1]]);

    setSelectedRange(newValue);
  };

  const valueLabelFormat = (value) => `${startText} ${value}`;
  React.useEffect(() => {
    if (
      isValidArray(selectedRangeReceived) &&
      selectedRangeReceived.length === 2
    )
      setSelectedRange(selectedRangeReceived);
  }, [selectedRangeReceived]);

  return (
    <Box className={classes.box}>
      {label && <CustomLabel label={label} />}
      <Slider
        key={id}
        value={selectedRange}
        valueLabelFormat={startText && valueLabelFormat}
        onChange={handleChange}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        className={clsx([classes.sliderRoot, customClasses])}
        disabled={disabled}
        // classes={{
        //   valueLabel: classes.valueLabel,
        // }}
      />

      <Box className={classes.valueDisplay}>
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600 }}
          variant="caption"
          component="span"
        >
          {`${startText || ""} ${min}`}
        </Typography>
        <Typography
          sx={{ fontSize: "18px", fontWeight: 600 }}
          variant="caption"
          component="span"
        >
          {`${startText || ""} ${max}`}
        </Typography>
      </Box>
    </Box>
  );
};

MinMaxCustomslider.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  selectedRangeOrValue: PropTypes.arrayOf(PropTypes.number), // Use an array for range
  startText: PropTypes.string,
  customClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MinMaxCustomslider;
