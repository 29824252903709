import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({}));

const HeroSection = (props) => {
  const classes = useStyles();
  return <div>HeroSection</div>;
};

HeroSection.propTypes = {};

export default HeroSection;
