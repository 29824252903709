import React from "react";
import { Link } from "react-router-dom";

const FooterComponent = (props) => {
  return (
    <footer {...props} className="site-footer">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <span>
                {" "}
                © Copyright by{" "}
                <Link to={""}>FindPro </Link> All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
