import configs from "../../Pages/Utils/configs";
import fetchWrapper from "../../Pages/Utils/fetchWrapper";

export const getJobDetail = async (jobID) =>
  fetchWrapper(`${configs.APIUrl}/jobs/${jobID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

export const getPublicJobDetail = async (jobID) =>
  fetchWrapper(`${configs.APIUrl}/jobs/info/${jobID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
