import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import JobListCard from "../common/Cards/List/Job";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";

import { messages } from "../../Utils/constants";
import { isValidArray } from "../../Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
    // maxHeight:"300px",
    overflowY: "auto",
    maxHeight: "110vh",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "48px",

    width: "956px",
    boxSizing: "content-box",
    [theme.breakpoints.down("1440")]: {
      width: "900px",
    },
    [theme.breakpoints.down("1385")]: {
      width: "800px",
    },
    [theme.breakpoints.down("1285")]: {
      width: "700px",
    },
    [theme.breakpoints.down("1185")]: {
      width: "600px",
    },
  },
  allJobsText: {
    fontWeight: 600,
    fontSize: "24px",
    marginBottom: "6px",
  },
  jobPostsCountText: {
    fontWeight: 600,
    fontSize: "14px",
    textTransform: "uppercase",
    marginBottom: "5px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
    marginBottom: "5px",
  },
  sortRadioGroupDiv: {
    marginLeft: "24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordion: {
    boxShadow: "none",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "76px",
    rowGap: "5px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));
const JobFeedBody = ({ data, setData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const sortOptions = [
    {
      label: "Relevance",
      value: "Relevance",
    },
    {
      label: "Date Posted",
      value: "Date Posted",
    },
    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          # of Open Positions <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "# of Open Positions",
    },
    {
      label: (
        <div className={classes.sortTextAndIconDiv}>
          # of Applicants <img src="/iconlightarrow-top.svg" />
        </div>
      ),
      value: "# of Applicants",
    },
  ];
  const { experiences, contractTypes } = useSelector((state) => state.misc);
  const employerInfoBuf = useSelector(
    (state) => state?.clientProfileInfo.value
  );

  const [clientData, setClientData] = useState();
  const [postedJobs, setPostedJobs] = useState(data);
  const [sortedAndFilteredPostedJobs, setSortedAndFilteredPostedJobs] =
    useState([]);
  const [currentSortSelectionValue, setCurrentSortSelectionValue] = useState();
  const [statusData, setStatusData] = useState([]);

  const onSortChange = (id, currentValue) => {
    setCurrentSortSelectionValue(currentValue);
  };

  const onStatusFilterChange = (checkboxValue, checkedState) => {
    setStatusData((prevItems) => {
      const updatedItems = prevItems?.map((item) => {
        if (item.value === checkboxValue) {
          return { ...item, checked: checkedState };
        }
        return item;
      });

      return updatedItems;
    });
  };

  const onJobDelete = (id) => {
    setData((jobs) => jobs?.filter((job) => job?._id !== id));
  };

  const isLastJob = (index) => index === sortedAndFilteredPostedJobs.length - 1;

  const filterData = [
    {
      title: "Status",
      component: (
        <CustomCheckboxGroup
          id="status-checkbox-group"
          data={statusData}
          onChange={onStatusFilterChange}
        />
      ),
    },
    // {
    //   title: "Skills",
    //   component: (
    //     <Typography>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //       malesuada lacus ex, sit amet blandit leo lobortis eget.
    //     </Typography>
    //   ),
    // },
    // {
    //   title: "Contract",
    //   component: (
    //     <Typography>
    //       Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    //       malesuada lacus ex, sit amet blandit leo lobortis eget.
    //     </Typography>
    //   ),
    // },
  ];

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  useEffect(() => {
    const statusCounts = postedJobs?.reduce((counts, item) => {
      counts[item.status] = (counts[item.status] || 0) + 1;
      return counts;
    }, {});
    const summaryArray = Object.entries(statusCounts).map(([status, count]) => {
      const previousChecked =
        statusData.find(
          (item) =>
            item.value ===
            `${status.charAt(0).toUpperCase() + status.slice(1)} (${count})`
        )?.checked || false;
      return {
        label: `${status.charAt(0).toUpperCase() + status.slice(1)} (${count})`,
        value: status,
        checked: previousChecked,
      };
    });
    setStatusData(summaryArray);
  }, []);

  useEffect(() => {
    // let sortedAndFilteredData = postedJobs.slice().sort((a, b) => {
    //   if (a.is_pinned && !b.is_pinned) {
    //     return -1; // a comes before b
    //   } else if (!a.is_pinned && b.is_pinned) {
    //     return 1; // b comes before a
    //   }

    //   const statusOrder = {
    //     active: 1,
    //     inactive: 2,
    //     draft: 3,
    //     closed: 4,
    //   };

    //   return statusOrder[a.status] - statusOrder[b.status];
    // });
    let sortedAndFilteredData = postedJobs;
    switch (currentSortSelectionValue) {
      case sortOptions[0].value:
        sortedAndFilteredData = sortedAndFilteredData.slice().sort((a, b) => {
          if (a.is_pinned && !b.is_pinned) {
            return -1;
          } else if (!a.is_pinned && b.is_pinned) {
            return 1;
          }
          const statusOrder = {
            active: 1,
            inactive: 2,
            draft: 3,
            closed: 4,
          };
          return statusOrder[a.status] - statusOrder[b.status];
        });
        break;

      case sortOptions[1].value:
        sortedAndFilteredData = sortedAndFilteredData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case sortOptions[2].value:
        sortedAndFilteredData = sortedAndFilteredData
          .slice()
          .sort((a, b) => b.openPositions - a.openPositions);
        break;
      case sortOptions[3].value:
        sortedAndFilteredData = sortedAndFilteredData
          .slice()
          .sort((a, b) => b.totalApplicants - a.totalApplicants);
        break;
      default:
        break;
    }

    const selectedConnectionStatus = statusData
      .filter((onboardvalue) => onboardvalue.checked === true)
      .map((onboardvalue) => onboardvalue.value);

    if (
      isValidArray(selectedConnectionStatus) &&
      selectedConnectionStatus.length > 0
    ) {
      let filterDataBystatus = sortedAndFilteredData.filter((job) =>
        selectedConnectionStatus.includes(job?.status)
      );
      setSortedAndFilteredPostedJobs(filterDataBystatus);
    } else {
      setSortedAndFilteredPostedJobs(sortedAndFilteredData);
    }
  }, [currentSortSelectionValue, postedJobs, statusData]);

  useEffect(() => {
    setPostedJobs(data);
  }, [data]);

  useEffect(() => {
    if (!employerInfoBuf?.length) {
      dispatch(getEmployerInfoThunk())
        .unwrap()
        .then((res) => {
          setClientData(res?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setClientData(employerInfoBuf);
    }
  }, []);

  return (
    <Grid container>
      <Grid item className={classes.leftView}>
        <Typography className={classes.allJobsText}>All jobs</Typography>
        <Typography className={classes.jobPostsCountText}>
          {sortedAndFilteredPostedJobs?.length ?? 0} total unique jobs posted
        </Typography>
        <div>
          <Typography className={classes.sortOrFilterText}>Sort </Typography>
          <div className={classes.sortRadioGroupDiv}>
            <CustomRadioGroup
              id="sort-radio-group"
              name="sort-radio-group"
              defaultValue={currentSortSelectionValue}
              data={sortOptions}
              onChange={onSortChange}
            />
          </div>
        </div>
        <div>
          <Typography className={classes.sortOrFilterText}>Filter</Typography>
          {filterData.map((filterEntry, idx) => (
            <Accordion key={idx} className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.accordionTitle}>
                  {filterEntry.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{filterEntry.component}</AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Grid>
      <Grid item className={classes.rightView}>
        {sortedAndFilteredPostedJobs?.length ? (
          sortedAndFilteredPostedJobs?.map((postedJob, idx) => (
            <>
              <JobListCard
                key={idx}
                data={postedJob}
                onJobDelete={onJobDelete}
                experiencesData={experiences}
                contractTypesData={contractTypes}
                isProfileComplete={
                  clientData?.clientDetail?.orgDetails
                    ?.isOrganizationProfileComplete
                }
              />
              {!isLastJob(idx) && <hr className="my-[20px]" />}
            </>
          ))
        ) : (
          <div className={classes.loadMoreDiv}>
            {/* <MoreHorizIcon className={classes.moreIcon} fontSize="large" /> */}
            {/* <Typography className={classes.loadMoreText}>Load more</Typography> */}
            <Alert severity="info" className="w-full h-fit mt-[16px]">
              {" "}
              {messages?.NO_DATA_FOUND}{" "}
            </Alert>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

JobFeedBody.propTypes = {};

export default JobFeedBody;
