/** Start- Library imports. */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import ContentLoader from "react-content-loader";
/** End- Library imports. */

/** Start- Components import */
import {
  Typography,
  Link,
  Grid,
  CircularProgress,
  Tooltip,
  Alert,
} from "@mui/material";
import { Line } from "../../../common";
import CustomButton from "app/v2/components/CustomButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import JobOverview from "./JobOverview";
import TinyMceEditor from "app/v2/Pages/common/Editor/TinyMceEditor";
import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import InterviewPanel from "./InterviewPanel";
// import { jobData as dummyJobData } from "./jobdata";
// import fetchWrapper from "app/v2/Pages/Utils/fetchWrapper";
// import configs from "app/v2/Pages/Utils/configs";
// import { isValidObject, isValidArray } from "../../../Utils/utilFunctions";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import DialogLoadingBody from "app/v2/Pages/common/DialogLoadingBody";

/**End- Components import */

/** Start- thunks import */
import {
  createJobThunk,
  getPostedJobsThunk,
  updateJobThunk,
} from "../../../../store/features/employer/jobFeed/jobFeed.thunk";
import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { getEmployerInfoThunk } from "app/v2/store/features/employer/profile-settings/profile-settings.thunk";
/** End- thunks import */

/** Start - Static data,utilFunctions imports */
import { defaultSkillList } from "app/v2/Pages/Utils/constants";
import { jobData as dummyJobData } from "./jobdata";
import { isValidObject, scrollToTop } from "app/v2/Pages/Utils/utilFunctions";
import {
  messages,
  DEFAULT_EXPERIENCE_ID,
  DEFAULT_CONTRACT_ID,
} from "app/v2/Pages/Utils/constants";
import {
  isValidString,
  isValidArrayIndex,
  isValidAlphaNumericID,
} from "app/v2/Pages/Utils/utilFunctions";
/** End - Static data,utilFunctions imports */
import CustomAutocomplete from "app/v2/components/filters/CustomAutocomplete";

import { getJobDetail } from "app/v2/services/job/jobDetail.service";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leftView: {
    padding: "24px 48px",
    width: "291px",
    boxSizing: "content-box",
  },
  cancelDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "35px",
  },
  cancelIcon: {
    cursor: "pointer",
    marginRight: "9px",
    position: "relative",
    right: "5px",
  },
  navItemsDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
    marginBottom: "24px",
  },
  navItemLinkDiv: {
    cursor: "pointer",
  },
  navItemTitle: {
    fontSize: "16px",
    fontWeight: 600,
  },
  selectedNavItem: {
    color: `${theme.palette.common.black} !important`,
  },
  unselectedNavItem: {
    color: `${theme.palette.secondary.light} !important`,
  },
  selectedNavItemForTeamIcon: {
    color: `${theme.palette.primary.main} !important`,
  },
  unselectedNavItemForTeamIcon: {
    color: `${theme.palette.success.main} !important`,
  },
  saveButton: {
    fontSize: "14px",
    fontWeight: 600,
  },
  saveDraftButton: {
    backgroundColor: "#E7E7E7 !important",
    color: `${theme.palette.common.black} !important`,
    marginBottom: "16px",
    width: "100%",
  },
  saveAndPublishButton: {
    width: "100%",
  },
  rightView: {
    borderLeft: "1px solid #EDEDED",
    padding: "0 48px 48px", // setting padding top as o since first navItemComponent has margin top of 48px.
    width: "956px",
    boxSizing: "content-box",
  },
  navItemComponent: {
    margin: "48px 0",
  },
  componentTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  componentDesc: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "48px",
    color: "#ABABAB",
  },
  scrollTopButton: {
    padding: 0,
    marginTop: "100px",
  },
  arrowTopIcon: {
    marginRight: "10px",
  },
  dialogTitle: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  errorMessage: {
    fontSize: "12px",
    color: "red",
  },
  iPSubDivText: {
    fontSize: "18px",
    fontWeight: 600,
    marginBottom: "16px",
  },
  dataTagsDiv: {
    marginBottom: "48px",
  },
  dataTagsDescText: {
    fontSize: "14px",
    marginBottom: "16px",
  },
}));

const JobActionDialog = ({
  isCreateView,
  saveActionType,
  jobActionDialogOpen,
  setJobActionDialogOpen,
  jobActionLoading,
  jobActionCompleted,
  jobActionError,
  jobActionErrorMessage,
  jobSuccessIDReceived,
  jobData,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const onRedirect = () => {
    history.push(
      `/client/job-detail/${
        jobData?._id ? jobData?._id : jobSuccessIDReceived // edit case we will already have job id else we get it in jobSuccessIDReceived.
      }`,
      {
        backURL: "/client/job-feed",
      }
    );
  };

  const saveDialogSuccessBodyData = {
    saveAsDraft: {
      create: {
        dialogTitle: "Draft Job Created",
        content: {
          title: "Your job has been created as a draft.",
        },
        onRedirect,
      },
      edit: {
        dialogTitle: "Draft Job Updated",
        content: {
          title: "Your job has been updated as a draft.",
        },
        onRedirect,
      },
    },
    saveAndPublish: {
      create: {
        dialogTitle: "Job Published",
        content: {
          title: "Your job listing has been saved and is now published",
          desc: "Consultants and Agencies are now able to view your listing publicly and will be able to apply to your post.",
        },
        // actions: {
        //   secondaryAction: {
        //     text: "Find Consultants",
        //     onClick: () => {
        //       history.push("/client/consultant-feed/" + jobData._id);
        //       window.scrollTo(0, 0);
        //     },
        //   },
        //   primaryAction: {
        //     text: "View Job",
        //     onClick: () => {
        //       history.push("/client/job-detail");
        //       window.scrollTo(0, 0);
        //     },
        //   },
        // },
        onRedirect,
      },
      edit: {
        dialogTitle: "Changes Saved",
        content: {
          title: "Your changes have been saved and is now published ",
        },
        // actions: {
        //   primaryAction: {
        //     text: "Done",
        //     onClick: () => {
        //       setJobActionDialogOpen(false);
        //       history.push("/client/job-feed");
        //     },
        //   },
        // },
        onRedirect,
      },
    },
  };

  const saveActionSuccessData =
    saveDialogSuccessBodyData?.[saveActionType] ?? {};
  const successDialogData = isCreateView
    ? saveActionSuccessData?.create
    : saveActionSuccessData?.edit;

  const Title = () => {
    return (
      <Typography className={classes.dialogTitle}>
        {jobActionLoading && "Action in progress"}
        {jobActionError && "Error"}
        {jobActionCompleted && `${successDialogData?.dialogTitle}`}
      </Typography>
    );
  };

  const DialogBody = () => {
    if (jobActionLoading)
      return (
        <DialogLoadingBody
          message={`${isCreateView ? "Creating" : "Updating"} Job${
            saveActionType === "saveAsDraft" ? " as a draft" : ""
          }. Please wait..`}
        />
      );
    else if (jobActionCompleted)
      return (
        <DialogSuccessBody
          content={successDialogData?.content}
          actions={successDialogData?.actions}
          onRedirect={successDialogData?.onRedirect}
        />
      );
    else if (jobActionError)
      return (
        <DialogErrorBody
          content={{
            title: messages.GENERIC_ERROR_MESSAGE,
            desc: jobActionErrorMessage ? jobActionErrorMessage : "",
          }}
        />
      );
    else return <></>;
  };

  return (
    <CustomDialog
      open={jobActionDialogOpen}
      setOpen={setJobActionDialogOpen}
      noCloseAction={jobActionLoading || jobActionCompleted}
      title={<Title />}
      content={<DialogBody />}
    />
  );
};

/**
 * @description Component to create/edit a job.
 *
 * @jobID job id of job to be edited(edit view). If no jobID is passed its a create view.
 */
const CreateOrEditJob = ({ jobID }) => {
  const history = useHistory();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const employerInfoBuf = useSelector(
    (state) => state?.clientProfileInfo.value
  );

  const leftNavData = [
    "Job Overview",
    "Job Description",
    "Skills",
    "Responsibilities",
    // "Interview Panel",
    "Tags",
  ];
  const workModeData = ["On site", "Hybrid", "Remote"];
  // const categoryData = ["A", "B", "C"];
  const isCreateView = Boolean(jobID) === false;

  const currentURL = window.location.href;
  const urlSegments = currentURL.split("/");
  const currentJobID = urlSegments[urlSegments.length - 1];

  const { experiences, contractTypes } = useSelector((state) => state.misc);

  const [onDataTagsChangeCalled, setOnDataTagsChangeCalled] = useState(false); // To show the tags required error message after the user interacts with the element.

  const sectionRefs = useRef([]);
  const [clientData, setClientData] = useState();
  const [jobData, setJobData] = useState({
    jobTitle: "",
    openPositions: 1,
    // experienceLevelId: DEFAULT_EXPERIENCE_ID, // add this instead once the change is done from create/edit job api end as well(to receive this as a string).
    // contractType: DEFAULT_CONTRACT_ID, // add this instead once the change is done from create/edit job api end as well(to receive this as a string).
    experienceLevelId: 0,
    contractType: 0,
    country: "",
    state: "",
    city: "",
    tags: [],
    timezone: "",
    workType: "",
    // category: "",
    salary: {},
    jobDescription: { htmlString: "" }, // on initial load and for any updates to the editor component, 'text' key and its value also gets added to this object(for save button validation- should be on the basis of exact text count and not the html string).
    skillsRequired: defaultSkillList,
    jobResponsibilities: { htmlString: "" }, // on initial load and for any updates to the editor component, 'text' key and its value also gets added to this object(for save button validation- should be on the basis of exact text count and not the html string).
    // hiringManager: "",
  });
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);

  const [selectedNavItemIndex, setSelectedNavItemIndex] = useState(
    "nav-item-component-0"
  );
  const [jobActionDialogOpen, setJobActionDialogOpen] = useState();
  const [allEntriesValid, setAllEntriesValid] = useState(false);
  const [saveActionType, setSaveActionType] = useState(); // saveAndPublish or saveAsDraft.
  const [jobActionLoading, setJobActionLoading] = useState(false);
  const [jobActionCompleted, setJobActionCompleted] = useState(false);
  const [jobActionError, setJobActionError] = useState(false);
  const [jobActionErrorMessage, setJobActionErrorMessage] = useState();
  const [jobIDReceived, setJobIDReceived] = useState();
  /** Start - Function handlers */
  const isCurrentSelectedNavItem = (navItemIndex) => {
    return selectedNavItemIndex === `nav-item-component-${navItemIndex}`;
  };
  const onNavItemClick = (navItemIndex) => {
    const element = sectionRefs?.current[navItemIndex];
    const elementTop =
      element?.getBoundingClientRect().top + window.pageYOffset;
    const container = window;
    const offset = -136;
    container.scrollTo({ top: elementTop + offset, behavior: "smooth" });
  };

  const onJobDataChange = (elementId, value) => {
    // console.log({ elementId, value });
    setJobData((jobData) => ({
      ...jobData,
      [elementId]: value,
    }));
  };

  const onSkillsPickerChange = (skillPickerID, value) => {
    onJobDataChange("skillsRequired", value);
  };
  // const areSkillsSelected = () => {
  //   // const skillsData = jobData?.skills;
  //   const skillsData = jobData?.skillsRequired;
  //   // const defaultSkills = skillsData?.defaultSkills;
  //   // const selectedCustomSkills = skillsData?.customSkills;

  //   // return isValidArray(defaultSkills) || isValidArray(selectedCustomSkills);
  //   return true; // edss
  // };

  const areSalaryDetailsEntered = (salaryDetails) => {
    const salaryRange = salaryDetails?.range;
    const fixedSalary = salaryDetails?.fixedRate;
    let isSalarySelected = isValidObject(salaryDetails);
    if (isSalarySelected) {
      const isValidFixedSalary = !isNaN(fixedSalary);
      const isValidSalaryRange =
        !isNaN(salaryRange?.min) &&
        !isNaN(salaryRange?.max) &&
        salaryRange?.min < salaryRange?.max;
      isSalarySelected &&= isValidFixedSalary || isValidSalaryRange;
    }
    return isSalarySelected;
  };

  const validateEntries = () => {
    const experienceLevelId = jobData?.experienceLevelId;
    const contractTypeId = jobData?.contractType;
    const descriptionText = jobData?.jobDescription?.text;
    const responsibilitiesText = jobData?.jobResponsibilities?.text;
    const dataTags = jobData?.tags;

    // const interviewPanelData = jobData?.interviewPanel;

    // overview section data validation
    const jobTitleExists = jobData?.jobTitle?.length > 0;
    const validOpenPositions = Boolean(jobData?.openPositions);
    const experienceSelected =
      isValidArrayIndex(experienceLevelId, experiences) ||
      isValidAlphaNumericID(experienceLevelId, experiences); // array based index or entry's alphanumeric id.
    const contractSelected =
      isValidArrayIndex(contractTypeId, contractTypes) ||
      isValidAlphaNumericID(contractTypeId, contractTypes); // array based index or entry's alphanumeric id.
    const countrySelected = Boolean(jobData?.country);
    const stateSelected = Boolean(jobData?.state);
    const cityEntered = jobData?.city?.length > 0;
    const timezoneSelected = Boolean(jobData?.timezone);
    const workTypeSelected = Boolean(jobData?.workType);
    // const categorySelected = Boolean(jobData?.category);
    const isSalaryValid = areSalaryDetailsEntered(jobData?.salary);
    const isOverViewSectionValid =
      jobTitleExists &&
      validOpenPositions &&
      experienceSelected &&
      contractSelected &&
      countrySelected &&
      stateSelected &&
      cityEntered &&
      timezoneSelected &&
      workTypeSelected &&
      isSalaryValid;
    // categorySelected -category commented for now hence its validation is not included.

    // description,skills,responsibilities validation
    const isDescriptionValid = descriptionText?.length > 100;
    const skillsSelected = jobData?.skillsRequired?.length > 0;
    const isResponsibilitiesValid = responsibilitiesText?.length > 100;

    // interview panel recruiter's data validation --commented for now.
    // const isRecruiterSelected = Boolean(interviewPanelData?.recruiter);
    const validDataTagsSelectedCount =
      dataTags?.length > 0 && dataTags?.length <= 6;

    // console.log({
    //   jobTitleExists,
    //   validOpenPositions,
    //   experienceSelected,
    //   contractSelected,
    //   countrySelected,
    //   stateSelected,
    //   cityEntered,
    //   timezoneSelected,
    //   workTypeSelected,
    //   isSalaryValid,
    //   isOverViewSectionValid,
    //   isDescriptionValid,
    //   skillsSelected,
    //   isResponsibilitiesValid,
    //   validDataTagsSelectedCount,
    // });
    setAllEntriesValid(
      isOverViewSectionValid &&
        isDescriptionValid &&
        skillsSelected &&
        isResponsibilitiesValid &&
        validDataTagsSelectedCount
    ); // removed interview panel recruiter element's validation for now.
  };

  const onJobActionSuccess = () => {
    setJobActionLoading(false);
    setJobActionCompleted(true);
  };

  const onJobActionError = (errorMessage) => {
    setJobActionLoading(false);
    setJobActionError(true);
    setJobActionErrorMessage(errorMessage);
  };

  const onSaveClick = async (actionType) => {
    const saveAndPublish = actionType === "saveAndPublish";
    const jobStatus = saveAndPublish ? "active" : "draft";

    let updatedJobData = { ...jobData };
    // passing only htmlString and no text value before passing to the api.
    updatedJobData.jobDescription = updatedJobData?.jobDescription?.htmlString;
    updatedJobData.jobResponsibilities =
      updatedJobData?.jobResponsibilities?.htmlString;

    setSaveActionType(actionType);
    setJobActionLoading(true);
    setJobActionDialogOpen(true);

    // setTimeout(() => {
    //   /** Confirm case */
    //   onJobActionSuccess();
    //   /** Error case */
    //   // onJobActionError();
    // }, 10000);

    if (isCreateView) {
      await dispatch(createJobThunk({ ...updatedJobData, status: jobStatus }))
        .unwrap()
        .then((result) => {
          setJobIDReceived(result?.data?.id);
          onJobActionSuccess();
        })
        .catch((error) => {
          onJobActionError(error?.message);
        });
    } else {
      await dispatch(
        updateJobThunk({
          ...updatedJobData,
          status: jobStatus,
          _id: currentJobID,
        })
      )
        .unwrap()
        .then((result) => {
          setJobIDReceived(result?.data?.id);
          onJobActionSuccess();
        })
        .catch((error) => {
          onJobActionError();
        });
    }
  };

  const isValidJobTitle = () => jobData?.jobTitle?.length > 0;
  /** End - Function handlers */

  /** Start - useEffects,useCallback */
  const handleScroll = useCallback(() => {
    const pageYOffset = window.pageYOffset + 136;

    sectionRefs.current.forEach((section, index) => {
      const sectionOffsetTop = section?.offsetTop;
      const sectionHeight = section?.offsetHeight;
      if (
        pageYOffset >= sectionOffsetTop &&
        pageYOffset < sectionOffsetTop + sectionHeight
      )
        setSelectedNavItemIndex(section.id);
    });
  }, [selectedNavItemIndex]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (jobID) {
      // edit mode
      getJobDetail(jobID)
        .then((res) => {
          const jobDataReceived = res?.data;
          if (isValidObject(jobDataReceived)) {
            setJobData({
              ...jobDataReceived,
              jobTitle: jobDataReceived?.jobTitle || "",
              openPositions: jobDataReceived?.openPositions,
              experienceLevelId: jobDataReceived?.experienceLevelId,
              contractType: jobDataReceived?.contractType,
              country: jobDataReceived?.country || "",
              state: jobDataReceived?.state || "",
              city: jobDataReceived?.city || "",
              tags: jobDataReceived?.tags || [],
              timezone: jobDataReceived?.timezone || "",
              workType: jobDataReceived?.workType || "",
              category: jobDataReceived?.category || "",
              salary: jobDataReceived?.salary || {},
              jobDescription: {
                htmlString: jobDataReceived?.jobDescription || "",
              },
              skillsRequired: jobDataReceived?.skillsRequired || [],
              jobResponsibilities: {
                htmlString: jobDataReceived?.jobResponsibilities || "",
              },
              hiringManager: jobDataReceived?.hiringManager || "",
              hiringRole: jobDataReceived?.hiringRole || "",
              hiringRoleName: jobDataReceived?.hiringRoleName || "",

              // interviewPanel: { recruiter: "", dataTags: [] },
            });
          } else {
            setDataError(true);
          }
          setDataLoading(false);
        })
        .catch((error) => {
          setDataLoading(false);
          setDataError(true);
        });
    } else setDataLoading(false); // create mode
  }, []);

  useEffect(() => {
    dispatch(getPostedJobsThunk());
    if (!experiences.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes.length) dispatch(fetchContractTypesThunk());
    if (!employerInfoBuf?.length) {
      dispatch(getEmployerInfoThunk())
        .unwrap()
        .then((res) => {
          setClientData(res?.data);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setClientData(employerInfoBuf);
    }
  }, [dispatch, !currentJobID]);

  useEffect(() => {
    // console.log({ jobData });
    validateEntries();
  }, [jobData]);

  useEffect(() => {
    if (!jobActionDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setJobActionLoading(false);
      setJobActionCompleted(false);
      setJobActionError(false);
    }
  }, [jobActionDialogOpen]);

  /** End - useEffects,useCallback */

  return (
    <>
      <div className={classes.container}>
        {dataLoading ? ( // <CircularProgress />
          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="100" rx="3" ry="3" width="280" height="605" />
            <rect x="335" y="100" rx="3" ry="3" width="1200" height="605" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : (
              <Grid container>
                <Grid item className={classes.leftView}>
                  <div className={classes.cancelDiv}>
                    <ArrowBackIcon
                      className={classes.cancelIcon}
                      onClick={() => {
                        history.goBack();
                      }}
                    />
                    <span>Cancel</span>
                  </div>

                  <div className={classes.navItemsDiv}>
                    {leftNavData?.map((navItemName, index) => (
                      <Link
                        key={index}
                        className={clsx([
                          classes.navItemLinkDiv,
                          isCurrentSelectedNavItem(index)
                            ? classes.selectedNavItem
                            : classes.unselectedNavItem,
                        ])}
                        onClick={() => {
                          onNavItemClick(index);
                        }}
                      >
                        <Typography className={classes.navItemTitle}>
                          {navItemName}
                        </Typography>
                      </Link>
                    ))}
                  </div>
                  <Tooltip
                    arrow
                    title={!isValidJobTitle() && "Please enter a job title."}
                  >
                    <div>
                      <CustomButton
                        customClasses={clsx([
                          classes.saveButton,
                          classes.saveDraftButton,
                        ])}
                        onClick={() => {
                          onSaveClick("saveAsDraft");
                        }}
                        disabled={!isValidJobTitle()}
                      >
                        Save as Draft
                      </CustomButton>
                    </div>
                  </Tooltip>
                  <Tooltip
                    arrow
                    title={
                      clientData?.clientDetail?.orgDetails
                        ?.isOrganizationProfileComplete ? (
                        !allEntriesValid && "Please enter all the details."
                      ) : (
                        <>
                          Please complete all details in the{" "}
                          <span
                            onClick={() => {
                              history.push({
                                pathname: "/client/settings",
                                state: {
                                  parentSettingIndex: 2,
                                  childSettingIndex: 0,
                                },
                              });
                            }}
                            className="cursor-pointer underline text-[#0096FF]"
                          >
                            Organization Details
                          </span>{" "}
                          page.
                        </>
                      )
                    }
                  >
                    <div>
                      <CustomButton
                        type="button1"
                        customClasses={clsx([
                          classes.saveButton,
                          classes.saveAndPublishButton,
                        ])}
                        disabled={
                          !clientData?.clientDetail?.orgDetails
                            ?.isOrganizationProfileComplete || !allEntriesValid
                        }
                        onClick={() => {
                          onSaveClick("saveAndPublish");
                        }}
                      >
                        Save and Publish
                      </CustomButton>
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item className={classes.rightView}>
                  <div
                    className={classes.navItemComponent}
                    id="nav-item-component-0"
                    ref={(el) => (sectionRefs.current[0] = el)}
                  >
                    <Typography className={classes.componentTitle}>
                      Job Overview
                    </Typography>
                    <Typography className={classes.componentDesc}>
                      Provides a concise summary of the position, including key
                      details like job title, location, and employment type.
                    </Typography>
                    <JobOverview
                      id="overview"
                      data={jobData}
                      onChange={onJobDataChange}
                      experiences={experiences}
                      contractTypes={contractTypes}
                      workModeData={workModeData}
                      // categoryData={categoryData}
                    />
                  </div>
                  <Line className="bg-gray-300 h-px w-full" />
                  <div
                    className={clsx([
                      classes.navItemComponent,
                      classes.descriptionDiv,
                    ])}
                    id="nav-item-component-1"
                    ref={(el) => (sectionRefs.current[1] = el)}
                  >
                    <Typography className={classes.componentTitle}>
                      Job Description
                    </Typography>
                    <Typography className={classes.componentDesc}>
                      Offers a detailed account of the primary duties,
                      responsibilities, and expectations associated with the
                      role.
                    </Typography>
                    <TinyMceEditor
                      id="jobDescription"
                      data={jobData?.jobDescription?.htmlString}
                      setData={onJobDataChange}
                      placeholder="Enter job description"
                      requiredCharCount={100}
                      insufficientCharCountMessage="Job description should be atleast 100 characters."
                    />
                  </div>

                  <Line className="bg-gray-300 h-px w-full" />
                  <div
                    className={clsx([
                      classes.navItemComponent,
                      classes.skillsDiv,
                    ])}
                    id="nav-item-component-2"
                    ref={(el) => (sectionRefs.current[2] = el)}
                  >
                    <Typography className={classes.componentTitle}>
                      Skills
                    </Typography>
                    <Typography className={classes.componentDesc}>
                      Rate how well they should fare on each skill below. Fill
                      out or add the relevant skills that apply.
                    </Typography>
                    <SkillsPicker
                      id={`client${isCreateView ? "Create" : "Edit"}JobSP`} // SP - Skills picker
                      data={jobData?.skillsRequired}
                      setData={onSkillsPickerChange}
                    />
                  </div>

                  <Line className="bg-gray-300 h-px w-full" />
                  <div
                    className={clsx([classes.navItemComponent, classes.resDiv])}
                    id="nav-item-component-3"
                    ref={(el) => (sectionRefs.current[3] = el)}
                  >
                    <Typography className={classes.componentTitle}>
                      Responsibilities
                    </Typography>
                    <Typography className={classes.componentDesc}>
                      Outlines the day-to-day tasks and duties that the
                      candidate will be responsible for performing in the role.
                    </Typography>
                    <TinyMceEditor
                      id="jobResponsibilities"
                      data={jobData?.jobResponsibilities?.htmlString}
                      setData={onJobDataChange}
                      placeholder="Enter responsibilities"
                      requiredCharCount={100}
                      insufficientCharCountMessage="Responsibilities should be atleast 100 characters."
                    />
                  </div>

                  <Line className="bg-gray-300 h-px w-full" />
                  <div
                    className={clsx([
                      classes.navItemComponent,
                      classes.interviewPanelDiv,
                    ])}
                    id="nav-item-component-4"
                    ref={(el) => (sectionRefs.current[4] = el)}
                  >
                    {/* Will add this in a later stage */}
                    {/* <Typography className={classes.componentTitle}>
                       Interview Panel
                     </Typography>
                     <Typography className={classes.componentDesc}>
                       Turpis elit placerat vitae tincidunt eu cras. Morbi eu neque
                       ut nuncnisl suspendisse.
                     </Typography>
                     <InterviewPanel
                       id="interviewPanel"
                       // data={jobData?.["interviewPanel"]}
                       data={jobData}
                       // setData={setComponentData}
                     /> */}
                    <div className={classes.dataTagsDiv}>
                      <Typography className={classes.componentTitle}>
                        Data Tags
                      </Typography>
                      {/* <Typography className={classes.iPSubDivText}>
                         Data Tags
                       </Typography> */}
                      <Typography className={classes.dataTagsDescText}>
                        Add more data points to your listing by adding
                        appropriate tags to your job post. Maximum of 6 tags per
                        job only.
                        <br />
                        <b>
                          Note: Press the 'Enter' key to create the tag after
                          entering it.
                        </b>
                      </Typography>

                      <CustomAutocomplete
                        id="tags"
                        data={jobData?.tags}
                        placeholder="Add Tags"
                        defaultSelection={jobData?.tags}
                        allowMultipleSelection={true}
                        allowCustomUserInput={true}
                        onChange={(e, val) => {
                          setOnDataTagsChangeCalled(true);
                          // setAllDataTags(val);
                          setJobData((prevJobData) => {
                            let updatedData = { ...prevJobData, tags: val };
                            return updatedData;
                          });
                        }}
                      />
                      {onDataTagsChangeCalled && (
                        <Typography className={classes.errorMessage}>
                          {/* {allDataTags?.length === 0 && "Please select atleast one tag."} */}
                          {jobData?.tags?.length === 0 &&
                            "Please select atleast one tag."}
                          {/* {allDataTags?.length > 6 && */}
                          {jobData?.tags?.length > 6 &&
                            "Please select upto a maximum of 6 tags."}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <CustomButton
                    onClick={() => {
                      scrollToTop();
                    }}
                    customClasses={classes.scrollTopButton}
                  >
                    <img
                      src={"/iconlightarrow-top.svg"}
                      className={classes.arrowTopIcon}
                    />
                    Back to top
                  </CustomButton>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>

      <JobActionDialog
        isCreateView={isCreateView}
        saveActionType={saveActionType}
        jobActionDialogOpen={jobActionDialogOpen}
        setJobActionDialogOpen={setJobActionDialogOpen}
        jobActionLoading={jobActionLoading}
        jobActionCompleted={jobActionCompleted}
        jobActionError={jobActionError}
        jobActionErrorMessage={jobActionErrorMessage}
        jobSuccessIDReceived={jobIDReceived}
        jobData={jobData}
      />
    </>
  );
};

CreateOrEditJob.propTypes = {};

export default CreateOrEditJob;
