import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Keyboard,
  Scrollbar,
  Navigation,
  Pagination,
  A11y,
  Controller,
  Autoplay,
} from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../images/landingpage";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.common.black,
    marginBottom: "11.5rem",
    [theme.breakpoints.up("1000")]: {
      paddingLeft: "11rem",
    },
    [theme.breakpoints.down("600")]: {
      marginBottom: "3.1rem",
    },
  },
  featuresRoot: {
    maxHeight: "74rem",
    color: theme.palette.common.white,
    fontFamily: theme.typography.fontFamily,
    padding: "6rem 0 9.8rem 19rem",
    [theme.breakpoints.up("1000")]: {
      borderLeft: "1px solid #5F5F5F",
    },
    [theme.breakpoints.down("1400")]: {
      paddingLeft: "10rem",
    },
    [theme.breakpoints.down("1000")]: {
      paddingLeft: "4rem",
    },
    [theme.breakpoints.down("600")]: {
      padding: "4rem 0 5.7rem 4rem",
    },
  },
  title: {
    color: "inherit",
    fontWeight: 600,
    marginBottom: "2.25rem",
    [theme.breakpoints.down("600")]: {
      fontSize: "1.5rem",
      marginBottom: "1.5rem",
    },
  },
  body: {
    display: "flex",
    justifyContent: "space-between",
  },
  description: {
    color: theme.palette.secondary.light,
    maxWidth: "24rem",
    marginBottom: "5.3rem",
    [theme.breakpoints.down("600")]: {
      fontSize: "1rem",
      marginBottom: "1.1rem",
      maxWidth: "15rem",
    },
  },
  globalText: {
    paddingRight: "7.5rem",
    fontSize: "1.1rem",
    fontWeight: 500,
    [theme.breakpoints.down("600")]: {
      display: "none",
    },
  },
  featureImage: {
    position: "relative",
    width: "fit-content",
    maxWidth: "29rem",
    [theme.breakpoints.down("600")]: {
      minWidth: "16rem",
    },
  },
  textOverImage: {
    fontWeight: 600,
    color: "inherit",
    [theme.breakpoints.down("1600")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down("1200")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.down("1000")]: {
      marginTop: "1rem",
      maxWidth: "9.6rem",
    },
  },
  textOverImageBig: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate( -50%, -50% )",
    textAlign: "center",
  },
  arrow: {
    color: theme.palette.common.white,
    cursor: "pointer",
    zIndex: "2",
    position: "absolute",
    top: "50%",
    // transform: "translateY(-50%)",
    border: "1px solid #5F5F5F",
    padding: "25px 20px 20px",
    borderRadius: "50%",
  },
  prevArrow: {
    left: "-175px", // 108px is expected spacing from the swiper, added a bit more considering the width of the arrow since we're absolutely positioning the arrows.
  },
  nextArrow: {
    right: 0,
  },
  swiperOuterContainer: {
    position: "relative",
  },
}));

const Features = (props) => {
  const classes = useStyles();
  const featureImages = [...images.features, ...images.features];
  const featuresText = [
    "AI-powered Recommendation Engine",
    "Search Engine",
    "Notification Engine",
    "AI-powered Recommendation Engine",
    "Search Engine",
    "Notification Engine",
  ];
  const mdUp = useMediaQuery("(min-width:1000px)");
  const smDown = useMediaQuery("(max-width:600px)");
  const swiperRef = useRef(null);

  // Custom arrow components
  const CustomPrevArrow = (props) => (
    <div
      className={clsx([classes.arrow, classes.prevArrow, "custom-prev-arrow"])}
      onClick={props.onClick}
    >
      <ArrowBackIcon />
    </div>
  );
  const CustomNextArrow = (props) => (
    <div
      className={clsx([classes.arrow, classes.nextArrow, "custom-next-arrow"])}
      onClick={props.onClick}
    >
      <ArrowForwardIcon />
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.featuresRoot}>
        <Typography variant="h1" className={classes.title}>
          FEATURES
        </Typography>
        <div className={classes.body}>
          <Typography variant="body1" className={classes.description}>
            With Findpro, you'll have access to a range of powerful features
            that will transform the way you hire and take your business to the
            next level.
          </Typography>
          <Typography className={classes.globalText}>Global</Typography>
        </div>
        <div className={classes.swiperOuterContainer}>
          {/* <CustomPrevArrow
            onClick={() => swiperRef.current.swiper.slidePrev()}
          />
          <CustomNextArrow
            onClick={() => swiperRef.current.swiper.slideNext()}
          /> */}
          <div className={classes.swiperInnerContainer}>
            <Swiper
              slidesPerView={smDown ? 1.1 : 3}
              spaceBetween={5}
              grabCursor={true}
              loop={true}
              keyboard={{
                enabled: true,
              }}
              // navigation={!smDown}
              // navigation={{
              //   nextEl: ".custom-next-arrow",
              //   prevEl: ".custom-prev-arrow",
              // }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={500}
              modules={[
                Keyboard,
                Scrollbar,
                // Navigation,
                Pagination,
                A11y,
                Controller,
                Autoplay,
              ]}
              className="mySwiper"
              ref={swiperRef}
            >
              {featureImages.map((featureImage, index) => (
                <SwiperSlide>
                  <div className={classes.featureImage}>
                    <img src={featureImage} alt={`Feature-image-${index}`} />

                    <Typography
                      variant="h5"
                      className={clsx([
                        classes.textOverImage,
                        mdUp && classes.textOverImageBig,
                      ])}
                    >
                      {featuresText[index]}
                    </Typography>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

Features.propTypes = {};

export default Features;
