import { createSlice } from "@reduxjs/toolkit";
import { getPostedJobsThunk, createJobThunk, updateJobThunk, deleteJobByIdThunk, pinJobThunk } from "./jobFeed.thunk";

const initialState = {
  jobs: [],
  currentJob: {}
};


const manageJobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setCurrentJob: (state, action) => {
      state.currentJob = action.payload
    },

    // setJobPinnedState: (state, action) => {
    //   const { jobID, isPinned } = action.payload;
    //   state.jobs = state.jobs.map((job) =>
    //     job._id === jobID ? { ...job, is_pinned: isPinned } : job
    //   );
    // },

  },
  extraReducers: (builder) => {
    builder
    .addCase(getPostedJobsThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.jobs = action.payload.data;
      }
    })
      .addCase(createJobThunk.fulfilled, (state, action) => {
        state.jobs.push( action.payload );
      })
      .addCase(updateJobThunk.fulfilled, (state, action) => {
        const updatedJob = action.payload;
        const index = state.jobs.findIndex((job) => job.id === updatedJob.id);
        if (index !== -1) {
          state.jobs[index] = updatedJob;
        }
      })
      .addCase(deleteJobByIdThunk.fulfilled, (state, action) => {
        state.jobs = state.jobs.filter((job) => job.id !== action.payload );
      })
      .addCase(pinJobThunk.fulfilled, (state, action) => {
        const jobID = action.meta.arg;
        const isPinned = action.payload.result.message === 'Job pinned successfully!'; 
        state.jobs = state.jobs.map((job) =>
          job._id === jobID ? { ...job, is_pinned: isPinned } : job
        );
        
      })
  },
});

export const { setCurrentJob } = manageJobsSlice.actions;

export default manageJobsSlice.reducer;
