import React, { useState, useEffect, useMemo } from "react";
// import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Signup from "../Pages/common/signup/Signup";
import Login from "../Pages/common/login/Login";

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import clsx from "clsx";

// import ForgotPassword from "../Pages/common/ForgotPassword";
// import Signup from "../Pages/Employer/Signup";
// import Login from "../Pages/Employer/Login";
import Questionnaire from "../Pages/Employer/onboarding/Questionnaire";
import Feed from "../Pages/Employer/Dashboard/Feed";
import DashboardPage from "../Pages/Employer/Dashboard/Dashboard.page";
import ConsultantDetailPage from "../Pages/Employer/ConsultantDetail/ConsultantDetail.page";
import { consultantDetailObj } from "../Pages/Employer/ConsultantDetail/ConsultantDetail.dummy.js";
import JobFeed from "../Pages/Employer/jobfeed/JobFeed";
import ConsultantFeed from "../Pages/Employer/searchresultsandfeed/ConsultantFeed";
import ActiveConsultantFeed from "../Pages/Employer/searchresultsandfeed/ActiveConsultantFeed";
import HiringProcessKanbanBoard from "../Pages/Employer/hiringboard/HiringProcessKanbanBoard";
import Settings from "../Pages/Employer/Settings";
import AgencyFeed from "../Pages/Employer/searchresultsandfeed/AgencyFeed";
import { makeStyles } from "@mui/styles";
import ResetPassword from "../Pages/common/resetpassword/ResetPassword";
import ForgotPassword from "../Pages/common/forgotpassword/ForgotPassword";
import CreateOrEditJob from "../Pages/Employer/jobfeed/createeoreditjob";
import withLocalStorageCheck from "./hoc/withConsultantDataCheck";
import PrivateRoute from "./PrivateRoute";
import SignupEmailVerified from "app/v2/Pages/common/SignupEmailVerified";
import GoogleAuth from "app/common/login/google-auth.component";
import SearchLoader from "../Pages/common/SearchLoader";
import Banner from "../components/Banner";
import ConsultantDetail from "../Pages/Employer/Details/consultant";
import JobDetail from "../Pages/Employer/Details/job";
import AgencyDetail from "../Pages/Employer/Details/agency";
import AccountActivation from "../Pages/common/AccountActivation";
import Connections from "../Pages/Employer/searchresultsandfeed/Connections";
import Favorites from "../Pages/Employer/searchresultsandfeed/Favorites";
import PaymentSuccessPage from "../Pages/Employer/Settings/subscription/PaymentSuccessPage";
import PaymentErrorPage from "../Pages/Employer/Settings/subscription/PaymentErrorPage";

const useStyles = makeStyles((theme) => ({
  bannerAndNavbar: {
    position: "fixed",
    top: 0,
    maxWidth: "1440px", // setting max-width again since the element has fixed position and does not inherit it from parent.
    width: "100%",
    zIndex: 999,
  },
  body: {
    display: "flex",
    flexDirection: "column",
  },
  bodyWithF: {
    minHeight: "calc(100vh - 265px)", // total viewport height minus footer's height(265px).
  }, // F-Footer
  bodyWithBNF: {
    minHeight: "calc(100vh - 399px)", // total viewport height minus banner(34px),navbar(100px),footer's(265px) heights.
    paddingTop: "134px", // fixed banner and navbar's height
  }, // BNF-Banner,Navbar,Footer
  bodyWithNF: {
    minHeight: "calc(100vh - 365px)", // total viewport height minus navbar(100px),footer's(265px) heights.
    paddingTop: "100px", // fixed navbar's height
  }, // NF- Navbar,Footer

  /** In terms of viewport height - work on this later */
  // bodyWithF: {
  //   minHeight: "calc(100vh - 28.9vh)", // total viewport height minus footer's height(265px).
  // }, // F-Footer
  // bodyWithBNF: {
  //   minHeight: "calc(100vh - 3.6vh - 10.8vh - 28.9vh)", // total viewport height minus banner(34px),navbar(100px),footer's(265px) heights.
  //   paddingTop: "134px", // fixed banner and navbar's height
  // }, // BNF-Banner,Navbar,Footer
  // bodyWithNF: {
  //   minHeight: "calc(100vh - 10.8vh - 28.9vh)", // total viewport height minus navbar(100px),footer's(265px) heights.
  //   paddingTop: "100px", // fixed navbar's height
  // }, // NF- Navbar,Footer
}));

const withBannerNavbarFooter = (WrappedComponent) => {
  const ComponentWithBNF = (props) => {
    const classes = useStyles();
    const [showBanner, setShowBanner] = useState(false); // Setting default as false -> Not showing banner for MVP.

    const onBannerClose = () => {
      setShowBanner(false);
    };

    return (
      <>
        <div className={classes.bannerAndNavbar}>
          <Banner showBanner={showBanner} onClose={onBannerClose} />
          <Navbar />
        </div>
        <div
          className={clsx([
            classes.body,
            showBanner ? classes.bodyWithBNF : classes.bodyWithNF,
          ])}
        >
          <WrappedComponent {...props} />
        </div>

        <Footer />
      </>
    );
  };

  return ComponentWithBNF;
};

const withFooter = (WrappedComponent) => {
  const ComponentWithFooter = (props) => {
    const classes = useStyles();
    return (
      <>
        <div className={clsx([classes.body, classes.bodyWithF])}>
          <WrappedComponent {...props} />
        </div>
        <Footer />
      </>
    );
  };

  return ComponentWithFooter;
};

const EmployerRoute = () => {
  const ConsultantDetailView = withBannerNavbarFooter(ConsultantDetail);
  const AgencyDetailView = withBannerNavbarFooter(AgencyDetail);
  const JobDetailView = withBannerNavbarFooter(JobDetail);
  const CreateOrEditJobView = withBannerNavbarFooter(CreateOrEditJob);
  const ConsultantFeedView = withBannerNavbarFooter(ConsultantFeed);
  const HiringBoardView = withBannerNavbarFooter(HiringProcessKanbanBoard);

  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route
          path="/client/sign-up/:inviteeID?"
          component={withLocalStorageCheck(Signup)}
        />
        {/* <Route path="/client/sign-up" component={withFooter(Signup)} /> */}
        <Route
          path="/client/account-activation"
          component={withFooter(AccountActivation)}
        />
        <Route
          path="/client/verify-email/:token"
          component={SignupEmailVerified}
        />
        <Route path={`${path}/login`} component={withFooter(Login)} />
        <Route path="/client/google-auth" component={GoogleAuth} />
        <Route
          path="/client/forgot-password"
          component={withFooter(ForgotPassword)}
        />
        <Route
          path="/client/reset-password/:token"
          component={withFooter(ResetPassword)}
        />
        <Route
          path={"/client/onboarding/:inviteeID?"}
          component={Questionnaire}
        />
        <PrivateRoute
          path={"/client/dashboard"}
          component={withBannerNavbarFooter(DashboardPage)}
        />
        <PrivateRoute // we may not need this route
          path={"/client/consultant-feed"}
          component={withBannerNavbarFooter(ConsultantFeed)}
        />
        <PrivateRoute //consultants by the posted job
          path={"/client/:jobID/consultants"}
          render={(props) => <ConsultantFeedView props={props} />}
        />
        <PrivateRoute
          path={"/client/consultants"}
          component={withBannerNavbarFooter(ConsultantFeed)}
        />
        <PrivateRoute
          path={"/client/active-consultant-feed"}
          component={withBannerNavbarFooter(ActiveConsultantFeed)}
        />
        <PrivateRoute
          path={"/client/connections"}
          component={withBannerNavbarFooter(Connections)}
        />
        <PrivateRoute
          path={"/client/favorites"}
          component={withBannerNavbarFooter(Favorites)}
        />
        <PrivateRoute
          path={"/client/agency-feed"}
          component={withBannerNavbarFooter(AgencyFeed)}
        />
        <PrivateRoute
          path={"/client/job-feed"}
          component={withBannerNavbarFooter(JobFeed)}
        />{" "}
        <PrivateRoute //consultant detail by consultantID - This won't display "Job Post" information on the page
          path={"/client/consultant/:candidate_id"}
          render={(props) => <ConsultantDetailView props={props} />}
        />
        <PrivateRoute
          path={"/client/:jobID/consultant/:candidate_id"}
          render={(props) => <ConsultantDetailView props={props} />}
        />
        <PrivateRoute
          path={"/client/agency-detail"}
          render={(props) => <AgencyDetailView />}
        />
        <PrivateRoute
          path={"/client/job-detail/:jobID"}
          render={(props) => <JobDetailView props={props} />}
        />
        <Route path={"/client/loader"} render={(props) => <SearchLoader />} />
        <PrivateRoute
          path={"/client/hiring-board/:jobID"}
          render={(props) => (
            <HiringBoardView
              jobID={props?.match?.params?.jobID}
              // jobID={"64c6821f825d60123ff5fa8e"}
            />
          )}
        />{" "}
        <PrivateRoute
          path={"/client/settings"}
          component={withBannerNavbarFooter(Settings)}
        />
        <PrivateRoute
          path={"/client/create-job"}
          render={(props) => <CreateOrEditJobView />}
        />
        <PrivateRoute
          path={"/client/edit-job/:jobID"}
          render={(props) => (
            <CreateOrEditJobView jobID={props?.match?.params?.jobID} />
          )}
        />
        <PrivateRoute
          path="/client/subscription-payment-success"
          component={withBannerNavbarFooter(PaymentSuccessPage)}
        />
        <PrivateRoute
          path="/client/subscription-payment-error"
          component={withBannerNavbarFooter(PaymentErrorPage)}
        />
        <Redirect
          from="/client/help"
          to={{
            pathname: "/client/settings",
            state: { parentSettingIndex: 3 },
          }} // help & support view's index.
        />
        {/*

  return (
    // <BrowserRouter basename="/v2/employer">
      <div>
        <Switch>
          <Route path="/client/sign-up" exact component={Signup} />
          <Route path={`${path}/login`} exact component={Login} />
          <Route path="/client/forgot-password" component={ForgotPassword} />
          <Route path={"/client/onboarding"} component={Questionnaire} />
          <Route path={"/client/dashboard"} component={DashboardPage} />
          <Route path={"/client/consultant-feed"} component={ConsultantFeed} />
          <Route path={"/client/consultants"} component={ConsultantFeed} />
          <Route
            path={"/client/active-consultant-feed"}
            component={ActiveConsultantFeed}
          />
          <Route path={"/client/agency-feed"} component={AgencyFeed} />
          <Route path={"/client/job-feed"} component={JobFeed} /> 
          <Route
            path={"/client/consultant/:candidate_id"}
            render={(props) => <DetailsPage props={props} view="consultant" />}
          />
          <Route
            path={"/client/agency-detail"}
            render={(props) => <DetailsPage props={props} view="agency" />}
          />
          <Route
            path={"/client/job-detail"}
            render={(props) => <DetailsPage props={props} view="job" />}
          />
          <Route path={"/client/hiring-board"} component={HiringProcessKanbanBoard} />{" "}
          <Route path={"/client/profile-settings"} component={Settings} />
*/}
        {/* <Route
            path={"/profile-view"}
            render={(props) => (
              <ConsultantDetailPage {...consultantDetailObj} />
            )}
          /> */}
      </Switch>
    </div>
  );
};

export default EmployerRoute;
