import { createSlice } from "@reduxjs/toolkit";
import { profileInfo, updateProfilePictureThunk } from "./profile-settings.thunk";

const initialState = {
  value: {
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    personal_info: {},
    profile_picture: "",
  },
};

const profileSettingsSlice = createSlice({
  name: "vendor_info",
  initialState,
  reducers: {
    vendorInfoIn: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
    vendorInfoOut: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(profileInfo.fulfilled, (state, action) => {
      state.value = action.payload;
    }).addCase(updateProfilePictureThunk.fulfilled, (state, action) => {
      state.value.profile_picture = action.meta.arg.profile_picture;
    });
  },
});

// Action creators are generated for each case reducer function
export const { vendorInfoIn, vendorInfoOut } = profileSettingsSlice.actions;

export default profileSettingsSlice.reducer;
