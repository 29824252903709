import storageUtil from "../../../../../app/v2/utils/storageUtil";

const fetchWrapper = (path, options, onProgress) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      body:
        options?.body instanceof FormData
          ? options?.body
          : JSON.stringify(options?.body),
      method: options?.method || "GET",
      headers: options?.headers || {},
      credentials: "include",
    };
    const parseJSON = (response) => {
      return new Promise((resolv, rej) =>
        response
          .json()
          .then((json) => {
            resolv({
              status: response.status,
              ok: response.ok,
              json,
            });
          })
          .catch((err) => {
            rej(response);
          })
      );
    };

    const fetchWithProgress = (url, options) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(options.method || "GET", url);

        for (const header in options.headers) {
          xhr.setRequestHeader(header, options.headers[header]);
        }

        xhr.onload = () => resolve(xhr);
        xhr.onerror = () => reject(xhr);

        if (onProgress && xhr.upload) {
          xhr.upload.onprogress = onProgress;
        }

        xhr.send(options.body || null);
      });
    };

    // fetchWithProgress(path, requestOptions)
    //   .then((xhr) => {
    //     const response = {
    //       status: xhr.status,
    //       ok: xhr.status >= 200 && xhr.status < 300,
    //       json: function () {
    //         return Promise.resolve(JSON.parse(xhr.responseText));
    //       },
    //     };
    //     return parseJSON(response);
    //   })
    fetch(path, requestOptions)
      .then(parseJSON)

      .then((response) => {
        if (response.ok) {
          return resolve(response.json);
        }
        if (response.status === 401) {
          storageUtil.setItem("user", undefined);
          window.location.reload();
          return reject({
            message: "Your login session expired. Please login again",
          });
        }
        return reject(response.json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default fetchWrapper;
