import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    maxWidth: "317px",
    width: "100%",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    overflowX: "auto",
    width: "269px",
    paddingBottom: "10px",
  },

  hideActions: {
    display: "none",
  },
  sectionHeadingText: {
    fontSize: "10px",
    // color: theme.palette.secondary.light,
    fontfamily: "Plus Jakarta Sans",
    fontWeight: 600,
    color: "#717171",
  },
  userImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const Grid = ({ data, jobID }) => {
  const history = useHistory();
  const classes = useStyles();
  // const cardData = {
  //   image: commonImages.user_profile,
  //   name: "Lacy Clark",
  //   role: "Sr. Product Owner",
  //   matchRate: "86%",
  //   availability: "Part Time",
  //   level: "Expert",
  //   location: "Singapore",
  //   bio: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
  //   skills: ["React", "NodeJS", "AngularJS"],
  // };
  const cardData = {
    image: data?.profile_picture,
    name: data?.name,
    role: data?.profile_title,
    matchRate: "-",
    availability: "Part Time",
    level: "Expert",
    location: data?.state,
    bio: "bio",
    skills: data?.skills?.map((skill) => skill?.skill),
  };
  // const cardData = data;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="h-[440px] flex items-center">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={clsx([
          classes.cardContainer,
          " flex flex-col p-6 items-start justify-center gap-[19px] text-left text-3xs text-neutral-500 font-button-text-button-10 border-[1px] border-solid border-neutral-50",
        ])}
      >
        <div className="self-stretch flex flex-row items-start justify-start gap-[19px] text-lg text-black h-fit">
          <img
            className={classes.userImage}
            alt={`"user_${cardData?._id}_image"`}
            src={cardData?.image ? cardData.image : "/user_placeholder.svg"}
          />
          <div className="flex flex-col items-start justify-start gap-[2px]">
            <div className="relative leading-[130%] font-semibold inline-block w-[150px]">
              {/* {cardData?.name} */}
              Lacy Clark
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 inline-block w-[150px]">
              {/* {cardData?.role} */}
              Sr.Product Owner
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px]">
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-800">
            <div className="text-gray-700 text-xs font-jakarta-sans font-normal leading-5 mt-[-5px]">
              Active 3 hours Ago
            </div>
            <div
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              AVAILABILITY
            </div>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-center">
              {cardData?.availability}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-center">
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Level
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.level}
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-800 mt-[-8px]">
            <div className="relative uppercase font-semibold inline-block w-[53px]"></div>
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Location
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {/* {cardData?.location} */}
              New York
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-xs leading-[136%] font-poppins [display:-webkit-inline-box] overflow-auto text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
          {/* {cardData?.bio} */}
          With a strong foundation in computer science and extensive experience
          in software development, I excel at architecting software solutions
          across the entire stack. My expertise includes designing and
          implementing robust backend systems, crafting intuitive user
          interfaces, and optimizing performance for seamless user experiences
        </div>
        <div className="self-stretch flex flex-col items-start justify-center gap-[8px] text-center">
          <div
            className={clsx([classes.sectionHeadingText, "relative uppercase"])}
          >
            Skills
          </div>

          <div
            className={clsx([
              classes.skillsChipsDiv,
              "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
            ])}
          >
            {cardData?.skills?.map((skillName) => (
              <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                <div className="relative leading-[136%] font-medium">
                  {/* {skillName} */}
                  React JS
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={clsx([
            "self-stretch flex flex-row items-start justify-start gap-[19px]",
            !isHovered && classes.hideActions,
          ])}
        >
          <button
            // onClick={() => {
            //   if (jobID) {
            //     history.push(`/client/${jobID}/consultant/${data._id}`);
            //   } else {
            //     history.push(`/client/consultant/${data._id}`);
            //   }
            //   // window.scrollTo(0, 0);
            // }}
            onClick={() => {
              history.push("/agency/consultant-detail");
            }}
            className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 flex-1 h-10 flex flex-row box-border items-center justify-center gap-[10px]"
          >
            <div className="relative text-sm font-semibold font-button-text-button-10 text-neutral-white text-center whitespace-nowrap">
              View Profile{" "}
            </div>
            <img
              className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src="/iconlightup-right.svg"
            />
          </button>
          {/* <button className="cursor-pointer [border:none] p-2.5 bg-neutral-900 h-10 flex flex-row box-border items-center justify-center">
            <img
              className="relative w-5 h-5 overflow-hidden shrink-0"
              alt=""
              src="/iconlightbookmark.svg"
            />
          </button> */}
        </div>
      </div>
    </div>
  );
};

Grid.propTypes = {};

export default Grid;
