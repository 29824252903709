import configs from "configs";
import fetchWrapper from "../../common/utils/fetchWrapper";

export const bulkUploadCandidates = (candidatesList) => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
        method: "POST",
        body: { users: candidatesList },
    };
    return fetchWrapper(`${configs.APIUrl}/misc/bulk-upload`, options);
};

export const getBulkCandidates = () => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
        method: "get"
    };
    return fetchWrapper(`${configs.APIUrl}/misc/bulk-upload/candidates`, options);
};

export const updateBulkListCandidate = (userInfo) => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
        method: "put",
        body: userInfo
    };
    return fetchWrapper(`${configs.APIUrl}/misc/bulk-upload/update-candidate`, options);
};

export const resendInvite = (userInfo) => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        body: userInfo
    };
    return fetchWrapper(`${configs.APIUrl}/misc/bulk-upload/resend-invite`, options);
};

export const deleteUser = ({user_id}) => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
        method: "delete"
    };
    return fetchWrapper(`${configs.APIUrl}/misc/bulk-upload/${user_id}`, options);
};

export const applyJobForBulkCandidates = (payload) => {
    const options = {
        headers: {
            "content-type": "application/json",
        },
        method: "POST",
        body: { ...payload },
    };
    return fetchWrapper(`${configs.APIUrl}/vendors/apply-job-multiple-user`, options);
};