import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from './rootReducer';

const store = configureStore({
	reducer: appReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}),
});

export default store;
