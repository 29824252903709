import { useSnackbar } from "notistack";
import React, { useState,useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { forgotPassword } from "./forgotpassword.service";
import { Form } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import images from "app/v2/images/common";
import clsx from "clsx";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "603px",
    margin: "auto",
    padding: "120px 60px",
    boxSizing: "content-box",
  },
  logo: {
    width: "156px",
    height: "42px",
    marginBottom: "48px",
  },
  title: {
    fontSize: "36px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  element: {
    boxSizing: "border-box",
    border: 0,
    borderBottom: " 0.7px solid #717171",
    padding: "12px 0 12px 2px !important",
  },
  emailDiv: {
    padding: "16px",
    marginBottom: "72px",
  },
  emailBackground: {
    backgroundColor: "#F7F7F7",
  },
  emailLabel: {
    marginBottom: "12px",
    fontSize: "14px",
    fontWeight: 700,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
  },
  actionButton: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
    fontSize: "14px",
    fontWeight: 600,
    border: "1px solid #EDEDED",
  },
  backButton: {
    padding: "10px 20px 10px 15px",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  submitButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: "10px 15px 10px 20px",
    "&:disabled": {
      backgroundColor: "grey !important",
      borderColor: "#EDEDED !important",
    },
  },
  passwordResetText: {
    fontSize: "14px",
    fontWeight: 700,
    marginBottom: "48px",
    padding: "16px 0",
  },
  newForgotPasswordLink: {
    color: theme.palette.secondary.light,
    cursor: "pointer",
  },
  backToSignInLink: {
    fontSize: "14px",
    color: theme.palette.primary.main,
  },
}));

const ForgotPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [formValidated, setFormValidated] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [viewType, setViewType] = useState("forgotPassword");
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    const data = new FormData(event.target);
    if (!formValidated) setFormValidated(true);
    if (form.checkValidity() === false) return;
    // setViewType("passwordResetMailSent");
    forgotPassword({
      email: data.get("email"),
    })
      .then((res) => {
        form.reset();
        setViewType("passwordResetMailSent");
      })
      .catch((err) => {
        form.reset();
        enqueueSnackbar(
          err.message,
          {
            variant: "error",
          }
        );
        console.error(err);
      });
  };

  useEffect(() => {
    const isSettingsPage = history.location.pathname === '/client/settings' || '/consultant/settings';

    document.title = isSettingsPage ? 'Change Password' : 'Forgot Password';
  }, [history]);

  

  const checkIsFormValid = () => {
    const form = document.getElementById("reset-form");
    if (!formValidated) return false;
    //return !form?.checkValidity();
    setIsFormValid(form?.checkValidity());
  };
  const viewTypes = {
    forgotPassword: {
      title: document.title ,
      body: (
        <Form
          id="reset-form"
          noValidate
          validated={formValidated}
          onSubmit={handleSubmit}
        >
          <Form.Group
            className={clsx([classes.emailBackground, classes.emailDiv])}
            controlId="formFirstName"
          >
            <Form.Label className={classes.emailLabel}>
              Enter your email address to receive a password reset prompt.
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              name="email"
              required
              onChange={() => checkIsFormValid()}
              className={clsx([classes.element, classes.emailBackground])}
            />
            <Form.Control.Feedback type="invalid">
              Invalid Email address
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className={classes.actions}>
            <button
              onClick={() => {
                history.goBack();
              }}
              className={clsx([classes.actionButton, classes.backButton])}
            >
              <ArrowBackIcon />
              Back
            </button>
            <button
              type="submit"
              disabled={!isFormValid && formValidated}
              className={clsx([classes.actionButton, classes.submitButton])}
            >
              Submit
              <ArrowForwardIcon />
            </button>
          </Form.Group>
        </Form>
      ),
    },
    passwordResetMailSent: {
      title: "Password Reset Email Sent",
      body: (
        <>
          <div className={classes.passwordResetText}>
            We’ve sent an email to your email address. Follow the directions in
            the email to reset your password. If it doesn’t appear within a few
            minutes, check your spam folder or{" "}
            <span
              className={classes.newForgotPasswordLink}
              onClick={() => {
                setFormValidated(false);
                setIsFormValid(false);
                setViewType("forgotPassword");
              }}
            >
              click here to request a new link.
            </span>
          </div>
          <Link className={classes.backToSignInLink} to="/client/login">
            Back to Sign in
          </Link>
        </>
      ),
    },
  };

  return (
    <div className={classes.container}>
      <img src={images?.logo_v2} className={classes.logo} />
      <Typography className={classes.title}>
        {viewTypes[viewType].title}
      </Typography>
      <>{viewTypes[viewType].body}</>
    </div>
  );
};

export default ForgotPassword;
