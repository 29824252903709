import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Button, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  body: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px",
    textAlign: "center",
  },
  actionsDiv: {
    display: "flex",
    columnGap: "36px",
    justifyContent: "center",
  },
  button: {
    width: "261px",
    padding: "10px 28px",
    fontSize: "14px",
    fontWeight: 600,
    // "&:disabled": {
    //   width: "261px",
    //   padding: "10px 28px",
    // },
  },
  primaryButton: {
    backgroundColor: ` ${theme.palette.common.black} !important`,
    color: ` ${theme.palette.common.white} `,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    columnGap: "12px",
    "&:disabled": {
      backgroundColor: ` ${theme.palette.common.black} !important`,
    },
  },
  secondaryButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    border: "1px solid #EDEDED",
    "&:disabled": {
      backgroundColor: `${theme.palette.common.white} !important`,
      color: `${theme.palette.common.black} !important`,
      border: "1px solid #EDEDED",
    },
  },
  loadingIcon: {
    width: "20px !important",
    height: "20px !important",
  },
}));

/**
 * @component - Common dialog confirm body component.
 * @param {import("react").ReactComponentElement} body

 */
const DialogConfirmBody = ({
  body,
  onCancelClick,
  onConfirmClick,
  deleteLoading,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      {body}
      <div className={classes.actionsDiv}>
        {onCancelClick && (
          <Button
            className={clsx([classes.button, classes.secondaryButton])}
            onClick={onCancelClick}
            disabled={deleteLoading}
          >
            Cancel
          </Button>
        )}
        {onConfirmClick && (
          <Button
            className={clsx([classes.button, classes.primaryButton])}
            onClick={onConfirmClick}
            disabled={deleteLoading}
          >
            Confirm
            {deleteLoading && (
              <CircularProgress className={classes.loadingIcon} />
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

DialogConfirmBody.propTypes = {};

export default DialogConfirmBody;
