import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import TravelExploreIcon from "@mui/icons-material/TravelExplore";

import CustomLabel from "app/v2/components/filters/CustomLabel";
import EmployeeListCard from "../common/Card/Employee/List";

import { IconDot } from "app/v2/Pages/SVGs/IconDot";
import { Typography } from "@mui/material";

import { isValidArray, isValidString } from "../../Utils/utilFunctions";
import { URL_PATTERN } from "../../Utils/constants";

const useStyles = makeStyles((theme) => ({
  headerSection1: {
    display: "flex",
    columnGap: "24px",
    alignItems: "center",
    marginBottom: "24px",
  },
  orgImage: {
    width: "120px",
    height: "120px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
  hS1SubSection: {
    display: "flex",
    flexDirection: "column",
    rowGap: "12px",
    flex: 2,
  }, // hS1 - header section 1
  updatedCreatedDiv: {
    display: "flex",
    columnGap: "8px",
  },
  updatedCreatedText: {
    fontSize: "12px",
    color: "#717171",
  },
  companyName: {
    fontSize: "36px",
    fontWeight: 600,
  },
  empNoSocialProofDiv: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
  },
  empNoText: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#8C8C8C",
  },
  socialProofDiv: {
    display: "flex",
    columnGap: "12px",
    alignItems: "center",
  },
  socialProofText: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  socialIconsDiv: {
    display: "flex",
    columnGap: "12px",
  },
  socialIcon: {
    minWidth: "24px",
    width: "24px !important",
    height: "24px !important",
    cursor: "pointer",
  },
  sectionDiv: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: "24px",
    columnGap: "12px",
  },
  sectionElement: {
    minWidth: "187px",
    flex: 1,
  },
  sectionElementTitle: {
    fontSize: "12px",
    fontWeight: 600,
    color: "#717171",
  },
  sectionElementValue: {
    fontSize: "16px",

    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  iframeDiv: {
    padding: "16px",
    backgroundColor: "#F7F7F7",
  },
  iframeTitle: {
    fontSize: "24px",
    fontWeight: 600,
  },

  iframe: {
    // fontSize: "16px",
    // color: "#717171", // Not working - check later
    width: "100%",
  },
  sectionTitle: {
    fontSize: "32px",
    fontWeight: 600,
    marginBottom: "24px",
  },
  locationsOrEmployeesDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "24px",
  },
  hr: {
    margin: 0,
  },
}));

const Status = ({ type }) => {
  const statusTypeInfo = {
    active: {
      text: "Active",
      textColor: "#14C042",
      dotColor: "#14C042",
      divColor: "#EBFFF0",
    },
    inactive: {
      text: "Inactive",
      textColor: "#4b5563",
      dotColor: "#4b5563",
      divColor: "#F9FAFB",
    },
    // closed: {
    //   text: "Closed",
    //   textColor: "#1E5DFF",
    //   dotColor: "#1E5DFF",
    //   divColor: "#F4F8FF",
    // },
    // draft: {
    //   text: "Draft",
    //   textColor: "#717171",
    //   divColor: "#F4F8FF",
    //   borderColor: "#717171",
    // },
  };
  const statusInfo = statusTypeInfo[type];

  return (
    <>
      {statusInfo ? (
        <div
          style={{ backgroundColor: statusInfo?.divColor }} // divColor addition in className below is not working.
          className={`flex gap-x-[5px] items-center px-[8px] bg-[${
            statusInfo?.divColor
          }] ${
            statusInfo?.borderColor &&
            `border-[1px] border-solid border-[${statusInfo?.borderColor}]`
          }`}
          // className={`flex gap-x-[5px] items-center px-[8px] ${
          //   statusInfo?.borderColor &&
          //   `border-[1px] border-solid border-[${statusInfo?.borderColor}]`
          // }`}
        >
          {statusInfo?.dotColor && <IconDot fillColor={statusInfo?.dotColor} />}
          <Typography
            style={{
              color: statusInfo?.textColor,
              fontSize: "12px",
              fontWeight: 700,
            }}
          >
            {statusInfo?.text}
          </Typography>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const SectionElement = ({ title, value }) => {
  const classes = useStyles();
  return (
    <>
      {title ? (
        <div className={classes.sectionElement}>
          <div className={classes.sectionElementTitle}>{title}</div>
          <div className={classes.sectionElementValue} title={value}>
            {value ? value : "-"}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const getLocationString = (locationObject) => {
  const locationArray = [
    locationObject?.country,
    locationObject?.state,
    locationObject?.city,
  ].filter(Boolean);
  const locationString = locationArray.join(",");

  return locationString;
};

export const ClientHeader = ({ data }) => {
  const classes = useStyles();

  const socialLinks = data?.socialLinks;
  const companyLocations = data?.companyLocation;

  const section2ElementsData = [
    { title: "HIRED APPLICANTS", value: data?.hiredApplicants },
    { title: "ACTIVE JOBS POSTED", value: data?.activeJobs },
    { title: "COMPANY INDUSTRY", value: data?.industry },
    { title: "COMPANY SIZE", value: data?.companySize },
    { title: "WEBSITE", value: data?.socialLinks?.website },
    {
      title: "SUBSCRIPTION",
      value: data?.subscription?.status,
    },
    {
      title: "LOCATION",
      value: getLocationString(companyLocations?.[0]),
    },
    { title: "TIMEZONE", value: companyLocations?.[0]?.timezone },
  ];

  let linkedinURL, facebookURL, websiteURL, createdAt, updatedAt;

  createdAt = data?.createdAt ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  updatedAt = data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;

  if (socialLinks?.linkedin) {
    linkedinURL = URL_PATTERN.test(socialLinks?.linkedin)
      ? socialLinks?.linkedin
      : "https://" + socialLinks?.linkedin;
  }
  if (socialLinks?.facebook) {
    facebookURL = URL_PATTERN.test(socialLinks?.facebook)
      ? socialLinks?.facebook
      : "https://" + socialLinks?.facebook;
  }
  if (socialLinks?.website) {
    websiteURL = URL_PATTERN.test(socialLinks?.website)
      ? socialLinks?.website
      : "https://" + socialLinks?.website;
  }

  return (
    <div>
      <div className={classes.headerSection1}>
        <img
          src={data?.profilePhoto ? data.profilePhoto : "/org_placeholder.svg"}
          className={classes.orgImage}
          alt={`org_${data?._id}_image`}
        />
        <div className={classes.hS1SubSection}>
          {/* 1 */}
          <div className="flex justify-between">
            <div className={classes.updatedCreatedDiv}>
              <span className={classes.updatedCreatedText}>
                Last updated {updatedAt}
              </span>
              <span className={classes.updatedCreatedText}>
                User since {createdAt}
              </span>
            </div>
            <Status type="active" />
          </div>
          {/* 2 */}
          <div className={classes.companyName}>{data?.company}</div>
          {/* 3 */}
          <div className={classes.empNoSocialProofDiv}>
            <div className={classes.empNoText}>
              Employer No <span className="text-[#363636]">2345566</span>
            </div>
            <div className={classes.socialProofDiv}>
              <div className={classes.socialProofText}>SOCIAL PROOF</div>
              {linkedinURL || facebookURL || websiteURL ? (
                <div className={classes.socialIconsDiv}>
                  {linkedinURL && (
                    <a
                      href={linkedinURL ?? ""}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="/linkedin-logo-black.png"
                        alt="linkedin-logo"
                        className={classes.socialIcon}
                      />
                    </a>
                  )}
                  {facebookURL && (
                    <a href={facebookURL} target="_blank" rel="noreferrer">
                      <img
                        src="/facebook-logo-black.svg"
                        alt="facebook-logo"
                        className={classes.socialIcon}
                      />
                    </a>
                  )}
                  {websiteURL && (
                    <a href={websiteURL} target="_blank" rel="noreferrer">
                      <TravelExploreIcon className={classes.socialIcon} />
                    </a>
                  )}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.sectionDiv}>
        {section2ElementsData.map((elementInfo, index) => (
          <SectionElement
            title={elementInfo?.title}
            value={elementInfo?.value}
            key={`headerSectionElement-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export const Subscription = ({ data }) => {
  const classes = useStyles();

  let createdAt, updatedAt;

  createdAt = data?.createdAt ? moment(data?.createdAt).fromNow() : "-";
  createdAt = createdAt === "Invalid date" ? "-" : createdAt;
  updatedAt = data?.updatedAt ? moment(data?.updatedAt).fromNow() : "-";
  updatedAt = updatedAt === "Invalid date" ? "-" : updatedAt;

  const sectionElementsData = [
    { title: "Plan ID", value: data?.planId },
    { title: "Status", value: data?.status },
    { title: "Transaction ID", value: data?.transactionId },
    { title: "Expiry", value: data?.expiry },
    { title: "Subscription ID", value: data?.subscriptionId },
    { title: "Autopay", value: data?.autoPay },
    { title: "Billing Cycle", value: data?.billing_cycle },
    { title: "Created At", value: createdAt },
    { title: "Updated At", value: updatedAt },
  ];
  return (
    <div>
      <div className={classes.sectionTitle}>Subscription Details</div>
      <div className={classes.sectionDiv}>
        {sectionElementsData.map((elementInfo, index) => (
          <SectionElement
            title={elementInfo?.title}
            value={elementInfo?.value}
            key={`headerSectionElement-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

const LocationDataTile = ({ data }) => {
  const timezone = data?.timezone;
  const locationArray = [data?.country, data?.state, data?.city].filter(
    Boolean
  );
  const locationString = locationArray.join(",");

  return (
    <div className="flex flex-col gap-y-[8px] pl-[24px] border-l-[1px] border-solid border-l-[#717171]">
      {locationString ? (
        <div className="text-[16px] font-bold">{locationString}</div>
      ) : (
        <></>
      )}

      {timezone ? (
        <div className="text-[14px] font-semibold uppercase text-[#717171]">
          {timezone}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export const Locations = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.sectionTitle}>Locations</div>
      {isValidArray(data) ? (
        <div className={classes.locationsOrEmployeesDiv}>
          {data.map((locationEntry, index) => (
            <>
              <LocationDataTile data={locationEntry} />
              {index !== data.length - 1 && <hr className={classes.hr} />}
            </>
          ))}
        </div>
      ) : (
        <>-</>
      )}
    </div>
  );
};

export const Employees = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.sectionTitle}>Employees</div>
      {isValidArray(data) ? (
        <div className={classes.locationsOrEmployeesDiv}>
          {data.map((employeeEntry, index) => (
            <>
              <EmployeeListCard data={employeeEntry} />
              {index !== data.length - 1 && <hr className={classes.hr} />}
            </>
          ))}
        </div>
      ) : (
        <>-</>
      )}
    </div>
  );
};

export const IframeSection = ({ title, htmlString }) => {
  const classes = useStyles();
  return (
    <>
      {title ? (
        <div className={classes.iframeDiv}>
          <div className={classes.iframeTitle}>{title}</div>
          <>
            {isValidString(htmlString) ? (
              <iframe
                srcDoc={htmlString}
                className={classes.iframe}
                title={title}
              ></iframe>
            ) : (
              "-"
            )}
          </>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
