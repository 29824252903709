import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { Typography, Button } from "@mui/material";
import CustomDialog from "app/v2/components/CustomDialog";
import DialogConfirmBody from "app/v2/Pages/common/DialogConfirmBody";
import DialogSuccessBody from "app/v2/Pages/common/DialogSuccessBody";
import DialogErrorBody from "app/v2/Pages/common/DialogErrorBody";
import { messages } from "../../Utils/constants";
import { isFunction } from "formik";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    width: "558px",
    paddingBottom: "48px",
    boxSizing: "content-box",
    display: "flex",
    flexDirection: "column",
    rowGap: "33px",
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
  },
  deleteConfirmationText: {
    fontSize: "24px",
    fontWeight: 600,
  },
  jobDetailText: {
    fontSize: "18px",
    fontWeight: 600,
  },
  jobName: {
    fontWeight: 700,
  },
  actionsDiv: {
    display: "flex",
    columnGap: "36px",
    justifyContent: "center",
  },
  button: {
    width: "261px",
    padding: "10px 28px",
    fontSize: "14px",
    fontWeight: 600,
  },
  deleteButton: {
    backgroundColor: "#FFECEA !important",
    color: "#D80027",
  },
  cancelButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    border: "1px solid #EDEDED",
  },
}));

const DeleteJobDialog = ({
  open,
  setOpen,
  jobDetails,
  onDeleteClick,
  deleteLoading,
  deleteCompleted,
  deleteError,
  onSuccessRedirect,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const onCancelClick = () => {
    setOpen(!open);
  };

  const Title = () => {
    return (
      <Typography className={classes.title}>
        {deleteCompleted
          ? "Job Deleted"
          : deleteError
          ? "Error"
          : "Delete Job Confirmation"}
      </Typography>
    );
  };

  const Content = () => {
    return (
      <div className={classes.contentContainer}>
        <Typography className={classes.deleteConfirmationText}>
          Are you sure you want to delete this job?
        </Typography>
        <Typography className={classes.jobDetailText}>
          {" "}
          Job no {jobDetails?.jobId} :{" "}
          <span className={classes.jobName}>{jobDetails?.jobName}</span>
        </Typography>
        <div className={classes.actionsDiv}>
          <Button
            className={clsx([classes.button, classes.cancelButton])}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            className={clsx([classes.button, classes.deleteButton])}
            onClick={onDeleteClick}
          >
            Confirm and Delete
          </Button>
        </div>
      </div>
    );
  };

  const DialogBody = () => {
    if (deleteCompleted)
      return (
        <DialogSuccessBody
          content={{ title: "Job Deleted Successfully!" }}
          onRedirect={onSuccessRedirect}
        />
      );
    else if (deleteError)
      return (
        <DialogErrorBody
          content={{ title: messages.GENERIC_ERROR_MESSAGE, desc: "" }}
        />
      );
    else
      return (
        <DialogConfirmBody
          body={
            <div className={classes.contentContainer}>
              <Typography className={classes.deleteConfirmationText}>
                Are you sure you want to delete this job?
              </Typography>
              {/* <Typography className={classes.jobDetailText}>
                {" "}
                Job no {jobDetails?.jobNo} :{" "}
                <span className={classes.jobName}>{jobDetails?.jobName}</span>
              </Typography> */}
            </div>
          }
          onCancelClick={onCancelClick}
          onConfirmClick={onDeleteClick}
          deleteLoading={deleteLoading}
        />
      );
  };

  return (
    <div>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        noCloseAction={deleteLoading || isFunction(onSuccessRedirect)}
        title={<Title />}
        content={<DialogBody />}
      />
    </div>
  );
};

DeleteJobDialog.propTypes = {};

export default DeleteJobDialog;
