import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./common/login/login.slice";
import profileSettingsSlice from "./candidate/myaccount/profile-settings/profile-settings.slice";
import profileSettingsSliceVendor from "./vendor/myaccount/profile-settings/profile-settings.slice";
import resumeSettingsSlice from "./candidate/myaccount/resume-settings/resume-settings.slice";
import loaderSlice from "./common/loaderSlice";
import manageJobsSlice from "./employer/myjobs/manage-jobs/manage-jobs.slice";
import employerProfileSettings from "./employer/myaccount/profile-settings/profile-settings.slice";
import jobsSlice from "./employer/myjobs/manage-jobs/jobs.slice";
import activeConsultantsslice from "./v2/store/features/consultant/ActiveConsultantSlice"

export default configureStore({
  reducer: {
    loader: loaderSlice,
    login_info: loginSlice,
    candidate_info: profileSettingsSlice,
    resume_settings: resumeSettingsSlice,
    manage_jobs: manageJobsSlice,
    employer_info: employerProfileSettings,
    job_details: jobsSlice,
    vendor_info: profileSettingsSliceVendor,
    activeConsultant_info:activeConsultantsslice
  },
});
