import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { Typography, Button } from "@mui/material";
import { isFunction } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles((theme) => ({
  body: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px 84px",
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "33px",
  },
  successIconDiv: {
    width: "76px",
    height: "76px",
    padding: "14.1px",
    borderRadius: "100px",
    backgroundColor: "#EBFFF0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  contentTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "12px",
  },
  contentDesc: {
    fontSize: "14px",
    fontWeight: 400,
  },
  actionsDiv: {
    display: "flex",
    columnGap: "36px",
    justifyContent: "center",
  },
  button: {
    width: "261px",
    padding: "10px 28px",
    fontSize: "14px",
    fontWeight: 600,
  },
  primaryButton: {
    backgroundColor: "#EBFFF0 !important",
    color: "#14C042",
  },
  secondaryButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    border: "1px solid #EDEDED",
  },
}));

/**
 * @component - Common dialog success body component.
 * @param {object} content Object denoting body's structure
 * structure -
 * {
 * title:"",
 * desc:""
 * }
 * @param {object} actions Object denoting actions structure.
 * structure -
 * actions:{
 * primaryAction:{
 *  text:"",
 *  onClick:()=>{}
 * },
 * secondaryAction:{
 *  text:"",
 *  onClick:()=>{}
 * }
 * @param {function} onRedirect If this function handler is passed (actions are not required in this case), the dialog is displayed for few seconds and then its executed.
 * }
 */
const DialogSuccessBody = ({ content, actions, onRedirect = null }) => {
  const classes = useStyles();
  const isPrimaryActionPresent = Boolean(actions?.primaryAction);
  const isSecondaryActionPresent = Boolean(actions?.secondaryAction);
  const onRedirectPresent = isFunction(onRedirect);
  const [redirectTimer, setRedirectTimer] = useState(3); // for 3 seconds

  if (onRedirectPresent) {
    // Wait for 3 second and then redirect to the specified URL
    setTimeout(() => {
      if (redirectTimer > 0) {
        setRedirectTimer((prevState) => prevState - 1);
      }
    }, 1000);

    setTimeout(() => {
      onRedirect();
    }, 3000); // milliseconds
  }

  return (
    <div className={classes.body}>
      <div className={classes.content}>
        <div className={classes.successIconDiv}>
          <img src="/success-check.svg" />
        </div>
        <Typography className={classes.contentTitle}>
          {content?.title}
        </Typography>
        {content?.desc && (
          <Typography className={classes.contentDesc}>
            {content?.desc}
          </Typography>
        )}
      </div>
      {onRedirectPresent ? (
        <div className="font-semibold">Redirecting in {redirectTimer}</div>
      ) : (
        <div className={classes.actionsDiv}>
          {isSecondaryActionPresent && (
            <Button
              className={clsx([classes.button, classes.secondaryButton])}
              onClick={actions?.secondaryAction?.onClick}
            >
              {actions?.secondaryAction?.text}
            </Button>
          )}
          {isPrimaryActionPresent && (
            <Button
              className={clsx([classes.button, classes.primaryButton])}
              onClick={actions?.primaryAction?.onClick}
            >
              {actions?.primaryAction?.text}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

DialogSuccessBody.propTypes = {};

export default DialogSuccessBody;
