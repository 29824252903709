import React from "react";
import PropTypes from "prop-types";

import SkillsPicker from "app/v2/Pages/common/SkillsPicker";
import SectionTitleAndIcon from "./SectionTitleAndIcon";

const Skills = ({ data, setSkillsData }) => {
  return (
    <div className="py-[36px] border-b-[1px] border-solid border-[#EDEDED]">
      <SectionTitleAndIcon title="Skills *" iconPath="/file.svg" />
      <div className="p-[16px]">
        <SkillsPicker
          id="consultantProfileSettingsEditSP" // SP - Skills Picker
          data={data}
          setData={setSkillsData}
        />
      </div>
    </div>
  );
};

Skills.propTypes = {};

export default Skills;
