import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Typography, Button, CircularProgress } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import { messages } from "app/v2/Pages/Utils/constants";

const useStyles = makeStyles((theme) => ({
  body: {
    boxSizing: "content-box",
    minWidth: "486px",
    padding: "48px 84px",
    textAlign: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "33px",
  },
  errorIcon: {
    width: "48px",
    height: "48px",
    color: "#9b0000",
  },
  errorIconDiv: {
    width: "76px",
    height: "76px",
    padding: "14.1px",
    borderRadius: "100px",
    backgroundColor: "#ffa1a1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "24px",
  },
  contentTitle: {
    fontSize: "24px",
    fontWeight: 600,
    marginBottom: "12px",
  },
  contentDesc: {
    fontSize: "14px",
    fontWeight: 400,
  },
  actionsDiv: {
    display: "flex",
    columnGap: "36px",
    justifyContent: "center",
  },
  button: {
    width: "261px",
    padding: "10px 28px",
    fontSize: "14px",
    fontWeight: 600,
  },
  primaryButton: {
    backgroundColor: "#ffa1a1 !important",
    color: "#9b0000",
  },
  actionButton: {
    backgroundColor: `${theme.palette.common.white} !important`,
    color: `${theme.palette.common.black} !important`,
    border: "1px solid #EDEDED",
  },
}));

/**
 * @component - Common dialog loading body component.
 * @param {object} content Object denoting body's structure
 * structure -
 * {
 * title:"",
 * desc:""
 * }
 */
const DialogLoadingBody = ({ message }) => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <div className={classes.content}>
        <div className="flex flex-col gap-x-[12px] justify-center items-center">
          <CircularProgress className="mb-[12px]" />
          <div>{message ?? messages.ACTION_LOADING_MESSAGE}</div>
        </div>
      </div>
    </div>
  );
};

DialogLoadingBody.propTypes = {};

export default DialogLoadingBody;
