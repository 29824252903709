import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
    name: 'globalLoader',
    initialState:{
        value: false,
        isLoadingRequestIds: []
    },
    reducers: {
        toggleLoader: (state, action) => {
            state.value = action.payload
        },
        enableLoader: (state, action) => {
            if (!state.isLoadingRequestIds.includes(action.payload))
              state.isLoadingRequestIds = [
                ...state.isLoadingRequestIds,
                action.payload,
              ];
        },
        disableLoader: (state, action) => {
          state.isLoadingRequestIds.splice(
            state.isLoadingRequestIds.indexOf(action.payload),
            1
          );
        }
    }
});

export const {toggleLoader, enableLoader, disableLoader} = loaderSlice.actions;

export default loaderSlice.reducer