import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  matchType: {
    fontSize: "14px",
    fontWeight: 600,
  },
  highMatchType: {
    color: "#1D30B5",
  },
  fairMatchType: {
    color: "#618EFF",
  },
  lowMatchType: {
    color: "#A8C1FF",
  },
  greyBg: {
    backgroundColor: "#F7F7F7",
  },
}));
/**
 * @description Common component for match rate.
 * @param {String} matchType valid match types -> High,Fair,Low.
 * @param {Boolean} withGreyBg To show a grey background or not.
 * @returns
 */
const MatchRate = ({ matchType, withGreyBg = false }) => {
  const classes = useStyles();
  const matchTypeStyle = {
    High: classes.highMatchType,
    Fair: classes.fairMatchType,
    Low: classes.lowMatchType,
  };
  const validMatchTypes = ["High", "Fair", "Low"];
  return (
    <div
      className={`w-[129px] h-[28px] box-content p-[8px] flex flex-col gap-y-[2px] ${
        withGreyBg ? classes.greyBg : ""
      }`}
    >
      <div
        className={`flex gap-x-[6px] items-center text-[12px] text-[#717171] ${
          withGreyBg ? "font-bold" : "font-semibold"
        } `}
      >
        MATCH RATE <img src="/iconoirdoublecheck.svg" />
      </div>
      <div className={clsx([classes.matchType, matchTypeStyle?.[matchType]])}>
        {/* {validMatchTypes.includes(matchType) ? matchType : "-"} */}
        {matchType ? matchType : "-"}
      </div>
    </div>
  );
};

MatchRate.propTypes = {};

export default MatchRate;
