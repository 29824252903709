import CustomSelect from "app/v2/components/filters/CustomSelect";
import React, { useState } from "react";
import { useEffect } from "react";
import Modal from "./Modal";
import {
  addConsultantTimesheet,
  getConsultantTimesheet,
  updateStatusOfConsultantTimesheet,
} from "app/v2/services/client/timesheet.service";
import { isValidArray } from "../../Utils/utilFunctions";
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
  Tooltip,
  Typography,
} from "@mui/material";
import { messages } from "../../Utils/constants";
import CustomButton from "app/v2/components/CustomButton";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { activeConsultants } from "app/v2/services/client/consultants.service";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomDateRangePicker from "app/v2/components/filters/CustomDateRangePicker";
import ContentLoader from "react-content-loader";

const useStyles = makeStyles((theme) => ({}));
const Timesheet = (details) => {
  const classes = useStyles();

  const [tasks, setTasks] = useState([
    {
      description: "Project X Development",
      date: "2024-02-01",
      startTime: "09:00",
      endTime: "17:00",
      hours: "8.00",
    },
    {
      description: "Client Meeting",
      date: "2024-02-02",
      startTime: "14:00",
      endTime: "15:30",
      hours: "1.50",
    },
  ]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(false);
  const [updateListApi, setUpdateListApi] = useState(false);

  const consultantId =
    details?.details?.drawerDetails?.contractActivity?.consultantDetails?._id;
  const jobDetails =
    details?.details?.drawerDetails?.contractActivity?.jobDetails || [];
  const jobId =
    details?.details?.drawerDetails?.contractActivity?.jobDetails?._id;

  // const jobId = jobDetails.map(job => job?._id);

  const [timesheetTypesData, setTimesheettypesData] = useState({});
  const appNo = details?.details?.drawerDetails?.contractActivity?.appNo;
  const [newTask, setNewTask] = useState({
    jobId: jobId,
    consultantId: consultantId,
    description: "",
    date: "",
    startTime: "",
    endTime: "",
    hours: "",
    timesheetType: "",
    amount: 0,
    dueDate: "",
  });
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [TimeSheetType, setTimeSheetType] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchValues = async () => {
      activeConsultants().then((res) => {
        if (res?.data !== "undefined") {
          let receivedJobData = res?.data;

          const filterActiveConsultantData = receivedJobData
            ?.filter((data) => data?.consultantDetails?._id === consultantId)
            .filter((filterdata) => filterdata?.appNo === appNo);

          setTimesheettypesData(
            filterActiveConsultantData?.[0]?.settings?.timesheetSettings
              ?.consultant
          );
        }
      });
    };
    fetchValues();
  }, []);

  useEffect(() => {
    // Calculate the start date (Monday) and end date (Sunday) of the current week
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay();
    const diff =
      currentDate.getDate() -
      currentDayOfWeek +
      (currentDayOfWeek === 0 ? -6 : 1);
    const startOfWeek = new Date(currentDate.setDate(diff));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);

    // Fetch data for the current week
    setUpdateListApi((prev) => !prev);
  }, []);

  useEffect(() => {
    setDataLoading(true);
    const request = {
      jobId: jobId,
      consultantId: consultantId,
      limit: 10,
      offset: 0,
      startDate: startDate,
      endDate: endDate,
    };

    getConsultantTimesheet(request)
      .then((res) => {
        if (res?.success) {
          setDataLoading(false);
          // setTasks(res?.data?.timesheet?.["pay-as-you-go"]);
          setTasks(res?.data?.timesheet);
        } else {
          setTasks([]);
        }
      })
      .catch(() => {
        setDataLoading(false);
        setDataError(true);
      });
  }, [updateListApi]);

  useEffect(() => {
    if (timesheetTypesData) {
      const typeMapping = {
        payasyougo: "Pay-as-you-go",
        milestone: "Milestone",
        fixedrate: "Fixed Rate",
      };

      const filteredOptions = Object.keys(timesheetTypesData)
        .filter((type) => timesheetTypesData[type])
        .map((key) => typeMapping[key]);

      // Object.keys(timesheetTypesData)
      // .filter(key => timesheetTypesData[key] === true)
      // .map(key => typeMapping[key]);
      // .map(selectedType => typeMapping[selectedType]);

      setTimeSheetType(filteredOptions);
    }
  }, [timesheetTypesData]);

  useEffect(() => {
    const requiredFieldsMap = {
      "Pay-as-you-go": ["description", "date", "startTime", "endTime", "hours"],
      Milestone: ["description", "dueDate"],
      "Fixed Rate": ["description", "date"],
    };

    const requiredFields = requiredFieldsMap[newTask.timesheetType] || [];

    const areRequiredFieldsFilled = requiredFields.every(
      (field) => newTask[field]
    );

    setIsSubmitDisabled(!areRequiredFieldsFilled);
  }, [
    newTask.timesheetType,
    newTask.description,
    newTask.date,
    newTask.startTime,
    newTask.endTime,
    newTask.hours,
    newTask.dueDate,
  ]);

  useEffect(() => {
    calculateTime();
  }, [newTask.startTime, newTask.endTime, newTask.hours]);

  const calculateTime = () => {
    const { startTime, endTime, hours } = newTask;

    if ((startTime && endTime && !hours) || (startTime && endTime && hours)) {
      const start = new Date(`2024-01-01 ${startTime}`);
      const end = new Date(`2024-01-01 ${endTime}`);

      const milliseconds =
        end < start ? end - start + 24 * 60 * 60 * 1000 : end - start;

      const hours = milliseconds / (1000 * 60 * 60);

      setNewTask((prevTask) => ({
        ...prevTask,
        hours: hours.toFixed(2),
      }));
    } else if (startTime && hours && !endTime) {
      const start = new Date(`2024-01-01 ${startTime}`);
      const end = new Date(
        start.getTime() + parseFloat(hours) * 60 * 60 * 1000
      );
      const newEndTime = end.toTimeString().slice(0, 5);

      setNewTask((prevTask) => ({
        ...prevTask,
        endTime: newEndTime,
      }));
    }
  };

  const handleInputChange = (name, value) => {
    // const { name, value } = e.target;

    const updatedName =
      newTask?.timesheetType === "Milestone" && name === "date"
        ? "dueDate"
        : name;

    setNewTask((prevTask) => ({
      ...prevTask,
      [updatedName]: value,
    }));

    if (name === "hours" && newTask.startTime) {
      const start = new Date(`2024-01-01 ${newTask.startTime}`);
      const end = new Date(
        start.getTime() + parseFloat(value) * 60 * 60 * 1000
      );
      const newEndTime = end.toTimeString().slice(0, 5);

      setNewTask((prevTask) => ({
        ...prevTask,
        endTime: newEndTime,
        [name]: value,
      }));
    } else if (name === "startTime") {
      setNewTask((prevTask) => ({
        ...prevTask,
        [name]: value,
        hours: 0,
      }));
    } else {
      setNewTask((prevTask) => ({
        ...prevTask,
        [name]: value,
      }));
    }
  };

  const onChangeOfCreateTask = (name, value) => {
    setNewTask((prevValues) => {
      let newTaskData = {
        ...prevValues,
        [name]: value,
        jobId: jobId,
        consultantId: consultantId,
        description: "",
        date: format(new Date(), "yyyy-MM-dd"),
        startTime: format(new Date(), "HH:mm"),
        endTime: "",
        hours: 0,
        amount: 0,
      };
      return newTaskData;
    });
  };

  useEffect(() => {
    onChangeOfCreateTask();
  }, [newTask.timesheetType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // setTasks([...tasks, newTask]);
    // setNewTask((previnfo) => ({
    //   ...previnfo,
    //   description: "",
    //   date: "",
    //   startTime: "",
    //   endTime: "",
    //   hours: 0,
    //   timesheetType: "",
    //   amount: 0,
    // }));

    const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });

    addConsultantTimesheet(newTask)
      .then((res) => {
        if (res.success) {
          const key = enqueueSnackbar(`Your action has been successfull.`, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setUpdateListApi((prev) => !prev);
          setNewTask({
            jobId: jobId,
            consultantId: consultantId,
            description: "",
            date: "",
            startTime: "",
            endTime: "",
            hours: 0,
            timesheetType: "",
            amount: 0,
            dueDate: "",
          });
        }
      })
      .catch(() => {
        const key = enqueueSnackbar(
          `Your action was not succesfull. Please try again later.`,
          {
            variant: "error",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      });
  };

  const handleReviewClick = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const handleAcceptReview = () => {
    setIsModalOpen(false);
  };

  const handleRejectReview = () => {
    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onSubmitOfReview = (reviewStatus) => {
    const key = enqueueSnackbar(`Your action in progress.Please wait.  `, {
      variant: "info",
      autoHideDuration: 3000,
      action: (key) => (
        <CloseIcon
          onClick={() => closeSnackbar(key)}
          style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
        />
      ),
    });

    updateStatusOfConsultantTimesheet(reviewStatus)
      .then((res) => {
        if (res?.success === true) {
          const key = enqueueSnackbar(res?.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setUpdateListApi((prev) => !prev);
        } else if (res.success === false) {
          const key = enqueueSnackbar(res?.message, {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          });
          setUpdateListApi((prev) => !prev);
        }
      })
      .catch((res) => {
        const key = enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);

    if (date && endDate && date > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleSubmitOftimesheetRange = () => {
    setUpdateListApi((prev) => !prev);
  };

  const resetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setUpdateListApi((prev) => !prev);
  };

  return (
    <div className="m-[20px] space-y-8">
      <CustomSelect
        id="timesheetType"
        name="timesheetType"
        data={TimeSheetType}
        // label="Time Sheet  Type"
        placeholder={"Timesheet type"}
        defaultSelection={newTask.timesheetType}
        onChange={onChangeOfCreateTask}
        // defaultSelection={onboardingStatus}
      />
      <div className="w-full">
        {newTask?.timesheetType === "Pay-as-you-go" && (
          <div className="flex flex-column gap-4">
            <form
              onSubmit={handleSubmit}
              className="flex items-center space-x-4"
            >
              <label className="w-full">
                {/* Task Description: */}
                <CustomTextfield
                  id="description"
                  type="text"
                  name="description"
                  placeholder="Enter task description"
                  className="border w-full p-2"
                  value={newTask?.description}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label>
                {/* Date: */}
                <CustomTextfield
                  id="date"
                  type="date"
                  name="date"
                  className="border p-2"
                  value={newTask?.date}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label>
                {/* Start Time: */}
                <CustomTextfield
                  id="startTime"
                  type="time"
                  name="startTime"
                  className="border p-2"
                  value={newTask?.startTime}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label>
                {/* End Time: */}
                <CustomTextfield
                  id="endTime"
                  type="time"
                  name="endTime"
                  className="border p-2"
                  value={newTask?.endTime}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label>
                <CustomTextfield
                  id="hours"
                  type="string"
                  name="hours"
                  className="border p-2 w-4/5 sm:w-1/3 lg:w-1/4 xl:w-1/5"
                  value={newTask?.hours}
                  onChange={handleInputChange}
                />
              </label>
            </form>
            <CustomButton
              onClick={handleSubmit}
              type="button1"
              customClasses="px-4 py-2 mb-2 mx-auto !important "
              disabled={isSubmitDisabled}
            >
              Submit
            </CustomButton>
          </div>
        )}
        {newTask?.timesheetType === "Milestone" && (
          <div className=" flex flex-column gap-4">
            <label className="w-full" htmlFor="amount ">
              <span className="font-bold">Milestone Amount ($):</span>
              <CustomTextfield
                id="amount"
                type="number"
                name="amount"
                className="border p-2"
                value={newTask.amount}
                onChange={handleInputChange}
                required
              />
              <span className="font-bold"> per milestone</span>
            </label>

            <form
              onSubmit={handleSubmit}
              className="flex items-center space-x-4 w-full"
            >
              <label className="w-full">
                {/* Task Description: */}
                <CustomTextfield
                  id="description"
                  type="text"
                  name="description"
                  placeholder="Enter task description"
                  className="border w-full p-2"
                  value={newTask.description}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label>
                {/* Date: */}
                <CustomTextfield
                  id="date"
                  type="date"
                  name="date"
                  className="border p-2"
                  value={newTask.dueDate}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </form>
            <CustomButton
              onClick={handleSubmit}
              type="button1"
              customClasses="px-4 py-2 mb-2 mx-auto !important "
              disabled={isSubmitDisabled}
            >
              Submit
            </CustomButton>
          </div>
        )}
        {newTask?.timesheetType === "Fixed Rate" && (
          <div className="flex flex-column gap-4 ">
            <label className="w-full" htmlFor="amount ">
              <span className="font-bold">Fixed Rate ($):</span>
              <CustomTextfield
                id="amount"
                type="number"
                name="amount"
                className="border p-2"
                value={newTask?.amount}
                onChange={handleInputChange}
              />
              <span className="font-bold"> per month</span>
            </label>

            <form
              onSubmit={handleSubmit}
              className="flex items-center  space-x-4 w-full"
            >
              <label className="w-full">
                {/* Task Description: */}
                <CustomTextfield
                  id="description"
                  type="text"
                  name="description"
                  placeholder="Enter task description"
                  className="border  w-full p-2"
                  value={newTask?.description}
                  onChange={handleInputChange}
                  required
                />
              </label>

              <label>
                {/* Date: */}
                <CustomTextfield
                  id="date"
                  type="date"
                  name="date"
                  className="border p-2"
                  value={newTask?.date}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </form>
            <CustomButton
              onClick={handleSubmit}
              type="button1"
              customClasses="px-4 py-2 mb-2 mx-auto !important "
              disabled={isSubmitDisabled}
            >
              Submit
            </CustomButton>
          </div>
        )}
      </div>
      <h3>Timesheet</h3>

      <div className="flex space-x-6">
        <CustomDateRangePicker
          selectedDate={startDate}
          onDateChange={handleStartDateChange}
          placeholderText="Start Date"
          minDate={null}
          maxDate={new Date()}
          value={startDate}
        />
        <CustomDateRangePicker
          selectedDate={endDate}
          onDateChange={handleEndDateChange}
          placeholderText="End Date"
          minDate={startDate}
          // maxDate={new Date()}
          value={endDate}
        />

        <div>
          <label className="block text-sm font-medium text-gray-700">
            &nbsp;
          </label>
          <CustomButton
            onClick={handleSubmitOftimesheetRange}
            type="button1"
            customClasses="w-full  px-4 py-2 mb-2 mx-auto !important "
            // disabled={isSubmitDisabled}
          >
            Submit
          </CustomButton>
        </div>
      </div>
      {dataLoading ? (
        <ContentLoader
          speed={2}
          width={1440}
          height={800}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="20" y="0" rx="3" ry="3" width="1440" height="700" />
        </ContentLoader>
      ) : (
        <>
          {dataError ? (
            <Alert severity="error">
              {" No record found for the selected criteria"}{" "}
            </Alert>
          ) : (
            <>
              {tasks && Object.keys(tasks).length > 0 ? (
                <>
                  {tasks?.["pay-as-you-go"]?.length > 0 && (
                    <>
                      <h3>Pay-as-you-go</h3>
                      <div className="max-h-[300px] overflow-y-auto  overflow-x-hidden">
                        <table className="w-full table-auto ">
                          <thead>
                            <tr>
                              <th className="p-2">Description</th>
                              <th className="p-2">Date</th>
                              <th className="p-2">Start Time</th>
                              <th className="p-2">End time</th>
                              <th className="p-2">Hours</th>
                              <th className="p-2">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tasks?.["pay-as-you-go"]?.map(
                              (payAsYouGo, index) => (
                                <tr
                                  key={index}
                                  className="border text-base font-semibold"
                                >
                                  <td className="p-2 w-2/5 border-r">
                                    {payAsYouGo?.description ?? "-"}
                                  </td>
                                  <td className="p-2 border-r">
                                    {payAsYouGo?.date
                                      ? new Date(
                                          payAsYouGo?.date
                                        ).toLocaleDateString()
                                      : "-"}
                                  </td>
                                  <td className="p-2 border-r">
                                    {payAsYouGo?.startTime ?? "-"}
                                  </td>
                                  <td className="p-2 border-r">
                                    {payAsYouGo?.endTime ?? "-"}
                                  </td>
                                  <td className="p-2 border-r">
                                    {payAsYouGo?.hours ?? "-"}
                                  </td>
                                  <td className="m-2 p-1 text-base font-semibold">
                                    {payAsYouGo.status === "review" ? (
                                      <CustomButton
                                        onClick={() =>
                                          handleReviewClick(payAsYouGo)
                                        }
                                        type="button1"
                                        customClasses="px-4 py-2 mb-2 mx-auto w-full !important "
                                        // disabled={isSubmitDisabled}
                                      >
                                        {payAsYouGo?.status
                                          .charAt(0)
                                          .toUpperCase() +
                                          payAsYouGo?.status.slice(1) ?? " "}
                                      </CustomButton>
                                    ) : (
                                      <Tooltip
                                        title={payAsYouGo?.statusReason}
                                        arrow
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0.5rem",
                                            borderRadius: "4px",
                                            color:
                                              payAsYouGo?.status === "approved"
                                                ? "#8BC34A" // Light Green
                                                : payAsYouGo?.status ===
                                                  "declined"
                                                ? "#FF0000" // Red
                                                : "#9e9e9e", // Grey
                                          }}
                                        >
                                          {payAsYouGo?.status === "review"
                                            ? "Pending"
                                            : payAsYouGo?.status
                                                .charAt(0)
                                                .toUpperCase() +
                                              payAsYouGo?.status.slice(1)}{" "}
                                        </span>
                                      </Tooltip>
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {tasks?.["milestone"]?.length > 0 && (
                    <>
                      <h3>Milestone</h3>
                      <div className="max-h-[300px] overflow-y-auto overflow-x-hidden">
                        <table className="w-full table-auto">
                          <thead>
                            <tr>
                              <th className="p-2">Description</th>
                              <th className="p-2">Due Date</th>
                              <th className="p-2">Amount</th>
                              <th className="p-2">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tasks?.["milestone"]?.map((milestone, index) => (
                              <tr
                                key={index}
                                className="border text-base font-semibold"
                              >
                                <td className="p-2 w-2/5 border-r">
                                  {milestone?.description ?? "-"}
                                </td>
                                <td className="p-2 border-r">
                                  {milestone?.date
                                    ? new Date(
                                        milestone?.date
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>
                                <td className="p-2 border-r">
                                  {milestone?.amount ?? "-"}
                                </td>
                                <td className="m-2 p-1 text-base font-semibold">
                                  {milestone?.status === "review" ? (
                                    <CustomButton
                                      onClick={() =>
                                        handleReviewClick(milestone)
                                      }
                                      type="button1"
                                      customClasses="px-4 py-2 mb-2 mx-auto w-full !important "
                                      // disabled={isSubmitDisabled}
                                    >
                                      {milestone?.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        milestone?.status.slice(1) ?? ""}
                                    </CustomButton>
                                  ) : (
                                    <Tooltip
                                      title={milestone?.statusReason}
                                      arrow
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "0.5rem",
                                          borderRadius: "4px",
                                          color:
                                            milestone?.status === "approved"
                                              ? "#8BC34A" // Light Green
                                              : milestone?.status === "declined"
                                              ? "#FF0000" // Red
                                              : "#9e9e9e", // Grey
                                        }}
                                      >
                                        {milestone?.status === "review"
                                          ? "Pending"
                                          : milestone?.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            milestone?.status.slice(1)}{" "}
                                      </span>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {tasks?.["fixedrate"]?.length > 0 && (
                    <>
                      <h3>Fixed Rate</h3>
                      <div className="max-h-[300px] overflow-y-auto overflow-x-hidden">
                        <table className="w-full table-auto">
                          <thead className="w-full table-fixed important">
                            <tr>
                              <th className="p-2">Description</th>
                              <th className="p-2">Date</th>
                              <th className="p-2">Amount</th>
                              <th className="p-2">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tasks?.["fixedrate"]?.map((fixedrate, index) => (
                              <tr
                                key={index}
                                className="border text-base font-semibold"
                              >
                                <td className="p-2 w-2/5 border-r">
                                  {fixedrate?.description ?? "-"}
                                </td>
                                <td className="p-2 border-r">
                                  {fixedrate?.date
                                    ? new Date(
                                        fixedrate?.date
                                      ).toLocaleDateString()
                                    : "-"}
                                </td>
                                <td className="p-2 border-r">
                                  {fixedrate?.amount ?? "-"}
                                </td>
                                <td className="m-2 p-1 text-base font-semibold">
                                  {fixedrate?.status === "review" ? (
                                    <CustomButton
                                      onClick={() =>
                                        handleReviewClick(fixedrate)
                                      }
                                      type="button1"
                                      customClasses="px-4 py-2 mb-2 mx-auto w-full !important "
                                      // disabled={isSubmitDisabled}
                                    >
                                      {fixedrate?.status
                                        .charAt(0)
                                        .toUpperCase() +
                                        fixedrate?.status.slice(1) ?? ""}
                                    </CustomButton>
                                  ) : (
                                    <Tooltip
                                      title={fixedrate?.statusReason}
                                      arrow
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          padding: "0.5rem",
                                          borderRadius: "4px",
                                          color:
                                            fixedrate?.status === "approved"
                                              ? "#8BC34A" // Light Green
                                              : fixedrate?.status === "declined"
                                              ? "#FF0000" // Red
                                              : "#9e9e9e", // Grey
                                        }}
                                      >
                                        {fixedrate?.status === "review"
                                          ? "Pending"
                                          : fixedrate?.status
                                              .charAt(0)
                                              .toUpperCase() +
                                            fixedrate?.status.slice(1)}{" "}
                                      </span>
                                    </Tooltip>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <Alert severity="info">
                  {"No record found for the selected criteria"}
                </Alert>
              )}
            </>
          )}
        </>
      )}

      <Modal
        task={selectedTask}
        isOpen={isModalOpen}
        onAccept={handleAcceptReview}
        onReject={handleRejectReview}
        onClose={handleCloseModal}
        onSubmit={onSubmitOfReview}
      />
    </div>
  );
};

export default Timesheet;
