import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginInfo, postLogin } from "./login.service";

// First, create the thunk
const loginUser = createAsyncThunk("login_info/login", async (request, thunkAPI) => {
  return postLogin(request).then((res) => {
    localStorage.setItem('user', JSON.stringify(res));
    return res;
  });
});

const loginInfoThunk = createAsyncThunk("login_info/get", async (request, thunkAPI) => {
  return loginInfo();
});


export { loginUser, loginInfoThunk };
