import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import images from "../../../images/hire_developer_team/developer";

const useStyles = makeStyles((theme) => ({
  consultantRoot: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: "1px solid black",
    marginBottom: "164px",
    [theme.breakpoints.down("600")]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: "1px solid white",
      background: "linear-gradient(to bottom, transparent 50%, black 50%)",
      marginBottom: "69px",
    },
    [theme.breakpoints.down("400")]: {
      background: "linear-gradient(to bottom, transparent 60%, black 60%)",
    },
    [theme.breakpoints.down("400")]: {
      background: "linear-gradient(to bottom, transparent 70%, black 70%)",
    },
  },
  consultantContainer: {
    display: "flex ",
    alignItems: "center",
    columnGap: "26px",
    maxWidth: "1684px",
    margin: "122px auto 0 auto",
    [theme.breakpoints.down("1700")]: {
      maxWidth: "1400px",
    },
    [theme.breakpoints.down("1400")]: {
      maxWidth: "1000px",
    },
    [theme.breakpoints.down("1000")]: {
      maxWidth: "790px",
    },
    [theme.breakpoints.down("800")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("600")]: {
      margin: "0 49px 80px 49px",
    },
  },
  consultantImageDiv: {
    [theme.breakpoints.down("800")]: {
      margin: "63px 20px 0 20px",
    },
    [theme.breakpoints.down("600")]: {
      backgroundColor: theme.palette.common.black,
      marginTop: "0px",
    },
  },
  consultantTextDiv: {},
  title: {
    maxWidth: "613px",
    width: "100%",
    marginBottom: "30px",
    color: "inherit",
    [theme.breakpoints.down("1700")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("1400")]: {
      fontSize: "42px",
    },
    [theme.breakpoints.down("1000")]: {
      fontSize: "35px",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("600")]: {
      marginBottom: "35px",
    },
  },
  description: {
    fontWeight: 600,
    maxWidth: "604px",
    width: "100%",
    [theme.breakpoints.down("1000")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("800")]: {
      fontSize: "15px",
      fontWeight: 500,
    },
    [theme.breakpoints.down("600")]: {
      marginBottom: "63px",
    },
  },
}));

const ConsultantInfo = (props) => {
  const classes = useStyles();
  const data = {
    title: "Find the right fit for your team at FindPro",
    image: images.consultant_detail_page,
    smallImage: images.consultant_details_small_view,
    description:
      "We believe that finding the right developer is about more than just technical skills. Our Deep Developer Profiles take into account a range of factors, including communication style and personality, to help you find a developer who is the right fit for your team.",
  };
  const smDown = useMediaQuery("(max-width:800px)");
  const xsDown = useMediaQuery("(max-width:600px)");
  const ImageDiv = () => {
    return (
      <div className={classes.consultantImageDiv}>
        <img
          src={xsDown ? data.smallImage : data.image}
          alt="consultant-page-image"
        />
      </div>
    );
  };
  const TextDiv = () => {
    return (
      <div className={classes.consultantTextDiv}>
        <Typography variant="h2" className={classes.title}>
          {data.title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {data.description}
        </Typography>
      </div>
    );
  };
  return (
    <div className={classes.consultantRoot}>
      <div className={classes.consultantContainer}>
        {smDown ? (
          <>
            <TextDiv />
            <ImageDiv />
          </>
        ) : (
          <>
            <ImageDiv />
            <TextDiv />
          </>
        )}
      </div>
    </div>
  );
};

ConsultantInfo.propTypes = {};

export default ConsultantInfo;
