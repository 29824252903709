import React, { useState } from "react";
import tw from 'twin.macro';

import { Img, Text } from "../../common";
import Tooltip from "../../common/ToolTip";

import CustomButton from "app/v2/components/CustomButton";
import { IconDot } from "../../SVGs/IconDot";
const Button = CustomButton;

const JobStatusBadge = tw(Text)`flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm`;


const JobDetailHeader = ({props}) => {

    return (
    <>
      <div className="flex flex-col items-center justify-end mx-auto md:px-10 sm:px-5 w-full">
        <div className="flex md:flex-col flex-row gap-6 items-start justify-start max-w-[890px] mb-1 mt-1 mx-auto w-full">
          <Img
            src={props?.logo}
            className="h-[120px] md:h-auto object-cover w-[120px]"
            alt="company_logo"
          />
          <div className="flex flex-1 flex-col gap-3 items-start justify-center w-full">
            <div className="flex sm:flex-col flex-row gap-3 items-start justify-between w-full">
              <div className="flex sm:flex-1 sm:flex-col flex-row gap-3 items-center justify-start w-auto sm:w-full">
                <Text className="font-normal text-gray-600 text-xs w-auto">Posted {props?._created_at}</Text>
                <Text className="font-normal text-gray-600 text-xs w-auto">Last Updated {props?._updated_at} </Text>
                <Text className="font-semibold text-gray-600 text-xs uppercase w-auto">team no. {props?.jobNo} </Text>
              </div>

                {props?.status === 'active' || props?.status === 'Available' ? (
                      <JobStatusBadge className="bg-green-50">
                        <IconDot fillColor="#14C042" className="mt-0.5 mb-[3px] mr-[5px]" />
                        <div className="font-bold text-[#14C042] text-left text-xs">Available</div>
                      </JobStatusBadge>
                    ) : props?.status === 'inactive' ? (
                      <JobStatusBadge className="bg-gray-50">
                        <IconDot fillColor="gray" className="mt-0.5 mb-[3px] mr-[5px]" />
                        <div className="font-bold text-gray-600 text-left text-xs">Inactive</div>
                      </JobStatusBadge>
                    ) : (
                      <>
                        Error
                      </>
                    )}


            </div>
            <Text className="font-semibold text-4xl sm:text-[32px] md:text-[34px] text-black_900 w-full">
              {props?.jobName} 
              {/* Costco App Development Team */}
            </Text>
            <div className="flex sm:flex-col flex-row gap-9 items-center justify-start w-full">
              <div className="flex flex-row gap-0.5 items-center justify-start w-auto">
                <Text className="font-semibold text-gray-400 text-sm w-auto"> {props.role} </Text>
                <Text className="font-semibold text-gray-900 text-sm w-auto"> {props.roleName} </Text>
              </div>
              <div className="flex flex-row gap-0.5 items-center justify-start w-auto">
                <Text className="font-semibold text-gray-400 text-sm w-auto">Created by</Text>
                <Text className="font-semibold text-gray-900 text-sm w-auto"> {props.createdBy} </Text>
              </div>
              <div className="flex flex-row gap-0.5 items-center justify-start w-auto">
                <Text className="font-semibold text-gray-400 text-sm w-auto">Updated by</Text>
                <Text className="font-semibold text-gray-900 text-sm w-auto"> {props.updatedBy} </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    );
};

JobDetailHeader.propTypes = {};

export default JobDetailHeader;