import React, { useState } from "react";
import PropTypes from "prop-types";
import { Select, InputLabel, MenuItem, FormControl, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import CustomLabel from "./CustomLabel";

const useStyles = makeStyles((theme) => ({
  box: {
    // textAlign: "center",
    width: "100%",
  },
  formControl: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 0,
      border: "1px solid #EDEDED",
    },
  },
  selectRoot: {
    height: "50px",
    fontSize: "16px",
  },
  noLeftPadding: {
    "& .MuiSelect-select": {
      paddingLeft: 0,
    },
  },
  selectLabel: {
    fontSize: "16px",
    color: theme.palette.secondary.light,
  },
  menuItem: {
    fontSize: "16px",
  },
  noBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));

const CustomSelect = (props) => {
  const classes = useStyles();
  let {
    id,
    data,
    defaultSelection,
    disabled,
    label,
    placeholder,
    onChange,
    customClasses,
    overviewData,
    onOverviewEntryChange,
    showMandatorySymbol,
    noOutline,
  } = props;
  // placeholder = placeholder ?? "Select";

  const [selectedValue, setSelectedValue] = useState("");
  const noDefaultSelection = Boolean(defaultSelection) === false;

  const handleSelectChange = (event) => {
    // console.log({ value: event.target.value, event });
    setSelectedValue(event.target.value);
    onChange(id, event.target.value);
  };

  React.useEffect(() => {
    setSelectedValue(defaultSelection);
    // if (!noDefaultSelection) onChange(id, defaultSelection);
  }, [defaultSelection]);

  return (
    <>
      <Box className={classes.box} key={id}>
        {label && (
          <CustomLabel label={`${label} ${showMandatorySymbol ? "*" : ""}`} />
        )}
        <FormControl
          fullWidth
          className={noOutline ? classes.noBorder : classes.formControl}
        >
          <InputLabel id={`${id}-select-label`} className={classes.selectLabel}>
            {placeholder}
          </InputLabel>
          <Select
            labelId={`${id}-select-label`}
            id={`${id}-select`}
            value={selectedValue}
            label={placeholder}
            onChange={handleSelectChange}
            className={clsx([
              customClasses,
              classes.selectRoot,
              noOutline && classes.noLeftPadding,
            ])}
            disabled={disabled}
            MenuProps={{
              // getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "center",
              },
              // classes: {
              //   paper: classes.menu,
              // },
            }}
          >
            {data.map((menuItemValue, idx) => (
              <MenuItem
                key={idx}
                value={menuItemValue}
                className={classes.menuItem}
              >
                {menuItemValue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

CustomSelect.propTypes = {};

export default CustomSelect;
