import moment from "moment";

import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteJobByIdThunk,
  pinJobThunk,
  setStatusJobByIdThunk,
} from "../../../store/features/employer/jobFeed/jobFeed.thunk";

import { List, Text } from "../../common";

import { HiringBoardJobDetailsObj } from "./HiringBoardJobDetails.dummy.js";

import { EditSVG, DeactivateSVG, DeleteSVG, ShareSVG } from "../../SVGs/Index";
import { IconDot } from "../../SVGs/IconDot";
import { PinSVG } from "../../SVGs/Pin";
import { CheckSVG } from "../../SVGs/Check";

import CustomButton from "app/v2/components/CustomButton";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import ShareIcon from "@mui/icons-material/Share";
import DeleteJobDialog from "./DeleteJobDialog";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
import { isFunction } from "../../Utils/utilFunctions";

const Button = CustomButton;

const useStyles = makeStyles((theme) => ({
  jobSection2Div: {
    width: "730px",
  },
  languageDiv: {
    width: "130px",
  },
  locationOrTimezoneDiv: {
    // width: "284px",
    width: "340px",
  },
  pinIconButton: {
    backgroundColor: "#F4F8FF !important",
  },
  shareIcon: {
    color: "#363636",
  },
}));

const HiringBoardJobDetails = ({ data, onJobDelete }) => {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const { experiences, contractTypes } = useSelector((state) => state.misc);
  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  const [deleteJobDialogOpen, setDeleteJobDialogOpen] = useState(false);
  const [deleteActionLoading, setDeleteActionLoading] = useState(false);
  const [deleteActionCompleted, setDeleteActionCompleted] = useState(false);
  const [deleteActionError, setDeleteActionError] = useState(false);

  const onJobTitleClick = () => {
    // history.push("/client/job-detail", {
    //   jobData: data,
    // });
    // window.scrollTo(0, 0);
  };

  const onDeleteClick = () => {
    setDeleteActionLoading(true);

    // setTimeout(() => {
    //   /** Confirm case */
    //   setDeleteActionLoading(false);
    //   setDeleteActionCompleted(true);
    //   /** Error case */
    //   // setDeleteActionLoading(false);
    //   // setDeleteActionError(true);
    // }, 2000);

    dispatch(deleteJobByIdThunk(data?._id))
      .then((result) => {
        console.log("Job deleted successfully:", result);
        setDeleteActionLoading(false);
        setDeleteActionCompleted(true);
        isFunction(onJobDelete) && onJobDelete(data?._id);
      })
      .catch((error) => {
        setDeleteActionLoading(false);
        setDeleteActionError(true);
        console.error("Error deleting job:", error);
      });
  };

  useEffect(() => {
    if (!deleteJobDialogOpen) {
      // Setting back to default states- used when the dialog is closed.
      setDeleteActionLoading(false);
      setDeleteActionCompleted(false);
      setDeleteActionError(false);
    }
  }, [deleteJobDialogOpen]);

  return (
    <>
      <div className="bg-white flex flex-col sm:gap-10 md:gap-10 gap-10 items-center justify-start mx-auto p-10 md:px-10 sm:px-5 w-full">
        {data?.status === "draft" ? (
          <div className="bg-gray-100 flex flex-col gap-9 items-start justify-start max-w-[1088px] mb-[57px] mx-auto p-9 md:px-5 w-full">
            <div className="flex flex-col gap-6 items-start justify-start w-full">
              <div className="flex md:flex-col flex-row gap-[19px] items-start justify-start w-full">
                <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                  <Text
                    className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto cursor-pointer"
                    onClick={() => {
                      onJobTitleClick();
                    }}
                  >
                    {data?.jobTitle}
                  </Text>
                  <div className="flex sm:flex-col flex-row gap-3 items-center justify-start w-auto sm:w-full">
                    <Text className="font-normal text-gray-600 text-sm w-auto">
                      {/* Created {data?.createdAt} */}
                      Created {moment(data.createdAt).fromNow()}{" "}
                    </Text>
                    <Text className="font-normal text-gray-600 text-sm w-auto">
                      {/* Last Updated {data?.updatedAt} */}
                      Last Updated {moment(data.updatedAt).fromNow()}{" "}
                    </Text>
                    <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                      job no. {data?.jobNo}
                    </Text>
                  </div>
                  <div className="flex flex-row gap-1 items-center justify-start w-auto">
                    <Text className="font-semibold text-gray-400 text-sm w-auto">
                      Hiring Manager
                    </Text>
                    <Text className="font-semibold text-gray-900 text-sm w-auto">
                      {" "}
                      {data?.hiringManager}{" "}
                    </Text>
                  </div>
                </div>
                <div className="border border-gray-600 flex items-center justify-center min-w-[47px] px-2 py-0.5 rounded-sm  text-xs">
                  <div className="font-bold text-gray-600 text-left text-xs">
                    Draft
                  </div>
                </div>
              </div>
              <List
                className="sm:flex-col flex-row gap-4 grid sm:grid-cols-1 md:grid-cols-3 grid-cols-5 justify-start w-full"
                orientation="horizontal"
              >
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                    Open Positions
                  </Text>
                  <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                    {data?.openPositions}
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                    {" "}
                    experience Level{" "}
                  </Text>
                  <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                    {/* {data.experienceLevelId}{" "} */}
                    {experiences?.[data.experienceLevelId]?.level}
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                    Contract type
                  </Text>
                  <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                    {/* {" "}{data.contractType}{" "} */}
                    {contractTypes?.[data.contractType]?.level}
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                    work type
                  </Text>
                  <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                    -
                  </Text>
                </div>
                <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                  <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                    hourly rate
                  </Text>
                  <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                    -
                  </Text>
                </div>
              </List>
            </div>
            <div className="flex flex-row gap-3 items-end justify-between w-full">
              <Button
                customClasses="border border-gray-900 border-solid cursor-pointer flex items-center justify-center min-w-[188px] px-4 py-2.5"
                onClick={() => {
                  // history.push("/client/edit-job/" + data._id);
                  // window.scrollTo(0, 0);
                }}
              >
                <EditSVG className="mt-px mb-0.5 m-2.5 mb-[3px]" />
                <div className="font-semibold leading-[normal] text-gray-900 text-center text-sm">
                  Complete Job Post
                </div>
              </Button>
              <Button
                onClick={() => {
                  setDeleteJobDialogOpen(true);
                }}
              >
                <DeleteSVG />
              </Button>
            </div>
          </div>
        ) : (
          <List
            className="flex-col md:gap-10 grid items-center max-w-[1088px] mx-auto w-full"
            orientation="vertical"
          >
            <div className="border border-blue-700 border-solid flex flex-1 flex-col items-start justify-start max-w-[1088px] p-9 sm:px-5 w-full">
              <div className="flex flex-col gap-9 items-start justify-start w-full">
                <div className="flex flex-col gap-6 items-start justify-start w-full">
                  <div className="flex md:flex-col flex-row gap-[19px] items-start justify-start w-full">
                    <div className="flex flex-1 flex-col gap-1.5 items-start justify-start w-full">
                      <Text
                        className="font-semibold text-2xl md:text-[22px] text-black-900 sm:text-xl w-auto cursor-pointer"
                        onClick={() => {
                          onJobTitleClick();
                        }}
                      >
                        {data?.jobTitle}
                      </Text>
                      <div className="flex sm:flex-col flex-row gap-3 items-center justify-start w-auto sm:w-full">
                        <Text className="font-normal text-gray-600 text-sm w-auto">
                          Posted {moment(data.createdAt).fromNow()}{" "}
                          {/* Posted {data.createdAt}{" "} */}
                        </Text>
                        <Text className="font-normal text-gray-600 text-sm w-auto">
                          {/* Last Updated {data.updatedAt}{" "} */}
                          Last Updated {moment(data.updatedAt).fromNow()}{" "}
                        </Text>
                        <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
                          job no. {data.jobNo}
                        </Text>
                      </div>
                      <div className="flex flex-row gap-1 items-center justify-start w-auto">
                        <Text className="font-semibold text-gray-400 text-sm w-auto">
                          {" "}
                          {data.role}{" "}
                        </Text>
                        <Text className="font-semibold text-gray-900 text-sm w-auto">
                          {" "}
                          {data.roleName}{" "}
                        </Text>
                      </div>
                    </div>

                    {data?.status === "active" ? (
                      <div className="bg-green-50 flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm">
                        <IconDot
                          className="mt-0.5 mb-[3px] mr-[5px]"
                          fillColor="#14C042"
                        />
                        <div className="font-bold text-green-500 text-left text-xs">
                          Active
                        </div>
                      </div>
                    ) : data?.status === "inactive" ? (
                      <div className="bg-gray-300 flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm">
                        <IconDot
                          className="mt-0.5 mb-[3px] mr-[5px]"
                          fillColor="#555"
                        />
                        <div className="font-bold text-gray-600 text-left text-xs">
                          Inactive
                        </div>
                      </div>
                    ) : data?.status === "closed" ? (
                      <div className="bg-[#f5f7ff] flex items-center justify-center min-w-[69px] px-2 py-0.5 rounded-sm">
                        <IconDot
                          className="mt-0.5 mb-[3px] mr-[5px]"
                          fillColor="#1d5dff"
                        />
                        <div className="font-bold text-blue-700 text-left text-xs">
                          Closed
                        </div>
                      </div>
                    ) : (
                      <>Error</>
                    )}
                  </div>
                  <div className="flex md:flex-col flex-row gap-4 items-center justify-start w-full">
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        Open Positions
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                        {" "}
                        {data?.openPositions}{" "}
                      </Text>
                    </div>
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        experience Level
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                        {/* {" "} */}
                        {/* {data?.experienceLevelId}{" "} */}
                        {experiences?.[data.experienceLevelId]?.level}
                      </Text>
                    </div>
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        Contract type
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                        {/* {" "}{data.contractType}{" "} */}
                        {contractTypes?.[data.contractType]?.level}
                      </Text>
                    </div>
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        work type
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                        {" "}
                        {data.workType}{" "}
                      </Text>
                    </div>
                    <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                      <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-auto">
                        {data.salary?.range ? "Salary Range" : "Fixed Rate"}
                      </Text>
                      <Text className="font-bold text-center text-gray-900 text-lg w-auto">
                        {/* {" "}
                        {data.salary?.range?.min +
                          " - " +
                          data.salary?.range?.max}{" "} */}
                        {data.salary?.range ? (
                          <span>
                            ${data.salary.range.min} - ${data.salary.range.max}
                          </span>
                        ) : (
                          <span>${data.salary?.fixedRate}</span>
                        )}
                      </Text>
                    </div>
                  </div>
                  <div className="flex md:flex-col flex-row gap-4 items-center justify-between w-full">
                    <div className={`flex flex-row ${classes.jobSection2Div}`}>
                      {/* <div
                        className={`flex flex-col gap-0.5 items-start ${classes.languageDiv}`}
                      >
                        <Text className="font-semibold text-center text-gray-600 text-xs uppercase">
                          Language
                        </Text>
                        <Text className="font-bold text-center text-gray-900 text-lg">
                          {" "}
                          {data.language}{" "}
                        </Text>
                      </div> */}
                      <div
                        className={`flex flex-col gap-0.5 items-start ${classes.locationOrTimezoneDiv}`}
                      >
                        <Text className="font-semibold text-center text-gray-600 text-xs uppercase">
                          Location
                        </Text>
                        <Text className="font-bold text-left text-gray-900 text-lg ">
                          {/* {data.city + ", " + data.state + ", " + data.country}{" "} */}
                          {data.city && (
                            <span>
                              {data.city.charAt(0).toUpperCase() +
                                data.city.slice(1)}
                              ,{" "}
                            </span>
                          )}
                          {data.state && (
                            <span>
                              {data.state.charAt(0).toUpperCase() +
                                data.state.slice(1)}
                              ,{" "}
                            </span>
                          )}
                          {data.country && (
                            <span>
                              {data.country.charAt(0).toUpperCase() +
                                data.country.slice(1)}{" "}
                            </span>
                          )}
                        </Text>
                      </div>
                      <div
                        className={`flex flex-col gap-0.5 items-start ${classes.locationOrTimezoneDiv}`}
                      >
                        <Text className="font-semibold text-center text-gray-600 text-xs uppercase">
                          Timezone
                        </Text>
                        <Text className="font-bold text-center text-gray-900 text-lg">
                          {" "}
                          {data.timezone}{" "}
                        </Text>
                      </div>
                    </div>
                    <div className="bg-gray-100 flex flex-row gap-1.5 items-start justify-start p-3 w-[270px]">
                      <div className="flex flex-col gap-0.5 items-center justify-center w-full">
                        <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">
                          Total Applicants
                        </Text>
                        <Text className="font-bold text-base text-center text-gray-900">
                          {/* {" "}
                          {data?.acceptedApplicants +
                            data?.rejectedApplicants}{" "} */}
                          {(data.acceptedApplicants || 0) +
                            (data.rejectedApplicants || 0) || 0}
                        </Text>
                      </div>
                      <div className="flex flex-col gap-0.5 items-center justify-center w-full">
                        <Text className="font-semibold text-center text-gray-600 text-xs uppercase w-full">
                          Accepted
                        </Text>
                        <Text className="font-bold text-base text-center text-gray-900 w-full">
                          {" "}
                          {data?.acceptedApplicants || 0}{" "}
                        </Text>
                      </div>
                    </div>
                    {/* <div className="flex flex-1 sm:flex-col flex-row gap-4 items-start justify-start px-4 py-3 w-full">
                      <List
                        className="sm:flex-1 sm:flex-col flex-row gap-4 grid grid-cols-4 w-[66%] sm:w-full"
                        orientation="horizontal"
                      >
                        <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                          <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                            pending
                          </Text>
                          <Text className="font-bold text-base text-center text-gray-900 w-auto">
                            {" "}
                            {data.pending}{" "}
                          </Text>
                        </div>
                        <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                          <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                            shortlisted
                          </Text>
                          <Text className="font-bold text-base text-center text-gray-900 w-auto">
                            {" "}
                            {data.shortlisted}{" "}
                          </Text>
                        </div>
                        <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                          <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                            interview1
                          </Text>
                          <Text className="font-bold text-base text-center text-gray-900 w-auto">
                            {" "}
                            {data.interview1}{" "}
                          </Text>
                        </div>
                        <div className="flex flex-col gap-0.5 items-start justify-center w-full">
                          <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                            testing
                          </Text>
                          <Text className="font-bold text-base text-center text-gray-900 w-auto">
                            {" "}
                            {data.testing}{" "}
                          </Text>
                        </div>
                      </List>
                      <div className="flex flex-1 flex-col gap-0.5 items-start justify-center w-full">
                        <Text className="font-semibold text-gray-600 text-xs uppercase w-full">
                          interview2
                        </Text>
                        <Text className="font-bold text-base text-center text-gray-900 w-auto">
                          {" "}
                          {data.interview2}{" "}
                        </Text>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="flex md:flex-col flex-row gap-9 items-end justify-between w-full">
                  <div className="flex sm:flex-1 sm:flex-col flex-row gap-3 items-start justify-start w-auto sm:w-full">
                    {data?.status === "active" ? (
                      <>
                        <Button
                          onClick={() => {
                            history.push("/client/hiring-board");
                            // history.push("/client/hiring-board/"+data?.emp_id);
                            window.scrollTo(0, 0);
                          }}
                          type="button1"
                          customClasses={"h-10 px-4 py-2.5"}
                        >
                          <div className="font-semibold leading-[normal] text-center text-white mr-2">
                            View Job Hiring Board
                          </div>
                          <img src="/board.svg" />
                        </Button>
                        <Button
                          variant="outlined"
                          type="button2"
                          customClasses={
                            "border border-gray-900 h-10 px-4 py-2.5"
                          }
                          onClick={() => {
                            // history.push("/client/consultant-feed");
                            history.push("/client/consultant-feed/" + data._id);
                            window.scrollTo(0, 0);
                          }}
                        >
                          Find Consultants
                        </Button>
                      </>
                    ) : data?.status === "closed" ? (
                      <Button type="button1" customClasses={"px-5 py-2.5"}>
                        Check Consultant Activity
                      </Button>
                    ) : data?.status === "inactive" ? (
                      <Button
                        type="button2"
                        customClasses={
                          "border border-gray-200 text-white px-5 py-2.5"
                        }
                        onClick={() => {
                          dispatch(
                            setStatusJobByIdThunk({
                              jobID: data?._id,
                              status: "active",
                            })
                          )
                            .unwrap()
                            .then((result) => {
                              console.log("activated:", result);
                              history.push("/client/job-feed");
                            })
                            .catch((error) => {
                              console.error("Error activate job:", error);
                            });
                        }}
                      >
                        <CheckSVG
                          className=" mt-px mb-0.5 m-2.5"
                          fillColor="#000"
                        />
                        Reactivate
                      </Button>
                    ) : (
                      <>Error</>
                    )}

                    <Button
                      type="button2"
                      customClasses={
                        "border border-gray-200 border-solid flex h-10 items-center justify-center w-10"
                      }
                      onClick={() => {
                        history.push("/client/edit-job/" + data._id);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <EditSVG />
                    </Button>
                    {data?.status === "active" && (
                      <Button
                        type="button2"
                        customClasses={
                          "border border-gray-200 border-solid flex h-10 items-center justify-center w-10"
                        }
                        onClick={() => {
                          dispatch(
                            setStatusJobByIdThunk({
                              jobID: data?._id,
                              status: "inactive",
                            })
                          )
                            .unwrap()
                            .then((result) => {
                              history.push("/client/job-feed");
                            })
                            .catch((error) => {
                              console.error("Error activate job:", error);
                            });
                        }}
                      >
                        <DeactivateSVG />
                      </Button>
                    )}
                    {data?.status === "active" && (
                      <Button
                        type="button2"
                        customClasses={`flex h-10 items-center justify-center w-10 
                        ${data.is_pinned && classes.pinIconButton}`}
                        onClick={() => {
                          dispatch(pinJobThunk(data?._id))
                            .unwrap()
                            .then((result) => {
                              history.push("/client/job-feed");
                            })
                            .catch((error) => {
                              console.error("Error pin job:", error);
                            });
                        }}
                      >
                        {data.is_pinned ? (
                          <PinSVG fillColor="#1E5DFF" />
                        ) : (
                          <PinSVG fillColor="#dedede" />
                        )}
                      </Button>
                    )}

                    <Button
                      type="button2"
                      customClasses={
                        "border border-gray-200 border-solid flex h-10 items-center justify-center w-10"
                      }
                    >
                      <ShareIcon
                        fontSize="small"
                        className={classes.shareIcon}
                      />
                    </Button>
                  </div>
                  <Button
                    type="button2"
                    customClasses={
                      "border border-gray-200 border-solid flex h-10 items-center justify-center w-10"
                    }
                    onClick={() => {
                      setDeleteJobDialogOpen(true);
                    }}
                  >
                    <DeleteSVG className="h-6 w-6" />
                  </Button>
                </div>
              </div>
            </div>
          </List>
        )}
      </div>
      {deleteJobDialogOpen && (
        <DeleteJobDialog
          open={deleteJobDialogOpen}
          setOpen={setDeleteJobDialogOpen}
          jobDetails={{
            jobNo: data?.jobNo,
            jobName: data?.jobTitle,
          }}
          onDeleteClick={onDeleteClick}
          deleteLoading={deleteActionLoading}
          deleteCompleted={deleteActionCompleted}
          deleteError={deleteActionError}
        />
      )}
    </>
  );
};

HiringBoardJobDetails.propTypes = {};
HiringBoardJobDetails.defaultProps = HiringBoardJobDetailsObj;

export default HiringBoardJobDetails;
