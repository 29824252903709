import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const Breadcrumb = (props) => {
  const { breadcrumbData, subTitleExists } = props;
  const history = useHistory();
  const breadCrumbLastIndex = breadcrumbData?.length - 1;
  return (
    <div className={"breadcrumb-row"}>
      <ul
        className={"list-inline" + (!subTitleExists && " noSubTitle")}
        style={{ paddingLeft: "2px" }}
      >
        {breadcrumbData.map((breadcrumb, index) => {
          return (
            <li
              onClick={() => {
                if (index !== breadCrumbLastIndex)
                  history.go(-(breadCrumbLastIndex - index));
              }}
              className={index !== breadCrumbLastIndex && "clickableBreadcrumb"}
            >
              {breadcrumb}
            </li>
          );
        })}
        {/* <li
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.goBack();
          }}
        >
          {breadcrumbData[0]}
        </li>
        <li>{breadcrumbData[1]}</li> */}
      </ul>
    </div>
  );
};

Breadcrumb.propTypes = {};

export default Breadcrumb;
