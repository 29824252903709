import { combineReducers } from 'redux';
import { miscReducer } from './features/misc/miscSlice';
import { notificationsReducer } from './features/notifications/notificationsSlice';

import consultantReducer from "../../candidate/myaccount/profile-settings/profile-settings.slice";

import jobsReducer from "./features/employer/jobFeed/jobFeed.slice";
import consultantsReducer from "./features/employer/consultants/consultants.slice";
import clientDashboardReducer from "./features/employer/employerDashboardSlice";
import clientProfileInfoReducer from "./features/employer/profile-settings/profile-settings.slice";


export const appReducer = combineReducers({
  notifications: notificationsReducer,
  misc: miscReducer,
  consultant:consultantReducer,
  jobs:jobsReducer,
  consultants:consultantsReducer,
  clientDashboard:clientDashboardReducer,
  clientProfileInfo:clientProfileInfoReducer,
});

  
// login_info: loginSlice,
// candidate_info: profileSettingsSlice,
// resume_settings: resumeSettingsSlice,
// manage_jobs: manageJobsSlice,
// employer_info: employerProfileSettings,
// job_details: jobsSlice,
// vendor_info: profileSettingsSliceVendor


const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
