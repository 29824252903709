import React, { useState, useContext, useCallback, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";
import { UserContext } from "app/v2";
import { getAccountType } from "app/v2/Pages/Utils/utilFunctions";
import CustomLabel from "./CustomLabel";
import { useDispatch, useSelector } from "react-redux";

import { fetchRolesThunk } from "app/v2/store/features/misc/miscThunk";

import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";

const useStyles = makeStyles({
  textFieldRoot: {
    height: "41px",
    padding: "0 0 0 10px !important", // paddingLeft is actually 15px in design but the input tag has 5px default padding on the left hence set only 10 here.
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.943166px solid #EDEDED",
    },
  },
  input: {
    fontWeight: "bold",
    fontSize: "16px",

    border: "none",
    "&:hover": {
      border: "none",
    },
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
    "&::placeholder": {
      fontWeight: 400,
    },
  },
  label: {
    textAlign: "left",
    fontWeight: "normal",
    fontSize: "14px",
    color: "black",
  },
  selectedOption: {
    fontWeight: "normal",
    fontSize: "15px",
  },
  line: {
    borderTop: "1px solid ",
    marginTop: "25px",
    paddingTop: "16px",
  },
});

const CustomSearch = ({ id, onChange, tittle, placeholder, value }) => {
  const classes = useStyles();
  const accountType = getAccountType();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.misc);

  const [drawerDetails, setDrawerDetails] = useContext(UserContext);
  const [jobListings, setJobListings] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const handleSearch = useCallback(() => {
    if (tittle !== "Search All Jobs") {
      setDrawerDetails((prevState) => ({
        ...prevState,
        showDrawer: true,
        componentType: "filter",
        showHeading: true,
        accountType: accountType,
      }));
    }
  }, [tittle, accountType, setDrawerDetails]);

  useEffect(() => {
    if (!roles?.length) dispatch(fetchRolesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (isValidArray(roles)) {
      const roleNames = roles.map((role) => role?.name);
      setJobListings(roleNames);
    }
  }, [roles]);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  return (
    <>
      {tittle && <CustomLabel label={tittle} />}
      {/* <div className={classes.label}>{tittle}</div> */}
      <Autocomplete
        options={tittle !== "Search All Jobs" ? [] : jobListings}
        value={searchValue}
        onInputChange={(event, newValue) => {
          if (event !== null) {
            // Execute the following logic only if the input change is user-triggered,
            //  preventing unintended actions when programmatically updating the value eg: clearing the filters( sets the empty value in selectedFilters again if this condition is not there).
            setSearchValue(newValue);
            onChange(id, newValue);
          }
        }}
        freeSolo
        classes={{
          option: classes.option,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.textFieldRoot,
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ position: "absolute", right: "10px" }}
                >
                  <SearchIcon
                    onClick={handleSearch}
                    style={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.2)" }}
                  />
                </InputAdornment>
              ),
              onClick: handleSearch,
            }}
          />
        )}
        renderOption={(props, option, state) => (
          <li {...props}>
            <span className={classes.selectedOption}>{option}</span>
          </li>
        )}
      />
      {tittle === "Search All Jobs" && <div className={classes.line}></div>}
    </>
  );
};

export default CustomSearch;
