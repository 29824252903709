import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import CustomVerticalStepper from "app/v2/components/CustomVerticalStepper";
import CustomButton from "app/v2/components/CustomButton";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles((theme) => ({
  stepperContainer: {
    padding: "38px 51px",
  },
  step2And3Text: {
    fontSize: "12px",
    marginBottom: "16px",
  },
  signContractButton: {
    padding: "20px",
    display: "flex",
    alignItems: "center",
  },
  signContractText: {
    fontWeight: 600,
    fontSize: "14px",
    marginRight: "10px",
  },
  actionRequiredButton: {
    backgroundColor: "#F4F8FF !important",
    color: theme.palette.primary.main,
    width: "133px",
    height: "25px",
    fontWeight: 600,
    fontSize: "12px",
  },

  doneIcon: {
    color: "#14C042",
    position: "relative",
    bottom: "2px",
    left: "7px",
  },
  waitIcon: {
    color: "#FBBC05",
    position: "relative",
    bottom: "2px",
    left: "7px",
  },
  errorIcon: {
    color: "#f0002b",
    position: "relative",
    bottom: "2px",
    left: "7px",
  },
  stepDiv: {
    dispaly: "flex",
    alignItems: "center",
  },
}));

const ContractOnboarding = (props) => {
  const classes = useStyles();

  const StepStatusIcon = ({ status }) => {
    let icon;
    switch (status) {
      case "done":
        icon = (
          <CheckCircleOutlineIcon
            fontSize="small"
            className={classes.doneIcon}
          />
        );
        break;
      case "wait":
        icon = (
          <RemoveCircleOutlineIcon
            fontSize="small"
            className={classes.waitIcon}
          />
        );
        break;
      case "error":
        icon = (
          <ErrorOutlineIcon fontSize="small" className={classes.errorIcon} />
        );
        break;
      default:
    }
    return icon;
  };
  const ContractStep1 = () => {
    return <></>;
  };

  const ContractStep2 = () => {
    return (
      <div>
        <Typography className={classes.step2And3Text}>
          We’ve also sent the contract to Carol for her to sign.
        </Typography>
        <CustomButton type="button1" className={classes.signContractButton}>
          <span className={classes.signContractText}>
            Sign Contract via PandaDoc
          </span>{" "}
          <CallMadeIcon fontSize="small" />
        </CustomButton>
      </div>
    );
  };

  const ContractStep3 = () => {
    return (
      <div>
        <Typography className={classes.step2And3Text}>
          There was a problem with one of the documents.
        </Typography>
        <Button type="button1" className={classes.actionRequiredButton}>
          Action Required
        </Button>
      </div>
    );
  };

  const ContractStep4 = () => {
    return <></>;
  };

  const ContractStep5 = () => {
    return <></>;
  };

  const dummyStepsData = [
    {
      leftLabel: "April 4 2023",
      rightLabel: "We’re drafting your contract...",
      rightLabelIcon: <StepStatusIcon status="done" />,
      componentData: {
        component: <ContractStep1 />,
      },
    },
    {
      leftLabel: "April 4 2023",
      rightLabel: "Your contract is ready for you to review and sign.",
      rightLabelIcon: <StepStatusIcon status="wait" />,
      componentData: {
        component: <ContractStep2 />,
      },
    },
    {
      leftLabel: "April 4 2023",
      rightLabel: "Just confirming all your documents and Carol’s documents.",
      rightLabelIcon: <StepStatusIcon status="error" />,
      componentData: {
        component: <ContractStep3 />,
      },
    },
    {
      rightLabel: "Getting your work diary and payroll set-up just a moment.",
      componentData: {
        component: <ContractStep4 />,
      },
    },
    {
      rightLabel: "Your workspace is ready to access.",
      componentData: {
        component: <ContractStep5 />,
      },
    },
  ];
  const [stepperData, setStepperData] = useState({
    onFinish: () => {
      // history.push("/sign-up");
    },
  });

  useEffect(() => {
    let updatedStepperData;
    updatedStepperData = { ...stepperData };
    updatedStepperData.stepsData = dummyStepsData;
    setStepperData(updatedStepperData);
  }, []);

  return (
    <div className={classes.stepperContainer}>
      <CustomVerticalStepper
        stepperData={stepperData?.stepsData}
        // onStepperDataChange={onStepperDataChange}
      />
    </div>
  );
};

ContractOnboarding.propTypes = {};

export default ContractOnboarding;
