import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";

import Title from "app/v2/Pages/common/Title";
import {
  CircularProgress,
  Alert,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
// import ActiveWorkListCard from "../../common/Cards/activework/List";
import ActiveWorkListCard from "../../common/Cards/activework/List";
import { activeWorks } from "app/v2/services/consultant/myJobs.service";
import CloseIcon from '@mui/icons-material/Close';

import { messages } from "app/v2/Pages/Utils/constants";
import CustomTextfield from "app/v2/components/filters/CustomTextfield";
import CustomRadioGroup from "app/v2/components/CustomRadioGroup";
import CustomCheckboxGroup from "app/v2/components/CustomCheckboxGroup";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ContentLoader from "react-content-loader";
import { activeConsultants } from "app/v2/services/client/consultants.service";
import { useSnackbar } from "notistack";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import { getEmployerInfo } from "app/v2/services/client/profileSettings.service";
import { isValidObject } from "app/v2/Pages/Utils/utilFunctions";
import {
  fetchContractTypesThunk,
  fetchExperiencesThunk,
} from "app/v2/store/features/misc/miscThunk";
const useStyles = makeStyles((theme) => ({
  activeWorkContainerLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerDiv: {
    position: "fixed",
    width: "1440px",
    borderBottom: "1px solid #EDEDED",
    backgroundColor: "white",
    zIndex: 1,
  },
  body: {
    paddingTop: "102px",
  },
  leftView: {
    padding: "48px 24px",
    width: "305px",
    position: "absolute",
    overflow: "auto",
    height: "615px",
  },
  rightView: {
    margin: "0px 24px 24px 329px", // left margin - 305px +24px(here 305 is fixed leftView div's width )
    borderLeft: "1px solid #EDEDED",
    minHeight: "650px",
  },
  searchTextDiv: {
    marginBottom: "26px",
  },
  sortOrFilterText: {
    fontWeight: 600,
    fontSize: "16px",
  },
  sortRadioGroupDiv: {
    margin: "12px 24px",
  },
  sortTextAndIconDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "8px",
  },
  accordionDiv: {
    margin: "14px 0",
  },
  accordion: {
    boxShadow: "none",
    borderBottom: "1px solid #EDEDED",
  },
  accordionTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
  workTypeCheckboxDiv: {
    margin: "12px 24px",
  },
  moreFilterDiv: {
    border: "1px solid #EDEDED",
    padding: "7.545px 13.204px",
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    cursor: "pointer",
  },
  moreFilterText: {
    fontWeight: 600,
    fontSize: "14px",
  },
  filterImgAndCountDiv: {
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  moreFilterCount: {
    fontWeight: 600,
    fontSize: "10px",
    color: theme.palette.primary.main,
    position: "relative",
    top: "1px",
  },
  loadMoreDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "76px",
    rowGap: "12px",
  },
  moreIcon: {
    color: theme.palette.secondary.light,
    padding: "8px",
    border: "1px solid #EDEDED",
    borderRadius: "4px",
  },
  loadMoreText: {
    fontSize: "14px",
  },
}));

const dummyActiveWorkData = [
  {
    _id: "1",
    image: "/company_image.png",
    companyName: "ABC Company",
    acceptedDate: "April 2, 2023",
    appNo: "FP23147",
    onboardingStatus: "underAssessment",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Part Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },
  {
    _id: "2",
    image: "/company_image.png",
    companyName: "BMC Company",
    acceptedDate: "April 10, 2023",
    appNo: "FP23147",
    onboardingStatus: "underAssessment",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Part Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },

  {
    _id: "3",
    image: "/company_image.png",
    companyName: "ABC Company",
    acceptedDate: "April 2, 2023",
    appNo: "FP23147",
    onboardingStatus: "underAssessment",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Part Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },
  {
    _id: "4",
    image: "/company_image.png",
    companyName: "ABC Company",
    acceptedDate: "April 2, 2023",
    appNo: "FP23147",
    onboardingStatus: "completed",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Part Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },
  {
    _id: "5",
    image: "/company_image.png",
    companyName: "CMD Company",
    acceptedDate: "March 9, 2023",
    appNo: "FP23147",
    onboardingStatus: "underAssessment",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Full Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },
  {
    _id: "64c6821f825d60123ff5fa8e" /* used it just to test the viewdetails page */,
    image: "/company_image.png",
    companyName: "ABC Company",
    acceptedDate: "July 7, 2023",
    appNo: "FP23147",
    onboardingStatus: "completed",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Part Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },

  {
    _id: "7",
    image: "/company_image.png",
    companyName: "BMC Company",
    acceptedDate: "June 2, 2023",
    appNo: "FP23147",
    onboardingStatus: "completed",
    workTime: "9:00 am - 5:00 pm",
    timeZone: "(SGST GMT +8.0)",
    startDate: "April 23, 2023",
    jobNo: "FP23147",
    jobTitle: " Frontend Developer for Contractual Project",
    contractType: "Full Time",
    workType: "Remote",
    acceptedRate: "$30/hr",
    location: "Singapore City, Singapore",
  },
];
const ActiveWork = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { experiences, contractTypes, skills, industryData } = useSelector(
    (state) => state.misc
  );

  const sortData = [
    {
      label: "Date Accepted",
      value: "Date Accepted",
    },

    // {
    //   label: (
    //     <div className={classes.sortTextAndIconDiv}>
    //       Expertise <img src="/iconlightarrow-top.svg" />
    //     </div>
    //   ),
    //   value: "Expertise",
    // },
    {
      label: "Alphabetical (A-Z)",
      value: "Alphabetical (A-Z)",
    },

    {
      label: "Alphabetical (Z-A)",
      value: "Alphabetical (Z-A)",
    },
  ];
  const [sortSelectionValue, setSortSelectionValue] = useState(
    sortData[0]?.value
  );
  const [activeWorksData, setActiveWorksData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [dataError, setDataError] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [clientData, setClientData] = useState({});

  const [onBoardingStatusData, setOnboardingStatusData] = useState([
    // {
    //   label: "Complete (2)",
    //   value: "Complete (2)",
    //   checked: false,
    // },
    // {
    //   label: "Ongoing (4)",
    //   value: "Ongoing (4)",
    //   checked: false,
    // },
  ]);
  const [contractTypeData, setContractTypeData] = useState([]);

  const [filteredJobData, setFilteredJobData] = useState([]);
  const [jobData, setJobData] = useState([]);

  const { enqueueSnackbar,closeSnackbar } = useSnackbar();

  const onSortChange = (id, currentValue) => {
    setSortSelectionValue(currentValue);
  };

  const onCheckboxGroupChange = (checkboxValue, checkedState, elementID) => {
    if (elementID === "worktype-checkbox-group") {
      setOnboardingStatusData((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });

        return updatedItems;
      });
    }
    if (elementID === "contractType-checkbox-group") {
      setContractTypeData((prevItems) => {
        const updatedItems = prevItems?.map((item) => {
          if (item.value === checkboxValue) {
            return { ...item, checked: checkedState };
          }
          return item;
        });
        return updatedItems;
      });
    }
  };

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);

  const filterBySortValue = (jobDataReceived) => {
    switch (sortSelectionValue) {
      case "Date Accepted":
        // Sort the jobData based on the "published" date in descending order
        jobDataReceived?.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0);
          console.log("date1", date1); // Treats undefined published dates as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
        break;
      // case "Relevance":
      //   break;
      case "Alphabetical (A-Z)":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const companyName1 = jobEntry1?.organizationDetails.company || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
          const companyName2 = jobEntry2?.organizationDetails.company || "ZZZZZ";

          return companyName1.localeCompare(companyName2);
        });
        break;
      case "Alphabetical (Z-A)":
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const title1 = jobEntry1?.organizationDetails.company || "ZZZZZ"; // Treats undefined jobTitle as greater than any other jobTitle and shows that entry at the end of the list.
          const title2 = jobEntry2?.organizationDetails.company || "ZZZZZ";

          return title2.localeCompare(title1);
        });
        break;
      // case "Oldest":
      //   // Sort the jobDataReceived based on the "published" date in descending order
      //   jobDataReceived.sort((jobEntry1, jobEntry2) => {
      //     const date1 = jobEntry1.createdAt
      //       ? new Date(jobEntry1.createdAt)
      //       : new Date(0); // Treats undefined published dates as oldest possible date
      //     const date2 = jobEntry2.createdAt
      //       ? new Date(jobEntry2.createdAt)
      //       : new Date(0);
      //     return date1 - date2;
      //   });
      //   break;
      default:
        // If the sortSelectionValue is not recognized, default to sorting by "Date Accepted"
        jobDataReceived.sort((jobEntry1, jobEntry2) => {
          const date1 = jobEntry1.createdAt
            ? new Date(jobEntry1.createdAt)
            : new Date(0); // Treats undefined acceptedDate as oldest possible date
          const date2 = jobEntry2.createdAt
            ? new Date(jobEntry2.createdAt)
            : new Date(0);
          return date2 - date1;
        });
    }

    return jobDataReceived;
  };

  useEffect(() => {
    filterBySortValue(filteredJobData);
  }, [filteredJobData]);

  const filterByOnBoardingStatus = (jobDataReceived) => {
    const selectedonBoardingStatus = onBoardingStatusData
      .filter((onboardvalue) => onboardvalue.checked === true)
      .map((onboardvalue) => onboardvalue.value);

    if (isValidArray(selectedonBoardingStatus)) {
      console.log("job revegegegeg", jobDataReceived);
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        selectedonBoardingStatus.includes(
          jobEntry?.onboardingDetails.onboardingStatus
        )
      );
    }

    return jobDataReceived;
  };

  const filterByContractType = (jobDataReceived) => {
    const selectedContractType = contractTypeData
      .filter((contractvalue) => contractvalue.checked === true)
      .map((contractvalue) => contractvalue.value);
    console.log("testdata1", selectedContractType);

    if (isValidArray(selectedContractType)) {
      jobDataReceived = jobDataReceived.filter((jobEntry) =>
        selectedContractType.includes(jobEntry.jobDetails.contractType)
      );
    }
    return jobDataReceived;
  };

  const updateFilteredJobData = () => {
    if (isValidArray(jobData)) {
      let filteredJobData = JSON.parse(JSON.stringify(jobData));
      if (searchText?.length > 0) {
        const searchTerm = searchText.toLowerCase();
        filteredJobData = filteredJobData.filter((jobEntry) =>
          jobEntry?.organizationDetails.company?.toLowerCase()?.includes(searchTerm)
        );
      }
      filteredJobData = filterBySortValue(filteredJobData);
      filteredJobData = filterByOnBoardingStatus(filteredJobData);
      filteredJobData = filterByContractType(filteredJobData);
      // filteredJobData = filterByCountry(filteredJobData);
      // filteredJobData = filterBySalary(filteredJobData);
      setFilteredJobData(filteredJobData);
    }
  };

  const getOnboardingStatusfilter = (jobData) => {
    let filters = [];
    let onBoardingstatusAndCount = new Map();
    jobData?.forEach((jobEntry) => {
      const workType = jobEntry?.onboardingDetails.onboardingStatus;
      if (workType?.length > 0) {
        const workTypeCountInMap = onBoardingstatusAndCount.get(workType);
        onBoardingstatusAndCount.set(
          workType,
          workTypeCountInMap ? workTypeCountInMap + 1 : 1
        );
      }
    });
    onBoardingstatusAndCount.forEach((workTypeEntryCount, workTypeEntry) => {
      filters.push({
        label: `${workTypeEntry} (${workTypeEntryCount})`,
        value: `${workTypeEntry}`,
        checked: false,
      });
    });
    return filters;
  };

  const getContractTypeLevel = (contractTypeID) => {
    const contractTypeData = contractTypes.find(
      (type) => type._id === contractTypeID
    );
    return contractTypeData ? contractTypeData.level : "-";
  };

  const getContractTypeFilters = (jobData) => {
    let filters = [];
    let contractTypeAndCount = new Map();
    jobData?.forEach((jobEntry) => {
      const contractType = jobEntry?.jobDetails?.contractType;
      if (contractType?.length > 0) {
        const contractTypeCountInMAp = contractTypeAndCount.get(contractType);
        contractTypeAndCount.set(
          contractType,
          contractTypeCountInMAp ? contractTypeCountInMAp + 1 : 1
        );
      }
    });
    contractTypeAndCount.forEach(
      (contractTypeEntryCount, contractTypeEntry) => {
        filters.push({
          label: `${getContractTypeLevel(
            contractTypeEntry
          )} (${contractTypeEntryCount})`,
          value: `${contractTypeEntry}`,
          checked: false,
        });
      }
    );
    return filters;
  };

  // const getClientInfoOfJob = (clientID) => {
  //   getEmployerInfo(clientID)
  //     .then((res) => {
  //       const receivedClientData = res?.employer;
  //       console.log({ receivedClientData });
  //       if (isValidObject(receivedClientData)) {
  //         let reqClientData = {}; // required client data
  //         reqClientData.companyName = receivedClientData?.company ?? "";
  //         reqClientData.profilePhoto = receivedClientData?.profilePhoto ?? "";
  //         reqClientData.website =
  //           receivedClientData?.socialLinks?.website ?? "";
  //         reqClientData.profileDesc =
  //           receivedClientData.profileDesc?.htmlString ??
  //           receivedClientData.profileDesc ??
  //           "";
  //         reqClientData.culture =
  //           receivedClientData.profileDesc?.htmlString ??
  //           receivedClientData.profileDesc ??
  //           "";
  //         setClientData(reqClientData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log({ error });
  //     });
  // };

  useEffect(() => {
    // dispatch(loginInfoThunk());
    setSearchText("");

    // let receivedJobData = JSON.parse(JSON.stringify(dummyActiveWorkData));
    //       setFilteredJobData(receivedJobData);
    //       setJobData(receivedJobData);
    //       // getClientInfoOfJob(receivedJobData.emp_id)
    //       getClientInfoOfJob('6213313b5eaf63543dc2b946')
    //       const onBoardingFilters = getOnboardingStatusfilter(receivedJobData);

    //       setOnboardingStatusData(onBoardingFilters);
    //       const contractTypeFilters = getContractTypeFilters(receivedJobData);
    //       setContractTypeData(contractTypeFilters);
    //       // setActiveWorksData(res); //remove this commented code

    activeWorks()
      .then((res) => {
        setDataLoading(false);

        if (isValidArray(res.data)) {
          console.log("res of activework data ", res.data);
          let receivedJobData = JSON.parse(JSON.stringify(res.data));
          setFilteredJobData(receivedJobData);
          setJobData(receivedJobData);
          setActiveWorksData(receivedJobData);

          // getClientInfoOfJob(receivedJobData.emp_id)
          // getClientInfoOfJob("6213313b5eaf63543dc2b946");
          const onBoardingFilters = getOnboardingStatusfilter(receivedJobData);

          setOnboardingStatusData(onBoardingFilters);
          const contractTypeFilters = getContractTypeFilters(receivedJobData);
          setContractTypeData(contractTypeFilters);
          // setActiveWorksData(res);
        } else {
          // let receivedJobData = JSON.parse(JSON.stringify(dummyActiveWorkData));
          // setFilteredJobData(receivedJobData);
          // setJobData(receivedJobData);
          // getClientInfoOfJob('6213313b5eaf63543dc2b946') //remove this
          // const onBoardingFilters = getOnboardingStatusfilter(receivedJobData);
          // setOnboardingStatusData(onBoardingFilters);
          // const contractTypeFilters = getContractTypeFilters(receivedJobData);
          // setContractTypeData(contractTypeFilters);
          setOnboardingStatusData([]);
          setFilteredJobData([]);
        }
      })
      .catch((err) => {
        // enqueueSnackbar("Problem in active consultants", {
        //   variant: "error",
        // });

        const key = enqueueSnackbar("Problem in active consultants", {
          variant: "error",  autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer',fontSize:'15',marginTop:'-1px' }} />
          ),
        });
        console.error(err);
      });
  }, []);

  useEffect(() => {
    updateFilteredJobData();
  }, [
    searchText,
    sortSelectionValue,
    onBoardingStatusData,
    contractTypeData,
    // salaryDetails,
    // jobCountries,
  ]);

  useEffect(() => {
    console.log("clientData", { clientData });
  }, [clientData]);

  console.log("filtered datataa", filteredJobData);
  return (
    <div className={dataLoading && classes.activeWorkContainerLoading}>
      <>
        {dataLoading ? (
          // <CircularProgress />
          <ContentLoader
            speed={2}
            width={1400}
            height={800}
            viewBox="0 0 1400 800"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="80" rx="3" ry="3" width="280" height="500" />
            <rect x="305" y="80" rx="3" ry="3" width="1080" height="500" />
          </ContentLoader>
        ) : (
          <>
            {dataError ? (
              <Alert severity="error">{messages?.GENERIC_ERROR_MESSAGE} </Alert>
            ) : filteredJobData ? (
              <div>
                <div className={classes.headerDiv}>
                  <Title title="Active Work" />
                </div>

                <div className={classes.body}>
                  <Grid container>
                    <Grid item xs={3} className={classes.leftView}>
                      <div className={classes.searchTextDiv}>
                        <CustomTextfield
                          id="searchText"
                          value={searchText}
                          placeholder="Search by name"
                          onChange={(id, value) => {
                            setSearchText(value);
                          }}
                          noErrorMessage={true}

                          // endAdornment={
                          //   <SearchRoundedIcon
                          //     onClick={updateFilteredJobData}
                          //     className={classes.seacriconstyle}
                          //   />
                          // }
                        />
                      </div>
                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Sort{" "}
                        </Typography>
                        <div className={classes.sortRadioGroupDiv}>
                          <CustomRadioGroup
                            id="sort-radio-group  "
                            name="sort-radio-group"
                            defaultValue={sortSelectionValue}
                            data={sortData}
                            onChange={onSortChange}
                          />
                        </div>
                      </div>

                      <div>
                        <Typography className={classes.sortOrFilterText}>
                          Filter
                        </Typography>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Onboarding Status
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {" "}
                              <div className={classes.workTypeCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="worktype-checkbox-group"
                                  data={onBoardingStatusData}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                        <div className={classes.accordionDiv}>
                          <Accordion className={classes.accordion}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography className={classes.accordionTitle}>
                                Contract
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className={classes.workTypeCheckboxDiv}>
                                <CustomCheckboxGroup
                                  id="contractType-checkbox-group"
                                  data={contractTypeData}
                                  onChange={onCheckboxGroupChange}
                                />
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>

                      {/* <div className={classes.moreFilterDiv}>
                        <Typography className={classes.moreFilterText}>
                          More Filters
                        </Typography>
                        <div className={classes.filterImgAndCountDiv}>
                          <img src="/filter-list.svg" />
                          <span className={classes.moreFilterCount}>2</span>
                        </div>
                      </div> */}
                    </Grid>
                    <Grid item xs={9} className={classes.rightView}>
                      {filteredJobData && filteredJobData.length > 0 ? (
                        <>
                          {filteredJobData.map((activeWorkData, index) => (
                            <React.Fragment key={index}>
                              <ActiveWorkListCard data={activeWorkData} />
                              {index !== filteredJobData.length - 1 && (
                                <hr className="my-[20px]" />
                              )}
                            </React.Fragment>
                          ))}
                          {/* <div className={classes.loadMoreDiv}>
                            <MoreHorizIcon
                              className={classes.moreIcon}
                              fontSize="large"
                            />
                            <Typography className={classes.loadMoreText}>
                              Load more
                            </Typography>
                          </div> */}
                        </>
                      ) : (
                        <Alert style={{ margin: "5px" }} severity="info">
                          No Data Available
                        </Alert>
                      )}
                      
                    </Grid>
                  </Grid>
                </div>
              </div>
            ) : (
              <Alert severity="info">{messages?.NO_DATA_FOUND}</Alert>
            )}
          </>
        )}
      </>
    </div>
  );
};

ActiveWork.propTypes = {};

export default ActiveWork;

