// DatePickerComponent.jsx
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDateRangePicker = ({ value ,selectedDate, onDateChange, placeholderText, minDate, maxDate }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        {placeholderText}
      </label>
      <DatePicker
        selected={selectedDate}
        onChange={onDateChange}
        placeholderText={`Select ${placeholderText}`}
        minDate={minDate}
        maxDate={maxDate}
        value={value}
      />
    </div>
  );
};

export default CustomDateRangePicker;
