import { logout } from "app/common/login/login.service";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
// import LOGO from "../../images/logo.png";
import v2Images from "../v2/images/common";
import { useSnackbar } from "notistack";
import storageUtil from "app/common/utils/storageUtil";
import {
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from "@mui/material";
import DehazeIcon from "@mui/icons-material/Dehaze";
import NoUserPic from "../../images/user.svg";
import { shallowEqual, useSelector } from "react-redux";
import Bell from "../../images/bells.svg";
import BellWithNotification from "../../images/bellWithNotification.svg";
import { candidateInfoThunk } from "app/candidate/myaccount/profile-settings/profile-settings.thunk";
import { Dropdown, DropdownButton } from "react-bootstrap";
import "./header.scss";
import { getJobAlert } from "app/candidate/myjobs/jobs-alerts/job-alert.service";
import { getAccountType, isUserSubscribed } from "app/common/utils/loginUtil";
import EditIcon from "../../images/edit-icon.svg";
import { getEmployerInfoThunk } from "app/employer/myaccount/profile-settings/profile-settings.thunk";
import { profileInfo } from "app/vendor/myaccount/profile-settings/profile-settings.thunk";

const HeaderComponent = (props) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userPic, setUserPic] = useState("");
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [jobAlertList, setJobAlertList] = useState([]);
  const login_info = storageUtil.getItem("user");

  useEffect(() => {
    if (login_info.role !== "candidates") return;
    getJobAlert(login_info._id)
      .then((res) => {
        setJobAlertList(res);
      })
      .catch((err) => {
        if (err.message) {
          alert(err.message);
        }
        console.error(err);
      });
  }, []);

  const onLogout = () => {
    logout()
      .then((res) => {
        if (res.message) {
          enqueueSnackbar(res.message, { variant: "success" });
        }
        storageUtil.removeItem("user");
        history.push("/login");
      })
      .catch((err) => {
        if (err.message) {
          enqueueSnackbar(err.message, { variant: "error" });
        }
        console.error(err);
      });
  };

  useEffect(() => {
    if (login_info.role === "admin") return;

    let infoThunk = null;
    if (login_info.role === "candidates") {
      infoThunk = candidateInfoThunk;
    }

    if (login_info.role === "employers") {
      infoThunk = getEmployerInfoThunk;
    }

    if (login_info.role === "vendors") {
      infoThunk = profileInfo;
    }

    if (!infoThunk) {
      window.location.pathname = "/";
      return;
    }

    dispatch(infoThunk())
      .unwrap()
      .then((res) => {
        if (res) {
          if (res?.profile_picture) {
            setUserPic(res.profile_picture);
            localStorage.setItem("profile_pic", res.profile_picture);
          }
        }
      })
      .catch((err) => {
        if (err.message) {
          // alert(err.message);
        }
        console.error(err);
      });
  }, []);

  const user_info = useSelector((state) => {
    if (login_info.role === "candidates") {
      return state["candidate_info"].value;
    }
    if (login_info.role === "employers") {
      return state["employer_info"].value;
    }
    if (login_info.role === "vendors") {
      return state["employer_info"].value;
    }
  }, shallowEqual);

  useEffect(() => {
    setUserPic(user_info?.profile_picture);
  }, [user_info?.profile_picture]);

  const handleProfileClick = () => {
    const { role } = login_info;
    switch (role) {
      case "candidates":
        window.location.pathname = `${role}/myaccount`;
        return;
      case "employers":
        window.location.pathname = `${role}/myaccount/company-profile`;
        return;
      case "vendors":
        window.location.pathname = `${role}/myaccount/profile-settings`;
        return;
      default:
        return;
    }
  };

  const getJobActionImg = () => {
    if (getAccountType() === "candidates") {
      return jobAlertList && jobAlertList.length > 0
        ? BellWithNotification
        : Bell;
    }
    if (getAccountType() === "employers") {
      return EditIcon;
    }
  };

  const getJobActionText = () => {
    if (getAccountType() === "candidates") {
      return "Job Alerts";
    }
    if (getAccountType() === "employers") {
      return "Post a Job";
    }
  };

  const handleJobActionPath = () => {
    if (getAccountType() === "candidates") {
      history.push("/myjobs/job-alerts");
    }
    if (getAccountType() === "employers") {
      history.push("/myjobs/post-jobs");
    }
  };
  const routeMatch = useRouteMatch(props.menuList?.map((x) => x.link));
  const currentTab = routeMatch?.path;

  return (
    <>
      <header className="site-header mo-left header fullwidth header-v2">
        <Box
          className="sticky-header navbar-expand-lg"
          sx={{
            borderColor: "primary.main",
            borderBottomWidth: "4px",
            borderBottomStyle: "solid",
          }}
        >
          <div className="main-bar clearfix">
            <Container sx={{ display: "flex", alignItems: "center" }}>
              <Box className="logo-header app-logo">
                <Link to={"./"}>
                  <img src={v2Images.logo_v2} className="logo" alt="" />
                </Link>
              </Box>
              <div
                className="header-nav navbar-collapse collapse myNavbar justify-content-start"
                id="navbarNavDropdown"
              >
                <Tabs
                  value={currentTab}
                  className={
                    window.location.pathname ===
                    "/candidates/myjobs/jobs-alerts"
                      ? "hide-tab-select"
                      : ""
                  }
                >
                  {props.menuList?.map((menu, i) => {
                    //return ((menu.name !== "Upgrade" && menu.name !== "My Profile") || (menu.name === "Upgrade" && localStorage.isVendorSubs)) && (
                    return (
                      menu.name !== "My Profile" && (
                        <Tab
                          key={i}
                          sx={{ fontSize: "15px" }}
                          label={menu.name}
                          value={menu.link}
                          to={menu.link}
                          component={Link}
                          disabled={!!menu.disabled}
                        />
                      )
                    );
                  })}
                </Tabs>
              </div>
              <Box
                sx={{ width: "100%", textAlign: "right" }}
                className="d-lg-none align-items-center d-block"
              >
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls="long-menu"
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <DehazeIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {props.menuList?.map((menu, i) => (
                    <MenuItem
                      key={menu.name}
                      disabled={!!menu.disabled}
                      onClick={handleClose}
                    >
                      <Link to={menu.link}>{menu.name}</Link>
                    </MenuItem>
                  ))}
                  {getAccountType() !== "vendors" && (
                    <MenuItem onClick={handleJobActionPath}>
                      {getJobActionText()}
                    </MenuItem>
                  )}
                  {getAccountType() === "employers" && (
                    <MenuItem>
                      <Link to="/myaccount/company-profile">My Profile</Link>
                    </MenuItem>
                  )}
                  <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>
              </Box>
              {props.children}
              <Box
                className="d-lg-flex align-items-center d-none"
                sx={{ position: "relative" }}
              >
                {props.menuList ? (
                  <>
                    <Button
                      className="job-details-btn"
                      variant="contained"
                      style={{
                        borderRadius: 0,
                        background: "transparent",
                        boxShadow: "none",
                      }}
                      onClick={handleJobActionPath}
                    >
                      {getAccountType() !== "vendors" &&
                        getAccountType() !== "admin" && (
                          <Link>
                            <img src={getJobActionImg()} alt="notification" />
                            &nbsp;
                            <span>{getJobActionText()}</span>
                          </Link>
                        )}
                    </Button>
                    &emsp;&emsp;
                    <DropdownButton
                      title={
                        <img
                          src={userPic || NoUserPic}
                          alt="user"
                          className="profile-pic"
                        />
                      }
                      className="user-actions"
                    >
                      {getAccountType() !== "admin" && (
                        <Dropdown.Item onClick={() => handleProfileClick()}>
                          Profile
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={onLogout}>Logout</Dropdown.Item>
                    </DropdownButton>
                  </>
                ) : null}
              </Box>
            </Container>
          </div>
        </Box>
      </header>
    </>
  );
};

export default HeaderComponent;
