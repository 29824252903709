import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Chip, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonImages from "../../../../../images/common";
import { useHistory } from "react-router-dom";
import { ShareSVG } from "app/v2/Pages/SVGs/Share";
import { NoProfileImageSVG } from "app/v2/Pages/SVGs/NoProfile";
import { isValidArray } from "app/v2/Pages/Utils/utilFunctions";
import { useDispatch, useSelector } from "react-redux";
import { favUnfavSelection } from "app/v2/services/client/common.service";
import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import { putConnectionsData } from "app/v2/services/consultant/connections.service";
import CloseIcon from "@mui/icons-material/Close";

import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import { getSkillName } from "app/v2/Pages/common/getIdTypeLevel";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    width: "100%",
    border: "1px solid #EDEDED",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
    },
  },
  skillsChipsDiv: {
    width: "100%",
    overflowX: "auto",
    paddingBottom: "10px",
    whiteSpace: "nowrap",
    maxWidth: "422px",
  },
  sectionHeadingText: {
    fontSize: "10px",
    color: theme.palette.secondary.light,
  },
  hideActions: {
    display: "none",
  },
  userImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));

const ListCard = ({ data, jobID, setUpdateApiOfConsultan }) => {
  // console.log({ data });
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cardData, setCardData] = useState();
  // const [isHovered, setIsHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(true);
  const [isConnected, setIsConnected] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isFavorite, setIsFavorite] = useState(false);
  useEffect(() => {
    setIsFavorite(data?.isFavorite);
  }, [data]);
  const handlesavedData = () => {
    if (isFunction(setUpdateApiOfConsultan))
      setUpdateApiOfConsultan((prevState) => !prevState);
  };
  const handleBookMarkClick = async (consultantId, favouriteType) => {
    try {
      const requestData = {
        favoriteId: consultantId,
        favoriteModel: favouriteType,
      };
      const response = await favUnfavSelection(requestData);

      if (response?.message) {
        setIsFavorite(response?.data?.status);
        // enqueueSnackbar(`Successfull`, { variant: "success" });

        const key = enqueueSnackbar(`Successfull`, {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
        handlesavedData();
      }
    } catch (error) {
      // enqueueSnackbar(error, { variant: "error" });
      const key = enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });
    }
  };

  const handleConnection = async (consultantId) => {
    try {
      const requestData = {
        // fromId: "65593a25b957af8508a65bbe", // edward consultant
        fromUserType: "Client", // Consultants | Client | Agency
        toId: consultantId, // "6557e3f09b7363581b1bc9dc", // edward client
        toUserType: "Consultants", // Consultants | Client | Agency
      };
      const response = await putConnectionsData(requestData);

      if (response?.message) {
        // setIsConnected( response?.data?.status );
        setIsConnected(true);
        // enqueueSnackbar(`Successfull`, { variant: "success" });
        const key = enqueueSnackbar(`Successfull`, {
          variant: "success",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        });
      }
    } catch (error) {
      // enqueueSnackbar(error.message, { variant: "error" });
      const key = enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      });
    }
  };

  const { experiences, contractTypes, skillLevels, skills, industryData } =
    useSelector((state) => state.misc);
    console.log(contractTypes,"contractTypes")

  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypes?.length) dispatch(fetchContractTypesThunk());
    if (!skillLevels?.length) dispatch(fetchSkillsThunk());
    if (!skills?.length) dispatch(fetchSkillLevelsThunk());
  }, [dispatch]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    // setIsHovered(false);
  };

  useEffect(() => {
    setCardData(data);
  }, [data]);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx([
        classes.listContainer,
        "self-stretch bg-neutral-white overflow-hidden flex flex-row py-[21px] px-[18px] items-center justify-center gap-[36px] text-center text-lg text-black font-button-text-button-14",
      ])}
    >
      <img
        className={classes.userImage}
        alt={`"user_${data?._id}_image"`}
        src={
          cardData?.displayPicture
            ? cardData.displayPicture
            : "/user_placeholder.svg"
        }
      />
      <div className="w-[308px] flex flex-col  items-start justify-center gap-[19px]">
        <div className="self-stretch flex flex-row items-end justify-start gap-[8px]">
          <div className="flex-1 flex flex-col items-start justify-center gap-[2px]">
            <div className="relative leading-[130%] font-semibold text-left">
              {cardData?.name}
            </div>
            <div className="relative text-xs font-poppins text-neutral-500 text-left">
              {cardData?.profile_title}
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-center gap-[6px] text-left text-3xs text-neutral-500">
            <div className="flex flex-row items-center justify-start gap-[6px]">
              <b
                className={clsx([
                  classes.sectionHeadingText,
                  "relative uppercase",
                ])}
              >
                Match rate
              </b>
              <img
                className="relative w-4 h-4 overflow-hidden shrink-0"
                alt=""
                src="/iconoirdoublecheck.svg"
              />
            </div>
            <div className="relative text-sm leading-[130%] font-semibold text-primary-600 text-center">
              {cardData?.matchRate ?? "-"}
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[8px] text-3xs text-neutral-800">
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px] text-left">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              availability
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900 text-start">
              {contractTypes[cardData?.availability]?.level ?? "_"}

            </div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-center gap-[6px] text-neutral-500">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              Level
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {experiences[cardData?.experienceLevel]?.level ?? "_"}
            </div>
          </div>
          <div className="self-stretch flex-1 flex flex-col items-start justify-center gap-[6px]">
            <b
              className={clsx([
                classes.sectionHeadingText,
                "relative uppercase",
              ])}
            >
              location
            </b>
            <div className="relative text-sm leading-[130%] font-semibold text-neutral-900">
              {cardData?.city} {cardData?.country}
            </div>
          </div>
        </div>
      </div>
      <div className="w-[422px] h-[120px] flex flex-col  items-start justify-center gap-[16px] text-3xs text-neutral-500">
        <div className="flex flex-col items-start justify-start gap-[8px]">
          <div
            className={clsx([classes.sectionHeadingText, "relative uppercase"])}
          >
            technology skills
          </div>
          <div
            className={clsx([
              classes.skillsChipsDiv,
              "flex flex-row items-start justify-start gap-[8px] text-xs font-poppins",
              "overflow-auto",
            ])}
          >
            {isValidArray(cardData?.skills) ? (
              <>
                {cardData?.skills?.map((skillDetails) => (
                  <div className="rounded-md flex flex-row py-1.5 px-2 items-start justify-start border-[1px] border-solid border-neutral-50">
                    <div className="relative leading-[136%] font-medium">
                      {getSkillName(skillDetails?.skillID, skills)}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="self-stretch relative text-xs leading-[136%] font-poppins text-left [display:-webkit-inline-box] overflow-hidden overflow-y-scroll text-ellipsis [-webkit-line-clamp:2] [-webkit-box-orient:vertical]">
          {cardData?.summary}
        </div>
      </div>

      <div className="self-stretch flex flex-col items-start justify-start gap-[19px] max-w-[247px] w-full">
        {isHovered && (
          <>
            <button
              onClick={() => {
                if (jobID) {
                  history.push(`/client/${jobID}/consultant/${cardData._id}`);
                } else {
                  history.push(`/client/consultant/${cardData._id}`);
                }
                window.scrollTo(0, 0);
              }}
              className="cursor-pointer [border:none] py-2.5 pr-[15px] pl-5 bg-neutral-900 self-stretch flex flex-row items-center justify-center gap-[10px]"
            >
              <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-white text-center">
                View Profile
              </div>
              <img
                className="relative w-[15px] h-[15px] overflow-hidden shrink-0"
                alt=""
                src="/iconlightup-right.svg"
              />
            </button>
            <div className="self-stretch flex flex-row items-start justify-start gap-[12px]">
              {cardData?.isconnected ? (
                <button
                  onClick={() => handleConnection(cardData?._id)}
                  className="cursor-pointer py-2.5 px-5 bg-[transparent] flex-1 box-border h-10 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50"
                >
                  <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-300 text-center">
                    Connect
                  </div>
                </button>
              ) : (
                <Tooltip title="Feature will be available in next release">
                  <button className="cursor-pointer py-2.5 px-5 bg-[transparent] flex-1 box-border h-10 flex flex-row items-center justify-center border-[1px] border-solid border-neutral-50">
                    <div className="relative text-sm font-semibold font-button-text-button-14 text-neutral-300 text-center">
                      Message
                    </div>
                  </button>
                </Tooltip>
              )}

              <button
                className={`cursor-pointer ${
                  isFavorite
                    ? "bg-neutral-900 border-none h-10"
                    : "border-[1px] border-solid border-neutral-50"
                } p-2.5 flex flex-row box-border items-center justify-center`}
                onClick={() => {
                  handleBookMarkClick(cardData?._id, "Consultants");
                }}
              >
                <img
                  className="relative w-5 h-5 overflow-hidden shrink-0"
                  alt=""
                  src={
                    isFavorite
                      ? "/iconlightbookmark.svg"
                      : "/icondarkbookmark.svg"
                  }
                />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ListCard.propTypes = {};

export default ListCard;
