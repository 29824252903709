import { Button, CircularProgress, Container, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import FilterTopbarComponent from "../../common/top-filter/filter-topbar.component";
import EmployerSignupComponent from "./employer-signup.component";
import {
  blackListEmployer,
  deleteEmployer,
  getEmployers,
  updateUserSubscription,
} from "./manage-employers.service";
import {
  getEmployerByFilter,
  resendEmailVerification,
} from "../candidate/manage-candidates.service";
import ListView from "../list-view";
import NoDataFound from "../../../images/noData.svg";
import BulkUploadEmployer from "./employer-bulk-upload.component";

const ManageEmployerComponent = () => {
  const [employers, setEmployers] = useState(null);
  const [keywordFilter, setKeywordFilter] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    loadEmployers();
  }, []);

  useEffect(() => {
    if (keywordFilter === "") {
      loadEmployers();
      return;
    }
    setShowLoader(true);
    if (keywordFilter) {
      getEmployerByFilter({ keywords: keywordFilter })
        .then((res) => {
          setEmployers(res);
          setShowLoader(false);
        })
        .catch((err) => {
          enqueueSnackbar("Problem in filtering employers!", {
            variant: "error",
          });
          console.error(err);
          setShowLoader(false);
        });
    }
  }, [keywordFilter]);

  const loadEmployers = (keywords) => {
    setShowLoader(true);
    getEmployers(keywords)
      .then((res) => {
        setEmployers(res);
        setShowLoader(false);
      })
      .catch((err) => {
        enqueueSnackbar("Problem in fetching employers!", { variant: "error" });
        console.error(err);
        setShowLoader(false);
      });
  };

  const updateSubscription = (payload) => {
    setShowLoader(true);
    updateUserSubscription(payload, "employers")
      .then((res) => {
        enqueueSnackbar("Subscription updated successfuly", {
          variant: "success",
        });
        loadEmployers();
      })
      .catch((err) => {
        enqueueSnackbar("Problem in updating employers subscription", {
          variant: "error",
        });
        console.error(err);
        setShowLoader(false);
      });
  };
  const createdNewEmployer = (response) => {
    setShowLoader(true);
    setShowModal(false);
    getEmployers()
      .then((res) => {
        setEmployers(res);
        setShowLoader(false);
      })
      .catch((err) => {
        enqueueSnackbar("Problem in fetching jobs!", { variant: "error" });
        console.error(err);
        setShowLoader(false);
      });
  };

  const handleBlacklist = (checked, _id) => {
    blackListEmployer({ blacklisted: !checked, _id })
      .then((res) => {
        if (res.message) {
          enqueueSnackbar(res.message, { variant: "success" });
          loadEmployers();
        }
      })
      .catch((err) => {
        enqueueSnackbar("Error in blacklisting employer", {
          variant: "error",
        });
        console.error(err);
      });
  };

  const removeEmployer = (_id) => {
    deleteEmployer(_id)
      .then((res) => {
        loadEmployers();
        enqueueSnackbar(res.message, { variant: "success" });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Some problem with deleting employer.", {
          variant: "error",
        });
      });
  };

  const resendVerification = (_id) => {
    resendEmailVerification(_id, "employers")
      .then((res) => {
        enqueueSnackbar(res.message, { variant: "success" });
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("Some problem with resend candidate verification.", {
          variant: "error",
        });
      });
  };

  return (
    <>
      <div className="dez-bnr-inr card-cap m-b30">
        <div className="container" style={{ padding: "0 10px" }}>
          <div className="dez-bnr-inr-entry">
            <FilterTopbarComponent
              setKeywordFilter={setKeywordFilter}
              inputWatch
            />
          </div>
        </div>
      </div>
      <div className="content-block">
        <div className="section-full browse-job p-b50 admin-manage-people">
          <Container>
            <Grid container columnSpacing={2}>
              <Grid item xs="12">
                <Button
                  sx={{ bottom: "10px" }}
                  onClick={() => setShowModal(true)}
                  variant="contained"
                  type="button"
                  className="pull-right"
                >
                  Create a new employer
                </Button>
                <BulkUploadEmployer createdNewEmployer={createdNewEmployer}/>
              </Grid>

              <Grid md={12} xs={12} item>
                {(showLoader || !employers) && (
                  <CircularProgress
                    sx={{ display: "flex", margin: "auto", height: "100%" }}
                  />
                )}
                {employers && !showLoader && (
                  <>
                    {" "}
                    {employers?.length === 0 ? (
                      <Grid item xs="12">
                        <div className="revamp-table__nodata mt-5">
                          <img
                            src={NoDataFound}
                            alt="NoDataFound"
                            className="mb-3"
                          />
                        </div>
                      </Grid>
                    ) : (
                      <ListView
                        jobList={employers}
                        handleBlacklist={handleBlacklist}
                        removeProfile={removeEmployer}
                        resendVerification={resendVerification}
                        filters={filters}
                        updateSubscription={updateSubscription}
                        type="employer"
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <EmployerSignupComponent
        open={showModal}
        setOpen={setShowModal}
        createdEmployer={createdNewEmployer}
      />
    </>
  );
};

export default ManageEmployerComponent;
