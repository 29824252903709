import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, Chip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  buildTeamRoot: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "105px 0 105px 185px",
    [theme.breakpoints.down("1300")]: {
      marginLeft: "100px",
    },
    [theme.breakpoints.down("1000")]: {
      flexDirection: "column",
      margin: "80px 0",
    },
  },
  titleDescDiv: {
    maxWidth: "706px",
    width: "100%",
    [theme.breakpoints.down("1000")]: {
      textAlign: "center",
      marginBottom: "49px",
      margin: "0 47px",
    },
  },
  title: {
    marginBottom: "54px",
    [theme.breakpoints.down("1780")]: {
      fontSize: "55px",
    },
    [theme.breakpoints.down("1300")]: {
      fontSize: "42px",
    },
    [theme.breakpoints.down("1000")]: {
      marginLeft: "35px",
    },
  },
  description: {
    fontWeight: 500,
    color: theme.palette.secondary.light,
  },
  consultantDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "19px",
    maxWidth: "486px", // setting it as (590 - 52*2) since padding is already added.
    minHeight: "610px", // setting it as (714 - 52*2) since padding is already added.
    width: "100%",
    border: "1px solid #6B6B6B",
    borderBottom: "12px solid black",
    borderRadius: "6px 6px 0 0",
    padding: "52px",
    [theme.breakpoints.down("1500")]: {
      maxWidth: "386px",
    },
    [theme.breakpoints.down("1300")]: {
      padding: "40px",
    },
    [theme.breakpoints.down("1000")]: {
      maxWidth: "486px",
      margin: "auto",
      paddingBottom: 0,
    },
  },
  personalDetails: {
    display: "flex",
    alignItems: "center",
    columnGap: "31px",
  },
  avatar: {},
  nameRoleDiv: {},
  name: {
    fontWeight: 500,
  },
  role: {
    fontWeight: 500,
    color: theme.palette.secondary.light,
  },
  bio: {
    maxWidth: "472px",
    width: "100%",
    fontWeight: 500,
    color: theme.palette.secondary.light,
    height: "350px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "auto",
    },
    [theme.breakpoints.down("1000")]: {
      height: "150px",
    },
  },
  expertiseDiv: {},
  expertiseTitle: {
    fontWeight: 700,
  },
  expertiseChipDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: "9px",
    columnGap: "13px",
    height: "78px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "auto",
    },
  },
  chip: {
    minWidth: "148px",
    border: "1px solid #6B6B6B",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.secondary.light,
  },
  otherDetails: {},
  otherDetailsTitle: {
    fontWeight: 700,
  },
  otherDetailsContent: {
    fontWeight: 500,
    color: theme.palette.secondary.light,
  },
  swiperOuterContainer: {},
  swiperInnerContainer: {},
  swiper: {
    [theme.breakpoints.up("1300")]: {
      marginLeft: "198px",
    },
    [theme.breakpoints.down("1300")]: {
      marginLeft: "100px",
    },
    [theme.breakpoints.down("1000")]: {
      margin: "0 0px 179px 16px",
    },
  },
  swiperSlide: {
    [theme.breakpoints.up("1000")]: {
      width: "fit-content !important",
    },
    [theme.breakpoints.down("1000")]: {
      width: "100% !important",
    },
  },
  arrow: {
    // color: theme.palette.common.white,
    // cursor: "pointer",
    // zIndex: "2",
    // position: "absolute",
    // top: "50%",
    // // transform: "translateY(-50%)",
    // border: "1px solid #5F5F5F",
    // padding: "25px 20px 20px",
    // borderRadius: "50%",
  },
  prevArrow: {
    // left: "-175px", // 108px is expected spacing from the swiper, added a bit more considering the width of the arrow since we're absolutely positioning the arrows.
  },
  nextArrow: {
    // right: 0,
  },
}));

const BuildTeam = (props) => {
  const classes = useStyles();
  const data = {
    title: "How FindPro builds your dedicated development team",
    description:
      ": At FindPro, we take the time to understand your business objectives, development goals, and specific requirements for your project. Our team works closely with you to identify your needs and create a plan for building a dedicated development team that is tailored to your project.",
    cardsData: [
      {
        icon: "",
        name: "Bernard",
        role: "Software Tech Lead",
        bio: (
          <>
            Bernard is an experienced professional with a strong background in
            leading and managing teams. As a team lead, he has a proven track
            record of driving project success, fostering collaboration, and
            achieving organizational goals. With a passion for empowering and
            developing team members, Bernard believes in creating a positive
            work environment that promotes growth and innovation.
            <br />
            <br /> In addition to his leadership abilities, Bernard is also
            highly skilled in technical areas related to his field.
            <br />
            <br />
            <br /> Outside of work, Bernard enjoys spending time with his
            family, playing sports, and exploring new technologies.
          </>
        ),
        expertise: [
          "Team Lead",
          ".NET",
          "C#",
          "Agile",
          "Team Lead",
          ".NET",
          "C#",
          "Agile",
        ],
        experience: "18 years",
        availability: "Full-time",
      },
      {
        icon: "",
        name: "Bernard",
        role: "Software Tech Lead",
        bio: (
          <>
            Bernard is an experienced professional with a strong background in
            leading and managing teams. As a team lead, he has a proven track
            record of driving project success, fostering collaboration, and
            achieving organizational goals. With a passion for empowering and
            developing team members, Bernard believes in creating a positive
            work environment that promotes growth and innovation.
            <br />
            <br /> In addition to his leadership abilities, Bernard is also
            highly skilled in technical areas related to his field.
            <br />
            <br />
            <br /> Outside of work, Bernard enjoys spending time with his
            family, playing sports, and exploring new technologies.
          </>
        ),
        expertise: [
          "Team Lead",
          ".NET",
          "C#",
          "Agile",
          "Team Lead",
          ".NET",
          "C#",
          "Agile",
        ],
        experience: "18 years",
        availability: "Full-time",
      },
      {
        icon: "",
        name: "Bernard",
        role: "Software Tech Lead",
        bio: (
          <>
            Bernard is an experienced professional with a strong background in
            leading and managing teams. As a team lead, he has a proven track
            record of driving project success, fostering collaboration, and
            achieving organizational goals. With a passion for empowering and
            developing team members, Bernard believes in creating a positive
            work environment that promotes growth and innovation.
            <br />
            <br /> In addition to his leadership abilities, Bernard is also
            highly skilled in technical areas related to his field.
            <br />
            <br />
            <br /> Outside of work, Bernard enjoys spending time with his
            family, playing sports, and exploring new technologies.
          </>
        ),
        expertise: ["Team Lead", ".NET", "C#", "Agile"],
        experience: "18 years",
        availability: "Full-time",
      },
    ],
  };
  const swiperRef = useRef(null);
  const mdDown = useMediaQuery("(max-width:1000px)");
  const smDown = useMediaQuery("(max-width:600px)");

  const ExpertiseChips = ({ expertiseData }) => {
    return (
      <div className={classes.expertiseDiv}>
        <Typography variant="subtitle1" className={classes.expertiseTitle}>
          Expert in
        </Typography>
        <div className={classes.expertiseChipDiv}>
          {expertiseData.map((skillName) => (
            <Chip
              label={skillName}
              variant="outlined"
              className={classes.chip}
            />
          ))}
        </div>
      </div>
    );
  };

  const ConsultantCard = ({ consultantData }) => {
    return (
      <div className={classes.consultantDiv}>
        <div className={classes.personalDetails}>
          <Avatar className={classes.avatar} sx={{ width: 84, height: 84 }} />
          <div className={classes.nameRoleDiv}>
            <Typography variant="body1" className={classes.name}>
              {consultantData.name}
            </Typography>
            <Typography variant="caption" className={classes.role}>
              {consultantData.role}
            </Typography>
          </div>
        </div>
        <Typography variant="caption" className={classes.bio}>
          {consultantData.bio}
        </Typography>
        <ExpertiseChips expertiseData={consultantData.expertise} />
        <div className={classes.otherDetails}>
          <Typography variant="subtitle1" className={classes.otherDetailsTitle}>
            Experience
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.otherDetailsContent}
          >
            {consultantData.experience}
          </Typography>
        </div>
        <div className={classes.otherDetails}>
          <Typography variant="subtitle1" className={classes.otherDetailsTitle}>
            Availability
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.otherDetailsContent}
          >
            {consultantData.availability}
          </Typography>
        </div>
      </div>
    );
  };

  // Custom arrow components
  const CustomPrevArrow = (props) => (
    <div
      className={clsx([classes.arrow, classes.prevArrow, "custom-prev-arrow"])}
      onClick={props.onClick}
    >
      <ArrowBackIcon />
    </div>
  );
  const CustomNextArrow = (props) => (
    <div
      className={clsx([classes.arrow, classes.nextArrow, "custom-next-arrow"])}
      onClick={props.onClick}
    >
      <ArrowForwardIcon />
    </div>
  );

  return (
    <div className={classes.buildTeamRoot}>
      <div className={classes.titleDescDiv}>
        <Typography variant="h2" className={classes.title}>
          {data.title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {data.description}
        </Typography>
      </div>
      {/* <div className={classes.swiperOuterContainer}> */}
      {/* <CustomPrevArrow onClick={() => swiperRef.current.swiper.slidePrev()} />
        <CustomNextArrow onClick={() => swiperRef.current.swiper.slideNext()} /> */}
      {/* <div className={classes.swiperInnerContainer}> */}
      <Swiper
        slidesPerView={mdDown ? 1.8 : "auto"}
        spaceBetween={mdDown ? 5 : 30}
        grabCursor={true}
        // loop={true}
        // autoplay={{
        //   delay: 2000,
        //   disableOnInteraction: false,
        // }}
        // speed={2000}
        // navigation={{
        //   nextEl: ".custom-next-arrow",
        //   prevEl: ".custom-prev-arrow",
        // }}
        // modules={[Navigation, Autoplay]}
        className={clsx(["mySwiper", classes.swiper])}
        // ref={swiperRef}
      >
        {data.cardsData.map((cardData, index) => (
          <SwiperSlide className={classes.swiperSlide}>
            <ConsultantCard consultantData={cardData} />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

BuildTeam.propTypes = {};

export default BuildTeam;
