export const messages = {
  FIXED_RATE_ERROR: "Please enter a value greater than 0.",
  MIN_RANGE_ERROR: "Please enter a value that is lesser than max value.",
  MAX_RANGE_ERROR: "Please enter a value that is greater than min value.",
  SELECT_ROLE: "Please select a role.",
  ENTER_STATE: "Please enter a state.",
  ENTER_CITY: "Please enter a city.",
  ATLEAST_ONE_SKILL: "Please select atleast one skill.",
  GENERIC_ERROR_MESSAGE: "Something went wrong. Please try again later.",
  GENERIC_SUCCESS_MESSAGE: "Your action has been completed successfully.",
  NO_DATA_FOUND: "No Data Found.",
  NO_PREVIEW_AVAILABLE: "No preview available.",
  ACTION_LOADING_MESSAGE: "Your action is in progress. Please wait..",
  /** Image/profile pic upload messages-START */
  IMG_UPLOAD_EXTENSION_INFO: "Preferable formats: PNG, JPG, JPEG, SVG.",
  IMG_UPLOAD_RESOLUTION_INFO:
    "For optimal quality, upload an image with dimensions ≥ 200x200 pixels.",
  IMG_UPLOAD_INCORRECT_RESOLUTION:
    "Please upload an image with dimensions ≥ 200x200 pixels.",
  UPLOAD_VALID_IMG_WARNING: "Please upload a valid image file.",
  EXPECTED_IMG_FILE_SIZE_WARNING: "Please upload a file with size ≤ 1MB.",
  /** Image/profile pic upload messages-END */
  /** resume doc upload messages-START */
  RESUME_DOC_UPLOAD_EXTENSION_INFO: "Accepted formats: PDF, DOC, DOCX, TXT.",
  RESUME_DOC_UPLOAD_EXTENSION_WARNING:
    "Please upload one of the following file types: PDF, DOC, DOCX, TXT.",
  /** resume doc upload messages-END */
  /** Generic file(doc,video) upload messages-START */
  DOC_FILE_UPLOAD_EXTENSION_INFO:
    "Acceptable formats: PDF, DOC, DOCX, TXT, PPT, PPTX.",
  VIDEO_UPLOAD_EXTENSION_INFO: "Preferable formats: MP4, AVI, MKV, MOV.",
  DOC_OR_VIDEO_FILE_SIZE_WARNING_OR_INFO:
    "Please upload a file with size ≤ 5MB.",
  UPLOAD_VALID_DOC_WARNING: "Please upload a valid doc file.",
  UPLOAD_VALID_VIDEO_WARNING: "Please upload a valid video file.",
  /** Generic file(doc,video) upload messages-END */
};

export const PASSWORD_PATTERN =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g; // must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long.

export const CHAR_NUMBER_PATTERN = /^[a-zA-Z0-9 ]*$/;
export const WEBSITE_PATTERN =
  /^(https?):\/\/(www\.)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const URL_PATTERN =
  /^(https?:\/\/)(www\.)?[a-zA-Z0-9-]{1,63}(\.[a-zA-Z]{2,})+([/?#]\S*)?$/;

export const defaultSkillList = [
  {
    skillID: "646f1c2f96656ed53aa3257f",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  {
    skillID: "646f1c2f96656ed53aa32343",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  {
    skillID: "646f1c2f96656ed53aa3264c",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  {
    skillID: "646f1c2f96656ed53aa32478",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  {
    skillID: "646f1c2f96656ed53aa32509",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  {
    skillID: "646f1c2f96656ed53aa324b0",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  {
    skillID: "646f1c2f96656ed53aa324a3",
    skillLevelID: "649a7f1e75ed0920604c5959",
  },
  // { name: "ReactJS", rating: 1, ratingText: "Novice" },
  // { name: "AngularJS", rating: 1, ratingText: "Novice" },
  // { name: "VueJS", rating: 1, ratingText: "Novice" },
  // { name: "HTML/CSS", rating: 1, ratingText: "Novice" },
  // { name: "NextJS", rating: 1, ratingText: "Novice" },
  // { name: "JQuery", rating: 1, ratingText: "Novice" },
  // { name: "Javascript", rating: 1, ratingText: "Novice" },
];

export const fileExtensionMimeType = {
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  txt: "text/plain",
  pdf: "application/pdf",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
};

export const validDocFileMimeTypes = [
  fileExtensionMimeType.doc,
  fileExtensionMimeType.docx,
  fileExtensionMimeType.txt,
  fileExtensionMimeType.pdf,
];

export const validPPTMimeTypes = [
  fileExtensionMimeType.ppt,
  fileExtensionMimeType.pptx,
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const hiringBoardFixedStages = [
  "invited",
  "applied",
  "shortlisted", // After shortlisted there might be custom stages(of type test/meeting) added by the user.
  "offered",
  "accepted",
  "withdrew",
  "rejected",
];

export const DEFAULT_EXPERIENCE_ID = "649a78f675ed0920604c5953"; // Entry level
export const DEFAULT_CONTRACT_ID = "649a78c275ed0920604c594f"; // Full time
export const TWELVE_HOUR_TIME_FORMAT = "hh:mm:ss A"; // Eg:02:25:55 PM
export const TWENTY_FOUR_HOUR_TIME_FORMAT = "HH:mm:ss"; // Eg: 14:25:55
