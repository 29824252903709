import React from "react";
import incompleteProfileImage from "../../images/img_image7.png";
import { Img, Text } from "../../common";
import BarChart from "../../common/Charts/BarChart";
import img_hero_arrow from "../../images/_img_hero_arrow.png";
import CustomButton from "app/v2/components/CustomButton";
import { ArrowDownSVG } from "../../SVGs/ArrowDown";
import { CheckmarkSVG, StarSVG } from "../../SVGs/Index";
import { useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";
const Button = CustomButton;
const DashboardHero = ({ props }) => {
  const history = useHistory();
  return (
    <>
    {props?.consultant?.isProfileComplete !==undefined ? (
      !props?.consultant?.isProfileComplete ? (
        <div className="flex flex-col items-center justify-start mx-auto w-full">
          <div className="h-[740px] md:px-5 relative w-full">
            <div className="absolute bottom-[0] h-[647px] inset-x-[0] mx-auto px-10 sm:px-5 w-full">
              <Img
                src={img_hero_arrow}
                className="absolute h-[647px] inset-y-[0] my-auto object-cover right-[6%]"
                alt="vector"
              />

              <div className="absolute flex flex-col items-start justify-start left-[6%] top-[6%] w-auto">
                <Text className="font-semibold text-5xl sm:text-[38px] md:text-[44px] text-gray-900 w-auto">
                    Hi{" "}
                    {props?.consultant?.consultantDetails?.overview?.first_name}
                    ,
                </Text>
                <Text className="font-semibold text-base text-[#1e5dff] w-auto">
                    Find jobs that matter to you
                </Text>
              </div>


    
        
          
    

        
      
  
              <div className="absolute bottom-[6%] h-[447px] md:h-[471px] inset-x-[0] mx-auto w-[85%] md:w-full">
                <Img
                  src={incompleteProfileImage}
                  className="h-[55%] m-auto object-cover w-full object-cover filter blur-md"
                  alt="image_alt"
                />
                <div className="absolute flex flex-col gap-[26px] md:h-auto h-max inset-[0] items-center justify-center m-auto max-w-[1179px] md:px-10 sm:px-5 px-[121px] py-[133px] w-full">
                  <Text className="font-semibold text-2xl md:text-[22px] text-black-900 text-center sm:text-xl w-auto">
                    Get verified
                  </Text>
                  <Text className="font-normal leading-[160.00%] max-w-[559px] md:max-w-full text-base text-black-900 text-center">
                    Complete your profile and create your first job listing to
                    refine recommendations per job listed ando get your
                    dedicated metrics on your dashboard up and running.
                  </Text>
                  <Button
                      type="button3"
                    customClasses="flex items-center justify-center px-4 py-2"
                    onClick={() => {
                      history.push("/client/profile-settings");

    
          
            
    

          
          
  
                    }}
                  >
                    Complete Profile
                    <CheckmarkSVG className="h-3.5 ml-2.5 w-3.5" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // <div className="flex flex-col items-center justify-start mx-auto w-full">
        //   <div className="h-[740px] md:px-5 relative w-full">
        //     <div className="h-[655px] md:h-[974px] mt-auto mx-auto md:px-10 sm:px-5 w-full">
        //       <Img
        //         src={img_hero_arrow}
        //         className="absolute h-[655px] inset-y-[0] my-auto object-cover right-[6%]"
        //         alt="vector"
        //       />
        //       <div className="absolute flex flex-col items-start justify-start left-[0%] w-auto">
        //         <Text className="font-semibold text-20xl sm:text-[38px] md:text-[44px] text-gray-900 w-auto">
        //           Hi {props?.consultant?.consultantDetails?.overview?.first_name},
        //         </Text>
        //         <Text className="font-semibold text-base text-blue_A700 w-auto">
        //           Let’s build your virtual team today!
        //         </Text>
        //       </div>
        //       <div className="absolute bottom-[24%] flex flex-col inset-x-[0] items-center justify-start mx-auto w-full">
        //         <div className="flex md:flex-col flex-row gap-[35px] items-center justify-between w-full">
        //           <div className="flex md:flex-1 flex-col gap-11 items-start justify-start w-[58%] md:w-full">
        //             <div className="flex md:flex-col flex-row gap-8 h-[149px] md:h-auto items-start justify-start max-w-[670px] w-full">
        //               <div className="flex flex-1 flex-col gap-[19px] h-full items-start justify-start px-[18px] py-[21px] shadow-bs1 w-full">
        //                 <div className="flex flex-col items-start justify-center w-full">
        //                   <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-full">
        //                     {/* {props?.analyticData?.total_jobs_filled} */}
        //                   </Text>
        //                 </div>
        //                 <Text className="font-semibold text-gray-600 text-sm uppercase w-full">
        //                   Total Jobs Filled
        //                 </Text>
        //               </div>
        //               <div className="flex flex-1 flex-col gap-[19px] h-full items-start justify-start px-[18px] py-[21px] shadow-bs1 w-full">
        //                 <div className="flex flex-col items-start justify-center w-full">
        //                   <Text className="font-semibold md:text-3xl sm:text-[28px] text-[32px] text-gray-900 w-full">
        //                     {/* {props?.analyticData?.jobs_filled}% */}
        //                   </Text>
        //                 </div>
        //                 <div className="flex flex-col gap-0.5 items-start justify-start w-auto">
        //                   <Text className="font-semibold text-gray-600 text-sm uppercase w-auto">
        //                     Jobs Filled
        //                   </Text>
        //                   <div className="flex flex-row gap-4 items-start justify-between w-[283px]">
        //                     <Text className="font-normal text-gray-900 text-center text-xs w-auto">
        //                       this week
        //                     </Text>
        //                     <ArrowDownSVG className="h-[12px] w-[12px]" />
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //             <div className="gap-7 grid sm:grid-cols-1 md:grid-cols-2 grid-cols-3 h-[186px] md:h-auto items-start justify-start max-w-[670px] w-full">
        //               <div className="flex flex-1 flex-col gap-4 h-full items-start justify-between p-9 sm:px-5 shadow-bs1 w-full">
        //                 <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">
        //                   {/* {props?.analyticData?.jobs_filled} */}
        //                 </Text>
        //                 <Text className="font-bold leading-[140.00%] max-w-[132px] md:max-w-full text-gray-600 text-sm">
        //                   Ave. Time Jobs Filled
        //                 </Text>
        //               </div>
        //               <div className="flex flex-1 flex-col gap-4 h-full items-start justify-between p-9 sm:px-5 shadow-bs1 w-full">
        //                 <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-full">
        //                   {/* {props?.analyticData?.job_retention_rate}% */}
        //                 </Text>
        //                 <Text className="font-bold text-gray-600 text-sm w-full">
        //                   Job Retention Rate
        //                 </Text>
        //               </div>
        //               <div className="flex flex-1 flex-col gap-4 h-full items-start justify-between p-9 sm:px-5 shadow-bs1 w-full">
        //                 <div className="flex flex-row gap-1 items-start justify-start w-auto">
        //                   <Text className="font-semibold text-2xl md:text-[22px] text-gray-900 sm:text-xl w-auto">
        //                     {props?.analyticData?.consultant_satisfaction_rate}
        //                   </Text>
        //                   <StarSVG className="h-7 w-7" />
        //                 </div>
        //                 <Text className="font-bold leading-[140.00%] max-w-[132px] md:max-w-full text-gray-600 text-sm">
        //                   Consultant Satisfaction Rating
        //                 </Text>
        //               </div>
        //             </div>
        //           </div>
        //           <div className="bg-gray_900 flex md:flex-1 flex-col items-center justify-end pt-[10px] shadow-bs1 w-2/5 md:w-full ">
        //             <div className="flex flex-col gap-1 items-center justify-start w-full">
        //               <div className=" relative w-full ">
        //                 <div className="mx-auto z-[1] p-3 ">
        //                   {/* <BarChart props={{name:"Interview - Offer", data: props?.analyticData?.chart}} /> */}
        //                 </div>
        //                 <div className="bg-white flex flex-row gap-12 z-100 items-center justify-center mt-auto mx-auto sm:px-5 px-9 py-[20px]  w-full sm:w-full">
        //                   <div className="flex flex-col gap-px items-start justify-start w-auto">
        //                     <div className="bg-gray-200 h-[17px] rotate-[180deg] w-[17px]"></div>
        //                     <Text className="font-bold text-blue_gray_200 text-xs w-auto">
        //                       Total Interviews
        //                     </Text>
        //                     <Text className="font-semibold text-2xl md:text-[22px] text-gray-700 sm:text-xl">
        //                       {props?.analyticData?.total_interview}
        //                     </Text>
        //                   </div>
        //                   <div className="flex flex-col gap-px items-start justify-start w-auto">
        //                     <div className="bg-gray-200 h-[17px] rotate-[180deg] w-[17px]"></div>
        //                     <Text className="font-bold text-blue_gray_200 text-xs w-auto">
        //                       Total Job Offers
        //                     </Text>
        //                     <Text className="font-semibold text-2xl md:text-[22px] text-gray-700 sm:text-xl w-auto">
        //                       {props?.analyticData?.total_job_offers}
        //                     </Text>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>

          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            {/* <div
            style={{
              padding: "1.375em 9.1875em 2.4375em 8.125em",
              display: "flex",
              flexDirection: "column",
              gap: "2.4375em",
            }}
            > */}

            <Img
              src={img_hero_arrow}
              className="absolute h-[60%] w-[45%] top-1/4 transform -translate-y-1/2 inset-y-[0] my-auto object-cover  right-[6%] "
              alt="vector"
            />
            <div>
              <div style={{ fontWeight: 600, fontSize: "48px" }}>
                Hi {props?.consultant?.consultantDetails?.overview?.first_name},

    
        
          
    

        
       
              </div>
              <h5
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  wordWrap: "break-word",
                }}
              >
                Find jobs that matter to you
              </h5>
            </div>
            {/* <div
              style={{
                width: "235px",
                color: "#0B0E1E",

    
          
            
    

          
       
  
                fontSize: "16px",
                fontWeight: 600,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  borderBottom:
                    props.selectedTab !== "Analytics"
                      ? "2px solid #000"
                      : "none",
                }}
              >
                Analytics
              </div>
              <div
                style={{
                  borderBottom:
                    props.selectedTab === "User Support"
                      ? "2px solid #000"
                      : "none",
                  color: "#ABABAB",
                }}
              >
                User Support
              </div>
            </div> */}
            </div>
          // </div>
      )
      ) : (
        <div className="flex flex-col items-center justify-start mx-auto w-full">
<ContentLoader
          speed={2}
          width={1400}

    
        
          
    

        
        
  
          height={800}
          viewBox="0 0 1400 800"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          {/* Define the skeleton structure */}
          <rect x="135" y="40" rx="3" ry="3" width="210" height="43" />
          <rect x="135" y="100" rx="3" ry="3" width="270" height="20" />
          <rect x="135" y="170" rx="3" ry="3" width="320" height="150" />
          <rect x="485" y="170" rx="3" ry="3" width="320" height="150" />
          <rect x="135" y="360" rx="3" ry="3" width="208" height="190" />
          <rect x="365" y="360" rx="3" ry="3" width="208" height="190" />
          <rect x="597" y="360" rx="3" ry="3" width="208" height="190" />
          <rect x="950" y="140" rx="3" ry="3" width="540" height="340" />

          {/* <rect x="0" y="40" rx="3" ry="3" width="200" height="10" />
          <rect x="0" y="60" rx="3" ry="3" width="250" height="10" /> */}
        </ContentLoader>
        </div>
      )}
    </>
  );
};

    
          
            
    

          
        
    
    
  
DashboardHero.propTypes = {};
export default DashboardHero;