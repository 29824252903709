import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Chip } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { saveOrunsaveJob } from "app/v2/services/consultant/myJobs.service";
import { useSnackbar } from "notistack";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";

import {
  fetchExperiencesThunk,
  fetchContractTypesThunk,
  fetchIndustryThunk,
  fetchSkillsThunk,
  fetchSkillLevelsThunk,
} from "app/v2/store/features/misc/miscThunk";
import { isFunction } from "app/v2/Pages/Utils/utilFunctions";
import {
  getContractTypeLevel,
  getExperience,
} from "app/v2/Pages/common/getIdTypeLevel";
const useStyles = makeStyles((theme) => ({
  gridCardOuterDiv: {
    margin: "40px auto",
    maxWidth: "320px",
    width: "100%",
    padding: "24px",
    transition: "box-shadow 0.3s ease-in-out transform 0.3s", // Add a smooth transition
    border: "1px solid #ededed",
    "&:hover": {
      boxShadow: "0px 4px 36px rgba(0, 0, 0, 0.12)",
      transform: "translateY(-25px)",
    },
    minHeight: "470px",
  },
  gridCardInnerDiv: {
    // maxWidth: "289px",
    // width: "100%",
    width: "289px",
  },
  jobImageInfoDiv: {
    display: "flex",

    columnGap: "19px",
    marginBottom: "19px",
  },
  jobBasicInfo: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
  },
  companyName: {
    fontSize: "12px",
    color: theme.palette.secondary.light, // light grey
  },
  postedDate: {
    fontSize: "14px",
    // fontWeight: 300,
  },
  jobInfoSets: {
    display: "flex",
    marginBottom: "19px",
    flexWrap: "wrap",
    marginRight: "10px",
  },
  jobInfoDiv: {
    display: "flex",
    flexDirection: "column",
    rowGap: "19px",
    flex: 1,
  },
  addInfoName: {
    fontSize: "10px",
    fontWeight: 700,
    color: theme.palette.secondary.light, // light grey
    marginBottom: "6px",
  },
  addInfoValue: {
    fontSize: "14px",
    fontWeight: 600,
  },
  jobDescription: {
    maxWidth: "269px",
    width: "100%",
    color: theme.palette.secondary.light, // light grey
    fontSize: "12px",
    marginBottom: "19px",
    height: "48px",
    overflowY: "auto",
    paddingRight: "12px",
  },
  skillsDiv: {
    marginBottom: "19px",
  },
  skillsHeading: {
    fontSize: "10px",
    fontWeight: 600,
    color: theme.palette.secondary.light, // light grey
    marginBottom: "8px",
  },
  skillsChipsDiv: {
    display: "flex",
    width: "100%",
    overflowX: "auto",
    paddingBottom: "10px",
  },
  chip: {
    padding: "6px 8px",
    border: "1px solid #EDEDED",
    borderRadius: "6px",
    fontSize: "12px",
    fontWeight: 500,
    color: theme.palette.secondary.light, // light grey
    marginRight: "8px",
    textTransform: "uppercase",
  },
  actionsDiv: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "19px",
  },
  viewJobButton: {
    minWidth: "210px",
    width: "100%",
    height: "40px",
    padding: "10px 15px",
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    backgroundColor: `${theme.palette.common.black} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  viewProfileIcon: {
    marginLeft: "8px",
    width: "16px",
    height: "16px",
  },
  bookmarkDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #E0E0E0",
    height: "40px",
    width: "40px",
  },
  orgImage: {
    width: "100px",
    height: "100px",
    objectFit: "scale-down",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #EDEDED",
  },
}));
const Grid = ({ data, setUpdateSaveJobData }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const data = {
  //   image: "/company_image.png",
  //   title: "Frontend Javascript Developer Needed",

  //   companyName: "Company Name",
  //   postedDate: "3 days ago",
  //   matchRate: "86%",
  //   jobType: "Part Time",
  //   level: "Expert",
  //   location: "Singapore",
  //   jobDescription: "Ornare facilisis id senectus amet lectus tempus at quis in. Ut sit quis augue facilisis facilisis. Vel elit vestibulum nunc donec. Eu risus et integer nulla diam sapien non.",
  //   skills: ["React", "NodeJS", "AngularJS"],
  // };
  const [isHovered, setIsHovered] = useState(false);
  const [experienceTypes, setExperienceTypes] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [isSavedJob, setIsSavedJob] = useState(false);
  const {
    experiences,
    contractTypes: contractTypesReceived,
    skillLevels,
    skills,
    industryData,
  } = useSelector((state) => state.misc);
  const AddInfo = ({ name, value }) => {
    return (
      <div>
        <Typography className={classes.addInfoName}>{name}</Typography>
        <Typography className={classes.addInfoValue}>{value}</Typography>
      </div>
    );
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handlesavedData = () => {
    if (isFunction(setUpdateSaveJobData))
      setUpdateSaveJobData((prevState) => !prevState);
  };
  const handleBookMarkClick = async (jobId) => {
    const actionName = isSavedJob ? "unsave" : "save";
    // enqueueSnackbar(
    //   `Your action to ${actionName} the job is in progress.Please wait.  `,
    //   { variant: "info" }
    // );

    const key = enqueueSnackbar(
      `Your action to ${actionName} the job is in progress.Please wait. `,
      {
        variant: "info",
        autoHideDuration: 3000,
        action: (key) => (
          <CloseIcon
            onClick={() => closeSnackbar(key)}
            style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
          />
        ),
      }
    );
    try {
      const requestData = {
        jobId: jobId,
      };
      const response = await saveOrunsaveJob(requestData);
      if (response) {
        handlesavedData();
        setIsSavedJob((prevState) => !prevState);
        // enqueueSnackbar(`Your job has been ${actionName}d  successfully.`, {
        //   variant: "success",
        // });

        const key = enqueueSnackbar(
          `Your job has been ${actionName}d  successfully. `,
          {
            variant: "success",
            autoHideDuration: 3000,
            action: (key) => (
              <CloseIcon
                onClick={() => closeSnackbar(key)}
                style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
              />
            ),
          }
        );
      } else {
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // enqueueSnackbar(
      //   `Your job could not be ${actionName}d.Please try again later.`,
      //   { variant: "error" }
      // );

      const key = enqueueSnackbar(
        `Your job could not be ${actionName}d.Please try again later.  `,
        {
          variant: "error",
          autoHideDuration: 3000,
          action: (key) => (
            <CloseIcon
              onClick={() => closeSnackbar(key)}
              style={{ cursor: "pointer", fontSize: "15", marginTop: "-1px" }}
            />
          ),
        }
      );
    }
  };
  useEffect(() => {
    const experienceData = experiences?.map(
      (expEntry) =>
        `${expEntry?.level} (${expEntry?.minExp} - ${expEntry?.maxExp} years)`
    );
    setExperienceTypes(experienceData);
  }, [experiences]);
  useEffect(() => {
    if (!experiences?.length) dispatch(fetchExperiencesThunk());
    if (!contractTypesReceived?.length) dispatch(fetchContractTypesThunk());
  }, [dispatch]);
  useEffect(() => {
    const contractData = contractTypesReceived?.map(
      (contractEntry) => contractEntry?.level
    );
    setContractTypes(contractData);
  }, [contractTypesReceived]);
  useEffect(() => {
    setIsSavedJob(data?.isSaved);
  }, [data]);
  return (
    <div
      className={classes.gridCardOuterDiv}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={classes.gridCardInnerDiv}>
        <div className={classes.jobImageInfoDiv}>
          <img
            className={classes.orgImage}
            alt={`org_${data?._id}_image`}
            src={data?.image ? data.image : "/org_placeholder.svg"}
          />
          <div className={classes.jobBasicInfo}>
            <Typography variant="subtitle1" className={classes.title}>
              {data?.jobTitle ?? "--"}
            </Typography>
            {/* <Typography className={classes.companyName}>
              {data?.companyName ?? "--"}
            </Typography> */}
            <div className="text-[14px] text-[#717171] font-semibold">
              JOB NO {data?.jobNo ? data?.jobNo : "-"}
            </div>
            <Typography className={classes.postedDate}>
              Posted {data.createdAt ? moment(data?.createdAt).fromNow() : "-"}
            </Typography>
          </div>
        </div>
        <div className={classes.jobInfoSets}>
          <div className={classes.jobInfoDiv}>
            {/* <AddInfo name="MATCH RATE" value={data?.matchRate ?? "-" } /> */}
            <AddInfo name="MATCH RATE" value={data?.matchingRate || "-"} />
            <AddInfo
              name="LEVEL"
              // value={experienceTypes?.[data?.experienceLevelId] ?? "--"}

              value={getExperience(data?.experienceLevelId, experiences)}
            />
          </div>
          <div className={classes.jobInfoDiv}>
            <AddInfo
              name="CONTRACT TYPE"
              value={getContractTypeLevel(
                data?.contractType,
                contractTypesReceived
              )}
            />
            <AddInfo
              name="LOCATION"
              value={`${data?.country ?? "-"},${data?.state ?? "-"},${
                data?.city ?? "-"
              }`}
            />
          </div>
        </div>
        <Typography className={classes.jobDescription}>
          {data?.companyDetail?.companyDesc?.text ?? "-"}
        </Typography>
        {/* <div className={classes.skillsDiv}>
          <Typography className={classes.skillsHeading}>
            TECHNOLOGY SKILLS
          </Typography>
          <div className={classes.skillsChipsDiv}>
            {data?.skills?.length > 0 ? (
              data.skills.map((skill) => (
                <Chip
                  label={skill}
                  variant="outlined"
                  className={classes.chip}
                />
                <></>
              ))
            ) : (
              <p>No skills available</p>
            )}
          </div>
        </div> */}
        {isHovered && (
          <div className={classes.actionsDiv}>
            <Button
              className={classes.viewJobButton}
              onClick={() => {
                history.push(`/consultant/job-detail/${data?._id}`);
              }}
            >
              View Job Details
              <CallMadeIcon
                fontSize="small"
                className={classes.viewProfileIcon}
              />
            </Button>
            <button
              className={`cursor-pointer ${
                isSavedJob
                  ? "bg-neutral-900 border-none h-10"
                  : "border-[1px] border-solid border-neutral-50"
              } p-2.5 flex flex-row box-border items-center justify-center`}
              onClick={() => handleBookMarkClick(data._id)}
            >
              <img
                className="relative w-5 h-5 overflow-hidden shrink-0"
                alt=""
                src={
                  isSavedJob
                    ? "/iconlightbookmark.svg"
                    : "/icondarkbookmark.svg"
                }
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
Grid.propTypes = {};
export default Grid;
